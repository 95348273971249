import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Map as IMap } from 'immutable';
import Toolbar from '../Toolbar/View';
import ConversationSearch from '../ConversationSearch/View';
import ToolbarButton from '../ToolbarButton/View';
import '../ConversationList/ConversationList.scss';
import CompaniesListItem from '../CompaniesListItem/View';
import { emptySearch, infinityLoader } from '../../../images';
import styles from '../../Feed/feed.module.scss';

const CompaniesList = (props) => {
  const {
    actions,
    companies,
    loaderVisible,
    activeCompanies,
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [companiesData, setCompaniesData] = useState(companies);

  const { t } = useTranslation();

  useEffect(() => {
    actions.activateLoader();
    actions.setVisibilityMessagesList(false);
    actions.getCompanies('', 'name');
  }, [actions]);

  useEffect(() => {
    setCompaniesData(companies);
  }, [companies]);

  const updateSearch = (e) => {
    const newData = companies.filter((item) => {
      const textData = e.target.value.toUpperCase();
      const itemData = item.get('name', '').toUpperCase();
      return itemData.includes(textData);
    });
    setCompaniesData(newData);
    setSearchValue(e.target.value);
    actions.deactivateLoader();
  };
  return (
    <div className="conversation-list">
      <Toolbar
        leftItems={(
          <ToolbarButton
            icon="ion-ios-close"
            actions={actions}
          />
                )}
        title="Search Companies"
      />
      <ConversationSearch
        placeholderText="messenger searchCompanies"
        onChangeText={updateSearch}
        value={searchValue}
      />
      {companiesData.size > 0 && companiesData.map((company) => (
        company.get('id', null) !== parseInt(localStorage.getItem('userId'), 10)
          ? (
            <CompaniesListItem
              key={company.get('id', null)}
              activeStatus={!!activeCompanies.get(`${company.get('id', '')}`)}
              actions={actions}
              companyName={company.get('name', '')}
              companyId={company.get('id', null)}
            />
          ) : null

      ))}
      {companiesData.size === 0 && !loaderVisible && (
        <div className="empty-search-container">
          <img src={emptySearch} alt="Empty search" />
          <h6>{t('feed search noResultsTitle')}</h6>
          <p>{t('feed search noResultsTagline')}</p>
        </div>
      )}
      {
        <img
          src={infinityLoader}
          alt="loader"
          className={classNames(
            styles.imageloaderStyle,
            loaderVisible && companiesData.size === 0 ? '' : 'invisible',
          )}
        />
      }
    </div>
  );
};

const { shape, func, bool } = PropTypes;
const { list, map } = ImmutablePropTypes;

CompaniesList.propTypes = {
  actions: shape({
    setChatRoute: func,
    setCompanyChat: func,
    getCompanies: func,
    setVisibilityCompaniesList: func,
    setVisibilityMessagesList: func,
    activateLoader: func,
    deactivateLoader: func,
  }).isRequired,
  companies: list.isRequired,
  loaderVisible: bool.isRequired,
  activeCompanies: map,
};

CompaniesList.defaultProps = {
  activeCompanies: IMap(),
};

export default CompaniesList;
