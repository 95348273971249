import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAdminStatistic, setAdminStatistic } from '../modules/actions';
import { selectAdminStatistic } from '../modules/selectors'


const mapStateToProps = (state) => ({
  adminStatistic: selectAdminStatistic(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAdminStatistic,
      setAdminStatistic
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
