import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Fade } from 'react-reveal';
import { RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import RegisterStep from './RegisterStep';

import styles from './register.module.scss';

const Register = (props) => {
  const { actions } = props;
  const { t } = useTranslation();
  const [activeComponent, setActiveComponent] = useState('register');
  const [registerData, setRegisterData] = useState({});

  const addRegisterData = (values) => {
    setActiveComponent('interests');
    setRegisterData(values);
  };

  const handleRegister = (interests, industryInterests) => {
    actions.register({ ...registerData, interests, industryInterests });
  };

  return (
    <Fade>
      <div className={styles.register}>
        <div className="container  px-md-5">
          <div className={classNames(styles.navigationInfo, 'pt-5')}>
            <Link to="/">
              <h5>Bizbook</h5>
            </Link>
            <RightOutlined className="px-3 pt-1" />
            <h6>{t('register navigationInfo')}</h6>
          </div>
          <RegisterStep
            handleRegister={addRegisterData}
            setActiveComponent={setActiveComponent}
            addInterests={handleRegister}
            registerData={registerData}
            isRegister={activeComponent === 'register'}
          />
        </div>
      </div>
    </Fade>
  );
};

const { shape, func } = PropTypes;

Register.propTypes = {
  actions: shape({
    register: func.isRequired,
  }).isRequired,
};

export default Register;
