import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { List } from 'antd';
import styles from './langunageSelector.module.scss';
import languageOptions from './languageOptions';
import { ArrowUp, ArrowDown } from '../../../../../svgs';

const LanguageSelectorLoggedUser = (props) => {
  const { actions, activeLanguage } = props;

  const [dropDownVisible, setDropDownVisible] = useState(false);
  const changeLanguage = (language) => {
    setDropDownVisible(false);
    i18n.changeLanguage(language.value);
    actions.setActiveLanguage(language.value);
  };

  const getActiveLanguage = () => {
    const language = languageOptions.find((obj) => obj.value === activeLanguage);
    if (language) return language.label;
    return null;
  };

  const handleVisable = () => {
    if (dropDownVisible) {
      setDropDownVisible(false);
    } else {
      setDropDownVisible(true);
    }
  };
  return (
    <div>
      <div className={styles.selectedLanguage} onClick={handleVisable}>

        {getActiveLanguage()}
        {dropDownVisible
          ? (
            <div className={styles.languageArrow}>
              <ArrowUp />
            </div>
          )
          : (
            <div className={styles.languageArrow}>
              <ArrowDown />
            </div>
          )}

      </div>
      { dropDownVisible
      && (
        <List
          size="small"
          dataSource={languageOptions}
          className={styles.languageList}
          renderItem={(item) => (item.value === activeLanguage ? (<></>) : (
            <List.Item
              className={styles.languageListItem}
              onClick={() => changeLanguage(item)}
            >
              {item.label}
            </List.Item>
          ))}
        />
      )}
    </div>
  );
};

const { shape, string, func } = PropTypes;

LanguageSelectorLoggedUser.propTypes = {
  actions: shape({
    setActiveLanguage: func.isRequired,
  }).isRequired,
  activeLanguage: string.isRequired,
};

export default LanguageSelectorLoggedUser;
