import io from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import {
  take,
  call,
  put,
  all,
  takeEvery,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';
import uuid from 'react-uuid';
import { SOCKET_URL } from 'utils/constants';
import { uploadService } from 'services/cloudinaryService';
import {
  getMessagesService,
  putMessagesReadStatusService,
  getConversationIdService,
  getNewMessagesCountService,
  archiveConversationService,
} from 'services/conversationService';
import { getConversationsService } from 'services/companyService';
import {
  types,
  setConversations,
  setMessages,
  updateMessages,
  updateConversations,
  sendMessage,
  setTitle,
  setVisibilityMessagesList,
  resetChatRoute,
  setConversationId,
  getMessages,
  handleNewMessage,
  setConversationsCount,
  setCompanyChat,
  setActiveCompanies,
  setChatRoute
} from './actions';
import {
  selectConversationId,
  selectCompanyId,
  selectChatFromRoute,
  selectCompanyName,
  selectActiveConversationProductId,
} from './selectors';
import { selectActiveItem } from '../../Feed/modules/selectors';
import history from '../../../history';
import {deactivateLoader} from "../../Feed/modules/actions";

const deferTime = 1000;
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function tryParse(id) {
  const parsed = parseInt(id);
  if (isNaN(parsed)) {
    return 0;
  }
  return parsed;
}

let socket;

function connect() {
  socket = io(SOCKET_URL);
  return new Promise((resolve) => {
    socket.on('connect', () => {
      resolve(socket);
    });
  });
}

window.addEventListener('load', () => {
  if (localStorage.getItem('token')) connect();
});

function subscribe() {
  return eventChannel((emit) => {
    socket.on('new-message', (message) => {
      emit(handleNewMessage(message));
    });
    socket.on('online-status', (message) => {
      emit(setActiveCompanies(message));
    });
    return () => {};
  });
}

function * handleNewMessages(action) {
  const { data } = action.payload;
  const currentConversationId = yield select(selectConversationId);
  if (data.conversationId === currentConversationId) {
    yield put(updateMessages(data));
  } else {
    yield getUnreadMessagesCount();
    yield call(getConversationsSaga);
  }
}

function * archiveConversation(action) {
  const { chatId, activeChatId } = action.payload;
  const { data, error } = yield call(archiveConversationService, chatId);

  const route = yield select(selectChatFromRoute);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setVisibilityMessagesList(false));
    if (data.conversationId === activeChatId || !route) {
      yield put(resetChatRoute());
    } else {
      yield put(setConversationId(activeChatId));
      yield put(getMessages());
      history.push(`/messenger/${activeChatId}`);
      yield put(setVisibilityMessagesList(true));
    }
    yield call(getConversationsSaga);
  }
}

function * getUnreadMessagesCount() {
  const { conversationsCount } = yield call(getNewMessagesCountService);
  yield put(setConversationsCount(conversationsCount.count));
}

function * read() {
  const channel = yield call(subscribe, socket);
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

function * uploadFile(action) {
  const uploadData = action.payload.data;
  const formData = new FormData();
  formData.append('file', uploadData.file);
  formData.append('upload_preset', 'messenger-files');
  const { data, error } = yield call(uploadService, '', formData);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(
      sendMessage({
        message: data.secure_url, type: 'file', productId: null, filename: uploadData.file.name,
      }),
    );
  }
}

function * getMessagesSaga() {
  const conversationId = yield select(selectConversationId);
  if (typeof conversationId !== 'string' && conversationId) {
    const { data, error } = yield call(getMessagesService, conversationId);
    if (error) {
      notification.error({
        message: i18n.t('notification error'),
        description: error,
      });
    } else if (data) {
      const newMessages = data.map((result) => ({
        id: result.id,
        author: `${result.companyId}`,
        timestamp: result.updatedAt,
        message: result.value,
        type: result.type,
        filename: result?.fileName,
      }));
      yield put(setMessages(newMessages));
      const err = yield call(putMessagesReadStatusService, conversationId);
      if (!err) {
        yield call(getConversationsSaga);
      }
    }
  } else {
    yield put(setMessages([]));
  }
}

function * sendMessageSaga(action) {
  const conversationId = yield select(selectConversationId);
  const { sendMessageData } = action.payload;
  const companyId = yield select(selectCompanyId);
  const companyName = yield select(selectCompanyName);

  const messageData = {
    value: sendMessageData.message,
    myId: tryParse(localStorage.getItem('userId')),
    conversationId: tryParse(conversationId) || null,
    companyId: tryParse(companyId),
    companyName,
    type: sendMessageData.type,
    productId: sendMessageData.productId,
    fileName: sendMessageData?.filename,
  };

  socket.emit('send-message', messageData);

  const newMessage = {
    id: uuid(),
    author: localStorage.getItem('userId'),
    timestamp: Date.now(),
    message: sendMessageData.message,
    type: sendMessageData.type,
    filename: sendMessageData?.filename,
  };

  yield put(updateMessages(newMessage));
  yield call(delay, deferTime);
  yield call(getConversationsSaga);
}

function disconnect() {
  socket.emit('company-disconnected', {
    companyId: localStorage.getItem('userId'),
  });
}

window.addEventListener('beforeunload', (event) => {
  event.preventDefault();
  disconnect();
});

function * flow() {
  socket = yield call(connect);
  socket.emit('company-loggedin', {
    companyId: localStorage.getItem('userId'),
    socketId: socket.id,
  });
  yield all([call(read, socket)]);
}

function* getConversationsSaga() {
  const companyId = localStorage.getItem('userId');
  const { data, error } = yield call(getConversationsService, companyId);

  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
    return;
  }

  const productId = yield select(selectActiveConversationProductId);
  const selectedCompanyId = yield select(selectCompanyId);

  const { conversationId } = yield call(getConversationIdService, {
    productId: productId,
    companyId: selectedCompanyId,
  });

  yield put(setConversationId(conversationId));
  yield getUnreadMessagesCount();
  
  const sortedConversations = data.conversations.sort((a, b) => {
    const dateA = new Date(a.lastMessageTime);
    const dateB = new Date(b.lastMessageTime);
    return dateB - dateA;
  });
  yield put(setConversations(sortedConversations));

  const item = yield select(selectActiveItem);
  const route = yield select(selectChatFromRoute);
  const selectedCompanyName = yield select(selectCompanyName);

  if (route && !conversationId) {
    let title = '';
    const secondParticipant = { id: selectedCompanyId };

    if (route === 'feed-product') {
      title = item.get('name', '')
      secondParticipant.name = selectedCompanyName;
      yield put(setCompanyChat({
        id: item.get('companyId', ''),
        name: item.get('name', ''),
      }));
    }

    route === 'company' && (title = selectedCompanyName);

    const conversation = {
      title,
      createdAt: Date.now(),
      productId,
      participants: [{ id: companyId }, secondParticipant],
    };

    yield put(setTitle(title));
    yield put(updateConversations(conversation));
  }

  if (route) {
    yield put(setVisibilityMessagesList(true));
    yield put(getMessages());
    yield put(setChatRoute("", productId))
  }
  yield put(deactivateLoader());
}

export default function * chat() {
  yield all([
    takeEvery(types.CHAT_LOGIN, flow),
    takeEvery(types.CHAT_SEND_MESSAGE, sendMessageSaga),
    takeLatest(types.CHAT_GET_CONVERSATIONS, getConversationsSaga),
    takeLatest(types.CHAT_GET_MESSAGES, getMessagesSaga),
    takeLatest(types.CHAT_DISCONNECT, disconnect),
    takeLatest(types.CHAT_UPLOAD_FILE, uploadFile),
    takeEvery(types.HANDLE_NEW_MESSAGE, handleNewMessages),
    takeEvery(types.ARCHIVE_CONVERSATION, archiveConversation),
  ]);
}
