import React, { useEffect } from 'react';
import { list } from 'react-immutable-proptypes';
import { string, shape, func } from 'prop-types';
import { List as IList } from 'immutable';

import NewsBanner from './NewsBanner';
import CategoryBanner from './CategoryBanner';
import ArticlePreview from './ArticlePreview';

import styles from './news.module.scss';
import ForYou from './ForYou';

const News = (props) => {
  const { articles } = props;

  useEffect(() => {
    props.actions.setSelectedCategory({ category: 'all' });
    props.actions.getBlogArticles();
  }, []);

  return (
    <div className="container">
      <div className={styles.news}>
        <CategoryBanner {...props} />
        <NewsBanner category={props.selectedCategory} newsArticles={articles} showTag />
        <div className={styles.articles}>
          <ArticlePreview articles={articles} />
          <ForYou />
        </div>
      </div>
    </div>
  );
};

News.propTypes = {
  allArticles: list,
  articles: list,
  selectedCategory: string.isRequired,
  actions: shape({
    getBlogArticles: func.isRequired,
    setSelectedCategory: func.isRequired,
  }).isRequired,
};

News.defaultProps = {
  articles: IList(),
  allArticles: IList(),
};

export default News;
