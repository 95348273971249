import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectCompanyId, selectAdminUserRole } from 'selectors/auth';
import { selectProductComments, selectTotalItems, selectQueryData } from './modules/selectors';
import { selectActiveItem, selectActiveItemId } from 'components/Feed/modules/selectors';
import { setQueryData, getProductComments, createNewComment, deleteCommentById, editCommentById } from './modules/actions';

const mapStateToProps = (state) => ({
  companyId: selectCompanyId(state),
  isAdmin: selectAdminUserRole(state),
  itemDetails: selectActiveItem(state),
  activeItemId: selectActiveItemId(state),
  comments: selectProductComments(state),
  totalItems: selectTotalItems(state),
  queryData: selectQueryData(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getProductComments,
      createNewComment,
      deleteCommentById,
      editCommentById,
      setQueryData
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
