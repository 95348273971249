import React, { useEffect } from 'react';
import { List as IList } from 'immutable';
import { list } from 'react-immutable-proptypes';
import { shape, func } from 'prop-types';

import { bizbookBanner2 } from '../../images';
import HomeSlider from './HomeSlider';
import BusinessPlatform from './BusinessPlatform';
import About from './About';
import BusinessOpportunities from './BusinessOpportunities';
import NewsSection from './NewsSection';

import styles from './home.module.scss';

const Home = (props) => {
  useEffect(() => {
    props.actions.getBlogArticles();
    props.actions.getFeaturedProducts();
  }, []);

  return (
    <div className={styles.home}>
      <HomeSlider
        actions={props.actions}
        featuredProducts={props.featuredProducts}
      />
      <BusinessOpportunities contentType="product" />
      {/* <div className="container">
        <a href="https://www.bhtelecom.ba/travel">
          <img
            src="https://res.cloudinary.com/bizbook-blog/image/upload/v1690972732/970x250_druga_pozicija_odozgo_98cf566132.png"
            alt=""
            style={{ width: '100%', margin: '0 auto' }}
          />
        </a>
      </div> */}
      <NewsSection
        showTag={false}
        newsArticles={props.promoArticles}
        category="promo"
      />
      <BusinessOpportunities contentType="demand" />
      <img
        src={bizbookBanner2}
        alt="All – in – one business platform"
        className={styles.banner}
      />
      <About />
      <NewsSection showTag newsArticles={props.blogArticles} category="blog" />
      <BusinessPlatform />
    </div>
  );
};

Home.propTypes = {
  featuredProducts: list,
  blogArticles: list,
  promoArticles: list,
  actions: shape({
    getBlogArticles: func.isRequired,
    getFeaturedProducts: func.isRequired,
    showModal: func.isRequired,
    setNextRoute: func.isRequired,
    setNextRouteParams: func.isRequired,
  }).isRequired,
};

Home.defaultProps = {
  blogArticles: IList(),
  promoArticles: IList(),
  featuredProducts: IList(),
};

export default Home;
