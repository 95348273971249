export const types = {
  SHOW_CONTACT_FORM_MODAL: 'SHOW_CONTACT_FORM_MODAL',
  HIDE_CONTACT_FORM_MODAL: 'HIDE_CONTACT_FORM_MODAL',
  SEND_SUBSCRIPTION_PACKAGE_REQUEST: 'SEND_SUBSCRIPTION_PACKAGE_REQUEST',
  SEND_CONTACT_US_MAIL: 'SEND_CONTACT_US_MAIL',
  RESET_CONTACT_US_FORM_DATA: 'RESET_CONTACT_US_FORM_DATA',
};

export const hideModal = () => ({
  type: types.HIDE_CONTACT_FORM_MODAL,
  payload: {},
});

export const showModal = (packageType) => ({
  type: types.SHOW_CONTACT_FORM_MODAL,
  payload: {
    packageType,
  },
});

export const sendSubscriptionPackageRequest = (postData) => ({
  type: types.SEND_SUBSCRIPTION_PACKAGE_REQUEST,
  payload: {
    postData,
  },
});

export const sendContactUsMail = (data) => ({
  type: types.SEND_CONTACT_US_MAIL,
  payload: {
    data,
  },
});

export const resetContactUsFormData = () => ({
  type: types.RESET_CONTACT_US_FORM_DATA,
  payload: {},
});

export default types;
