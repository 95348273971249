import { useEffect, useReducer, useCallback } from 'react';
import {
  setValue,
  setValues as setValuesActionCreator,
  reducer,
  initialState,
  setErrors as setErrorsActionCreator,
  setSubmitting,
  setSubmittingOff,
} from './useFormReducer';

const useForm = (callback, validate) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setValues = (values) => {
    dispatch(setValuesActionCreator(values));
  };
  const setErrors = (errors) => {
    dispatch(setErrorsActionCreator(errors));
  };

  useEffect(() => {
    if (Object.keys(state.errors).length === 0 && state.isSubmitting) {
      dispatch(setSubmitting());
      callback();
    }
  }, [callback, state.errors, state.isSubmitting]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const validationErrors = validate(state.values);
      setErrors(validationErrors);
      dispatch(setSubmitting());
    },
    [dispatch, validate, state.values],
  );

  const handleOnInputBlur = useCallback(
    (event) => {
      event.preventDefault();
      const validationErrors = validate(state.values);
      setErrors(validationErrors);
    },
    [dispatch, validate, state.values],
  );

  const handleOnAddressBlur = useCallback(
    (event) => {
      event.preventDefault();
      let newState;
      const addressValue = event.target.value;
      dispatch(setValue('address', addressValue));
      if (addressValue !== '') {
        newState = {
          ...state,
          values: {
            ...state.values,
            address: addressValue || null,
          },
        };
      } else {
        const { values: { address, ...values } } = state;
        newState = { ...state, values };
      }
      const validationErrors = validate(newState.values);
      setErrors(validationErrors);
    },
    [dispatch, validate, state.values],
  );

  const handleChange = useCallback(
    (event) => {
      const {
        target: { value, name },
      } = event;
      dispatch(setValue(name, value));
    },
    [dispatch],
  );

  const handleCurrencySelect = useCallback(
    (event) => {
      dispatch(setValue('currency', event));
    },
    [dispatch],
  );

  const handleDateSelect = useCallback(
    (event) => {
      dispatch(setValue('expiryDate', event ? event._d.toISOString() : ''));
    },
    [dispatch],
  );

  const handleOnBlurDateSelectValidation = useCallback(() => {
    const validationErrors = validate(state.values);
    setErrors(validationErrors);
    dispatch(setSubmittingOff());
  }, [dispatch, validate, state.values]);

  const handleSelectCountry = useCallback(
    (name, countryObject) => {
      if (countryObject?.value) {
        dispatch(setValue('country', countryObject));
      } else {
        dispatch(setValue('country', {}));
      }
    },
    [dispatch],
  );

  const handleIndustrySelect = useCallback(
    (name, industry) => {
      dispatch(setValue('industry', industry));
    },
    [dispatch],
  );

  const handleChangeCheckbox = useCallback(
    (event) => {
      const {
        target: { name, checked },
      } = event;
      dispatch(setValue(name, checked));
    },
    [dispatch],
  );

  const handleChangeReCaptcha = useCallback(
    (event) => {
      if (event) {
        dispatch(setValue('reCaptcha', true));
      }
    },
    [dispatch],
  );

  const { values, errors } = state;

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    handleSelectCountry,
    handleChangeCheckbox,
    handleChangeReCaptcha,
    handleCurrencySelect,
    handleDateSelect,
    handleOnInputBlur,
    handleIndustrySelect,
    handleOnBlurDateSelectValidation,
    handleOnAddressBlur,
    setValues,
    setErrors,
  };
};

export default useForm;
