import React from 'react';

const FeedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16">
    <path fill="#FFF" stroke="#FFF" strokeWidth=".2" d="M12 13.36c0 .301-.245.546-.547.546h-.547v.547c0 .302-.245.547-.547.547-.302 0-.546-.245-.546-.547v-.547h-.547c-.302 0-.547-.245-.547-.547 0-.302.245-.546.547-.546h.546v-.547c0-.302.245-.547.547-.547.302 0 .547.245.547.547v.546h.547c.302 0 .547.245.547.547zm0-8.532v5.25c0 .302-.245.547-.547.547-.302 0-.547-.245-.547-.547V5.375H9.812v1.64c0 .303-.244.548-.546.548-.302 0-.547-.245-.547-.547V5.375h-5.25v1.64c0 .303-.245.548-.547.548-.302 0-.547-.245-.547-.547V5.375H1.281v8.531h5.797c.302 0 .547.245.547.547 0 .302-.245.547-.547.547H.734c-.302 0-.546-.245-.546-.547V4.828c0-.302.244-.547.546-.547h1.667C2.618 2.436 4.19 1 6.094 1c1.902 0 3.475 1.436 3.693 3.281h1.666c.302 0 .547.245.547.547zm-3.318-.547c-.209-1.24-1.29-2.187-2.588-2.187-1.299 0-2.38.947-2.589 2.187h5.177z" />
  </svg>


);

export default FeedIcon;
