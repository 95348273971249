/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useReducer, useEffect, useMemo } from 'react';
import PropTypes, { string } from 'prop-types';
import { Modal } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { RightOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { Map as IMap } from 'immutable';
import ImmutableProptypes from 'react-immutable-proptypes';
import {
  width,
  height,
  border,
  defautFont,
  defaultColor,
  heightLine,
  redBorderColor,
  logoBorder,
} from '../../../utils/constants';
import styles from './colorPalette.module.scss';
import ColorPaletteItem from './ColorPaletteItem/View';
import colors from './colors';
import ColoredLine from '../../ColoredLine/View';
import ButtonSaveChangesMedium from '../../Buttons/ButtonSaveChanges/ButtonSaveChangesMedium/View';
import ButtonCancel from '../../Buttons/ButtonCancel/View';
import UploadLogo from './UploadLogo';
import {
  initialState,
  reducer,
  setColor,
  setFontFamily,
  setMainBorder,
  setUploadLogoBorder,
} from './utils/useReducer';
import fonts from './fontFamily';

const ColorPalette = (props) => {
  const { Option } = Select;
  const {
    actions,
    modalVisibility,
    imageUrl,
    theme,
    isToggleDisabled,
    companyName,
  } = props;
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);

  const imageUploaded = useMemo(
    () => imageUrl || theme.get('companyLogo', ''),
    [imageUrl, theme],
  );

  useEffect(() => {
    dispatch(setColor(theme.get('color', defaultColor)));
    dispatch(
      setFontFamily({
        key: theme.getIn(['fontFamily', 'key'], defautFont.key),
      }),
    );
  }, [theme]);

  const toggle = () => {
    if (modalVisibility && !isToggleDisabled) {
      actions.hideModal();
      dispatch(setMainBorder(border));
      dispatch(setUploadLogoBorder(logoBorder));
    } else {
      actions.showModal();
    }
  };

  const handleSaveChanges = () => {
    // Logo validation
    if (!imageUploaded) {
      dispatch(setUploadLogoBorder(redBorderColor));
    } else {
      dispatch(setUploadLogoBorder(logoBorder));
    }

    if (imageUploaded) {
      actions.updateActiveCompanyTheme(imageUploaded, state.color, state.fontFamily);
      actions.hideModal();
    }
  };

  const handleSetColor = (selectedColor) => {
    dispatch(setColor(selectedColor));
    dispatch(setMainBorder(border));
  };
  const handleHideModal = () => {
    actions.hideModal();
    dispatch(setMainBorder(border));
    dispatch(setUploadLogoBorder(logoBorder));
  };

  const handleSetUploadLogoBorder = () => {
    dispatch(setUploadLogoBorder(logoBorder));
  };
  return (
    <Modal
      isOpen={modalVisibility}
      toggle={toggle}
      className={styles.modalContainerStyle}
    >
      <div className={classNames(styles.modalWrapper)}>
        <div className={classNames(styles.navigationInfo)}>
          <h5>{companyName}</h5>
          <RightOutlined className="px-4 pt-1" />
          <h6>{t('color palette nav new identity')}</h6>
        </div>
        <div className={styles.colorPaletteContentContainer}>
          <div className={styles.colorsContainer}>
            <div className={styles.colorSelectedContainer}>
              <div className={classNames(styles.labelMedium, 'py-3')}>
                {t('color palette color picker')}
              </div>
              <div className="d-flex flex-row py-3">
                <div className="pr-3 d-flex flex-column">
                  <ColorPaletteItem
                    width={width}
                    height={height}
                    background="black"
                  />
                </div>
                <div className="pr-3 d-flex flex-column">
                  <ColorPaletteItem
                    width={width}
                    height={height}
                    background={state.color}
                    border={border}
                    mainBorder={state.mainBorder}
                  />
                  {state.mainBorder !== border && (
                    <div className={styles.mainBorder}>
                      {'*'}
                      {t('color palette color validation')}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={classNames(styles.labelMedium, 'd-flex flex-row')}>
              {'*'}
              {t('color palette pick color')}
            </div>
            <div
              className={classNames(
                'd-flex flex-row flex-wrap mt-3',
                styles.colorItemsContainer,
              )}
            >
              {colors.map((item) => (
                <div className="py-2 px-2" key={item.key}>
                  <ColorPaletteItem
                    width="90px"
                    height="48px"
                    background={item.primaryColor}
                    handleClick={handleSetColor}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={styles.logoUploadContainer}>
            <div>
              <p className={classNames(styles.labelMedium, 'py-2')}>
                {'*'}
                {t('color palette your logo')}
              </p>
              <div className={styles.uploadLogo}>
                {state.uploadLogoBorder !== logoBorder && (
                  <p className={styles.uploadLogoValidationTitle}>
                    {'*'}
                    {t('color palette logo validation')}
                  </p>
                )}
                <div style={{ border: state.uploadLogoBorder }}>
                  <UploadLogo setBorder={handleSetUploadLogoBorder} />
                </div>
              </div>
            </div>
            <div>
              <p className={classNames(styles.labelSmall, 'py-2')}>
                {t('color palette select font')}
              </p>
              <Select
                labelInValue
                defaultValue={state.fontFamily}
                onChange={(e) => dispatch(setFontFamily({ key: e.value }))}
                className={classNames(styles.selector)}
                optionLabelProp="label"
                style={{ fontFamily: state.fontFamily.key }}
              >
                {fonts.map((item) => (
                  <Option
                    value={item.font}
                    key={item.key}
                    style={{ fontFamily: item.font }}
                  >
                    {item.font}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <ColoredLine height={heightLine} color={defaultColor} />
        <div
          className={classNames(
            styles.buttonContainer,
            'mb-3 col-12 mt-5 mt-md-0 ml-md-auto pr-lg-5',
          )}
        >
          <ButtonCancel
            handleClick={handleHideModal}
            disabled={isToggleDisabled}
          />
          <ButtonSaveChangesMedium handleClick={handleSaveChanges} />
        </div>
      </div>
    </Modal>
  );
};

const { bool, shape, func } = PropTypes;

ColorPalette.propTypes = {
  actions: shape({
    showModal: func.isRequired,
    hideModal: func.isRequired,
    updateActiveCompanyTheme: func.isRequired,
  }).isRequired,
  modalVisibility: bool.isRequired,
  imageUrl: string.isRequired,
  theme: ImmutableProptypes.map,
  isToggleDisabled: bool.isRequired,
  companyName: string.isRequired,
};

ColorPalette.defaultProps = {
  theme: IMap(),
};

export default ColorPalette;
