import { fromJS } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  modalContactFormVisible: false,
  resetContactUsFormData: false,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SHOW_CONTACT_FORM_MODAL:
      return state.merge({
        requestedPackageType: action.payload.packageType,
        modalContactFormVisible: true,
      });
    case types.HIDE_CONTACT_FORM_MODAL:
      return state.set('modalContactFormVisible', false);
    case types.RESET_CONTACT_US_FORM_DATA:
      return state.set(
        'resetContactUsFormData',
        !state.get('resetContactUsFormData', false),
      );
    default:
      return state;
  }
};
