import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import styles from './commentEdit.module.scss';
import buttonStyles from 'utils/buttonStyle.module.scss';

const CommentEdit = (props) => {
	const {
		comment,
		toggleMode,
		handleEditComment
	} = props;

	const { t } = useTranslation();
	const commentContent = comment.get('content', '');
	const [content, setContent] = useState(commentContent);

	const handleChange = (e) => {
		setContent(e.target.value)
	}

	const handleClickSave = () => {
		if (content.trim() !== "" && content !== commentContent) {
			handleEditComment(comment.get('id', null), content)
		}
		toggleMode();
	};

	const handleClickCancel = () => {
		toggleMode();
	}

	return (
		<div className={styles.commentEdit}>
			<textarea className={styles.commentArea} onChange={handleChange} value={content} />
			<div className={styles.buttonContainer}>
				<button
					type="button"
					className={buttonStyles.mainButtonStyle}
					onClick={handleClickSave}
				>
					{t('comment saveChanges')}
				</button>
				<button
					type="button"
					className={buttonStyles.mainButtonStyle}
					onClick={handleClickCancel}
				>
					{t('comment cancelChanges')}
				</button>
			</div>
		</div>
	)
}

const { func } = PropTypes;
const { map } = ImmutablePropTypes;

CommentEdit.propTypes = {
	comment: map.isRequired,
	handleEditComment: func.isRequired,
	toggleMode: func.isRequired,
}

export default CommentEdit;
