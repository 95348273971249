import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import RouteConstants from 'components/routes/routeConstants';
import { useTranslation } from 'react-i18next';
import {
  bizbookLogo,
  accountCircleLogo,
  confederaziunSvizra,
  marketMakersLogo,
} from 'images';
import LanguageSelector from 'containers/LanguageSelector';
import { composeEditCompanyUrl } from 'utils/pathsHelper';
import NavItem from './NavItem/NavItem';
import Login from '../Login';
import LoggedUserInfo from './LoggedUserInfo';
import styles from './navbar.module.scss';
import useScrollPosition from '../../utils/hooks/useScrollPosition';

const Navbar = (props) => {
  const {
    location: { pathname },
    actions,
    activeLanguage,
    companySlug,
  } = props;

  const { t } = useTranslation();
  const [collapsedNavbar, setCollapsedNavbar] = useState(true);
  const [routeConstants, setRouteConstants] = useState(RouteConstants());
  const [isScrollUp, setIsScrollUp] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScrollPosition(setIsScrollUp);
  }, []);

  useEffect(() => {
    const localStorageLanguage = localStorage.getItem('i18nextLng');
    if (localStorageLanguage) {
      actions.setActiveLanguage(localStorageLanguage);
    }
    if (localStorage.getItem('userId')) {
      actions.loadCompanyDetails(localStorage.getItem('userId'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRouteConstants(RouteConstants());
  }, [activeLanguage]);

  const toggleNavbar = () => {
    setCollapsedNavbar(!collapsedNavbar);
  };

  const closeNavbar = () => {
    setCollapsedNavbar(true);
  };

  return (
    <nav
      className={classNames(
        styles.navbar,
        'navbar navbar-expand-lg',
        ['/tutorial', '/resetpassword'].includes(pathname) ? 'd-none' : ''
      )}
    >
      <div className={classNames('container', styles.container)}>
        <div className={styles.navbarBrandContainer}>
          <Link to="/" className={classNames(styles.navbarBrand)}>
            <img
              src={bizbookLogo}
              alt="bizbook"
              className={classNames(styles.navbarTitleLogo, 'mr-2 my-auto')}
            />
            <div>
              <p className={styles.navbarTitleName}>bizbook</p>
            </div>
          </Link>
        </div>
        {!localStorage.getItem('userId') && (
          <div className={classNames(styles.languageSelector)}>
            <LanguageSelector />
          </div>
        )}

        <button
          className={classNames(styles.navbarToggleButton, 'ml-auto')}
          type="button"
          onClick={toggleNavbar}
          aria-label="Toggle navigation"
        >
          <div className={classNames(styles.line, styles.lineTop)} />
          <div className={classNames(styles.line, styles.lineMiddle)} />
          <div className={classNames(styles.line, styles.lineBottom)} />
        </button>
        <div
          className={classNames(
            'collapse navbar-collapse',
            styles.navbarCollapse,
            collapsedNavbar ? '' : styles.show
          )}
        >
          <Link
            to="/"
            className={classNames(
              styles.navbarBrand,
              styles.mobileBizbookBrand
            )}
          >
            <img
              src={bizbookLogo}
              alt="bizbook"
              className={classNames(styles.navbarTitleLogo, 'mr-1 my-auto')}
            />
            <div>
              <p className={styles.navbarTitleName}>bizbook</p>
            </div>
          </Link>
          <ul className={classNames('navbar-nav', styles.navbarNav)}>
            {localStorage.getItem('userId') && (
              <NavItem
                route={composeEditCompanyUrl(companySlug)}
                title={t('landingPageNavbar myProfileTab')}
                name="my-profile"
                pathname={pathname}
                closeNavbar={closeNavbar}
                id={99}
              />
            )}
            {routeConstants.map((route) => (
              <NavItem
                key={route.routeIndex}
                route={route.route}
                title={route.title}
                name={route.name}
                pathname={pathname}
                closeNavbar={closeNavbar}
                id={route.routeIndex}
              />
            ))}
          </ul>
          <div>
            <LanguageSelector customStyle={styles.languageSelectorMobile} />
          </div>
          <div className={classNames(styles.marketMakersContainer, 'mt-5')}>
            <img
              src={confederaziunSvizra}
              alt="Swiss Confederation"
              className={styles.firstImage}
            />
            <img
              src={marketMakersLogo}
              alt="MarketMakers logo"
              className={styles.secondImage}
            />
          </div>
        </div>
        {localStorage.getItem('userId') ? (
          <div
            className={classNames(
              !collapsedNavbar && styles.hiddenCompanyStyle
            )}
          >
            <LoggedUserInfo />
          </div>
        ) : (
          <button
            type="button"
            className={styles.navbarButton}
            onClick={() => actions.showModal()}
          >
            <img
              src={accountCircleLogo}
              alt="account logo"
              className={styles.navbarButtonImage}
            />
            <p className={styles.navbarButtonText}>{t('navbar buttonText')}</p>
          </button>
        )}
        <Login />
      </div>
    </nav>
  );
};

const { shape, func, string } = PropTypes;

Navbar.propTypes = {
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
  actions: shape({
    setActiveLanguage: func.isRequired,
    showModal: func.isRequired,
    loadCompanyDetails: func.isRequired,
  }).isRequired,
  activeLanguage: string.isRequired,
  companySlug: string.isRequired,
};

export default withRouter(Navbar);
