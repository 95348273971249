import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login } from 'actions/auth';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      login,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps);
