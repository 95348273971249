import React from 'react';

const BookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16" viewBox="0 0 21 16">
    <g fill="#FFF" stroke="#FFF" strokeWidth=".17">
      <path d="M10.26 1.324L8.887.733c-2.231-.95-4.896-.95-7.13 0l-1.372.59C.148 1.426 0 1.628 0 1.849v11.541c0 .206.13.397.34.504.212.107.476.115.696.02l1.369-.588c1.826-.777 4.008-.778 5.832-.002l1.372.59c.102.044.214.066.325.066.129 0 .256-.029.37-.086.21-.107.34-.298.34-.504V1.848c0-.221-.148-.423-.383-.524zM9.226 12.42l-.34-.146c-1.115-.474-2.338-.711-3.562-.711-1.225 0-2.45.237-3.568.712l-.336.145V2.206l.986-.423c1.826-.777 4.008-.777 5.832-.001l.988.424V12.42z" transform="translate(1 1)" />
      <path d="M19.486 1.324L18.11.733c-2.232-.95-4.897-.95-7.13 0l-1.372.59c-.236.102-.384.304-.384.525v11.541c0 .206.13.397.34.504.212.107.476.115.695.02l1.37-.588c1.826-.777 4.007-.777 5.832-.002l1.371.59c.103.044.215.066.326.066.128 0 .256-.029.37-.086.21-.107.34-.298.34-.504V1.848c0-.221-.148-.423-.383-.524zM18.45 12.42l-.34-.146c-1.115-.474-2.338-.711-3.562-.711-1.225 0-2.45.237-3.568.712l-.336.145V2.206l.986-.423c1.826-.777 4.007-.777 5.832-.001l.988.424V12.42z" transform="translate(1 1)" />
    </g>
  </svg>


);

export default BookIcon;
