import { connect } from 'react-redux';
import {
  selectActiveCompanyColorTheme,
  selectActiveCompanyFontFamily,
} from 'selectors/auth';

const mapStateToProps = (state) => ({
  colorTheme: selectActiveCompanyColorTheme(state),
  fontFamily: selectActiveCompanyFontFamily(state),
});

export default connect(mapStateToProps);
