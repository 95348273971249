import { fromJS } from 'immutable';

const initialState = fromJS({
  industryInputValue: '',
  inputValue: '',
  formData: {
    interests: [],
    industryInterests: [],
    showInterests: false,
  },
  deletedInterests: [],
  deletedIndustryInterests: [],
  options: [],
  industries: [],
});

/**
 * @function setInputValue
 * @param {string} inputValue
 * @param {string} key
 */
const setInputValue = (inputValue, key) => ({
  type: 'SET_INPUT_VALUE',
  payload: { inputValue, key },
});

/**
 * @function setIndustries
 * @param {array} industries
 */
const setIndustries = (industries) => ({
  type: 'SET_INDUSTRIES',
  payload: { industries },
});

/**
 * @function setFormData
 * @param {IMap} formData
 */
const setFormData = (formData) => ({
  type: 'SET_FORM_DATA',
  payload: { formData },
});

/**
 * setDeletedInterests
 * @param {IList} deletedInterests
 */
const setDeletedInterests = (deletedInterests) => ({
  type: 'SET_DELETED_INTERESTS',
  payload: { deletedInterests },
});

/**
 * @function setDeletedIndustryInterests
 * @param {IList} deletedIndustryInterests
 */
const setDeletedIndustryInterests = (deletedIndustryInterests) => ({
  type: 'SET_DELETED_INDUSTRY_INTERESTS',
  payload: { deletedIndustryInterests },
});

/**
 * @function setOptions
 * @param {IList} options
 */
const setOptions = (options) => ({
  type: 'SET_OPTIONS',
  payload: { options },
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_INPUT_VALUE':
      return setInputValueReducer(state, action);
    case 'SET_FORM_DATA':
      return setFormDataReducer(state, action);
    case 'SET_DELETED_INTERESTS':
      return setDeletedInterestsReducer(state, action);
    case 'SET_DELETED_INDUSTRY_INTERESTS':
      return setDeletedIndustryInterestsReducer(state, action);
    case 'SET_OPTIONS':
      return setOptionsReducer(state, action);
    case 'SET_INDUSTRIES':
      return setIndustriesReducer(state, action);
    default:
      return state;
  }
}

function setInputValueReducer(state, action) {
  const { key, inputValue } = action.payload;
  return state.set(key, fromJS(inputValue));
}

function setFormDataReducer(state, action) {
  return state.set('formData', fromJS(action.payload.formData));
}

function setDeletedInterestsReducer(state, action) {
  return state.set('deletedInterests', fromJS(action.payload.deletedInterests));
}

function setDeletedIndustryInterestsReducer(state, action) {
  return state.set('deletedIndustryInterests', fromJS(action.payload.deletedIndustryInterests));
}

function setOptionsReducer(state, action) {
  return state.set('options', fromJS(action.payload.options));
}

function setIndustriesReducer(state, action) {
  return state.set('industries', fromJS(action.payload.industries));
}

export {
  reducer,
  initialState,
  setInputValue,
  setOptions,
  setDeletedIndustryInterests,
  setDeletedInterests,
  setFormData,
  setIndustries,
};
