/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import SocialShare from 'components/SocialShare';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, MessageTwoTone } from '@ant-design/icons';
import bizbookBackground from 'images/sliderImages/background2-min.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { composeEditCompanyUrl, composeAboutCompanyUrl } from 'utils/pathsHelper';
import { isLoggedUser } from 'utils/authorizationHelper';
import buttonStyles from 'utils/buttonStyle.module.scss';
import RateContainer from './RateContainer';
import styles from './companyHeader.module.scss';
import history from '../../../history';

const CompanyHeader = (props) => {
  const {
    title,
    subtitle,
    textAlignment,
    imageBackground,
    colorTheme,
    fontFamily,
    imageBackgroundThumbnail,
    companyId,
    isAuthorized,
    ratingCompleted,
    actions,
    companyName,
  } = props;
  const { t } = useTranslation();
  const params = useParams();
  const { id } = params;

  const handleSendMessage = () => {
    actions.setChatRoute('company');
    actions.setCompanyChat({
      id: companyId,
      name: companyName,
    });
    if (localStorage.getItem('userId')) {
      history.push('/messenger');
    } 
    else {
      actions.setNextRoute('/messenger');
      actions.showModal();
    }
  };

  const aboutCompany = () => {
    if (localStorage.getItem('userId')) {
      history.push(composeAboutCompanyUrl(id));
    } 
    else {
      actions.setNextRoute(composeAboutCompanyUrl(id));
      actions.showModal()
    };
  };

  return (
    <div className={styles.companyHeaderContainer}>
      <LazyLoadImage
        alt="Company background"
        effect="blur"
        src={imageBackground || bizbookBackground}
        className={styles.lazyLoadImageContainer}
        placeholderSrc={imageBackgroundThumbnail}
      />
      <div className={styles.companyHeaderContentContainer}>
        <div
          className={classNames(
            'container',
            styles.editActionsRow,
            'py-3 py-md-5',
            isLoggedUser(id)
            && 'justify-content-end',
          )}
        >
          {!ratingCompleted
            && !isLoggedUser(id) ? (
            <RateContainer id={companyId} />
          )
            : <div />}
          {isAuthorized ? (
            <div className={classNames(styles.headerRightContainer)}>
              <div className={styles.siteWatchingModeContainer}>
                <h4>{t('createCompany companyHeader siteWatchingMode title')}</h4>
                <div className={styles.buttonContainer}>
                  <Link to={composeEditCompanyUrl(id)}>
                    <button type="button">
                      {t('createCompany companyHeader siteWatchingMode editing')}
                    </button>
                  </Link>
                  <button type="button">
                    {t('createCompany companyHeader siteWatchingMode watching')}
                    <CheckOutlined className={classNames(styles.iconStyle)} />
                  </button>
                </div>
              </div>
              <SocialShare url={window.location.href} />
            </div>
          ) : (
            <div className={classNames(styles.headerRightContainer)}>
              <button
                type="button"
                className={classNames(
                  buttonStyles.mainButtonStyle,
                  styles.mainButtonStyle,
                )}
                onClick={handleSendMessage}
              >
                <MessageTwoTone className={styles.messageIconStyle} twoToneColor="#30cdc0" />
                {t('viewCompany requestMeetingButton')}
              </button>
              <SocialShare url={window.location.href} />
            </div>
          )}
        </div>
        <div className={classNames('container', styles.companyInfoHeader)}>
          <div>
            <h2
              className={classNames(
                styles.inputCompanySlogan,
                textAlignment,
                styles.viewModeTextStyle,
              )}
              style={{ color: colorTheme, fontFamily }}
            >
              {title}
            </h2>
            <h4
              className={classNames(
                styles.inputAboutCompany,
                textAlignment,
                styles.aboutCompanyViewModeTextStyle,
              )}
            >
              {subtitle}
            </h4>
          </div>
          <button
            type="button"
            onClick={() => aboutCompany()}
            className={classNames(
              styles.aboutCompanyButtonStyle,
              textAlignment === 'text-center'
                ? 'mx-auto'
                : textAlignment === 'text-right'
                  ? 'ml-auto'
                  : '',
            )}
          >
            {t('createCompany companyHeader aboutCompanyTextButton')}
          </button>
        </div>
      </div>
    </div>
  );
};

const {
  string, number, bool, shape, func,
} = PropTypes;

CompanyHeader.propTypes = {
  title: string,
  subtitle: string,
  textAlignment: string.isRequired,
  imageBackground: string.isRequired,
  colorTheme: string.isRequired,
  fontFamily: string.isRequired,
  imageBackgroundThumbnail: string.isRequired,
  companyId: number,
  isAuthorized: bool.isRequired,
  ratingCompleted: bool.isRequired,
  actions: shape({
    setCompanyChat: func.isRequired,
    setChatRoute: func.isRequired,
  }).isRequired,
  companyName: string.isRequired,
};

CompanyHeader.defaultProps = {
  title: '',
  subtitle: '',
  companyId: null,
};

export default CompanyHeader;
