import React from 'react';
import { bool, string } from 'prop-types';
import { list } from 'react-immutable-proptypes';
import { useTranslation } from 'react-i18next';

import styles from './newsSection.module.scss';
import homeStyles from '../home.module.scss';
import NewsBanner from '../../News/NewsBanner';
import SmallerArticles from '../../News/NewsBanner/SmallerArticles';

const NewsSection = (props) => {
  const { newsArticles, category, showTag } = props;
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className={styles.newsSectionContainer}>
        <h2 className={homeStyles.homeSubtitle}>
          {t('homepage news tagline')}
        </h2>
        <h1 className={homeStyles.homeTitle}>
          {`${t('homepage news headline')} "${category}"`}
        </h1>
        <div className={styles.divider} />
      </div>
      <NewsBanner showTag={showTag} newsArticles={newsArticles} />
      {showTag ? null : (<SmallerArticles articles={newsArticles} />)}
    </div>
  );
};

NewsSection.propTypes = {
  newsArticles: list.isRequired,
  showTag: bool.isRequired,
  category: string.isRequired,
};

export default NewsSection;
