export const types = {
  SHOW_ICON_SELECT_MODAL: 'SHOW_ICON_SELECT_MODAL',
  HIDE_ICON_SELECT_MODAL: 'HIDE_ICON_SELECT_MODAL',
  GET_ICONS: 'GET_ICONS',
  SET_ICONS: 'SET_ICONS',
};

export const hideModal = (modalType) => ({
  type: types.HIDE_ICON_SELECT_MODAL,
  payload: {
    modalType,
  },
});

export const showModal = (modalType) => ({
  type: types.SHOW_ICON_SELECT_MODAL,
  payload: {
    modalType,
  },
});

export const getIcons = () => ({
  type: types.GET_ICONS,
  payload: {},
});

export const setIcons = (icons) => ({
  type: types.SET_ICONS,
  payload: {
    icons,
  },
});

export default types;
