import { fromJS, Map as IMap } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  modalColorPaletteVisible: false,
  companyLogoUrl: '',
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SHOW_COLOR_PALETTE_MODAL:
      return state.set('modalColorPaletteVisible', true);
    case types.HIDE_COLOR_PALETTE_MODAL:
      return state.set('modalColorPaletteVisible', false);
    case types.SET_LOGO_URL:
      return state.set('companyLogoUrl', action.payload.logoUrl);
    case types.SET_USER_IMAGE:
      return state.set(
        'uploadedImage',
        fromJS({
          key: action.payload.userObjectKey,
          imageUrl: action.payload.imageUrl,
        }),
      );
    case types.SET_UPLOADED_ABOUT_COMPANY_IMAGE:
      return state.set(
        'uploadedAboutCompanyImage',
        fromJS({
          imageKey: action.payload.imageKey,
          imageUrl: action.payload.imageUrl,
        }),
      );
    case types.SET_UPLOADED_NEW_PRODUCT_DEMAND_IMAGE:
      return state.set(
        'uploadedNewProductDemandImage',
        fromJS({
          id: action.payload.imageKey,
          url: action.payload.imageUrl,
          publicId: action.payload.publicId,
        }),
      );
    case types.CLEAR_UPLOADED_IMAGE:
      return state.set('uploadedNewProductDemandImage', IMap());
    default:
      return state;
  }
};
