import React from 'react';
import { string } from 'prop-types';

const TractorIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 53.364 44.771"
  >
    <g
      id="prefix__Group_650"
      data-name="Group 650"
      transform="translate(-792.017 -330.026)"
    >
      <g
        id="prefix__Group_21"
        data-name="Group 21"
        transform="translate(712.198 -28.855)"
      >
        <g id="prefix__Group_20" data-name="Group 20">
          <g id="prefix__Group_14" data-name="Group 14">
            <path
              id="prefix__Path_27"
              d="M124.6 375.236h-4v-7.221h-.075a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.95a3.125 3.125 0 0 1 3.125 3.125z"
              className="prefix__cls-1"
              data-name="Path 27"
              style={{ fill: color }}
            />
          </g>
          <g id="prefix__Group_15" data-name="Group 15">
            <path
              id="prefix__Rectangle_5"
              d="M0 0H16.55V4H0z"
              className="prefix__cls-1"
              data-name="Rectangle 5"
              transform="translate(102.351 390.42)"
              style={{ fill: color }}
            />
          </g>
        </g>
        <path
          id="prefix__Path_32"
          d="M111.127 382.829h-3.2a.531.531 0 0 1-.531-.531v-1.281a.531.531 0 0 1 .531-.531h3.2a.531.531 0 0 1 .531.531v1.283a.529.529 0 0 1-.531.529z"
          className="prefix__cls-1"
          data-name="Path 32"
          style={{ fill: color }}
        />
      </g>
      <path
        id="prefix__Path_33"
        d="M128.052 374.819h-5.241v-6.1a3.125 3.125 0 0 0-3.125-3.125h-.95a2 2 0 1 0 0 4h.075v5.221H110.5v-4.287a10.079 10.079 0 0 0-10.068-10.068H90.019a3.114 3.114 0 0 0-3.11 3.11v11.26a7.4 7.4 0 0 0-7.09 7.378v9.718A4.077 4.077 0 0 0 83.892 396h.618a9.088 9.088 0 0 0 17.946 0h11.839a6.6 6.6 0 1 0 12.845-.84l4.144-16.18a3.336 3.336 0 0 0-3.232-4.164zm-37.143-10.355h9.526a6.075 6.075 0 0 1 6.068 6.068v4.287H90.909zm2.575 35.189a5.1 5.1 0 1 1 5.1-5.1 5.106 5.106 0 0 1-5.1 5.1zm27.328 0a2.606 2.606 0 1 1 2.607-2.606 2.609 2.609 0 0 1-2.607 2.606zm3.2-8.383a6.577 6.577 0 0 0-7.456.734h-14.34a9.092 9.092 0 0 0-17.459 0h-.861a.073.073 0 0 1-.073-.073v-9.718a3.4 3.4 0 0 1 3.394-3.394H127.2z"
        className="prefix__cls-2"
        data-name="Path 33"
        transform="translate(712.198 -28.855)"
        style={{ fill: color, opacity: 0.2 }}
      />
      <path
        id="prefix__Path_34"
        d="M109.336 382.069h-3.2a.531.531 0 0 0-.531.531v1.281a.531.531 0 0 0 .531.531h3.2a.531.531 0 0 0 .531-.531V382.6a.531.531 0 0 0-.531-.531z"
        className="prefix__cls-2"
        data-name="Path 34"
        transform="translate(712.198 -28.855)"
        style={{ fill: color, opacity: 0.2 }}
      />
      <g
        id="prefix__Group_199"
        data-name="Group 199"
        transform="translate(712.198 -28.855)"
      >
        <path
          id="prefix__Path_28"
          d="M128.718 394.42h-3.127v-2.4l-.363-.093 3.762-14.689H89a3.4 3.4 0 0 0-3.394 3.394v9.718a.074.074 0 0 0 .074.073h2.491v4h-2.486a4.078 4.078 0 0 1-4.074-4.073v-9.718A7.4 7.4 0 0 1 89 373.235h40.839a3.338 3.338 0 0 1 3.233 4.165z"
          className="prefix__cls-1"
          data-name="Path 28"
          style={{ fill: color }}
        />
      </g>
      <g
        id="prefix__Group_200"
        data-name="Group 200"
        transform="translate(712.198 -28.855)"
      >
        <path
          id="prefix__Path_31"
          d="M112.295 377.236H88.7v-15.245a3.113 3.113 0 0 1 3.11-3.11h10.416a10.079 10.079 0 0 1 10.068 10.068zm-19.594-4h15.6v-4.287a6.075 6.075 0 0 0-6.068-6.068H92.7v10.355z"
          className="prefix__cls-1"
          data-name="Path 31"
          style={{ fill: color }}
        />
      </g>
      <g
        id="prefix__Group_201"
        data-name="Group 201"
        transform="translate(712.198 -28.855)"
      >
        <path
          id="prefix__Path_29"
          d="M122.6 402.07a6.606 6.606 0 1 1 6.607-6.607 6.614 6.614 0 0 1-6.607 6.607zm0-9.213a2.606 2.606 0 1 0 2.607 2.606 2.609 2.609 0 0 0-2.607-2.606z"
          className="prefix__cls-3"
          data-name="Path 29"
          style={{ fill: '#050713' }}
        />
      </g>
      <g
        id="prefix__Group_202"
        data-name="Group 202"
        transform="translate(712.198 -28.855)"
      >
        <path
          id="prefix__Path_30"
          d="M95.275 402.07a9.1 9.1 0 1 1 9.1-9.1 9.11 9.11 0 0 1-9.1 9.1zm0-14.2a5.1 5.1 0 1 0 5.1 5.1 5.106 5.106 0 0 0-5.1-5.1z"
          className="prefix__cls-3"
          data-name="Path 30"
          style={{ fill: '#050713' }}
        />
      </g>
    </g>
  </svg>
);

TractorIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

TractorIcon.defaultProps = {
  color: '#37ead9',
  width: '53.364',
  height: '44.771',
};

export default TractorIcon;
