export const types = {
  SHOW_COMPANY_HEADER_BACKGROUND_MODAL: 'SHOW_COMPANY_HEADER_BACKGROUND_MODAL',
  HIDE_COMPANY_HEADER_BACKGROUND_MODAL: 'HIDE_COMPANY_HEADER_BACKGROUND_MODAL',
  GET_BACKGROUND_IMAGES: 'GET_BACKGROUND_IMAGE',
  SET_BACKGROUND_IMAGES: 'SET_BACKGROUND_IMAGE',
};

export const hideModal = () => ({
  type: types.HIDE_COMPANY_HEADER_BACKGROUND_MODAL,
  payload: {},
});

export const showModal = () => ({
  type: types.SHOW_COMPANY_HEADER_BACKGROUND_MODAL,
  payload: {},
});

export const getBackgroundImages = () => ({
  type: types.GET_BACKGROUND_IMAGES,
  payload: {},
});

export const setBackgroundImages = (images) => ({
  type: types.SET_BACKGROUND_IMAGES,
  payload: {
    images,
  },
});

export default types;
