import React, { useEffect } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

import BannerItem from './BannerItem';

import styles from './Banner.module.scss';

const Banner = (props) => {
  const { actions, banner } = props;

  useEffect(() => {
    actions.getBanner();
  }, []);

  if (banner.getIn(['image', 'url'], '').length < 1 && banner.get('url', '').length < 1) {
    return null;
  }

  return (
    <div className={styles.bannerContainer}>
      <div className="container">
        <BannerItem
          img={banner.getIn(['image', 'url'], '')}
          url={banner.get('url', '')}
          alt={banner.getIn(['image', 'name'], '')}
        />
      </div>
    </div>
  );
};

const { map } = ImmutablePropTypes;
const { shape, func } = PropTypes;

Banner.propTypes = {
  banner: map.isRequired,
  actions: shape({
    getBanner: func.isRequired,
  }).isRequired,
};

export default Banner;
