import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadCompanyDetails } from 'actions/auth';
import { selectActiveCompanyId, selectCompanySlug, selectActiveCompanySlug } from 'selectors/auth';
import { login } from 'components/Chat/modules/actions';
import { setActiveLanguage } from '../modules/actions';

const mapStateToProps = (state) => ({
  activeCompanyId: selectActiveCompanyId(state),
  companySlug: selectCompanySlug(state),
  activeCompanySlug: selectActiveCompanySlug(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loadCompanyDetails,
      setActiveLanguage,
      login,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
