import { fromJS } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  conversations: [],
  messages: [],
  conversationId: null,
  visibilityMessagesList: false,
  visibilityCompaniesList: false,
  companyId: '',
  chatFromRoute: '',
  title: '',
  companyName: '',
  activeConversationProductId: null,
  newMessagesCount: 0,
  activeCompanies: {},
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.CHAT_SET_CONVERSATIONS:
      return state.set('conversations', fromJS(action.payload.conversations));
    case types.CHAT_SET_MESSAGES:
      return state.set('messages', fromJS(action.payload.messages));
    case types.CHAT_SET_CONVERSATIONID:
      return state.set('conversationId', action.payload.conversationId);
    case types.CHAT_VISIBILITY_MESSAGELIST:
      return state.set('visibilityMessagesList', action.payload.visibility);
    case types.CHAT_VISIBILITY_COMPANIESLIST:
      return state.set('visibilityCompaniesList', action.payload.visibility);
    case types.CHAT_SET_COMPANY_DATA:
      return state.merge({
        companyId: action.payload.company.id,
        companyName: action.payload.company.name,
      });
    case types.CHAT_UPDATE_MESSAGES:
      return state.update('messages', (messages) => messages.push(fromJS(action.payload.message)));
    case types.CHAT_UPDATE_CONVERSATION:
      return state.withMutations((nextState) => {
        nextState.update('conversations', (conversations) => conversations.push(fromJS(action.payload.conversation)));
        nextState.set('visibilityMessagesList', true);
        nextState.set('visibilityCompaniesList', false);
      });
    case types.CHAT_FROM_ROUTE:
      return state.merge({
        chatFromRoute: action.payload.route,
        activeConversationProductId: action.payload.id,
      });
    case types.RESET_CHAT_FROM_ROUTE:
      return state.set('chatFromRoute', '');
    case types.CHAT_SET_TITLE:
      return state.set('title', action.payload.title);
    case types.SET_CONVERSATIONS_COUNT:
      return state.set('newMessagesCount', action.payload.data);
    case types.SET_ACTIVE_COMPANIES:
      return state.set('activeCompanies', fromJS(action.payload.data));
    default:
      return state;
  }
};
