import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './ConversationSearch.css';

const ConversationSearch = (props) => {
  const {
    onChangeText,
    value,
    placeholderText,
  } = props;
  const { t } = useTranslation();

  return (

    <div className="conversation-search">
      <input
        type="search"
        className="conversation-search-input"
        placeholder={t(placeholderText)}
        value={value}
        onChange={onChangeText}
      />
    </div>
  );
};

const { string, func } = PropTypes;

ConversationSearch.propTypes = {
  onChangeText: func.isRequired,
  value: string.isRequired,
  placeholderText: string.isRequired,
};

export default ConversationSearch;
