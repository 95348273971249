import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Navbar from '../components/Navbar';
import NavBarLoggedUser from '../components/Navbar/NavBarLoggedUser';
import Footer from '../components/Footer';
import { types } from '../components/Login/modules/actions';
import SubscriptionEndingBanner from '../components/SubscriptionEndingBanner';
import Banner from '../components/Banner';

export const PrivateRouteMessengerList = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (localStorage.getItem('token') ? (
      <>
        <Banner />
        <NavBarLoggedUser />
        <div className="contentMobile">
          <SubscriptionEndingBanner />
          <Component {...props} />
        </div>
      </>
    ) : (
      <Redirect to="/" />
    ))}
  />
);

export const RouteWithoutNavbar = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    component={(props) => (
      <div className="contentMobile">
        <Component {...props} />
        <Footer />
      </div>
    )}
  />
);

export const NavRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    component={(props) => (
      <div>
        <Banner />
        <Navbar />
        <div className="contentMobile">
          <SubscriptionEndingBanner />
          <Component {...props} />
        </div>
        <Footer />
      </div>
    )}
  />
);

export const UniversalRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <div>
        <Banner />
        {localStorage.getItem('token') ? <NavBarLoggedUser /> : <Navbar />}
        <div className="contentMobile">
          <SubscriptionEndingBanner />
          <Component {...props} />
        </div>
        <Footer />
      </div>
    )}
  />
);

export const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      dispatch({
        type: types.SET_NEXT_ROUTE,
        payload: { nextRoute: location.pathname },
      });
    }
  }, [token]);

  return (
    <Route
      {...rest}
      render={(props) => (localStorage.getItem('token') ? (
        <>
          <Banner />
          <NavBarLoggedUser />
          <div className="contentMobile">
            <SubscriptionEndingBanner />
            <Component {...props} />
          </div>
          <Footer />
        </>
      ) : (
        <Redirect to="/login" />
      ))}
    />
  );
};

export default PrivateRoute;
