/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { colorPicker, imageIcon } from 'images/createCompany';
import bizbookBackground from 'images/sliderImages/background2-min.png';
import { CheckOutlined } from '@ant-design/icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { composeViewCompanyUrl, composeAboutCompanyUrl } from 'utils/pathsHelper';
import EditBar from '../modules/utils/EditBar';
import styles from './companyHeader.module.scss';
import ColorPalette from '../ColorPalette';
import SelectHeaderBackground from '../SelectHeaderBackground';

const CompanyHeader = (props) => {
  const {
    actions,
    title,
    subtitle,
    saveChange,
    textAlignment,
    imageBackground,
    colorTheme,
    fontFamily,
    imageBackgroundThumbnail,
    companyId,
    setSectionCompleted,
    companyLogo,
  } = props;
  const { t } = useTranslation();
  const params = useParams();
  const { id } = params;
  const [viewMode, setViewMode] = useState('edit');
  const [formData, setFormData] = useState({ title, subtitle, textAlignment });
  const [changesSaved, setChangesSavedStatus] = useState(false);
  const sectionDidChange = (change) => {
    actions.setChangesMade(change);
    setChangesSavedStatus(!change);
  };
  const updateForm = (property, value) => {
    const newFormData = { ...formData };
    if (property) newFormData[property] = value;
    setFormData(newFormData);
    sectionDidChange(true);
  };

  const saveChanges = () => {
    saveChange('companyHeader', {
      ...formData,
      title: formData.title || 'Ponosni što smo dio Bizbook platforme!',
      subtitle: formData.subtitle || 'Ovdje nas možete bolje upoznati.',
    });
    sectionDidChange(false);
  };

  useEffect(() => {
    setFormData({
      title,
      subtitle,
      textAlignment,
    });
    setSectionCompleted('companyLogo', !!companyLogo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtitle, title, textAlignment, companyLogo]);

  const setTextAlignment = (alignment) => {
    updateForm('textAlignment', alignment);
  };

  return (
    <div className={styles.companyHeaderContainer}>
      <LazyLoadImage
        alt="Company background"
        effect="blur"
        src={imageBackground || bizbookBackground}
        className={styles.lazyLoadImageContainer}
        placeholderSrc={imageBackgroundThumbnail}
      />
      <div className={styles.companyHeaderContentContainer}>
        <div
          className={classNames(
            'container',
            styles.editActionsRow,
            'py-3 py-md-5',
          )}
        >
          <div className={styles.modalButtonContainer}>
            <button
              type="button"
              onClick={() => actions.showModal()}
              className={classNames(
                styles.buttonStyle,
                viewMode === 'view' ? 'invisible' : '',
              )}
            >
              {t('createCompany companyHeader setupColors')}
              <img
                src={colorPicker}
                alt="set colors"
                className={classNames(styles.iconStyle)}
              />
            </button>
            <button
              type="button"
              onClick={() => {
                actions.showBackgroundImageSelectModal();
                actions.getBackgroundImages();
              }}
              className={classNames(
                styles.buttonStyle,
                viewMode === 'view' ? 'invisible' : '',
              )}
            >
              {t('createCompany companyHeader addBackground')}
              <img
                src={imageIcon}
                alt="set background"
                className={classNames(styles.iconStyle)}
              />
            </button>
          </div>
          <div className={styles.siteWatchingModeContainer}>
            <h4>{t('createCompany companyHeader siteWatchingMode title')}</h4>
            <div className={styles.buttonContainer}>
              <button type="button">
                {t('createCompany companyHeader siteWatchingMode editing')}
                <CheckOutlined className={classNames(styles.iconStyle)} />
              </button>
              <Link to={composeViewCompanyUrl(id)}>
                <button type="button">
                  {t('createCompany companyHeader siteWatchingMode watching')}
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className={classNames('container', styles.companyInfoHeader)}>
          <div>
            {viewMode === 'edit' ? (
              <textarea
                type="text"
                placeholder={t(
                  'createCompany companyHeader sloganText placeholder',
                )}
                name="companySlogan"
                className={classNames(
                  styles.inputCompanySlogan,
                  formData.textAlignment,
                  viewMode === 'view' ? styles.noBorderStyle : '',
                )}
                rows={2}
                maxLength={100}
                value={formData.title}
                onChange={(e) => {
                  updateForm('title', e.target.value);
                }}
                style={{ color: colorTheme, fontFamily }}
              />
            ) : (
              <h2
                className={classNames(
                  styles.inputCompanySlogan,
                  formData.textAlignment,
                  styles.viewModeTextStyle,
                )}
                style={{ color: colorTheme, fontFamily }}
              >
                {formData.title}
              </h2>
            )}
            {viewMode === 'edit' ? (
              <input
                type="text"
                placeholder={t(
                  'createCompany companyHeader aboutCompanyText placeholder',
                )}
                name="aboutCompany"
                className={classNames(
                  styles.inputAboutCompany,
                  formData.textAlignment,
                  viewMode === 'view' ? styles.noBorderStyle : '',
                )}
                value={formData.subtitle}
                onChange={(e) => {
                  updateForm('subtitle', e.target.value);
                }}
                maxLength={95}
              />
            ) : (
              <h4
                className={classNames(
                  styles.inputAboutCompany,
                  formData.textAlignment,
                  styles.aboutCompanyViewModeTextStyle,
                )}
              >
                {formData.subtitle}
              </h4>
            )}
          </div>
          <Link
            to={composeAboutCompanyUrl(id)}
            className={classNames(
              styles.aboutCompanyButtonStyle,
              formData.textAlignment === 'text-center'
                ? 'mx-auto'
                : formData.textAlignment === 'text-right'
                  ? 'ml-auto'
                  : '',
            )}
          >
            {t('createCompany companyHeader aboutCompanyTextButton')}
          </Link>
          <EditBar
            textAlignment={formData.textAlignment}
            viewMode={viewMode}
            setTextAlignment={setTextAlignment}
            setViewMode={setViewMode}
            saveChanges={saveChanges}
            changesSaved={changesSaved}
          />
        </div>
        <ColorPalette />
        <SelectHeaderBackground />
      </div>
    </div>
  );
};

const {
  string, func, shape, number,
} = PropTypes;

CompanyHeader.propTypes = {
  title: string,
  subtitle: string,
  saveChange: func.isRequired,
  textAlignment: string.isRequired,
  actions: shape({
    showModal: func.isRequired,
    showBackgroundImageSelectModal: func.isRequired,
    getBackgroundImages: func.isRequired,
    setChangesMade: func.isRequired,
  }).isRequired,
  imageBackground: string.isRequired,
  colorTheme: string.isRequired,
  fontFamily: string.isRequired,
  imageBackgroundThumbnail: string.isRequired,
  companyId: number,
  setSectionCompleted: func.isRequired,
  companyLogo: string.isRequired,
};

CompanyHeader.defaultProps = {
  title: '',
  subtitle: '',
  companyId: null,
};

export default CompanyHeader;
