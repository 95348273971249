import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { loginIcon } from 'images';
import { useTranslation } from 'react-i18next';
import AnimationLoader from 'utils/animationLoader';
import { registerUrl } from 'utils/pathsHelper';
import ForgetPasswordForm from './forgotPasswordForm';
import LoginForm from './loginForm';
import styles from './login.module.scss';

const Login = (props) => {
  const { actions, modalVisibility, animationIsPaused } = props;
  const { t } = useTranslation();
  const [displayForgotPasswordSection, setForgotPasswordSection] = useState(
    false,
  );

  const toggle = () => {
    setForgotPasswordSection(false);
    if (modalVisibility) {
      actions.hideModal();
    } else {
      actions.showModal();
    }
  };

  const setIsPaused = (status) => {
    actions.setAnimationStatus(status);
  };

  return (
    <div className={styles.modalContainerStyle}>
      {animationIsPaused
        ? modalVisibility && (
        <Modal
          isOpen={modalVisibility}
          toggle={toggle}
          className={styles.modalContainer}
        >
          <ModalHeader toggle={toggle} className={styles.modalHeader} />
          <ModalBody className={styles.modalBody}>
            <img src={loginIcon} alt="login to bizbook" />
            {displayForgotPasswordSection ? (
              <div>
                <h1>{t('login forgotPassword')}</h1>
                <p
                  className={classNames(
                    styles.forgotPasswordInstructions,
                    'my-5',
                  )}
                >
                  {t('login forgotPasswordInstructions')}
                </p>
              </div>
            ) : (
              <div>
                <h1>{t('login headline')}</h1>
                <h2>{t('login tagline')}</h2>
              </div>
            )}
            {displayForgotPasswordSection ? (
              <ForgetPasswordForm
                actions={actions}
                setIsPaused={setIsPaused}
                setForgotPasswordSection={setForgotPasswordSection}
              />
            ) : (
              <LoginForm actions={actions} setIsPaused={setIsPaused} />
            )}
            <div
              className={`${
                displayForgotPasswordSection ? 'invisible' : ''
              }`}
            >
              <h3>
                {t('login noProfileText noProfile')}
                {' '}
                <Link to={registerUrl} onClick={() => actions.hideModal()}>
                  {' '}
                  {t('login noProfileText becomeUser')}
                </Link>
              </h3>
              <div
                onClick={() => setForgotPasswordSection(true)}
                role="presentation"
              >
                <h4>{t('login forgotPasswordText')}</h4>
              </div>
            </div>
          </ModalBody>
        </Modal>
        )
        : modalVisibility && (
        <Modal isOpen={modalVisibility} className={styles.animationStyle}>
          <AnimationLoader isPaused={animationIsPaused} isStopped={false} />
        </Modal>
        )}
    </div>
  );
};

const { bool, shape, func } = PropTypes;

Login.propTypes = {
  actions: shape({
    login: func.isRequired,
    requestPasswordReset: func.isRequired,
    showModal: func.isRequired,
    hideModal: func.isRequired,
    setAnimationStatus: func.isRequired,
  }).isRequired,
  modalVisibility: bool.isRequired,
  animationIsPaused: bool.isRequired,
};

export default Login;
