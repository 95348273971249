import { fromJS } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  companyInterests: [],
  industryInterests: [],
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_INTERESTS:
      return state.set('companyInterests', fromJS(action.payload.data));
    case types.SET_INDUSTRY_INTERESTS:
      return state.set('industryInterests', fromJS(action.payload.industryInterests));
    default:
      return state;
  }
};
