import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AutoComplete } from 'antd';
import ImmutableProptypes from 'react-immutable-proptypes';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from '../../adminPanel.module.scss';

const { Option } = AutoComplete;

const SelectIndustry = (props) => {
  const {
    industries,
    currentIndustry,
    handleIndustryChange,
    data,
    companyId,
    columnName,
  } = props;

  const { t } = useTranslation();
  const [industrySearchVal, setIndustrySearchVal] = useState('');

  useEffect(() => {
    setIndustrySearchVal(currentIndustry);
  }, [currentIndustry]);

  const handleIndustrySelect = (option) => {
    setIndustrySearchVal(option.value);
    handleIndustryChange(companyId, option.key, data, columnName);
  };

  const filterIndustrySearch = (inputValue, option) => option.value
        && option.value.toUpperCase().includes(inputValue.toUpperCase());

  return (
    <AutoComplete
      onChange={setIndustrySearchVal}
      value={industrySearchVal || ''}
      placeholder={t('register input industry placeholder')}
      className={classNames('my-2', styles.industryField)}
      filterOption={
            (inputValue, option) => filterIndustrySearch(inputValue, option)
        }
      onSelect={
            ((value, option) => handleIndustrySelect(option))
        }
    >
      {industries.map((industry) => (
        <Option value={industry.get('name', '')} key={industry.get('id', null)}>
          {industry.get('name', '')}
        </Option>
      ))}
    </AutoComplete>
  );
};

const { string, number, func } = PropTypes;
const { list, map } = ImmutableProptypes;

SelectIndustry.propTypes = {
  industries: list.isRequired,
  data: map.isRequired,
  companyId: number.isRequired,
  columnName: string.isRequired,
  handleIndustryChange: func.isRequired,
  currentIndustry: string.isRequired,
};

export default SelectIndustry;
