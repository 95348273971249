const initialState = {
  values: {},
  errors: {},
  isSubmitting: false,
};

const setValue = (name, value) => ({
  type: 'SET_VALUE',
  payload: { name, value },
});

const setValues = (values) => ({
  type: 'SET_VALUES',
  payload: { values },
});

const setErrors = (errors) => ({
  type: 'SET_ERRORS',
  payload: { errors },
});

const setSubmitting = () => ({
  type: 'SET_IS_SUBMITTING',
});

const setSubmittingOff = () => ({
  type: 'SET_SUBMITTING_OFF',
});

function reducer(state, action) {
  switch (action.type) {
    case 'SET_VALUE':
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.name]: action.payload.value,
        },
      };
    case 'SET_VALUES':
      return {
        ...state,
        values:{
          ...state.values,
          ...action.payload.values,
        },
      };
    case 'SET_ERRORS':
      return { ...state, errors: action.payload.errors };
    case 'SET_IS_SUBMITTING':
      return { ...state, isSubmitting: !state.isSubmitting };
    case 'SET_SUBMITTING_OFF':
      return { ...state, isSubmitting: false };
    default:
      return state;
  }
}

export {
  reducer,
  setValue,
  setValues,
  initialState,
  setErrors,
  setSubmitting,
  setSubmittingOff,
};
