/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { rubbish, addIcon } from 'images/createCompany';
import buttonStyles from 'utils/buttonStyle.module.scss';
import icons from 'images/Icons/index';
import { textInputMaxLength } from 'utils/constants';
import styles from './productsAndServices.module.scss';

const ProductItem = (props) => {
  const {
    textAlignment,
    viewMode,
    title,
    description,
    setProductsData,
    itemKey,
    setActiveCard,
    iconName,
    colorTheme,
    showModal,
    unsavedCard,
    actions,
  } = props;
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    key: itemKey,
    title,
    description,
    iconName,
  });
  useEffect(() => {
    setProductsData(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);
  useEffect(() => {
    setFormData({
      key: itemKey,
      title,
      description,
      iconName,
    });
  }, [description, iconName, itemKey, title]);
  const clearCard = () => {
    actions.setChangesMade(true);
    setProductsData({ key: formData.key, title: '', description: '' });
  };
  const openModal = () => {
    setActiveCard({
      key: itemKey,
      title,
      description,
    });
    showModal('productsAndServicesIconSelect');
  };
  return (
    <>
      <div
        className={classNames(
          styles.productItemContainer,
          textAlignment === 'text-center'
            ? 'align-items-center'
            : textAlignment === 'text-right'
              ? 'align-items-end'
              : '',
          'px-3',
          unsavedCard && styles.unsavedCard,
        )}
      >
        <div className={classNames(styles.imageContainer, 'px-3', !iconName && styles.iconEmpty)}>
          {formData.iconName ? (
            <div className={styles.svgIconContainer}>
              {icons(colorTheme, '46px', '37px')[formData.iconName]}
            </div>
          ) : (
            <img
              src={addIcon}
              alt="about us icon upload"
              className={styles.addIconImgStyle}
            />
          )}
          <button
            type="button"
            className={classNames(
              buttonStyles.buttonSuccessStyle,
              styles.buttonSuccessStyle,
              viewMode === 'view' ? 'd-none' : '',
            )}
            onClick={openModal}
          >
            {t('createCompany aboutUs buttonText addIcon')}
          </button>
        </div>
        {viewMode === 'edit' ? (
          <input
            type="text"
            placeholder={t('createCompany whatWeDo inputTitle placeholder')}
            name="sectionTitle"
            className={classNames(
              styles.inputSectionTitle,
              textAlignment,
              viewMode === 'view' ? styles.noBorderStyle : '',
            )}
            value={formData.title}
            onChange={(e) => {
              actions.setChangesMade(true);
              setFormData({ ...formData, title: e.target.value });
            }}
            maxLength={100}
          />
        ) : (
          <h3
            className={classNames(
              styles.inputSectionTitle,
              textAlignment,
              styles.noBorderStyle,
            )}
          >
            {formData.title}
          </h3>
        )}

        <div className={classNames(styles.horizontalBreakline, 'mx-auto')} />
        <p
          className={classNames(
            styles.textCounterContainer,
            viewMode === 'view' && 'd-none',
          )}
        >
          {`${formData.description.length}/${textInputMaxLength}`}
        </p>
        {viewMode === 'edit' ? (
          <textarea
            type="text"
            placeholder={t('createCompany whatWeDo inputText placeholder')}
            name="sectionShortDescription"
            className={classNames(
              styles.inputSectionDescription,
              textAlignment,
              viewMode === 'view' ? styles.noBorderStyle : '',
            )}
            rows={4}
            value={formData.description}
            onChange={(e) => {
              actions.setChangesMade(true);
              setFormData({ ...formData, description: e.target.value });
            }}
            maxLength={textInputMaxLength}
          />
        ) : (
          <p
            className={classNames(
              styles.inputSectionDescription,
              textAlignment,
              styles.noBorderStyle,
            )}
          >
            {formData.description}
          </p>
        )}
      </div>
      <div
        className={classNames(
          styles.rubbishIconStyle,
          textAlignment === 'text-center'
            ? 'mx-auto'
            : textAlignment === 'text-right'
              ? 'ml-auto'
              : '',
          'mx-3',
          viewMode === 'view' ? 'invisible' : '',
        )}
        onClick={clearCard}
        onKeyPress={clearCard}
      >
        <img src={rubbish} alt="delete" />
      </div>
    </>
  );
};

const { string, func, bool, shape } = PropTypes;

ProductItem.propTypes = {
  viewMode: string.isRequired,
  textAlignment: string.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  setProductsData: func.isRequired,
  itemKey: string.isRequired,
  setActiveCard: func.isRequired,
  iconName: string,
  colorTheme: string.isRequired,
  showModal: func.isRequired,
  unsavedCard: bool.isRequired,
  actions: shape({
    setChangesMade: func.isRequired,
  }).isRequired,
};

ProductItem.defaultProps = {
  iconName: '',
};

export default ProductItem;
