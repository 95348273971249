import React from 'react';

const EmailIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="110" height="106" viewBox="0 0 110 106">
    <g fill="none" fillRule="evenodd">
      <path fill="#68FFF1" d="M107.704 4.986L67.483 34.265l-6.6 4.816c-3.497 2.6-8.27 2.6-11.766 0l-6.6-4.816-40.27-29.23C4.488 1.88 8.108.01 11.958.025h86.087c3.82 0 7.413 1.844 9.66 4.96z" />
      <g fill="#68FFF1">
        <path d="M2.248.059l40.27 29.23L3.49 68.586C1.248 66.322-.005 63.254 0 60.06V7.09C-.01 4.561.775 2.101 2.248.06zM110 7.09V60.06c.005 3.193-1.248 6.26-3.491 8.524L67.483 29.29 107.704.01C109.197 2.062 110 4.542 110 7.09z" transform="translate(0 4.976)" />
      </g>
      <path fill="#455A64" fillRule="nonzero" d="M71.74 86.707c-3.176 0-4.784-2.408-4.784-7.223.077-6.728-5.275-12.246-11.956-12.324-6.681-.077-12.162 5.312-12.239 12.04-.076 6.727 5.275 12.245 11.957 12.323 3.582.043 6.997-1.517 9.326-4.253 1.588 2.74 4.548 4.378 7.695 4.253 9.565 0 9.565-9.063 9.565-12.04 0-14.63-11.774-26.485-26.304-26.485s-26.304 11.856-26.304 26.48c0 14.626 11.775 26.487 26.304 26.487 6.825 0 13.382-2.673 18.289-7.45.952-.93.97-2.46.048-3.414-.923-.959-2.444-.978-3.396-.049-8.556 8.307-22.182 8.062-30.432-.553-8.25-8.616-8.006-22.335.55-30.642 8.556-8.307 22.182-8.062 30.432.554 3.874 4.045 6.036 9.448 6.031 15.068 0 5.403-1.205 7.228-4.783 7.228zm-16.74 0c-3.96 0-7.174-3.236-7.174-7.223 0-3.988 3.214-7.224 7.174-7.224 3.96 0 7.174 3.231 7.174 7.224 0 3.992-3.214 7.223-7.174 7.223z" />
      <path fill="#35D2C3" d="M67.483 34.265l-6.6 4.816c-3.497 2.6-8.27 2.6-11.766 0l-6.6-4.816L3.491 73.56c2.248 2.259 5.295 3.52 8.466 3.516h12.076c1.296-17.221 16.208-30.122 33.306-28.822 15.304 1.166 27.466 13.412 28.624 28.822h12.08c3.172.004 6.218-1.257 8.466-3.516L67.483 34.265z" />
      <path fill="#000" d="M98.044 77.076h-9.566c-1.32 0-2.391-1.079-2.391-2.408s1.071-2.408 2.391-2.408h9.566c3.955 0 7.173-3.24 7.173-7.223V12.065c0-3.983-3.218-7.223-7.173-7.223H11.957c-3.956 0-7.174 3.236-7.174 7.223v52.972c0 3.982 3.219 7.223 7.174 7.223h9.565c1.32 0 2.391 1.074 2.391 2.408 0 1.334-1.071 2.408-2.391 2.408h-9.565C5.367 77.076 0 71.673 0 65.036V12.066C0 5.429 5.366.026 11.957.026h86.087C104.639.026 110 5.424 110 12.065v52.972c0 6.636-5.361 12.039-11.956 12.039z" />
      <path fill="#000" d="M55.005 43.439c-2.559 0-5.117-.805-7.303-2.413L10.56 14.016c-1.076-.78-1.315-2.283-.54-3.362.77-1.083 2.262-1.324 3.338-.544l37.16 27.02c2.693 1.98 6.285 1.98 8.959.01l37.165-27.03c1.072-.775 2.564-.54 3.339.54.774 1.078.53 2.585-.536 3.36l-37.16 27.02c-2.172 1.604-4.726 2.409-7.28 2.409zM11.957 67.445c-.612 0-1.225-.236-1.693-.704-.933-.939-.933-2.465 0-3.404l23.913-24.078c.932-.94 2.448-.94 3.38 0 .934.939.934 2.466 0 3.404L13.646 66.741c-.464.468-1.076.704-1.688.704zM98.044 67.445c-.613 0-1.225-.236-1.693-.704L72.438 42.663c-.933-.939-.933-2.465 0-3.404.932-.94 2.448-.94 3.38 0l23.914 24.078c.932.939.932 2.465 0 3.404-.464.468-1.076.704-1.688.704zM55.014 105.974c-.148 0-.296 0-.444-.005-7.026-.115-13.583-2.98-18.47-8.066-10.087-10.498-9.81-27.295.611-37.446 5.05-4.922 11.813-7.57 18.724-7.45 7.021.116 13.583 2.98 18.47 8.062 4.769 4.964 7.4 11.504 7.4 18.415 0 1.329-1.072 2.407-2.392 2.407-1.32 0-2.391-1.078-2.391-2.407 0-5.654-2.152-11.004-6.06-15.068-3.998-4.156-9.364-6.501-15.113-6.598-5.634 0-11.186 2.076-15.318 6.102-8.528 8.307-8.753 22.05-.498 30.637 3.998 4.16 9.365 6.506 15.113 6.597 5.768.202 11.182-2.07 15.319-6.096.947-.92 2.463-.901 3.381.057.918.959.895 2.48-.057 3.405-4.94 4.815-11.411 7.454-18.275 7.454z" />
      <path fill="#000" fillRule="nonzero" d="M55 91.523c-6.59 0-11.956-5.404-11.956-12.04 0-6.635 5.366-12.038 11.956-12.038s11.956 5.398 11.956 12.039c0 6.64-5.365 12.039-11.956 12.039zm0-19.263c-3.955 0-7.174 3.241-7.174 7.224 0 3.982 3.219 7.223 7.174 7.223 3.955 0 7.174-3.241 7.174-7.223 0-3.983-3.219-7.224-7.174-7.224z" />
      <path fill="#000" d="M71.74 91.523c-5.989 0-9.566-4.503-9.566-12.04 0-1.329 1.071-2.407 2.391-2.407s2.391 1.074 2.391 2.408c0 4.791 1.612 7.223 4.783 7.223 3.577 0 4.783-1.825 4.783-7.223 0-1.33 1.07-2.408 2.39-2.408s2.392 1.074 2.392 2.408c0 2.976 0 12.039-9.565 12.039z" />
    </g>
  </svg>

);

export default EmailIcon;
