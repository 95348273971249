import i18n from 'i18next';
import { notification } from 'antd';
import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  types,
  setCompanies,
} from './actions';
import { getCompaniesService } from '../../../../services/companyService';

function * getCompaniesSaga(action) {
  const { val, type } = action.payload;
  const { data, error } = yield call(
    getCompaniesService,
    { value: val, property: type, limit: 2000 },
  );
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setCompanies(data.data));
  }
}

export default function * companiesList() {
  yield all([
    takeLatest(types.GET_COMPANIES, getCompaniesSaga),
  ]);
}
