import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  sendMessage,
  getMessages,
  setVisibilityMessagesList,
  setVisibilityCompaniesList,
  setConversationId,
  setChatRoute,
  setCompanyChat
} from './modules/actions';
import { selectVisibilityCompaniesList, selectVisibilityMessagesList } from './modules/selectors';

const mapStateToProps = (state) => ({
  showMessagesList: selectVisibilityMessagesList(state),
  showCompaniesList: selectVisibilityCompaniesList(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      sendMessage,
      getMessages,
      setVisibilityMessagesList,
      setVisibilityCompaniesList,
      setConversationId,
      setChatRoute,
      setCompanyChat
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
