export const types = {
  GET_COMPANIES: 'GET_COMPANIES',
  SET_COMPANIES: 'SET_COMPANIES',
};

export const getCompanies = (val, type) => ({
  type: types.GET_COMPANIES,
  payload: {
    val,
    type,
  },
});

export const setCompanies = (companies) => ({
  type: types.SET_COMPANIES,
  payload: {
    companies,
  },
});
