import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { loginIcon } from 'images';
import classNames from 'classnames';
import styles from '../loginContainer.module.scss';
import LoginForm from '../loginForm';

const LoginAfterConfirmation = (props) => {
  const {
    actions,
  } = props;
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    actions.registrationConfirmation(parsed);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setIsPaused = (status) => {
    actions.setAnimationStatus(status);
  };

  return (
    <div className={classNames(styles.loginFormContainer, 'col-md-12')}>
      <div className={styles.loginHeader}>
        <img src={loginIcon} alt="login to bizbook" />
        <div className={styles.loginHeader}>
          <h1>{t('login headline')}</h1>
          <h2>{t('login tagline')}</h2>
        </div>
      </div>
      <div className={classNames(styles.loginHeader, 'col-lg-5 col-md-6 col-sm-6 col-12')}>
        <LoginForm actions={actions} setIsPaused={setIsPaused} />
      </div>
    </div>
  );
};

const { shape, func } = PropTypes;

LoginAfterConfirmation.propTypes = {
  actions: shape({
    registrationConfirmation:func.isRequired,
    login:func.isRequired,
    requestPasswordReset:func.isRequired,
    hideModal:func.isRequired,
    showModal:func.isRequired,
    setAnimationStatus:func.isRequired,
  }).isRequired,
};
export default LoginAfterConfirmation;
