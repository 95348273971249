import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectActiveLanguage } from 'components/Navbar/modules/selectors';
import { showModal } from './modules/actions';

const mapStateToProps = (state) => ({
  activeLanguage: selectActiveLanguage(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showModal,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
