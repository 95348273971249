import React, { useEffect, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import styles from './searchTab.module.scss';

const Searchtab = (props) => {
  const {
    handleSearchByName,
    countriesList,
    handleSearchByCountry,
    searchValue,
    activeCountry,
  } = props;
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { Option } = Select;

  useEffect(() => {
    setSearchInput(searchValue);
  }, [searchValue]);

  useEffect(() => {
    const findCountryByIso = (countryIso) =>
      countriesList.find((country) => country.get('ISO', '') === countryIso);
    setSelectedCountry(findCountryByIso(activeCountry));
  }, [activeCountry, countriesList]);

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.searchTabContainer}>
          <input
            type="text"
            placeholder={t('feed searcTab placeholder')}
            className={styles.searchInputStyle}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleSearchByName(searchInput);
              }
            }}
          />
          <SearchOutlined
            className={styles.searchOutlinedStyles}
            onClick={() => handleSearchByName(searchInput)}
          />
        </div>
        <Select
          defaultValue={t('feed countryDropdown placeholder')}
          value={selectedCountry?.get('name', '') || ''}
          className={styles.countryDropdownContainer}
          onChange={(e) => handleSearchByCountry(e)}
          bordered={false}
          showSearch
          clearIcon
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          <Option value="">
            {t('feed countryDropdown selectAllCountries')}
          </Option>
          {countriesList.size > 0 &&
            countriesList
              .sort((a, b) =>
                a.get('name', '').localeCompare(b.get('name', ''))
              )
              .map((country) => (
                <Option value={country.get('ISO', '')} key={country.get('ISO')}>
                  {country.get('name', '')}
                </Option>
              ))}
        </Select>
      </div>
      {/* <a
        href="https://www.bhtelecom.ba/usluge-za-privatne-korisnike/2023/10/novi-i-brzi-netflat-i-internet-povremeno-paketi/"
        className={styles.banner}
      >
        <img
          alt="banner"
          style={{ width: '100%' }}
          src="https://res.cloudinary.com/bizbook-blog/image/upload/v1696512159/Screenshot_2023_10_05_at_15_22_26_89785ee33d.png"
        />
      </a> */}
    </>
  );
};

const { func, string } = PropTypes;
const { list } = ImmutablePropTypes;

Searchtab.propTypes = {
  handleSearchByName: func.isRequired,
  handleSearchByCountry: func.isRequired,
  countriesList: list.isRequired,
  searchValue: string,
  activeCountry: string,
};

Searchtab.defaultProps = {
  searchValue: '',
  activeCountry: '',
};

export default Searchtab;
