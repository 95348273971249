import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selectBlogArticles, selectPromoArticles } from '../News/modules/selectors';
import { getBlogArticles } from '../News/modules/actions';
import { selectFeaturedProducts } from './modules/selectors';
import { getFeaturedProducts } from './modules/actions';
import { setNextRoute, setNextRouteParams, showModal } from '../Login/modules/actions';

const mapStateToProps = (state) => ({
  blogArticles: selectBlogArticles(state),
  promoArticles: selectPromoArticles(state),
  featuredProducts: selectFeaturedProducts(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    getBlogArticles,
    getFeaturedProducts,
    setNextRoute,
    setNextRouteParams,
    showModal,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
