import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, shape, string } from 'prop-types';
import { map } from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';

import Category from '../../CategoryDateElements/Category';
import DateContent from '../../CategoryDateElements/DateContent';
import { composeBlogArticleUrl } from '../../../../utils/pathsHelper';

import styles from './smallerArticles.module.scss';

const Article = (props) => {
  const { article, isHomepage, style } = props;
  const { t } = useTranslation();

  return (
    <div className={style.article}>
      <div className={style.imgContainer}>
        <img
          src={article.getIn(['imageSlider', 'images', 0, 'image', 'url'])}
          alt={article.get('title')}
        />
      </div>
      <div className={style.contentContainer}>
        <Category
          showCategory
          isBlogArticle={!isHomepage}
          category={article.getIn(['category', 'slug'])}
        />
        <Link to={composeBlogArticleUrl(article.get('slug'))} className={styles.title}>
          {article.get('title')}
        </Link>
        <DateContent date={article.get('date')} />
        <p className={styles.description}>
          {article.get('description')}
        </p>
        <Link to={composeBlogArticleUrl(article.get('slug'))} className={styles.link}>
          <button type="button" className={styles.readButton}>
            <span>
              {t('news button readArticle')}
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
};

Article.propTypes = {
  article: map.isRequired,
  isHomepage: bool.isRequired,
  style: shape({
    imgContainer: string.isRequired,
    contentContainer: string.isRequired,
  }).isRequired,
};

export default Article;
