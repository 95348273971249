import React from 'react';
import { useTranslation } from 'react-i18next';

import { aboutBizbookImg, bizbookComputerIcon } from '../../../images';
import homeStyles from '../home.module.scss';
import styles from './about.module.scss';

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={styles.aboutComponent}>
        <div className={styles.aboutContainer}>
          <h3 className={homeStyles.homeSubtitle}>
            {t('homepage about tagline')}
          </h3>
          <h1 className={homeStyles.homeTitle}>
            {t('homepage about headline')}
          </h1>
          <hr />
          <div className={styles.aboutContent}>
            <div className={styles.aboutBizbookImg}>
              <img src={aboutBizbookImg} alt="Bizbook mobile login" />
            </div>
            <div className={styles.descriptionContainer}>
              <img src={bizbookComputerIcon} alt="Bizbook computer icon" className={styles.bizbookComputerIcon} />
              <h2 className={styles.title}>
                {t('homepage about title')}
              </h2>
              <div className={styles.divider} />
              <p className={styles.description}>
                {t('homepage about text')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
