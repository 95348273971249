import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import styles from './mobileFilters.module.scss';

const MobileFilters = (props) => {
  const {
    countriesList,
    handleSearchByCountry,
    suggestedTags,
    updateTagsQueryData,
    activeTagId,
    resetFilters,
    searchByType,
    queryDataType,
  } = props;
  const { t } = useTranslation();
  const [filterIsActive, setFilterActiveStatus] = useState(true);

  const resetFilter = () => {
    resetFilters();
    setFilterActiveStatus(true);
  };

  const filtersMenu = (
    <Menu>
      <Menu.Item
        value="reset"
        className={classNames(filterIsActive && 'font-weight-bold')}
        onClick={resetFilter}
      >
        {t('feed filters dropdownTitle showAll')}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          searchByType('product');
          setFilterActiveStatus(false);
        }}
        className={classNames(
          queryDataType === 'product' && 'font-weight-bold',
        )}
      >
        {t('feed filters dropdownTitle productsServices')}
      </Menu.Item>
      <Menu.Item
        className={classNames(queryDataType === 'demand' && 'font-weight-bold')}
        onClick={() => {
          searchByType('demand');
          setFilterActiveStatus(false);
        }}
      >
        {t('feed filters dropdownTitle demand')}
      </Menu.Item>
      <Menu.Item
        className={classNames(queryDataType === 'companies' && 'font-weight-bold')}
        onClick={() => {
          searchByType('companies');
          setFilterActiveStatus(false);
        }}
      >
        {t('feed filters dropdownTitle companies')}
      </Menu.Item>
    </Menu>
  );

  const tagsMenu = suggestedTags.size > 0 && (
    <Menu>
      {suggestedTags.map((tag) => (
        <Menu.Item
          value={tag.get('key', '')}
          key={tag.get('key')}
          className={classNames(
            activeTagId === tag.get('key') && styles.activeTagStyle,
          )}
          onClick={() => {
            updateTagsQueryData(tag.get('key'));
            setFilterActiveStatus(false);
          }}
        >
          {tag.get('value', '')}
        </Menu.Item>
      ))}
    </Menu>
  );

  const countrySelectMenu = (
    <Menu>
      <Menu.Item onClick={() => handleSearchByCountry('')}>
        {t('feed countryDropdown selectAllCountries')}
      </Menu.Item>
      {countriesList.size > 0 &&
        countriesList.map((country) => (
          <Menu.Item
            key={country.get('ISO')}
            onClick={() => handleSearchByCountry(country.get('ISO', ''))}
          >
            {country.get('name', '')}
          </Menu.Item>
        ))}
    </Menu>
  );

  const renderDropdownMenu = (menu, dropdownTitle, overlayClassName) => (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      overlayClassName={overlayClassName}
    >
      <div
        className={styles.dropdownMenuContainer}
        onClick={(e) => e.preventDefault()}
        role="presentation"
      >
        <h4>{dropdownTitle}</h4>
        <DownOutlined />
      </div>
    </Dropdown>
  );

  return (
    <div className={styles.mobileFiltersContainer}>
      {renderDropdownMenu(filtersMenu, t('feed filters title'))}
      {renderDropdownMenu(tagsMenu, t('feed mobile tagsSelect placeholder'))}
      {renderDropdownMenu(
        countrySelectMenu,
        t('feed mobile countrySelect placeholder'),
        styles.overlay,
      )}
    </div>
  );
};

const { func, number, string } = PropTypes;
const { list } = ImmutablePropTypes;

MobileFilters.propTypes = {
  handleSearchByCountry: func.isRequired,
  countriesList: list.isRequired,
  suggestedTags: list.isRequired,
  updateTagsQueryData: func.isRequired,
  activeTagId: number,
  resetFilters: func.isRequired,
  searchByType: func.isRequired,
  queryDataType: string,
};

MobileFilters.defaultProps = {
  activeTagId: null,
  queryDataType: '',
};

export default MobileFilters;
