import React from 'react';
import PropTypes from 'prop-types';

import RegisterComponent from './RegisterComponent';
import InterestsComponent from './InterestsComponent';

const RegisterStep = (props) => {
  const {
    handleRegister,
    setActiveComponent,
    addInterests,
    registerData,
    isRegister,
  } = props;

  if (isRegister) {
    return (
      <RegisterComponent
        handleRegister={handleRegister}
        registerData={registerData}
      />
    );
  }
  return (
    <InterestsComponent
      setActiveComponent={setActiveComponent}
      addInterests={addInterests}
    />
  );
};

const { object, func, bool } = PropTypes;

RegisterStep.propTypes = {
  handleRegister: func.isRequired,
  setActiveComponent: func.isRequired,
  addInterests: func.isRequired,
  registerData: object.isRequired,
  isRegister: bool.isRequired,
};

export default RegisterStep;
