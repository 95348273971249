import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectBlogArticleBySlug } from '../modules/selectors';
import {
  getBlogArticles,
  increaseArticleViewCount,
  setBlogArticleSlug,
  setSelectedCategory,
} from '../modules/actions';

const mapStateToProps = (state) => ({
  article: selectBlogArticleBySlug(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    getBlogArticles,
    setBlogArticleSlug,
    increaseArticleViewCount,
    setSelectedCategory,
  },
  dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
