import React, { useEffect, useState } from 'react';
import { shape, func, string } from 'prop-types';

import { categories } from '../utils/categories';
import CategoryTag from './CategoryTag';
import styles from './categoryBanner.module.scss';

const CategoryBanner = (props) => {
  const { selectedCategory } = props;
  const [selected, setSelected] = useState(selectedCategory);

  useEffect(() => {
    setSelected(selectedCategory);
  }, [selectedCategory]);

  return (
    <div className={styles.categoryBanner}>
      {categories.map((category) => (
        <CategoryTag
          key={category.value}
          category={category.name}
          selected={selected === category.value}
          value={category.value}
          setSelectedCategory={props.actions.setSelectedCategory}
        />
      ))}
    </div>
  );
};

CategoryBanner.propTypes = {
  actions: shape({
    setSelectedCategory: func.isRequired,
  }).isRequired,
  selectedCategory: string.isRequired,
};

export default CategoryBanner;
