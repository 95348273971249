/* eslint-disable no-nested-ternary */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { arrowRight } from 'images/createCompany';
import { handleRedirect } from 'utils/constants';
import styles from './productsAndServicesHeader.module.scss';

const ProductsAndServicesHeader = (props) => {
  const {
    title, description, textAlignment, colorTheme, fontFamily, companySlug, actions
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const showProducts = () => {
    handleRedirect(history, `/products/${companySlug}`, actions);
  };
  return (
    <div className={styles.productsAndServicesHeaderContainer}>
      <div
        className={classNames(
          'container',
          styles.productsAndServicesHeaderContent,
          textAlignment === 'text-center'
            ? 'align-items-center'
            : textAlignment === 'text-right'
              ? 'align-items-end'
              : '',
        )}
      >
        <h4>{t('createCompany productsAndServices headerTitleText')}</h4>
        <h2
          className={classNames(
            styles.inputSectionTitle,
            textAlignment,
            styles.sectionTextViewMode,
          )}
          style={{ color: colorTheme, fontFamily }}
        >
          {title}
        </h2>

        <div className={classNames(styles.horizontalBreakline, 'mx-2')} />
        <div
          className={classNames(
            styles.flexContainer,
            textAlignment === 'text-center'
              ? 'ml-auto'
              : textAlignment === 'text-right'
                ? 'flex-row-reverse'
                : '',
          )}
        >
          <div className={styles.descriptionSectionContainer}>
            <h5
              className={classNames(
                styles.inputSectionDescription,
                textAlignment,
                styles.sectionTextViewMode,
              )}
            >
              {description}
            </h5>
          </div>

          <div
            className={styles.navigationRightContainer}
            role="presentation"
            onClick={() => showProducts()}
          >
            <h5>
              {t(
                'createCompany productsAndServices navigationRight subtitleText',
              )}
              <img
                src={arrowRight}
                alt="Our products and services"
                className="ml-1"
              />
            </h5>
            <h3>
              {t('createCompany productsAndServices navigationRight titleText')}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

const { string, shape, func } = PropTypes;

ProductsAndServicesHeader.propTypes = {
  title: string,
  description: string,
  textAlignment: string.isRequired,
  colorTheme: string.isRequired,
  fontFamily: string.isRequired,
  companySlug: string.isRequired,
  actions: shape({
    showModal: func.isRequired,
  }).isRequired,
};
ProductsAndServicesHeader.defaultProps = {
  title: '',
  description: '',
};

export default ProductsAndServicesHeader;
