import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  updateCompanyJson,
} from 'actions/auth';
import { selectCountryIsoCode } from 'selectors/auth';

const mapStateToProps = (state) => ({
  countryIsoCode: selectCountryIsoCode(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateCompanyJson,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
