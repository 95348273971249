import React from 'react';
import { string } from 'prop-types';

const HomeIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 57.238 52.492"
  >
    <g
      id="prefix__Group_642"
      data-name="Group 642"
      transform="translate(-792.018 -178.14)"
    >
      <g
        id="prefix__Group_34"
        data-name="Group 34"
        transform="translate(712.198 -8.855)"
      >
        <path
          id="prefix__Path_44"
          d="M115.407 218.718h-13.778a2.366 2.366 0 0 0-2.366 2.366v14.351h4v-12.717h12.144v12.717h4v-14.351a2.366 2.366 0 0 0-2.366-2.366z"
          className="prefix__cls-1"
          data-name="Path 44"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_45"
          d="M136.542 208.933L125.1 196.282l-1.558-1.722-12.8-7.225a2.615 2.615 0 0 0-2.574 0l-12.85 7.533-1.415 1.571c-.047.043-.1.079-.143.127l-11.62 12.34a2 2 0 0 0 2.608 3v20.924a5.077 5.077 0 0 0 5.077 5.077h39.269a5.078 5.078 0 0 0 5.077-5.077v-20.766a2 2 0 0 0 2.228-.3 2 2 0 0 0 .143-2.831zm-6.371 23.894a1.077 1.077 0 0 1-1.077 1.077H89.825a1.077 1.077 0 0 1-1.077-1.077v-24.976l9.11-9.824 11.361-6.689a.491.491 0 0 1 .484 0l11.308 6.384 1.123 1.242 4.249 4.7 3.787 4.188v24.974z"
          data-name="Path 45"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_46"
          d="M109.335 214.318a7.466 7.466 0 1 1 7.467-7.467 7.476 7.476 0 0 1-7.467 7.467zm0-10.933a3.466 3.466 0 1 0 3.467 3.466 3.471 3.471 0 0 0-3.467-3.466z"
          className="prefix__cls-1"
          data-name="Path 46"
          style={{ fill: color }}
        />
      </g>
      <path
        id="prefix__Path_47"
        d="M123.31 197.865l-1.558-1.722-12.8-7.225a2.615 2.615 0 0 0-2.574 0l-12.85 7.533-1.415 1.571a1.879 1.879 0 0 0-.142.126l-11.622 12.341a2 2 0 0 0 2.608 3v20.921a5.077 5.077 0 0 0 5.077 5.077H127.3a5.077 5.077 0 0 0 5.077-5.077v-20.763a2 2 0 0 0 2.228-.3 2 2 0 0 0 .142-2.825zm-9.695 37.622h-12.144V224.3h12.144zm14.764-1.077a1.077 1.077 0 0 1-1.077 1.077h-9.687v-12.82a2.366 2.366 0 0 0-2.366-2.366H99.837a2.366 2.366 0 0 0-2.366 2.366v12.82h-9.438a1.077 1.077 0 0 1-1.077-1.077v-24.975l9.11-9.824 11.361-6.689a.491.491 0 0 1 .484 0l11.308 6.384 1.123 1.242 4.25 4.7 3.787 4.188v24.974z"
        className="prefix__cls-3"
        data-name="Path 47"
        transform="translate(712.198 -8.855)"
        style={{ fill: color, opacity: 0.2 }}
      />
      <path
        id="prefix__Path_48"
        d="M107.543 200.969a7.466 7.466 0 1 0 7.467 7.466 7.476 7.476 0 0 0-7.467-7.466zm0 10.932a3.466 3.466 0 1 1 3.467-3.467 3.472 3.472 0 0 1-3.467 3.466z"
        className="prefix__cls-3"
        data-name="Path 48"
        transform="translate(712.198 -8.855)"
        style={{ fill: color, opacity: 0.2 }}
      />
    </g>
  </svg>
);

HomeIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

HomeIcon.defaultProps = {
  color: '#37ead9',
  width: '57.238',
  height: '52.492',
};

export default HomeIcon;
