
import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import styles from './createComment.module.scss';
import buttonStyles from 'utils/buttonStyle.module.scss';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const CreateComment = (props) => {
    const {
        handleCreateComment,
    } = props;

    const { t } = useTranslation();
    const [isValid, setIsValid] = useState(true)
    const textArea = useRef(null);

    const handleClick = () => {
        const content = textArea.current?.value;
        if (content && content.trim()) {
            handleCreateComment(content);
            textArea.current.value = "";
        }
        else {
            setIsValid(false)
            textArea.current.focus();
        }
    }

    return (
        <div className={styles.createCommentContainer}>
            <h5 className={styles.title}>{t('comments writeNewOne')}</h5>
            <textarea
                className={classNames(
                    styles.commentArea,
                    !isValid && styles.commentAreaNotValid
                )}
                onMouseEnter={() => setIsValid(true)}
                ref={textArea}
            />
            <button
                type="button"
                className={classNames(
                    buttonStyles.mainButtonStyle,
                    styles.createCommentButton
                )}
                onClick={handleClick}
            >
                {t('comment create sendButton')}
            </button>
        </div>
    )
}

const { func } = PropTypes;

CreateComment.propTypes = {
    handleCreateComment: func.isRequired
}

export default CreateComment;