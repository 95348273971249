import React from 'react';
import { string } from 'prop-types';

const MessageIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
    <g fill="none" fillRule="evenodd">
      <path fill={color} fillRule="nonzero" d="M19 4H5C3.343 4 2 5.343 2 7v10c0 1.657 1.343 3 3 3h14c1.657 0 3-1.343 3-3V7c0-1.657-1.343-3-3-3zm-.67 2L12 10.75 5.67 6h12.66zM19 18H5c-.552 0-1-.448-1-1V7.25l7.4 5.55c.173.13.384.2.6.2.216 0 .427-.07.6-.2L20 7.25V17c0 .552-.448 1-1 1z" transform="translate(-2 -4)" />
    </g>
  </svg>
);

MessageIcon.propTypes = {
  color: string.isRequired,
};

export default MessageIcon;
