import { fromJS } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  createNewCompanyModalVisible: false,
  adminStatistic: {},
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_ADMIN_STATISTIC:
      return state.set('adminStatistic', fromJS(action.payload.content))
    case types.SHOW_REGISTER_NEW_COMPANY_MODAL:
      return state.set('createNewCompanyModalVisible', true);
    case types.HIDE_REGISTER_NEW_COMPANY_MODAL:
      return state.set('createNewCompanyModalVisible', false);
    default:
      return state;
  }
};
