import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import StackGrid from 'react-stack-grid';
import BottomScrollListener from 'react-bottom-scroll-listener';
import { emptySearch, jumpToTop, infinityLoader } from 'images';
import { debounce } from 'lodash';
import { scrollToTop, handleCardsLayout } from 'utils/constants';
import { fromJS } from 'immutable';
import queryString from 'query-string';
import { feedUrl } from 'utils/pathsHelper';
import FeedCard from './FeedCard/FeedCard';
import CompanyCard from './CompanyCard/CompanyCard';
import Filters from './Filters/Filters';
import SearchTab from './SearchTab/Searchtab';
import MobileFilters from './MobileFilters/MobileFilters';
import styles from './feed.module.scss';
import history from '../../history';
import checkFeedFilterType from './checkFeedFilterType-helper';

const Feed = (props) => {
  const {
    actions,
    feedContent,
    loaderVisible,
    suggestedTags,
    countriesList,
    industriesList,
    queryData,
    location: { pathname, search },
    hasMore,
  } = props;
  const { t } = useTranslation();
  const [jumpTopTopVisible, setJumpToTopVisible] = useState(false);
  const [cardWidth, setCardWidth] = useState(
    handleCardsLayout(window.innerWidth),
  );

  useEffect(() => {
    actions.activateLoader();
    actions.clearActiveItem();
    actions.getSuggestedTags();
    if (countriesList.size < 1) {
      actions.getCountriesList();
    }
    if (industriesList.size < 1) {
      actions.getIndustriesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (queryData.get('page', '') === 1) {
      actions.getFeedContent(queryData.toJS());
    } else if (hasMore) {
      actions.loadMoreContent(queryData.toJS());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  const handleIncreasePage = () => {
    actions.setQueryData(queryData.set('page', queryData.get('page', '') + 1));
  };
  const debouncedHandleIncreasePage = debounce(handleIncreasePage, 1000);

  const increaseFeedLimit = () => {
    actions.activateLoader();
    debouncedHandleIncreasePage();
    if (!jumpTopTopVisible) setJumpToTopVisible(true);
  };

  const memoizedFeedContent = useMemo(() => feedContent, [feedContent]);

  const updateTagsQueryData = (tagId) => {
    const tagIds = queryData.get('tagIds', '');
    const tagExists = tagIds === tagId;
    if (tagExists) {
      actions.setQueryData(queryData.filter((value, key) => key !== 'tagIds'));
    } else {
      actions.setQueryData(queryData.merge({ limit: 15, page: 1, tagIds: tagId }));
    }
  };

  const resetFilters = () => {
    const queryObject = queryString.parse(search);
    actions.setQueryData(fromJS({
      limit: 15,
      page: 1,
      name: queryObject?.name,
      industryId: queryObject?.industryId,
      tagIds: queryObject?.tagIds,
      countryIso: queryObject?.countryIso,
    }));
  };

  const handleSearchByName = (name) => {
    if (name.length > 0) {
      actions.setQueryData(queryData.merge({ limit: 15, page: 1, name }));
    } else {
      actions.setQueryData(queryData.filter((value, key) => key !== 'name'));
    }
  };

  const handleSearchByCountry = (countryIso) => {
    if (countryIso.length > 0) {
      actions.setQueryData(queryData.merge({ limit: 15, page: 1, countryIso }));
    } else {
      actions.setQueryData(queryData.filter((value, key) => key !== 'countryIso'));
    }
  };

  useEffect(() => {
    const updateWidth = () => {
      setCardWidth(handleCardsLayout(window.innerWidth));
    };
    const debounceUpdateWidth = debounce(updateWidth, 200);
    window.addEventListener('resize', debounceUpdateWidth);
    return () => window.removeEventListener('resize', debounceUpdateWidth);
  }, []);

  const searchByType = (type) => {
    if (type.length > 0) {
      if (type === 'companies') {
        actions.setQueryData(queryData.filter((value, key) => key !== 'tagIds').merge({ limit: 15, page: 1, type }));
      } else {
        actions.setQueryData(queryData.merge({ limit: 15, page: 1, type }));
      }
    } else {
      actions.setQueryData(queryData.filter((value, key) => key !== 'type'));
    }
  };

  const searchByIndustry = (industryObject) => {
    if (industryObject.size > 0) {
      actions.setQueryData(
        queryData.merge({
          limit: 15,
          page: 1,
          industryId: industryObject.first().key,
        }),
      );
    } else {
      actions.setQueryData(queryData.filter((value, key) => key !== 'industryId'));
    }
  };

  useEffect(() => {
    resetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const queryObject = queryString.parse(search);
    actions.setQueryData(
      fromJS({
        limit: 15,
        page: 1,
        type: queryObject?.type,
        industryId: queryObject?.industryId,
        countryIso: queryObject?.countryIso,
        tagIds: queryObject?.tagIds,
        name: queryObject?.name,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (queryData.get('page', '') === 1) {
      history.push(`${feedUrl}?${queryString.stringify(queryData.toJS())}`);
    }
  }, [queryData]);

  return (
    <div className="container">
      <div className={classNames(styles.feedContainer)}>
        <MobileFilters
          countriesList={countriesList}
          handleSearchByCountry={handleSearchByCountry}
          suggestedTags={suggestedTags}
          updateTagsQueryData={updateTagsQueryData}
          activeTagId={queryData.get('tagIds', null)}
          resetFilters={resetFilters}
          searchByType={searchByType}
          queryDataType={queryData.get('type', '')}
        />
        <SearchTab
          searchValue={queryData.get('name', '')}
          handleSearchByName={handleSearchByName}
          countriesList={countriesList}
          handleSearchByCountry={handleSearchByCountry}
          activeCountry={queryData.get('countryIso', '')}
        />
        <StackGrid
          columnWidth={cardWidth}
          className="mb-5"
          gutterWidth={23}
          gutterHeight={35}
          duration={650}
        >
          <Filters
            suggestedTags={checkFeedFilterType(queryData.get('type', '')) ? suggestedTags : []}
            updateTagsQueryData={updateTagsQueryData}
            activeTagId={queryData.get('tagIds', null)}
            resetFilters={resetFilters}
            searchByType={searchByType}
            searchByIndustry={searchByIndustry}
            queryDataType={queryData.get('type', '')}
            industriesList={industriesList}
            activeIndustry={queryData.get('industryId', null)}
          />

          {queryData.get('type', '') === 'companies'
            ? memoizedFeedContent.map((item) => (
              <CompanyCard
                item={item}
                key={item.get('id')}
                actions={actions}
              />
            ))
            : memoizedFeedContent.map((item) => (item.get('type', '') ? (
              <FeedCard item={item} key={`p${item.get('id')}`} actions={actions} />
            ) : (
              <CompanyCard
                item={item}
                key={`c${item.get('id')}`}
                actions={actions}
              />
            )))}
        </StackGrid>
        {feedContent.size === 0 && !loaderVisible && (
          <div className={styles.emptySearchContainer}>
            <img src={emptySearch} alt="Empty search" />
            <h6>{t('feed search noResultsTitle')}</h6>
            <p>{t('feed search noResultsTagline')}</p>
          </div>
        )}
        {
          <img
            src={infinityLoader}
            alt="loader"
            className={classNames(
              styles.imageloaderStyle,
              loaderVisible && feedContent.size === 0 ? '' : 'invisible',
            )}
          />
        }
        {
          <img
            src={infinityLoader}
            alt="loader"
            className={classNames(
              styles.imageloaderStyle,
              loaderVisible && feedContent.size > 0 && hasMore ? '' : 'invisible',
            )}
          />
        }
        {/* jump to to button */}
        {jumpTopTopVisible && (
          <img
            src={jumpToTop}
            alt="jump to top"
            className={styles.jumpTopTop}
            role="presentation"
            onClick={scrollToTop}
          />
        )}
        {!loaderVisible && (
          <BottomScrollListener onBottom={increaseFeedLimit} />
        )}
      </div>
    </div>
  );
};

const {
  shape, func, bool, string,
} = PropTypes;
const { list, map } = ImmutablePropTypes;

Feed.propTypes = {
  actions: shape({
    getFeedContent: func.isRequired,
    loadMoreContent: func.isRequired,
    getSuggestedTags: func.isRequired,
    getCountriesList: func.isRequired,
    clearActiveItem: func.isRequired,
    getIndustriesList: func.isRequired,
    setQueryData: func.isRequired,
    showModal: func.isRequired,
  }).isRequired,
  feedContent: list.isRequired,
  loaderVisible: bool.isRequired,
  suggestedTags: list.isRequired,
  countriesList: list.isRequired,
  industriesList: list.isRequired,
  queryData: map.isRequired,
  hasMore: bool.isRequired,
  location: shape({
    pathname: string.isRequired,
    search: string.isRequired,
  }).isRequired,
};

export default Feed;
