import React from 'react';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { messengerAppId } from 'utils/constants';

import styles from './socialShare.module.scss';

const SocialShare = (props) => {
  const { url, className } = props;

  const openMobileMessenger = (shareUrl, appId) => {
    window.open(`fb-messenger://share?link=${encodeURIComponent(shareUrl)}&app_id=${encodeURIComponent(appId)}`);
  };

  return (
    <div className={classNames(styles.socialShare, className)}>
      <FacebookShareButton url={url}>
        <FacebookIcon round size={32} />
      </FacebookShareButton>
      {isMobile ? (
        <button
          type="button"
          className={styles.clearShareBtn}
          onClick={() => openMobileMessenger(url, messengerAppId)}
        >
          <FacebookMessengerIcon round size={32} />
        </button>
      ) : (
        <FacebookMessengerShareButton appId={messengerAppId} url={url}>
          <FacebookMessengerIcon round size={32} />
        </FacebookMessengerShareButton>
      )}

      <TwitterShareButton url={url}>
        <TwitterIcon round size={32} />
      </TwitterShareButton>
      <LinkedinShareButton url={url}>
        <LinkedinIcon round size={32} />
      </LinkedinShareButton>
    </div>
  );
};

const { string } = PropTypes;

SocialShare.propTypes = {
  url: string.isRequired,
  className: string,
};

SocialShare.defaultProps = {
  className: '',
};

export default SocialShare;
