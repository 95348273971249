import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { layoutPositions } from 'utils/parse-helper';
import { List as iList } from 'immutable';
import ProductItem from './ProductItem';
import styles from './whatWeDo.module.scss';

const WhatWeDo = (props) => {
  const {
    productsAndServices, textAlignment, colorTheme, fontFamily,
  } = props;
  const { t } = useTranslation();

  return (
    <div className={classNames('container pb-0 pb-md-5', styles.whatWeDoMainContainer)}>
      <h4 style={{ color: colorTheme, fontFamily, textAlign: `${textAlignment.split('-')[1]}` }}>
        {t('createCompany whatWeDo titleText')}
      </h4>
      <div className={styles.productsAndServicesContainer}>
        {productsAndServices
          && productsAndServices
            .filter(
              (item) => item.get('title', '') && item.get('description', ''),
            )
            .map(
              (item) => item.get('title', '') && (
              <div
                className={classNames(
                  styles.productItemContainer,
                  'col-12',
                  layoutPositions(
                    productsAndServices.filter(
                      (productItem) => productItem.get('title', '')
                            && productItem.get('description', ''),
                    ).size,
                  ),
                )}
                key={item.get('key', '')}
              >
                <ProductItem
                  textAlignment={textAlignment}
                  title={item.get('title', '')}
                  description={item.get('description', '')}
                  itemKey={item.get('key', '')}
                />
              </div>
              ),
            )}
      </div>
    </div>
  );
};

const { node, string } = PropTypes;

WhatWeDo.propTypes = {
  productsAndServices: node,
  textAlignment: string.isRequired,
  colorTheme: string.isRequired,
  fontFamily: string.isRequired,
};

WhatWeDo.defaultProps = {
  productsAndServices: iList(),
};

export default WhatWeDo;
