export const types = {
  GET_INTERESTS: 'GET_INTERESTS',
  SET_INTERESTS: 'SET_INTERESTS',
  SAVE_INTERESTS: 'SAVE_INTERESTS',
  DELETE_INTEREST: 'DELETE_INTEREST',
  SET_INDUSTRY_INTERESTS: 'SET_INDUSTRY_INTERESTS',
  GET_INDUSTRY_INTERESTS: 'GET_INDUSTRY_INTERESTS',
  SAVE_INDUSTRY_INTERESTS: 'SAVE_INDUSTRY_INTERESTS',
};

export const getInterests = (queryString) => ({
  type: types.GET_INTERESTS,
  payload: {
    queryString,
  },
});

export const setInterests = (data) => ({
  type: types.SET_INTERESTS,
  payload: {
    data,
  },
});

export const saveInterests = (postData) => ({
  type: types.SAVE_INTERESTS,
  payload: {
    postData,
  },
});

export const deleteInterest = (id) => ({
  type: types.DELETE_INTEREST,
  payload: {
    id,
  },
});

/**
 * @function setIndustryInterests
 * gets industry interests based on id of the company
 * @param {object} payload
 * @return FSA
 */
export const getIndustryInterests = (payload = {}) => ({
  type: types.GET_INDUSTRY_INTERESTS,
  payload,
});

/**
 * @function setIndustryInterests
 * gets industry interests based on id of the company
 * @param {{ industryInterests: IList }} payload
 * @return FSA
 */
export const setIndustryInterests = (payload) => ({
  type: types.SET_INDUSTRY_INTERESTS,
  payload,
});

/**
 * @function saveIndustryInterests
 * @param {{ postData }} payload
 * @return FSA
 */
export const saveIndustryInterests = (payload) => ({
  type: types.SAVE_INDUSTRY_INTERESTS,
  payload,
});

export default types;
