export const types = {
  SEND_RESET_PASSWORD_EMAIL: 'SEND_RESET_PASSWORD_EMAIL',
  RESET_PASSWORD: 'RESET_PASSWOR',
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  SET_ANIMATION_STATUS: 'SET_ANIMATION_STATUS',
  REGISTRATION_CONFIRMATION: 'REGISTRATION_CONFIRMATION',
  SEND_REACTIVATE_EMAIL: 'SEND_REACTIVATE_EMAIL',
  SET_NEXT_ROUTE_PARAMS: 'SET_NEXT_ROUTE_PARAMS',
  SET_NEXT_ROUTE: 'SET_NEXT_ROUTE',
};

export const setNextRoute = (nextRoute) => ({
  type: types.SET_NEXT_ROUTE,
  payload: {
    nextRoute,
  },
});

export const setNextRouteParams = (nextRouteParams) => ({
  type: types.SET_NEXT_ROUTE_PARAMS,
  payload: {
    nextRouteParams,
  },
});

export const requestPasswordReset = (email) => ({
  type: types.SEND_RESET_PASSWORD_EMAIL,
  payload: {
    email,
  },
});

export const reactivateEmail = (email) => ({
  type: types.SEND_REACTIVATE_EMAIL,
  payload: {
    email,
  },
});

export const resetPassword = (email, password, token) => ({
  type: types.RESET_PASSWORD,
  payload: {
    email,
    password,
    token,
  },
});

export const hideModal = () => ({
  type: types.HIDE_MODAL,
  payload: {},
});
export const registrationConfirmation = (userData) => ({
  type: types.REGISTRATION_CONFIRMATION,
  payload: { userData },
});

export const showModal = () => ({
  type: types.SHOW_MODAL,
  payload: {},
});

export const setAnimationStatus = (status) => ({
  type: types.SET_ANIMATION_STATUS,
  payload: {
    status,
  },
});

export default types;
