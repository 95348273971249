import React from 'react';

export const packageOptions = [
  { value: 1, name: 'Početni paket' },
  { value: 2, name: 'Poduzetnički paket' },
  { value: 3, name: 'Promotivni paket' },
  { value: 4, name: 'Prodajni paket' },
];

export const paidStatusOptions = [
  { value: 1, name: 'Paid' },
  { value: 2, name: 'Unpaid' },
  { value: 3, name: 'Trial' },
];

export const EditableContext = React.createContext(null);

export const columnsConstants = (getColumnSearchProps) => ([
  {
    title: 'Company name',
    dataIndex: 'name',
    key: 'name',
    editable: true,
    ...getColumnSearchProps('name'),
    fixed: 'left',
    width: 180,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    editable: true,
    ...getColumnSearchProps('email'),
  },
  {
    title: 'Industry',
    dataIndex: 'industry',
    key: 'industry',
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: 'Phone number',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Subscription',
    dataIndex: 'subscription',
    key: 'subscription',
  },
  {
    title: 'Subscription End Date',
    dataIndex: 'subscriptionEndDate',
    key: 'subscriptionEndDate',
  },
  {
    title: 'Paid status',
    dataIndex: 'paidStatus',
    key: 'paidStatus',
  },
  {
    title: 'Web shop',
    dataIndex: 'shop',
    key: 'shop',
    editable: true,
  },
  {
    title: 'Verification',
    dataIndex: 'verification',
    key: 'verification',
    width: 120,
  },
  {
    title: 'Delete company',
    dataIndex: 'delete',
    key: 'delete',
    width: 110,
  },
  {
    title: 'Block company',
    dataIndex: 'block',
    key: 'block',
    width: 110,
  },
]);
