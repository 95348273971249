import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { loader } from 'images';
import classNames from 'classnames';
import { map } from 'images/createCompany';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImgCrop from 'antd-img-crop';
import styles from '../aboutCompany.module.scss';
import { imageUploadMaxLimit } from '../../../utils/constants';

const UploadComponent = (props) => {
  const {
    imageKey, imageUrl, customClass, uploadAboutCompanyImage, setAboutUsSaved,
  } = props;
  const { t } = useTranslation();
  const [uploading, setUploadingStatus] = useState(false);

  const handleImageUpload = (data) => {
    if (data.file.status === 'uploading') {
      setUploadingStatus(true);
    }
  };
  const renderImageComponent = (isUploading, imgUrl) => {
    if (isUploading) {
      return (
        <div className={styles.loaderContainer}>
          <LazyLoadImage
            alt="Loader"
            effect="blur"
            src={loader}
            className={styles.loaderImgStyle}
          />
        </div>
      );
    }
    return (
      <LazyLoadImage
        alt="Add img"
        effect="blur"
        src={imgUrl || map}
        className={classNames(
          styles.mainImageStyle,
          customClass && styles.rowImageStyle,
        )}
        placeholderSrc={map}
      />
    );
  };

  useEffect(() => {
    setUploadingStatus(false);
    setAboutUsSaved(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl]);

  return (
    <div className={classNames(styles.imageUploadContainer, customClass)}>
      {renderImageComponent(uploading, imageUrl)}
      <ImgCrop
        modalTitle={t('imageCropperModal modalTitle')}
        modalOk={t('imageCropperModal okButton')}
        modalCancel={t('imageCropperModal cancelButton')}
        grid={false}
        shape="rect"
        fillColor="rgba(255, 255, 255, 0.0)"
        minZoom={0.5}
        cropperProps={{ restrictPosition: false }}
      >
        <Upload
          name="avatar"
          listType="picture-card"
          showUploadList={false}
          onChange={handleImageUpload}
          className={classNames(styles.imageUploadButtonContainer)}
          beforeUpload={(file) => {
            const isImageFile = file.type.includes('image');
            if (!isImageFile) {
              notification.error({
                message: t('notification error'),
                description: t('imageUpload typeImageError'),
              });
              setTimeout(() => setUploadingStatus(false), 1000);
            }
            return isImageFile;
          }}
          customRequest={(options) => {
            const isLimitSizeExceeded = options.file.size / 1024 / 1024 >= imageUploadMaxLimit;
            if (isLimitSizeExceeded) {
              notification.error({
                message: t('notification error'),
                description: t('imageUpload fileTooLarge'),
              });
              setTimeout(() => setUploadingStatus(false), 1000);
            } else {
              options.onSuccess(uploadAboutCompanyImage(imageKey, options));
            }
          }}
        >
          <button
            type="button"
            className={classNames(
              styles.addPictureButtonStyle,
              uploading && 'invisible',
            )}
          >
            {imageUrl
              ? t('aboutCompany uploadPicture changePicture')
              : t('aboutCompany uploadPicture addPicture')}
          </button>
        </Upload>
      </ImgCrop>
    </div>
  );
};

const { string, func } = PropTypes;

UploadComponent.propTypes = {
  imageUrl: string,
  imageKey: string.isRequired,
  customClass: string,
  uploadAboutCompanyImage: func.isRequired,
  setAboutUsSaved: func.isRequired,
};

UploadComponent.defaultProps = {
  customClass: '',
  imageUrl: '',
};

export default UploadComponent;
