import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import ConversationList from './ConversationList';
import MessageList from './MessageList';
import './Messenger.scss';
import CompaniesList from './CompaniesList';

const Messenger = (props) => {
  const {
    actions,
    showMessagesList,
    showCompaniesList,
  } = props;

  useEffect(() => () => {
    actions.setConversationId(null);
    actions.setVisibilityCompaniesList(false);
    actions.setChatRoute('', null);
    actions.setCompanyChat({
      id: '',
      name: '',
    });
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  return (
    <div className="container messenger px-0">
      {isMobile && !showMessagesList && (
      <div className="scrollable sidebarSM">
        { showCompaniesList
          ? <CompaniesList />
          : <ConversationList /> }
      </div>
      )}

      <div className="scrollable sidebarLS">
        { showCompaniesList
          ? <CompaniesList />
          : <ConversationList /> }
      </div>
      {showMessagesList && (
        <div className="scrollable contentLS">
          <MessageList />
        </div>
      )}
    </div>
  );
};

const { bool, shape, func } = PropTypes;

Messenger.propTypes = {
  actions: shape({
    sendMessage: func.isRequired,
    getMessages: func.isRequired,
    setVisibilityMessagesList: func.isRequired,
    setVisibilityCompaniesList: func.isRequired,
    setConversationId: func.isRequired,
  }).isRequired,
  showMessagesList: bool.isRequired,
  showCompaniesList: bool.isRequired,
};

export default Messenger;
