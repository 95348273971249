/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { RightOutlined } from '@ant-design/icons';
import ImmutableProptypes from 'react-immutable-proptypes';
import { notification } from 'antd';
import ButtonSaveChangesMedium from '../../Buttons/ButtonSaveChanges/ButtonSaveChangesMedium/View';
import ButtonCancel from '../../Buttons/ButtonCancel/View';
import styles from './selectHeaderBackground.module.scss';

const SelectHeaderBackground = (props) => {
  const {
    actions,
    modalVisibility,
    backgroundImage,
    backgroundImages,
    companyName,
  } = props;
  const [selectedImage, selectImage] = useState({ id: '' });
  const { t } = useTranslation();

  useEffect(() => {
    selectImage({
      id: backgroundImage.get('id', ''),
      thumbnailUrl: backgroundImage.get('thumbnailUrl', ''),
      url: backgroundImage.get('url', ''),
    });
  }, [backgroundImage]);

  const toggle = () => {
    if (modalVisibility) {
      actions.hideModal();
    } else {
      actions.showModal();
    }
  };
  const handleSaveChanges = () => {
    if (selectedImage.id) {
      actions.updateActiveCompanyBackground(selectedImage);
      actions.hideModal();
    } else {
      notification.error({
        message: 'Saving Error',
        description: 'Image not selected',
      });
    }
  };

  const handleHideModal = () => {
    actions.hideModal();
  };

  return (
    <Modal
      isOpen={modalVisibility}
      toggle={toggle}
      className={styles.modalContainerStyle}
    >
      <div className={styles.modalWrapper}>
        <div className={classNames(styles.navigationInfo)}>
          <h5>{companyName}</h5>
          <RightOutlined className="px-4 pt-1" />
          <h6>{t('backgroundImageSelect navigation text')}</h6>
        </div>
        {/* modal content */}
        <div className={styles.modalContent}>
          {backgroundImages.size > 0
            && backgroundImages.map((imageItem) => (
              <div
                className={classNames(
                  styles.imageItemContainer,
                  'col-12 col-sm-6 col-lg-3 px-2 py-2',
                )}
                key={imageItem.get('id', '')}
              >
                <div
                  className={classNames(
                    styles.imageContainer,
                    selectedImage.id === imageItem.get('id', '')
                      ? styles.selectedImage
                      : '',
                  )}
                  onClick={() => selectImage({
                    id: imageItem.get('id', ''),
                    thumbnailUrl: imageItem.get('thumbnailUrl', ''),
                    url: imageItem.get('url', ''),
                  })}
                  role="presentation"
                >
                  <img src={imageItem.get('thumbnailUrl', '')} alt="Bizbook" />
                </div>
              </div>
            ))}
        </div>
        <div
          className={classNames(
            styles.buttonContainer,
            'col-12 mt-5 mt-md-0 ml-auto pr-0',
          )}
        >
          <ButtonCancel handleClick={handleHideModal} />
          <ButtonSaveChangesMedium handleClick={handleSaveChanges} />
        </div>
      </div>
    </Modal>
  );
};

const {
  bool, shape, func, string,
} = PropTypes;

SelectHeaderBackground.propTypes = {
  actions: shape({
    showModal: func.isRequired,
    hideModal: func.isRequired,
    updateActiveCompanyBackground: func.isRequired,
  }).isRequired,
  modalVisibility: bool.isRequired,
  backgroundImage: ImmutableProptypes.map.isRequired,
  backgroundImages: ImmutableProptypes.list.isRequired,
  companyName: string.isRequired,
};

export default SelectHeaderBackground;
