import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import buttonStyle from 'utils/buttonStyle.module.scss';
import { Fade } from 'react-reveal';
import { bizbookWelcome } from 'images';
import useForm from 'utils/hooks/useForm';
import { useTranslation } from 'react-i18next';
import inputStyle from 'utils/inputStyle.module.scss';
import InputContainer from 'utils/InputMain';
import debounce from 'lodash/debounce';
import validate from './validateContactForm';
import styles from './contactUs.module.scss';

const ContactUs = (props) => {
  const { actions, formDataStatus } = props;
  const { t } = useTranslation();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnInputBlur,
  } = useForm(sendMail, validate);

  function sendMail() {
    actions.sendContactUsMail(values);
  }

  useEffect(() => {
    const updateWidth = () => {
      setInnerWidth(window.innerWidth);
    };
    const debounceUpdateWidth = debounce(updateWidth, 200);
    window.addEventListener('resize', debounceUpdateWidth);
    return () => window.removeEventListener('resize', debounceUpdateWidth);
  }, []);

  useEffect(() => {
    handleChange({ target: { name: 'email', value: '' } });
    handleChange({ target: { name: 'phone', value: '' } });
    handleChange({ target: { name: 'body', value: '' } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDataStatus]);

  return (
    <div className={styles.contactUs}>
      <Fade>
        <div className="container px-md-5 pt-md-4">
          <h3 className={classNames(styles.headlineWriteToUs, 'pt-5 pb-2')}>
            {t('contactUs tagline')}
          </h3>
          <h4 className={classNames(styles.titleWriteToUs, 'pb-5')}>
            {t('contactUs headline')}
          </h4>
          <h5 className={classNames(styles.mobileTitleWriteToUs, 'mt-5 mb-3 ')}>
            {t('contactUs headlineMobile')}
          </h5>
          <form onSubmit={handleSubmit}>
            <div className={styles.inputsContainer}>
              <InputContainer
                label={t('contactUs input email label')}
                name="email"
                type="email"
                placeholder={t('contactUs input email placeholder')}
                value={values.email}
                handleChange={handleChange}
                error={errors.email}
                onBlur={handleOnInputBlur}
              />
              <InputContainer
                label={t('contactUs input phone label')}
                name="phone"
                type="text"
                placeholder={t('contactUs input phone placeholder')}
                value={values.phone}
                handleChange={handleChange}
                error={errors.phone}
                onBlur={handleOnInputBlur}
              />
              <div
                className={classNames(
                  inputStyle.formGroup,
                  styles.textareaStyle,
                  'col-12 col-md-11 my-3 mx-auto',
                  errors.body ? inputStyle.validationError : '',
                )}
              >
                <div>
                  <h6 className={classNames(inputStyle.error, styles.error)}>
                    {errors.body}
                  </h6>
                  <h5 className={classNames(styles.textAreaLabel, 'px-1')}>
                    {t('contactUs input textarea label')}
                  </h5>
                </div>
                <textarea
                  className={classNames(inputStyle.formControl, 'py-2 my-1')}
                  type="text"
                  placeholder={t('contactUs input textarea placeholder')}
                  name="body"
                  value={values.body}
                  onChange={handleChange}
                  rows={innerWidth >= 760 ? 4 : 7}
                  onBlur={handleOnInputBlur}
                />
              </div>
            </div>
            <div className={classNames(styles.buttonContainer, 'ml-4 pl-3')}>
              <button
                type="submit"
                className={classNames(
                  buttonStyle.buttonSuccessStyle,
                  styles.buttonSuccessStyle,
                )}
              >
                {t('contactUs buttonText')}
              </button>
            </div>
          </form>
        </div>
      </Fade>
      <img src={bizbookWelcome} alt="contact us at bizbook" />
    </div>
  );
};

const { shape, func, bool } = PropTypes;

ContactUs.propTypes = {
  actions: shape({
    sendContactUsMail: func.isRequired,
  }).isRequired,
  formDataStatus: bool.isRequired,
};

export default ContactUs;
