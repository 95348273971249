import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadCompanyDetails } from 'actions/auth';
import { selectCompanyDetails } from 'selectors/auth';

const mapStateToProps = (state) => ({
  companyData: selectCompanyDetails(state),
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loadCompanyDetails,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
