import React from 'react';
import { string } from 'prop-types';

const MapIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 47.677 50.555"
  >
    <g
      id="prefix__Group_655"
      data-name="Group 655"
      transform="translate(-286.135 -469.027)"
    >
      <g
        id="prefix__Group_186"
        data-name="Group 186"
        transform="translate(34.198 -48.855)"
      >
        <g id="prefix__Group_119" data-name="Group 119">
          <path
            id="prefix__Rectangle_13"
            d="M0 0H3V40.368H0z"
            className="prefix__cls-1"
            data-name="Rectangle 13"
            transform="rotate(-.115 259202.719 -140423.028)"
            style={{ fill: color }}
          />
        </g>
        <g id="prefix__Group_120" data-name="Group 120">
          <path
            id="prefix__Rectangle_14"
            d="M0 0H3V40.754H0z"
            className="prefix__cls-1"
            data-name="Rectangle 14"
            transform="rotate(-.246 122283.413 -62323.07)"
            style={{ fill: color }}
          />
        </g>
        <g id="prefix__Group_121" data-name="Group 121">
          <path
            id="prefix__Path_152"
            d="M270.482 567.282l-.667-.168L255.539 563a2.1 2.1 0 0 1-1.509-2.005v-39.039l.7-.662a2.077 2.077 0 0 1 1.636-.508l13.8 1.638 13.625-4.542.649.165 13.65 3.866a2.1 2.1 0 0 1 1.519 2.007v42.191l-.887.668a2.087 2.087 0 0 1-1.9.256l-12.941-4.581zm-12.452-7.727l12.278 3.538 13.52-4.872.723.227 11.064 3.916v-37l-11.692-3.312-13.313 4.439-.48-.047-12.1-1.435v34.545z"
            data-name="Path 152"
            style={{ fill: '#050713' }}
          />
        </g>
        <path
          id="prefix__Path_153"
          d="M296 523.068l-13.65-3.866-.649-.165-13.625 4.542-13.8-1.638a2.081 2.081 0 0 0-1.636.508l-.7.662v39.039a2.1 2.1 0 0 0 1.509 2.005l14.276 4.114.667.168 13.4-4.828 12.941 4.581a2.089 2.089 0 0 0 1.9-.256l.887-.669v-42.191a2.1 2.1 0 0 0-1.52-2.006zm-26.386 4.214l10.669-3.557.072 36.149-10.585 3.814zm-13.68-1.115l10.681 1.267.156 36.4-10.837-3.123zm37.584 37.354l-10.162-3.6-.073-36.3 10.235 2.9z"
          data-name="Path 153"
          style={{ opacity: 0.2, fill: color }}
        />
      </g>
    </g>
  </svg>
);

MapIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

MapIcon.defaultProps = {
  color: '#37ead9',
  width: '47.677',
  height: '50.555',
};

export default MapIcon;
