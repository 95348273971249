import { parseError } from 'utils/parse-helper';
import { AuthorizedApiService } from './apiService';

export const getMessagesService = async (id) => AuthorizedApiService.get(`conversation/${id}/messages`)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const getConversationIdService = async (queryData) => AuthorizedApiService.get('conversation', { params: queryData })
  .then((res) => ({ conversationId: res.data.id }))
  .catch((err) => ({
    conversationId: null,
    error: parseError(err),
  }));

export const putMessagesReadStatusService = async (id) => AuthorizedApiService.put('conversation', {
  read: true,
  conversationIds: [id],
})
  .then(() => null)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const getNewMessagesCountService = async () => AuthorizedApiService.get('conversation-new-count')
  .then((res) => ({ conversationsCount: res.data }))
  .catch((err) => ({
    conversationsCount: null,
    error: parseError(err),
  }));

export const archiveConversationService = async (id) => AuthorizedApiService.put(`conversation/${id}/archive`)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));
