export const types = {
  SHOW_COLOR_PALETTE_MODAL: 'SHOW_COLOR_PALETTE_MODAL',
  HIDE_COLOR_PALETTE_MODAL: 'HIDE_COLOR_PALETTE_MODAL',
  UPLOAD_COMPANY_LOGO: 'UPLOAD_COMPANY_LOGO',
  SET_LOGO_URL: 'SET_LOGO_URL',
  UPLOAD_USER_IMAGE: 'UPLOAD_USER_IMAGE',
  SET_USER_IMAGE: 'SET_USER_IMAGE',
  UPLOAD_ABOUT_COMPANY_IMAGE: 'UPLOAD_ABOUT_COMPANY_IMAGE',
  SET_UPLOADED_ABOUT_COMPANY_IMAGE: 'SET_UPLOADED_ABOUT_COMPANY_IMAGE',
  UPLOAD_NEW_PRODUCT_DEMAND_IMAGE: 'UPLOAD_NEW_PRODUCT_DEMAND_IMAGE',
  SET_UPLOADED_NEW_PRODUCT_DEMAND_IMAGE:
    'SET_UPLOADED_NEW_PRODUCT_DEMAND_IMAGE',
  CLEAR_UPLOADED_IMAGE: 'CLEAR_UPLOADED_IMAGE',
};

export const hideModal = () => ({
  type: types.HIDE_COLOR_PALETTE_MODAL,
  payload: {},
});

export const showModal = () => ({
  type: types.SHOW_COLOR_PALETTE_MODAL,
  payload: {},
});

export const uploadLogo = (data) => ({
  type: types.UPLOAD_COMPANY_LOGO,
  payload: { data },
});

export const setLogo = (logoUrl) => ({
  type: types.SET_LOGO_URL,
  payload: { logoUrl },
});

export const uploadTestimonialUserImage = (userObjectKey, data) => ({
  type: types.UPLOAD_USER_IMAGE,
  payload: { userObjectKey, data },
});

export const setTestimonialUserImage = (userObjectKey, imageUrl) => ({
  type: types.SET_USER_IMAGE,
  payload: { userObjectKey, imageUrl },
});

export const uploadAboutCompanyImage = (imageKey, data) => ({
  type: types.UPLOAD_ABOUT_COMPANY_IMAGE,
  payload: { imageKey, data },
});

export const setUploadedAboutCompanyImage = (imageKey, imageUrl) => ({
  type: types.SET_UPLOADED_ABOUT_COMPANY_IMAGE,
  payload: { imageKey, imageUrl },
});

export const uploadNewProductOrDemandImage = (imageKey, data) => ({
  type: types.UPLOAD_NEW_PRODUCT_DEMAND_IMAGE,
  payload: { imageKey, data },
});

export const setUploadedNewProducOrDemandImage = (
  imageKey,
  imageUrl,
  publicId,
) => ({
  type: types.SET_UPLOADED_NEW_PRODUCT_DEMAND_IMAGE,
  payload: { imageKey, imageUrl, publicId },
});

export const clearUploadedImage = () => ({
  type: types.CLEAR_UPLOADED_IMAGE,
  payload: {},
});

export default types;
