import React from 'react';
import { string } from 'prop-types';

const ShovelIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 51.004 50.729"
  >
    <g
      id="prefix__Group_660"
      data-name="Group 660"
      transform="translate(-1128.554 -477.886) translate(712.198 -40.855)"
    >
      <g id="prefix__Group_89" data-name="Group 89">
        <g id="prefix__Group_86" data-name="Group 86">
          <path
            id="prefix__Rectangle_10"
            d="M0 0H37.74V4H0z"
            data-name="Rectangle 10"
            transform="rotate(-45 888.224 -229.786)"
            style={{ fill: '#050713' }}
          />
        </g>
        <g id="prefix__Group_87" data-name="Group 87">
          <path
            id="prefix__Path_82"
            d="M426.5 567.3l-7.767-7.767a2 2 0 0 1 0-2.828 2 2 0 0 1 2.828 0l7.767 7.767a2 2 0 0 1 0 2.828 2 2 0 0 1-2.828 0z"
            className="prefix__cls-2"
            data-name="Path 82"
            style={{ fill: color }}
          />
        </g>
        <g id="prefix__Group_88" data-name="Group 88">
          <path
            id="prefix__Path_83"
            d="M455.466 545.656l-14.9-14.9a1.039 1.039 0 0 1 0-1.468l7.563-7.562c4.2-4.2 15.359-2.791 16.617-2.617l.876.094a1.363 1.363 0 0 1 1.213 1.247l.067.853c.242 1.6 1.737 12.653-2.4 16.793l-7.563 7.563a1.038 1.038 0 0 1-1.473-.003zm-9.9-15.558l10.559 10.559a.109.109 0 0 0 .155 0l5.391-5.391c1.84-1.839 1.94-7.955 1.417-12.326-4.335-.433-10.3-.22-12.13 1.613l-5.391 5.39a.11.11 0 0 0-.003.157z"
            className="prefix__cls-2"
            data-name="Path 83"
            style={{ fill: color }}
          />
        </g>
      </g>
      <path
        id="prefix__Path_84"
        d="M465.041 522.031a1.362 1.362 0 0 0-1.212-1.247l-.875-.094c-1.259-.174-12.418-1.583-16.617 2.617l-7.563 7.562a1.039 1.039 0 0 0 0 1.468l6.037 6.037-22.478 22.478-2.563-2.563a2 2 0 0 0-2.828 2.828l7.767 7.767a2 2 0 0 0 2.828-2.828l-2.376-2.376 22.478-22.48 6.037 6.037a1.039 1.039 0 0 0 1.468 0l7.563-7.563c4.14-4.14 2.645-15.19 2.4-16.793zm-5.164 14.818l-5.391 5.391a.109.109 0 0 1-.155 0l-10.559-10.559a.109.109 0 0 1 0-.155l5.391-5.39c1.833-1.833 7.8-2.046 12.13-1.613.524 4.371.423 10.487-1.416 12.326z"
        data-name="Path 84"
        style={{ opacity: 0.2, fill: color }}
      />
    </g>
  </svg>
);

ShovelIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

ShovelIcon.defaultProps = {
  color: '#37ead9',
  width: '51.004',
  height: '50.729',
};

export default ShovelIcon;
