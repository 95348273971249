import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createNewCompany } from 'actions/auth';
import { showModal, hideModal } from '../modules/actions';
import { selectModalVisibility } from '../modules/selectors';

const mapStateToProps = (state) => ({
  modalVisibility: selectModalVisibility(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showModal,
      hideModal,
      createNewCompany,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
