import React from 'react';
import PropTypes from 'prop-types';
import './Toolbar.scss';

const Toolbar = (props) => {
  const {
    title,
    leftItems,
    rightItems,
  } = props;
  return (
    <div className="toolbar">
      <div className="left-items">{ leftItems }</div>
      <h1 className="toolbar-title">{ title }</h1>
      <div className="right-items">{ rightItems }</div>
    </div>
  );
};

const { string, element } = PropTypes;

Toolbar.propTypes = {
  title: string.isRequired,
  leftItems: element,
  rightItems: element,
};

Toolbar.defaultProps = {
  leftItems: null,
  rightItems: null,
};
export default Toolbar;
