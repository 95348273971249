import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectNewMessagesCount } from 'components/Chat/modules/selectors';
import { selectActiveCompanyId } from '../../../../selectors/auth';
import {
  startNotificationPolling,
  stopNotificationPolling,
  setNotificationReadStatus,
  setNotificationSeen,
  getMessagesCounts,
} from './modules/actions';
import {
  selectNewNotificationsCount,
  selectNotifications,
} from './modules/selectors';

const mapStateToProps = (state) => ({
  activeCompanyId: selectActiveCompanyId(state),
  newNotificationsCount: selectNewNotificationsCount(state),
  notifications: selectNotifications(state),
  newMessagesCount: selectNewMessagesCount(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      startNotificationPolling,
      stopNotificationPolling,
      setNotificationReadStatus,
      setNotificationSeen,
      getMessagesCounts,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
