import { parseError } from 'utils/parse-helper';
import { AuthorizedApiService } from './apiService';

export const packageContactService = async (postData) => AuthorizedApiService.post('package-contact', postData)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const getSuggestedTagsService = async (queryString) => AuthorizedApiService.get('tag', { params: queryString })
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const getCurrenciesRatesByCurrencyService = async (queryString) => AuthorizedApiService.get('currencies/convert', { params: queryString })
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const sendMailContactService = async (postData) => AuthorizedApiService.post('contact', postData)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));
