import i18n from 'i18next';

const websiteRegex = /^(ftp|http|https|www|:\/\/|\.|@){2,}(localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\S*:\w*@)*([a-zA-Z]|(\d{1,3}|\.){7}){1,}(\w|\.{2,}|\.[a-zA-Z]{2,3}|\/|\?|&|:\d|@|=|\/|\(.*\)|#|-|%)*$/;

export default function validate(values) {
  const errors = {};
  if (!values.address) {
    errors.address = i18n.t('createCompany contactInfo validationError address empty');
  }
  if (!values.countryIso) {
    errors.countryIso = i18n.t('createCompany contactInfo validationError country countryNotSelected');
  }
  if (values.phone && !/^[+]*[\s]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(values.phone)) {
    errors.phone = i18n.t('createCompany contactInfo validationError phone invalid');
  }
  if (values.contactEmail && !/\S+@\S+\.\S+/.test(values.contactEmail)) {
    errors.contactEmail = i18n.t('createCompany contactInfo validationError email invalid');
  }
  if (values.website
    // eslint-disable-next-line max-len
    && !websiteRegex.test(values.website)) {
    errors.website = i18n.t('createCompany contactInfo validationError website invalid');
  }
  return errors;
}
