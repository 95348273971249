import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import styles from './adminPanel.module.scss';
import CompaniesTable from './CompaniesTable';
import ProductsTable from './ProductsTable';
import {
  initialState,
  reducer,
  setActiveTab,
  setLoadingStatus,
  updatePage,
  filterData,
  resetFilters,
} from './utils/useReducer';
import CreateNewCompanyModal from './CreateNewCompanyModal';
import Statistics from './Statistics';

const AdminPanel = (props) => {
  const {
    actions,
    tableContent,
    industriesList,
    countriesList,
    availablePages,
    hasMoreDataStatus,
    adminRole,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  useEffect(() => {
    if (!adminRole) {
      history.push('/');
    }
  }, []);

  useEffect(() => {
    if (industriesList.size === 0) {
      actions.getIndustriesList();
    }
    if (countriesList.size === 0) {
      actions.getCountriesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.filterQueryObject.type !== 'statistic') {
      dispatch(setLoadingStatus(true));
      actions.getFeedContent(state.filterQueryObject, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filterQueryObject]);

  useEffect(() => {
    dispatch(setLoadingStatus(false));
  }, [tableContent]);

  const handlePageUpdate = (page) => {
    dispatch(updatePage(page));
  };

  const handleFilterData = (fieldName, value) => {
    dispatch(filterData(fieldName, value));
  };

  const handleFiltersReset = () => {
    dispatch(resetFilters());
  };

  return (
    <div className={classNames('container', styles.adminPanelMainContainer)}>
      <div className={styles.adminActionTabContainer}>
        <div className={classNames(styles.buttonsContainer, 'my-4')}>
          <Button
            shape="round"
            size="large"
            onClick={() => dispatch(setActiveTab('companies', 'createdAt'))}
            className={classNames(
              'mr-2', 'mt-2',
              state.filterQueryObject.type === 'companies'
              && 'bg-primary text-light',
            )}
          >
            Companies
          </Button>
          <Button
            shape="round"
            size="large"
            onClick={() => dispatch(setActiveTab('product'))}
            className={classNames(
              'mr-2', 'mt-2',
              state.filterQueryObject.type === 'product'
              && 'bg-primary text-light',
            )}
          >
            Products
          </Button>
          <Button
            shape="round"
            size="large"
            onClick={() => dispatch(setActiveTab('demand'))}
            className={classNames(
              'mr-2', 'mt-2',
              state.filterQueryObject.type === 'demand'
              && 'bg-primary text-light',
            )}
          >
            Demands
          </Button>
          <Button
            shape="round"
            size="large"
            onClick={() => dispatch(setActiveTab('statistic'))}
            className={classNames(
              'mt-2', 'mr-2',
              state.filterQueryObject.type === 'statistic'
              && 'bg-primary text-light',
            )}
          >
            Statistics
          </Button>
        </div>
        <Button shape="round" size="large" onClick={actions.showModal}>
          Create new company
        </Button>
      </div>
      {state.filterQueryObject.type === 'companies' && (
        <CompaniesTable
          tableContent={tableContent}
          industriesList={industriesList}
          countriesList={countriesList}
          currentPage={state.filterQueryObject.page}
          updatePage={handlePageUpdate}
          disableNext={!hasMoreDataStatus}
          loading={state.loading}
          handleFilterData={handleFilterData}
          handleFiltersReset={handleFiltersReset}
          availablePages={availablePages}
        />
      )}
      {(state.filterQueryObject.type === 'product' || state.filterQueryObject.type === 'demand') && (
        <ProductsTable
          tableContent={tableContent}
          currentPage={state.filterQueryObject.page}
          updatePage={handlePageUpdate}
          disableNext={!hasMoreDataStatus}
          loading={state.loading}
          handleFilterData={handleFilterData}
          handleFiltersReset={handleFiltersReset}
          availablePages={availablePages}
        />
      )}
      {state.filterQueryObject.type === 'statistic' && (
        <Statistics />
      )}
      <CreateNewCompanyModal />
    </div>
  );
};

const {
  func, shape, number, bool,
} = PropTypes;
const { list } = ImmutablePropTypes;

AdminPanel.propTypes = {
  actions: shape({
    getFeedContent: func.isRequired,
    getIndustriesList: func.isRequired,
    getCountriesList: func.isRequired,
    showModal: func.isRequired,
  }).isRequired,
  tableContent: list.isRequired,
  industriesList: list.isRequired,
  countriesList: list.isRequired,
  availablePages: number.isRequired,
  hasMoreDataStatus: bool.isRequired,
  adminRole: bool.isRequired,
};

export default AdminPanel;
