import React from 'react';
import { string } from 'prop-types';

const PineTree = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 30.788 51.778"
  >
    <g
      id="prefix__Group_646"
      data-name="Group 646"
      transform="translate(-119.095 -318.936)"
    >
      <g
        id="prefix__Group_182"
        data-name="Group 182"
        transform="translate(34.198 -36.855)"
      >
        <g id="prefix__Group_127" data-name="Group 127">
          <g id="prefix__Group_123" data-name="Group 123">
            <path
              id="prefix__Rectangle_15"
              d="M0 0H4V10.404H0z"
              data-name="Rectangle 15"
              transform="translate(100.917 394.009)"
              style={{ fill: color }}
            />
          </g>
          <g id="prefix__Group_124" data-name="Group 124">
            <path
              id="prefix__Path_154"
              d="M90.751 406.413H86.9a2 2 0 0 1-2-2 2 2 0 0 1 2-2h3.854a2 2 0 0 1 2 2 2 2 0 0 1-2.003 2z"
              className="prefix__cls-2"
              data-name="Path 154"
              style={{ fill: '#050713' }}
            />
          </g>
          <g id="prefix__Group_125" data-name="Group 125">
            <path
              id="prefix__Path_155"
              d="M109.939 406.413H96.084a2 2 0 0 1-2-2 2 2 0 0 1 2-2h13.854a2 2 0 0 1 2 2 2 2 0 0 1-1.999 2z"
              className="prefix__cls-2"
              data-name="Path 155"
              style={{ fill: '#050713' }}
            />
          </g>
          <g id="prefix__Group_126" data-name="Group 126">
            <path
              id="prefix__Path_156"
              d="M113.551 396.009h-3.029a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.549l-7.942-27.509-8.329 27.509h10.725a2 2 0 0 1 2 2 2 2 0 0 1-2 2H92.284a2.133 2.133 0 0 1-2.039-2.758l10.885-35.945a2.116 2.116 0 0 1 2.041-1.515h.008a2.119 2.119 0 0 1 2.041 1.537l10.38 35.957a2.133 2.133 0 0 1-2.052 2.724zM94.075 394.4v.013s-.001-.005 0-.013z"
              className="prefix__cls-2"
              data-name="Path 156"
              style={{ fill: '#050713' }}
            />
          </g>
        </g>
        <path
          id="prefix__Path_158"
          d="M106.428 395.165a2 2 0 0 0 2 2h3.029a2.134 2.134 0 0 0 2.051-2.725l-10.383-35.957a2.119 2.119 0 0 0-2.041-1.537h-.008a2.116 2.116 0 0 0-2.041 1.515L88.15 394.406a2.133 2.133 0 0 0 2.039 2.759h8.634v6.4H93.99a2 2 0 0 0 0 4h13.854a2 2 0 0 0 0-4h-5.021v-6.4h.608a2 2 0 0 0 0-4H92.706l8.328-27.505 7.942 27.505h-.549a2 2 0 0 0-1.999 2z"
          data-name="Path 158"
          style={{ opacity: 0.2, fill: color }}
        />
      </g>
    </g>
  </svg>
);

PineTree.propTypes = {
  color: string,
  width: string,
  height: string,
};

PineTree.defaultProps = {
  color: '#37ead9',
  width: '30.788',
  height: '51.778',
};

export default PineTree;
