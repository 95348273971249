import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useParams } from 'react-router';
import { List as IList, Map as IMap } from 'immutable';
import CustomFooter from 'components/AboutCompany/utils/CustomFooter';
import { useTranslation } from 'react-i18next';
import { isLoggedUser } from 'utils/authorizationHelper';
import bizbookBackground from 'images/sliderImages/background2-min.png';
import classNames from 'classnames';
import CompanyHeader from './CompanyHeader';
import AboutUsSection from './AboutUs';
import WhatWeDo from './WhatWeDo';
import ProductsAndServicesHeader from './ProductsAndServicesHeader';
import ProductsAndServices from './ProductsAndServices';
import OurClients from './OurClients';
import ContactInfo from './ContactInfo';
import styles from './viewCompany.module.scss';
import { infinityLoader } from '../../images';

const ViewCompany = (props) => {
  const {
    isLoading,
    companyData,
    actions,
    companyProgress,
    adminRole,
  } = props;
  const params = useParams();
  const { t } = useTranslation();
  const { id } = params;
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    actions.activateLoader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    actions.getCompanyDetailsById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyData.size !== 0) actions.deactivateLoader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedUser(id)) {
      setIsAuthorized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData]);

  useEffect(() => {
    if (adminRole) {
      setIsAuthorized(true);
    }
  }, [adminRole]);

  if (isLoading) {
    return (
      <img
        src={infinityLoader}
        alt="loader"
        className={classNames(styles.imageloaderStyle)}
      />
    );
  }
  if (companyProgress < 80 && !isAuthorized) {
    return (
      <div className={styles.progressNotSatisfiedContainerStyle}>
        <img src={bizbookBackground} alt="requirements not satisfied" />
        <h3>
          {t('companyViewMode companyProgress minimumRequirementNotSatisfied')}
        </h3>
        <h4>{`${t('companyName titleText')}: ${companyData.get('name', '')}`}</h4>
      </div>
    );
  }
  return (
    <div className={styles.progressSatisfiedContainer}>
      <CompanyHeader
        title={companyData.getIn(['companyJson', 'companyHeader', 'title'], '')}
        subtitle={companyData.getIn(
          ['companyJson', 'companyHeader', 'subtitle'],
          '',
        )}
        textAlignment={companyData.getIn(
          ['companyJson', 'companyHeader', 'textAlignment'],
          'text-left',
        )}
        isAuthorized={isAuthorized}
        ratingCompleted={companyData.get('ratings', '').size > 0}
      />
      {companyData.getIn(
        ['companyJson', 'aboutUsSection', 'sectionTitle'],
        '',
      ) && (
      <AboutUsSection
        sectionTitle={companyData.getIn(
          ['companyJson', 'aboutUsSection', 'sectionTitle'],
          '',
        )}
        shortInfo={companyData.getIn(
          ['companyJson', 'aboutUsSection', 'shortInfo'],
          '',
        )}
        iconName={companyData.getIn([
          'companyJson',
          'aboutUsSection',
          'iconName',
        ])}
        textAlignment={companyData.getIn(
          ['companyJson', 'aboutUsSection', 'textAlignment'],
          'text-left',
        )}
      />
      )}
      {companyData.getIn(
        ['companyJson', 'whatWeDo', 'productsAndServices'],
        IList(),
      ).size > 0 && (
      <WhatWeDo
        productsAndServices={companyData.getIn(
          ['companyJson', 'whatWeDo', 'productsAndServices'],
          IList(),
        )}
        textAlignment={companyData.getIn(
          ['companyJson', 'whatWeDo', 'textAlignment'],
          'text-left',
        )}
      />
      )}
      {companyData.getIn(
        ['companyJson', 'productsAndServicesHeader', 'title'],
        '',
      ) && (
      <ProductsAndServicesHeader
        title={companyData.getIn(
          ['companyJson', 'productsAndServicesHeader', 'title'],
          '',
        )}
        description={companyData.getIn(
          ['companyJson', 'productsAndServicesHeader', 'description'],
          '',
        )}
        textAlignment={companyData.getIn(
          ['companyJson', 'productsAndServicesHeader', 'textAlignment'],
          'text-left',
        )}
        companySlug={companyData.get('slug', '')}
        actions={actions}
      />
      )}
      {companyData.getIn(
        ['companyJson', 'productsAndServices', 'productsAndServices'],
        IList(),
      ).size > 0 && (
      <ProductsAndServices
        productsAndServices={companyData.getIn(
          ['companyJson', 'productsAndServices', 'productsAndServices'],
          IList(),
        )}
        textAlignment={companyData.getIn(
          ['companyJson', 'productsAndServices', 'textAlignment'],
          'text-left',
        )}
      />
      )}
      {companyData.getIn(['companyJson', 'ourClients', 'title'], IMap())
        .length > 0 && (
        <OurClients
          clients={companyData.getIn(['companyJson', 'ourClients'], IMap())}
        />
      )}
      {companyData.get('address', '') && (
      <ContactInfo
        title={companyData.getIn(['companyJson', 'contactInfo', 'title'], '')}
        address={companyData.get('address', '')}
        city={companyData.get('city', '')}
        countryIso={companyData.get('countryIso', '')}
        idNumber={companyData.get('idNumber', '')}
        phone={companyData.get('phone', '')}
        contactEmail={companyData.get('contactEmail', '')}
        website={companyData.get('website', '')}
        textAlignment={companyData.getIn(
          ['companyJson', 'contactInfo', 'textAlignment'],
          'text-left',
        )}
      />
      )}

      <CustomFooter companyData={companyData} />
    </div>
  );
};

const {
  shape,
  func,
  number,
  bool,
} = PropTypes;
const { map } = ImmutablePropTypes;

ViewCompany.propTypes = {
  companyData: map,
  actions: shape({
    getCompanyDetailsById: func.isRequired,
    activateLoader: func.isRequired,
    deactivateLoader: func.isRequired,
  }).isRequired,
  companyProgress: number.isRequired,
  adminRole: bool.isRequired,
  isLoading: bool.isRequired,
};

ViewCompany.defaultProps = {
  companyData: IMap(),
};

export default ViewCompany;
