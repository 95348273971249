export const types = {
  GET_FEED_CONTENT: 'GET_FEED_CONTENT',
  SET_FEED_CONTENT: 'SET_FEED_CONTENT',
  LOAD_MORE_FEED_CONTENT: 'LOAD_MORE_FEED_CONTENT',
  SET_MORE_FEED_CONTENT: 'SET_MORE_FEED_CONTENT',
  ACTIVATE_LOADER: 'ACTIVATE_LOADER',
  DEACTIVATE_LOADER: 'DEACTIVATE_LOADER',
  GET_SUGGESTED_TAGS: 'GET_SUGGESTED_TAGS',
  SET_SUGGESTED_TAGS: 'SET_SUGGESTED_TAGS',
  GET_USER_FEED_CONTENT: 'GET_USER_FEED_CONTENT',
  SET_USER_FEED_CONTENT: 'SET_USER_FEED_CONTENT',
  LOAD_MORE_USER_FEED_CONTENT: 'LOAD_MORE_USER_FEED_CONTENT',
  SET_MORE_LOADED_USER_FEED_CONTENT: 'SET_MORE_LOADED_USER_FEED_CONTENT',
  GET_ITEM_BY_ID: 'GET_ITEM_BY_ID',
  SET_ITEM_BY_ID: 'SET_ITEM_BY_ID',
  CLEAR_ACTIVE_ITEM: 'CLEAR_ACTIVE_ITEM',
  GET_RATES_BY_CURRENCY: 'GET_RATES_BY_CURRENCY',
  SET_RATES_BY_CURRENCY: 'SET_RATES_BY_CURRENCY',
  RATE_PRODUCT: 'RATE_PRODUCT',
  SHOW_RATING_MODAL: 'SHOW_RATING_MODAL',
  HIDE_RATING_MODAL: 'HIDE_RATING_MODAL',
  SET_FILTERS_QUERY_DATA: 'SET_FILTERS_QUERY_DATA',
};

export const getFeedContent = (queryObject, adminPanelFeed = false) => ({
  type: types.GET_FEED_CONTENT,
  payload: {
    queryObject,
    adminPanelFeed,
  },
});

export const setFeedContent = (content) => ({
  type: types.SET_FEED_CONTENT,
  payload: {
    content,
  },
});

export const loadMoreContent = (queryObject) => ({
  type: types.LOAD_MORE_FEED_CONTENT,
  payload: {
    queryObject,
  },
});

export const setMoreLoadedContent = (content) => ({
  type: types.SET_MORE_FEED_CONTENT,
  payload: {
    content,
  },
});

export const activateLoader = () => ({
  type: types.ACTIVATE_LOADER,
  payload: {},
});

export const deactivateLoader = () => ({
  type: types.DEACTIVATE_LOADER,
  payload: {},
});

export const getSuggestedTags = (queryObject) => ({
  type: types.GET_SUGGESTED_TAGS,
  payload: {
    queryObject,
  },
});

export const setSuggestedTags = (tags) => ({
  type: types.SET_SUGGESTED_TAGS,
  payload: {
    tags,
  },
});

export const getUserFeedContent = (queryObject, companyId) => ({
  type: types.GET_USER_FEED_CONTENT,
  payload: {
    queryObject,
    companyId,
  },
});

export const setUserFeedContent = (content) => ({
  type: types.SET_USER_FEED_CONTENT,
  payload: {
    content,
  },
});

export const loadMoreUserFeedContent = (queryObject, id) => ({
  type: types.LOAD_MORE_USER_FEED_CONTENT,
  payload: {
    queryObject,
    id,
  },
});

export const updateUserFeedContent = (content) => ({
  type: types.SET_MORE_LOADED_USER_FEED_CONTENT,
  payload: {
    content,
  },
});

export const getItemById = (id) => ({
  type: types.GET_ITEM_BY_ID,
  payload: {
    id,
  },
});

export const setItemById = (itemContent) => ({
  type: types.SET_ITEM_BY_ID,
  payload: {
    itemContent,
  },
});

export const clearActiveItem = () => ({
  type: types.CLEAR_ACTIVE_ITEM,
  payload: {},
});

export const getRatesByCurrency = (queryObject) => ({
  type: types.GET_RATES_BY_CURRENCY,
  payload: {
    queryObject,
  },
});

export const setRatesByCurrency = (rates) => ({
  type: types.SET_RATES_BY_CURRENCY,
  payload: {
    rates,
  },
});

export const rateProductItem = (companyId, rating) => ({
  type: types.RATE_PRODUCT,
  payload: {
    companyId,
    rating,
  },
});

export const showRatingModal = () => ({
  type: types.SHOW_RATING_MODAL,
  payload: {},
});

export const hideRatingModal = () => ({
  type: types.HIDE_RATING_MODAL,
  payload: {},
});

export const setQueryData = (queryData) => ({
  type: types.SET_FILTERS_QUERY_DATA,
  payload: {
    queryData,
  },
});

export default types;
