import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login } from 'actions/auth';
import { selectIsAuthenticated } from 'selectors/auth';
import {
  requestPasswordReset, hideModal, showModal, setAnimationStatus,
} from './modules/actions';
import { selectModalVisibility, selectAnimationStatus } from './modules/selectors';

const mapStateToProps = (state) => ({
  isAuthenticated: selectIsAuthenticated(state),
  modalVisibility: selectModalVisibility(state),
  animationIsPaused: selectAnimationStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      login,
      requestPasswordReset,
      hideModal,
      showModal,
      setAnimationStatus,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
