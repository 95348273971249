import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import inputStyle from 'utils/inputStyle.module.scss';
import { AutoComplete } from 'antd';
import SimpleMap from 'components/Map';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map as IMap } from 'immutable';
import InputContainer from '../modules/utils/InputFieldContainer';
import inputFields from './contactInfoInputs';
import EditBar from '../modules/utils/EditBar';
import GoogleAutocomplete from './utils/GoogleAutocomplete';
import styles from './contactInfo.module.scss';
import validateContactForm from './validateContactForm';
import useForm from '../../../utils/hooks/useForm';

const { Option } = AutoComplete;

const ContactInfo = (props) => {
  const {
    activeLanguage,
    title,
    address,
    city,
    countryIso,
    idNumber,
    phone,
    contactEmail,
    website,
    saveChange,
    updateCompanyJson,
    textAlignment,
    colorTheme,
    fontFamily,
    countriesList,
    actions,
    setSectionCompleted,
    positionInfo,
    countryIsoCode,
  } = props;

  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState('edit');
  const [displayInputFields, setInputFields] = useState(inputFields());
  const [options, setOptions] = useState(countriesList);
  const [changesSaved, setChangesSavedStatus] = useState(false);
  const sectionDidChange = (change) => {
    actions.setChangesMade(change);
    setChangesSavedStatus(!change);
  };
  const setSectionDidChange = () => sectionDidChange(true);
  const updateForm = (e) => {
    // eslint-disable-next-line no-use-before-define
    handleChange(e);
    setSectionDidChange();
  };

  const saveChanges = (e) => {
    // eslint-disable-next-line no-use-before-define
    if (Object.keys(errors).length < 1) {
      if (e) handleSubmit(e);
      let titleRendered = false;
      // eslint-disable-next-line no-restricted-syntax,no-use-before-define
      for (const [key, value] of Object.entries(values)) {
        if (displayInputFields[key]) {
          if (value) {
            saveChange(key, value.trim());
            titleRendered = true;
          } else {
            saveChange(key, null);
            titleRendered = true;
          }
        }
      }
      sectionDidChange(false);
      updateCompanyJson('contactInfo', {
        // eslint-disable-next-line no-use-before-define
        title: values.title || (titleRendered && 'Gdje nas možete pronaći') || '',
        // eslint-disable-next-line no-use-before-define
        textAlignment: values.textAlignment,
        // eslint-disable-next-line no-use-before-define
        positionInfo: values.positionInfo,
      });
    }
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnInputBlur,
    setValues,
    handleOnAddressBlur,
  } = useForm(saveChanges, validateContactForm);

  useEffect(() => {
    setInputFields(inputFields());
  }, [activeLanguage]);
  useEffect(() => {
    setValues({
      title,
      address,
      city,
      countryIso,
      idNumber,
      phone,
      contactEmail,
      website,
      textAlignment,
      positionInfo,
    });
    setSectionCompleted('contactInfo', !!address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    address,
    city,
    contactEmail,
    countryIso,
    idNumber,
    phone,
    title,
    website,
    textAlignment,
    positionInfo,
  ]);
  const setTextAlignment = (alignment) => {
    updateForm({
      target: {
        name: 'textAlignment', value: alignment,
      },
    });
  };

  const findCountryByIsoCode = (iso) => countriesList.find((country) => country.get('ISO') === iso);

  const handleSelectCountry = (selectedCountryIso) => {
    const countryObj = findCountryByIsoCode(selectedCountryIso);
    updateForm({
      target: {
        name: 'countryIso', value: countryObj?.get('ISO'),
      },
    });
  };

  useEffect(() => {
    actions.getCountriesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterCountrySearch = (inputValue, option) => option.children
    && option.children.toUpperCase().includes(inputValue.toUpperCase());

  useEffect(() => {
    setOptions(
      countriesList.map((item) => ({
        value: item.getIn(
          ['translations', activeLanguage === 'eng' ? 'en' : 'hr'],
          item.get('name', 'null'),
        ),
        name: item.get('ISO', ''),
        key: item.get('ISO', ''),
      })),
    );
  }, [activeLanguage, countriesList]);

  const translateCountryNameByIso = (countryISO) => findCountryByIsoCode(countryISO)?.getIn(
    ['translations', activeLanguage === 'eng' ? 'en' : 'hr'],
    findCountryByIsoCode(countryISO)?.get('name', ''),
  );
  const updatePositionData = (positionInfoData) => {
    setValues({ ...values, positionInfo: positionInfoData });
    setChangesSavedStatus(false);
  };
  const setNewCoordinates = (coordinates, addressValue) => {
    setValues({
      ...values,
      positionInfo: IMap({
        lat: coordinates.lat,
        lng: coordinates.lng,
      }),
      address: addressValue,
    });
  };

  const handleAddressOnChange = () => {
    setSectionDidChange();
  };

  const handleOnBlur = (e) => {
    setSectionDidChange();
    handleOnAddressBlur(e);
  };

  const renderContactInfoContainer = (label, value) => (value ? (
    <div className={styles.contactInfo}>
      <h5>{label}</h5>
      <h4>{value}</h4>
    </div>
  ) : <div />);

  const renderWebsiteContainer = (label, value) => {
    if (value) {
      let newValue = '';
      if (value.substring(0, 4) !== 'http') newValue = `https://${value}`;
      else newValue = value;
      return (
        <div className={styles.contactInfo}>
          <h5>{label}</h5>
          <a
            target="_blank"
            href={newValue}
          >
            {value}
          </a>
        </div>
      );
    }
    return (<div />);
  };

  return (
    <div className={classNames(styles.contactInfoMainContainer)}>
      <div
        className={classNames(
          'container',
          styles.contactInfoContainer,
          values.textAlignment === 'text-center'
            ? 'align-items-center'
            : values.textAlignment === 'text-right'
              ? 'align-items-end'
              : '',
        )}
      >
        <h4>{t('createCompany contactInfo sectionTitle')}</h4>
        {viewMode === 'edit' ? (
          <input
            type="text"
            placeholder={t(
              'createCompany contactInfo inputSectionTitle placeholder',
            )}
            name="title"
            className={classNames(
              styles.inputSectionTitle,
              values.textAlignment,
              viewMode === 'view' ? styles.noBorderStyle : '',
            )}
            value={values.title || ''}
            onChange={(e) => updateForm(e)}
            style={{ color: colorTheme, fontFamily }}
            maxLength={190}
          />
        ) : (
          <h2
            className={classNames(
              styles.inputSectionTitle,
              values.textAlignment,
              styles.noBorderStyle,
            )}
            style={{ color: colorTheme, fontFamily }}
          >
            {values.title}
          </h2>
        )}
        <div className={styles.inputFieldsContainer}>
          <div className={styles.inputMain}>
            {viewMode === 'edit'
              ? (
                <GoogleAutocomplete
                  label={displayInputFields.address.label}
                  countryIsoCode={countryIsoCode}
                  setCoordinates={setNewCoordinates}
                  value={values.address}
                  placeholder={t('createCompany contactInfo address placeholder')}
                  handleChange={handleAddressOnChange}
                  error={errors.address}
                  onBlur={handleOnBlur}
                  positionInfo={values?.positionInfo}
                />
              )
              : renderContactInfoContainer(
                displayInputFields.address.label,
                values[displayInputFields.address.key],
              )}

            {viewMode === 'edit'
              ? (
                <InputContainer
                  label={displayInputFields.city.label}
                  name={displayInputFields.city.key}
                  type="text"
                  placeholder={displayInputFields.city.placeholder}
                  value={values.city}
                  handleChange={updateForm}
                  customStyle="mx-auto"
                  error={errors.city}
                  disabled={viewMode === 'view'}
                />
              )
              : renderContactInfoContainer(
                displayInputFields.city.label,
                values[displayInputFields.city.key],
              )}
            {viewMode === 'edit'
              ? (
                <div
                  className={classNames(
                    inputStyle.formGroup,
                    'col-11 my-3 mr-auto mx-auto',
                    styles.countryInputStyle,
                    errors.countryIso ? inputStyle.validationError : '',
                  )}
                  id="country"
                >
                  <h6 className={inputStyle.error}>{errors.countryIso}</h6>
                  <h5 className="px-1">{t('register input country label')}</h5>
                  <AutoComplete
                    onChange={handleSelectCountry}
                    value={translateCountryNameByIso(values.countryIso)}
                    placeholder={t('register input country placeholder')}
                    className="my-2 mx-auto"
                    filterOption={(inputValue, option) => filterCountrySearch(inputValue, option)}
                    error={errors.countryIso}
                    onBlur={handleOnInputBlur}
                  >
                    {options.map((opt) => (
                      <Option value={opt.name} key={opt.name}>{opt.value}</Option>
                    ))}
                  </AutoComplete>
                </div>
              )
              : renderContactInfoContainer(
                displayInputFields.countryIso.label,
                translateCountryNameByIso(values.countryIso),
              )}

            {viewMode === 'edit'
              ? (
                <InputContainer
                  label={displayInputFields.idNumber.label}
                  name={displayInputFields.idNumber.key}
                  type="text"
                  placeholder={displayInputFields.idNumber.placeholder}
                  value={values.idNumber}
                  handleChange={updateForm}
                  customStyle="mx-auto"
                  error={errors.idNumber}
                  onBlur={handleOnInputBlur}
                  disabled={viewMode === 'view'}
                />
              )
              : renderContactInfoContainer(
                displayInputFields.idNumber.label,
                values[displayInputFields.idNumber.key],
              )}

            {viewMode === 'edit'
              ? (
                <InputContainer
                  label={displayInputFields.phone.label}
                  name={displayInputFields.phone.key}
                  type="text"
                  placeholder={displayInputFields.phone.placeholder}
                  value={values.phone}
                  handleChange={updateForm}
                  customStyle="mx-auto"
                  error={errors.phone}
                  onBlur={handleOnInputBlur}
                  disabled={viewMode === 'view'}
                />
              )
              : renderContactInfoContainer(
                displayInputFields.phone.label,
                values[displayInputFields.phone.key],
              )}

            {viewMode === 'edit'
              ? (
                <InputContainer
                  label={displayInputFields.contactEmail.label}
                  name={displayInputFields.contactEmail.key}
                  type="text"
                  placeholder={displayInputFields.contactEmail.placeholder}
                  value={values.contactEmail}
                  handleChange={updateForm}
                  customStyle="mx-auto"
                  error={errors.contactEmail}
                  onBlur={handleOnInputBlur}
                  disabled={viewMode === 'view'}
                />
              )
              : renderContactInfoContainer(
                displayInputFields.contactEmail.label,
                values[displayInputFields.contactEmail.key],
              )}

            {viewMode === 'edit'
              ? (
                <InputContainer
                  label={displayInputFields.website.label}
                  name={displayInputFields.website.key}
                  type="text"
                  placeholder={displayInputFields.website.placeholder}
                  value={values.website}
                  handleChange={updateForm}
                  customStyle="mx-auto"
                  error={errors.website}
                  onBlur={handleOnInputBlur}
                  disabled={viewMode === 'view'}
                />
              )
              : renderWebsiteContainer(
                displayInputFields.website.label,
                values[displayInputFields.website.key],
              )}
          </div>
          <div
            className={classNames(
              styles.simpleMapContainer,
              'my-auto ml-0 ml-md-4 pb-5 pb-md-0',
            )}
          >
            <SimpleMap
              positionInfo={values.positionInfo || IMap()}
              updatePositionData={updatePositionData}
            />
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <EditBar
          textAlignment={values.textAlignment}
          viewMode={viewMode}
          setTextAlignment={setTextAlignment}
          setViewMode={setViewMode}
          saveChanges={saveChanges}
          changesSaved={changesSaved}
        />
      </div>
    </div>
  );
};

const {
  string, func, shape,
} = PropTypes;

const {
  map, mapContains, listOf, list,
} = ImmutablePropTypes;

ContactInfo.propTypes = {
  activeLanguage: string.isRequired,
  title: string,
  address: string,
  city: string,
  countryIso: string,
  idNumber: string,
  phone: string,
  contactEmail: string,
  website: string,
  saveChange: func.isRequired,
  updateCompanyJson: func.isRequired,
  textAlignment: string.isRequired,
  colorTheme: string.isRequired,
  fontFamily: string.isRequired,
  countriesList: listOf(mapContains({
    ISO: string.isRequired,
    name: string.isRequired,
    translations: map.isRequired,
    callingCodes: list.isRequired,
    createdAt: string,
    updatedAt: string,
  })).isRequired,
  actions: shape({
    getCountriesList: func.isRequired,
    setChangesMade: func.isRequired,
  }).isRequired,
  setSectionCompleted: func.isRequired,
  positionInfo: ImmutablePropTypes.map.isRequired,
  countryIsoCode: string.isRequired,
};

ContactInfo.defaultProps = {
  title: '',
  address: '',
  city: '',
  countryIso: '',
  idNumber: '',
  phone: '',
  contactEmail: '',
  website: '',
};

export default ContactInfo;
