import React from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from '../news.module.scss';

const NoArticles = (props) => {
  const { t } = useTranslation();

  if (!props.noArticles) {
    return null;
  }
  return (
    <div className={styles.noArticles}>
      <p className={styles.noArticlesText}>
        {t('news noArticles')}
      </p>
    </div>
  );
};

NoArticles.propTypes = { noArticles: bool.isRequired };

export default NoArticles;
