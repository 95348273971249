import React from 'react';
import { string } from 'prop-types';

const CowIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 52.436 32.988"
  >
    <g
      id="prefix__Group_659"
      data-name="Group 659"
      transform="translate(-959.617 -486.756) translate(712.198 -40.855)"
    >
      <g id="prefix__Group_79" data-name="Group 79">
        <g id="prefix__Group_73" data-name="Group 73">
          <g id="prefix__Group_71" data-name="Group 71">
            <g id="prefix__Group_70" data-name="Group 70">
              <path
                id="prefix__Path_70"
                d="M289.716 545.623c-.283 0-.566-.01-.851-.03a11.549 11.549 0 0 1-7.952-3.984 2 2 0 0 1 .2-2.822l1.538-1.331a11.62 11.62 0 0 1 16.389 1.18l.515.594a1.213 1.213 0 0 1-.123 1.712l-2.13 1.845a11.531 11.531 0 0 1-7.586 2.836zm-4.267-5.292a7.627 7.627 0 0 0 9.057-.417 7.639 7.639 0 0 0-9.057.417z"
                className="prefix__cls-1"
                data-name="Path 70"
                style={{ fill: color }}
              />
            </g>
          </g>
          <g id="prefix__Group_72" data-name="Group 72">
            <path
              id="prefix__Path_71"
              d="M280.356 536.263v-4a4.661 4.661 0 0 0 4.432-3.239l.016-.05a1.866 1.866 0 0 1 1.766-1.363h.354a1.837 1.837 0 0 1 1.762 2.34 8.668 8.668 0 0 1-8.33 6.312z"
              className="prefix__cls-1"
              data-name="Path 71"
              style={{ fill: color }}
            />
          </g>
        </g>
        <g id="prefix__Group_78" data-name="Group 78">
          <g id="prefix__Group_75" data-name="Group 75">
            <g id="prefix__Group_74" data-name="Group 74">
              <path
                id="prefix__Path_72"
                d="M259.348 545.623a11.534 11.534 0 0 1-7.59-2.837l-2.13-1.845a1.213 1.213 0 0 1-.123-1.712l.512-.591a11.624 11.624 0 0 1 16.4-1.18l1.535 1.328a2 2 0 0 1 .2 2.822 11.541 11.541 0 0 1-7.952 3.984c-.286.022-.57.031-.852.031zm-4.791-5.709a7.627 7.627 0 0 0 9.057.417 7.636 7.636 0 0 0-9.057-.417z"
                className="prefix__cls-1"
                data-name="Path 72"
                style={{ fill: color }}
              />
            </g>
          </g>
          <g id="prefix__Group_76" data-name="Group 76">
            <path
              id="prefix__Path_73"
              d="M268.708 536.263a8.666 8.666 0 0 1-8.329-6.311 1.837 1.837 0 0 1 1.762-2.34h.366a1.854 1.854 0 0 1 1.753 1.362v.013a4.66 4.66 0 0 0 4.444 3.277v4z"
              className="prefix__cls-1"
              data-name="Path 73"
              style={{ fill: color }}
            />
          </g>
          <g id="prefix__Group_77" data-name="Group 77">
            <path
              id="prefix__Path_74"
              d="M275.571 559.017H272.4a4.978 4.978 0 0 1-4.878-4.017l-2.989-15.482a9.946 9.946 0 0 1 19.892 0v.257l-4.047 15.525a4.97 4.97 0 0 1-4.807 3.717zm-7.036-19.677l2.912 14.9a.97.97 0 0 0 .949.782h3.174a.968.968 0 0 0 .936-.724l3.914-15.012a5.946 5.946 0 0 0-11.885.059z"
              data-name="Path 74"
              style={{ fill: '#050713' }}
            />
          </g>
        </g>
      </g>
      <path
        id="prefix__Path_75"
        d="M297.252 540.218a11.614 11.614 0 0 0-15.092-2.138c-.1-.3-.2-.595-.327-.882a8.686 8.686 0 0 0 5.061-5.663 1.837 1.837 0 0 0-1.762-2.34h-.354a1.866 1.866 0 0 0-1.766 1.363l-.016.05a4.662 4.662 0 0 1-3.613 3.162 9.886 9.886 0 0 0-13.369-.021 4.66 4.66 0 0 1-3.541-3.18v-.013a1.854 1.854 0 0 0-1.753-1.362h-.366a1.837 1.837 0 0 0-1.762 2.34 8.686 8.686 0 0 0 4.972 5.628c-.122.281-.229.569-.325.863a11.617 11.617 0 0 0-15.008 2.2l-.512.591a1.213 1.213 0 0 0 .123 1.712l2.13 1.845a11.532 11.532 0 0 0 7.59 2.837q.423 0 .851-.03a11.552 11.552 0 0 0 5.187-1.654l2.136 11.063a4.979 4.979 0 0 0 4.875 4.015h3.174a4.97 4.97 0 0 0 4.807-3.715l3.009-11.543a11.544 11.544 0 0 0 5.478 1.834c.285.021.568.03.851.03a11.532 11.532 0 0 0 7.59-2.837l2.13-1.845a1.213 1.213 0 0 0 .123-1.712zm-39.132 2.968a7.529 7.529 0 0 1-5.355-1.689 7.638 7.638 0 0 1 9.058.417 7.571 7.571 0 0 1-3.703 1.272zm16.6 12.691a.968.968 0 0 1-.936.724H270.6a.969.969 0 0 1-.949-.782l-2.912-14.894a5.947 5.947 0 0 1 11.886-.059zm12.645-12.691a7.571 7.571 0 0 1-3.7-1.272 7.641 7.641 0 0 1 9.058-.417 7.552 7.552 0 0 1-5.363 1.689z"
        data-name="Path 75"
        style={{ opacity: 0.2, fill: color }}
      />
    </g>
  </svg>
);

CowIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

CowIcon.defaultProps = {
  color: '#37ead9',
  width: '52.436',
  height: '32.988',
};

export default CowIcon;
