import { parseError } from 'utils/parse-helper';
import { AuthorizedApiService } from './apiService';

export const getCompanyByEmailService = async (email) => AuthorizedApiService.get(`company/email/${email}`)
  .then((res) => {
    return res;
  })
  .catch((err) => parseError(err));

export const getCompanyDetailsService = async () => AuthorizedApiService.get('company')
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const getCompanyDetailsByIdService = async (id) => AuthorizedApiService.get(`company/${id}`)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const getCompanyDetailsBySlugService = async (slug) => AuthorizedApiService.get(`company/slug/${slug}`)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const saveCompanyDetailsService = async (id, companyData) => AuthorizedApiService.put(`company/${id}`, companyData)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const getCompaniesService = async (queryString) => AuthorizedApiService.get('companies', { params: queryString })
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const getConversationsService = async (id) => AuthorizedApiService.get(`company/${id}/conversations`)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const rateCompanyService = async (id, postData) => AuthorizedApiService.put(`company/${id}/rate`, postData)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const deleteCompanyByIdService = async (id) => AuthorizedApiService.delete(`company/${id}`)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const updateCompanyByIdService = async (companyId, formData) => AuthorizedApiService.put(`company/admin/${companyId}`, formData)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const verifyCompanyService = async (companyId) => AuthorizedApiService.put(`company/${companyId}/verify`, { verify: true })
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));
