import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectActiveCompanyColorTheme, selectActiveCompanyFontFamily, selectActiveCompanySlug } from 'selectors/auth';
import { setChangesMade } from 'actions/auth';

const mapStateToProps = (state) => ({
  colorTheme: selectActiveCompanyColorTheme(state),
  fontFamily: selectActiveCompanyFontFamily(state),
  activeCompanySlug: selectActiveCompanySlug(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setChangesMade,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
