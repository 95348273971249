import { StrapiService } from './apiService';
import { parseError } from '../utils/parse-helper';

const bannersUrl = '/banner';

export const getBanner = async () => StrapiService.get(bannersUrl)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export default null;
