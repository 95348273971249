export const truncateString = (string, limit) => {
  if (string !== undefined && typeof string === 'string') {
    if (string.length > limit) {
      return `${string.substring(0, limit)}...`;
    }
    return string;
  }
  return '';
};

export default truncateString;
