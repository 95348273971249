import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  selectActiveCompanyBackgroundImageUrl,
  selectActiveCompanyColorTheme,
  selectActiveCompanyFontFamily,
  selectActiveCompanyBackgroundImageThumbnailUrl,
  selectActiveCompanyId,
  selectActiveCompanyLogo,
} from 'selectors/auth';
import { setChangesMade } from 'actions/auth';
import { showModal } from '../ColorPalette/modules/actions';

import {
  showModal as showBackgroundImageSelectModal,
  getBackgroundImages,
} from '../SelectHeaderBackground/modules/actions';

const mapStateToProps = (state) => ({
  imageBackground: selectActiveCompanyBackgroundImageUrl(state),
  imageBackgroundThumbnail: selectActiveCompanyBackgroundImageThumbnailUrl(
    state,
  ),
  colorTheme: selectActiveCompanyColorTheme(state),
  fontFamily: selectActiveCompanyFontFamily(state),
  companyId: selectActiveCompanyId(state),
  companyLogo: selectActiveCompanyLogo(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showModal,
      showBackgroundImageSelectModal,
      getBackgroundImages,
      setChangesMade,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
