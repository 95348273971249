import React from 'react';
import PropTypes from 'prop-types';

const ColoredLine = (props) => {
  const {
    color,
    height,
  } = props;
  return (
    <div>
      <hr
        style={{
          color,
          backgroundColor: color,
          height,
        }}
      />
    </div>
  );
};

const { string, number } = PropTypes;

ColoredLine.propTypes = {
  color: string.isRequired,
  height: number.isRequired,
};
export default ColoredLine;
