import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setNextRoute, setNextRouteParams, showModal } from '../../Login/modules/actions';
import { selectHomepageProducts, selectHomepageDemands } from '../modules/selectors';
import { getHomepageContent } from '../modules/actions';

const mapStateToProps = (state) => ({
  products: selectHomepageProducts(state),
  demands: selectHomepageDemands(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getHomepageContent,
      showModal,
      setNextRouteParams,
      setNextRoute,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
