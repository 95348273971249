import {
  all, call, takeLatest, put,
} from 'redux-saga/effects';
import i18n from 'i18next';
import { notification } from 'antd';
import { List as IList } from 'immutable';
import { getInterestsService, deleteInterestService, saveInterestsService } from 'services/InterestsService';
import { types, setInterests, setIndustryInterests } from './actions';
import { getIndustryInterestsService } from '../../../services/industryInterestsService';

function * getInterests(action) {
  const { queryString } = action.payload;
  const { data, error } = yield call(getInterestsService, queryString);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setInterests(data));
  }
}

function * saveInterests(action) {
  const { formData, deletedInterests, deletedIndustryInterests } = action.payload.postData;
  const newFormData = ['interests', 'industryInterests'].reduce((acc, n) => {
    const key = n === 'interests' ? 'name' : 'industryId';
    acc[n] = formData
      .get(n, IList())
      .map((item) => ({ [key]: item.get(key), id: item.get('id', null) }));
    return acc;
  }, {});
  const postData = {
    showInterests: formData.get('showInterests', false),
    interests: [
      ...newFormData.interests,
      ...deletedInterests.toJS(),
    ],
    industryInterests: [
      ...newFormData.industryInterests,
      ...deletedIndustryInterests.toJS(),
    ],
  };
  const { error } = yield call(saveInterestsService, localStorage.getItem('userId'), postData);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: i18n.t('interests errorAddingInterest'),
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
    });
  }
}

function * deleteInterest(action) {
  const { id } = action.payload;
  const { error } = yield call(deleteInterestService, id);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: i18n.t('interests errorDeletingInterest'),
    });
  } else {
    yield getInterests();
  }
}

function * handleGetIndustryInterests() {
  const { data, error } = yield call(getIndustryInterestsService);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setIndustryInterests({ industryInterests: data }));
  }
}

function * handleSaveIndustryInterests(action) {
  const { postData } = action.payload;
}

export default function * interestsSaga() {
  yield all([
    takeLatest(types.GET_INTERESTS, getInterests),
    takeLatest(types.SAVE_INTERESTS, saveInterests),
    takeLatest(types.DELETE_INTEREST, deleteInterest),
    takeLatest(types.GET_INDUSTRY_INTERESTS, handleGetIndustryInterests),
    takeLatest(types.SAVE_INDUSTRY_INTERESTS, handleSaveIndustryInterests),
  ]);
}
