/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { rubbish, addIcon, commentTags } from 'images/createCompany';
import { Upload, notification } from 'antd';
import { imageUploadMaxLimit, textInputMaxLength } from 'utils/constants';
import { loader } from 'images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import buttonStyles from 'utils/buttonStyle.module.scss';
import ImgCrop from 'antd-img-crop';
import styles from './ourClients.module.scss';

const ProductItem = (props) => {
  const {
    textAlignment,
    viewMode,
    comment,
    name,
    position,
    setClientsData,
    itemKey,
    imageUrl,
    handleChange,
    uploadedImage,
    unsavedCard,
    actions,
  } = props;
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    key: itemKey,
    comment,
    name,
    position,
    imageUrl,
  });
  const [uploading, setUploadingStatus] = useState(false);
  useEffect(() => {
    setClientsData(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);
  useEffect(() => {
    setFormData({
      key: itemKey,
      comment,
      name,
      position,
      imageUrl: uploadedImage || imageUrl,
    });
  }, [comment, itemKey, name, position, imageUrl, uploadedImage]);
  const clearCard = () => {
    actions.setChangesMade(true);
    setClientsData({
      key: formData.key,
      comment: '',
      name: '',
      position: '',
      imageUrl: '',
    });
  };
  const renderImg = (url, altText) => (
    <LazyLoadImage
      alt={altText}
      effect="blur"
      src={url}
      className={classNames(styles.addIconImgStyle, !imageUrl && styles.imageNotUploaded)}
      placeholderSrc={addIcon}
    />
  );
  const handleImageUpload = (data) => {
    if (data.file.status === 'uploading') {
      setUploadingStatus(true);
    }
  };

  const renderUploadComponent = (isUploading, imgUrl) => {
    if (isUploading) {
      return renderImg(loader, 'Image loader');
    }
    if (imgUrl) {
      return renderImg(imgUrl, 'Testimonial user');
    }
    return renderImg(addIcon, 'Add user');
  };
  const updateForm = (property, value) => {
    const newFormData = { ...formData };
    if (property) newFormData[property] = value;
    else {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in newFormData) {
        if (newFormData[key]) newFormData[key] = '';
      }
    }
    setFormData(newFormData);
    actions.setChangesMade(true);
  };
  const uploadSuccess = (options) => {
    handleChange(itemKey, options);
    actions.setChangesMade(true);
  };

  useEffect(() => {
    setUploadingStatus(false);
  }, [formData.imageUrl]);

  return (
    <div>
      <div
        className={classNames(
          styles.clientItemContainer,
          textAlignment === 'text-center'
            ? 'align-items-center'
            : textAlignment === 'text-right'
              ? 'align-items-end'
              : '',
          unsavedCard && styles.unsavedCard,
        )}
      >
        {renderUploadComponent(uploading, formData.imageUrl)}
        <ImgCrop
          modalTitle={t('imageCropperModal modalTitle')}
          modalOk={t('imageCropperModal okButton')}
          modalCancel={t('imageCropperModal cancelButton')}
          grid={false}
          shape="round"
          fillColor="rgba(255, 255, 255, 0.0)"
          minZoom={0.5}
          cropperProps={{ restrictPosition: false }}
        >
          <Upload
            name="avatar"
            listType="picture-card"
            showUploadList={false}
            onChange={handleImageUpload}
            className={styles.imageUploadButtonContainer}
            beforeUpload={(file) => {
              const isImageFile = file.type.includes('image');
              if (!isImageFile) {
                notification.error({
                  message: t('notification error'),
                  description: t('imageUpload typeImageError'),
                });
                setTimeout(() => setUploadingStatus(false), 1000);
              }
              return isImageFile;
            }}
            customRequest={(options) => {
              const isLimitSizeExceeded = options.file.size / 1024 / 1024 >= imageUploadMaxLimit;
              if (isLimitSizeExceeded) {
                notification.error({
                  message: t('notification error'),
                  description: t('imageUpload fileTooLarge'),
                });
                setTimeout(() => setUploadingStatus(false), 1000);
              } else {
                options.onSuccess(uploadSuccess(options));
              }
            }}
          >
            <button
              type="button"
              className={classNames(
                buttonStyles.buttonSuccessStyle,
                styles.buttonSuccessStyle,
                viewMode === 'view' ? 'd-none' : '',
              )}
            >
              {t('createCompany ourClients addPictureButtonText')}
            </button>
          </Upload>
        </ImgCrop>
        <img
          src={commentTags}
          alt="clients comments"
          className={classNames(styles.commentTagImageStyle, 'my-3')}
        />
        <p
          className={classNames(
            styles.textCounterContainer,
            viewMode === 'view' && 'd-none',
          )}
        >
          {`${formData.comment.length}/${textInputMaxLength}`}
        </p>
        {viewMode === 'edit' ? (
          <textarea
            type="text"
            placeholder={t(
              'createCompany ourClients inputClientComment placeholder',
            )}
            name="inputClientComment"
            className={classNames(
              styles.inputClientComment,
              textAlignment,
              viewMode === 'view' ? styles.noBorderStyle : '',
            )}
            rows={10}
            value={formData.comment}
            onChange={(e) => {
              updateForm('comment', e.target.value);
            }}
            maxLength={textInputMaxLength}
          />
        ) : (
          <p
            className={classNames(
              styles.inputClientComment,
              textAlignment,
              styles.noBorderStyle,
            )}
          >
            {formData.comment}
          </p>
        )}
        <div className={classNames(styles.horizontalBreakline)} />
        <input
          type="text"
          placeholder={t(
            'createCompany ourClients inputClientName placeholder',
          )}
          name="inputClientName"
          className={classNames(
            styles.inputClientName,
            textAlignment,
            viewMode === 'view' ? styles.noBorderStyle : '',
          )}
          disabled={viewMode === 'view'}
          value={formData.name}
          onChange={(e) => {
            updateForm('name', e.target.value);
          }}
        />
        <input
          type="text"
          placeholder={t(
            'createCompany ourClients inputClientPosition placeholder',
          )}
          name="inputClientPosition"
          className={classNames(
            styles.inputClientPosition,
            textAlignment,
            viewMode === 'view' ? styles.noBorderStyle : '',
          )}
          disabled={viewMode === 'view'}
          value={formData.position}
          onChange={(e) => {
            updateForm('position', e.target.value);
          }}
        />
        <div
          className={classNames(
            styles.rubbishIconStyle,
            textAlignment === 'text-center'
              ? 'mx-auto'
              : textAlignment === 'text-right'
                ? 'ml-auto'
                : '',
            'mx-3',
            viewMode === 'view' ? 'invisible' : '',
          )}
          onClick={clearCard}
          onKeyPress={clearCard}
        >
          <img src={rubbish} alt="delete" />
        </div>
      </div>
    </div>
  );
};

const {
  string, func, bool, shape,
} = PropTypes;

ProductItem.propTypes = {
  viewMode: string.isRequired,
  textAlignment: string.isRequired,
  comment: string.isRequired,
  name: string.isRequired,
  position: string.isRequired,
  setClientsData: func.isRequired,
  itemKey: string.isRequired,
  imageUrl: string.isRequired,
  handleChange: func.isRequired,
  uploadedImage: string.isRequired,
  unsavedCard: bool.isRequired,
  actions: shape({
    setChangesMade: func.isRequired,
  }).isRequired,
};

export default ProductItem;
