import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  Button, Table, Popconfirm, Input, Space,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import times from 'lodash/times';
import classNames from 'classnames';
import styles from '../adminPanel.module.scss';
import history from '../../../history';

const renderActionsColumn = (id, type) => (
  <div className="d-flex flex-column align-items-center justify-content-center">
    <Button
      onClick={() => history.push(`/view/${type}/${id}`)}
      type="default"
      className="w-100 mb-2"
    >
      View Item
    </Button>
    <Button
      onClick={() => history.push(`/edit/${type}/${id}`)}
      type="primary"
      className="w-100"
    >
      Edit Item
    </Button>
  </div>
);

const renderDeleteConformation = (handleDelete, id, type) => (
  <Popconfirm
    title="Are you sure about deleting this"
    onConfirm={() => {
      handleDelete(id, type);
    }}
    okText="Ok"
    cancelText="Cancel"
  >
    <Button danger>Delete</Button>
  </Popconfirm>
);

const ProductsTable = (props) => {
  const {
    tableContent,
    actions,
    currentPage,
    updatePage,
    disableNext,
    loading,
    handleFilterData,
    handleFiltersReset,
    availablePages,
  } = props;
  const [dataSource, setDataSource] = useState([]);
  const [searchState, updateSearchState] = useState({
    searchedColumn: '',
    searchText: '',
  });

  useEffect(() => {
    setDataSource([]);
    updateSearchState({ searchedColumn: '', searchText: '' });
    if (tableContent.size > 0) {
      tableContent.map((item, index) => setDataSource((prevState) => prevState.concat({
        key: index,
        name: item.get('name', ''),
        company: item.getIn(['company', 'name'], ''),
        price: item.get('price', ''),
        createdAt: item.get('createdAt', ''),
        updatedAt: item.get('updatedAt', ''),
        isFeatured: item.get('isFeatured') ? 'Featured' : 'Not Featured',
        action: renderActionsColumn(item.get('id', ''), item.get('type', '')),
        delete: renderDeleteConformation(
          actions.deleteProductById,
          item.get('id', ''),
          item.get('type', ''),
        ),
      })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableContent]);

  const handleSearch = (selectedKeys, confirm, dataIndex, value) => {
    confirm();
    handleFilterData(dataIndex, value);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    handleFiltersReset();
  };

  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className="p-4">
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={(e) => handleSearch(selectedKeys, confirm, dataIndex, e.target.value)}
          style={{ width: 188, marginBottom: 15, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(
              selectedKeys,
              confirm,
              dataIndex,
                searchInput?.state?.value || '',
            )}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined className={classNames(filtered && 'bg-primary')} />
    ),
    render: (text) => (searchState.searchedColumn === dataIndex ? searchState.searchText : text),
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
    {
      title: 'Featured?',
      dataIndex: 'isFeatured',
      key: 'isFeatured',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
    },
  ];

  return (
    <div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{ x: '100%' }}
        className={styles.tableContainer}
        loading={loading}
      />
      <div className={classNames(styles.paginationContainer, 'mx-auto')}>
        {!loading && (
          <h5
            role="presentation"
            onClick={() => updatePage(currentPage - 1)}
            className={classNames(currentPage === 1 && 'invisible', 'mr-3')}
          >
            Previous
          </h5>
        )}
        <div className={styles.paginationNumbersRow}>
          {times(availablePages, (page) => (
            <div
              className={classNames(
                styles.page,
                page + 1 === currentPage && styles.activePage,
              )}
              key={page}
              role="presentation"
              onClick={() => updatePage(page + 1)}
            >
              <p>{page + 1}</p>
            </div>
          ))}
        </div>
        {!loading && (
          <h5
            role="presentation"
            onClick={() => updatePage(currentPage + 1)}
            className={classNames(disableNext && 'invisible', 'ml-3')}
          >
            Next
          </h5>
        )}
      </div>
    </div>
  );
};
const {
  func, shape, number, bool,
} = PropTypes;
const { list } = ImmutablePropTypes;

ProductsTable.propTypes = {
  tableContent: list.isRequired,
  actions: shape({
    deleteProductById: func.isRequired,
  }).isRequired,
  currentPage: number.isRequired,
  updatePage: func.isRequired,
  disableNext: bool.isRequired,
  loading: bool.isRequired,
  handleFilterData: func.isRequired,
  handleFiltersReset: func.isRequired,
  availablePages: number.isRequired,
};

export default ProductsTable;
