import { List as IList } from 'immutable';

export const selectConversations = (state) => {
  const conversations = state.chatReducer
    .get('conversations', IList())
    .sort((a, b) => {
      const dateA = new Date(a.get('lastMessageTime', ''));
      const dateB = new Date(b.get('lastMessageTime', ''));
      return dateB - dateA;
    });
  return conversations;
};
export const selectMessages = (state) => state.chatReducer.get('messages');
export const selectConversationId = (state) => state.chatReducer.get('conversationId', '');
export const selectVisibilityMessagesList = (state) => state.chatReducer.get('visibilityMessagesList', '');
export const selectVisibilityCompaniesList = (state) => state.chatReducer.get('visibilityCompaniesList', '');
export const selectActiveCompanies = (state) => state.chatReducer.get('activeCompanies', IList());
export const selectCompanyId = (state) => state.chatReducer.get('companyId', '');
export const selectChatFromRoute = (state) => state.chatReducer.get('chatFromRoute', '');
export const selectTitle = (state) => state.chatReducer.get('title', '');
export const selectCompanyName = (state) => state.chatReducer.get('companyName', '');
export const selectHideMessageList = (state) => state.chatReducer.get('hideMessageList', '');
export const selectActiveConversationProductId = (state) => state.chatReducer.get('activeConversationProductId', null);
export const selectNewMessagesCount = (state) => state.chatReducer.get('newMessagesCount', 0);
