import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import buttonStyles from 'utils/buttonStyle.module.scss';
import { map } from 'images/createCompany';
import { composeViewCompanyUrl } from 'utils/pathsHelper';
import styles from './companyCard.module.scss';
import history from '../../../history';

function forwardTo(location) {
  history.push(location);
}

const CompanyCard = (props) => {
  const {
    item,
    actions,
  } = props;
  const { t } = useTranslation();

  const handleCompany = (data) => {   
    actions.setChatRoute('company');
    actions.setCompanyChat({
      id: data.get('id', ''),
      name: data.get('name', ''),
    });
    if (localStorage.getItem('userId')) {
      forwardTo('/messenger');
    } 
    else {
      actions.setNextRoute('/messenger');
      actions.showModal();
    }
  };
  
  return (
    <div className={classNames(styles.feedCardContainer, 'px-3')}>
      <div className={styles.companyLogoContainer}>
        <img src={item.getIn(['theme', 'companyLogo'], map)} alt="" />
      </div>
      <div className="d-flex">
        <p>{`${t('companyName titleText')}:`}</p>
        <span className="pl-2 font-weight-bold">{item.get('name', '')}</span>
      </div>
      <div className={styles.buttonContainer}>
        <Link
          to={composeViewCompanyUrl(item.get('slug', ''))}
          target="_blank"
          className={classNames(
            buttonStyles.buttonSuccessStyle,
            styles.customButtonStyles,
          )}
        >
          {t('feed companySearch viewCompanyButton')}
        </Link>
        { item.get('id', null) !== parseInt(localStorage.getItem('userId'), 10)
          ? (
            <button
              type="button"
              onClick={() => {
                handleCompany(item);
              }}
              className={classNames(
                buttonStyles.buttonSuccessStyle,
                styles.customButtonStyles,
              )}
            >
              {t('feed companySearch sendMessageButton')}
            </button>
          )
          : null }
      </div>
    </div>
  );
};

const { mapContains } = ImmutablePropTypes;
const { number, shape, func } = PropTypes;

CompanyCard.propTypes = {
  item: mapContains({
    id: number.isRequired,
  }).isRequired,
  actions: shape({
    setChatRoute: func.isRequired,
    setCompanyChat: func.isRequired,
  }).isRequired,
};

export default CompanyCard;
