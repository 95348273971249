import React from 'react';
import { string } from 'prop-types';

const DiscountIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 51.292 38.291"
  >
    <g
      id="prefix__Group_661"
      data-name="Group 661"
      transform="translate(-1278 -481.63)"
    >
      <g
        id="prefix__Group_622"
        data-name="Group 622"
        transform="translate(-807.061 -251)"
      >
        <path
          id="prefix__Path_662"
          d="M272.1 608.847a1.5 1.5 0 0 1-.141-2.117l13-14.859a1.5 1.5 0 0 1 2.116-.141 1.5 1.5 0 0 1 .141 2.116l-13 14.859a1.5 1.5 0 0 1-2.116.142z"
          className="prefix__cls-1"
          data-name="Path 662"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_663"
          d="M284.952 611.139a4.947 4.947 0 1 1 4.947-4.947 4.953 4.953 0 0 1-4.947 4.947zm0-6.894a1.947 1.947 0 1 0 1.947 1.946 1.948 1.948 0 0 0-1.947-1.946z"
          className="prefix__cls-2"
          data-name="Path 663"
          transform="translate(1839 151)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_664"
          d="M273.422 599.371a4.947 4.947 0 1 1 4.947-4.947 4.953 4.953 0 0 1-4.947 4.947zm0-6.893a1.947 1.947 0 1 0 1.947 1.946 1.948 1.948 0 0 0-1.947-1.946z"
          className="prefix__cls-2"
          data-name="Path 664"
          transform="translate(1839 151)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_665"
          d="M291.51 618.631H249.5a2.152 2.152 0 0 1-2.15-2.15v-8.107a2.155 2.155 0 0 1 1.869-2.128 6.154 6.154 0 0 0 .017-12.228 2.159 2.159 0 0 1-1.886-2.13v-8.108a2.152 2.152 0 0 1 2.15-2.15h42.01a5.849 5.849 0 0 1 5.843 5.843v25.317a5.849 5.849 0 0 1-5.843 5.841zm-40.157-4h40.157a1.845 1.845 0 0 0 1.843-1.843v-25.317a1.845 1.845 0 0 0-1.843-1.843h-40.157v4.751a10.149 10.149 0 0 1 0 19.5v4.753z"
          className="prefix__cls-1"
          data-name="Path 665"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_666"
          d="M285.78 593.022a1.5 1.5 0 0 0-2.116.141l-13 14.859A1.5 1.5 0 1 0 272.92 610l13-14.859a1.5 1.5 0 0 0-.14-2.119z"
          className="prefix__cls-2"
          data-name="Path 666"
          transform="translate(1839 151)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_667"
          d="M283.661 602.537a4.947 4.947 0 1 0 4.947 4.946 4.952 4.952 0 0 0-4.947-4.946zm0 6.893a1.947 1.947 0 1 1 1.947-1.947 1.949 1.949 0 0 1-1.947 1.947z"
          className="prefix__cls-3"
          data-name="Path 667"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
        <path
          id="prefix__Path_668"
          d="M277.078 595.716a4.947 4.947 0 1 0-4.947 4.947 4.951 4.951 0 0 0 4.947-4.947zm-6.894 0a1.947 1.947 0 1 1 1.947 1.947 1.949 1.949 0 0 1-1.947-1.947z"
          className="prefix__cls-3"
          data-name="Path 668"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
        <path
          id="prefix__Path_669"
          d="M290.218 582.92h-42.007a2.152 2.152 0 0 0-2.15 2.15v8.108a2.159 2.159 0 0 0 1.886 2.13 6.154 6.154 0 0 1-.017 12.228 2.155 2.155 0 0 0-1.869 2.128v8.107a2.152 2.152 0 0 0 2.15 2.15h42.007a5.849 5.849 0 0 0 5.843-5.843v-25.317a5.849 5.849 0 0 0-5.843-5.841zm1.843 31.16a1.845 1.845 0 0 1-1.843 1.843h-40.157v-4.753a10.149 10.149 0 0 0 0-19.5v-4.75h40.157a1.845 1.845 0 0 1 1.843 1.843z"
          className="prefix__cls-3"
          data-name="Path 669"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
      </g>
    </g>
  </svg>
);

DiscountIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

DiscountIcon.defaultProps = {
  color: '#37ead9',
  width: '51.292',
  height: '38.291',
};

export default DiscountIcon;
