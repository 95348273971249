
import React from 'react';
import {
  bos, cro, eng, srb,
} from '../../../../../images/flags';
import styles from './langunageSelector.module.scss';

const languageOptions = [
  {
    value: 'bos',
    label: (
      <div className={styles.optionItem}>
        <img src={bos} alt="bos" />
        <h5>BOS</h5>
      </div>
    ),
  },
  {
    value: 'eng',
    label: (
      <div className={styles.optionItem}>
        <img src={eng} alt="eng" />
        <h5>ENG</h5>
      </div>
    ),
  },
  {
    value: 'cro',
    label: (
      <div className={styles.optionItem}>
        <img src={cro} alt="cro" />
        <h5>CRO</h5>
      </div>
    ),
  },
  {
    value: 'srb',
    label: (
      <div className={styles.optionItem}>
        <img src={srb} alt="srb" />
        <h5>SRB</h5>
      </div>
    ),
  },
];

export default languageOptions;
