import { fromJS } from 'immutable';

const productsAndServices = fromJS([
  {
    key: '0',
    title: '',
    description: '',
  },
  {
    key: '1',
    title: '',
    description: '',
  },
  {
    key: '2',
    title: '',
    description: '',
  },
  {
    key: '3',
    title: '',
    description: '',
  },
  {
    key: '4',
    title: '',
    description: '',
  },
  {
    key: '5',
    title: '',
    description: '',
  },
]);

export default productsAndServices;
