import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  createdProductDemandUrl,
  composeEditCompanyUrl,
} from 'utils/pathsHelper';
import PictureExpandModal from 'components/PictureExpand';
import { useTranslation } from 'react-i18next';
import { RightOutlined } from '@ant-design/icons';
import styles from './navbar.module.scss';
import stylesTab from './Tabs/tabs.module.scss';
import Logo from './Logo/View';
import LoggedUserInfo from './LoggedUserInfo';
import CompanyLogo from './CompanyLogo';
import Tabs from './Tabs';
import NumberOfViews from './NumberOfViews';
import Notifications from './Notifications';
import ButtonMenu from './ButtonMenu/View';
import BizbookMenu from './BizbookMenu/View';
import useScrollPosition from '../../../utils/hooks/useScrollPosition';

const NavBarLoggedUser = (props) => {
  const {
    actions,
    activeCompanyId,
    location: { pathname },
    companySlug,
    activeCompanySlug,
  } = props;
  const [collapsedNavbar, setCollapsedNavbar] = useState(true);
  const companyId = parseInt(localStorage.getItem('userId'));
  const [isScrollUp, setIsScrollUp] = useState(true);

  const { t } = useTranslation();
  const toggleNavbar = () => {
    setCollapsedNavbar(!collapsedNavbar);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScrollPosition(setIsScrollUp);
  }, []);

  useEffect(() => {
    actions.loadCompanyDetails();
    actions.login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeNavbar = () => {
    setCollapsedNavbar(true);
  };

  return (
    <div>
      <nav
        className={classNames(
          styles.navbar,
          'navbar navbar-expand-lg',
          pathname === createdProductDemandUrl && 'd-none'
        )}
      >
        <div className="container">
          <div className={styles.containerRow}>
            <div className={styles.companyLogo}>
              <CompanyLogo />
              <div className={styles.numberOfViews}>
                <NumberOfViews />
              </div>
            </div>
            <div className={styles.bizbookLogo}>
              <Logo />
              <div className="px-2 pt-1">
                <BizbookMenu />
              </div>
            </div>
          </div>

          <div className={styles.containerRow}>
            <div className={styles.tabs}>
              <Tabs
                activeCompanyId={activeCompanyId}
                activeCompanySlug={activeCompanySlug}
              />
              <NumberOfViews />
              <Notifications />
            </div>
            {companyId === activeCompanyId || !activeCompanyId ? (
              <div className={styles.userInfo}>
                <LoggedUserInfo />
              </div>
            ) : (
              <span className={classNames(styles.backToProfileContainer)}>
                <Link
                  to={composeEditCompanyUrl(companySlug)}
                  className={classNames(styles.backToProfile, 'px-3')}
                  onClick={closeNavbar}
                >
                  {t('nav bar logged user tabs back on profile')}
                  <RightOutlined
                    className={classNames(styles.backToProfileIcon, 'pl-3')}
                  />
                </Link>
              </span>
            )}
          </div>

          <div className={styles.sideMenu}>
            {/* hamburger menu */}
            <div className={styles.buttonMenu}>
              <ButtonMenu
                collapsedNavbar={collapsedNavbar}
                toggleNavbar={toggleNavbar}
              />
            </div>
            <div className={styles.notifications}>
              <Notifications />
            </div>
          </div>

          {!collapsedNavbar && (
            <div className={styles.sideMenu}>
              {/* side bar */}
              <div
                className={classNames(
                  'collapse navbar-collapse',
                  styles.navbarCollapse,
                  collapsedNavbar ? '' : styles.show
                )}
              >
                <div className={styles.companyLogoContainer}>
                  <div className={styles.companyLogoSm}>
                    <CompanyLogo />
                  </div>
                  <LoggedUserInfo hideBorder />
                  <Tabs
                    hideIcon
                    font="14px"
                    closeNavbar={closeNavbar}
                    activeCompanySlug={activeCompanySlug}
                  />

                  {companyId !== activeCompanyId ? (
                    <Link
                      to={composeEditCompanyUrl(companySlug)}
                      className={classNames(stylesTab.tabText, 'pl-2')}
                      onClick={closeNavbar}
                      style={{ fontSize: '14px' }}
                    >
                      {t('nav bar logged user tabs back on profile')}
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      </nav>
      <PictureExpandModal />
    </div>
  );
};

const { shape, func, string, number } = PropTypes;

NavBarLoggedUser.propTypes = {
  actions: shape({
    loadCompanyDetails: func.isRequired,
    login: func.isRequired,
  }).isRequired,
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
  activeCompanyId: number,
  companySlug: string.isRequired,
  activeCompanySlug: string.isRequired,
};

NavBarLoggedUser.defaultProps = {
  activeCompanyId: null,
};

export default withRouter(NavBarLoggedUser);
