import { fromJS } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  countriesList: [],
  industriesList: [],
  emailExists: false,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_COUNTRIES_LIST:
      return state.set('countriesList', fromJS(action.payload.listOfCountries));
    case types.SET_INDUSTRIES_LIST:
      return state.set(
        'industriesList',
        fromJS(action.payload.listOfIndustries),
      );
    case types.RECEIVE_EMAIL_EXISTS:
      return state.set('emailExists', fromJS(action.payload.emailExists));
    default:
      return state;
  }
};
