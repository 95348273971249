import {
  all,
  call,
  takeLeading,
  put,
  takeLatest,
  delay,
  select,
} from 'redux-saga/effects';
import {
  types,
  registerSuccess,
  loginSuccess,
  companyLoaded,
  clearData,
  setActiveCompanyDetails,
  setToggleDisabled,
  logoutUser as logoutUserActionCreator,
  setActiveCompanyNameIdSlug,
} from 'actions/auth';
import i18n from 'i18next';
import { notification } from 'antd';
import {
  hideModal,
  setAnimationStatus,
} from 'components/Login/modules/actions';
import JwtDecode from 'jwt-decode';
import {
  selectCompanyDetails,
  selectActiveCompanyDetails,
  selectAdminUserRole,
} from 'selectors/auth';
import isEqual from 'lodash/isEqual';
import { composeEditCompanyUrl } from 'utils/pathsHelper';
import { errorResponseParseHelper } from 'utils/constants';
import {
  getCompanyByEmailService,
  getCompanyDetailsService,
  getCompanyDetailsByIdService,
  getCompanyDetailsBySlugService,
  rateCompanyService,
  saveCompanyDetailsService,
  verifyCompanyService,
} from 'services/companyService';
import { authService } from 'services/authService';
import { selectNextRoute, selectNextRouteParams } from 'components/Login/modules/selectors';
import { showModal } from '../components/CreateCompany/ColorPalette/modules/actions';
// eslint-disable-next-line import/no-cycle
import store from '../store/index';
import history from '../history';
import { deactivateLoader } from '../components/Feed/modules/actions';

function forwardTo(location) {
  history.push(location);
}

function * registerUser(action) {
  const {
    email,
    password,
    company,
    country,
    industry,
    interests,
    industryInterests,
  } = action.payload.userData;
  const postData = {
    password,
    email,
    name: company,
    countryIso: country.name,
    industryId: industry.key,
    interests,
    industryInterests,
  };
  const { data, error } = yield call(authService, 'register', postData);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: errorResponseParseHelper(error),
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
      description: data.message,
    });
    yield put(registerSuccess(data.accessToken));
    history.push('/register-confirmed');
  }
}

function * loginUser(action) {
  const { userData } = action.payload;
  const { data, error } = yield call(authService, 'login', userData);
  if (error) {
    if (error === 'Vaš racun je blokiran' || error === 'Your account is blocked') {
      notification.error({
        message: i18n.t('notification error'),
        description: i18n.t('login blockedAccount contactSupport'),
      });
    } else {
      notification.error({
        message: i18n.t('notification error'),
        description: errorResponseParseHelper(error),
      });
    }
    yield put(setAnimationStatus(true));
  } else {
    const decodedToken = JwtDecode(data.accessToken);
    localStorage.setItem('userId', decodedToken.company.id);
    localStorage.setItem('userSlug', decodedToken.company.slug);
    localStorage.setItem('token', data.accessToken);
    localStorage.setItem('refresh_token', data.refreshToken);
    yield put(loginSuccess(data.accessToken));
    yield delay(200);
    yield loadCompanyDetails(userData.email);
    yield put(hideModal());
  }
}

function redirectAfterLogin(nextRoute, nextRouteParams, company) {
  // In app redirects
  const pathname = nextRouteParams.getIn(['pathname']);
  const id = nextRouteParams.getIn(['product', 'id']);
  const type = nextRouteParams.getIn(['product', 'type']);
  const companyId = nextRouteParams.getIn(['product', 'companyId']);

  if (pathname === '/feed' && id && type && companyId) {
    const mode = +localStorage.getItem('userId') === companyId ? 'edit' : 'view';
    return window.open(`/${mode}/${type}/${id}`);
  }

  // Outer link redirect
  if (nextRoute) {
    return history.push(nextRoute);
  }

  if (company.theme?.companyLogo) {
    return history.push(composeEditCompanyUrl(company.slug));
  }
  return history.push('/tutorial');
}

function * loadCompanyDetails(email) {
  const { data, error } = yield call(getCompanyByEmailService, encodeURIComponent(email));
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: errorResponseParseHelper(error),
    });
  } else {
    yield put(companyLoaded(data));
    yield put(setAnimationStatus(true));

    const nextRoute = yield select(selectNextRoute);
    const nextRouteParams = yield select(selectNextRouteParams);
    yield call(redirectAfterLogin, nextRoute, nextRouteParams, data);
  }
}

function * getCompanyDetails() {
  if (localStorage.getItem('userSlug') === null) {
    yield put(logoutUserActionCreator());
  } else {
    const { data, error } = yield call(getCompanyDetailsService);
    if (error) {
      notification.error({
        message: i18n.t('notification error'),
        description: errorResponseParseHelper(error),
      });
    } else {
      if (!data.theme || Object.keys(data.theme).length === 0) {
        yield put(setToggleDisabled(true));
        yield put(showModal());
      }
      if (!isEqual((yield select(selectCompanyDetails)).toJS(), data)) {
        yield put(companyLoaded(data));
      }
    }
  }
}

function * getCompanyDetailsById(action) {
  const id = action?.payload?.id || action;
  const { data, error } = isNaN(id)
    ? yield call(getCompanyDetailsBySlugService, id)
    : yield call(getCompanyDetailsByIdService, id);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: errorResponseParseHelper(error),
    });
  } else if (
    !isEqual(selectActiveCompanyDetails(store.getState()).toJS(), data)
  ) {
    yield put(setActiveCompanyDetails(data));
    yield put(setActiveCompanyNameIdSlug(data.name, data.id, data.slug));
    yield put(deactivateLoader());
  }
}

function * saveCompanyDetails(action) {
  const adminRole = selectAdminUserRole(store.getState());
  // NOTE: Delay is precaution for state update
  yield delay(100);
  const companyData = [
    'UPDATE_ACTIVE_COMPANY_DETAILS',
    'UPDATE_ACTIVE_COMPANY_JSON',
    'UPDATE_ACTIVE_COMPANY_THEME',
    'UPDATE_ACTIVE_COMPANY_BACKGROUND',
  ].includes(action.type)
    ? yield select(selectActiveCompanyDetails)
    : yield select(selectCompanyDetails);
  const postData = {
    name: companyData.get('name', ''),
    countryIso: companyData.get('countryIso', ''),
    address: companyData.get('address', ''),
    city: companyData.get('city', ''),
    idNumber: companyData.get('idNumber', ''),
    phone: companyData.get('phone', ''),
    contactEmail: companyData.get('contactEmail', ''),
    website: companyData.get('website', ''),
    companyJson: companyData.get('companyJson', ''),
    backgroundImageId: companyData.get('backgroundImageId', ''),
    facebookLink: companyData.get('facebookLink', ''),
    instagramLink: companyData.get('instagramLink', ''),
    linkedinLink: companyData.get('linkedinLink', ''),
    theme: companyData.get('theme', ''),
    industryId: companyData.get('industryId', ''),
  };
  const { error, data } = yield call(saveCompanyDetailsService, companyData.get('id', ''), postData);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: errorResponseParseHelper(error),
    });
  } else {
    yield getCompanyDetailsById(data.id);
    if (!adminRole) {
      yield getCompanyDetails();
    }
  }
}

function * logoutUser() {
  yield put(clearData());
  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('userId');
  localStorage.removeItem('userSlug');
  forwardTo('/');
}

function * rateCompany(action) {
  const { rating, id } = action.payload;
  const { error } = yield call(rateCompanyService, id, { value: rating });
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: errorResponseParseHelper(error),
    });
  }
}

function * createCompanyProfile(action) {
  const {
    email,
    password,
    company,
    country,
    industry,
    interests,
    industryInterests,
  } = action.payload.userData;
  const postData = {
    password,
    email,
    name: company,
    countryIso: country.name,
    industryId: industry.key,
    interests,
    industryInterests,
  };
  const { data, error } = yield call(authService, 'register', postData);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: errorResponseParseHelper(error),
    });
  } else {
    const verifySuccess = yield verifyCompany(data.id);
    if (verifySuccess) {
      notification.success({
        message: i18n.t('notification success'),
        description: data.message,
      });
    }
  }
}

function * verifyCompany(id) {
  const { error } = yield call(verifyCompanyService, id);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: errorResponseParseHelper(error),
    });
    return false;
  }
  return true;
}

export default function * authSaga() {
  yield all([
    takeLeading(types.REGISTER_USER, registerUser),
    takeLeading(types.LOGIN_USER, loginUser),
    takeLeading(types.LOGOUT_USER, logoutUser),
    takeLatest(types.LOAD_COMPANY_DETAILS, getCompanyDetails),
    takeLeading(types.GET_COMPANY_DETAILS_BY_ID, getCompanyDetailsById),
    takeLeading(types.RATE_COMPANY, rateCompany),
    takeLeading(
      [
        types.UPDATE_COMPANY_DETAILS,
        types.UPDATE_COMPANY_JSON,
        types.UPDATE_COMPANY_THEME,
        types.UPDATE_ACTIVE_COMPANY_DETAILS,
        types.UPDATE_ACTIVE_COMPANY_JSON,
        types.UPDATE_ACTIVE_COMPANY_THEME,
        types.UPDATE_ACTIVE_COMPANY_BACKGROUND,
      ],
      saveCompanyDetails,
    ),
    takeLeading(types.CREATE_NEW_COMPANY, createCompanyProfile),
  ]);
}
