import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { facebook, linkedIn, instagram } from 'images/socialIcons';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../aboutCompany.module.scss';

const CustomFooter = (props) => {
  const { companyData } = props;
  const { t } = useTranslation();
  return (
    <div className={styles.customFooterContainer}>
      <div className={classNames('container', styles.customFooterContent)}>
        {companyData.getIn(['theme', 'companyLogo'], '') && (
          <img
            src={companyData.getIn(['theme', 'companyLogo'], '')}
            alt="company logo"
            className={styles.companyLogoStyle}
          />
        )}
        <div className={styles.socialLinksContainer}>
          <div className={classNames('container', styles.socialLinksContent)}>
            {companyData.get(
              'linkedinLink',
              companyData.get(
                'instagramLink',
                companyData.get('facebookLink', ''),
              ),
            ) && (
              <h5 className={styles.followUsText}>
                {t('footer followUsText')}
              </h5>
            )}

            {companyData.get('linkedinLink', '') && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={companyData.get('linkedinLink', '')}
              >
                <img src={linkedIn} alt="linkedIn" />
              </a>
            )}
            {companyData.get('instagramLink', '') && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={companyData.get('instagramLink', '')}
              >
                <img src={instagram} alt="instagram" />
              </a>
            )}
            {companyData.get('facebookLink') && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={companyData.get('facebookLink')}
              >
                <img src={facebook} alt="facebook" />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CustomFooter.propTypes = {
  companyData: ImmutablePropTypes.map.isRequired,
};

export default CustomFooter;
