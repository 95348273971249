import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Progress } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './progressBar.module.scss';

const ProgressBar = (props) => {
  const { progress } = props;
  const { t } = useTranslation();
  return (
    <div className={styles.progressBarContainer}>
      {progress < 80 ? (
        <h2 className={classNames(styles.progressBarText, 'my-2')}>
          {t('createCompany progressBar progressBarText')}
        </h2>
      ) : (
        <h2 className={classNames(styles.progressBarText, 'my-2')}>
          {t('createCompany progressBar progressBarText completed')}
        </h2>
      )}
      <Progress
        percent={progress}
        showInfo={false}
        strokeLinecap="square"
        status="active"
        className={classNames(styles.progressBarStyle)}
        strokeColor={progress < 80 ? 'rgba(119, 119, 138, 0.42)' : '#37ead9'}
      />
      <div className={styles.progressBarTextContainer}>
        <h3>{`${progress} %`}</h3>
        <h5>{t('createCompany progressBar textPercentageOfDone')}</h5>
      </div>
    </div>
  );
};

const { number } = PropTypes;

ProgressBar.propTypes = {
  progress: number.isRequired,
};

export default ProgressBar;
