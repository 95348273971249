/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Upload, notification } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { loader } from 'images';
import ImgCrop from 'antd-img-crop';
import styles from './uploadLogo.module.scss';
import { imageUploadMaxLimit } from '../../../../utils/constants';

const UploadLogo = (props) => {
  const {
    actions, imageUrl, setBorder, companyLogo,
  } = props;
  const [uploading, setUploadingStatus] = useState(false);
  const { t } = useTranslation();

  const handleChange = (data) => {
    if (data.file.status === 'uploading') {
      setUploadingStatus(true);
    } else if (data.file.status === 'done') {
      setUploadingStatus(false);
    }
  };

  const renderImg = (url, altText) => (
    <img src={url} alt={altText} className="img-thumbnail" />
  );

  const renderUploadComponent = (isUploading, url, logo) => {
    if (isUploading) {
      return renderImg(loader, 'image loader');
    }
    if (url) {
      return renderImg(url, 'company logo');
    }
    if (logo) {
      return renderImg(logo, 'company logo');
    }
    return (
      <button type="submit" className={styles.buttonUploadLogoStyle}>
        {t('color palette upload logo')}
      </button>
    );
  };

  useEffect(() => {
    setUploadingStatus(false);
  }, [imageUrl]);

  return (
    <ImgCrop
      modalTitle={t('imageCropperModal modalTitle')}
      modalOk={t('imageCropperModal okButton')}
      modalCancel={t('imageCropperModal cancelButton')}
      grid={false}
      shape="rect"
      fillColor="rgba(255, 255, 255, 0.0)"
      minZoom={0.1}
      cropperProps={{ restrictPosition: false }}
    >
      <Upload
        name="avatar"
        listType="picture-card"
        showUploadList={false}
        onChange={handleChange}
        beforeUpload={(file) => {
          const isImageFile = file.type.includes('image');
          if (!isImageFile) {
            notification.error({
              message: t('notification error'),
              description: t('imageUpload typeImageError'),
            });
            setTimeout(() => setUploadingStatus(false), 1000);
          }
          return isImageFile;
        }}
        customRequest={(options) => {
          const isLimitSizeExceeded = options.file.size / 1024 / 1024 >= imageUploadMaxLimit;
          if (isLimitSizeExceeded) {
            notification.error({
              message: t('notification error'),
              description: t('imageUpload fileTooLarge'),
            });
            setTimeout(() => setUploadingStatus(false), 1000);
          } else {
            options.onSuccess(actions.uploadLogo(options), setBorder());
          }
        }}
      >
        {renderUploadComponent(uploading, imageUrl, companyLogo)}
      </Upload>
    </ImgCrop>
  );
};

const { string, shape, func } = PropTypes;

UploadLogo.propTypes = {
  actions: shape({
    uploadLogo: func.isRequired,
  }).isRequired,
  imageUrl: string.isRequired,
  setBorder: func.isRequired,
  companyLogo: string.isRequired,
};

export default UploadLogo;
