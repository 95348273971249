import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List as IList } from 'immutable';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { EyeIcon } from '../../../svgs';
import showProduct from '../../../utils/showProduct-helper';

import styles from './businessOpportunities.module.scss';

const SliderCard = (props) => {
  const { cardDetails, actions } = props;
  const { t } = useTranslation();
  const location = useLocation();

  const shortenString = (string, stringLength) => {
    const dots = string.length > stringLength ? '...' : '';
    return `${string.substring(0, stringLength)}${dots}`;
  };

  return (
    <div className={styles.sliderCard}>
      <div className={styles.titleContainer}>
        <h1>{shortenString(cardDetails.get('name', ''), 45)}</h1>
        <img
          src={cardDetails.getIn(['company', 'theme', 'companyLogo'], '')}
          alt={`${cardDetails.getIn(['company', 'name'], '')} logo`}
          className={styles.companyImage}
        />
      </div>
      <div className={styles.imgContainer}>
        <img
          src={cardDetails.getIn(['images', 0, 'url'], '')}
          alt={cardDetails.get('name')}
          className={styles.mainImage}
        />
        <div className={styles.views}>
          <EyeIcon />
          <span>{cardDetails.get('viewCount', 0)}</span>
        </div>
      </div>
      <div className={styles.offerInfo}>
        <h2>{cardDetails.getIn(['company', 'name'], '')}</h2>
        <span>{`${t('feed card datePublished')} ${moment(cardDetails.get('createdAt', '')).format('ll')}`}</span>
      </div>
      <div className={styles.tagsContainer}>
        {cardDetails.get('tags', IList()).map((tag) => (
          <div className={styles.tag}>
            <span>{tag.get('name', '')}</span>
          </div>
        ))}
      </div>
      <div className={styles.divider} />
      <div className={styles.additionalImages}>
        {cardDetails.get('images', IList()).slice(0, 3).map((image) => <img src={image.get('url', '')} alt="" />)}
      </div>
      <button
        type="button"
        className={styles.moreButton}
        onClick={() => showProduct(cardDetails, actions, location)}
      >
        {t('feed card button moreDetails')}
      </button>
    </div>
  );
};

const { map } = ImmutablePropTypes;
const { func, shape } = PropTypes;

SliderCard.propTypes = {
  cardDetails: map.isRequired,
  actions: shape({
    showModal: func.isRequired,
    setNextRoute: func.isRequired,
    setNextRouteParams: func.isRequired,
  }).isRequired,
};

export default SliderCard;
