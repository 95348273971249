import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Map as IMap } from 'immutable';
import {
  feedUrl,
  composeEditCompanyUrl,
  composeViewCompanyUrl,
  composeProductsUrl,
  companyUrls,
  composeCatalogsUrl,
} from 'utils/pathsHelper';
import { ShoppingCartOutlined } from '@ant-design/icons';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  HomeIcon, FeedIcon, SearchIcon, BookIcon,
} from '../../../../svgs';
import styles from './tabs.module.scss';
import { greenLightColor, whiteColor } from '../../../../utils/constants';

const Tabs = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const companyId = parseInt(localStorage.getItem('userId'), 10);

  const {
    hideIcon,
    font,
    closeNavbar,
    activeCompanyId,
    activeCompanySlug,
    activeCompanyDetails,
  } = props;

  return (
    <div className={styles.tabsContainer}>
      <Link
        to={
          activeCompanyId === companyId || !activeCompanyId
            ? composeEditCompanyUrl(activeCompanySlug || localStorage.getItem('userSlug'))
            : composeViewCompanyUrl(activeCompanySlug)
        }
        className={styles.tabIcon}
        onClick={closeNavbar}
      >
        <div className={hideIcon ? 'd-none' : ''}>
          <HomeIcon />
        </div>
        <div
          className={styles.tabText}
          style={{
            fontSize: font,
            color:
              location.pathname
                === composeEditCompanyUrl(activeCompanySlug)
              || location.pathname === `/${activeCompanySlug}`
                ? greenLightColor
                : whiteColor,
          }}
        >
          {t('nav bar logged user tabs about company')}
        </div>
      </Link>
      <Link
        to={composeProductsUrl(activeCompanySlug || localStorage.getItem('userSlug'))}
        className={styles.tabIcon}
        onClick={closeNavbar}
      >
        <div className={hideIcon ? 'd-none' : ''}>
          <FeedIcon />
        </div>
        <div
          className={styles.tabText}
          style={{
            fontSize: font,
            color:
              location.pathname.includes(companyUrls.products)
              && greenLightColor,
          }}
        >
          {companyId === activeCompanyId || !activeCompanyId
            ? t('nav bar logged user tabs my feed')
            : t('nav bar logged user tabs products')}
        </div>
      </Link>
      <Link to={feedUrl} className={styles.tabIcon} onClick={closeNavbar}>
        <div className={hideIcon ? 'd-none' : ''}>
          <SearchIcon />
        </div>
        <div
          className={styles.tabText}
          style={{
            fontSize: font,
            color: location.pathname === feedUrl && greenLightColor,
          }}
        >
          {t('nav bar logged user tabs search')}
        </div>
      </Link>
      <Link
        to={composeCatalogsUrl(activeCompanySlug || localStorage.getItem('userSlug'))}
        className={styles.tabIcon}
        onClick={closeNavbar}
      >
        <div className={hideIcon ? 'd-none' : ''}>
          <BookIcon />
        </div>
        <div
          className={styles.tabText}
          style={{
            fontSize: font,
            color:
              location.pathname.includes(companyUrls.catalog)
              && greenLightColor,
          }}
        >
          {t('nav bar logged user tabs catalog')}
        </div>
      </Link>
      {
        activeCompanyDetails.get('shopLink', '')
          ? (
            <Link
              to={activeCompanyDetails.get('shopLink', '')}
              target="_blank"
              className={styles.tabIcon}
              onClick={(event) => {
                event.preventDefault();
                window.open(activeCompanyDetails.get('shopLink', ''));
              }}
            >
              <div className={hideIcon ? 'd-none' : ''}>
                <ShoppingCartOutlined style={{ fontSize: 20, color: 'white' }} />
              </div>
              <div
                className={styles.tabText}
                style={{
                  fontSize: font,
                  color:
                  location.pathname.includes(companyUrls.catalog)
                  && greenLightColor,
                }}
              >
                {t('nav bar logged user tabs web shop')}
              </div>
            </Link>
          )
          : ''
      }
    </div>
  );
};

const { number, string } = PropTypes;
const { map } = ImmutablePropTypes;

Tabs.propTypes = {
  hideIcon: PropTypes.bool,
  font: string,
  closeNavbar: PropTypes.func,
  activeCompanyId: number,
  activeCompanySlug: string,
  activeCompanyDetails: map,
};

Tabs.defaultProps = {
  font: '',
  hideIcon: false,
  closeNavbar: () => {},
  activeCompanyId: null,
  activeCompanySlug: '',
  activeCompanyDetails: IMap(),
};
export default Tabs;
