import React from 'react';

import SliderArrowLeft from '../../../svgs/SliderArrowLeft';

const PreviousArrow = (props) => (
  <div {...props}>
    <SliderArrowLeft />
  </div>
);

export default PreviousArrow;
