export const types = {
  SET_BANNER: 'SET_BANNER',
  GET_BANNER: 'GET_BANNER',
};

export const getBanner = (payload = {}) => ({
  type: types.GET_BANNER,
  payload,
});

export const setBanner = (payload) => ({
  type: types.SET_BANNER,
  payload,
});
