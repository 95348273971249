import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  loadCompanyDetails,
  updateCompanyDetails,
  updateCompanyJson,
  saveCompanyDetails,
  getCompanyDetailsById,
  updateActiveCompanyDetails,
  updateActiveCompanyJson,
  setChangesMade,
} from 'actions/auth';
import {
  selectIsAuthenticated,
  selectCompanyDetails,
  selectActiveCompanyProgress,
  selectAdminUserRole,
  selectActiveCompanyDetails,
  selectChangesMade,
} from 'selectors/auth';

const mapStateToProps = (state) => ({
  isAuthenticated: selectIsAuthenticated(state),
  myCompanyData: selectCompanyDetails(state),
  companyProgress: selectActiveCompanyProgress(state),
  adminRole: selectAdminUserRole(state),
  activeCompanyData: selectActiveCompanyDetails(state),
  changesMade: selectChangesMade(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loadCompanyDetails,
      updateCompanyDetails,
      updateCompanyJson,
      saveCompanyDetails,
      getCompanyDetailsById,
      updateActiveCompanyDetails,
      updateActiveCompanyJson,
      setChangesMade,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
