import { Button, Popconfirm } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

const BlockCompanyConfirmation = (props) => {
  const {
    handleConfirmation,
    formData,
  } = props;

  return (
    <Popconfirm
      title={formData.get('isBlocked', false) ? 'Are you sure about unblocking this account?' : 'Are you sure about blocking this account?'}
      onConfirm={() => handleConfirmation(formData.get('id'), formData, 'isBlocked')}
      okText="Ok"
      cancelText="Cancel"
    >
      <Button
        block
        className="w-100"
      >
        {formData.get('isBlocked', false) ? 'Unblock' : 'Block'}
      </Button>
    </Popconfirm>
  );
};

const { func, bool, number } = PropTypes;

const { mapContains } = ImmutablePropTypes;

BlockCompanyConfirmation.propTypes = {
  handleConfirmation: func.isRequired,
  formData: mapContains({
    isBlocked: bool.isRequired,
    id: number.isRequired,
  }).isRequired,
};

export default BlockCompanyConfirmation;
