import { parseError } from 'utils/parse-helper';
import { AuthorizedApiService } from './apiService';

export const getInterestsService = async (queryString) => AuthorizedApiService.get('interest', { params: queryString })
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const saveNewInterestService = async (postData) => AuthorizedApiService.post('interest', postData)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const deleteInterestService = async (id) => AuthorizedApiService.delete(`interest/${id}`)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const saveInterestsService = async (id, postData) => AuthorizedApiService.put(`company/${id}/interests`, postData)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));
