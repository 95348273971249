import { Button, Popconfirm } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const DeleteConfirmation = (props) => {
  const {
    handleConfirmation,
    companyId,
  } = props;

  return (
    <Popconfirm
      title="Are you sure about deleting this account?"
      onConfirm={() => handleConfirmation(companyId)}
      okText="Ok"
      cancelText="Cancel"
    >
      <Button
        danger
        className="w-100"
      >
        Delete
      </Button>
    </Popconfirm>
  );
};

const { func, number } = PropTypes;

DeleteConfirmation.propTypes = {
  handleConfirmation: func.isRequired,
  companyId: number.isRequired,
};

export default DeleteConfirmation;
