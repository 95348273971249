import { List as IList, Map as IMap } from 'immutable';
import { adminPanelTableContentLimit as limit } from 'utils/constants';

export const selectFeedContent = (state) => state.feedContentReducer.get('feedContent', IList());
export const selectLoaderVisible = (state) => state.feedContentReducer.get('loaderVisible', false);
export const selectSuggestedTags = (state) => state.feedContentReducer.get('tags', IList());
export const selectUserFeedContent = (state) => state.feedContentReducer.get('userFeedContent', IList());
export const selectActiveItem = (state) => state.feedContentReducer.get('itemDetails', IMap());
export const selectCurrencyRates = (state) => state.feedContentReducer.get('rates', IList());
export const selectRatingModalVisibility = (state) => state.feedContentReducer.get('ratingModal', false);
export const selectQueryData = (state) => state.feedContentReducer.get('queryData', IMap());
export const selectHasMoreDataStatus = (state) => state.feedContentReducer.get('hasMore', false);
export const selectNumberOfAvailablePages = (state) => Math.ceil(state.feedContentReducer.get('totalItems', 0) / limit);
export const selectActiveItemId = (state) => state.feedContentReducer.getIn(['itemDetails', 'id']);