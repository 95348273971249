export const columns = [
    {
      title: 'Total companies',
      dataIndex: 'numOfCompanies',
      key: 'numOfCompanies',
      align: "center",
    },
    {
      title: 'Paid',
      dataIndex: 'numOfPaidCompanies',
      key: 'numOfPaidCompanies',
      align: "center",
    },
    {
      title: 'Unpaid',
      dataIndex: 'numOfUnpaidCompanies',
      key: 'numOfUnpaidCompanies',
      align: "center",
    },
    {
      title: 'Trial',
      dataIndex: 'numOfTrialCompanies',
      key: 'numOfTrialCompanies',
      align: "center",
    },
    {
      title: 'Conversations',
      dataIndex: 'numOfConversations',
      key: 'numOfConversations',
      align: "center",
    },
    {
      title: 'Products',
      dataIndex: 'numOfProducts',
      key: 'numOfProducts',
      align: "center",
    },
    {
      title: 'Demands',
      dataIndex: 'numOfDemands',
      key: 'numOfDemands',
      align: "center",
    },
  ];