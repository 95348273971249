import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { loginSuccess } from 'actions/auth';
import { setActiveLanguage } from 'components/Navbar/modules/actions';

import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import { sentryDsn, fullstoryOrgId } from 'utils/constants';
import i18n from './i18n';
import AppContainer from './AppContainer';
import history from './history';
import store from './store';
import 'moment/locale/bs';
import 'moment/locale/hr';
import changeDateLanguage from './utils/changeDateLanguage';

const App = () => {
  if (process.env.NODE_ENV === 'production') {
    if (sentryDsn) {
      Sentry.init({ dsn: sentryDsn });
    }
    if (fullstoryOrgId) {
      FullStory.init({ orgId: fullstoryOrgId });
    }
  }
  const extension = window.location.origin.split('.').pop().split(':')[0];
  useEffect(() => {
    if (localStorage.getItem('token')) {
      store.dispatch(loginSuccess(localStorage.getItem('token')));
    }
    const setExtensionLanguage = ({
      ba: 'bos',
      hr: 'cro',
    })[extension] ?? 'eng';
    const activeLanguage = localStorage.getItem('i18nextLng');
    if (activeLanguage && ['bos', 'cro', 'srb'].includes(activeLanguage)) {
      i18n.changeLanguage(activeLanguage);
      store.dispatch(setActiveLanguage(activeLanguage));
    } else if (activeLanguage) {
      i18n.changeLanguage(setExtensionLanguage);
      store.dispatch(setActiveLanguage(setExtensionLanguage));
    } else {
      i18n.changeLanguage(setExtensionLanguage);
      store.dispatch(setActiveLanguage(setExtensionLanguage));
    }
    changeDateLanguage(activeLanguage);
  }, [extension]);
  return (
    <div className="App">
      <Provider store={store}>
        <Router history={history}>
          <AppContainer />
        </Router>
      </Provider>
    </div>
  );
};
export default App;
