import { parseError } from 'utils/parse-helper';
import { AuthorizedApiService } from './apiService';

export const getCommentsService = async (queryString) => AuthorizedApiService.get('comments', { params: queryString })
    .then((res) => res)
    .catch((err) => ({
        data: null,
        error: parseError(err),
    }));

export const createCommentService = async (commentData) => AuthorizedApiService.post('comment', commentData)
    .then((res) => res)
    .catch((err) => ({
        data: null,
        error: parseError(err),
    }));

export const deleteCommentByIdService = async (id) => AuthorizedApiService.delete(`comment/${id}`)
    .then((res) => res)
    .catch((err) => ({
        data: null,
        error: parseError(err),
    }));

export const editCommentByIdService = async (id, commentData) => AuthorizedApiService.patch(`comment/${id}`, commentData)
    .then((res) => res)
    .catch((err) => ({
        data: null,
        error: parseError(err),
    }));
