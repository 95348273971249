import { useReducer } from 'react';
import { fromJS, List as IList, Map as IMap } from 'immutable';
import {
  initialState,
  reducer,
  setInputValue as setInputValueAC,
  setDeletedIndustryInterests as setDeletedIndustryInterestsAC,
  setOptions as setOptionsAC,
  setFormData as setFormDataAC,
  setDeletedInterests as setDeletedInterestsAC,
  setIndustries as setIndustriesAC,
} from './useInterestsReducer';

const useInterests = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * @function setInputValue
   * @param {string} inputValue
   * @param {string} key
   */
  const setInputValue = (inputValue, key) => {
    dispatch(setInputValueAC(inputValue, key));
  };

  /**
   * @function setOptions
   * @param {array} options
   * @param {string} type
   */
  const setOptions = (options, type) => {
    if (type === 'industry') {
      return dispatch(setIndustriesAC(options));
    }
    return dispatch(setOptionsAC(options));
  };

  /**
   * setDeletedInterests
   * @param {IList} deletedInterests
   * @param {string} type
   */
  const setDeletedInterests = (deletedInterests, type) => {
    if (type === 'industry') {
      return dispatch(setDeletedIndustryInterestsAC(deletedInterests));
    }
    return dispatch(setDeletedInterestsAC(deletedInterests));
  };

  /**
   * @function setFormData
   * @param {IMap} formData
   */
  const setFormData = (formData) => {
    dispatch(setFormDataAC(formData));
  };

  const handleAddEnteredInterest = (type) => {
    const isIndustry = type === 'industry';
    const interestType = isIndustry ? 'industryInterests' : 'interests';
    const inputValue = isIndustry ? 'industryInputValue' : 'inputValue';
    if (
      state.get(inputValue).length > 3
      && !state.getIn(['formData', interestType], IList())
        .some((interest) => interest.get('name') === state.get(inputValue))
    ) {
      const formData = state.get('formData', IMap());
      const interests = formData.get(interestType, IList());
      const newInterest = { name: state.get(inputValue) };
      if (isIndustry) {
        newInterest.industryId = state.get('industries', IList())
          .find((industry) => industry.name === state.get(inputValue)).key;
      }
      const newFormData = formData.set(
        interestType,
        interests.push(fromJS(newInterest)),
      );
      setFormData(newFormData);
      setInputValue('', inputValue);
    }
  };

  const handleDeleteInterest = (item, type, component) => {
    const isIndustry = type === 'industry';
    const interestType = isIndustry ? 'ndustryInterests' : 'nterests';
    const formData = state.get('formData', IMap());
    const interests = formData.get(`i${interestType}`);
    if (component !== 'register') {
      const deletedInterest = {
        id: null,
        deleted: true,
      };
      if (isIndustry) {
        deletedInterest.id = interests.find((interest) => interest.get('name') === item.get('name')).get('id');
      } else {
        deletedInterest.id = item.get('id');
        deletedInterest.name = item.get('name', '');
      }
      if (item.get('id', '')) {
        setDeletedInterests(
          state.get(`deletedI${interestType}`).push(deletedInterest),
          type,
        );
      }
    }
    const newFormData = formData.set(`i${interestType}`, interests.filter(
      (interest) => interest.get('name', '') !== item.get('name', ''),
    ));
    setFormData(newFormData);
  };

  return {
    state,
    handleAddEnteredInterest,
    handleDeleteInterest,
    setFormData,
    setOptions,
    setInputValue,
  };
};

export default useInterests;
