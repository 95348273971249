export const types = {
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  SET_PRODUCT_DEMAND_TYPE: 'SET_PRODUCT_DEMAND_TYPE',
};

export const createProduct = (type, formData) => ({
  type: types.CREATE_PRODUCT,
  payload: {
    type,
    formData,
  },
});

export const updateProduct = (type, formData, productId) => ({
  type: types.UPDATE_PRODUCT,
  payload: {
    type,
    formData,
    productId,
  },
});

export const deleteProduct = (id) => ({
  type: types.DELETE_PRODUCT,
  payload: {
    id,
  },
});

export const setProductDemandType = (type) => ({
  type: types.SET_PRODUCT_DEMAND_TYPE,
  payload: {
    type,
  },
});

export default types;
