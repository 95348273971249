const parseError = (error) => (error.response ? error.response.data.message ? error.response.data.message : error.response.data.errors : error.toString());

const layoutPositions = (numberOfElements) => {
  if (numberOfElements <= 2) {
    return 'col-md-6';
  }
  return 'col-md-4';
};

export { parseError, layoutPositions };
