import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { formatDate } from 'utils/constants';
import { map, viewMode } from 'images/createCompany';
import { composeViewCompanyUrl } from 'utils/pathsHelper';
import buttonStyles from 'utils/buttonStyle.module.scss';
import showProduct from 'utils/showProduct-helper';

import styles from './feedCard.module.scss';

const FeedCard = (props) => {
  const { item, actions } = props;
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className={classNames(styles.feedCardContainer, 'px-3')}>
      <div className={styles.titleContainerRow}>
        <h3>{item.get('name')}</h3>
        <Link
          className={styles.imageContainer}
          to={composeViewCompanyUrl(item.getIn(['company', 'slug'], ''))}
          target="_blank"
        >
          <img src={item.getIn(['company', 'theme', 'companyLogo'], '')} alt="" />
        </Link>
      </div>
      <p className={classNames(styles.expireAtStyle, 'px-3 py-1')}>
        {`${t('feed card expireAt')}:  ${item.get('expireAt', '') && formatDate(item.get('expireAt', ''))}`}
      </p>
      <div className={styles.mainImageContainer}>
        <img
          src={item.getIn(['images', 0, 'url'], '') || map}
          alt="product card"
        />
        <div className={styles.viewCountContainer}>
          <img src={viewMode} alt="view count" />
          <p>{item.get('viewCount', 0)}</p>
        </div>
      </div>
      <h4>{item.getIn(['company', 'name'], 'Company name')}</h4>
      <h5>
        {`${t('feed card datePublished')}: ${formatDate(
          item.get('createdAt', ''),
        )}`}
      </h5>
      <div className={styles.tagsContainer}>
        {item.get('tags', '') && item.get('tags').map((tag) => (
          <p key={tag.get('id', '')}>{tag.get('name', '')}</p>
        ))}
      </div>
      <div
        className={classNames(
          styles.additionalPicturesContainer,
          item.get('images', '') && item.get('images', '').size < 4 && 'd-none',
        )}
      >
        {item.get('images', '') && item.get('images', '').size > 1
          && item
            .get('images', '')
            .map(
              (image, index) => index > 0
                && index < 4 && (
                  <img
                    src={image.get('url', '')}
                    alt="Error"
                    key={image.get('id', '')}
                    className="mr-1"
                  />
              ),
            )}
      </div>
      <button
        type="button"
        onClick={() => {
          showProduct(item, actions, location);
        }}
        className={classNames(
          buttonStyles.buttonSuccessStyle,
          styles.customButtonStyles,
          'mt-1 mb-4',
        )}
      >
        {t('feed card button moreDetails')}
      </button>
    </div>
  );
};

const { mapContains } = ImmutablePropTypes;
const { number, shape, func } = PropTypes;

FeedCard.propTypes = {
  item: mapContains({
    id: number.isRequired,
  }).isRequired,
  actions: shape({
    showModal: func.isRequired,
  }).isRequired,
};

export default FeedCard;
