import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Slide } from 'react-reveal';
import { useTranslation } from 'react-i18next';

import steps from './steps';
import styles from './easySteps.module.scss';

const EasySteps = (props) => {
  const { activeLanguage } = props;
  const { t } = useTranslation();
  const [easySteps, setEasySteps] = useState(steps());
  useEffect(() => {
    setEasySteps(steps());
  }, [activeLanguage]);

  return (
    <div className={styles.easyStepsContainer}>
      <div className="container">
        <Slide bottom>
          <div className={styles.easyStepsHeadingContainer}>
            <h6 className={styles.subtitle}>
              {t('homepage easysteps tagline')}
            </h6>
            <h5 className={styles.title}>
              {t('homepage easysteps headline')}
            </h5>
          </div>
        </Slide>
        <hr />
        <div className={styles.stepsRow}>
          <Slide bottom>
            {easySteps.map((step) => (
              <div className="col-12 col-md-6 mt-5" key={step.index}>
                <img src={step.image} alt="easy step" />
                <div className={classNames(styles.stepTitleContainer, 'pt-4')}>
                  <div className={styles.stepSequenceNumber}>{step.index}</div>
                  <h5 className={styles.stepTitle}>{step.title}</h5>
                </div>
                <div className={styles.stepBreakLine} />
                <p className={styles.stepText}>{step.text}</p>
              </div>
            ))}
          </Slide>
        </div>
      </div>
    </div>
  );
};

const { string } = PropTypes;

EasySteps.propTypes = {
  activeLanguage: string.isRequired,
};

export default EasySteps;
