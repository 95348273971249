export const types = {
  CHAT_LOGIN: 'CHAT_LOGIN',
  CHAT_NEW_MESSAGE: 'CHAT_NEW_MESSAGE',
  CHAT_SEND_MESSAGE: 'CHAT_SEND_MESSAGE',
  CHAT_GET_CONVERSATIONS: 'CHAT_GET_CONVERSATIONS',
  CHAT_SET_CONVERSATIONS: 'CHAT_SET_CONVERSATIONS',
  CHAT_SET_MESSAGES: 'CHAT_SET_MESSAGES',
  CHAT_GET_MESSAGES: 'CHAT_GET_MESSAGES',
  CHAT_SET_CONVERSATIONID: 'CHAT_SET_CONVERSATIONID',
  CHAT_VISIBILITY_MESSAGELIST: 'CHAT_VISIBILITY_MESSAGELIST',
  CHAT_VISIBILITY_COMPANIESLIST: 'CHAT_VISIBILITY_COMPANIESLIST',
  CHAT_SET_COMPANY_DATA: 'CHAT_SET_COMPANY_DATA',
  CHAT_UPDATE_MESSAGES: 'CHAT_UPDATE_MESSAGES',
  CHAT_UPDATE_CONVERSATION: 'CHAT_UPDATE_CONVERSATION',
  CHAT_FROM_ROUTE: 'CHAT_FROM_ROUTE',
  CHAT_SET_TITLE: 'CHAT_SET_TITLE',
  CHAT_DISCONNECT: 'CHAT_DISCONNECT',
  CHAT_UPLOAD_FILE: 'CHAT_UPLOAD_FILE',
  RESET_CHAT_FROM_ROUTE: 'RESET_CHAT_FROM_ROUTE',
  HANDLE_NEW_MESSAGE: 'HANDLE_NEW_MESSAGE',
  SET_CONVERSATIONS_COUNT: 'SET_CONVERSATIONS_COUNT',
  ARCHIVE_CONVERSATION: 'ARCHIVE_CONVERSATION',
  SET_ACTIVE_COMPANIES: 'SET_ACTIVE_COMPANIES',
};

export const login = () => ({
  type: types.CHAT_LOGIN,
  payload: {},
});

export const newMessage = (message) => ({
  type: types.CHAT_NEW_MESSAGE,
  payload: {
    message,
  },
});

export const sendMessage = (sendMessageData) => ({
  type: types.CHAT_SEND_MESSAGE,
  payload: {
    sendMessageData,
  },
});

export const getConversations = () => ({
  type: types.CHAT_GET_CONVERSATIONS,
  payload: {},
});

export const setConversations = (conversations) => ({
  type: types.CHAT_SET_CONVERSATIONS,
  payload: {
    conversations,
  },
});

export const getMessages = () => ({
  type: types.CHAT_GET_MESSAGES,
  payload: {},
});

export const setMessages = (messages) => ({
  type: types.CHAT_SET_MESSAGES,
  payload: {
    messages,
  },
});

export const setConversationId = (conversationId) => ({
  type: types.CHAT_SET_CONVERSATIONID,
  payload: {
    conversationId,
  },
});

export const setCompanyChat = (company) => ({
  type: types.CHAT_SET_COMPANY_DATA,
  payload: {
    company,
  },
});

export const updateMessages = (message) => ({
  type: types.CHAT_UPDATE_MESSAGES,
  payload: {
    message,
  },
});

export const updateConversations = (conversation) => ({
  type: types.CHAT_UPDATE_CONVERSATION,
  payload: {
    conversation,
  },
});
export const setChatRoute = (route, id) => ({
  type: types.CHAT_FROM_ROUTE,
  payload: {
    route,
    id,
  },
});

export const resetChatRoute = () => ({
  type: types.RESET_CHAT_FROM_ROUTE,
  payload: {},
});

export const setTitle = (title) => ({
  type: types.CHAT_SET_TITLE,
  payload: {
    title,
  },
});

export const setVisibilityMessagesList = (visibility) => ({
  type: types.CHAT_VISIBILITY_MESSAGELIST,
  payload: {
    visibility,
  },
});

export const setVisibilityCompaniesList = (visibility) => ({
  type: types.CHAT_VISIBILITY_COMPANIESLIST,
  payload: {
    visibility,
  },
});

export const chatDisconnect = () => ({
  type: types.CHAT_DISCONNECT,
  payload: {},
});

export const uploadFile = (data) => ({
  type: types.CHAT_UPLOAD_FILE,
  payload: {
    data,
  },
});

export const handleNewMessage = (data) => ({
  type: types.HANDLE_NEW_MESSAGE,
  payload: {
    data,
  },
});

export const setConversationsCount = (data) => ({
  type: types.SET_CONVERSATIONS_COUNT,
  payload: {
    data,
  },
});

export const setActiveCompanies = (data) => ({
  type: types.SET_ACTIVE_COMPANIES,
  payload: {
    data,
  },
});

export const archiveConversation = (chatId, activeChatId) => ({
  type: types.ARCHIVE_CONVERSATION,
  payload: {
    chatId,
    activeChatId,
  },
});

export default types;
