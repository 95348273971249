import i18n from 'i18next';

export const categories = [
  {
    name: i18n.t('news category all'),
    value: 'all',
  },
  {
    name: i18n.t('news category vijesti'),
    value: 'vijesti',
  },
  {
    name: i18n.t('news category blog'),
    value: 'blog',
  },
  {
    name: i18n.t('news category eventi'),
    value: 'eventi',
  },
  {
    name: i18n.t('news category kolumna'),
    value: 'kolumna',
  },
  {
    name: i18n.t('news category promo'),
    value: 'promo',
  },
  {
    name: i18n.t('news category gala-home'),
    value: 'gala-home',
  },
  {
    name: i18n.t('news category finansijski-savjetnik'),
    value: 'finansijski-savjetnik',
  },
];

export default categories;
