import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useMediaQuery } from 'react-responsive';
import { FileIcon } from 'svgs';
import Compose from '../Compose';
import Toolbar from '../Toolbar/View';
import ToolbarButton from '../ToolbarButton/View';
import './MessageList.scss';
import renderMessages from './renderMessages';

const MessageList = (props) => {
  const {
    actions,
    newMessages,
    title
  } = props;

  useEffect(() => {
    actions.getMessages();
    return () => {
      actions.setMessages([]);
      actions.setVisibilityMessagesList(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ block: 'end' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [newMessages]);

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  return (
    <div className="message-list">
      <Toolbar
        title={title}
        leftItems={isMobile ? (
          <ToolbarButton
            key="info"
            icon="ion-ios-arrow-back"
            actions={actions}
          />
        ) : null}
      />

      <div className="message-list-container" ref={messagesEndRef}>
        {renderMessages(newMessages)}
        <Compose
          rightItems={[<FileIcon fontSize={25} key="upload" className="pdf-upload" color="grey" />]}
        />
      </div>
    </div>
  );
};

const {
  shape, string, func,
} = PropTypes;

const { list } = ImmutablePropTypes;

MessageList.propTypes = {
  actions: shape({
    getMessages: func.isRequired,
    setMessages: func.isRequired,
    setVisibilityMessagesList: func.isRequired,
  }).isRequired,
  title: string.isRequired,
  newMessages: list.isRequired,
};

export default MessageList;
