import React from 'react';
import PropTypes from 'prop-types';
import '../ConversationListItem/ConversationListItem.scss';
import { Avatar } from 'antd';
import history from '../../../history';

const CompaniesListItem = (props) => {
  const {
    companyName,
    companyId,
    actions,
    activeStatus,
  } = props;

  const getAcronym = (item) => {
    if (item) {
      const matches = item.match(/\b(\w)/g);

      return matches.length > 1 ? matches[0].concat(matches[1]) : matches[0];
    }
    return null;
  };

  const handleSendMessage = () => {
    actions.setVisibilityCompaniesList(false);
    if (localStorage.getItem('userId')) {
      actions.setChatRoute('company');
      actions.setCompanyChat({
        id: companyId,
        name: companyName,
      });
      history.push('/messenger');
    } else actions.showModal();
  };

  return (
    <div
      className="conversation-list-item"
      role="presentation"
      onClick={handleSendMessage}
    >
      <div className="avatar-container">
        <Avatar size="large" className="avatar">
          <div className="avatar-name">
            {getAcronym(companyName)}
          </div>
        </Avatar>
      </div>
      <div className="conversation-info">
        <h1
          className="conversation-title"
        >
          {companyName}
        </h1>
      </div>
      {activeStatus ? (<div className="active-status" />) : null}
    </div>
  );
};

const {
  shape,
  func,
  string,
  number,
  bool,
} = PropTypes;

CompaniesListItem.propTypes = {
  actions: shape({
    setVisibilityCompaniesList: func,
    setVisibilityMessagesList: func,
    setChatRoute: func,
    setCompanyChat: func,
  }).isRequired,
  companyName: string.isRequired,
  companyId: number.isRequired,
  activeStatus: bool,
};

CompaniesListItem.defaultProps = {
  activeStatus: false,
};

export default CompaniesListItem;
