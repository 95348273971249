import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import buttonStyles from 'utils/buttonStyle.module.scss';
import styles from './pictureExpand.module.scss';

const PictureExpandModal = (props) => {
  const { actions, modalVisibility, pictureUrl } = props;
  const { t } = useTranslation();

  const toggle = () => {
    if (modalVisibility) {
      actions.hidePictureExpandModal();
    } else {
      actions.showPictureExpandModal();
    }
  };

  return (
    <div className={styles.pictureExpandModal}>
      <Modal
        isOpen={modalVisibility}
        toggle={toggle}
        className={classNames(styles.modalContainer)}
      >
        <img src={pictureUrl} alt="expaned" />
        <button
          type="button"
          className={classNames(
            buttonStyles.mainButtonStyle,
            styles.mainButtonStyle,
          )}
          onClick={toggle}
        >
          {t('pictureExpandModal button closeModal')}
        </button>
      </Modal>
    </div>
  );
};

const { bool, shape, func, string } = PropTypes;

PictureExpandModal.propTypes = {
  actions: shape({
    showPictureExpandModal: func.isRequired,
    hidePictureExpandModal: func.isRequired,
  }).isRequired,
  modalVisibility: bool.isRequired,
  pictureUrl: string.isRequired,
};

export default PictureExpandModal;
