import React from 'react';
import { string } from 'prop-types';

const AxeIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 51.19 44.116"
    width={width}
    height={height}
  >
    <g
      id="prefix__Group_658"
      data-name="Group 658"
      transform="translate(-792.017 -481.194)"
    >
      <g
        id="prefix__Group_84"
        data-name="Group 84"
        transform="translate(712.198 -40.855)"
      >
        <g id="prefix__Group_81" data-name="Group 81">
          <path
            id="prefix__Path_76"
            d="M105.673 537.532l-2.828-2.828-20.321 20.321a3.116 3.116 0 0 0 0 4.407l4.237 4.237a3.116 3.116 0 0 0 4.407 0l16.932-16.934a2 2 0 0 0 0-2.828 2 2 0 0 0-2.828 0l-16.31 16.31-2.987-2.987z"
            className="prefix__cls-1"
            data-name="Path 76"
          />
        </g>
        <g id="prefix__Group_82" data-name="Group 82">
          <path
            id="prefix__Path_77"
            d="M124.889 548.606a22.064 22.064 0 0 1-11.523 6.1 2.144 2.144 0 0 1-2.54-2l-.63-13.481 2.4 2.4 5.313-5.313-2.293-2.293 13.35.623a2.143 2.143 0 0 1 2 2.56 22.067 22.067 0 0 1-6.077 11.404zm-10.174 1.643a18.2 18.2 0 0 0 11.785-11.718l-4.927-.23-7.087 7.087zm12.329-13.822v.014z"
            className="prefix__cls-2"
            data-name="Path 77"
            style={{ fill: color }}
          />
        </g>
        <g id="prefix__Group_83" data-name="Group 83">
          <path
            id="prefix__Path_78"
            d="M128.944 538.646l-9.129-.427-9.741-9.741-5.313 5.313 9.634 9.634.432 9.252-4 .187-.36-7.706-9.844-9.844a2.157 2.157 0 0 1 0-3.048l7.921-7.921a2.158 2.158 0 0 1 3.049 0l9.95 9.95 7.583.354z"
            className="prefix__cls-2"
            data-name="Path 78"
            style={{ fill: color }}
          />
        </g>
        <path
          id="prefix__Path_79"
          d="M123.543 531.249a1.9 1.9 0 0 1-2.688 0l-5.956-5.956a1.9 1.9 0 0 1 0-2.688 1.9 1.9 0 0 1 2.688 0l5.956 5.956a1.9 1.9 0 0 1 0 2.688z"
          className="prefix__cls-1"
          data-name="Path 79"
          style={{ fill: '#050713' }}
        />
      </g>
      <path
        id="prefix__Path_81"
        d="M121.751 532.832a1.9 1.9 0 0 0 0-2.688l-5.956-5.956a1.9 1.9 0 0 0-2.688 2.688l5.956 5.956a1.9 1.9 0 0 0 2.688 0z"
        className="prefix__cls-3"
        data-name="Path 81"
        transform="translate(712.198 -40.855)"
        style={{ fill: color, opacity: 0.2 }}
      />
      <path
        id="prefix__Path_203"
        d="M127.339 536.251v-.018l-.164-.008-4.071-.19-3.345-.156-9.95-9.95a2.159 2.159 0 0 0-3.049 0l-7.921 7.921a2.16 2.16 0 0 0 0 3.049l.8.8-5.839 5.839-13.071 13.07a3.116 3.116 0 0 0 0 4.407l4.237 4.237a3.116 3.116 0 0 0 4.407 0l16.934-16.935a2 2 0 0 0-2.828-2.828L87.172 561.8l-2.987-2.987 18.281-18.281 6.214 6.214.36 7.706h.018a2.126 2.126 0 0 0 2.515 1.843 22.174 22.174 0 0 0 17.6-17.5 2.131 2.131 0 0 0-1.836-2.538zm-19.056-6.189l5.54 5.54 2.292 2.293-5.313 5.313-2.4-2.4-5.434-5.434zm11.986 17.3a18.091 18.091 0 0 1-7.346 4.472l-.179-3.834-.048-1.028 7.087-7.087 4.927.23a18.1 18.1 0 0 1-4.441 7.245z"
        className="prefix__cls-3"
        data-name="Path 203"
        transform="translate(712.198 -40.855)"
        style={{ fill: color, opacity: 0.2 }}
      />
    </g>
  </svg>
);

AxeIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

AxeIcon.defaultProps = {
  color: '#37ead9',
  width: '51.19',
  height: '44.116',
};

export default AxeIcon;
