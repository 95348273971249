import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  selectIsAuthenticated,
  selectActiveCompanyDetails,
  selectAdminUserRole,
} from 'selectors/auth';
import {
  loadCompanyDetails,
  updateActiveCompanyJson,
  getCompanyDetailsById,
} from 'actions/auth';
import { selectAboutCompanyImage } from '../CreateCompany/ColorPalette/modules/selectors';
import { uploadAboutCompanyImage } from '../CreateCompany/ColorPalette/modules/actions';

const mapStateToProps = (state) => ({
  isAuthenticated: selectIsAuthenticated(state),
  uploadedAboutCompanyImage: selectAboutCompanyImage(state),
  activeCompanyDetails: selectActiveCompanyDetails(state),
  adminRole: selectAdminUserRole(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loadCompanyDetails,
      uploadAboutCompanyImage,
      updateActiveCompanyJson,
      getCompanyDetailsById,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
