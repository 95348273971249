/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { addIcon } from 'images/createCompany';
import icons from 'images/Icons/index';
import styles from './productsAndServices.module.scss';

const ProductItem = (props) => {
  const {
    textAlignment, title, description, iconName, colorTheme,
  } = props;
  return (
    <div
      className={classNames(
        styles.productItemContainer,
        textAlignment === 'text-center'
          ? 'align-items-center'
          : textAlignment === 'text-right'
            ? 'align-items-end'
            : '',
        'px-3',
      )}
    >
      <div className={classNames(styles.imageContainer, 'px-0 px-md-3')}>
        {iconName ? (
          <div className={styles.svgIconContainer}>
            {icons(colorTheme, '46px', '37px')[iconName]}
          </div>
        ) : (
          <img
            src={addIcon}
            alt="about us icon upload"
            className={styles.addIconImgStyle}
          />
        )}
      </div>
      <h3
        className={classNames(
          styles.inputSectionTitle,
          textAlignment,
          styles.noBorderStyle,
        )}
      >
        {title}
      </h3>

      <div className={classNames(styles.horizontalBreakline, 'mx-auto')} />
      <p
        className={classNames(
          styles.inputSectionDescription,
          textAlignment,
          styles.noBorderStyle,
        )}
      >
        {description}
      </p>
    </div>
  );
};

const { string } = PropTypes;

ProductItem.propTypes = {
  textAlignment: string.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  iconName: string,
  colorTheme: string.isRequired,
};

ProductItem.defaultProps = {
  iconName: '',
};

export default ProductItem;
