import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Badge, Dropdown, Menu } from 'antd';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { messagerUrl } from 'utils/pathsHelper';
import styles from './notifications.module.scss';
import { MessageIcon, BellIcon } from '../../../../svgs';
import {
  greenLightColor,
  whiteColor,
} from '../../../../utils/constants';
import Notification from './Notification';

const Notifications = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    activeCompanyId,
    actions,
    newNotificationsCount,
    notifications,
    newMessagesCount,
  } = props;
  const myId = parseInt(localStorage.getItem('userId'), 10);

  useEffect(() => {
    actions.startNotificationPolling();
    actions.getMessagesCounts();
    return () => {
      actions.stopNotificationPolling();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menu = (
    <Menu className={styles.dropdownContainer}>
      {notifications.size > 0 ? (
        notifications.map((notification) => (
          <Menu.Item
            key={notification.get('id', '')}
            onClick={() => actions.setNotificationReadStatus(notification.get('id', ''))}
            className={classNames(
              styles.menuItemStyle,
              !notification.get('read', false) && styles.unreadNotification,
            )}
          >
            <Notification
              notification={notification}
            />
          </Menu.Item>
        ))
      ) : (
        <Menu.Item
          key="noKey"
          className={classNames(
            styles.menuItemStyle,
            styles.noNotificationsMenuItemStyle,
          )}
        >
          <h4>{t('notification emptyError')}</h4>
        </Menu.Item>
      )}
    </Menu>
  );

  return myId === activeCompanyId || !activeCompanyId ? (
    <div className={styles.notificationContainer}>
      <div className={styles.notificationContainerIcon}>
        <Link className={styles.notificationIcon} to="/messenger">
          <Badge
            count={newMessagesCount}
            className={classNames(styles.badge, 'pt-1')}
          >
            <MessageIcon
              color={
                location.pathname === messagerUrl ? greenLightColor : whiteColor
              }
            />
          </Badge>
        </Link>
      </div>
      <div className={styles.notificationContainerIcon}>
        <Dropdown
          overlay={menu}
          trigger={['click']}
          onVisibleChange={() => actions.setNotificationSeen()}
        >
          <div className={styles.notificationIcon}>
            <Badge
              count={newNotificationsCount}
              className={styles.badge}
            >
              <BellIcon />
            </Badge>
          </div>
        </Dropdown>
        ,
      </div>
    </div>
  ) : (
    <div style={{ paddingLeft: '10px' }} />
  );
};

const { shape, func, number } = PropTypes;
const { list } = ImmutablePropTypes;

Notifications.propTypes = {
  actions: shape({
    startNotificationPolling: func.isRequired,
    stopNotificationPolling: func.isRequired,
    setNotificationReadStatus: func.isRequired,
    setNotificationSeen: func.isRequired,
    getMessagesCounts: func.isRequired,
  }).isRequired,
  activeCompanyId: number,
  newNotificationsCount: number.isRequired,
  notifications: list.isRequired,
  newMessagesCount: number.isRequired,
};

Notifications.defaultProps = {
  activeCompanyId: parseInt(localStorage.getItem('userId'), 10),
};

export default Notifications;
