import React from 'react';
import { string, number } from 'prop-types';

const FileIcon = ({
  fontSize, width, height, color,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    style={{ fontSize: `${fontSize}px` }}
  >
    <g data-name="Google Docs">
      <g
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        data-name="&lt;Group&gt;"
      >
        <g data-name="&lt;Group&gt;">
          <path
            d="M20.5 5.5V22a1.5 1.5 0 01-1.5 1.5H5A1.5 1.5 0 013.5 22V2A1.5 1.5 0 015 .5h10.5z"
            data-name="&lt;Path&gt;"
          />
          <path
            d="M15.5.5V4A1.5 1.5 0 0017 5.5h3.5"
            data-name="&lt;Path&gt;"
          />
        </g>
        <path d="M7.5 11.5L16.5 11.5" data-name="&lt;Path&gt;" />
        <path d="M7.5 13.5L16.5 13.5" data-name="&lt;Path&gt;" />
        <path d="M7.5 15.5L16.5 15.5" data-name="&lt;Path&gt;" />
        <path d="M7.5 17.5L12.5 17.5" data-name="&lt;Path&gt;" />
      </g>
    </g>
  </svg>
);

FileIcon.propTypes = {
  fontSize: number,
  width: string,
  height: string,
  color: string,
};

FileIcon.defaultProps = {
  fontSize: 15,
  width: '1em',
  height: '1em',
  color: 'black',
};

export default FileIcon;
