import React from 'react';
import PropTypes from 'prop-types';
import styles from './companyLogo.module.scss';

const CompanyLogo = (props) => {
  const { imageUrl, activeImgUrl } = props;
  return (
    <div className={styles.companyLogo}>
      <img
        className={styles.companyImgUrl}
        src={activeImgUrl || imageUrl}
        alt="new"
      />
    </div>
  );
};

const { string } = PropTypes;

CompanyLogo.propTypes = {
  imageUrl: string,
  activeImgUrl: string,
};

CompanyLogo.defaultProps = {
  imageUrl: '',
  activeImgUrl: '',
};
export default CompanyLogo;
