import React from 'react';
import { string } from 'prop-types';

const ShirtIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 47.05 51.203"
  >
    <g
      id="prefix__Group_653"
      data-name="Group 653"
      transform="translate(-1281 -327.174)"
    >
      <g
        id="prefix__Group_621"
        data-name="Group 621"
        transform="translate(-637.523 -399)"
      >
        <path
          id="prefix__Path_653"
          d="M120.147 625.084H87.24a6.432 6.432 0 0 1-6.425-6.425V587.74a6.434 6.434 0 0 1 6.426-6.426h3.191v4H87.24a2.429 2.429 0 0 0-2.426 2.426v30.919a2.428 2.428 0 0 0 2.425 2.425h32.908a2.429 2.429 0 0 0 2.426-2.425V587.74a2.428 2.428 0 0 0-2.426-2.426h-3.4v-4h3.4a6.434 6.434 0 0 1 6.426 6.426v30.919a6.432 6.432 0 0 1-6.426 6.425z"
          className="prefix__cls-1"
          data-name="Path 653"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Rectangle_43"
          d="M0 0H19.819V4H0z"
          className="prefix__cls-1"
          data-name="Rectangle 43"
          transform="translate(1932.805 726.174)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_654"
          d="M108.9 584.729l-2.83-2.829 6.088-6.088a2.142 2.142 0 0 1 3.44.574l2.954 6.1a2 2 0 0 1-.924 2.669 2 2 0 0 1-2.674-.927l-1.81-3.743zm6.1-6.1l-.014.014z"
          className="prefix__cls-1"
          data-name="Path 654"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_655"
          d="M108.287 593.762h-.051a3.335 3.335 0 0 1-2.51-1.14l-4.838-5.538 5.182-5.184 2.829 2.828-2.539 2.539 1.94 2.221 6.862-7.451a2 2 0 0 1 2.83-.112 2 2 0 0 1 .112 2.822l-7.288 7.911a3.454 3.454 0 0 1-2.529 1.104z"
          className="prefix__cls-1"
          data-name="Path 655"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_656"
          d="M98.462 584.729l2.828-2.828-6.09-6.088a2.142 2.142 0 0 0-3.44.574l-2.954 6.1a2 2 0 0 0 .924 2.669 2 2 0 0 0 2.674-.927l1.81-3.743zm-6.1-6.1l.014.014z"
          className="prefix__cls-1"
          data-name="Path 656"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_657"
          d="M99.073 593.762h.051a3.335 3.335 0 0 0 2.51-1.14l4.838-5.538-5.182-5.184-2.829 2.828 2.539 2.539-1.94 2.221-6.86-7.451a2 2 0 0 0-2.83-.112 2 2 0 0 0-.112 2.822l7.288 7.911a3.452 3.452 0 0 0 2.527 1.104z"
          className="prefix__cls-1"
          data-name="Path 657"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_658"
          d="M109.642 600.979a1.5 1.5 0 0 1 1.5-1.5h6.395a1.5 1.5 0 0 1 1.5 1.5 1.5 1.5 0 0 1-1.5 1.5h-6.395a1.5 1.5 0 0 1-1.5-1.5z"
          className="prefix__cls-2"
          data-name="Path 658"
          transform="translate(1839 151)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_659"
          d="M104.725 623.229h-3v-21.417a1.5 1.5 0 0 1 1.5-1.5 1.5 1.5 0 0 1 1.5 1.5z"
          className="prefix__cls-2"
          data-name="Path 659"
          transform="translate(1840 149)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_660"
          d="M118.856 582.607h-2.161l-2.388-4.928a2.146 2.146 0 0 0-1.578-1.174 2.1 2.1 0 0 0-.395-.018v-.021h-19.82v.018a2.114 2.114 0 0 0-2.045 1.2l-2.387 4.928h-2.133a6.434 6.434 0 0 0-6.426 6.426v30.919a6.432 6.432 0 0 0 6.425 6.425h32.908a6.432 6.432 0 0 0 6.425-6.425v-30.924a6.433 6.433 0 0 0-6.425-6.426zm-5.816 1.624l-6.032 6.55-1.94-2.221 2.539-2.539 4.247-4.246zM100 583.193l-1.414 1.414 1.414-1.414-2.727-2.727h10.234l-2.727 2.727 1.414 1.414-1.415-1.414-2.39 2.39zm-7.074-1.418l4.246 4.246 2.539 2.539-1.94 2.221-6.032-6.55zm28.357 38.176a2.428 2.428 0 0 1-2.425 2.425h-15.425V603.1a1.5 1.5 0 0 0-3 0v19.272H85.948a2.428 2.428 0 0 1-2.425-2.425v-30.915a2.428 2.428 0 0 1 2.426-2.426h2.539l6.765 7.344a3.454 3.454 0 0 0 2.528 1.1h.052a3.335 3.335 0 0 0 2.51-1.14l2.046-2.341 2.046 2.341a3.331 3.331 0 0 0 2.51 1.14H107a3.45 3.45 0 0 0 2.528-1.1l6.765-7.344h2.567a2.428 2.428 0 0 1 2.425 2.426v30.919z"
          className="prefix__cls-3"
          data-name="Path 660"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
        <path
          id="prefix__Path_661"
          d="M116.245 600.77h-6.395a1.5 1.5 0 0 0 0 3h6.395a1.5 1.5 0 1 0 0-3z"
          className="prefix__cls-3"
          data-name="Path 661"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
      </g>
    </g>
  </svg>
);

ShirtIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

ShirtIcon.defaultProps = {
  color: '#37ead9',
  width: '47.05',
  height: '51.203',
};

export default ShirtIcon;
