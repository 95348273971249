import React, { useState } from 'react';
import PropTypes, { string } from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import InputContainer from 'utils/InputMain';
import buttonStyle from 'utils/buttonStyle.module.scss';
import useForm from 'utils/hooks/useForm';
import inputStyle from 'utils/inputStyle.module.scss';
import { EyeOutlined } from '@ant-design/icons';
import { loginIcon } from 'images';
import validate from './validateNewPasswodForm';
import loginStyles from '../Login/login.module.scss';
import styles from './resetPassword.module.scss';

const ResetPassword = (props) => {
  const {
    actions,
    location: { search },
  } = props;
  const { t } = useTranslation();
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const searchedValues = search.split('&code=');
  const token = searchedValues[1];
  const email = searchedValues[0].split('email=')[1];

  const handlePasswordChange = () => {
    actions.resetPassword(email, values.password, token);
  };
  const {
    values, errors, handleChange, handleSubmit,
  } = useForm(
    handlePasswordChange,
    validate,
  );
  return (
    <div className={classNames('container', styles.resetPasswordContainer)}>
      <img src={loginIcon} alt="login to bizbook" />
      <div>
        <h1>{t('resetPassword title')}</h1>
        <h2>{t('resetPassword subtitleText')}</h2>
      </div>

      <form onSubmit={handleSubmit} noValidate className={styles.formStyle}>
        <div
          className={classNames(loginStyles.formContainer, 'px-0 mx-auto')}
        >
          <InputContainer
            label={t('resetPassword passwordInput label')}
            name="password"
            type="password"
            placeholder={t('resetPassword passwordInput placeholder')}
            value={values.password}
            handleChange={handleChange}
            error={errors.password}
            customStyle="col-md-12 col-lg-12"
          />
          <div
            className={classNames(
              inputStyle.formGroup,
              'col-12  my-4 mx-5 mx-auto',
              errors.repeatPassword ? inputStyle.validationError : '',
            )}
          >
            <h6 className={inputStyle.error}>{errors.repeatPassword}</h6>
            <h5 className="px-1">{t('resetPassword passwordInput label')}</h5>
            <div className={inputStyle.passwordContainer}>
              <input
                type={passwordFieldType}
                className={classNames(inputStyle.formControl, 'py-2 my-1')}
                placeholder={t('resetPassword repeatPasswordInput placeholder')}
                name="repeatPassword"
                value={values.repeatPassword || ''}
                onChange={handleChange}
              />
              <EyeOutlined
                onClick={() => setPasswordFieldType(
                  passwordFieldType === 'password' ? 'text' : 'password',
                )}
                className={classNames(
                  inputStyle.passwordInputImgStyle,
                  passwordFieldType !== 'password' ? 'text-info' : '',
                )}
              />
            </div>
          </div>
          <button
            type="submit"
            className={classNames(
              buttonStyle.buttonSuccessStyle,
              'my-3 col-12',
              loginStyles.buttonSuccessStyle,
            )}
          >
            {t('resetPassword buttonText')}
          </button>
        </div>
      </form>
    </div>
  );
};

const { shape, func } = PropTypes;

ResetPassword.propTypes = {
  actions: shape({
    resetPassword: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,
};

export default ResetPassword;
