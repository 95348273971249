import React from 'react';
import { string } from 'prop-types';

const FlashlightIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 54.423 52.705"
  >
    <g
      id="prefix__Group_654"
      data-name="Group 654"
      transform="translate(-117.001 -467.953)"
    >
      <g
        id="prefix__Group_185"
        data-name="Group 185"
        transform="translate(34.198 -48.855)"
      >
        <g id="prefix__Group_154" data-name="Group 154">
          <g id="prefix__Group_151" data-name="Group 151">
            <path
              id="prefix__Path_180"
              d="M113.61 545.915l-17.836 17.872-6.308-6.308 17.124-17.16a1.008 1.008 0 0 0 0-1.425l-2.1-2.1-18.825 18.829a2.625 2.625 0 0 0 0 3.712l8.253 8.253a2.628 2.628 0 0 0 1.857.769 2.6 2.6 0 0 0 1.853-.769l18.827-18.827zM94.8 564.763z"
              className="prefix__cls-1"
              data-name="Path 180"
              style={{ fill: '#050713' }}
            />
          </g>
          <g id="prefix__Group_152" data-name="Group 152">
            <circle
              id="prefix__Ellipse_4"
              cx="1.714"
              cy="1.714"
              r="1.714"
              data-name="Ellipse 4"
              transform="translate(102.48 547.394)"
              style={{ fill: color }}
            />
          </g>
          <g id="prefix__Group_153" data-name="Group 153">
            <path
              id="prefix__Path_181"
              d="M126.732 544.8l-18.452-18.453a2 2 0 0 1 0-2.828 2 2 0 0 1 2.828 0l18.452 18.453a2 2 0 0 1 0 2.828 2 2 0 0 1-2.828 0z"
              className="prefix__cls-1"
              data-name="Path 181"
              style={{ fill: '#050713' }}
            />
          </g>
        </g>
        <path
          id="prefix__Path_182"
          d="M122.708 544.69l-14.178-14.178a2.057 2.057 0 0 0-1.941-.562 2.177 2.177 0 0 0-1.518 1.433l-2.86 8.788 4.563-1.095 1.146-3.519 9.777 9.777-3.521 1.146-4.742-4.742a2 2 0 0 0-2.828 0 2 2 0 0 0 0 2.828l6.476 6.476 8.844-2.878a2.056 2.056 0 0 0 1.37-1.471 2.177 2.177 0 0 0-.588-2.003z"
          className="prefix__cls-1"
          data-name="Path 182"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_187"
          d="M109.014 524.675a2 2 0 0 0-2.828 2.828l18.452 18.453a2 2 0 1 0 2.828-2.828z"
          className="prefix__cls-3"
          data-name="Path 187"
          style={{ fill: color, opacity: 0.2 }}
        />
        <path
          id="prefix__Path_188"
          d="M106.436 531.668a2.057 2.057 0 0 0-1.941-.562 2.177 2.177 0 0 0-1.518 1.433l-2.332 7.166-17.073 17.074a2.625 2.625 0 0 0 0 3.712l8.253 8.253a2.628 2.628 0 0 0 1.857.769 2.6 2.6 0 0 0 1.853-.769l17.074-17.073 7.222-2.35a2.056 2.056 0 0 0 1.37-1.471 2.176 2.176 0 0 0-.588-2zm5.646 15.968l-.565-.565-4.176-4.177a2 2 0 0 0-2.828 2.828l4.179 4.179-15.012 15.042-6.308-6.308 17.125-17.16a.994.994 0 0 0 .129-1.229l.053-.013 1.146-3.52 9.777 9.777z"
          className="prefix__cls-3"
          data-name="Path 188"
          style={{ fill: color, opacity: 0.2 }}
        />
        <g id="prefix__Group_177" data-name="Group 177">
          <g id="prefix__Group_155" data-name="Group 155">
            <path
              id="prefix__Path_183"
              d="M122.795 531.064a1.361 1.361 0 0 1 0-1.924l9.964-9.964a1.361 1.361 0 0 1 1.924 0 1.361 1.361 0 0 1 0 1.924l-9.964 9.964a1.361 1.361 0 0 1-1.924 0z"
              className="prefix__cls-4"
              data-name="Path 183"
              style={{ fill: color }}
            />
          </g>
          <g id="prefix__Group_156" data-name="Group 156">
            <path
              id="prefix__Path_184"
              d="M127.714 536.267a1.361 1.361 0 0 1 .79-1.754l6.88-2.606a1.361 1.361 0 0 1 1.754.79 1.361 1.361 0 0 1-.79 1.754l-6.88 2.606a1.359 1.359 0 0 1-1.754-.79z"
              className="prefix__cls-4"
              data-name="Path 184"
              style={{ fill: color }}
            />
          </g>
          <g id="prefix__Group_157" data-name="Group 157">
            <path
              id="prefix__Path_185"
              d="M117.766 526.32a1.361 1.361 0 0 0 1.754-.79l2.605-6.88a1.361 1.361 0 0 0-.79-1.754 1.361 1.361 0 0 0-1.754.79l-2.605 6.88a1.361 1.361 0 0 0 .79 1.754z"
              className="prefix__cls-4"
              data-name="Path 185"
              style={{ fill: color }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

FlashlightIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

FlashlightIcon.defaultProps = {
  color: '#37ead9',
  width: '54.423',
  height: '52.705',
};

export default FlashlightIcon;
