import { fromJS } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  currentLanguage: 'bos',
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_ACTIVE_LANGUAGE:
      return state.set('currentLanguage', action.payload.language);
    default:
      return state;
  }
};
