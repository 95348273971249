import React from 'react';
import { string } from 'prop-types';

const BagIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 37.507 51.917"
  >
    <g
      id="prefix__Group_666"
      data-name="Group 666"
      transform="translate(-629.595 -620)"
    >
      <g
        id="prefix__Group_630"
        data-name="Group 630"
        transform="translate(-1807 254.661)"
      >
        <path
          id="prefix__Path_649"
          d="M632.753 256.459l-26.813-.044a1.5 1.5 0 0 1-1.5-1.5 1.5 1.5 0 0 1 1.5-1.5l26.813.044z"
          className="prefix__cls-1"
          data-name="Path 649"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_651"
          d="M624.176 233.192a2 2 0 0 1-2-2v-7.62a5.162 5.162 0 0 0-4.509-5.208 5.015 5.015 0 0 0-5.51 4.985v7.843a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-7.525a9.222 9.222 0 0 1 8.393-9.307 9.019 9.019 0 0 1 9.626 8.989v7.843a2 2 0 0 1-2 2z"
          data-name="Path 651"
          transform="translate(1839 151)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_652"
          d="M605.929 266.256h19.548a8.331 8.331 0 0 0 8.319-8.819l-1.561-26.7a6.19 6.19 0 0 0-6.174-5.823h-1.177v-.271a9.019 9.019 0 0 0-9.626-8.989 9.22 9.22 0 0 0-8.391 9.261h-1.524a6.191 6.191 0 0 0-6.174 5.824l-1.56 26.7a8.33 8.33 0 0 0 8.32 8.819zm4.937-41.615a5.014 5.014 0 0 1 5.509-4.985 5.161 5.161 0 0 1 4.509 5.208v.049h-10.019v-.272zm-7.7 6.329a2.187 2.187 0 0 1 2.181-2.058h1.522v3.571a2 2 0 0 0 2 2 2 2 0 0 0 2-2v-3.571h10.019v3.571a2 2 0 0 0 2 2 2 2 0 0 0 2-2v-3.571h1.177a2.187 2.187 0 0 1 2.181 2.057l1.39 23.779-24.979-.041a1.5 1.5 0 0 0-.005 3l25.151.042a4.321 4.321 0 0 1-4.323 4.507h-19.55a4.332 4.332 0 0 1-4.326-4.587z"
          data-name="Path 652"
          transform="translate(1839 151)"
          style={{ opacity: 0.2, fill: color }}
        />
        <path
          id="prefix__Path_650"
          d="M626.769 264.964h-19.548a8.331 8.331 0 0 1-8.319-8.82l1.56-26.7a6.19 6.19 0 0 1 6.174-5.824h20.718a6.191 6.191 0 0 1 6.174 5.823l1.56 26.7a8.331 8.331 0 0 1-8.319 8.819zm-20.133-37.344a2.187 2.187 0 0 0-2.181 2.058l-1.56 26.7a4.332 4.332 0 0 0 4.326 4.587h19.548a4.332 4.332 0 0 0 4.326-4.586l-1.56-26.7a2.186 2.186 0 0 0-2.181-2.057h-20.718z"
          className="prefix__cls-1"
          data-name="Path 650"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
      </g>
    </g>
  </svg>
);

BagIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

BagIcon.defaultProps = {
  color: '#37ead9',
  width: '37.507',
  height: '51.917',
};

export default BagIcon;
