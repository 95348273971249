import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import classNames from 'classnames';
import registerStyles from '../../Register/register.module.scss';

import RegisterStep from '../../Register/RegisterStep';

import styles from './createCompanyModal.module.scss';

const CreateNewCompanyModal = (props) => {
  const { actions, modalVisibility } = props;
  const [activeComponent, setActiveComponent] = useState('register');
  const [registerData, setRegisterData] = useState({});

  const toggle = () => {
    if (modalVisibility) {
      actions.hideModal();
    } else {
      actions.showModal();
    }
  };

  const handleRegister = (values) => {
    setActiveComponent('interests');
    setRegisterData(values);
  };

  const addInterests = (interests, industryInterests) => {
    actions.hideModal();
    actions.createNewCompany({ ...registerData, interests, industryInterests });
    setActiveComponent('register');
  };

  return (
    <Modal
      isOpen={modalVisibility}
      toggle={toggle}
      className={styles.modalContainerStyle}
    >
      <div className={classNames(registerStyles.register, 'pt-0')}>
        <RegisterStep
          handleRegister={handleRegister}
          setActiveComponent={setActiveComponent}
          addInterests={addInterests}
          registerData={registerData}
          isRegister={activeComponent === 'register'}
        />
      </div>
    </Modal>
  );
};

const { bool, shape, func } = PropTypes;

CreateNewCompanyModal.propTypes = {
  actions: shape({
    showModal: func.isRequired,
    hideModal: func.isRequired,
    createNewCompany: func.isRequired,
  }).isRequired,
  modalVisibility: bool.isRequired,
};

export default CreateNewCompanyModal;
