import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ProgressBar from 'components/CreateCompany/ProgressBar';
import { RightOutlined, CheckOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Map as IMap } from 'immutable';
import {
  composeEditCompanyUrl,
  composeViewCompanyUrl,
} from 'utils/pathsHelper';
import { isLoggedUser } from 'utils/authorizationHelper';
import ViewMode from './ViewMode';
import EditMode from './EditMode';
import Footer from './utils/CustomFooter';
import {
  initialState,
  reducer,
  setFormData,
  setIsAuthorized,
  setViewMode,
} from './utils/useReducer';
import styles from './aboutCompany.module.scss';

const AboutCompany = (props) => {
  const {
    actions,
    uploadedAboutCompanyImage,
    activeCompanyDetails,
    adminRole,
  } = props;
  const params = useParams();
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { companyId } = params;

  useEffect(() => {
    dispatch(setIsAuthorized(isLoggedUser(companyId)));
    actions.getCompanyDetailsById(companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (activeCompanyDetails.size > 0) {
      dispatch(
        setFormData(
          activeCompanyDetails.getIn(['companyJson', 'aboutCompany'], IMap()),
        ),
      );
    }
  }, [activeCompanyDetails, state.isAuthorized]);

  useEffect(() => {
    if (uploadedAboutCompanyImage.size > 0) {
      dispatch(
        setFormData(
          state.formData.setIn(
            ['images', uploadedAboutCompanyImage.get('imageKey')],
            uploadedAboutCompanyImage.get('imageUrl'),
          ),
        ),
      );
    }
  }, [state.formData, uploadedAboutCompanyImage]);

  // eslint-disable-next-line max-len
  const updateFormData = (fieldName, value) => dispatch(setFormData(state.formData.set(fieldName, value)));

  const handleSaveChanges = () => {
    actions.updateActiveCompanyJson('aboutCompany', state.formData);
  };

  useEffect(() => {
    if (adminRole) {
      dispatch(setIsAuthorized(true));
    }
  }, [adminRole]);

  return (
    <>
    {(isLoggedUser(companyId))
        && (
        <div className={classNames('col md-12 p-0', styles.progressBar)}>
          <ProgressBar
            progress={activeCompanyDetails.getIn(
              ['companyJson', 'companyProgress'],
              0,
            )}
          />
        </div>
        )}
    <div className="container">
      <div className={styles.aboutCompanyContainer}>
        <div className={classNames('container', styles.navigationContainer)}>
          <div className={classNames(styles.navigationInfo)}>
            <Link
              to={
                  isLoggedUser(companyId)
                    ? composeEditCompanyUrl(companyId)
                    : composeViewCompanyUrl(companyId)
                }
            >
              <h5>{activeCompanyDetails.get('name', 'BIZBOOK')}</h5>
            </Link>
            <RightOutlined className="px-3 pt-1" />
            <h6>{t('aboutCompany navigation textAboutCompany')}</h6>
          </div>
          {state.isAuthorized && (
          <div className={styles.siteWatchingModeContainer}>
            <h4>{t('createCompany companyHeader siteWatchingMode title')}</h4>
            <div className={styles.buttonContainer}>
              <button
                type="button"
                onClick={() => dispatch(setViewMode('edit'))}
              >
                {t('createCompany companyHeader siteWatchingMode editing')}
                {state.viewMode === 'edit' && (
                <CheckOutlined className={classNames(styles.iconStyle)} />
                )}
              </button>
              <button
                type="button"
                onClick={() => dispatch(setViewMode('view'))}
              >
                {t('createCompany companyHeader siteWatchingMode watching')}
                {state.viewMode === 'view' && (
                <CheckOutlined className={classNames(styles.iconStyle)} />
                )}
              </button>
            </div>
          </div>
          )}
        </div>
        <div className={classNames('container', styles.sectionsContainer)}>
          {state.viewMode === 'edit' ? (
            <EditMode
              formData={state.formData}
              updateFormData={updateFormData}
              uploadAboutCompanyImage={actions.uploadAboutCompanyImage}
              handleSaveChanges={handleSaveChanges}
            />
          ) : (
            <ViewMode
              formData={state.formData}
              ratingsCount={activeCompanyDetails.get(
                'ratingsCount',
                activeCompanyDetails.get('ratingsCount', 0),
              )}
              currentRating={activeCompanyDetails.get(
                'currentRating',
                activeCompanyDetails.get('currentRating', 0),
              )}
            />
          )}
        </div>
      </div>
    </div>
    <Footer companyData={activeCompanyDetails} />
    </>
  );
};

const { shape, func, bool } = PropTypes;

AboutCompany.propTypes = {
  actions: shape({
    uploadAboutCompanyImage: func.isRequired,
    updateActiveCompanyJson: func.isRequired,
    getCompanyDetailsById: func.isRequired,
  }).isRequired,
  uploadedAboutCompanyImage: ImmutablePropTypes.map.isRequired,
  activeCompanyDetails: ImmutablePropTypes.map.isRequired,
  adminRole: bool.isRequired,
};

export default AboutCompany;
