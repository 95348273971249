import React from 'react';
import PropTypes from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';

import styles from './uploadedImage.module.scss';

const UploadedImage = (props) => {
  const { imageUrl, deleteUploadedImage, imageKey } = props;
  return (
    <div className={styles.uploadedImageContainer}>
      <img src={imageUrl} alt="Product/Demand/Service" />
      <CloseOutlined className={styles.iconStyle} onClick={() => deleteUploadedImage(imageKey)} />
    </div>
  );
};

const { string, func } = PropTypes;

UploadedImage.propTypes = {
  imageKey: string.isRequired,
  imageUrl: string.isRequired,
  deleteUploadedImage: func.isRequired,
};

export default UploadedImage;
