import React from 'react';
import { string } from 'prop-types';

const WalletIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 46.671 45.436"
  >
    <g
      id="prefix__Group_662"
      data-name="Group 662"
      transform="translate(-1284 -624)"
    >
      <g
        id="prefix__Group_631"
        data-name="Group 631"
        transform="translate(-1143.431 75.182)"
      >
        <g id="prefix__Group_629" data-name="Group 629">
          <path
            id="prefix__Path_627"
            d="M631.7 431.193h-5.313a2.972 2.972 0 0 1-2.969-2.969v-5a2.972 2.972 0 0 1 2.969-2.969h5.313a2.972 2.972 0 0 1 2.969 2.969v5a2.972 2.972 0 0 1-2.969 2.969zm-.031-7.969l-5.281.031.031 4.969 5.25-.031z"
            data-name="Path 627"
            transform="translate(1839 151)"
            style={{ fill: '#050713' }}
          />
          <path
            id="prefix__Path_628"
            d="M627.745 441.963h-30.664a7.366 7.366 0 0 1-7.358-7.357v-28.7h4v28.7a3.36 3.36 0 0 0 3.358 3.357h30.664a3.361 3.361 0 0 0 3.357-3.357v-18.217a3.361 3.361 0 0 0-3.357-3.358H597.09v-4h30.655a7.366 7.366 0 0 1 7.357 7.358v18.217a7.365 7.365 0 0 1-7.357 7.357z"
            className="prefix__cls-2"
            data-name="Path 628"
            transform="translate(1839 151)"
            style={{ fill: color }}
          />
          <path
            id="prefix__Path_629"
            d="M597.33 413.032a7.615 7.615 0 0 1-7.542-8.608 7.785 7.785 0 0 1 7.808-6.606h26.869a6.376 6.376 0 0 1 6.376 6.376v1.291a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-1.291a2.376 2.376 0 0 0-2.376-2.376h-27.03a3.675 3.675 0 0 0-3.634 2.849 3.613 3.613 0 0 0 3.529 4.365z"
            className="prefix__cls-2"
            data-name="Path 629"
            transform="translate(1839 151)"
            style={{ fill: color }}
          />
          <path
            id="prefix__Path_630"
            d="M626.453 410.323h-30.415a3.613 3.613 0 0 1-3.529-4.365 3.675 3.675 0 0 1 3.634-2.849h27.031a2.376 2.376 0 0 1 2.376 2.376v1.291a2 2 0 0 0 4 0v-1.291a6.376 6.376 0 0 0-6.376-6.376H596.3a7.563 7.563 0 0 0-7.849 8.093h-.024v28.7a7.366 7.366 0 0 0 7.358 7.357h30.664a7.366 7.366 0 0 0 7.357-7.357V417.68a7.366 7.366 0 0 0-7.353-7.357zm3.357 19.165l-4.679.028-.031-4.969 4.71-.028zm-3.357 9.767h-30.664a3.361 3.361 0 0 1-3.358-3.357V413.4a7.541 7.541 0 0 0 3.367.909v.013h30.655a3.361 3.361 0 0 1 3.357 3.357v3.866h-4.71a2.972 2.972 0 0 0-2.969 2.969v5a2.972 2.972 0 0 0 2.969 2.969h4.71v3.417a3.361 3.361 0 0 1-3.357 3.355z"
            data-name="Path 630"
            transform="translate(1839 151)"
            style={{ opacity: 0.2, fill: color }}
          />
        </g>
      </g>
    </g>
  </svg>
);

WalletIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

WalletIcon.defaultProps = {
  color: '#37ead9',
  width: '46.671',
  height: '45.436',
};

export default WalletIcon;
