export const types = {
  START_NOTIFICATION_POLLING: 'START_NOTIFICATION_POLLING',
  STOP_NOTIFICATION_POLLING: 'STOP_NOTIFICATION_POLLING',
  SET_NOTIFICATIONS_COUNT: 'SET_NOTIFICATIONS_COUNT',
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  SET_NOTIFICATIONS_SEEN_STATUS: 'SET_NOTIFICATIONS_SEEN_STATUS',
  SET_NOTIFICATION_READ: 'SET_NOTIFICATION_READ',
  GET_MESSAGES_COUNTS: 'GET_MESSAGES_COUNTS',
};

export const startNotificationPolling = () => ({
  type: types.START_NOTIFICATION_POLLING,
  payload: {},
});

export const stopNotificationPolling = () => ({
  type: types.STOP_NOTIFICATION_POLLING,
  payload: {},
});

export const setNotificationCount = (count) => ({
  type: types.SET_NOTIFICATIONS_COUNT,
  payload: {
    count,
  },
});

export const getNotifications = (queryData) => ({
  type: types.GET_NOTIFICATIONS,
  payload: {
    queryData,
  },
});

export const setNotifications = (notifications) => ({
  type: types.SET_NOTIFICATIONS,
  payload: {
    notifications,
  },
});

export const setNotificationReadStatus = (id) => ({
  type: types.SET_NOTIFICATION_READ,
  payload: {
    id,
  },
});

export const setNotificationSeen = () => ({
  type: types.SET_NOTIFICATIONS_SEEN_STATUS,
  payload: {},
});

export const getMessagesCounts = () => ({
  type: types.GET_MESSAGES_COUNTS,
  payload: {},
});

export default types;
