import { fromJS, Map as IMap } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  uploadedCatalog: {},
  catalogs: [],
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_UPLOADED_CATALOG:
      return state.set(
        'uploadedCatalog',
        fromJS({
          url: action.payload.data.secure_url,
          publicId: action.payload.data.public_id,
          name: action.payload.data.name,
        }),
      );
    case types.ADD_CATALOG:
      return state.set('uploadedCatalog', IMap());
    case types.SET_COMPANY_CATALOGS:
      return state.set('catalogs', fromJS(action.payload.catalogs));
    default:
      return state;
  }
};
