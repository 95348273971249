import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { register } from 'actions/auth';
import { selectActiveLanguage } from 'components/Navbar/modules/selectors';
import { getCountriesList, getIndustriesList } from './modules/actions';
import { selectCountriesList, selectIndustriesList } from './modules/selectors';

const mapStateToProps = (state) => ({
  countriesList: selectCountriesList(state),
  activeLanguage: selectActiveLanguage(state),
  industriesList: selectIndustriesList(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      register,
      getCountriesList,
      getIndustriesList,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
