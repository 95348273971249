import React from 'react';
import { string } from 'prop-types';

const WestIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 51.547 49.174"
  >
    <g
      id="prefix__Group_656"
      data-name="Group 656"
      transform="translate(-449.96 -469.718)"
    >
      <g
        id="prefix__Group_187"
        data-name="Group 187"
        transform="translate(34.198 -48.855)"
      >
        <g id="prefix__Group_169" data-name="Group 169">
          <g id="prefix__Group_161" data-name="Group 161">
            <g id="prefix__Group_160" data-name="Group 160">
              <path
                id="prefix__Path_192"
                d="M436.257 549.028h-11.464a1.5 1.5 0 0 1-1.5-1.5 1.5 1.5 0 0 1 1.5-1.5h11.464a1.5 1.5 0 0 1 1.5 1.5 1.5 1.5 0 0 1-1.5 1.5z"
                className="prefix__cls-1"
                data-name="Path 192"
                style={{ fill: color }}
              />
            </g>
          </g>
          <g id="prefix__Group_163" data-name="Group 163">
            <g id="prefix__Group_162" data-name="Group 162">
              <path
                id="prefix__Path_193"
                d="M436.257 554.217h-11.464a1.5 1.5 0 0 1-1.5-1.5 1.5 1.5 0 0 1 1.5-1.5h11.464a1.5 1.5 0 0 1 1.5 1.5 1.5 1.5 0 0 1-1.5 1.5z"
                className="prefix__cls-1"
                data-name="Path 193"
                style={{ fill: color }}
              />
            </g>
          </g>
          <g id="prefix__Group_165" data-name="Group 165">
            <g id="prefix__Group_164" data-name="Group 164">
              <path
                id="prefix__Path_194"
                d="M459.694 549.028H448.23a1.5 1.5 0 0 1-1.5-1.5 1.5 1.5 0 0 1 1.5-1.5h11.464a1.5 1.5 0 0 1 1.5 1.5 1.5 1.5 0 0 1-1.5 1.5z"
                className="prefix__cls-1"
                data-name="Path 194"
                style={{ fill: color }}
              />
            </g>
          </g>
          <g id="prefix__Group_167" data-name="Group 167">
            <g id="prefix__Group_166" data-name="Group 166">
              <path
                id="prefix__Path_195"
                d="M459.694 554.217H448.23a1.5 1.5 0 0 1-1.5-1.5 1.5 1.5 0 0 1 1.5-1.5h11.464a1.5 1.5 0 0 1 1.5 1.5 1.5 1.5 0 0 1-1.5 1.5z"
                className="prefix__cls-1"
                data-name="Path 195"
                style={{ fill: color }}
              />
            </g>
          </g>
          <g id="prefix__Group_168" data-name="Group 168">
            <path
              id="prefix__Path_196"
              d="M442.307 537.2a2.074 2.074 0 0 0-1.664 2.079v23.309h-18.786v-22.135a6.994 6.994 0 0 0 5.2-6.751v-11.129h4.963v1.855a11.09 11.09 0 0 0 9.361 10.991 8.691 8.691 0 0 0 1.28.089v-3.995a4.546 4.546 0 0 1-.643-.043 7.1 7.1 0 0 1-6-7.042v-3.8l-.038-1.306a.775.775 0 0 0-.775-.753h-10.087l-1.273.036a.809.809 0 0 0-.786.809v14.4a2.944 2.944 0 0 1-3.034 2.865 2.216 2.216 0 0 0-2.168 2.222v25.385a2.3 2.3 0 0 0 2.3 2.3h22.183a2.3 2.3 0 0 0 2.3-2.3v-25.11a2 2 0 0 0-2.333-1.976z"
              data-name="Path 196"
              style={{ fill: '#050713' }}
            />
            <path
              id="prefix__Path_197"
              d="M465.033 536.683a2.612 2.612 0 0 1-2.557-2.674v-13.377l-.036-1.273a.809.809 0 0 0-.809-.786h-10.315l-1.306.037a.775.775 0 0 0-.753.775v2.752c0 3.806-.9 6.5-2.67 8.01a6.081 6.081 0 0 1-3.924 1.365v3.995a10.068 10.068 0 0 0 6.518-2.315c2.626-2.237 3.995-5.809 4.072-10.62h5.223v11.5a6.624 6.624 0 0 0 4.833 6.368v22.15h-14.726a2.075 2.075 0 0 0-2.08 1.664 2 2 0 0 0 1.973 2.336h16.532a2.3 2.3 0 0 0 2.3-2.3V538.9a2.225 2.225 0 0 0-2.275-2.217z"
              className="prefix__cls-1"
              data-name="Path 197"
              style={{ fill: color }}
            />
          </g>
          <path
            id="prefix__Path_198"
            d="M434.163 547.184H422.7a1.5 1.5 0 0 0 0 3h11.464a1.5 1.5 0 0 0 0-3z"
            className="prefix__cls-3"
            data-name="Path 198"
            style={{ fill: color, opacity: 0.2 }}
          />
          <path
            id="prefix__Path_199"
            d="M434.163 552.373H422.7a1.5 1.5 0 0 0 0 3h11.464a1.5 1.5 0 0 0 0-3z"
            className="prefix__cls-3"
            data-name="Path 199"
            style={{ fill: color, opacity: 0.2 }}
          />
          <path
            id="prefix__Path_202"
            d="M462.939 537.839a2.612 2.612 0 0 1-2.557-2.674v-13.377l-.036-1.273a.809.809 0 0 0-.809-.786h-10.315l-1.306.037a.775.775 0 0 0-.753.775v2.752c0 3.806-.9 6.5-2.67 8.01a6.088 6.088 0 0 1-3.924 1.366 4.573 4.573 0 0 1-.644-.043 7.1 7.1 0 0 1-6-7.042v-3.8l-.038-1.306a.775.775 0 0 0-.775-.753h-10.089l-1.273.036a.809.809 0 0 0-.786.809v14.4a2.944 2.944 0 0 1-3.035 2.865 2.216 2.216 0 0 0-2.167 2.222v25.385a2.3 2.3 0 0 0 2.3 2.3h22.183a2.3 2.3 0 0 0 2.3-2.3v-25.11a2 2 0 0 0-2.336-1.973 2.074 2.074 0 0 0-1.664 2.079v23.309h-18.783v-22.138a6.994 6.994 0 0 0 5.2-6.751v-11.129h4.963v1.855a11.089 11.089 0 0 0 9.361 10.991 8.691 8.691 0 0 0 1.28.089 10.064 10.064 0 0 0 6.517-2.315c2.626-2.237 3.995-5.809 4.072-10.62h5.223v11.5a6.624 6.624 0 0 0 4.833 6.368v22.151h-14.724a2.075 2.075 0 0 0-2.08 1.664 2 2 0 0 0 1.973 2.336h16.532a2.3 2.3 0 0 0 2.3-2.3V540.06a2.222 2.222 0 0 0-2.273-2.221z"
            className="prefix__cls-3"
            data-name="Path 202"
            style={{ fill: color, opacity: 0.2 }}
          />
        </g>
      </g>
    </g>
  </svg>
);

WestIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

WestIcon.defaultProps = {
  color: '#37ead9',
  width: '51.547',
  height: '49.174',
};

export default WestIcon;
