import React from 'react';

const ArrowDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5">
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M6.667 12.667c-.156 0-.307-.054-.427-.154-.136-.113-.222-.275-.238-.452-.017-.176.038-.352.151-.488L9.14 8 6.26 4.42c-.112-.138-.164-.314-.145-.49.018-.177.107-.339.245-.45.14-.123.324-.182.509-.163.184.02.353.115.464.263l3.22 4c.203.246.203.6 0 .847l-3.333 4c-.136.163-.341.252-.553.24z" transform="matrix(0 1 1 0 -3 -6)" />
    </g>
  </svg>


);

export default ArrowDown;
