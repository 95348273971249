import React from 'react';

const HomeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16">
    <path fill="#FFF" stroke="#FFF" strokeWidth=".4" d="M13.18 6.475L7.346 1.128c-.19-.174-.482-.17-.667.01L1.17 6.483c-.093.092-.147.218-.147.35v7.68c0 .268.218.486.486.486H5.56c.269 0 .486-.218.486-.486v-3.565h2.269v3.565c0 .268.218.486.486.486h4.051c.268 0 .486-.217.486-.486v-7.68c0-.137-.057-.267-.157-.36zm-.815 7.553H9.286v-3.565c0-.269-.217-.486-.486-.486H5.56c-.27 0-.487.217-.487.486v3.565H1.994v-6.99l5.033-4.884 5.338 4.893v6.98z" />
  </svg>

);

export default HomeIcon;
