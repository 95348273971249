import { Form } from 'antd';
import React from 'react';
import { EditableContext } from '../constants';

// eslint-disable-next-line react/prop-types
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export default EditableRow;
