import React from 'react';
import { list } from 'react-immutable-proptypes';
import { shape, func } from 'prop-types';
import Slider from 'react-slick';

import NextArrow from '../Arrows/NextArrow';
import PreviousArrow from '../Arrows/PreviousArrow';
import HomeSliderCard from './HomeSliderCard';
import styles from './homeSlider.module.scss';

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  arrows: true,
  prevArrow: <PreviousArrow />,
  nextArrow: <NextArrow />,
  responsive: [{
    breakpoint: 767,
    settings: {
      arrows: false,
      swipeToSlide: true,
      dots: true,
    },
  }],
};

const HomeSlider = (props) => {
  const { featuredProducts, actions } = props;

  if (featuredProducts.size === 0) {
    return null;
  }

  return (
    <div className={styles.slideContainer}>
      <Slider {...settings}>
        {featuredProducts.map((product) => (
          <HomeSliderCard actions={actions} product={product} />
        ))}
      </Slider>
    </div>
  );
};

HomeSlider.propTypes = {
  featuredProducts: list.isRequired,
  actions: shape({
    showModal: func.isRequired,
    setNextRoute: func.isRequired,
    setNextRouteParams: func.isRequired,
  }).isRequired,
};

export default HomeSlider;
