import { combineReducers } from 'redux';

import registerReducer from 'components/Register/modules/reducer';
import navbarReducer from 'components/Navbar/modules/reducer';
import loginReducer from 'components/Login/modules/reducer';
import companyColorPalette from 'components/CreateCompany/ColorPalette/modules/reducer';
import companyBackgroundSelect from 'components/CreateCompany/SelectHeaderBackground/modules/reducer';
import iconSelect from 'components/CreateCompany/AddIcon/modules/reducer';
import subscriptionPackagesReducer from 'components/SubscriptionPackages/modules/reducer';
import feedContentReducer from 'components/Feed/modules/reducer';
import pictureExpandModalReducer from 'components/PictureExpand/modules/reducer';
import chatReducer from 'components/Chat/modules/reducer';
import productsDemandsReducer from 'components/CreateProductDemand/modules/reducer';
import companiesReducer from 'components/Chat/CompaniesList/modules/reducer';
import companyCatalogReducer from 'components/Catalog/modules/reducer';
import notificationReducer from 'components/Navbar/NavBarLoggedUser/Notifications/modules/reducer';
import interestsReducer from 'components/Interests/modules/reducer';
import adminPanelReducer from 'components/AdminPanel/modules/reducer';
import webShopReducer from 'components/WebShop/modules/reducer';
import commentsReducer from 'components/Comments/modules/reducer';
import homepageContentReducer from 'components/Home/modules/reducer';
import blogReducer from 'components/News/modules/reducer';
import auth from './auth';
import bannerReducer from 'components/Banner/modules/reducer';

export default combineReducers({
  auth,
  registerReducer,
  navbarReducer,
  loginReducer,
  companyColorPalette,
  companyBackgroundSelect,
  iconSelect,
  subscriptionPackagesReducer,
  feedContentReducer,
  pictureExpandModalReducer,
  chatReducer,
  productsDemandsReducer,
  companiesReducer,
  companyCatalogReducer,
  notificationReducer,
  interestsReducer,
  adminPanelReducer,
  webShopReducer,
  commentsReducer,
  homepageContentReducer,
  blogReducer,
  bannerReducer,
});
