import React from 'react';
import { string } from 'prop-types';

const EmblemIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 52.791 44.711"
  >
    <g
      id="prefix__Group_651"
      data-name="Group 651"
      transform="translate(-959.499 -330.056)"
    >
      <g
        id="prefix__Group_27"
        data-name="Group 27"
        transform="translate(712.198 -28.855)"
      >
        <g id="prefix__Group_23" data-name="Group 23">
          <path
            id="prefix__Path_35"
            d="M275.053 402.037a16.331 16.331 0 0 1-16.313-16.313v-23.271a3.546 3.546 0 0 1 3.542-3.542h25.542a3.546 3.546 0 0 1 3.542 3.542v23.271a16.331 16.331 0 0 1-16.313 16.313zm-12.312-39.125v22.813a12.327 12.327 0 0 0 12.312 12.313 12.327 12.327 0 0 0 12.313-12.313v-22.813z"
            className="prefix__cls-1"
            data-name="Path 35"
            style={{ fill: color }}
          />
        </g>
        <g id="prefix__Group_24" data-name="Group 24">
          <path
            id="prefix__Path_36"
            d="M298.092 379.412h-47a2 2 0 0 1-2-2 2 2 0 0 1 2-2h47a2 2 0 0 1 2 2 2 2 0 0 1-2 2z"
            className="prefix__cls-1"
            data-name="Path 36"
            style={{ fill: color }}
          />
        </g>
        <g id="prefix__Group_26" data-name="Group 26">
          <g id="prefix__Group_25" data-name="Group 25">
            <path
              id="prefix__Path_37"
              d="M276.184 392.8a1.806 1.806 0 0 1-2.552 0 4.558 4.558 0 0 0-5.383-.783 1.8 1.8 0 0 1-2.134-.3 1.816 1.816 0 0 1 .445-2.885 8.191 8.191 0 0 1 8.21.289.255.255 0 0 0 .276 0 8.191 8.191 0 0 1 8.21-.289 1.816 1.816 0 0 1 .445 2.885 1.8 1.8 0 0 1-2.134.3 4.559 4.559 0 0 0-5.383.783z"
              data-name="Path 37"
              style={{ fill: '#050713' }}
            />
          </g>
        </g>
      </g>
      <path
        id="prefix__Path_38"
        d="M296.3 377h-6.727v-12.962a3.546 3.546 0 0 0-3.542-3.542H260.49a3.545 3.545 0 0 0-3.541 3.542V377H249.3a2 2 0 0 0 0 4h7.648v6.313a16.313 16.313 0 1 0 32.625 0V381h6.727a2 2 0 0 0 0-4zm-35.352-12.5h24.625V377h-24.624zm24.625 22.812a12.327 12.327 0 0 1-12.312 12.313 12.326 12.326 0 0 1-12.312-12.313V381h24.625v6.313z"
        className="prefix__cls-3"
        data-name="Path 38"
        transform="translate(712.198 -28.855)"
        style={{ fill: color, opacity: 0.2 }}
      />
      <path
        id="prefix__Path_39"
        d="M264.324 393.3a1.8 1.8 0 0 0 2.134.3 4.556 4.556 0 0 1 5.383.783 1.8 1.8 0 0 0 2.551 0 4.558 4.558 0 0 1 5.383-.783 1.8 1.8 0 0 0 2.134-.3 1.816 1.816 0 0 0-.445-2.885 8.191 8.191 0 0 0-8.21.289.255.255 0 0 1-.276 0 8.191 8.191 0 0 0-8.21-.289 1.817 1.817 0 0 0-.444 2.885z"
        className="prefix__cls-3"
        data-name="Path 39"
        transform="translate(712.198 -28.855)"
        style={{ fill: color, opacity: 0.2 }}
      />
    </g>
  </svg>
);

EmblemIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

EmblemIcon.defaultProps = {
  color: '#37ead9',
  width: '52.791',
  height: '44.711',
};

export default EmblemIcon;
