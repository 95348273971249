import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  selectActiveCompanyColorTheme,
  selectActiveCompanyFontFamily,
} from 'selectors/auth';
import { setChangesMade } from 'actions/auth';
import { uploadTestimonialUserImage } from '../ColorPalette/modules/actions';
import { selectTestimonialUserImage } from '../ColorPalette/modules/selectors';

const mapStateToProps = (state) => ({
  colorTheme: selectActiveCompanyColorTheme(state),
  fontFamily: selectActiveCompanyFontFamily(state),
  uploadedImageObject: selectTestimonialUserImage(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      uploadTestimonialUserImage,
      setChangesMade,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
