import i18n from 'i18next';

const termsAndConditions = () => [
  {
    id: 1,
    title: i18n.t('termsAndConditions introductoryProvisions title'),
    text: i18n.t('termsAndConditions introductoryProvisions text'),
  },
  {
    id: 2,
    title: i18n.t('termsAndConditions userRegistration title'),
    text: i18n.t('termsAndConditions userRegistration text'),
  },
  {
    id: 3,
    title: i18n.t('termsAndConditions rightAndRestrictions title'),
    text: i18n.t('termsAndConditions rightAndRestrictions text'),
  },
  {
    id: 4,
    title: i18n.t('termsAndConditions contentPublishing title'),
    text: i18n.t('termsAndConditions contentPublishing text'),
  },
  {
    id: 5,
    title: i18n.t('termsAndConditions communicationViaBizbook title'),
    text: i18n.t('termsAndConditions communicationViaBizbook text'),
  },
  {
    id: 6,
    title: i18n.t('termsAndConditions searchingPlatformContent title'),
    text: i18n.t('termsAndConditions searchingPlatformContent text'),
  },
  {
    id: 7,
    title: i18n.t('termsAndConditions viewOfAllUsers title'),
    text: i18n.t('termsAndConditions viewOfAllUsers text'),
  },
  {
    id: 8,
    title: i18n.t('termsAndConditions usingPlatform title'),
    text: i18n.t('termsAndConditions usingPlatform text'),
  },
  {
    id: 9,
    title: i18n.t('termsAndConditions priceOfServices title'),
    text: i18n.t('termsAndConditions priceOfServices text'),
  },
  {
    id: 10,
    title: i18n.t('termsAndConditions copyright title'),
    text: i18n.t('termsAndConditions copyright text'),
  },
  {
    id: 11,
    title: i18n.t('termsAndConditions linkToWebsiteUsers title'),
    text: i18n.t('termsAndConditions linkToWebsiteUsers text'),
  },
  {
    id: 12,
    title: i18n.t('termsAndConditions userPrivacy title'),
    text: i18n.t('termsAndConditions userPrivacy text'),
  },
  {
    id: 13,
    title: i18n.t('termsAndConditions rulesOfBehavior title'),
    text: i18n.t('termsAndConditions rulesOfBehavior text'),
  },
  {
    id: 14,
    title: i18n.t('termsAndConditions contractedJurisdiction title'),
    text: i18n.t('termsAndConditions contractedJurisdiction text'),
  },
  {
    id: 15,
    title: i18n.t('termsAndConditions changingTermsOfUse title'),
    text: i18n.t('termsAndConditions changingTermsOfUse text'),
  },
];

export default termsAndConditions;
