import React, { useEffect, useState } from 'react';
import PropTypes, { bool } from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { handleCompanyProgress } from 'utils/constants';
import { Map as IMap } from 'immutable';
import isEqual from 'lodash/isEqual';
import { composeViewCompanyUrl } from 'utils/pathsHelper';
import { isLoggedUser } from 'utils/authorizationHelper';
import UnsavedChangesPrompt from './UnsavedChangesPrompt';
import ProgressBar from './ProgressBar';
import CompanyHeader from './CompanyHeader';
import AboutUsSection from './AboutUs';
import WhatWeDo from './WhatWeDo';
import ProductsAndServicesHeader from './ProductsAndServicesHeader';
import ProductsAndServices from './ProductsAndServices';
import OurClients from './OurClients';
import ContactInfo from './ContactInfo';
import SocialLinks from './SocialLinks';
import whatWeDoMockData from './WhatWeDo/mockData';
import productsAndServicesMockData from './ProductsAndServices/mockData';
import ourClientsMockData from './OurClients/mockData';
import styles from '../AboutCompany/aboutCompany.module.scss';

const CreateCompany = (props) => {
  const {
    myCompanyData,
    actions,
    companyProgress,
    adminRole,
    activeCompanyData,
    changesMade,
  } = props;
  const history = useHistory();
  const params = useParams();
  const { id } = params;

  const [companyCompletion, setCompanyCompletion] = useState({});
  const [progress, setProgress] = useState(0);
  const [companyData, setCurrentCompanyData] = useState(IMap());

  const updateProgress = (sectionName, completed) => {
    setCompanyCompletion((prevCompletionState) => ({
      ...prevCompletionState,
      [sectionName]: completed,
    }));
  };

  useEffect(() => {
    actions.setChangesMade(false);
    if (!isLoggedUser(id)
    && !adminRole) {
      history.push(composeViewCompanyUrl(id));
    } else if (isLoggedUser(id)) {
      actions.loadCompanyDetails(localStorage.getItem('userId'));
      actions.getCompanyDetailsById(id);
    } else {
      actions.getCompanyDetailsById(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, adminRole]);

  useEffect(() => {
    if (activeCompanyData.size > 0) {
      if (adminRole) {
        setCurrentCompanyData(activeCompanyData);
      } else {
        setCurrentCompanyData(myCompanyData);
      }
    }
  }, [myCompanyData, activeCompanyData, adminRole]);

  const updateCompanyJson = (fieldName, dataObject) => {
    if (isLoggedUser(id)) {
      actions.updateCompanyJson(fieldName, dataObject);
    } else {
      actions.updateActiveCompanyJson(fieldName, dataObject);
    }
  };

  const updateCompanyDetails = (fieldName, value) => {
    if (isLoggedUser(id)) {
      actions.updateCompanyDetails(fieldName, value);
    } else {
      actions.updateActiveCompanyDetails(fieldName, value);
    }
  };

  useEffect(() => {
    if (
      Object.keys(companyCompletion).length >= 4
      && companyData.size > 0
      && !isEqual(
        progress,
        handleCompanyProgress(
          Object.keys(companyCompletion).filter((key) => companyCompletion[key])
            .length,
        ),
      )
    ) {
      const newProgress = handleCompanyProgress(
        Object.keys(companyCompletion).filter((key) => companyCompletion[key])
          .length,
      );
      setProgress(newProgress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCompletion, companyData]);

  useEffect(() => {
    if (
      progress !== 0
      && progress !== companyProgress
      && companyData.size > 0
    ) {
      updateCompanyJson('companyProgress', progress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData, progress, companyProgress]);

  return (
    <div className={styles.aboutCompanyContainer}>
      <div className={classNames('col md-12 p-0', styles.progressBar)}>
        <ProgressBar progress={progress} />
      </div>
      <CompanyHeader
        title={companyData.getIn(['companyJson', 'companyHeader', 'title'], '')}
        subtitle={companyData.getIn(
          ['companyJson', 'companyHeader', 'subtitle'],
          '',
        )}
        saveChange={updateCompanyJson}
        textAlignment={companyData.getIn(
          ['companyJson', 'companyHeader', 'textAlignment'],
          'text-left',
        )}
        setSectionCompleted={updateProgress}
      />
      <AboutUsSection
        sectionTitle={companyData.getIn(
          ['companyJson', 'aboutUsSection', 'sectionTitle'],
          '',
        )}
        shortInfo={companyData.getIn(
          ['companyJson', 'aboutUsSection', 'shortInfo'],
          '',
        )}
        iconName={companyData.getIn([
          'companyJson',
          'aboutUsSection',
          'iconName',
        ])}
        saveChange={updateCompanyJson}
        textAlignment={companyData.getIn(
          ['companyJson', 'aboutUsSection', 'textAlignment'],
          'text-left',
        )}
        setSectionCompleted={updateProgress}
      />
      <WhatWeDo
        productsAndServices={companyData.getIn(
          ['companyJson', 'whatWeDo', 'productsAndServices'],
          whatWeDoMockData,
        )}
        saveChange={updateCompanyJson}
        textAlignment={companyData.getIn(
          ['companyJson', 'whatWeDo', 'textAlignment'],
          'text-left',
        )}
        setSectionCompleted={updateProgress}
      />
      <ProductsAndServicesHeader
        title={companyData.getIn(
          ['companyJson', 'productsAndServicesHeader', 'title'],
          '',
        )}
        description={companyData.getIn(
          ['companyJson', 'productsAndServicesHeader', 'description'],
          '',
        )}
        saveChange={updateCompanyJson}
        textAlignment={companyData.getIn(
          ['companyJson', 'productsAndServicesHeader', 'textAlignment'],
          'text-left',
        )}
        companyId={companyData.get('id', 0)}
      />
      <ProductsAndServices
        productsAndServices={companyData.getIn(
          ['companyJson', 'productsAndServices', 'productsAndServices'],
          productsAndServicesMockData,
        )}
        saveChange={updateCompanyJson}
        textAlignment={companyData.getIn(
          ['companyJson', 'productsAndServices', 'textAlignment'],
          'text-left',
        )}
      />
      <OurClients
        clients={companyData.getIn(
          ['companyJson', 'ourClients'],
          ourClientsMockData,
        )}
        saveChange={updateCompanyJson}
        setSectionCompleted={updateProgress}
      />
      <ContactInfo
        title={companyData.getIn(['companyJson', 'contactInfo', 'title'], '')}
        address={companyData.get('address', '')}
        city={companyData.get('city', null)}
        countryIso={companyData.get('countryIso', '')}
        idNumber={companyData.get('idNumber', null)}
        phone={companyData.get('phone', null)}
        contactEmail={companyData.get('contactEmail', null)}
        website={companyData.get('website', null)}
        saveChange={updateCompanyDetails}
        updateCompanyJson={updateCompanyJson}
        textAlignment={companyData.getIn(
          ['companyJson', 'contactInfo', 'textAlignment'],
          'text-left',
        )}
        setSectionCompleted={updateProgress}
      />
      <SocialLinks
        facebookLink={companyData.get('facebookLink', '') || ''}
        instagramLink={companyData.get('instagramLink', '') || ''}
        linkedinLink={companyData.get('linkedinLink', '') || ''}
        saveChange={updateCompanyDetails}
      />
      <UnsavedChangesPrompt when={changesMade} />
    </div>
  );
};

const { shape, func, number } = PropTypes;
const { map } = ImmutablePropTypes;

CreateCompany.propTypes = {
  myCompanyData: map.isRequired,
  actions: shape({
    loadCompanyDetails: func.isRequired,
    updateCompanyDetails: func.isRequired,
    updateCompanyJson: func.isRequired,
    saveCompanyDetails: func.isRequired,
    getCompanyDetailsById: func.isRequired,
    updateActiveCompanyDetails: func.isRequired,
    updateActiveCompanyJson: func.isRequired,
    setChangesMade: func.isRequired,
    deactivateLoader: func.isRequired,
  }).isRequired,
  companyProgress: number.isRequired,
  adminRole: bool.isRequired,
  activeCompanyData: map.isRequired,
  changesMade: bool.isRequired,
};

export default CreateCompany;
