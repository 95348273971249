import { fromJS } from 'immutable';

export const countryCodeLatLong = fromJS({
  ad: {
    lat: 42.5000,
    lng: 1.5000,
  },
  ae: {
    lat: 24.0000,
    lng: 54.0000,
  },
  af: {
    lat: 33.0000,
    lng: 65.0000,
  },
  ag: {
    lat: 17.0500,
    lng: -61.8000,
  },
  ai: {
    lat: 18.2500,
    lng: -63.1667,
  },
  al: {
    lat: 41.0000,
    lng: 20.0000,
  },
  am: {
    lat: 40.0000,
    lng: 45.0000,
  },
  an: {
    lat: 12.2500,
    lng: -68.7500,
  },
  ao: {
    lat: -12.5000,
    lng: 18.5000,
  },
  ap: {
    lat: 35.0000,
    lng: 105.0000,
  },
  aq: {
    lat: -90.0000,
    lng: 0.0000,
  },
  ar: {
    lat: -34.0000,
    lng: -64.0000,
  },
  as: {
    lat: -14.3333,
    lng: -170.0000,
  },
  at: {
    lat: 47.3333,
    lng: 13.3333,
  },
  au: {
    lat: -27.0000,
    lng: 133.0000,
  },
  aw: {
    lat: 12.5000,
    lng: -69.9667,
  },
  az: {
    lat: 40.5000,
    lng: 47.5000,
  },
  ba: {
    lat: 44.0000,
    lng: 18.0000,
  },
  bb: {
    lat: 13.1667,
    lng: -59.5333,
  },
  bd: {
    lat: 24.0000,
    lng: 90.0000,
  },
  be: {
    lat: 50.8333,
    lng: 4.0000,
  },
  bf: {
    lat: 13.0000,
    lng: -2.0000,
  },
  bg: {
    lat: 43.0000,
    lng: 25.0000,
  },
  bh: {
    lat: 26.0000,
    lng: 50.5500,
  },
  bi: {
    lat: -3.5000,
    lng: 30.0000,
  },
  bj: {
    lat: 9.5000,
    lng: 2.2500,
  },
  bm: {
    lat: 32.3333,
    lng: -64.7500,
  },
  bn: {
    lat: 4.5000,
    lng: 114.6667,
  },
  bo: {
    lat: -17.0000,
    lng: -65.0000,
  },
  br: {
    lat: -10.0000,
    lng: -55.0000,
  },
  bs: {
    lat: 24.2500,
    lng: -76.0000,
  },
  bt: {
    lat: 27.5000,
    lng: 90.5000,
  },
  bv: {
    lat: -54.4333,
    lng: 3.4000,
  },
  bw: {
    lat: -22.0000,
    lng: 24.0000,
  },
  by: {
    lat: 53.0000,
    lng: 28.0000,
  },
  bz: {
    lat: 17.2500,
    lng: -88.7500,
  },
  ca: {
    lat: 60.0000,
    lng: -95.0000,
  },
  cc: {
    lat: -12.5000,
    lng: 96.8333,
  },
  cd: {
    lat: 0.0000,
    lng: 25.0000,
  },
  cf: {
    lat: 7.0000,
    lng: 21.0000,
  },
  cg: {
    lat: -1.0000,
    lng: 15.0000,
  },
  ch: {
    lat: 47.0000,
    lng: 8.0000,
  },
  ci: {
    lat: 8.0000,
    lng: -5.0000,
  },
  ck: {
    lat: -21.2333,
    lng: -159.7667,
  },
  cl: {
    lat: -30.0000,
    lng: -71.0000,
  },
  cm: {
    lat: 6.0000,
    lng: 12.0000,
  },
  cn: {
    lat: 35.0000,
    lng: 105.0000,
  },
  co: {
    lat: 4.0000,
    lng: -72.0000,
  },
  cr: {
    lat: 10.0000,
    lng: -84.0000,
  },
  cu: {
    lat: 21.5000,
    lng: -80.0000,
  },
  cv: {
    lat: 16.0000,
    lng: -24.0000,
  },
  cx: {
    lat: -10.5000,
    lng: 105.6667,
  },
  cy: {
    lat: 35.0000,
    lng: 33.0000,
  },
  cz: {
    lat: 49.7500,
    lng: 15.5000,
  },
  de: {
    lat: 51.0000,
    lng: 9.0000,
  },
  dj: {
    lat: 11.5000,
    lng: 43.0000,
  },
  dk: {
    lat: 56.0000,
    lng: 10.0000,
  },
  dm: {
    lat: 15.4167,
    lng: -61.3333,
  },
  do: {
    lat: 19.0000,
    lng: -70.6667,
  },
  dz: {
    lat: 28.0000,
    lng: 3.0000,
  },
  ec: {
    lat: -2.0000,
    lng: -77.5000,
  },
  ee: {
    lat: 59.0000,
    lng: 26.0000,
  },
  eg: {
    lat: 27.0000,
    lng: 30.0000,
  },
  eh: {
    lat: 24.5000,
    lng: -13.0000,
  },
  er: {
    lat: 15.0000,
    lng: 39.0000,
  },
  es: {
    lat: 40.0000,
    lng: -4.0000,
  },
  et: {
    lat: 8.0000,
    lng: 38.0000,
  },
  eu: {
    lat: 47.0000,
    lng: 8.0000,
  },
  fi: {
    lat: 64.0000,
    lng: 26.0000,
  },
  fj: {
    lat: -18.0000,
    lng: 175.0000,
  },
  fk: {
    lat: -51.7500,
    lng: -59.0000,
  },
  fm: {
    lat: 6.9167,
    lng: 158.2500,
  },
  fo: {
    lat: 62.0000,
    lng: -7.0000,
  },
  fr: {
    lat: 46.0000,
    lng: 2.0000,
  },
  ga: {
    lat: -1.0000,
    lng: 11.7500,
  },
  gb: {
    lat: 54.0000,
    lng: -2.0000,
  },
  gd: {
    lat: 12.1167,
    lng: -61.6667,
  },
  ge: {
    lat: 42.0000,
    lng: 43.5000,
  },
  gf: {
    lat: 4.0000,
    lng: -53.0000,
  },
  gh: {
    lat: 8.0000,
    lng: -2.0000,
  },
  gi: {
    lat: 36.1833,
    lng: -5.3667,
  },
  gl: {
    lat: 72.0000,
    lng: -40.0000,
  },
  gm: {
    lat: 13.4667,
    lng: -16.5667,
  },
  gn: {
    lat: 11.0000,
    lng: -10.0000,
  },
  gp: {
    lat: 16.2500,
    lng: -61.5833,
  },
  gq: {
    lat: 2.0000,
    lng: 10.0000,
  },
  gr: {
    lat: 39.0000,
    lng: 22.0000,
  },
  gs: {
    lat: -54.5000,
    lng: -37.0000,
  },
  gt: {
    lat: 15.5000,
    lng: -90.2500,
  },
  gu: {
    lat: 13.4667,
    lng: 144.7833,
  },
  gw: {
    lat: 12.0000,
    lng: -15.0000,
  },
  gy: {
    lat: 5.0000,
    lng: -59.0000,
  },
  hk: {
    lat: 22.2500,
    lng: 114.1667,
  },
  hm: {
    lat: -53.1000,
    lng: 72.5167,
  },
  hn: {
    lat: 15.0000,
    lng: -86.5000,
  },
  hr: {
    lat: 45.1667,
    lng: 15.5000,
  },
  ht: {
    lat: 19.0000,
    lng: -72.4167,
  },
  hu: {
    lat: 47.0000,
    lng: 20.0000,
  },
  id: {
    lat: -5.0000,
    lng: 120.0000,
  },
  ie: {
    lat: 53.0000,
    lng: -8.0000,
  },
  il: {
    lat: 31.5000,
    lng: 34.7500,
  },
  in: {
    lat: 20.0000,
    lng: 77.0000,
  },
  io: {
    lat: -6.0000,
    lng: 71.5000,
  },
  iq: {
    lat: 33.0000,
    lng: 44.0000,
  },
  ir: {
    lat: 32.0000,
    lng: 53.0000,
  },
  is: {
    lat: 65.0000,
    lng: -18.0000,
  },
  it: {
    lat: 42.8333,
    lng: 12.8333,
  },
  jm: {
    lat: 18.2500,
    lng: -77.5000,
  },
  jo: {
    lat: 31.0000,
    lng: 36.0000,
  },
  jp: {
    lat: 36.0000,
    lng: 138.0000,
  },
  ke: {
    lat: 1.0000,
    lng: 38.0000,
  },
  kg: {
    lat: 41.0000,
    lng: 75.0000,
  },
  kh: {
    lat: 13.0000,
    lng: 105.0000,
  },
  ki: {
    lat: 1.4167,
    lng: 173.0000,
  },
  km: {
    lat: -12.1667,
    lng: 44.2500,
  },
  kn: {
    lat: 17.3333,
    lng: -62.7500,
  },
  kp: {
    lat: 40.0000,
    lng: 127.0000,
  },
  kr: {
    lat: 37.0000,
    lng: 127.5000,
  },
  kw: {
    lat: 29.3375,
    lng: 47.6581,
  },
  ky: {
    lat: 19.5000,
    lng: -80.5000,
  },
  kz: {
    lat: 48.0000,
    lng: 68.0000,
  },
  la: {
    lat: 18.0000,
    lng: 105.0000,
  },
  lb: {
    lat: 33.8333,
    lng: 35.8333,
  },
  lc: {
    lat: 13.8833,
    lng: -61.1333,
  },
  li: {
    lat: 47.1667,
    lng: 9.5333,
  },
  lk: {
    lat: 7.0000,
    lng: 81.0000,
  },
  lr: {
    lat: 6.5000,
    lng: -9.5000,
  },
  ls: {
    lat: -29.5000,
    lng: 28.5000,
  },
  lt: {
    lat: 56.0000,
    lng: 24.0000,
  },
  lu: {
    lat: 49.7500,
    lng: 6.1667,
  },
  lv: {
    lat: 57.0000,
    lng: 25.0000,
  },
  ly: {
    lat: 25.0000,
    lng: 17.0000,
  },
  ma: {
    lat: 32.0000,
    lng: -5.0000,
  },
  mc: {
    lat: 43.7333,
    lng: 7.4000,
  },
  md: {
    lat: 47.0000,
    lng: 29.0000,
  },
  me: {
    lat: 42.0000,
    lng: 19.0000,
  },
  mg: {
    lat: -20.0000,
    lng: 47.0000,
  },
  mh: {
    lat: 9.0000,
    lng: 168.0000,
  },
  mk: {
    lat: 41.8333,
    lng: 22.0000,
  },
  ml: {
    lat: 17.0000,
    lng: -4.0000,
  },
  mm: {
    lat: 22.0000,
    lng: 98.0000,
  },
  mn: {
    lat: 46.0000,
    lng: 105.0000,
  },
  mo: {
    lat: 22.1667,
    lng: 113.5500,
  },
  mp: {
    lat: 15.2000,
    lng: 145.7500,
  },
  mq: {
    lat: 14.6667,
    lng: -61.0000,
  },
  mr: {
    lat: 20.0000,
    lng: -12.0000,
  },
  ms: {
    lat: 16.7500,
    lng: -62.2000,
  },
  mt: {
    lat: 35.8333,
    lng: 14.5833,
  },
  mu: {
    lat: -20.2833,
    lng: 57.5500,
  },
  mv: {
    lat: 3.2500,
    lng: 73.0000,
  },
  mw: {
    lat: -13.5000,
    lng: 34.0000,
  },
  mx: {
    lat: 23.0000,
    lng: -102.0000,
  },
  my: {
    lat: 2.5000,
    lng: 112.5000,
  },
  mz: {
    lat: -18.2500,
    lng: 35.0000,
  },
  na: {
    lat: -22.0000,
    lng: 17.0000,
  },
  nc: {
    lat: -21.5000,
    lng: 165.5000,
  },
  ne: {
    lat: 16.0000,
    lng: 8.0000,
  },
  nf: {
    lat: -29.0333,
    lng: 167.9500,
  },
  ng: {
    lat: 10.0000,
    lng: 8.0000,
  },
  ni: {
    lat: 13.0000,
    lng: -85.0000,
  },
  nl: {
    lat: 52.5000,
    lng: 5.7500,
  },
  no: {
    lat: 62.0000,
    lng: 10.0000,
  },
  np: {
    lat: 28.0000,
    lng: 84.0000,
  },
  nr: {
    lat: -0.5333,
    lng: 166.9167,
  },
  nu: {
    lat: -19.0333,
    lng: -169.8667,
  },
  nz: {
    lat: -41.0000,
    lng: 174.0000,
  },
  om: {
    lat: 21.0000,
    lng: 57.0000,
  },
  pa: {
    lat: 9.0000,
    lng: -80.0000,
  },
  pe: {
    lat: -10.0000,
    lng: -76.0000,
  },
  pf: {
    lat: -15.0000,
    lng: -140.0000,
  },
  pg: {
    lat: -6.0000,
    lng: 147.0000,
  },
  ph: {
    lat: 13.0000,
    lng: 122.0000,
  },
  pk: {
    lat: 30.0000,
    lng: 70.0000,
  },
  pl: {
    lat: 52.0000,
    lng: 20.0000,
  },
  pm: {
    lat: 46.8333,
    lng: -56.3333,
  },
  pr: {
    lat: 18.2500,
    lng: -66.5000,
  },
  ps: {
    lat: 32.0000,
    lng: 35.2500,
  },
  pt: {
    lat: 39.5000,
    lng: -8.0000,
  },
  pw: {
    lat: 7.5000,
    lng: 134.5000,
  },
  py: {
    lat: -23.0000,
    lng: -58.0000,
  },
  qa: {
    lat: 25.5000,
    lng: 51.2500,
  },
  re: {
    lat: -21.1000,
    lng: 55.6000,
  },
  ro: {
    lat: 46.0000,
    lng: 25.0000,
  },
  rs: {
    lat: 44.0000,
    lng: 21.0000,
  },
  ru: {
    lat: 60.0000,
    lng: 100.0000,
  },
  rw: {
    lat: -2.0000,
    lng: 30.0000,
  },
  sa: {
    lat: 25.0000,
    lng: 45.0000,
  },
  sb: {
    lat: -8.0000,
    lng: 159.0000,
  },
  sc: {
    lat: -4.5833,
    lng: 55.6667,
  },
  sd: {
    lat: 15.0000,
    lng: 30.0000,
  },
  se: {
    lat: 62.0000,
    lng: 15.0000,
  },
  sg: {
    lat: 1.3667,
    lng: 103.8000,
  },
  sh: {
    lat: -15.9333,
    lng: -5.7000,
  },
  si: {
    lat: 46.0000,
    lng: 15.0000,
  },
  sj: {
    lat: 78.0000,
    lng: 20.0000,
  },
  sk: {
    lat: 48.6667,
    lng: 19.5000,
  },
  sl: {
    lat: 8.5000,
    lng: -11.5000,
  },
  sm: {
    lat: 43.7667,
    lng: 12.4167,
  },
  sn: {
    lat: 14.0000,
    lng: -14.0000,
  },
  so: {
    lat: 10.0000,
    lng: 49.0000,
  },
  sr: {
    lat: 4.0000,
    lng: -56.0000,
  },
  st: {
    lat: 1.0000,
    lng: 7.0000,
  },
  sv: {
    lat: 13.8333,
    lng: -88.9167,
  },
  sy: {
    lat: 35.0000,
    lng: 38.0000,
  },
  sz: {
    lat: -26.5000,
    lng: 31.5000,
  },
  tc: {
    lat: 21.7500,
    lng: -71.5833,
  },
  td: {
    lat: 15.0000,
    lng: 19.0000,
  },
  tf: {
    lat: -43.0000,
    lng: 67.0000,
  },
  tg: {
    lat: 8.0000,
    lng: 1.1667,
  },
  th: {
    lat: 15.0000,
    lng: 100.0000,
  },
  tj: {
    lat: 39.0000,
    lng: 71.0000,
  },
  tk: {
    lat: -9.0000,
    lng: -172.0000,
  },
  tm: {
    lat: 40.0000,
    lng: 60.0000,
  },
  tn: {
    lat: 34.0000,
    lng: 9.0000,
  },
  to: {
    lat: -20.0000,
    lng: -175.0000,
  },
  tr: {
    lat: 39.0000,
    lng: 35.0000,
  },
  tt: {
    lat: 11.0000,
    lng: -61.0000,
  },
  tv: {
    lat: -8.0000,
    lng: 178.0000,
  },
  tw: {
    lat: 23.5000,
    lng: 121.0000,
  },
  tz: {
    lat: -6.0000,
    lng: 35.0000,
  },
  ua: {
    lat: 49.0000,
    lng: 32.0000,
  },
  ug: {
    lat: 1.0000,
    lng: 32.0000,
  },
  um: {
    lat: 19.2833,
    lng: 166.6000,
  },
  us: {
    lat: 38.0000,
    lng: -97.0000,
  },
  uy: {
    lat: -33.0000,
    lng: -56.0000,
  },
  uz: {
    lat: 41.0000,
    lng: 64.0000,
  },
  va: {
    lat: 41.9000,
    lng: 12.4500,
  },
  vc: {
    lat: 13.2500,
    lng: -61.2000,
  },
  ve: {
    lat: 8.0000,
    lng: -66.0000,
  },
  vg: {
    lat: 18.5000,
    lng: -64.5000,
  },
  vi: {
    lat: 18.3333,
    lng: -64.8333,
  },
  vn: {
    lat: 16.0000,
    lng: 106.0000,
  },
  vu: {
    lat: -16.0000,
    lng: 167.0000,
  },
  wf: {
    lat: -13.3000,
    lng: -176.2000,
  },
  ws: {
    lat: -13.5833,
    lng: -172.3333,
  },
  ye: {
    lat: 15.0000,
    lng: 48.0000,
  },
  yt: {
    lat: -12.8333,
    lng: 45.1667,
  },
  za: {
    lat: -29.0000,
    lng: 24.0000,
  },
  zm: {
    lat: -15.0000,
    lng: 30.0000,
  },
  zw: {
    lat: -20.0000,
    lng: 30.0000,
  },
});
