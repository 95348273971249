import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { facebook, instagram, linkedIn } from 'images/socialIcons';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { regexUrlValidation } from 'utils/constants';
import inputStyle from 'utils/inputStyle.module.scss';
import styles from './socialLinks.module.scss';

const SocialLink = (props) => {
  const {
    facebookLink, instagramLink, linkedinLink, saveChange,
  } = props;
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    facebookLink,
    instagramLink,
    linkedinLink,
  });
  const saveLink = (link, url) => {
    if (url) {
      if (regexUrlValidation.test(url)) {
        saveChange(link, url);
      } else {
        notification.error({
          message: t('notification error'),
          description: 'Entered link is not valid url',
        });
      }
    }
  };
  useEffect(() => {
    setFormData({
      facebookLink,
      instagramLink,
      linkedinLink,
    });
  }, [facebookLink, instagramLink, linkedinLink]);
  return (
    <div className={classNames(styles.socialLinksContainer)}>
      <h4 className="container">{t('createCompany socialLinks title')}</h4>
      <div className={classNames('container', styles.linksRow)}>
        <div
          className={classNames(
            inputStyle.formGroup,
            'col-12 col-md-3',
            styles.formGroup,
          )}
        >
          <h5 className="px-1">
            {t('createCompany socialLinks facebook label')}
          </h5>
          <div className={inputStyle.passwordContainer}>
            <input
              type="text"
              className={classNames(
                inputStyle.formControl,
                'py-2 my-1',
                styles.formControl,
              )}
              name="facebook"
              value={formData.facebookLink}
              placeholder="Enter here"
              onChange={(e) => setFormData({ ...formData, facebookLink: e.target.value })}
              onBlur={() => saveLink('facebookLink', formData.facebookLink)}
            />
            <img
              src={facebook}
              alt="facebook url"
              className={classNames(inputStyle.passwordInputImgStyle, 'ml-2')}
            />
          </div>
        </div>
        <div
          className={classNames(
            inputStyle.formGroup,
            'col-12 col-md-3',
            styles.formGroup,
          )}
        >
          <h5 className="px-1">
            {t('createCompany socialLinks instagram label')}
          </h5>
          <div
            className={classNames(
              inputStyle.passwordContainer,
              styles.passwordContainer,
            )}
          >
            <input
              type="text"
              className={classNames(
                inputStyle.formControl,
                'py-2 my-1',
                styles.formControl,
              )}
              name="instagram"
              value={formData.instagramLink}
              placeholder="Enter here"
              onChange={(e) => setFormData({ ...formData, instagramLink: e.target.value })}
              onBlur={() => saveLink('instagramLink', formData.instagramLink)}
            />
            <img
              src={instagram}
              alt="instagram url"
              className={classNames(inputStyle.passwordInputImgStyle, 'ml-2')}
            />
          </div>
        </div>
        <div
          className={classNames(
            inputStyle.formGroup,
            'col-12 col-md-3',
            styles.formGroup,
          )}
        >
          <h5 className="px-1">
            {t('createCompany socialLinks linkedin label')}
          </h5>
          <div
            className={classNames(
              inputStyle.passwordContainer,
              styles.passwordContainer,
            )}
          >
            <input
              type="text"
              className={classNames(
                inputStyle.formControl,
                'py-2 my-1',
                styles.formControl,
              )}
              name="linkedin"
              value={formData.linkedinLink}
              placeholder="Enter here"
              onChange={(e) => setFormData({ ...formData, linkedinLink: e.target.value })}
              onBlur={() => saveLink('linkedinLink', formData.linkedinLink)}
            />
            <img
              src={linkedIn}
              alt="linkedin url"
              className={classNames(inputStyle.passwordInputImgStyle, 'ml-2')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const { string, func } = PropTypes;

SocialLink.propTypes = {
  facebookLink: string,
  instagramLink: string,
  linkedinLink: string,
  saveChange: func.isRequired,
};

SocialLink.defaultProps = {
  facebookLink: '',
  instagramLink: '',
  linkedinLink: '',
};

export default SocialLink;
