import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectActiveCompanyName, selectCountryIso } from 'selectors/auth';
import {
  getItemById,
  getUserFeedContent,
  getRatesByCurrency,
  showRatingModal,
} from 'components/Feed/modules/actions';

import {
  selectActiveItem,
  selectUserFeedContent,
  selectCurrencyRates,
} from 'components/Feed/modules/selectors';
import { showPictureExpandModal } from 'components/PictureExpand/modules/actions';
import { getCompanyDetailsById } from 'actions/auth';
import { setChatRoute, setCompanyChat } from '../Chat/modules/actions';

const mapStateToProps = (state) => ({
  companyName: selectActiveCompanyName(state),
  itemDetails: selectActiveItem(state),
  userFeedContent: selectUserFeedContent(state),
  countryIso: selectCountryIso(state),
  currencyRates: selectCurrencyRates(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getItemById,
      getUserFeedContent,
      showPictureExpandModal,
      getRatesByCurrency,
      setChatRoute,
      getCompanyDetailsById,
      showRatingModal,
      setCompanyChat,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
