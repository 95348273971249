export const types = {
  GET_HOMEPAGE_CONTENT: 'GET_HOMEPAGE_CONTENT',
  SET_HOMEPAGE_CONTENT: 'SET_HOMEPAGE_CONTENT',
  GET_FEATURED_PRODUCTS: 'GET_FEATURED_PRODUCTS',
  SET_FEATURED_PRODUCTS: 'SET_FEATURED_PRODUCTS',
};
/**
 * @function getHomepageContent
 * sends a request for homepage content based on content type
 * @param {{ type: string }} payload
 * @return {FSA}
 */
export const getHomepageContent = (payload) => ({
  type: types.GET_HOMEPAGE_CONTENT,
  payload,
});

/**
 * @function setHomepageContent
 * sets homepage content state
 * @param {{ content: array<object>, type: string }} payload
 * @return {FSA}
 */
export const setHomepageContent = (payload) => ({
  type: types.SET_HOMEPAGE_CONTENT,
  payload,
});

/**
 * @function getFeaturedProducts
 * sends a request for featured products
 * @return {FSA}
 */
export const getFeaturedProducts = () => ({
  type: types.GET_FEATURED_PRODUCTS,
  payload: {},
});

/**
 * @function setFeaturedProducts
 * sets featured products received from service response
 * @param {{ products: array }} payload
 * @return {FSA}
 */
export const setFeaturedProducts = (payload) => ({
  type: types.SET_FEATURED_PRODUCTS,
  payload,
});
