import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getInterests } from 'components/Interests/modules/actions';
import { selectSearchedInterests } from 'components/Interests/modules/selectors';
import { selectIndustriesList } from '../modules/selectors';

const mapStateToProps = (state) => ({
  searchedResultsInterests: selectSearchedInterests(state),
  industries: selectIndustriesList(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getInterests,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
