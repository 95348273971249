import React, {
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  Button, DatePicker, Input, Space, Table,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import times from 'lodash/times';
import styles from '../adminPanel.module.scss';
import SelectSubscriptionPackage from './components/SelectSubscriptionPackage';
import { columnsConstants } from './constants';
import DeleteConfirmation from './components/DeleteConfirmation';
import BlockCompanyConfirmation from './components/BlockCompanyConfirmation';
import EditableCell from './components/EditableCell';
import EditableRow from './components/EditableRow';
import SelectIndustry from './components/SelectIndustry';
import SelectPaidStatus from './components/SelectPaidStatus';
import CompanyStatusCheckbox from './components/CompanyStatusCheckbox'
import moment from 'moment';

const CompaniesTable = (props) => {
  const {
    tableContent,
    industriesList,
    actions,
    countriesList,
    currentPage,
    updatePage,
    disableNext,
    loading,
    handleFilterData,
    handleFiltersReset,
    availablePages,
  } = props;

  const [dataSource, setDataSource] = useState([]);
  const [searchState, updateSearchState] = useState({
    searchedColumn: '',
    searchText: '',
  });

  const handleCompanyInfoChange = (companyId, formData, columnName) => {
    actions.requestUpdateCompanyById(companyId, formData, columnName);
  };

  const handleSubscriptionOrIndustryChange = (companyId, dataId, data, columnName) => {
    actions.changeSubscriptionOrIndustry(companyId, dataId, data, columnName);
  };

  const disabledDate = (current) => current && current < moment().endOf('day');

  useEffect(() => {
    setDataSource([]);
    updateSearchState({ searchedColumn: '', searchText: '' });
    if (tableContent.size > 0) {
      tableContent.map((item, index) => setDataSource((prevState) => prevState.concat({
        key: index,
        rawData: item,
        name: item.get('name', ''),
        email: item.get('email', ''),
        country: countriesList
          .filter(
            (country) => country.get('ISO', '') === item.get('countryIso', ''),
          )
          .first()?.get('name', item.get('countryIso', '')),
        phone: item.get('phone', ''),
        shop: item.get('shopLink', '') ? item.get('shopLink', '') : '/',
        subscription: (
          <SelectSubscriptionPackage
            handleOnSelect={handleSubscriptionOrIndustryChange}
            companyId={item.get('id', '')}
            data={item}
            columnName="subscription"
          />
        ),
        verification: (
          <CompanyStatusCheckbox
            handleStatusChange={actions.updateCompanyStatusById}
            formData={item}
            property="isVerified"
            columnName="isVerified"
          />
        ),
        industry: (
          <SelectIndustry
            industries={industriesList}
            data={item}
            companyId={item.get('id', null)}
            columnName="industry"
            handleIndustryChange={handleSubscriptionOrIndustryChange}
            currentIndustry={item.getIn(['industry', 'name'], '')}
          />
        ),
        delete: (
          <DeleteConfirmation
            handleConfirmation={actions.deleteCompanyById}
            companyId={item.get('id', '')}
          />
        ),
        block: (
          <BlockCompanyConfirmation
            handleConfirmation={actions.updateCompanyStatusById}
            formData={item}
          />
        ),
        paidStatus: (
          <SelectPaidStatus
            handleOnSelect={handleSubscriptionOrIndustryChange}
            companyId={item.get('id', '')}
            data={item}
            columnName="paidStatus"
          />
        ),
        subscriptionEndDate: (
          <DatePicker
            value={item.get('subscriptionEndDate') && moment(item.get('subscriptionEndDate'))}
            format="DD/MM/YYYY"
            onChange={(e) => handleSubscriptionOrIndustryChange(item.get('id'), e, item, 'subscriptionEndDate')}
            disabledDate={disabledDate}
            placeholder="Select subscription end date"
          />
        ),
      })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableContent]);

  const handleSearch = (selectedKeys, confirm, dataIndex, value) => {
    confirm();
    handleFilterData(dataIndex, value);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    handleFiltersReset();
  };

  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className="p-4">
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={(e) => handleSearch(selectedKeys, confirm, dataIndex, e.target.value)}
          style={{ width: 188, marginBottom: 15, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(
              selectedKeys,
              confirm,
              dataIndex,
              searchInput?.state?.value || '',
            )}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    render: (text) => (searchState.searchedColumn === dataIndex ? searchState.searchText : text),
  });

  const columnDefs = columnsConstants(getColumnSearchProps).map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: (row) => handleCompanyInfoChange(
          row.rawData.get('id', null),
          row,
          col.dataIndex,
        ),
      }),
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const [rowId, setRowId] = useState(null);

  const onClickRow = (record) => ({
    onClick: () => {
      setRowId(record.key);
    },
  });

  const setActiveRowStyle = (record) => (record.key === rowId ? styles.activeRowStyle : '');

  return (
    <div>
      <Table
        components={components}
        dataSource={dataSource}
        columns={columnDefs}
        pagination={false}
        scroll={{ x: 'max-content' }}
        className={styles.tableContainer}
        loading={loading}
        onRow={onClickRow}
        rowClassName={setActiveRowStyle}
      />
      <div className={classNames(styles.paginationContainer, 'mx-auto')}>
        {!loading && (
          <h5
            role="presentation"
            onClick={() => updatePage(currentPage - 1)}
            className={classNames(currentPage === 1 && 'invisible', 'mr-3')}
          >
            Previous
          </h5>
        )}
        <div className={styles.paginationNumbersRow}>
          {times(availablePages, (page) => (
            <div
              className={classNames(
                styles.page,
                page + 1 === currentPage && styles.activePage,
              )}
              key={page}
              role="presentation"
              onClick={() => updatePage(page + 1)}
            >
              <p>{page + 1}</p>
            </div>
          ))}
        </div>
        {!loading && (
          <h5
            role="presentation"
            onClick={() => updatePage(currentPage + 1)}
            className={classNames(disableNext && 'invisible', 'ml-3')}
          >
            Next
          </h5>
        )}
      </div>
    </div>
  );
};
const {
  func, shape, number, bool,
} = PropTypes;
const { list } = ImmutablePropTypes;

CompaniesTable.propTypes = {
  tableContent: list.isRequired,
  industriesList: list.isRequired,
  actions: shape({
    requestUpdateCompanyById: func.isRequired,
    deleteCompanyById: func.isRequired,
    changeSubscriptionOrIndustry: func.isRequired,
    updateCompanyStatusById: func.isRequired,
  }).isRequired,
  countriesList: list.isRequired,
  currentPage: number.isRequired,
  updatePage: func.isRequired,
  disableNext: bool.isRequired,
  loading: bool.isRequired,
  handleFilterData: func.isRequired,
  handleFiltersReset: func.isRequired,
  availablePages: number.isRequired,
};

export default CompaniesTable;
