import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectActiveCompanyColorTheme, selectActiveCompanyName } from 'selectors/auth';
import { showModal, hideModal } from './modules/actions';
import { selectIcons } from './modules/selectors';

const mapStateToProps = (state) => ({
  icons: selectIcons(state),
  colorTheme: selectActiveCompanyColorTheme(state),
  companyName: selectActiveCompanyName(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showModal,
      hideModal,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
