import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { layoutPositions } from 'utils/parse-helper';
import { fromJS, Map as iMap, List as iList } from 'immutable';
import { notification } from 'antd';
import { createSelector } from 'reselect';
import EditBar from '../modules/utils/EditBar';
import ClientItem from './ClientItem';
import styles from './ourClients.module.scss';

const OurClients = (props) => {
  const {
    clients,
    saveChange,
    colorTheme,
    fontFamily,
    actions,
    uploadedImageObject,
    setSectionCompleted,
  } = props;
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState('edit');
  const [clientsData, setClientsData] = useState(clients);
  const [changesSaved, setChangesSavedStatus] = useState(false);
  const [titleRendered, setTitleRendered] = useState(false);
  const [unsavedCards, setUnsavedCards] = useState([]);
  const isUnfinished = (client) => (
    (client.get('name')
    || client.get('comment')
    || client.get('imageUrl')
    || client.get('position'))
    && (!client.get('name')
    || !client.get('comment')
    || !client.get('imageUrl')
    || !client.get('position'))
  );
  const checkUnsavedCard = (itemKey, newClients) => {
    const client = newClients?.get('clients').filter((item) => item.get('key') === itemKey).first();
    if (client) {
      if (client.get('name')) setTitleRendered(true);
      if (isUnfinished(client)) {
        setUnsavedCards([...unsavedCards.filter((unsavedCard) => unsavedCard !== client.get('key')), client.get('key')]);
      } else {
        setUnsavedCards([...unsavedCards.filter((unsavedCard) => unsavedCard !== client.get('key'))]);
      }
    }
  };
  const sectionDidChange = (change) => {
    actions.setChangesMade(change);
    setChangesSavedStatus(!change);
  };
  const changeClientsData = (item) => {
    setClientsData(
      clientsData.setIn(['clients', item.key], fromJS({ ...item })),
    );
    checkUnsavedCard(item.key, clientsData.setIn(['clients', item.key], fromJS({ ...item })));
    setChangesSavedStatus(false);
  };
  useEffect(() => {
    setClientsData(clients);
    const hasClient = !!clients.get('clients', []).filter((client) => client.get('name') !== '').first();
    setSectionCompleted('ourClients', hasClient);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);
  const saveChanges = () => {
    if (unsavedCards.length > 0) {
      notification.error({
        message: t('notification error'),
        description: t('createCompany cardDataUnsaved'),
      });
    }
    saveChange('ourClients', clientsData.updateIn(
      ['title'],
      (oldVal) => {
        if (!oldVal && titleRendered) return 'Šta drugi kažu o nama';
        return oldVal;
      },
    ));
    sectionDidChange(false);
  };
  const setTextAlignment = (alignment) => {
    setClientsData(
      clientsData.merge({ ...clientsData, textAlignment: alignment }),
    );
    sectionDidChange(true);
  };
  const filterClientsDataSelector = createSelector(
    () => clientsData.get('clients', iList()),
    (items) => items.filter(
      (item) => item.get('comment', '')
          && item.get('name', '')
          && item.get('position', ''),
    ),
  );
  const filteredSizeClientsDataSelector = createSelector(
    filterClientsDataSelector,
    (items) => items.size,
  );
  return (
    <div className={styles.ourClientsContainer}>
      <div className="container">
        <h4 className={clientsData.get('textAlignment', '')}>
          {t('createCompany ourClients sectionTitle')}
        </h4>
        {viewMode === 'edit' ? (
          <input
            type="text"
            placeholder={t(
              'createCompany ourClients inputSectionTitle placeholder',
            )}
            name="sectionTitle"
            className={classNames(
              styles.inputSectionTitle,
              clientsData.get('textAlignment', ''),
              viewMode === 'view' ? styles.noBorderStyle : '',
            )}
            value={clientsData.get('title', '')}
            onChange={(e) => {
              setClientsData(clientsData.set('title', e.target.value));
              sectionDidChange(true);
            }}
            style={{ color: colorTheme, fontFamily }}
            maxLength={200}
          />
        ) : (
          <h2
            className={classNames(
              styles.inputSectionTitle,
              clientsData.get('textAlignment', ''),
              styles.noBorderStyle,
            )}
            style={{ color: colorTheme, fontFamily }}
          >
            {clientsData.get('title', '')}
          </h2>
        )}
        {unsavedCards.length > 0 && viewMode === 'edit' && (
          <div className={styles.cardUnsavedAlert}>
            {t('createCompany cardDataUnsaved')}
          </div>
        )}
        <div className={styles.clientsContainer}>
          {clientsData
            && (viewMode === 'edit'
              ? clientsData.get('clients', iList())
              : filterClientsDataSelector(clientsData.get('clients', iList()))
            ).map((item) => (
              <div
                className={classNames(
                  'col-12',
                  layoutPositions(
                    viewMode === 'edit'
                      ? clientsData.get('clients', iList()).size
                      : filteredSizeClientsDataSelector(
                        clientsData.get('clients', iList()),
                      ),
                  ),
                )}
                key={item.get('key', '')}
              >
                <ClientItem
                  textAlignment={clientsData.get('textAlignment', '')}
                  viewMode={viewMode}
                  comment={item.get('comment', '')}
                  name={item.get('name', '')}
                  position={item.get('position', '')}
                  itemKey={item.get('key', '')}
                  setClientsData={changeClientsData}
                  imageUrl={item.get('imageUrl', '')}
                  handleChange={actions.uploadTestimonialUserImage}
                  uploadedImage={
                    item.get('key') === uploadedImageObject.get('key')
                      ? uploadedImageObject.get('imageUrl')
                      : ''
                  }
                  unsavedCard={unsavedCards?.includes(item.get('key', ''))}
                  actions={actions}
                />
              </div>
            ))}
        </div>
        <div className={classNames('container', styles.customEditBarStyle)}>
          <EditBar
            textAlignment={clientsData.get('textAlignment', '')}
            viewMode={viewMode}
            setTextAlignment={setTextAlignment}
            setViewMode={setViewMode}
            saveChanges={saveChanges}
            changesSaved={changesSaved}
          />
        </div>
      </div>
    </div>
  );
};

const {
  node, func, string, shape,
} = PropTypes;

OurClients.propTypes = {
  clients: node,
  saveChange: func.isRequired,
  colorTheme: string.isRequired,
  fontFamily: string.isRequired,
  actions: shape({
    uploadTestimonialUserImage: func.isRequired,
    setChangesMade: func.isRequired,
  }).isRequired,
  uploadedImageObject: ImmutablePropTypes.map.isRequired,
  setSectionCompleted: func.isRequired,
};

OurClients.defaultProps = {
  clients: iMap(),
};

export default OurClients;
