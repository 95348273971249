import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useParams } from 'react-router';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { rubbish } from 'images/createCompany';
import i18n from 'i18next';
import { RightOutlined, CloseOutlined } from '@ant-design/icons';
import {
  handleCurrencySelect,
} from 'utils/constants';
import {
  createDemandUrl,
  composeEditCompanyUrl,
  composeProductsUrl,
  createProductUrl,
} from 'utils/pathsHelper';
import { List as IList, fromJS } from 'immutable';
import {
  Popconfirm, AutoComplete, notification, DatePicker, Checkbox,
} from 'antd';
import useForm from 'utils/hooks/useForm';
import inputStyle from 'utils/inputStyle.module.scss';
import buttonStyles from 'utils/buttonStyle.module.scss';

import moment from 'moment';
import UploadImage from './UploadImageComponent/UploadImageComponent';
import UploadedImage from './UploadedImage/UploadedImage';
import validate from './utils/validateInputForm';
import InputField from './utils/InputField';
import styles from './createProduct.module.scss';
import Comments from '../Comments';

const CreateProduct = (props) => {
  const {
    companyName,
    actions,
    newProductImage,
    suggestedTags,
    countryIso,
    location: { pathname },
    itemDetails,
    adminRole,
    activeCompanySlug,
    activeCompanyId,
  } = props;
  const { t } = useTranslation();
  const params = useParams();
  const { type, id } = params;
  const [tagsOptions, setTagsOptions] = useState(
    fromJS([]),
  );
  const isEdit = pathname.split('/')[1] === 'edit';
  const showIsFeatured = adminRole && isEdit;

  const [formData, setFormData] = useState({
    companySlug: activeCompanySlug,
    images: IList(),
    selectedTags: IList(),
    currency: 'EUR',
    deletedImages: IList(),
    deletedTags: IList(),
  });

  useEffect(() => {
    actions.getSuggestedTags();
    actions.clearUploadedImage();
    if (isEdit) {
      actions.getItemById(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveProduct = () => {
    if (formData.images.size > 0 && formData.images.first().get('url', '')) {
      if (isEdit) {
        actions.setProductDemandType(type);
        actions.updateProduct(type, { ...formData, ...values }, id);
      } else {
        actions.setProductDemandType(type);
        actions.createProduct(
          pathname.includes(createProductUrl) ? 'product' : 'demand',
          (adminRole && localStorage.getItem('userSlug') !== id)
            ? {
              ...formData,
              ...values,
              companyId: activeCompanyId,
            }
            : { ...formData, ...values },
        );
      }
    } else {
      notification.error({
        message: i18n.t('notification error'),
        description: 'Upload at least one image',
      });
    }
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleDateSelect,
    handleOnInputBlur,
    handleOnBlurDateSelectValidation,
    setValues,
  } = useForm(saveProduct, validate);
  useEffect(() => {
    if (newProductImage.size > 0) {
      if (newProductImage.get('id') === '0') {
        if (itemDetails.get('images', '').size > 0) {
          setFormData({
            ...formData,
            deletedImages: formData.deletedImages.push(
              itemDetails.get('images', '').first(),
            ),
            images: formData.images.set(0, newProductImage),
          });
        } else {
          setFormData({
            ...formData,
            images: formData.images.set(0, newProductImage),
          });
        }
      } else {
        setFormData({
          ...formData,
          images: formData.images.push(
            fromJS({
              id: newProductImage.get('id', ''),
              publicId: newProductImage.get('publicId', ''),
              url: newProductImage.get('url', ''),
            }),
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newProductImage]);

  const deleteUploadedImage = (imageKey) => {
    setFormData({
      ...formData,
      images: formData.images.filter((image) => (typeof image.get('id', '') === 'number'
        ? image.get('id', '') !== parseInt(imageKey, 10)
        : image.get('id', '') !== imageKey)),
      deletedImages: formData.deletedImages.concat(
        itemDetails
          .get('images', '')
          .filter((image) => image.get('id', '') === parseInt(imageKey, 10)),
      ),
    });
  };

  const filterSuggestedTags = suggestedTags.filter((tag) => !tagsOptions.includes(tag));

  useEffect(() => {
    if (suggestedTags.size > 0) {
      setTagsOptions(
        tagsOptions.concat(filterSuggestedTags),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedTags]);

  const onSelect = (e) => {
    const existingTag = formData.selectedTags.some((tag) => tag.get('name') === e);
    if (!existingTag) {
      setFormData({
        ...formData,
        selectedTags: formData.selectedTags.push(fromJS({ name: e })),
      });
    }
  };

  const onSearch = (searchTerm) => {
    if (searchTerm.length > 3) {
      actions.getSuggestedTags({ name: searchTerm });
      if (suggestedTags.some((tag) => tag.get('value') === searchTerm)) {
        setTagsOptions(tagsOptions.filter((tag) => !tag.get('value') === searchTerm && !tag.get('key') === 'first'));
      } else {
        setTagsOptions(
          tagsOptions.set(0, fromJS({ value: searchTerm, key: 'first' })).filter((tag) => tag.includes(searchTerm)),
        );
      }
    } else if (searchTerm.length === 0) {
      actions.getSuggestedTags();
    }
  };

  const deleteCreatedTag = (tagName) => {
    setFormData({
      ...formData,
      selectedTags: formData.selectedTags.filter(
        (tag) => tag.get('name') !== tagName,
      ),
      deletedTags: formData.deletedTags.concat(
        formData.selectedTags.filter((tag) => tag.get('name') === tagName),
      ),
    });
  };

  useEffect(() => {
    if (countryIso) {
      setFormData({ ...formData, currency: handleCurrencySelect(countryIso) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryIso]);

  useEffect(() => {
    if (activeCompanySlug) {
      setFormData({ ...formData, companySlug: activeCompanySlug });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompanySlug]);

  useEffect(() => {
    if (itemDetails.size > 0) {
      setFormData({
        ...formData,
        images: itemDetails.get('images', IList()),
        selectedTags: itemDetails.get('tags', ''),
      });
      setValues({
        title: itemDetails.get('name', ''),
        price: itemDetails.get('price', ''),
        description: itemDetails.get('description', ''),
        expiryDate: itemDetails.get('expireAt', ''),
        isFeatured: itemDetails.get('isFeatured', false),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemDetails]);

  const addEnteredTag = () => {
    if (
      tagsOptions.size > 0
      && tagsOptions.first().get('key', '') === 'first'
      && !formData.selectedTags.some(
        (tag) => tag.get('name', '') === tagsOptions.first().get('value', ''),
      )
    ) {
      setFormData({
        ...formData,
        selectedTags: formData.selectedTags.push(
          fromJS({ name: tagsOptions.first().get('value', '') }),
        ),
      });
    }
  };

  const disabledDate = (current) => current && current < moment().endOf('day');

  useEffect(
    () => () => {
      setValues({
        title:'',
        price:'',
        description:'',
        expiryDate:'',
        isFeatured: false,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (itemDetails.size > 0) {
      actions.getCompanyDetailsById(itemDetails.get('companyId', ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, itemDetails]);

  return (
    <div className={classNames('container', styles.createProductsContainer)}>
      <div className={classNames(styles.navigationInfo)}>
        <Link to={composeEditCompanyUrl(activeCompanySlug)}>
          <h5>{companyName}</h5>
        </Link>
        {companyName && <RightOutlined className="px-3 pt-1" />}
        <h6>{t('createProduct/Service navigationInfo title')}</h6>
      </div>

      <div className={styles.mobileNavigationInfo}>
        <h6 className={styles.mobileTitle}>
          {t('createProduct/Service navigationInfo title')}
        </h6>
        <Link to={composeProductsUrl(localStorage.getItem('userSlug'))}>
          <CloseOutlined className={styles.iconStyle} />
        </Link>
      </div>
      <div className={styles.createProductContent}>
        <div className={classNames('col-12 col-md-7')}>
          <UploadImage
            imageKey="0"
            imageUrl={
              formData.images.size > 0
                ? formData.images.first().get('url', '')
                : ''
            }
            uploadNewImage={actions.uploadNewProductOrDemandImage}
          />
          <div className={styles.secondaryImagesUploadContainer}>
            <UploadImage
              uploadNewImage={actions.uploadNewProductOrDemandImage}
              images={formData.images}
              rowStyleImageUpload
            />
            {formData.images.size > 1
              && formData.images.map(
                (image, index) => index !== 0 && (
                <UploadedImage
                  key={image.get('id', '')}
                  imageKey={`${image.get('id', '')}`}
                  imageUrl={image.get('url', '')}
                  deleteUploadedImage={deleteUploadedImage}
                />
                ),
              )}
          </div>
        </div>
        <div className={classNames('col-12 col-md-5', styles.formContainer)}>
          <form onSubmit={handleSubmit}>
            <InputField
              name="title"
              placeholder={t('createProduct/Service title placeholder')}
              value={values.title || ''}
              label={t('createProduct/Service title label')}
              rows={3}
              error={errors.title}
              handleChange={handleChange}
              maxLength={140}
              onBlur={handleOnInputBlur}
            />
            <div className={styles.priceContainer}>
              <div
                className={classNames(
                  inputStyle.formGroup,
                  styles.priceInputContainer,
                  'col-md-6 my-3',
                  errors.price ? inputStyle.validationError : '',
                )}
              >
                <h6 className={inputStyle.error}>{errors.price}</h6>
                <h5 className="px-1">
                  {t('createProduct/Service price label')}
                </h5>
                <input
                  type="number"
                  min={0}
                  className={classNames(inputStyle.formControl, 'py-2 my-1')}
                  placeholder={t('createProduct/Service price placeholder')}
                  name="price"
                  value={values.price || ''}
                  onChange={handleChange}
                  onBlur={handleOnInputBlur}
                />
              </div>
              <div
                className={classNames(
                  inputStyle.formGroup,
                  styles.currencyContianer,
                  'col-md-6 my-3',
                )}
              >
                <div>
                  <h5 className={classNames(styles.textAreaLabel, 'px-1')}>
                    {t('createProduct/Service currency label')}
                  </h5>
                </div>
                <h4 className={styles.currencyStyle}>{formData.currency}</h4>
              </div>
            </div>
            <h5 className="mt-0">{t('createProduct/Service price title')}</h5>
            <h4 className="mt-5">
              {t('createProduct/Service description title')}
            </h4>
            <InputField
              name="description"
              placeholder={t('createProduct/Service decription placeholder')}
              value={values.description || ''}
              label={t('createProduct/Service description label')}
              rows={15}
              error={errors.description}
              handleChange={handleChange}
              onBlur={handleOnInputBlur}
            />
            <div
              className={classNames(
                inputStyle.formGroup,
                'col-12 my-4',
                errors.expiryDate ? inputStyle.validationError : '',
                styles.datePickerStyle,
              )}
            >
              <h6 className={inputStyle.error}>{errors.expiryDate}</h6>
              <h5 className="px-1">
                {t('createProduct/Service expirationDate label')}
              </h5>
              <DatePicker
                value={values.expiryDate && moment(new Date(values.expiryDate))}
                format="DD/MM/YYYY"
                onChange={handleDateSelect}
                disabledDate={disabledDate}
                onBlur={handleOnBlurDateSelectValidation}
                placeholder={t('createProduct/Service expirationDate placeholder')}
              />
            </div>
            {showIsFeatured && (
              <div className={styles.featuredContainer}>
                <h4>{t(`editProduct/Service feature ${type}`)}</h4>
                <Checkbox
                  name="isFeatured"
                  value={!values.isFeatured}
                  checked={values.isFeatured}
                  onChange={handleChange}
                />
              </div>
            )}
          </form>
        </div>
      </div>
      {pathname !== createDemandUrl && (
        <div className={styles.tagContainer}>
          <h3>{t('createProduct/Service tagsSearch title')}</h3>
          <div
            className={classNames(inputStyle.formGroup, 'col-12 col-md-5 my-4')}
          >
            <h5 className="px-1 ml-1">
              {t('createProduct/Service tagsInputSearch label')}
            </h5>
            <AutoComplete
              options={tagsOptions.toJS()}
              onSelect={onSelect}
              onSearch={onSearch}
              placeholder={t(
                'createProduct/Service tagsInputSearch placeholder',
              )}
              className="my-2"
            />
            <button
              type="button"
              className={styles.addTagButtonStyle}
              onClick={addEnteredTag}
            >
              {t('tagAutoComplete buttonText')}
            </button>
          </div>
          <div className={styles.tagsContainer}>
            {formData.selectedTags.size > 0
              && formData.selectedTags.map((tag) => (
                <div key={tag.get('name')} className={styles.tagWrapper}>
                  <p>{tag.get('name')}</p>
                  <CloseOutlined
                    onClick={() => deleteCreatedTag(tag.get('name'))}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
      <Comments modifyingClass={styles.modifyComments} />
      <div className={styles.mainButtonContainer}>
        <Popconfirm
          title={t('popConfirm deleteProduct')}
          onConfirm={() => actions.deleteProduct(itemDetails.get('id', ''))}
          okText={t('popConfirm confirm')}
          cancelText={t('popConfirm cancel')}
        >
          <button
            type="button"
            className={classNames(
              styles.rubbishIcon,
              itemDetails.size === 0 && 'invisible',
            )}
          >
            <img src={rubbish} alt="delete" />
          </button>
        </Popconfirm>
        <div className={classNames(styles.buttonContainer, 'my-2')}>
          <Link
            to={composeProductsUrl(localStorage.getItem('userSlug'))}
            className={classNames(
              buttonStyles.mainButtonStyle,
              styles.buttonSuccessStyle,
              'my-1 my-md-5 mr-2',
              styles.cancelButtonStyle,
            )}
          >
            {t('register button cancel')}
          </Link>
          <button
            type="submit"
            className={classNames(
              buttonStyles.buttonSuccessStyle,
              styles.buttonSuccessStyle,
              'px-3 px-md-4',
            )}
            onClick={handleSubmit}
          >
            {pathname.split('/')[1] === 'edit'
              ? t('editProduct/Service button saveChanges')
              : t('createProduct/Service button save')}
          </button>
        </div>
      </div>
    </div>
  );
};

const {
  string, shape, func, bool, number,
} = PropTypes;
const { list, map } = ImmutablePropTypes;

CreateProduct.propTypes = {
  companyName: string.isRequired,
  actions: shape({
    uploadNewProductOrDemandImage: func.isRequired,
    getSuggestedTags: func.isRequired,
    createProduct: func.isRequired,
    clearUploadedImage: func.isRequired,
    getItemById: func.isRequired,
    updateProduct: func.isRequired,
    deleteProduct: func.isRequired,
    getCompanyDetailsById: func.isRequired,
    setProductDemandType: func.isRequired,
  }).isRequired,
  newProductImage: map.isRequired,
  suggestedTags: list.isRequired,
  countryIso: string.isRequired,
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
  itemDetails: map.isRequired,
  adminRole: bool.isRequired,
  activeCompanySlug: string.isRequired,
  activeCompanyId: number,
};

CreateProduct.defaultProps = {
  activeCompanyId: null,
};

export default CreateProduct;
