import React from 'react';
import { string, number } from 'prop-types';

const PdfIcon = ({ fontSize, width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 27 33"
    style={{ fontSize: `${fontSize}px` }}
  >
    <g fill={color} fillRule="evenodd">
      <path d="M6.717 21.193c0-.547-.38-.873-1.052-.873-.274 0-.46.027-.557.053v1.755c.115.026.256.035.451.035.716 0 1.158-.361 1.158-.97zM10.876 20.338c-.3 0-.495.026-.61.053v3.888c.115.026.3.026.469.026 1.22.009 2.016-.66 2.016-2.08.009-1.234-.717-1.887-1.875-1.887z" />
      <path
        d="M18.602 0H5.332a3.516 3.516 0 00-3.516 3.506v12.965h-.344c-.782 0-1.417.632-1.417 1.412v8.568c0 .781.635 1.413 1.417 1.413h.344v1.571a3.515 3.515 0 003.516 3.506H23.44a3.515 3.515 0 003.515-3.506V8.298L18.602 0zM3.772 19.43c.416-.07 1-.123 1.822-.123.831 0 1.424.158 1.822.476.38.3.636.793.636 1.375 0 .581-.194 1.076-.548 1.41-.46.432-1.14.626-1.936.626a3.5 3.5 0 01-.46-.026v2.125H3.773V19.43zM23.44 30.794H5.332c-.75 0-1.362-.61-1.362-1.359v-1.57h16.88c.782 0 1.417-.633 1.417-1.414v-8.568c0-.78-.635-1.412-1.418-1.412H3.97V3.506c0-.747.612-1.357 1.362-1.357l12.465-.013V6.73a2.436 2.436 0 002.438 2.431l4.514-.013.05 20.287c0 .75-.61 1.36-1.36 1.36zM8.913 25.266V19.43c.495-.079 1.14-.123 1.822-.123 1.131 0 1.865.202 2.44.634.62.459 1.008 1.19 1.008 2.24 0 1.137-.415 1.922-.99 2.406-.628.52-1.583.767-2.75.767a11.7 11.7 0 01-1.53-.088zm9.65-3.456v1.093h-2.14v2.39h-1.352V19.35h3.643v1.102h-2.29v1.357h2.14z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

PdfIcon.propTypes = {
  fontSize: number,
  width: string,
  height: string,
  color: string,
};

PdfIcon.defaultProps = {
  fontSize: 15,
  width: '1em',
  height: '1em',
  color: 'black',
};

export default PdfIcon;
