/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { addIcon, commentTags } from 'images/createCompany';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './ourClients.module.scss';

const ProductItem = (props) => {
  const {
    textAlignment, comment, name, position, imageUrl,
  } = props;
  const renderImg = (url, altText) => (
    <LazyLoadImage
      alt={altText}
      effect="blur"
      src={url}
      className={styles.addIconImgStyle}
      placeholderSrc={addIcon}
    />
  );

  const renderUploadComponent = (imgUrl) => {
    if (imgUrl) {
      return renderImg(imgUrl, 'Testimonial user');
    }
    return renderImg(addIcon, 'Add user');
  };

  return (
    <div>
      <div
        className={classNames(
          styles.clientItemContainer,
          textAlignment === 'text-center'
            ? 'align-items-center'
            : textAlignment === 'text-right'
              ? 'align-items-end'
              : '',
        )}
      >
        {renderUploadComponent(imageUrl)}
        <img
          src={commentTags}
          alt="clients comments"
          className={classNames(styles.commentTagImageStyle, 'my-3')}
        />
        <p
          className={classNames(
            styles.inputClientComment,
            textAlignment,
            styles.noBorderStyle,
          )}
        >
          {comment}
        </p>
        <div className={classNames(styles.horizontalBreakline)} />
        <h4 className={classNames(styles.inputClientName, textAlignment)}>
          {name}
        </h4>
        <h5 className={classNames(styles.inputClientPosition, textAlignment)}>
          {position}
        </h5>
      </div>
    </div>
  );
};

const { string } = PropTypes;

ProductItem.propTypes = {
  textAlignment: string.isRequired,
  comment: string.isRequired,
  name: string.isRequired,
  position: string.isRequired,
  imageUrl: string.isRequired,
};

export default ProductItem;
