import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import styles from '../../adminPanel.module.scss';
import { paidStatusOptions } from '../constants';
const { Option } = Select;

const SelectPaidStatus = (props) => {
    const {
        handleOnSelect,
        companyId,
        data,
        columnName,
    } = props;

    const [activeStatus, setActiveStatus] = useState('');
    const selectedStatus = paidStatusOptions.find(option => option.name === activeStatus);
    
    useEffect(() => {
        setActiveStatus(data.get('paidStatus', ''));
    }, [data]);

    const handleSelectPaidStatus = (cId, val, formData, cName) => {
        setActiveStatus(val);
        handleOnSelect(cId, val, formData, cName);
    };

    return (
        <Select
            value={selectedStatus?.name}
            bordered={false}
            className={styles.selectStyle}
            onSelect={(e) => handleSelectPaidStatus(companyId, e, data, columnName)}
        >
            {paidStatusOptions.map(option => (
                <Option value={option.name} key={option.value}>
                    {option.name}
                </Option>
            ))}
        </Select>
    );
};
const { func, string, number } = PropTypes;

const { mapContains } = ImmutablePropTypes;

SelectPaidStatus.propTypes = {
    handleOnSelect: func.isRequired,
    companyId: number.isRequired,
    columnName: string.isRequired,
    data: mapContains({
        paidStatus: string.isRequired,
    }).isRequired,
};

export default SelectPaidStatus;