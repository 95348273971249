import React from 'react';
import PropTypes from 'prop-types';
import styles from './colorPaletteItem.module.scss';

const ColorPaletteItem = (props) => {
  const {
    width, height, background, border, mainBorder, handleClick,
  } = props;

  return (
    <div>
      <div
        className={styles.colorPaletteWrapper}
        style={{ width, height, border: mainBorder }}
        onClick={() => handleClick(background)}
        onKeyPress={() => handleClick(background)}
      >
        <div
          className={styles.colorPaletteColorMain}
          style={{ background, borderRight: border }}
        />
        <div className={styles.colorPaletteColorsWrapper}>
          <div
            className={styles.colorPaletteColorSecondaryFirst}
            style={{ background, borderBottom: border }}
          />
          <div
            className={styles.colorPaletteColorSecondarySecond}
            style={{ background, borderBottom: border }}
          />
          <div
            className={styles.colorPaletteColorSecondaryThird}
            style={{ background }}
          />
        </div>
      </div>
    </div>
  );
};

const { string, func } = PropTypes;

ColorPaletteItem.propTypes = {
  width: string.isRequired,
  height: string.isRequired,
  background: string.isRequired,
  handleClick: func,
  border: string,
  mainBorder: string,
};
ColorPaletteItem.defaultProps = {
  border: '',
  mainBorder: '',
  handleClick: () => {},
};

export default ColorPaletteItem;
