import { parseError } from 'utils/parse-helper';
import { AuthorizedApiService } from './apiService';

export const getNotificationsCountService = async () => AuthorizedApiService.get('notification-new-count')
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const getNotificationsService = async (queryData) => AuthorizedApiService.get('notification', { params: queryData })
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const setNotificationReadService = async (postData) => AuthorizedApiService.put('notification', postData)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const setNotificationSeenStatusService = async () => AuthorizedApiService.put('notification-read-time')
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));
