import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'antd/dist/antd.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './i18n';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
