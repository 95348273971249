import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  centerAlignment,
  editMode,
  rightAlignment,
  viewMode as viewModeImg,
  leftAlignment,
} from 'images/createCompany';
import { CheckOutlined } from '@ant-design/icons';
import buttonStyles from 'utils/buttonStyle.module.scss';
import styles from './editBar.module.scss';

const EditBar = (props) => {
  const {
    viewMode,
    textAlignment,
    setTextAlignment,
    setViewMode,
    saveChanges,
    changesSaved,
  } = props;
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.editBarContainer, 'px-md-5')}>
      {changesSaved ? (
        <div className={styles.checkOutlinedContainer}>
          <CheckOutlined className={classNames(styles.iconStyle)} />
        </div>
      ) : (
        <button
          type="submit"
          className={classNames(
            buttonStyles.buttonSuccessStyle,
            styles.buttonSuccessStyle,
          )}
          onClick={(e) => saveChanges(e)}
        >
          {t('createCompany companyHeader saveChangesButtonText')}
        </button>
      )}
      <div
        className={classNames(
          'd-flex flex-row',
          viewMode === 'view' ? 'invisible' : '',
          styles.alignmentContainer,
        )}
      >
        <div
          className={classNames(
            styles.imageContainer,
            textAlignment === 'text-left' ? styles.activeMode : '',
          )}
          onClick={() => setTextAlignment('text-left')}
          onKeyDown={() => setTextAlignment('text-left')}
        >
          <img src={leftAlignment} alt="align text" />
        </div>
        <div
          className={classNames(
            styles.imageContainer,
            textAlignment === 'text-center' ? styles.activeMode : '',
          )}
          onClick={() => setTextAlignment('text-center')}
          onKeyDown={() => setTextAlignment('text-center')}
        >
          <img src={centerAlignment} alt="align text" />
        </div>
        <div
          className={classNames(
            styles.imageContainer,
            textAlignment === 'text-right' ? styles.activeMode : '',
          )}
          onClick={() => setTextAlignment('text-right')}
          onKeyDown={() => setTextAlignment('text-right')}
        >
          <img src={rightAlignment} alt="align text" />
        </div>
      </div>
      <div className={styles.modeSelectButtonsContainer}>
        <div
          className={classNames(
            styles.imageContainer,
            viewMode === 'view' ? styles.activeMode : '',
          )}
          onClick={() => setViewMode('view')}
          onKeyDown={() => setViewMode('view')}
        >
          <img src={viewModeImg} alt="view mode" />
        </div>
        <div
          className={classNames(
            styles.imageContainer,
            viewMode === 'edit' ? styles.activeMode : '',
          )}
          onClick={() => setViewMode('edit')}
          onKeyDown={() => setViewMode('edit')}
        >
          <img src={editMode} alt="edit mode" />
        </div>
      </div>
    </div>
  );
};

const { string, func, bool } = PropTypes;

EditBar.propTypes = {
  viewMode: string.isRequired,
  textAlignment: string,
  setTextAlignment: func.isRequired,
  setViewMode: func.isRequired,
  saveChanges: func.isRequired,
  changesSaved: bool,
};

EditBar.defaultProps = {
  textAlignment: 'text-left',
  changesSaved: false,
};

export default EditBar;
