import React from 'react';
import { string } from 'prop-types';

const DeliveryIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 52.056 36.632"
  >
    <g
      id="prefix__Group_668"
      data-name="Group 668"
      transform="translate(-281.572 -627.643)"
    >
      <g
        id="prefix__Group_624"
        data-name="Group 624"
        transform="translate(-1807 254.661)"
      >
        <path
          id="prefix__Rectangle_41"
          d="M0 0H13.141V2.976H0z"
          className="prefix__cls-1"
          data-name="Rectangle 41"
          transform="translate(2126 388.919)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_635"
          d="M293.943 229.443h-5.156v2.976h3.78a1.676 1.676 0 0 1 1.277.59l4.806 5.65-.054 12.072h-7.738a3.718 3.718 0 0 1-.212 2.976h7.95a3.032 3.032 0 0 0 3.032-3.032v-12.822a.778.778 0 0 0-.185-.5l-6.223-7.317a1.673 1.673 0 0 0-1.277-.593z"
          className="prefix__cls-1"
          data-name="Path 635"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_636"
          d="M283.8 250.243l-14.511.031a3.764 3.764 0 0 1-.334 3.968l15.135-.033a3.773 3.773 0 0 1-.29-3.966z"
          className="prefix__cls-1"
          data-name="Path 636"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_637"
          d="M287.219 248.124a3.836 3.836 0 0 1 1.568.339v-22.812a3.669 3.669 0 0 0-3.669-3.669h-32.272a1.984 1.984 0 0 0 0 3.968h31.973v23.034a3.817 3.817 0 0 1 2.4-.86z"
          className="prefix__cls-1"
          data-name="Path 637"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_638"
          d="M262.394 250.289l-3.371.007V236.68h-3.968v13.782a3.842 3.842 0 0 0 .761 2.374 3.661 3.661 0 0 0 2.908 1.429h.008l4.016-.009a3.761 3.761 0 0 1-.354-3.967z"
          className="prefix__cls-1"
          data-name="Path 638"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_639"
          d="M287.219 257.321a5.342 5.342 0 1 1 5.343-5.342 5.348 5.348 0 0 1-5.343 5.342zm0-7.709a2.367 2.367 0 1 0 2.367 2.367 2.37 2.37 0 0 0-2.367-2.367z"
          className="prefix__cls-1"
          data-name="Path 639"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_640"
          d="M265.843 257.321a5.342 5.342 0 1 1 5.342-5.342 5.349 5.349 0 0 1-5.342 5.342zm0-7.709a2.367 2.367 0 1 0 2.367 2.367 2.37 2.37 0 0 0-2.367-2.367z"
          className="prefix__cls-1"
          data-name="Path 640"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_641"
          d="M265.82 232.466H253.7a1.749 1.749 0 0 1-1.749-1.749 1.749 1.749 0 0 1 1.749-1.749h12.12a1.749 1.749 0 0 1 1.749 1.749 1.749 1.749 0 0 1-1.749 1.749z"
          className="prefix__cls-1"
          data-name="Path 641"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_642"
          d="M260.87 237.892l-4.974-.082a1.489 1.489 0 0 1-1.464-1.513 1.488 1.488 0 0 1 1.513-1.463l4.974.082a1.489 1.489 0 0 1 1.464 1.513 1.489 1.489 0 0 1-1.513 1.463z"
          data-name="Path 642"
          transform="translate(1839 151)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_643"
          d="M293.929 231.325a1.676 1.676 0 0 0-1.277-.59H287.5v-3.791a3.669 3.669 0 0 0-3.669-3.669h-32.275a1.984 1.984 0 0 0 0 3.968h31.973v21.281a5.336 5.336 0 0 0-2.629 3.015l-11.309.025a5.307 5.307 0 0 0-10.081.022h-1.776v-12.431l1.848.03a1.488 1.488 0 1 0 .05-2.976l-4.975-.082a1.487 1.487 0 0 0-.89 2.7v12.932a3.844 3.844 0 0 0 .761 2.374 3.661 3.661 0 0 0 2.908 1.429h.008l2.3-.005a5.313 5.313 0 0 0 9.634-.021l11.717-.026a5.312 5.312 0 0 0 9.867-.506h6.347a3.031 3.031 0 0 0 3.032-3.032v-12.826a.774.774 0 0 0-.186-.5zm-2.654 2.385a1.678 1.678 0 0 1 1.278.591l4.176 4.91h-9.234v-5.5zm-26.724 21.927a2.367 2.367 0 1 1 2.367-2.367 2.369 2.369 0 0 1-2.367 2.367zm21.376 0a2.367 2.367 0 1 1 2.367-2.367 2.369 2.369 0 0 1-2.367 2.367zm5.181-3.614a5.333 5.333 0 0 0-3.613-3.833v-6h9.854l-.044 9.836z"
          data-name="Path 643"
          transform="translate(1839 151)"
          style={{ opacity: 0.2, fill: color }}
        />
        <path
          id="prefix__Path_644"
          d="M264.529 233.758a1.749 1.749 0 1 0 0-3.5H252.4a1.749 1.749 0 0 0 0 3.5z"
          data-name="Path 644"
          transform="translate(1839 151)"
          style={{ opacity: 0.95, fill: '#050713' }}
        />
      </g>
    </g>
  </svg>
);

DeliveryIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

DeliveryIcon.defaultProps = {
  color: '#37ead9',
  width: '52.056',
  height: '36.632',
};

export default DeliveryIcon;
