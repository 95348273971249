import React from 'react';
import classNames from 'classnames';
import buttonStyle from 'utils/buttonStyle.module.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { EmailIcon } from '../../../svgs';
import styles from './registerConfirmed.module.scss';

const RegisterConfirmed = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleRedirect = () => {
    history.push('/');
  };

  return (
    <div className={classNames(styles.registerConfirmedContainer, 'col-lg-12')}>
      <EmailIcon />
      <div className={styles.textRegisterConfirmedSucces}>{t('register confirmed succes')}</div>
      <div className={styles.textRegistterConfirmedEmail}>
        {t('register confirmed succes email')}
      </div>
      <div className={classNames(styles.buttonHomePage, 'col-lg-6')}>
        <button
          type="submit"
          className={classNames(
            styles.buttonSuccessStyle,
            buttonStyle.buttonSuccessStyle,
          )}
          onClick={handleRedirect}
        >
          {t('register confirmed home')}
        </button>
      </div>
    </div>
  );
};
export default RegisterConfirmed;
