import {
  all, call, takeLatest, put, select
} from 'redux-saga/effects';
import i18n from 'i18next';
import { notification } from 'antd';
import { getCommentsService, createCommentService, deleteCommentByIdService, editCommentByIdService } from 'services/commentsService';
import { types, setProductComments, getProductComments } from './actions';
import { selectQueryData } from './selectors';

function* getProductCommentsSaga(action) {
  const { queryObject } = action.payload;
  const { data, error } = yield call(getCommentsService, queryObject);
  if (!error) {
    yield put(setProductComments(data));
  }
}

function* createNewCommentSaga(action) {
  const { commentData } = action.payload;
  const { error } = yield call(createCommentService, commentData);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
      description: i18n.t('notification comment add'),
    });

    const queryData = yield select(selectQueryData)
    yield put(getProductComments(queryData.toJS()));
  }
}

function* deleteCommentByIdSaga(action) {
  const { id } = action.payload;
  const { error } = yield call(deleteCommentByIdService, id)
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
      description: i18n.t('notification comment delete'),
    });
    const queryData = yield select(selectQueryData)
    yield put(getProductComments(queryData.toJS()));
  }
}

function* editCommentByIdSaga(action) {
  const { id, content } = action.payload;
  const { error } = yield call(editCommentByIdService, id, { content })
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
      description: i18n.t('notification comment update'),
    });
    const queryData = yield select(selectQueryData)
    yield put(getProductComments(queryData.toJS()));
  }
}

export default function* commentsSaga() {
  yield all([
    takeLatest(types.GET_PRODUCT_COMMENTS, getProductCommentsSaga),
    takeLatest(types.CREATE_NEW_COMMENT, createNewCommentSaga),
    takeLatest(types.DELETE_COMMENT_BY_ID, deleteCommentByIdSaga),
    takeLatest(types.EDIT_COMMENT_BY_ID, editCommentByIdSaga)
  ]);
}