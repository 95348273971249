import i18n from 'i18next';
import {
  firstStepImage,
  secondStepImage,
  thirdStepImage,
  fourthStepImage,
} from 'images';

const steps = () => [
  {
    index: 1,
    image: firstStepImage,
    title: i18n.t('homepage steps createProfile title'),
    text: i18n.t('homepage steps createProfile text'),
  },
  {
    index: 2,
    image: secondStepImage,
    title: i18n.t('homepage steps usePersonalization title'),
    text: i18n.t('homepage steps usePersonalization text'),
  },
  {
    index: 3,
    image: thirdStepImage,
    title: i18n.t('homepage steps findPartners title'),
    text: i18n.t('homepage steps findPartners text'),
  },
  {
    index: 4,
    image: fourthStepImage,
    title: i18n.t('homepage steps communicateWithEase title'),
    text: i18n.t('homepage steps communicateWithEase text'),
  },
];

export default steps;
