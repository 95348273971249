import { fromJS } from 'immutable';

const mockData = fromJS([
  {
    key: '0',
    title: '',
    description: '',
  },
  {
    key: '1',
    title: '',
    description: '',
  },
  {
    key: '2',
    title: '',
    description: '',
  },
]);

export default mockData;
