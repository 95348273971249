import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import './Message.scss';
import { OpenNewTabIcon } from 'svgs';

const Message = (props) => {
  const {
    data,
    isMine,
    startsSequence,
    endsSequence,
    showTimestamp,
  } = props;

  const friendlyTimestamp = moment(data.timestamp).format('LLLL');

  return (
    <div
      className={[
        'message',
        `${isMine ? 'mine' : ''}`,
        `${startsSequence ? 'start' : ''}`,
        `${endsSequence ? 'end' : ''}`,
      ].join(' ')}
    >
      {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}

      <div className="bubble-container">
        <div
          className={classNames('bubble')}
          title={friendlyTimestamp}
        >
          {data.type === 'file' ? (
            <a
              href={`${data.message}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="message-file">
                {isMine ? <OpenNewTabIcon color="white" /> : <OpenNewTabIcon color="black" />}
                <u>{data.filename}</u>
              </p>
            </a>
          ) : (
            <div>{data.message}</div>
          )}
        </div>
      </div>
    </div>
  );
};

const {
  bool, shape, string, number,
} = PropTypes;

Message.propTypes = {
  data: shape({
    author: string.isRequired,
    id: number.isRequired,
    message: string.isRequired,
    timestamp: string.isRequired,
    type: string.isRequired,
    filename: string,
  }).isRequired,
  isMine: bool.isRequired,
  startsSequence: bool.isRequired,
  endsSequence: bool.isRequired,
  showTimestamp: bool.isRequired,
};

export default Message;
