import { fromJS } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  aboutUsIconSelect: false,
  productsAndServicesIconSelect: false,
  icons: [],
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SHOW_ICON_SELECT_MODAL:
      return state.set(action.payload.modalType, true);
    case types.HIDE_ICON_SELECT_MODAL:
      return state.set(action.payload.modalType, false);
    case types.SET_ICONS:
      return state.set('icons', fromJS(action.payload.icons));
    default:
      return state;
  }
};
