import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { layoutPositions } from 'utils/parse-helper';
import { List as iList } from 'immutable';
import ProductItem from './ProductItem';
import styles from './productsAndServices.module.scss';

const ProductsAndServices = (props) => {
  const {
    productsAndServices, textAlignment, colorTheme, fontFamily,
  } = props;
  const { t } = useTranslation();

  if (productsAndServices.every((item) => item.get('title') === '')) {
    return null;
  }

  return (
    <div
      className={classNames(
        'container',
        styles.productsAndServicesMainContainer,
      )}
    >
      <h4 style={{ textAlign: `${textAlignment.split('-')[1]}` }}>
        {t('createCompany productsAndServices headerTitleText')}
      </h4>
      <h3 style={{ color: colorTheme, fontFamily, textAlign: `${textAlignment.split('-')[1]}` }}>
        {t(
          'createCompany productsAndServices aboutProductsAndServices titleText',
        )}
      </h3>
      <div className={styles.productsAndServicesContainer}>
        {productsAndServices
          && productsAndServices
            .filter(
              (item) => item.get('title', '') && item.get('description', ''),
            )
            .map((item) => (
              <div
                className={classNames(
                  'col-12',
                  layoutPositions(
                    productsAndServices.filter(
                      (item) => item.get('title', '') && item.get('description', ''),
                    ).size,
                  ),
                )}
                key={item.get('key', '')}
              >
                <ProductItem
                  textAlignment={textAlignment}
                  title={item.get('title', '')}
                  description={item.get('description')}
                  iconName={item.get('iconName', '')}
                  colorTheme={colorTheme}
                />
              </div>
            ))}
      </div>
    </div>
  );
};

const { node, string } = PropTypes;

ProductsAndServices.propTypes = {
  productsAndServices: node,
  textAlignment: string.isRequired,
  colorTheme: string.isRequired,
  fontFamily: string.isRequired,
};

ProductsAndServices.defaultProps = {
  productsAndServices: iList(),
};

export default ProductsAndServices;
