import { fromJS } from 'immutable';
import { types } from 'actions/auth';

const defaultState = fromJS({
  token: null,
  isAuthenticated: false,
  companyData: {},
  activeCompanyDetails: {},
  activeCompanyId: null,
  activeCompanyName: '',
  activeCompanySlug: '',
  isToggleDisabled: false,
  changesMade: false,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.REGISTER_SUCCESS:
      return state.merge({
        token: action.payload.token,
        isAuthenticated: false,
      });
    case types.LOGIN_SUCCESS:
      return state.merge({
        token: action.payload.token,
        isAuthenticated: true,
      });
    case types.COMPANY_LOADED:
      return state.set(
        'companyData',
        fromJS({
          ...action.payload.companyData,
          companyJson: action.payload.companyData.companyJson || {},
          theme: action.payload.companyData.theme || {},
        }),
      );
    case types.UPDATE_COMPANY_DETAILS:
      return state.setIn(
        ['companyData', action.payload.sectionId],
        fromJS(action.payload.value),
      );
    case types.UPDATE_COMPANY_JSON:
      return state.setIn(
        ['companyData', 'companyJson', action.payload.sectionId],
        fromJS(action.payload.dataObject),
      );
    case types.SET_TOGGLE_DISABLED:
      return state.set('isToggleDisabled', fromJS(action.payload.isDisabled));

    case types.UPDATE_COMPANY_THEME:
      return state.setIn(
        ['companyData', 'theme', action.payload.sectionId],
        fromJS(action.payload.dataObject),
      );
    case types.SET_ACTIVE_COMPANY_DETAILS:
      return state.set(
        'activeCompanyDetails',
        fromJS({
          ...action.payload.details,
          companyJson: action.payload.details.companyJson || {},
          theme: action.payload.details.theme || {},
        }),
      );
    case types.SET_ACTIVE_COMPANY_NAME_ID_SLUG:
      return state.merge({
        activeCompanyName: action.payload.name,
        activeCompanyId: action.payload.id,
        activeCompanySlug: action.payload.slug,
      });
    case types.LOGOUT_CLEAR_DATA:
      return state.merge({
        token: null,
        isAuthenticated: false,
        companyData: null,
      });
    case types.UPDATE_ACTIVE_COMPANY_DETAILS:
      return state.setIn(
        ['activeCompanyDetails', action.payload.sectionId],
        fromJS(action.payload.value),
      );
    case types.UPDATE_ACTIVE_COMPANY_JSON:
      return state.setIn(
        ['activeCompanyDetails', 'companyJson', action.payload.sectionId],
        fromJS(action.payload.dataObject),
      );
    case types.UPDATE_ACTIVE_COMPANY_THEME:
      return state.withMutations((nextState) => {
        nextState.setIn(
          ['activeCompanyDetails', 'theme', 'companyLogo'],
          action.payload.companyLogo,
        );
        nextState.setIn(
          ['activeCompanyDetails', 'theme', 'color'],
          action.payload.color,
        );
        nextState.setIn(
          ['activeCompanyDetails', 'theme', 'fontFamily'],
          action.payload.fontFamily,
        );
      });
    case types.UPDATE_ACTIVE_COMPANY_BACKGROUND:
      return state.setIn(
        ['activeCompanyDetails', 'theme', 'backgroundImage'],
        fromJS(action.payload.imageUrl),
      );
    case types.SET_OPEN_MODAL:
      return state.set('openModal', action.payload.openModal);
    case types.SET_NEXT_LOCATION:
      return state.set('nextLocation', action.payload.nextLocation);
    case types.SET_CONFIRMED_NAVIGATION:
      return state.set('confirmedNavigation', action.payload.confirmedNavigation);
    case types.SET_CHANGES_MADE:
      return state.set('changesMade', action.payload.changesMade);
    default:
      return state;
  }
};
