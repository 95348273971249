import React from 'react';
import { listOf, mapContains } from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';
import { string } from 'prop-types';

import DateContent from '../../CategoryDateElements/DateContent';
import { composeBlogArticleUrl } from '../../../../utils/pathsHelper';

import styles from '../forYou.module.scss';

const ForYouArticle = (props) => {
  const { article } = props;

  return (
    <div className={styles.article}>
      <div className={styles.imgContainer}>
        <img src={article.getIn(['imageSlider', 'images', 0, 'image', 'url'])} alt={article.get('title')} />
      </div>
      <div className={styles.contentContainer}>
        <Link className={styles.link} to={composeBlogArticleUrl(article.get('slug'))}>
          <h1 className={styles.title}>{article.get('title')}</h1>
        </Link>
        <DateContent date={article.get('date')} />
      </div>
    </div>
  );
};

ForYouArticle.propTypes = {
  article: mapContains({
    slug: string.isRequired,
    title: string.isRequired,
    date: string.isRequired,
    imageSlider: mapContains({
      images: listOf(mapContains({
        image: mapContains({
          url: string.isRequired,
        }),
      })),
    }),
  }).isRequired,
};

export default ForYouArticle;
