export const types = {
  GET_COMPANIES: 'GET_COMPANIES',
  SET_COMPANIES: 'SET_COMPANIES',
};

export const getCompanies = (type) => ({
  type: types.GET_COMPANIES,
  payload: {
    type,
  },
});

export const setCompanies = (companies) => ({
  type: types.SET_COMPANIES,
  payload: {
    companies,
  },
});
