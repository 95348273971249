import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setActiveLanguage } from '../../../modules/actions';
import { selectActiveLanguage } from '../../../modules/selectors';

const mapStateToProps = (state) => ({
  activeLanguage: selectActiveLanguage(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setActiveLanguage,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
