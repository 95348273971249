import {
  border, defautFont, defaultColor, logoBorder,
} from 'utils/constants';

const initialState = {
  color: defaultColor,
  mainBorder: border,
  uploadLogoBorder: logoBorder,
  fontFamily: defautFont,
};

const setColor = (color) => ({
  type: 'SET_COLOR',
  payload: { color },
});

const setFontFamily = (fontFamily) => ({
  type: 'SET_FONT_FAMILY',
  payload: { fontFamily },
});

const setMainBorder = (mainBorder) => ({
  type: 'SET_MAIN_BORDER',
  payload: { mainBorder },
});

const setUploadLogoBorder = (uploadLogoBorder) => ({
  type: 'SET_LOGO_BORDER',
  payload: { uploadLogoBorder },
});

function reducer(state, action) {
  switch (action.type) {
    case 'SET_COLOR':
      return {
        ...state,
        color: action.payload.color,
      };
    case 'SET_FONT_FAMILY':
      return {
        ...state,
        fontFamily: action.payload.fontFamily,
      };
    case 'SET_MAIN_BORDER':
      return {
        ...state,
        mainBorder: action.payload.mainBorder,
      };
    case 'SET_LOGO_BORDER':
      return {
        ...state,
        uploadLogoBorder: action.payload.uploadLogoBorder,
      };
    default:
      return state;
  }
}

export {
  reducer,
  initialState,
  setColor,
  setFontFamily,
  setMainBorder,
  setUploadLogoBorder,
};
