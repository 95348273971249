import React, { useEffect, useState } from 'react';
import { List as IList } from 'immutable';
import { list } from 'react-immutable-proptypes';
import { useTranslation } from 'react-i18next';

import Article from '../NewsBanner/SmallerArticles/Article';

import newsStyles from '../news.module.scss';
import styles from './articlePreview.module.scss';

const ArticlePreview = (props) => {
  const [newArticles, setNewArticles] = useState(IList());
  const { t } = useTranslation();

  useEffect(() => {
    if (props.articles.size < 3) {
      setNewArticles(props.articles);
    } else {
      setNewArticles(props.articles.slice(3));
    }
  }, [props.articles]);

  if (props.articles.size === 0) {
    return (
      <div className={newsStyles.noArticles}>
        <p className={newsStyles.noArticlesText}>
          {t('news noArticles')}
        </p>
      </div>
    );
  }

  return (
    <div className={styles.articlePreviewContainer}>
      {
        newArticles.size === 0
          ? null
          : newArticles.map((article) => (
            <Article
              key={article.get('slug')}
              article={article}
              isHomepage={false}
              style={styles}
            />
          ))
      }
    </div>
  );
};

ArticlePreview.propTypes = {
  articles: list.isRequired,
};

export default ArticlePreview;
