import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes, { string } from 'prop-types';
import classNames from 'classnames';
import styles from './buttonSaveChangesMedium.module.scss';

const ButtonSaveChangesMedium = (props) => {
  const { handleClick, customClass, buttonText } = props;
  const { t } = useTranslation();
  return (
    <button
      type="button"
      onClick={handleClick}
      className={classNames(styles.buttonSuccessStyle, customClass)}
    >
      {buttonText || t('button save changes')}
    </button>
  );
};

const { func, node } = PropTypes;

ButtonSaveChangesMedium.propTypes = {
  handleClick: func.isRequired,
  customClass: node,
  buttonText: string,
};

ButtonSaveChangesMedium.defaultProps = {
  customClass: '',
  buttonText: '',
};

export default ButtonSaveChangesMedium;
