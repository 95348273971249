import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getMessages,
  setVisibilityMessagesList,
  setMessages,
} from '../modules/actions';
import { selectMessages, selectTitle } from '../modules/selectors';

const mapStateToProps = (state) => ({
  newMessages: selectMessages(state),
  title: selectTitle(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getMessages,
      setVisibilityMessagesList,
      setMessages,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
