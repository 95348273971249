export const types = {
  SET_ACTIVE_LANGUAGE: 'SET_ACTIVE_LANGUAGE',
};

export const setActiveLanguage = (language) => ({
  type: types.SET_ACTIVE_LANGUAGE,
  payload: {
    language,
  },
});

export default types;
