import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showModal, setNextRoute } from 'components/Login/modules/actions';
import {
  selectActiveCompanyBackgroundImageUrl,
  selectActiveCompanyColorTheme,
  selectActiveCompanyFontFamily,
  selectActiveCompanyBackgroundImageThumbnailUrl,
  selectActiveCompanyId,
  selectActiveCompanyName,
} from 'selectors/auth';
import { setChatRoute, setCompanyChat } from 'components/Chat/modules/actions';

const mapStateToProps = (state) => ({
  imageBackground: selectActiveCompanyBackgroundImageUrl(state),
  imageBackgroundThumbnail: selectActiveCompanyBackgroundImageThumbnailUrl(
    state,
  ),
  colorTheme: selectActiveCompanyColorTheme(state),
  fontFamily: selectActiveCompanyFontFamily(state),
  companyId: selectActiveCompanyId(state),
  companyName: selectActiveCompanyName(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setChatRoute,
      setCompanyChat,
      showModal,
      setNextRoute
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
