import React from 'react';
import { AutoComplete, Checkbox } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import IPropTypes from 'react-immutable-proptypes';
import { useTranslation } from 'react-i18next';

import inputStyle from '../../utils/inputStyle.module.scss';
import styles from './interests.module.scss';
import filterSearch from '../../utils/filterSearch';

const InterestInput = (props) => {
  const {
    hasError,
    error,
    component,
    type,
    state,
    setInputValue,
    handleAddEnteredInterest,
    setFormData,
    handleDeleteInterest,
  } = props;
  const { t } = useTranslation();
  const isIndustry = type === 'industry';
  const inputValueType = isIndustry ? 'industryInputValue' : 'inputValue';
  const interestType = isIndustry ? 'industryInterests' : 'interests';
  const interests = state.getIn(['formData', interestType]);

  return (
    <>
      <h4>{t(`${interestType} interestsTagline`)}</h4>
      <div className={styles.interestMainContent}>
        <div className={styles.rowInputContainer}>
          <div
            className={classNames(
              inputStyle.formGroup,
              'col-12 col-sm-8 col-md-5 col-lg-5 my-3 mx-5 mx-auto',
              hasError ? inputStyle.validationError : '',
            )}
          >
            {!hasError ? null : <h6 className={inputStyle.error}>{error}</h6>}
            <h5 className="px-1">{t('interests inputLabel')}</h5>
            <AutoComplete
              options={isIndustry ? state.get('industries') : state.get('options')}
              value={state.get(inputValueType)}
              placeholder={t('interests inputPlaceholder')}
              className={classNames(inputStyle.formControl, 'py-2 my-1 pr-5')}
              onChange={(e) => setInputValue(e, inputValueType)}
              filterOption={(inputValue, option) => filterSearch(inputValue, option)}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  handleAddEnteredInterest(type);
                }
              }}
            />
            <button
              type="button"
              className={styles.addInterestButtonStyle}
              onClick={() => handleAddEnteredInterest(type)}
            >
              {t('tagAutoComplete buttonText')}
            </button>
          </div>
          { type === 'interest' && (
            <div
              className={classNames(
                styles.checkBoxFormGroup,
                'col-12 col-sm-8 col-lg-5 my-md-3 mx-5 mx-auto',
              )}
            >
              <div className={styles.checkBoxContainer}>
                <Checkbox
                  className={classNames(
                    styles.formControl,
                    'my-md-1 mt-2 mt-md-0',
                  )}
                  checked={state.getIn(['formData', 'showInterests'], false)}
                  onChange={(event) => setFormData(
                    state.get('formData').merge({
                      showInterests: event.target.checked,
                    }),
                  )}
                  name="companyAuthorization"
                />
                <h5 className="ml-3 mt-md-1">{t('interests checkboxText')}</h5>
              </div>
            </div>
          )}
        </div>
        <div
          className={classNames(styles.savedInterestsContainer, 'mx-1 mx-md-5')}
        >
          {interests.size > 0
            && interests.map((item) => (
              <div key={item.get('name', '')} className={styles.interestItem}>
                <p>{item.get('name', '')}</p>
                <CloseOutlined onClick={() => handleDeleteInterest(item, type, component)} />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

const { string, func, bool } = PropTypes;
const { map } = IPropTypes;

InterestInput.propTypes = {
  error: string,
  hasError: bool,
  component: string,
  type: string.isRequired,
  state: map.isRequired,
  setInputValue: func.isRequired,
  handleAddEnteredInterest: func.isRequired,
  setFormData: func.isRequired,
  handleDeleteInterest:func.isRequired,
};
InterestInput.defaultProps = {
  component: 'companyInterests',
  hasError: false,
  error: '',
};

export default InterestInput;
