import React from 'react';
import { string } from 'prop-types';

const ShopIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 51.407 47.288"
  >
    <g
      id="prefix__Group_669"
      data-name="Group 669"
      transform="translate(-111.523 -622.314)"
    >
      <g
        id="prefix__Group_619"
        data-name="Group 619"
        transform="translate(-1807 254.661)"
      >
        <path
          id="prefix__Path_631"
          d="M112.932 262.149H97.991v-18.258a3.148 3.148 0 0 1 3.144-3.144h8.654a3.148 3.148 0 0 1 3.144 3.144v18.258zm-11.941-3h8.941v-15.258a.144.144 0 0 0-.144-.144h-8.654a.144.144 0 0 0-.144.144v15.258z"
          className="prefix__cls-1"
          data-name="Path 631"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_632"
          d="M123.6 262.649H88.93a4.578 4.578 0 0 1-4.578-4.578v-17.92a2 2 0 0 1 2-2 2 2 0 0 1 2 2v17.92a.58.58 0 0 0 .578.578h34.67a.58.58 0 0 0 .578-.578v-23.164h4v23.164a4.578 4.578 0 0 1-4.578 4.578z"
          className="prefix__cls-1"
          data-name="Path 632"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_633"
          d="M123.142 237.351a7.789 7.789 0 0 1-5.834-2.633 7.744 7.744 0 0 1-11.428-.045 7.752 7.752 0 0 1-11.5-.029 7.785 7.785 0 0 1-6.305 2.551 7.985 7.985 0 0 1-7.255-8.089v-.606l6.7-9.976a4.078 4.078 0 0 1 3.385-1.809l29.923-.059h.006a4.072 4.072 0 0 1 3.413 1.839l6.69 10.152v.6a7.984 7.984 0 0 1-7.252 8.088c-.185.011-.366.016-.543.016zm-5.8-7.841h.032a2.519 2.519 0 0 1 2.284 1.538 3.793 3.793 0 0 0 3.742 2.294 3.981 3.981 0 0 0 3.49-3.551l-6-9.1-30 .02-.07.037-5.981 8.912a3.981 3.981 0 0 0 3.491 3.543 3.751 3.751 0 0 0 3.723-2.256 2.527 2.527 0 0 1 2.306-1.516 2.537 2.537 0 0 1 2.317 1.519 3.76 3.76 0 0 0 6.893.01 2.516 2.516 0 0 1 2.294-1.505 2.514 2.514 0 0 1 2.294 1.506 3.756 3.756 0 0 0 6.867.036 2.537 2.537 0 0 1 2.32-1.487z"
          data-name="Path 633"
          transform="translate(1839 151)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_634"
          d="M119.532 217.946h-.006l-29.922.059a4.078 4.078 0 0 0-3.385 1.809l-6.7 9.976v.609a7.985 7.985 0 0 0 7.255 8.089 7.782 7.782 0 0 0 6.305-2.551 7.752 7.752 0 0 0 11.5.029 7.744 7.744 0 0 0 11.428.045 7.794 7.794 0 0 0 5.834 2.633c.177 0 .355-.006.534-.019s.339-.038.507-.061v20.8a.58.58 0 0 1-.578.578H111.64v-14.759a3.148 3.148 0 0 0-3.144-3.144h-8.654a3.148 3.148 0 0 0-3.144 3.144v14.758h-9.061a.58.58 0 0 1-.578-.578v-17.92a2 2 0 1 0-4 0v17.92a4.578 4.578 0 0 0 4.579 4.578h34.673a4.578 4.578 0 0 0 4.578-4.578v-22.657a8.247 8.247 0 0 0 2.745-6.169v-.6l-6.689-10.152a4.07 4.07 0 0 0-3.413-1.839zM99.7 245.183a.144.144 0 0 1 .144-.144h8.656a.144.144 0 0 1 .144.144v14.758H99.7v-14.758zm22.411-10.549a3.793 3.793 0 0 1-3.742-2.294 2.52 2.52 0 0 0-2.284-1.538h-.032a2.535 2.535 0 0 0-2.3 1.488 3.756 3.756 0 0 1-6.867-.036 2.513 2.513 0 0 0-2.294-1.506 2.516 2.516 0 0 0-2.295 1.505 3.761 3.761 0 0 1-6.894-.01 2.537 2.537 0 0 0-2.317-1.519 2.527 2.527 0 0 0-2.306 1.516 3.753 3.753 0 0 1-3.723 2.256 3.982 3.982 0 0 1-3.491-3.543l5.981-8.912.07-.037 29.994-.02 6 9.1a3.98 3.98 0 0 1-3.501 3.55z"
          data-name="Path 634"
          transform="translate(1839 151)"
          style={{ opacity: 0.2, fill: color }}
        />
      </g>
    </g>
  </svg>
);

ShopIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

ShopIcon.defaultProps = {
  color: '#37ead9',
  width: '51.407',
  height: '47.288',
};

export default ShopIcon;
