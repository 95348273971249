import moment from 'moment';

const changeDateLanguage = (language) => {
  if (language === 'bos' || language === 'srb') {
    moment.locale('bs');
  } else if (language === 'cro') {
    moment.locale('hr');
  } else {
    moment.locale('en');
  }
};

export default changeDateLanguage;
