import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setOpenModal,
  setNextLocation,
  setConfirmedNavigation,
} from 'actions/auth';
import {
  selectOpenModal,
  selectNextLocation,
  selectConfirmedNavigation,
} from 'selectors/auth';

const mapStateToProps = (state) => ({
  nextLocation: selectNextLocation(state),
  openModal: selectOpenModal(state),
  confirmedNavigation: selectConfirmedNavigation(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setOpenModal,
      setNextLocation,
      setConfirmedNavigation,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
