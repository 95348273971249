const colors = [
  {
    key:1,
    primaryColor:'#ff0000',
  },
  {
    key:2,
    primaryColor:'#0156fb',
  },
  {
    key:3,
    primaryColor:'#ffc837',
  },
  {
    key:4,
    primaryColor:'#c0ca33',
  },
  {
    key:5,
    primaryColor:'#ff5e0a',
  },
  {
    key:6,
    primaryColor:'#ff187c',
  },
  {
    key:7,
    primaryColor:'#782fff',
  },

  {
    key:8,
    primaryColor:'#1b008d',
  },
  {
    key:9,
    primaryColor:'#3cd379',
  },
  {
    key:10,
    primaryColor:'#04e6ce',
  },
  {
    key:11,
    primaryColor:'#d250e6',
  },
  {
    key:12,
    primaryColor:'#3f9476',
  },
  {
    key:13,
    primaryColor:'#03a9f4',
  },
  {
    key:14,
    primaryColor:'#ffab40',
  },
  {
    key:15,
    primaryColor:'#a87851',
  },
  {
    key:16,
    primaryColor:'#37474f',
  },
];

export default colors;
