import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './buttonMenu.module.scss';

const ButtonMenu = (props) => {
  const {
    collapsedNavbar,
    toggleNavbar,
  } = props;

  return (
    <div>
      <button
        className={classNames(
          styles.navbarToggleButton,
          'ml-auto',
          collapsedNavbar ? '' : styles.navbarOpen,
        )}
        type="button"
        onClick={toggleNavbar}
        aria-label="Toggle navigation"
      >
        <div className={classNames(styles.line, styles.lineTop)} />
        <div className={classNames(styles.line, styles.lineMiddle)} />
        <div className={classNames(styles.line, styles.lineBottom)} />
      </button>

    </div>
  );
};


ButtonMenu.propTypes = {
  collapsedNavbar: PropTypes.bool,
  toggleNavbar: PropTypes.func,
};

ButtonMenu.defaultProps = {
  collapsedNavbar:false,
  toggleNavbar: () => {},
};
export default ButtonMenu;
