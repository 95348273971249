import { fromJS } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  modalVisible: false,
  pictureUrl: '',
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SHOW_PICTURE_EXPAND_MODAL:
      return state.merge({
        modalVisible: true,
        pictureUrl: action.payload.pictureUrl,
      });
    case types.HIDE_PICTURE_EXPAND_MODAL:
      return state.set('modalVisible', false);
    default:
      return state;
  }
};
