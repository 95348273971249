import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { homepageLinks, adminPanelUrl, companyInterestsUrl } from 'utils/pathsHelper';
import { ArrowDownMedium, ArrowUpMedium } from '../../../../svgs';
import styles from './userMenu.module.scss';
import history from '../../../../history';
import LanguageSelectorLoggedUser from './LanguageSelectorLoggedUser';

const UserMenu = (props) => {
  const { actions, packageType, adminRole } = props;
  const [isDropDownVisible, setDropDownVisible] = useState(false);
  const [packageTypeTranslation, setPackageTypeTranslation] = useState('');
  const { t } = useTranslation();

  const handleVisibleChange = (isVisible) => {
    setDropDownVisible(isVisible);
  };

  useEffect(() => {
    switch (packageType) {
      case 'Početni paket':
        setPackageTypeTranslation(t('subPackage freePackage packageType'));
        break;
      case 'Prodajni paket':
        setPackageTypeTranslation(t('subscriptionPackageItem professional title'));
        break;
      case 'Promotivni paket':
        setPackageTypeTranslation(t('subscriptionPackageItem standard title'));
        break;
      default:
        setPackageTypeTranslation(t('subscriptionPackageItem entrepreneurial title'));
        break;
    }
  }, [packageType, t]);

  const logoutHandler = () => {
    actions.chatDisconnect();
    actions.setMessages([]);
    actions.setConversations([]);
    actions.setConversationId('');
    actions.setVisibilityMessagesList(false);
    actions.setCompanyChat({});
    actions.logoutUser();
  };
  const menu = (
    <Menu className={styles.userMenu}>
      <Menu.Item
        key="0"
        className={styles.userMenuItem}
        onClick={() => history.push(companyInterestsUrl)}
      >
        {t('user menu interest of the company')}
      </Menu.Item>
      <div>
        <LanguageSelectorLoggedUser />
      </div>
      <Menu.Item
        key="2"
        className={styles.userMenuItem}
        onClick={() => history.push(homepageLinks.packages)}
      >
        {packageTypeTranslation}
      </Menu.Item>
      {adminRole && (
        <Menu.Item
          key="4"
          className={styles.userMenuItem}
          onClick={() => history.push(adminPanelUrl)}
        >
          Admin Panel
        </Menu.Item>
      )}
      <Menu.Item
        key="3"
        onClick={logoutHandler}
        className={styles.userMenuItemLogout}
      >
        {t('user menu logout')}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      onVisibleChange={handleVisibleChange}
    >
      <div>{isDropDownVisible ? <ArrowUpMedium /> : <ArrowDownMedium />}</div>
    </Dropdown>
  );
};

const {
  shape, func, string, bool,
} = PropTypes;

UserMenu.propTypes = {
  actions: shape({
    logoutUser: func.isRequired,
  }).isRequired,
  packageType: string.isRequired,
  adminRole: bool.isRequired,
};
export default UserMenu;
