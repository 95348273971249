import React from 'react';

const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path fill="#FFF" stroke="#FFF" strokeWidth=".6" d="M14.599 12.791l-2.047-2.046c-.403-.403-.999-.476-1.47-.242l-.697-.698c.801-.933 1.286-2.146 1.286-3.47C11.671 3.393 9.278 1 6.336 1S1 3.393 1 6.335s2.394 5.336 5.336 5.336c1.324 0 2.536-.485 3.47-1.287l.698.698c-.236.478-.156 1.073.241 1.47l2.047 2.046c.5.5 1.307.5 1.807 0 .498-.498.498-1.308 0-1.807zm-8.263-1.94c-2.49 0-4.517-2.025-4.517-4.516 0-2.49 2.026-4.516 4.517-4.516 2.49 0 4.516 2.026 4.516 4.516s-2.026 4.517-4.516 4.517zm7.684 3.169c-.18.179-.47.179-.65 0l-2.046-2.047c-.179-.18-.179-.47 0-.65.18-.179.47-.179.65 0l2.046 2.047c.179.18.179.47 0 .65z" />
  </svg>


);

export default SearchIcon;
