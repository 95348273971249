import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ContactFormModal from './ContactFormModal';
import EasySteps from './EasySteps';

import styles from './subscriptionPackages.module.scss';
import Packages from './Packages';

const SubscriptionPackages = (props) => {
  const { actions } = props;

  return (
    <div
      className={classnames(
        styles.subscriptionPackages,
        'container py-5 px-md-5 mx-auto',
      )}
    >
      <EasySteps />
      <ContactFormModal />
      <Packages showModal={actions.showModal} />
    </div>
  );
};

const { shape, func } = PropTypes;

SubscriptionPackages.propTypes = {
  actions: shape({
    showModal: func.isRequired,
  }).isRequired,
};

export default SubscriptionPackages;
