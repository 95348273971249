import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

import { composeEditCompanyUrl } from 'utils/pathsHelper';
import buttonStyle from 'utils/buttonStyle.module.scss';
import useInterests from './utils/useInterests';
import InterestInput from './InterestInput';

import styles from './interests.module.scss';

const Interests = (props) => {
  const {
    companyDetails,
    companyInterests,
    actions,
    searchedResultsInterests,
    industryInterests,
    industries,
  } = props;
  const { t } = useTranslation();
  const {
    state,
    setFormData,
    setOptions,
    setInputValue,
    handleAddEnteredInterest,
    handleDeleteInterest,
  } = useInterests();

  useEffect(() => {
    setOptions(
      industries.map((item) => ({
        value: item.get('name', ''),
        name: item.get('name', ''),
        key: item.get('id', ''),
      })),
      'industry',
    );
  }, [industries]);

  useEffect(() => {
    setFormData(
      state.get('formData').merge({
        interests: companyInterests,
        industryInterests,
        showInterests: companyDetails.get('showInterests', false),
      }),
    );
  }, [companyInterests, industryInterests]);

  useEffect(() => {
    if (searchedResultsInterests.size > 0) {
      setOptions(
        searchedResultsInterests.map((item) => ({
          value: item.get('name', ''),
          name: item.get('name', ''),
          key: item.get('id', ''),
        })),
        'interest',
      );
    }
  }, [searchedResultsInterests]);

  const saveInterests = () => {
    actions.saveInterests({
      formData: state.get('formData'),
      deletedInterests: state.get('deletedInterests'),
      deletedIndustryInterests: state.get('deletedIndustryInterests'),
    });
  };

  useEffect(() => {
    if (state.get('inputValue').length === 0) {
      actions.getInterests();
    } else if (state.get('inputValue').length > 3) {
      actions.getInterests({ name: state.get('inputValue') });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.get('inputValue')]);

  useEffect(() => {
    actions.getIndustryInterests();
    actions.getIndustriesList();
  }, []);

  return (
    <div className={classNames('container', styles.interestsContainer)}>
      <div className={classNames(styles.navigationInfo)}>
        <Link to={composeEditCompanyUrl(localStorage.getItem('userId'))}>
          <h5>{companyDetails.get('name', 'BIZBOOK')}</h5>
        </Link>
        <RightOutlined className="px-3 pt-1" />
        <h6>{t('interests navigationTitle')}</h6>
      </div>
      <h2>{t('interests mainTitle')}</h2>
      <InterestInput
        type="interest"
        state={state}
        setInputValue={setInputValue}
        handleAddEnteredInterest={handleAddEnteredInterest}
        setFormData={setFormData}
        handleDeleteInterest={handleDeleteInterest}
      />
      <InterestInput
        type="industry"
        state={state}
        setInputValue={setInputValue}
        handleAddEnteredInterest={handleAddEnteredInterest}
        setFormData={setFormData}
        handleDeleteInterest={handleDeleteInterest}
      />
      <div
        className={classNames(
          styles.buttonContainer,
          'mb-3 col-12 mt-0 mt-md-0 ml-auto pr-lg-5',
        )}
      >
        <Link
          to={composeEditCompanyUrl(localStorage.getItem('userId'))}
          className={classNames(
            buttonStyle.mainButtonStyle,
            styles.mainButtonStyle,
            'my-1 my-md-5 mr-2',
          )}
        >
          {t('register button back')}
        </Link>
        <button
          type="button"
          className={classNames(
            buttonStyle.buttonSuccessStyle,
            styles.buttonSuccessStyle,
            'my-1 my-md-5',
          )}
          onClick={saveInterests}
        >
          {t('createCompany companyHeader saveChangesButtonText')}
        </button>
      </div>
    </div>
  );
};

const { shape, func } = PropTypes;
const { map, list } = ImmutablePropTypes;

Interests.propTypes = {
  companyDetails: map.isRequired,
  companyInterests: list.isRequired,
  actions: shape({
    getInterests: func.isRequired,
    saveInterests: func.isRequired,
    deleteInterest: func.isRequired,
    getIndustryInterests: func.isRequired,
    getIndustriesList: func.isRequired,
  }).isRequired,
  searchedResultsInterests: list.isRequired,
  industryInterests: list.isRequired,
  industries: list.isRequired,
};

export default Interests;
