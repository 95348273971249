export const types = {
  SET_BLOG_ARTICLES: 'SET_BLOG_ARTICLES',
  GET_BLOG_ARTICLES: 'GET_BLOG_ARTICLES',
  SET_SELECTED_CATEGORY: 'SET_SELECTED_CATEGORY',
  SET_BLOG_ARTICLE_SLUG: 'SET_BLOG_ARTICLE_SLUG',
  GET_FOR_YOU_ARTICLES: 'GET_FOR_YOU_ARTICLES',
  SET_FOR_YOU_ARTICLES: 'SET_FOR_YOU_ARTICLES',
  INCREASE_ARTICLE_VIEW_COUNT: 'INCREASE_ARTICLE_VIEW_COUNT',
};

export const setBlogArticles = (payload) => ({
  type: types.SET_BLOG_ARTICLES,
  payload,
});

export const getBlogArticles = (payload = {}) => ({
  type: types.GET_BLOG_ARTICLES,
  payload,
});

export const setSelectedCategory = (payload) => ({
  type: types.SET_SELECTED_CATEGORY,
  payload,
});

export const setBlogArticleSlug = (payload) => ({
  type: types.SET_BLOG_ARTICLE_SLUG,
  payload,
});

export const getForYouArticles = (payload) => ({
  type: types.GET_FOR_YOU_ARTICLES,
  payload,
});

export const setForYouArticles = (payload) => ({
  type: types.SET_FOR_YOU_ARTICLES,
  payload,
});

export const increaseArticleViewCount = (payload) => ({
  type: types.INCREASE_ARTICLE_VIEW_COUNT,
  payload,
});
