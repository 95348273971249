import { all, spawn } from 'redux-saga/effects';

import registerSaga from 'components/Register/modules/sagas';
import loginSaga from 'components/Login/modules/sagas';
import colorPaletteSaga from 'components/CreateCompany/ColorPalette/modules/sagas';
import selectHeaderBackgroundSaga from 'components/CreateCompany/SelectHeaderBackground/modules/sagas';
import selectIconSaga from 'components/CreateCompany/AddIcon/modules/sagas';
import subscriptionPackagesSaga from 'components/SubscriptionPackages/modules/sagas';
import feedContentSaga from 'components/Feed/modules/sagas';
import productDemandSaga from 'components/CreateProductDemand/modules/sagas';
import chatSagas from 'components/Chat/modules/sagas';
import companiesListSagas from 'components/Chat/CompaniesList/modules/sagas';
import companyCatalogSaga from 'components/Catalog/modules/sagas';
import notificationPollingSaga from 'components/Navbar/NavBarLoggedUser/Notifications/modules/sagas';
import notificationSaga from 'components/Navbar/NavBarLoggedUser/Notifications/modules/notificationSaga';
import interestsSaga from 'components/Interests/modules/sagas';
import adminPanelSaga from 'components/AdminPanel/modules/sagas';
import webShopSaga from 'components/WebShop/modules/sagas';
import commentsSaga from 'components/Comments/modules/sagas';
import homepageContentSaga from 'components/Home/modules/sagas';
import blogSaga from 'components/News/modules/sagas';
import authSagas from './auth';
import bannersSagas from 'components/Banner/modules/sagas';

const sagas = [
  authSagas,
  registerSaga,
  loginSaga,
  colorPaletteSaga,
  selectHeaderBackgroundSaga,
  selectIconSaga,
  subscriptionPackagesSaga,
  feedContentSaga,
  productDemandSaga,
  chatSagas,
  companiesListSagas,
  companyCatalogSaga,
  notificationPollingSaga,
  notificationSaga,
  interestsSaga,
  adminPanelSaga,
  webShopSaga,
  commentsSaga,
  homepageContentSaga,
  blogSaga,
  bannersSagas,
];

export default function * () {
  yield all(sagas.map(spawn));
}
