import React, { useEffect, useReducer } from 'react';
import GoogleMapReact from 'google-map-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Proptypes from 'prop-types';
import { mapMarker } from 'images/createCompany';
import { countryCodeLatLong } from 'utils/countryCodeLatLong';
import { googleMapsApiKey } from 'utils/constants';
import { fromJS } from 'immutable';
import {
  initialState,
  reducer,
  setDraggable,
  setMarkerPosition,
  setMapCenter,
  setZoomLevel,
} from './utils/useReducer';
import styles from './map.module.scss';

const defaultSettings = {
  center: {
    lat: 43.84864,
    lng: 18.35644,
  },
};

const Marker = () => (
  <img src={mapMarker} alt="My position" className={styles.markerStyle} />
);

const SimpleMap = (props) => {
  const {
    countryIsoCode,
    positionInfo,
    updatePositionData,
    draggableMarker,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  // Setup inital map cooridinates on map load by country iso code
  useEffect(() => {
    dispatch(
      setMapCenter({
        lat: countryCodeLatLong.getIn(
          [countryIsoCode?.toLowerCase(), 'lat'],
          defaultSettings.center.lat,
        ),
        lng: countryCodeLatLong.getIn(
          [countryIsoCode?.toLowerCase(), 'lng'],
          defaultSettings.center.lng,
        ),
      }),
    );
    dispatch(
      setMarkerPosition({
        lat: countryCodeLatLong.getIn(
          [countryIsoCode?.toLowerCase(), 'lat'],
          defaultSettings.center.lat,
        ),
        lng: countryCodeLatLong.getIn(
          [countryIsoCode?.toLowerCase(), 'lng'],
          defaultSettings.center.lng,
        ),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryIsoCode]);

  const getPositionInfoData = (field) => positionInfo.get(field, '');

  // Update map coordinates after position data get
  useEffect(() => {
    if (getPositionInfoData('lat') && getPositionInfoData('lng')) {
      dispatch(
        setMapCenter({
          lat: getPositionInfoData('lat'),
          lng: getPositionInfoData('lng'),
        }),
      );
      dispatch(
        setMarkerPosition({
          lat: getPositionInfoData('lat'),
          lng: getPositionInfoData('lng'),
        }),
      );
      if (getPositionInfoData('zoom')) {
        dispatch(setZoomLevel(getPositionInfoData('zoom')));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionInfo]);

  const getStateData = (field) => state.get(field, '');
  const handleMarkerChange = (childKey, childProps, mouse) => {
    if (draggableMarker) {
      dispatch(setMarkerPosition({ lat: mouse.lat, lng: mouse.lng }));
    }
  };

  // Update contact info zoom data on zoom change
  const currentZoom = state.get('zoom');
  useEffect(() => {
    updatePositionData(
      fromJS({
        lat: getStateData('lat'),
        lng: getStateData('lng'),
        zoom: getStateData('zoom'),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentZoom]);

  const draggable = state.get('draggable');
  useEffect(() => {
    if (draggable && draggableMarker) {
      updatePositionData(
        fromJS({
          lat: getStateData('lat'),
          lng: getStateData('lng'),
          zoom: getStateData('zoom'),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draggable]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ googleMapsApiKey }}
      center={{
        lat: state.getIn(['center', 'lat']),
        lng: state.getIn(['center', 'lng']),
      }}
      zoom={state.get('zoom')}
      onChange={({ center, zoom }) => {
        dispatch(setMapCenter(center));
        dispatch(setZoomLevel(zoom));
      }}
      draggable={state.get('draggable')}
      onChildMouseDown={() => dispatch(setDraggable(false))}
      onChildMouseUp={() => dispatch(setDraggable(true))}
      onChildMouseMove={handleMarkerChange}
    >
      <Marker lat={state.get('lat')} lng={state.get('lng')} />
    </GoogleMapReact>
  );
};

const { string, func, bool } = Proptypes;

SimpleMap.propTypes = {
  countryIsoCode: string.isRequired,
  positionInfo: ImmutablePropTypes.map.isRequired,
  updatePositionData: func,
  draggableMarker: bool,
};

SimpleMap.defaultProps = {
  updatePositionData: () => {},
  draggableMarker: true,
};

export default SimpleMap;
