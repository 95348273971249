import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selectArticles, selectSelectedCategory } from './modules/selectors';
import { getBlogArticles, setSelectedCategory } from './modules/actions';

const mapStateToProps = (state) => ({
  articles: selectArticles(state),
  selectedCategory: selectSelectedCategory(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getBlogArticles,
      setSelectedCategory,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
