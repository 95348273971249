import React from 'react';
import PropTypes from 'prop-types';
import './ToolbarButton.css';

const ToolbarButton = (props) => {
  const {
    icon,
    actions,
  } = props;
  const handleClick = () => {
    if (icon === 'ion-ios-arrow-back') {
      actions.setVisibilityMessagesList(false);
    }
    if (icon === 'ion-ios-add-circle-outline') {
      actions.setVisibilityCompaniesList(true);
    }
    if (icon === 'ion-ios-close') {
      actions.setVisibilityCompaniesList(false);
    }
  };
  return (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <i className={`toolbar-button ${icon}`} onClick={handleClick} />
  );
};

const { string, shape, func } = PropTypes;

ToolbarButton.propTypes = {
  icon: string.isRequired,
  actions: shape({
    setVisibilityMessagesList: func,
    setVisibilityCompaniesList: func,
  }).isRequired,
};

export default ToolbarButton;
