import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map as IMap } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import shave from 'shave';
import './ConversationListItem.scss';
import { Avatar, Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import history from '../../../history';
import styles from '../../Navbar/navbar.module.scss';
import { MoreIcon } from '../../../svgs';

const ConversationListItem = (props) => {
  useEffect(() => {
    shave('.conversation-snippet', 30);
  });

  const {
    name,
    text,
    id,
    actions,
    participants,
    conversationItem,
    activeConversationId,
    showSubtitle,
    activeStatus,
  } = props;

  const productId = conversationItem.get('productId',null)

  const conversationParticipantCompany = participants
    .filter(
      (item) => `${item.get('id', '')}` !== `${localStorage.getItem('userId')}`,
    )
    .first();

  const getAcronym = (item) => {
    if (item) {
      const matches = item.match(/\b(\w)/g);

      return matches.length > 1 ? matches[0].concat(matches[1]) : matches[0];
    }
    return null;
  };

  const handleOnClick = () => {
    actions.setChatRoute('', productId)
    actions.setConversationId(id);
    actions.setCompanyChat({
      id: conversationParticipantCompany.get('id', ''),
      name: conversationParticipantCompany.get('name', ''),
    });
    actions.setTitle(name);
    history.push(`/messenger/${id}`);
    actions.setVisibilityMessagesList(true);
    actions.getMessages();
  };
  const handleArchiveConversation = () => {
    actions.archiveConversation(conversationItem.get('id', null), activeConversationId);
  };

  const menu = (
    <Menu className={styles.userMenu}>
      <Menu.Item
        key="0"
        className={styles.userMenuItem}
        onClick={handleArchiveConversation}
      >
        Delete
      </Menu.Item>
    </Menu>
  );
  return (
    <div
      className={classNames(
        'conversation-list-item',
        (id === activeConversationId
          || conversationItem.get('id', null) === null)
        && 'active-conversation',
      )}
      role="presentation"
    >
      <div
        onClick={handleOnClick}
        className="conversation-list-item-info"
      >
        <div className="avatar-container">
          <Avatar size="large" className="avatar">
            <div className="avatar-name">
              {getAcronym(name || conversationItem.get('title', ''))}
            </div>
          </Avatar>
        </div>
        <div className="conversation-info">
          <h1
            className={classNames(
              'conversation-title',
              !conversationItem.get('read', '')
              && 'font-weight-bold text-primary',
            )}
          >
            {name || conversationItem.get('title', '')}
          </h1>
          <p className="conversation-snippet">
            {showSubtitle ? conversationParticipantCompany.get('name', text) : ''}
          </p>
        </div>
        {activeStatus ? (<div className="active-status" />) : null}
      </div>
      {conversationItem.get('id') ? (
        <div className="conversation-dropdown right-items">
          <Dropdown
            overlay={menu}
            placement="bottomRight"
            trigger={['click', 'hover']}
          >
            <div>
              <MoreIcon />
            </div>
          </Dropdown>
        </div>
      ) : <div />}
    </div>
  );
};

const {
  string,
  number,
  shape,
  func,
  bool,
} = PropTypes;

const { list, map } = ImmutablePropTypes;

ConversationListItem.propTypes = {
  name: string.isRequired,
  text: string.isRequired,
  id: number,
  actions: shape({
    setConversationId: func.isRequired,
    setCompanyChat: func.isRequired,
    setTitle: func.isRequired,
    setVisibilityMessagesList: func.isRequired,
    getMessages: func.isRequired,
  }).isRequired,
  participants: list.isRequired,
  conversationItem: map,
  activeConversationId: number,
  showSubtitle: bool,
  activeStatus: bool,
};

ConversationListItem.defaultProps = {
  id: null,
  showSubtitle: false,
  activeConversationId: null,
  conversationItem: IMap,
  activeStatus: false,
};
export default ConversationListItem;
