const fonts = [
  {
    key: 0,
    font: 'Poppins',
  },
  {
    key: 1,
    font: 'Roboto',
  },
  {
    key: 2,
    font: 'Open Sans',
  },
  {
    key: 3,
    font: 'Oswald',
  },
  {
    key: 4,
    font: 'Source Sans Pro',
  },
  {
    key: 5,
    font: 'Montserrat',
  },
  {
    key: 6,
    font: 'Raleway',
  },
  {
    key: 7,
    font: 'PT Sans',
  },
  {
    key: 8,
    font: 'Dancing Script',
  },
  {
    key: 9,
    font: 'Lora',
  },
  {
    key: 10,
    font: 'Noto Sans',
  },
  {
    key: 11,
    font: 'Nunito Sans',
  },
  {
    key: 12,
    font: 'Playfair',
  },
  {
    key: 13,
    font: 'Merriweather',
  },
];

export default fonts;
