import { fromJS, Map as IMap } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  modalVisible: false,
  animationIsPaused: true,
  nextRouteParams: {},
  nextRoute: '',
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_NEXT_ROUTE_PARAMS:
      return state.set('nextRouteParams', fromJS(action.payload.nextRouteParams));
    case types.SET_NEXT_ROUTE:
      return state.set('nextRoute', action.payload.nextRoute);
    case types.SHOW_MODAL:
      return state.set('modalVisible', true);
    case types.HIDE_MODAL:
      return state.merge({
        modalVisible:false,
        nextRoute:'',
        nextRouteParams: IMap(),
      });
    case types.SET_ANIMATION_STATUS:
      return state.set('animationIsPaused', action.payload.status);
    default:
      return state;
  }
};
