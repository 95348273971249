import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectCompanyDetails, selectCompanyInterests } from 'selectors/auth';
import {
  getInterests,
  saveInterests,
  deleteInterest,
  getIndustryInterests,
} from './modules/actions';
import { selectIndustryInterests, selectSearchedInterests } from './modules/selectors';
import { selectIndustriesList } from '../Register/modules/selectors';
import { getIndustriesList } from '../Register/modules/actions';

const mapStateToProps = (state) => ({
  companyDetails: selectCompanyDetails(state),
  companyInterests: selectCompanyInterests(state),
  searchedResultsInterests: selectSearchedInterests(state),
  industryInterests: selectIndustryInterests(state),
  industries: selectIndustriesList(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getInterests,
      saveInterests,
      deleteInterest,
      getIndustryInterests,
      getIndustriesList,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
