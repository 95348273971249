export const types = {
  UPLOAD_CATALOG: 'UPLOAD_CATALOG',
  SET_UPLOADED_CATALOG: 'SET_UPLOADED_CATALOG',
  GET_COMPANY_CATALOGS: 'GET_COMPANY_CATALOGS',
  SET_COMPANY_CATALOGS: 'SET_COMPANY_CATALOGS',
  ADD_CATALOG: 'ADD_CATALOG',
  DELETE_CATALOG: 'DELETE_CATALOG',
};

export const uploadCatalog = (catalogData) => ({
  type: types.UPLOAD_CATALOG,
  payload: { catalogData },
});

export const setUploadedCatalog = (data) => ({
  type: types.SET_UPLOADED_CATALOG,
  payload: { data },
});

export const getCompanyCatalogs = (companySlug) => ({
  type: types.GET_COMPANY_CATALOGS,
  payload: { companySlug },
});

export const setCompanyCatalogs = (catalogs) => ({
  type: types.SET_COMPANY_CATALOGS,
  payload: { catalogs },
});

export const addCompanyCatalog = (companyId, companySlug, url, publicId, name) => ({
  type: types.ADD_CATALOG,
  payload: {
    companyId,
    companySlug,
    publicId,
    url,
    name,
  },
});

export const deleteCatalog = (id, companySlug) => ({
  type: types.DELETE_CATALOG,
  payload: {
    id,
    companySlug,
  },
});

export default types;
