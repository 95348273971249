import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { accountCircleLogo, phoneIcon, bizbookButtonImg } from 'images';
import { useTranslation } from 'react-i18next';
import testimonials from './testimonials';
import styles from './testimonials.module.scss';

const Testimonials = (props) => {
  const { activeLanguage } = props;
  const { t } = useTranslation();
  const [displayTestimonials, setTestimonials] = useState(testimonials());
  useEffect(() => {
    setTestimonials(testimonials());
  }, [activeLanguage]);
  return (
    <div className={styles.testimonials}>
      <div className="container">
        <div className={classNames(styles.navigationInfo, 'pt-5')}>
          <Link to="/">
            <h5>Bizbook</h5>
          </Link>
          <RightOutlined className="px-3 pt-1" />
          <h6>{t('testimonials navigationInfo')}</h6>
        </div>
        <div
          className={classNames(styles.testimonialsContainer, 'mx-auto my-5')}
        >
          <div className={styles.scrollContainer}>
            {displayTestimonials.map((testimonial) => (
              <div className={styles.testimonialItem} key={testimonial.key}>
                <div
                  className={classNames(
                    styles.bubbleContainer,
                    'mx-auto mt-md-2',
                  )}
                >
                  <p>{testimonial.text}</p>
                </div>
                <div className={classNames(styles.testimonialInfoRow, 'py-2')}>
                  <img src={accountCircleLogo} alt="testimonial profile" />
                  <h5 className="pl-0 pl-md-4 pr-2">{testimonial.name}</h5>
                  <h6 className="my-auto">{`${testimonial.position}${testimonial.company}`}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={classNames(styles.bizbookMobileAdSection, 'mx-auto')}>
        <img src={bizbookButtonImg} alt="bizbook" />
        <h4>{t('testimonials text bizbookInAllSizes')}</h4>
        <h3>{t('testimonials text bizbookOnMobile')}</h3>
        <img src={phoneIcon} alt="bizbook on mobile" />
      </div>
    </div>
  );
};

const { string } = PropTypes;

Testimonials.propTypes = {
  activeLanguage: string.isRequired,
};

export default Testimonials;
