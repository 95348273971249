import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  hidePictureExpandModal,
  showPictureExpandModal,
} from './modules/actions';
import { selectModalVisibility, selectPictureUrl } from './modules/selectors';

const mapStateToProps = (state) => ({
  modalVisibility: selectModalVisibility(state),
  pictureUrl: selectPictureUrl(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      hidePictureExpandModal,
      showPictureExpandModal,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
