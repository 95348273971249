import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  showRatingModal,
  hideRatingModal,
  rateProductItem,
} from 'components/Feed/modules/actions';
import { selectRatingModalVisibility } from 'components/Feed/modules/selectors';

const mapStateToProps = (state) => ({
  modalVisibility: selectRatingModalVisibility(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showRatingModal,
      hideRatingModal,
      rateProductItem,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
