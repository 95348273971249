import React from 'react';
import { string } from 'prop-types';

const CarrotIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 29.571 53.25"
  >
    <g
      id="prefix__Group_645"
      data-name="Group 645"
      transform="translate(-1296.933 -187.754) translate(692.198 -8.855)"
    >
      <g id="prefix__Group_68" data-name="Group 68">
        <g id="prefix__Group_64" data-name="Group 64">
          <path
            id="prefix__Path_65"
            d="M620.122 205.675a3.8 3.8 0 0 1-2.545-.974l-5.145-4.6a2 2 0 0 1-.157-2.824 2 2 0 0 1 2.823-.157l5.024 4.493 5.024-4.493a2 2 0 0 1 2.823.157 2 2 0 0 1-.157 2.824l-5.145 4.6a3.806 3.806 0 0 1-2.545.974z"
            data-name="Path 65"
            style={{ fill: '#050713' }}
          />
        </g>
        <g id="prefix__Group_65" data-name="Group 65">
          <path
            id="prefix__Path_66"
            d="M616.107 221.4h-6.476v-4h6.476a2 2 0 0 1 2 2 2 2 0 0 1-2 2z"
            className="prefix__cls-2"
            data-name="Path 66"
            style={{ fill: color }}
          />
        </g>
        <g id="prefix__Group_66" data-name="Group 66">
          <path
            id="prefix__Path_67"
            d="M618.414 232.517H611.2v-4h7.214a2 2 0 0 1 2 2 2 2 0 0 1-2 2z"
            className="prefix__cls-2"
            data-name="Path 67"
            style={{ fill: color }}
          />
        </g>
        <g id="prefix__Group_67" data-name="Group 67">
          <path
            id="prefix__Path_68"
            d="M628.888 203.493a5.431 5.431 0 0 0-.916-.281 2.024 2.024 0 0 0-2.468 1.976 2.041 2.041 0 0 0 1.525 1.961 3.9 3.9 0 0 1 .841.314 4.827 4.827 0 0 1 2.182 2.614 5 5 0 0 1 .187 2.368l-3.913 27.719a4.783 4.783 0 0 1-4.736 4.114h-2.35a4.783 4.783 0 0 1-4.736-4.114l-3.913-27.719a5.011 5.011 0 0 1 .217-2.452 4.794 4.794 0 0 1 4.5-3.145h6.99a2 2 0 0 0 2-2 2 2 0 0 0-2-2h-6.77a9.1 9.1 0 0 0-4.888 1.337 8.8 8.8 0 0 0-4.028 8.674l3.934 27.864a8.783 8.783 0 0 0 8.7 7.555h2.35a8.782 8.782 0 0 0 8.7-7.555l3.934-27.864a8.837 8.837 0 0 0-5.342-9.366z"
            className="prefix__cls-2"
            data-name="Path 68"
            style={{ fill: color }}
          />
        </g>
      </g>
      <path
        id="prefix__Path_69"
        d="M608.751 242.3a8.783 8.783 0 0 0 8.7 7.555h2.35a8.782 8.782 0 0 0 8.7-7.555l3.934-27.864a8.834 8.834 0 0 0-5.331-9.364 5.523 5.523 0 0 0-.916-.281 2.024 2.024 0 0 0-.943 3.937 3.9 3.9 0 0 1 .841.314 4.826 4.826 0 0 1 2.181 2.614 5 5 0 0 1 .187 2.368l-3.913 27.719a4.782 4.782 0 0 1-4.735 4.114h-2.35a4.782 4.782 0 0 1-4.735-4.114l-1.079-7.645h4.99a2 2 0 0 0 0-4h-5.555l-1-7.113h4.252a2 2 0 0 0 0-4H609.5l-.7-4.96a5 5 0 0 1 .218-2.452 4.793 4.793 0 0 1 4.5-3.145h6.99a1.99 1.99 0 0 0 1.574-3.221l3.943-3.527a2 2 0 0 0 .157-2.824 2 2 0 0 0-2.823-.157l-5.023 4.493-5.026-4.492a2 2 0 0 0-2.666 2.981l3.072 2.748a9.079 9.079 0 0 0-4.867 1.336 8.8 8.8 0 0 0-4.028 8.674z"
        data-name="Path 69"
        style={{ opacity: 0.2, fill: color }}
      />
    </g>
  </svg>
);

CarrotIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

CarrotIcon.defaultProps = {
  color: '#37ead9',
  width: '29.571',
  height: '53.25',
};

export default CarrotIcon;
