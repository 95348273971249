import { Checkbox } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

const CompanyStatusCheckbox = (props) => {
  const {
    handleStatusChange,
    formData,
    property,
    columnName
  } = props;

  return (
    <div>
      <Checkbox
        checked={formData.get(property, false)}
        onChange={() => handleStatusChange(formData.get('id'), formData, columnName)}
      />
    </div>
  );
};

const { func, number, bool } = PropTypes;

const { mapContains } = ImmutablePropTypes;

CompanyStatusCheckbox.propTypes = {
  handleStatusChange: func.isRequired,
  formData: mapContains({
    isVerified: bool.isRequired,
    id: number.isRequired,
  }).isRequired,
};

export default CompanyStatusCheckbox;
