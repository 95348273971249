import React from 'react';

const ArrowDownMedium = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8">
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M10 19c-.234 0-.46-.08-.64-.23-.205-.17-.333-.414-.358-.678-.024-.265.058-.528.228-.732L13.71 12 9.39 6.63c-.168-.207-.246-.472-.218-.736.028-.265.16-.507.368-.674.21-.184.486-.272.763-.244.277.028.53.171.697.394l4.83 6c.303.369.303.901 0 1.27l-5 6c-.203.245-.512.38-.83.36z" transform="matrix(0 1 1 0 -5 -9)" />
    </g>
  </svg>
);

export default ArrowDownMedium;
