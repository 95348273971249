import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import inputStyle from 'utils/inputStyle.module.scss';

const InputMain = ({
  label,
  name,
  type,
  placeholder,
  value,
  handleChange,
  error,
  customStyle,
  id,
  disabled,
  onBlur,
}) => {
  const handle = (event) => {
    event.persist();
    handleChange(event);
  };
  return (
    <div
      className={classNames(
        inputStyle.formGroup,
        'col-11 my-3 mr-auto',
        customStyle,
        error ? inputStyle.validationError : '',
      )}
      id={id}
    >
      <h6 className={inputStyle.error}>{error}</h6>
      <h5 className="px-1">{label}</h5>
      <input
        type={type}
        className={classNames(inputStyle.formControl, 'py-2 my-1')}
        placeholder={placeholder}
        name={name}
        value={value || ''}
        onChange={handle}
        disabled={disabled}
        onBlur={onBlur}
      />
    </div>
  );
};

InputMain.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  customStyle: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
};

InputMain.defaultProps = {
  error: '',
  customStyle: '',
  value: '',
  id: '',
  disabled: false,
  onBlur: () => {},
};

export default InputMain;
