import { Map as IMap } from 'immutable';

const initialState = {
  viewMode: 'view',
  isAuthorized: false,
  formData: IMap(),
};

const setViewMode = (mode) => ({
  type: 'SET_VIEW_MODE',
  payload: { mode },
});

const setIsAuthorized = (isAuthorized) => ({
  type: 'SET_IS_AUTHORIZED',
  payload: { isAuthorized },
});

const setFormData = (formData) => ({
  type: 'SET_FORM_DATA',
  payload: { formData },
});

function reducer(state, action) {
  switch (action.type) {
    case 'SET_VIEW_MODE':
      return {
        ...state,
        viewMode: action.payload.mode,
      };
    case 'SET_IS_AUTHORIZED':
      return {
        ...state,
        isAuthorized: action.payload.isAuthorized,
      };
    case 'SET_FORM_DATA':
      return {
        ...state,
        formData: action.payload.formData,
      };
    default:
      return state;
  }
}

export {
  reducer, initialState, setViewMode, setIsAuthorized, setFormData,
};
