import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import styles from '../../navbar.module.scss';
import { bizbookLogo } from '../../../../images';

const Logo = () => (
  <Link to="/" className={classNames(styles.navbarBrand)}>
    <img
      src={bizbookLogo}
      alt="bizbook"
      className={classNames(styles.navbarTitleLogo, 'mr-2 my-auto')}
    />
    <div>
      <p className={styles.navbarTitleName}>bizbook</p>
    </div>
  </Link>
);

export default Logo;
