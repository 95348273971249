import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import InputContainer from 'utils/InputMain';
import buttonStyle from 'utils/buttonStyle.module.scss';
import useForm from 'utils/hooks/useForm';
import inputStyle from 'utils/inputStyle.module.scss';
import { EyeOutlined } from '@ant-design/icons';
import validate from './validateloginForm';
import styles from '../login.module.scss';

const LoginForm = (props) => {
  const { actions, setIsPaused } = props;
  const { t } = useTranslation();
  const [passwordFieldType, setPasswordFieldType] = useState('password');

  const handleLogin = () => {
    setIsPaused(false);
    actions.login(values);
  };
  const {
    values, errors, handleChange, handleSubmit,
  } = useForm(
    handleLogin,
    validate,
  );
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className={classNames(styles.formContainer, 'px-3   mx-auto')}>
        <InputContainer
          label={t('login input email label')}
          name="email"
          type="email"
          placeholder={t('login input email placeholder')}
          value={values.email}
          handleChange={handleChange}
          error={errors.email}
          customStyle="col-md-12 col-lg-12"
        />
        <div
          className={classNames(
            inputStyle.formGroup,
            'col-12  my-4 mx-5 mx-auto',
            errors.password ? inputStyle.validationError : '',
          )}
        >
          <h6 className={inputStyle.error}>{errors.password}</h6>
          <h5 className="px-1">{t('login input password label')}</h5>
          <div className={inputStyle.passwordContainer}>
            <input
              type={passwordFieldType}
              className={classNames(inputStyle.formControl, 'py-2 my-1')}
              placeholder={t('login input password placeholder')}
              name="password"
              value={values.password || ''}
              onChange={handleChange}
            />
            <EyeOutlined
              onClick={() => setPasswordFieldType(
                passwordFieldType === 'password' ? 'text' : 'password',
              )}
              className={classNames(
                inputStyle.passwordInputImgStyle,
                passwordFieldType !== 'password' ? 'text-info' : '',
              )}
            />
          </div>
        </div>
        <button
          type="submit"
          className={classNames(
            buttonStyle.buttonSuccessStyle,
            'my-3 col-12',
            styles.buttonSuccessStyle,
          )}
        >
          {t('login buttonText')}
        </button>
      </div>
    </form>
  );
};

const { shape, func } = PropTypes;

LoginForm.propTypes = {
  actions: shape({
    login: func.isRequired,
  }).isRequired,
  setIsPaused: func.isRequired,
};

export default LoginForm;
