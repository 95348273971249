import {
  all, call, takeLatest, put,
} from 'redux-saga/effects';
import i18n from 'i18next';
import { notification } from 'antd';
import { getCatalogsService, addCatalogService, deleteCatalogService } from 'services/catalogService';
import { uploadService } from 'services/cloudinaryService';
import { types, setUploadedCatalog, setCompanyCatalogs } from './actions';

function * uploadCatalog(action) {
  const { catalogData } = action.payload;
  const formData = new FormData();
  formData.append('file', catalogData.file);
  formData.append('upload_preset', 'bizbook-catalog');
  const { data, error } = yield call(uploadService, '', formData);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setUploadedCatalog({ ...data, name: catalogData.file.name }));
  }
}

function * getCompanyCatalogs(action) {
  const companySlug = action && action.payload ? action.payload.companySlug : action;
  const { data, error } = yield call(getCatalogsService, companySlug);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setCompanyCatalogs(data));
  }
}

function * addCompanyCatalog(action) {
  const { error } = yield call(addCatalogService, action.payload);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield getCompanyCatalogs(action.payload.companySlug);
  }
}

function * deleteCatalog(action) {
  const { error } = yield call(deleteCatalogService, action.payload.id);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
      description: i18n.t('uploadCatalog catalogDeletedErrorMessage'),
    });
    yield getCompanyCatalogs(action.payload.companySlug);
  }
}

export default function * companyCatalogSaga() {
  yield all([
    takeLatest(types.UPLOAD_CATALOG, uploadCatalog),
    takeLatest(types.GET_COMPANY_CATALOGS, getCompanyCatalogs),
    takeLatest(types.ADD_CATALOG, addCompanyCatalog),
    takeLatest(types.DELETE_CATALOG, deleteCatalog),
  ]);
}
