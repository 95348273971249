import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import InputContainer from 'utils/InputMain';
import buttonStyle from 'utils/buttonStyle.module.scss';
import useForm from 'utils/hooks/useForm';
import validate from '../validateEmailForm';
import styles from '../login.module.scss';

const ForgotPassword = (props) => {
  const { actions, setIsPaused, setForgotPasswordSection } = props;
  const { t } = useTranslation();

  const sendEmail = () => {
    setIsPaused(false);
    actions.requestPasswordReset(values.email);
    setForgotPasswordSection(false);
  };

  const {
    values, errors, handleChange, handleSubmit,
  } = useForm(
    sendEmail,
    validate,
  );
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className={classNames(styles.formContainer, 'px-3   mx-auto')}>
        <InputContainer
          label={t('login input email label')}
          name="email"
          type="email"
          placeholder={t('login input email placeholder')}
          value={values.email}
          handleChange={handleChange}
          error={errors.email}
          customStyle="col-md-12 col-lg-12"
        />
        <button
          type="submit"
          className={classNames(
            buttonStyle.buttonSuccessStyle,
            'my-3 col-12',
            styles.buttonSuccessStyle,
          )}
        >
          {t('login forgotPassword buttonText')}
        </button>
      </div>
    </form>
  );
};

const { shape, func } = PropTypes;

ForgotPassword.propTypes = {
  actions: shape({
    requestPasswordReset: func.isRequired,
  }).isRequired,
  setIsPaused: func.isRequired,
  setForgotPasswordSection: func.isRequired,
};

export default ForgotPassword;
