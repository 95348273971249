import React from 'react';

const BellIcon = () => (
  <svg
    width="18px"
    height="20px"
    viewBox="0 0 18 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <title>Group 12</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Guidelines"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Another-user's-navbar-active-links"
        transform="translate(-1021.000000, -29.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="Group-20">
          <g id="Group-13" transform="translate(149.000000, 0.000000)">
            <g id="Group-8" transform="translate(806.000000, 17.000000)">
              <g id="Group-12" transform="translate(66.000000, 12.000000)">
                <g id="bell-outline">
                  <path
                    d="M17.52,13.21 L15.72,11.4 L15.72,6.94 C15.769519,3.51534505 13.2854794,0.578885952 9.9,0.06 C7.97776262,-0.193144814 6.03960977,0.39375881 4.58072541,1.67076126 C3.12184105,2.94776371 2.28353924,4.79116883 2.28,6.73 L2.28,11.4 L0.48,13.21 C0.0177621425,13.6799467 -0.118187013,14.3809745 0.134877646,14.9896387 C0.387942305,15.5983028 0.980833812,15.9963001 1.64,16 L5,16 L5,16.34 C5.0972515,18.4536777 6.88601812,20.0903992 9,20 C11.1139819,20.0903992 12.9027485,18.4536777 13,16.34 L13,16 L16.36,16 C17.0191662,15.9963001 17.6120577,15.5983028 17.8651224,14.9896387 C18.118187,14.3809745 17.9822379,13.6799467 17.52,13.21 Z M11,16.34 C10.8847846,17.3392821 10.0034056,18.0708267 9,18 C7.99659441,18.0708267 7.11521537,17.3392821 7,16.34 L7,16 L11,16 L11,16.34 Z M2.51,14 L3.69,12.82 C4.0680394,12.4442036 4.28042238,11.9330446 4.28,11.4 L4.28,6.73 C4.2811021,5.36518715 4.87168113,4.06737151 5.9,3.17 C6.91482171,2.25050644 8.28208833,1.82277865 9.64,2 C12.0311278,2.38824478 13.7712522,4.47810005 13.72,6.9 L13.72,11.4 C13.7169265,11.931606 13.925627,12.4425623 14.3,12.82 L15.49,14 L2.51,14 Z"
                    id="Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default BellIcon;
