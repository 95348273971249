import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import styles from '../../adminPanel.module.scss';
import { packageOptions } from '../constants';

const { Option } = Select;

const SelectSubscriptionPackage = (props) => {
  const {
    handleOnSelect,
    companyId,
    data,
    columnName,
  } = props;
  const [activePackage, setActivePackage] = useState('');

  const selectedPackage = packageOptions.find((option) => option.value === activePackage);

  useEffect(() => {
    setActivePackage(data.get('packageId'));
  }, [data]);

  const handleSelectPackage = (cId, val, formData, cName) => {
    setActivePackage(val);
    handleOnSelect(cId, val, formData, cName);
  };

  return (
    <Select
      value={selectedPackage?.name}
      bordered={false}
      className={styles.selectStyle}
      onSelect={(e) => handleSelectPackage(companyId, e, data, columnName)}
    >
      {packageOptions.map((singlePackage) => (
        <Option value={singlePackage.value} key={singlePackage.value}>
          {singlePackage.name}
        </Option>
      ))}
    </Select>
  );
};

const { func, string, number } = PropTypes;

const { mapContains } = ImmutablePropTypes;

SelectSubscriptionPackage.propTypes = {
  handleOnSelect: func.isRequired,
  companyId: number.isRequired,
  columnName: string.isRequired,
  data: mapContains({
    packageId: number.isRequired,
  }).isRequired,
};

export default SelectSubscriptionPackage;
