import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import privacyPolicies from './privacyPolicies';
import styles from './privacyPolicies.module.scss';

const PrivacyPolicies = (props) => {
  const { activeLanguage } = props;
  const { t } = useTranslation();
  const [displayPrivacyPolicies, setPrivacyPolicies] = useState(
    privacyPolicies(),
  );
  useEffect(() => {
    setPrivacyPolicies(privacyPolicies());
  }, [activeLanguage]);
  return (
    <div className={classNames('container', styles.privacyPoliciesContainer)}>
      <h2>{t('privacyPolicies title')}</h2>
      <div>
        {displayPrivacyPolicies.map((item) => (
          <div key={item.id}>
            <h5>{item.title}</h5>
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const { string } = PropTypes;

PrivacyPolicies.propTypes = {
  activeLanguage: string.isRequired,
};

export default PrivacyPolicies;
