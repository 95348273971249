import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import { Prompt } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import buttonStyles from 'utils/buttonStyle.module.scss';
import styles from './unsavedChangesPrompt.module.scss';
import history from '../../../history';

const UnsavedChangesPrompt = (props) => {
  const {
    nextLocation,
    when,
    openModal,
    confirmedNavigation,
    actions,
  } = props;
  const { t } = useTranslation();

  const closeModal = () => {
    actions.setOpenModal(false);
  };

  const handleBlockedNavigation = (newLocation) => {
    if (!confirmedNavigation) {
      actions.setOpenModal(true);
      actions.setNextLocation(newLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    actions.setOpenModal(false);
    actions.setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && nextLocation) {
      actions.setConfirmedNavigation(false);
      history.push(nextLocation.pathname);
    }
  }, [confirmedNavigation, nextLocation, actions]);

  return (
    <div>
      <Prompt when={when} message={handleBlockedNavigation} />
      {openModal
      && (
      <Modal isOpen={openModal} centered>
        <div className={classNames(styles.modalBody)}>
          <p>{t('unsavedChangesPromptMessage')}</p>
          <button className={classNames(buttonStyles.buttonSuccessStyle)} type="button" onClick={closeModal}>{t('goBackPrompt')}</button>
          <button className={classNames(buttonStyles.buttonSuccessStyle)} type="button" onClick={handleConfirmNavigationClick}>{t('continuePrompt')}</button>
        </div>
      </Modal>
      )}
    </div>
  );
};

const {
  string,
  func,
  bool,
  shape,
} = PropTypes;

UnsavedChangesPrompt.defaultProps = {
  nextLocation:{
    pathname: '',
  },
};

UnsavedChangesPrompt.propTypes = {
  nextLocation: shape({
    pathname: string,
  }),
  when: bool.isRequired,
  openModal: bool.isRequired,
  confirmedNavigation: bool.isRequired,
  actions: shape({
    setOpenModal: func.isRequired,
    setNextLocation: func.isRequired,
    setConfirmedNavigation: func.isRequired,
  }).isRequired,
};

export default UnsavedChangesPrompt;
