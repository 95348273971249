import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { layoutPositions } from 'utils/parse-helper';
import { List as iList, fromJS } from 'immutable';
import { notification } from 'antd';
import { createSelector } from 'reselect';
import EditBar from '../modules/utils/EditBar';
import ProductItem from './ProductItem';
import SelectIcon from '../AddIcon';
import styles from './productsAndServices.module.scss';

const ProductsAndServices = (props) => {
  const {
    productsAndServices,
    saveChange,
    textAlignment,
    colorTheme,
    actions,
    modalVisibilityProductsAndServices,
    fontFamily,
  } = props;
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState('edit');
  const [activeCard, setActiveCard] = useState({ iconName: '' });
  const [productsData, setProductsData] = useState({
    productsAndServices,
    textAlignment,
  });
  const [changesSaved, setChangesSavedStatus] = useState(false);
  const [unsavedCards, setUnsavedCards] = useState([]);
  const isUnfinished = (product) => (
    (product.get('title')
    || product.get('description')
    || product.get('iconName'))
    && (!product.get('title')
    || !product.get('description')
    || !product.get('iconName'))
  );
  const checkUnsavedCard = (itemKey, newProducts) => {
    const product = newProducts?.productsAndServices?.filter((item) => item.get('key') === itemKey).first();
    if (product) {
      if (isUnfinished(product)) {
        setUnsavedCards([...unsavedCards.filter((unsavedCard) => unsavedCard !== product.get('key')), product.get('key')]);
      } else {
        setUnsavedCards([...unsavedCards.filter((unsavedCard) => unsavedCard !== product.get('key'))]);
      }
    }
  };
  const sectionDidChange = (change) => {
    actions.setChangesMade(change);
    setChangesSavedStatus(!change);
  };
  const changeProductsData = (item) => {
    setProductsData({
      ...productsData,
      productsAndServices: productsData.productsAndServices.set(
        item.key,
        fromJS({ ...item }),
      ),
    });
    checkUnsavedCard(item.key, {
      ...productsData,
      productsAndServices: productsData.productsAndServices.set(
        item.key,
        fromJS({ ...item }),
      ),
    });
    setChangesSavedStatus(false);
  };
  useEffect(() => {
    setProductsData({ productsAndServices, textAlignment });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAndServices, textAlignment]);
  const saveChanges = () => {
    if (unsavedCards.length > 0) {
      notification.error({
        message: t('notification error'),
        description: t('createCompany cardDataUnsaved'),
      });
    }
    saveChange('productsAndServices', productsData);
    sectionDidChange(false);
  };
  const setTextAlignment = (alignment) => {
    setProductsData({ ...productsData, textAlignment: alignment });
    sectionDidChange(true);
  };
  const filterProductsAndServicesSelector = createSelector(
    () => productsData.productsAndServices,
    (items) => items.filter(
      (item) => item.get('title', '') && item.get('description', ''),
    ),
  );
  const filteredSizeProductsAndServicesSelector = createSelector(
    filterProductsAndServicesSelector,
    (items) => items.size,
  );
  const handleIconsSave = (icon) => {
    setProductsData({
      ...productsData,
      productsAndServices: productsData.productsAndServices.setIn(
        [activeCard.key],
        fromJS({ ...activeCard, iconName: icon }),
      ),
    });
    sectionDidChange(true);
  };

  return (
    <div
      className={classNames(
        'container',
        styles.productsAndServicesMainContainer,
      )}
    >
      <h4 style={{ textAlign: `${productsData.textAlignment.split('-')[1]}` }}>
        {t('createCompany productsAndServices headerTitleText')}
      </h4>
      <h3
        style={{
          color: colorTheme,
          fontFamily,
          textAlign: `${productsData.textAlignment.split('-')[1]}`,
        }}
      >
        {t(
          'createCompany productsAndServices aboutProductsAndServices titleText',
        )}
      </h3>
      {unsavedCards.length > 0 && viewMode === 'edit' && (
        <div className={styles.cardUnsavedAlert}>
          {t('createCompany cardDataUnsaved')}
        </div>
      )}
      <div className={styles.productsAndServicesContainer}>
        {productsData.productsAndServices
          && (viewMode === 'edit'
            ? productsData.productsAndServices
            : filterProductsAndServicesSelector(
              productsData.productsAndServices,
            )
          ).map((item) => (
            <div
              className={classNames(
                'col-12',
                layoutPositions(
                  viewMode === 'edit'
                    ? productsData.productsAndServices.size
                    : filteredSizeProductsAndServicesSelector(
                      productsData.productsAndServices,
                    ),
                ),
              )}
              key={item.get('key', '')}
            >
              <ProductItem
                textAlignment={productsData.textAlignment}
                viewMode={viewMode}
                title={item.get('title', '')}
                description={item.get('description')}
                itemKey={item.get('key', '')}
                setProductsData={changeProductsData}
                setActiveCard={setActiveCard}
                iconName={item.get('iconName', '')}
                colorTheme={colorTheme}
                showModal={actions.showModal}
                unsavedCard={unsavedCards?.includes(item.get('key', ''))}
                actions={actions}
              />
            </div>
          ))}
      </div>
      <EditBar
        textAlignment={productsData.textAlignment}
        viewMode={viewMode}
        setTextAlignment={setTextAlignment}
        setViewMode={setViewMode}
        saveChanges={saveChanges}
        changesSaved={changesSaved}
      />
      <SelectIcon
        modalType="productsAndServicesIconSelect"
        modalVisibility={modalVisibilityProductsAndServices}
        handleIconSave={handleIconsSave}
        iconName={activeCard.iconName}
      />
    </div>
  );
};

const {
  node, func, string, shape, bool,
} = PropTypes;

ProductsAndServices.propTypes = {
  productsAndServices: node,
  saveChange: func.isRequired,
  textAlignment: string.isRequired,
  actions: shape({
    showModal: func.isRequired,
    setChangesMade: func.isRequired,
  }).isRequired,
  colorTheme: string.isRequired,
  modalVisibilityProductsAndServices: bool.isRequired,
  fontFamily: string.isRequired,
};

ProductsAndServices.defaultProps = {
  productsAndServices: iList(),
};

export default ProductsAndServices;
