import React from 'react';
import { string } from 'prop-types';

const SheepIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 52.794 36.897"
  >
    <g
      id="prefix__Group_652"
      data-name="Group 652"
      transform="translate(-1126.692 -333.963) translate(712.198 -28.855)"
    >
      <g id="prefix__Group_32" data-name="Group 32">
        <g id="prefix__Group_29" data-name="Group 29">
          <path
            id="prefix__Path_40"
            d="M466.277 383.259h-3.49a13.841 13.841 0 0 1-13.841-13.841v-2h4.5a13.842 13.842 0 0 1 13.842 13.842v.989a1.011 1.011 0 0 1-1.011 1.01zm-13.127-11.841a9.858 9.858 0 0 0 9.637 7.841h.3a9.858 9.858 0 0 0-9.637-7.841z"
            className="prefix__cls-1"
            data-name="Path 40"
            style={{ fill: color }}
          />
        </g>
        <g id="prefix__Group_30" data-name="Group 30">
          <path
            id="prefix__Path_41"
            d="M420.788 383.259H417.3a1.01 1.01 0 0 1-1.01-1.01v-.99a13.841 13.841 0 0 1 13.841-13.841h4.5v2a13.843 13.843 0 0 1-13.843 13.841zm-.3-4h.3a9.858 9.858 0 0 0 9.637-7.841h-.3a9.859 9.859 0 0 0-9.633 7.841z"
            className="prefix__cls-1"
            data-name="Path 41"
            style={{ fill: color }}
          />
        </g>
        <g id="prefix__Group_31" data-name="Group 31">
          <path
            id="prefix__Path_42"
            d="M443.17 398.132h-3.907a5.662 5.662 0 0 1-5.547-4.568l-3.67-18.966a11.78 11.78 0 0 1 23.56 0v.257l-4.966 19.051a5.656 5.656 0 0 1-5.47 4.226zm-9.123-23.717l3.594 18.381a1.656 1.656 0 0 0 1.622 1.336h3.907a1.653 1.653 0 0 0 1.6-1.236l4.833-18.54a7.78 7.78 0 0 0-15.555.059z"
            data-name="Path 42"
            style={{ fill: '#050713' }}
          />
        </g>
      </g>
      <path
        id="prefix__Path_43"
        d="M429.082 380.461l2.842 14.686a5.664 5.664 0 0 0 5.547 4.568h3.907a5.652 5.652 0 0 0 5.469-4.227l3.95-15.152a13.787 13.787 0 0 0 10.2 4.506h3.49a1.01 1.01 0 0 0 1.01-1.01v-.989A13.842 13.842 0 0 0 451.653 369h-2.3a11.738 11.738 0 0 0-18.638 0h-2.378a13.841 13.841 0 0 0-13.841 13.841v.99a1.01 1.01 0 0 0 1.01 1.01h3.49a13.79 13.79 0 0 0 10.086-4.38zM451.654 373a9.858 9.858 0 0 1 9.637 7.841H461a9.857 9.857 0 0 1-9.412-6.97c-.059-.3-.137-.584-.218-.871zm-11.62-4.6a7.79 7.79 0 0 1 7.418 5.45c.055.262.129.516.2.772a7.813 7.813 0 0 1 .161 1.316l-4.833 18.54a1.652 1.652 0 0 1-1.6 1.236h-3.907a1.657 1.657 0 0 1-1.622-1.336L432.256 376a7.789 7.789 0 0 1 7.778-7.6zM418.7 380.842a9.859 9.859 0 0 1 9.638-7.842h.3a9.858 9.858 0 0 1-9.638 7.842z"
        data-name="Path 43"
        style={{ opacity: 0.2, fill: color }}
      />
    </g>
  </svg>
);

SheepIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

SheepIcon.defaultProps = {
  color: '#37ead9',
  width: '52.794',
  height: '36.897',
};

export default SheepIcon;
