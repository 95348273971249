/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { arrowRight } from 'images/createCompany';
import { textInputMaxLength, handleRedirect } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import EditBar from '../modules/utils/EditBar';
import styles from './productsAndServicesHeader.module.scss';

const ProductsAndServicesHeader = (props) => {
  const {
    title,
    description,
    saveChange,
    textAlignment,
    colorTheme,
    fontFamily,
    actions,
    activeCompanySlug,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const showProducts = () => {
    handleRedirect(history, `/products/${activeCompanySlug}`, actions);
  };
  const [viewMode, setViewMode] = useState('edit');
  const [formData, setFormData] = useState({
    title,
    description,
    textAlignment,
  });
  const [changesSaved, setChangesSavedStatus] = useState(false);
  const sectionDidChange = (change) => {
    actions.setChangesMade(change);
    setChangesSavedStatus(!change);
  };
  const updateForm = (property, value) => {
    const newFormData = { ...formData };
    if (property) newFormData[property] = value;
    setFormData(newFormData);
    sectionDidChange(true);
  };
  const saveChanges = () => {
    saveChange('productsAndServicesHeader', {
      ...formData,
      title:
      formData.title
      || (formData.description.length > 0
      && 'Saznajte više') || '',
    });
    sectionDidChange(false);
  };
  useEffect(() => {
    setFormData({ title, description, textAlignment });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description, title, textAlignment]);
  const setTextAlignment = (alignment) => {
    updateForm('textAlignment', alignment);
  };
  return (
    <div className={styles.productsAndServicesHeaderContainer}>
      <div
        className={classNames(
          'container',
          styles.productsAndServicesHeaderContent,
          formData.textAlignment === 'text-center'
            ? 'align-items-center'
            : formData.textAlignment === 'text-right'
              ? 'align-items-end'
              : '',
        )}
      >
        <h4>{t('createCompany productsAndServices headerTitleText')}</h4>
        {viewMode === 'edit' ? (
          <input
            type="text"
            placeholder={t(
              'createCompany productsAndServices inputTitleText placeholder',
            )}
            name="sectionTitle"
            className={classNames(
              styles.inputSectionTitle,
              formData.textAlignment,
              viewMode === 'view' ? styles.noBorderStyle : '',
            )}
            value={formData.title}
            onChange={(e) => {
              updateForm('title', e.target.value);
            }}
            style={{ color: colorTheme, fontFamily }}
            maxLength={70}
          />
        ) : (
          <h2
            className={classNames(
              styles.inputSectionTitle,
              formData.textAlignment,
              styles.sectionTextViewMode,
            )}
            style={{ color: colorTheme, fontFamily }}
          >
            {formData.title}
          </h2>
        )}

        <div className={classNames(styles.horizontalBreakline, 'mx-2')} />
        <div
          className={classNames(
            styles.flexContainer,
            formData.textAlignment === 'text-center'
              ? 'ml-auto'
              : formData.textAlignment === 'text-right'
                ? 'flex-row-reverse'
                : '',
          )}
        >
          <div className={styles.descriptionSectionContainer}>
            <p
              className={classNames(
                styles.textCounterContainer,
                viewMode === 'view' && 'd-none',
              )}
            >
              {`${formData.description.length}/${textInputMaxLength}`}
            </p>
            {viewMode === 'edit' ? (
              <textarea
                type="text"
                placeholder={t(
                  'createCompany productsAndServices inputDescription placeholder',
                )}
                name="sectionShortDescription"
                className={classNames(
                  styles.inputSectionDescription,
                  formData.textAlignment,
                  viewMode === 'view' ? styles.noBorderStyle : '',
                )}
                rows={4}
                value={formData.description}
                onChange={(e) => {
                  updateForm('description', e.target.value);
                }}
                maxLength={textInputMaxLength}
              />
            ) : (
              <h5
                className={classNames(
                  styles.inputSectionDescription,
                  formData.textAlignment,
                  styles.sectionTextViewMode,
                )}
              >
                {formData.description}
              </h5>
            )}
          </div>

          <div className={styles.navigationRightContainer} role="presentation" onClick={showProducts}>
            <h5>
              {t(
                'createCompany productsAndServices navigationRight subtitleText',
              )}
              <img
                src={arrowRight}
                alt="Our products and services"
                className="ml-1"
              />
            </h5>
            <h3>
              {t('createCompany productsAndServices navigationRight titleText')}
            </h3>
          </div>
        </div>
      </div>
      <div className={classNames('container', styles.customEditBarStyle)}>
        <EditBar
          textAlignment={formData.textAlignment}
          viewMode={viewMode}
          setTextAlignment={setTextAlignment}
          setViewMode={setViewMode}
          saveChanges={saveChanges}
          changesSaved={changesSaved}
        />
      </div>
    </div>
  );
};

const {
  string, func, shape,
} = PropTypes;

ProductsAndServicesHeader.propTypes = {
  title: string,
  description: string,
  saveChange: func.isRequired,
  textAlignment: string.isRequired,
  colorTheme: string.isRequired,
  fontFamily: string.isRequired,
  activeCompanySlug: string,
  actions: shape({
    setChangesMade: func.isRequired,
  }).isRequired,
};
ProductsAndServicesHeader.defaultProps = {
  title: '',
  description: '',
  activeCompanySlug: '',
};

export default ProductsAndServicesHeader;
