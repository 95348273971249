import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Upload, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { loader, imagePlaceholder } from 'images';
import classNames from 'classnames';
import { List as IList } from 'immutable';
import { PictureOutlined } from '@ant-design/icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImgCrop from 'antd-img-crop';
import styles from './uploadImageComponent.module.scss';
import { imageUploadMaxLimit } from '../../../utils/constants';

const UploadImageComponent = (props) => {
  const {
    imageKey,
    imageUrl,
    rowStyleImageUpload,
    uploadNewImage,
    images,
  } = props;
  const { t } = useTranslation();
  const [uploading, setUploadingStatus] = useState(false);

  const handleImageUpload = (data) => {
    if (data.file.status === 'uploading') {
      setUploadingStatus(true);
    }
  };
  const renderImageComponent = (isUploading, imgUrl) => {
    if (isUploading) {
      return (
        <div className={styles.loaderContainer}>
          <LazyLoadImage
            alt="Loader"
            effect="blur"
            src={loader}
            className={styles.loaderImgStyle}
          />
        </div>
      );
    }
    return (
      <LazyLoadImage
        alt="Add img"
        effect="blur"
        src={imgUrl || imagePlaceholder}
        className={classNames(
          styles.mainImageStyle,
          rowStyleImageUpload && styles.rowImageStyle,
        )}
        placeholderSrc={imagePlaceholder}
      />
    );
  };

  useEffect(() => {
    setUploadingStatus(false);
  }, [imageUrl, images]);

  return (
    <div
      className={classNames(
        styles.imageUploadContainer,
        rowStyleImageUpload && styles.customMargin,
      )}
    >
      {renderImageComponent(uploading, imageUrl)}
      <ImgCrop
        modalTitle={t('imageCropperModal modalTitle')}
        modalOk={t('imageCropperModal okButton')}
        modalCancel={t('imageCropperModal cancelButton')}
        grid={false}
        shape="rect"
        fillColor="rgba(255, 255, 255, 0.0)"
        minZoom={0.5}
        cropperProps={{ restrictPosition: false }}
      >
        <Upload
          name="avatar"
          listType="picture-card"
          showUploadList={false}
          onChange={handleImageUpload}
          className={classNames(
            styles.imageUploadButtonContainer,
            rowStyleImageUpload && styles.rowStyledButton,
          )}
          beforeUpload={(file) => {
            const isImageFile = file.type.includes('image');
            if (!isImageFile) {
              notification.error({
                message: t('notification error'),
                description: t('imageUpload typeImageError'),
              });
              setTimeout(() => setUploadingStatus(false), 1000);
            }
            return isImageFile;
          }}
          customRequest={(options) => {
            const isLimitSizeExceeded = options.file.size / 1024 / 1024 >= imageUploadMaxLimit;
            if (isLimitSizeExceeded) {
              notification.error({
                message: t('notification error'),
                description: t('imageUpload fileTooLarge'),
              });
              setTimeout(() => setUploadingStatus(false), 1000);
            } else if (imageKey || images.size === 0) {
              options.onSuccess(uploadNewImage('0', options));
            } else {
              options.onSuccess(
                uploadNewImage(images.size.toString(), options),
              );
            }
          }}
        >
          <button
            type="button"
            className={classNames(
              styles.addPictureButtonStyle,
              uploading && 'invisible',
            )}
          >
            {imageUrl
              ? t('aboutCompany uploadPicture changePicture')
              : t('aboutCompany uploadPicture addPicture')}
            <PictureOutlined className={styles.imageOutlinedStyle} />
          </button>
        </Upload>
      </ImgCrop>
    </div>
  );
};

const { string, func, bool } = PropTypes;
const { list } = ImmutablePropTypes;

UploadImageComponent.propTypes = {
  imageUrl: string,
  imageKey: string,
  rowStyleImageUpload: bool,
  uploadNewImage: func.isRequired,
  images: list,
};

UploadImageComponent.defaultProps = {
  rowStyleImageUpload: false,
  imageUrl: '',
  imageKey: '',
  images: IList(),
};

export default UploadImageComponent;
