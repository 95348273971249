import React from 'react';
import { string } from 'prop-types';

const TreeIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 34.594 52.878"
  >
    <g
      id="prefix__Group_644"
      data-name="Group 644"
      transform="translate(-1144.059 -181.98) translate(712.198 -8.855)"
    >
      <g id="prefix__Group_62" data-name="Group 62">
        <g id="prefix__Group_58" data-name="Group 58">
          <path
            id="prefix__Path_60"
            d="M455.858 194.208a6.609 6.609 0 0 0-5.836-3.373 7.02 7.02 0 0 0-5.946 3.678c-6.917 12.169-10.424 20.54-10.424 24.885a16.423 16.423 0 0 0 10.206 15.182 2.016 2.016 0 0 0 2.806-1.843 2 2 0 0 0-1.254-1.851 12.409 12.409 0 0 1-7.758-11.488c0-3.508 3.641-11.912 9.993-23.069a2.851 2.851 0 0 1 2.1-1.478 2.662 2.662 0 0 1 2.635 1.337c6.4 11.229 10.073 19.688 10.073 23.21a12.415 12.415 0 0 1-12.4 12.4 12.21 12.21 0 0 1-1.886-.158.1.1 0 0 0-.114.1v3.836a.1.1 0 0 0 .089.1 16.3 16.3 0 0 0 18.313-16.276c.001-4.382-3.564-12.858-10.597-25.192z"
            data-name="Path 60"
            style={{ fill: color }}
          />
        </g>
        <g id="prefix__Group_59" data-name="Group 59">
          <path
            id="prefix__Path_61"
            d="M452.054 242.13h-4v-29.863a2 2 0 0 1 2-2 2 2 0 0 1 2 2z"
            className="prefix__cls-2"
            data-name="Path 61"
            style={{ fill: '#050713' }}
          />
        </g>
        <g id="prefix__Group_60" data-name="Group 60">
          <path
            id="prefix__Path_62"
            d="M447.14 221.26l-3.447-3.447a2 2 0 0 1 0-2.828 2 2 0 0 1 2.828 0l3.447 3.447a2 2 0 0 1 0 2.828 2 2 0 0 1-2.828 0z"
            className="prefix__cls-2"
            data-name="Path 62"
            style={{ fill: '#050713' }}
          />
        </g>
        <g id="prefix__Group_61" data-name="Group 61">
          <path
            id="prefix__Path_63"
            d="M449.968 227.535a2 2 0 0 1 0-2.828l3.447-3.446a2 2 0 0 1 2.828 0 2 2 0 0 1 0 2.828l-3.447 3.446a2 2 0 0 1-2.828 0z"
            className="prefix__cls-2"
            data-name="Path 63"
            style={{ fill: '#050713' }}
          />
        </g>
      </g>
      <path
        id="prefix__Path_64"
        d="M442.067 236.164a2 2 0 1 0 1.552-3.694 12.409 12.409 0 0 1-7.758-11.488c0-3.508 3.641-11.912 9.993-23.069a2.851 2.851 0 0 1 2.1-1.478 2.662 2.662 0 0 1 2.635 1.337c6.4 11.229 10.073 19.688 10.073 23.21a12.41 12.41 0 0 1-10.4 12.225v-3.626a1.979 1.979 0 0 0 .742-.463l3.447-3.446a2 2 0 0 0-2.828-2.828l-1.361 1.361V213.85a2 2 0 0 0-2-2 2 2 0 0 0-2 2v4.25l-1.533-1.533a2 2 0 0 0-2.828 0 2 2 0 0 0 0 2.828l3.447 3.447a1.977 1.977 0 0 0 .914.514v20.356h4v-6.465a16.417 16.417 0 0 0 14.4-16.267c0-4.381-3.566-12.856-10.6-25.19a6.609 6.609 0 0 0-5.835-3.373 7.022 7.022 0 0 0-5.946 3.678c-6.917 12.169-10.424 20.54-10.424 24.885a16.422 16.422 0 0 0 10.21 15.184z"
        data-name="Path 64"
        style={{ opacity: 0.2, fill: color }}
      />
    </g>
  </svg>
);

TreeIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

TreeIcon.defaultProps = {
  color: '#37ead9',
  width: '34.594',
  height: '52.878',
};

export default TreeIcon;
