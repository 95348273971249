import i18n from 'i18next';

const testimonials = () => ([
  {
    key: 1,
    name: i18n.t('testimonials firstCustomer name'),
    position: i18n.t('testimonials firstCustomer position'),
    company: i18n.t('testimonials firstCustomer company'),
    text: i18n.t('testimonials firstCustomer text'),
  },
  {
    key: 2,
    name: i18n.t('testimonials secondCustomer name'),
    position: i18n.t('testimonials secondCustomer position'),
    company: i18n.t('testimonials secondCustomer company'),
    text: i18n.t('testimonials secondCustomer text'),
  },
  {
    key: 3,
    name: i18n.t('testimonials thirdCustomer name'),
    position: i18n.t('testimonials thirdCustomer position'),
    company: i18n.t('testimonials thirdCustomer company'),
    text: i18n.t('testimonials thirdCustomer text'),
  },
  {
    key: 4,
    name: i18n.t('testimonials fourthCustomer name'),
    position: i18n.t('testimonials fourthCustomer position'),
    company: i18n.t('testimonials fourthCustomer company'),
    text: i18n.t('testimonials fourthCustomer text'),
  },
]);

export default testimonials;
