import { parseError } from 'utils/parse-helper';
import { StrapiService } from './apiService';

import { categories } from '../components/News/utils/categories';

const articlesUrl = '/articles';

export const getArticlesService = async () => StrapiService.get(`${articlesUrl}?_sort=date:DESC`)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

const categoryFilter = (category) => {
  const categoryNames = categories
    .slice(1)
    .reduce((acc, n) => {
      acc[n.value] = true;
      return acc;
    }, {});
  if (!categoryNames[category]) {
    return '';
  }
  return `&_where[0][category.slug]=${category}`;
};

export const getForYouArticlesService = async (category) => StrapiService.get(`${articlesUrl}?_sort=viewCount:DESC${categoryFilter(category)}`)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const increaseViewCountService = async (id, data) => StrapiService.put(`${articlesUrl}/${id}`, data)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));
