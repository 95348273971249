import React from 'react';
import { string, bool, func } from 'prop-types';

import classnames from 'classnames';
import styles from '../categoryBanner.module.scss';

const CategoryTag = (props) => (
  <button
    type="button"
    onClick={() => props.setSelectedCategory({ category: props.value })}
    className={classnames(styles.categoryTag, props.selected && styles.selected)}
  >
    <span>{props.category}</span>
  </button>
);

CategoryTag.propTypes = {
  category: string.isRequired,
  selected: bool.isRequired,
  value: string.isRequired,
  setSelectedCategory: func.isRequired,
};

export default CategoryTag;
