import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import buttonStyle from 'utils/buttonStyle.module.scss';
import ItemDetailsSection from '../ItemDetailsSection';
import RecommendsBanner from '../RecommendsBanner';
import styles from '../../subscriptionPackages.module.scss';

const SubscriptionPackage = (props) => {
  const { t } = useTranslation();
  const {
    item,
    showModal,
  } = props;

  const isRecommended = useMemo(() => item.packageType === 'standard', [item]);

  return (
    <div className={classnames(
      styles.packageContainer,
      isRecommended ? styles.recommendedPackageContainer : styles.defaultPackageContainer,
    )}
    >
      <RecommendsBanner isRecommended={isRecommended} />
      <div className={styles.packageContent}>
        <h1 className={classnames(
          styles.packageTitle,
          isRecommended ? styles.recommended : styles.default,
        )}
        >
          {t(`subscriptionPackageItem ${item.packageType} title`)}
        </h1>
        <p className={classnames(
          styles.packageDescription,
          isRecommended ? styles.recommended : styles.default,
        )}
        >
          {t(`subscriptionPackageItem ${item.packageType} description`)}
        </p>
        <span className={classnames(
          styles.packagePrice,
          isRecommended ? styles.recommended : styles.default,
        )}
        >
          {t(`subscriptionPackageItem ${item.packageType} price`)}
        </span>
        <div className={classnames(
          styles.divider,
          isRecommended ? styles.recommended : styles.default,
        )}
        />
        <div className={isRecommended ? styles.recommendedIncludedItems : styles.includedItems}>
          {Object.keys(item.packageIncludes).map((includedItem) => (
            <ItemDetailsSection
              title={t(`subscriptionPackageItem included ${includedItem} title`)}
              isIncluded={item.packageIncludes[includedItem]}
              isRecommended={isRecommended}
            />
          ))}
        </div>
        <button
          type="button"
          className={classnames(
            styles.buttonSuccessStyle,
            buttonStyle.buttonSuccessStyle,
            'my-1 py-3',
            isRecommended ? styles.recommendedButtonSuccessStyle : '',
          )}
          onClick={() => showModal(t(`subscriptionPackageItem ${item.packageType} title`))}
        >
          {t('subscriptionPackageItem subscribe')}
        </button>
      </div>
    </div>
  );
};

const {
  func, shape, string, bool, number,
} = PropTypes;

SubscriptionPackage.propTypes = {
  showModal: func.isRequired,
  item: shape({
    id: number,
    packageType: string,
    packageIncludes: shape({
      companyProfile: bool.isRequired,
      businessBase: bool.isRequired,
      bizbookArticles: bool.isRequired,
      sendingNewsletterWithSpecialOffers: bool.isRequired,
      socialMediaPromotion: bool.isRequired,
      businessMeetingSchedule: bool.isRequired,
      digitalCatalog: bool,
      bizbookWebShop: bool,
    }),
  }).isRequired,
};

export default SubscriptionPackage;
