import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadCompanyDetails } from 'actions/auth';
import { selectCompanySlug } from 'selectors/auth';
import { setActiveLanguage } from './modules/actions';
import { selectActiveLanguage } from './modules/selectors';
import { showModal } from '../Login/modules/actions';

const mapStateToProps = (state) => ({
  activeLanguage: selectActiveLanguage(state),
  companySlug: selectCompanySlug(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setActiveLanguage,
      showModal,
      loadCompanyDetails,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
