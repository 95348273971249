import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './numberOfViews.module.scss';
import { EyeIcon } from '../../../../svgs';

const NumberOfViews = (props) => {
  const {
    numberOfViews
  } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabIcon}>
        <EyeIcon />
        <div className={styles.numberOfViews}>
          {numberOfViews}
        </div>
      </div>

      <div className={styles.tabText}>
        {t('nav bar logged user tabs number of views')}
      </div>
    </div>

  );
};

export default NumberOfViews;
