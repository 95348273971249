import React, { useState, useEffect } from 'react';
import { List as IList } from 'immutable';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';

import CommentItem from './CommentItem';
import CreateComment from './CreateComment';
import { INITIAL_NUM_OF_COMMENTS } from './constants';

import styles from './comments.module.scss';

const Comments = (props) => {
  const {
    modifyingClass,
    companyId,
    isAdmin,
    activeItemId,
    comments,
    totalItems,
    queryData,
    actions,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    activeItemId && actions.setQueryData(queryData.set('value', activeItemId));
  }, [activeItemId]);

  useEffect(() => {
    const value = queryData.get('value', 0);
    value && actions.getProductComments(queryData.toJS());
  }, [queryData]);

  const [expanded, setExpanded] = useState(false);

  const handleCreateComment = (content) => {
    actions.createNewComment({
      content,
      productId: activeItemId,
    });
  };

  const handleDeleteComment = (id) => {
    actions.deleteCommentById(id);
  };

  const handleEditComment = (commentId, newContent) => {
    actions.editCommentById(commentId, newContent);
  };

  const handleShowAllComments = () => {
    if (totalItems > INITIAL_NUM_OF_COMMENTS) {
      const currentLimit = queryData.get('limit', INITIAL_NUM_OF_COMMENTS);
      const nextLimit = currentLimit === INITIAL_NUM_OF_COMMENTS
        ? totalItems
        : INITIAL_NUM_OF_COMMENTS;
      actions.setQueryData(queryData.set('limit', nextLimit));
      setExpanded((prevState) => !prevState);
    }
  };

  return (
    <div className={classNames(styles.commentsMainContainer, modifyingClass)}>
      <CreateComment handleCreateComment={handleCreateComment} />
      <p className={styles.numberOfComments}>{`${totalItems} ${t('comments totalNummber')}`}</p>
      <div className={styles.commentsList}>
        {comments.map((Icomment, i) => (
          <CommentItem
            key={`comment-${i}`}
            comment={Icomment}
            handleDeleteComment={handleDeleteComment}
            isAdmin={isAdmin}
            companyId={companyId}
            handleEditComment={handleEditComment}
          />
        ))}
      </div>
      <button
        type="button"
        className={styles.showAll}
        onClick={handleShowAllComments}
      >
        {expanded ? t('comments showLess') : t('comments showAll')}
      </button>
    </div>
  );
};

const {
  shape, func, number, bool,
} = PropTypes;
const { map, list } = ImmutablePropTypes;

Comments.propTypes = {
  companyId: number,
  isAdmin: bool.isRequired,
  activeItemId: number,
  comments: list,
  totalItems: number.isRequired,
  queryData: map.isRequired,
  actions: shape({
    getProductComments: func.isRequired,
    createNewComment: func.isRequired,
    deleteCommentById: func.isRequired,
    editCommentById: func.isRequired,
    setQueryData: func.isRequired,
  }).isRequired,
};

Comments.defaultProps = {
  comments: IList(),
};

export default Comments;
