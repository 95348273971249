import i18n from 'i18next';

export default function validate(values) {
  const errors = {};
  if (!values.title) {
    errors.title = i18n.t('createProduct/Service titleInputError empty');
  }

  if (!values.description) {
    errors.description = i18n.t('createProduct/Service decriptionInputError empty');
  }

  if (!values.expiryDate) {
    errors.expiryDate = i18n.t('createProduct/Service expirationDate empty');
  }

  return errors;
}
