import { fromJS } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  comments: [],
  totalItems: 0,
  queryData: {
    limit: 3,
    page: 1,
    property: 'productId'
  }
});

export default (state = defaultState, action) => {
  switch (action.type) {

    case types.SET_PRODUCT_COMMENTS:
      return state.merge({
        comments: fromJS(action.payload.content.data),
        totalItems: action.payload.content.totalItems
      });

    case types.SET_QUERY_DATA:
      return state.merge({
        queryData: action.payload.queryData
      })

    default:
      return state;
  }
};
