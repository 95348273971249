import React from 'react';
import { string } from 'prop-types';

import styles from './languageSelector.module.scss';

const LanguageOption = (props) => {
  const { src, value } = props;

  return (
    <div className={styles.optionItem}>
      <img src={src} alt={value} />
      <h5>{value}</h5>
    </div>
  );
};

LanguageOption.propTypes = {
  src: string.isRequired,
  value: string.isRequired,
};

export default LanguageOption;
