import React from 'react';
import PropTypes from 'prop-types';
import { StarFilled, StarTwoTone } from '@ant-design/icons';
import { greenLightColor } from 'utils/constants';
import styles from './ratingSlider.module.scss';

const RatingSlider = (props) => {
  const { bgcolor, percentage } = props;
  return (
    <div className={styles.mainSliderContainer}>
      <StarTwoTone twoToneColor={bgcolor} className={styles.twoToneStarStyle} />
      <div className={styles.fillContainer}>
        <div
          className={styles.fillerContainer}
          style={{ width: `${percentage}%` }}
        />
      </div>
      <StarFilled
        style={{ color: bgcolor }}
        className={styles.starFilledStyle}
      />
    </div>
  );
};

const { string, number } = PropTypes;

RatingSlider.propTypes = {
  bgcolor: string,
  percentage: number.isRequired,
};

RatingSlider.defaultProps = {
  bgcolor: greenLightColor,
};

export default RatingSlider;
