import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getConversations,
  setVisibilityMessagesList,
  setVisibilityCompaniesList,
} from '../modules/actions';
import { selectConversations, selectActiveCompanies } from '../modules/selectors';
import { getCompanies } from '../CompaniesList/modules/actions';
// eslint-disable-next-line import/named
import { selectLoaderVisible } from '../CompaniesList/modules/selectors';
import { activateLoader, deactivateLoader } from '../../Feed/modules/actions';

const mapStateToProps = (state) => ({
  conversations: selectConversations(state),
  activeCompanies: selectActiveCompanies(state),
  loaderVisible: selectLoaderVisible(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      activateLoader,
      getCompanies,
      getConversations,
      setVisibilityMessagesList,
      setVisibilityCompaniesList,
      deactivateLoader,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
