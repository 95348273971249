import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selectForYouArticles, selectSelectedCategory } from '../modules/selectors';
import { getForYouArticles } from '../modules/actions';

const mapStateToProps = (state) => ({
  articles: selectForYouArticles(state),
  category: selectSelectedCategory(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { getForYouArticles },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
