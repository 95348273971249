import React from 'react';

const NotFound = () => (
  <div style={{ minHeight: '100vh' }} className="d-flex flex-column align-items-center pt-0 pt-md-5">
    <h1 className="text-primary text-center">Page Not Found</h1>
    <p className="text-center">Sorry, this page does not exist</p>
  </div>
);

export default NotFound;
