import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFeedContent } from 'components/Feed/modules/actions';
import {
  selectFeedContent,
  selectHasMoreDataStatus,
  selectNumberOfAvailablePages,
} from 'components/Feed/modules/selectors';
import {
  getIndustriesList,
  getCountriesList,
} from 'components/Register/modules/actions';
import {
  selectIndustriesList,
  selectCountriesList,
} from 'components/Register/modules/selectors';
import { showModal } from './modules/actions';
import { selectAdminUserRole } from '../../selectors/auth';

const mapStateToProps = (state) => ({
  tableContent: selectFeedContent(state),
  industriesList: selectIndustriesList(state),
  countriesList: selectCountriesList(state),
  availablePages: selectNumberOfAvailablePages(state),
  hasMoreDataStatus: selectHasMoreDataStatus(state),
  adminRole: selectAdminUserRole(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getFeedContent,
      getIndustriesList,
      getCountriesList,
      showModal,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
