import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import buttonStyles from 'utils/buttonStyle.module.scss';
import PdfIcon from 'svgs/PdfIcon';
import { Popconfirm } from 'antd';
import { composeViewCompanyUrl } from 'utils/pathsHelper';
import UploadCatalog from './UploadCatalog/UploadCatalog';
import styles from './catalog.module.scss';

const Catalog = (props) => {
  const {
    companyName,
    companyData,
    actions,
    catalogs,
    uploadedCatalog,
    adminRole,
  } = props;
  const { t } = useTranslation();
  const params = useParams();
  const { id } = params;

  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    actions.getCompanyDetailsById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addCatalog = () => {
    actions.addCompanyCatalog(
      companyData.get('id', null),
      id,
      uploadedCatalog.get('url', ''),
      uploadedCatalog.get('publicId', ''),
      uploadedCatalog.get('name', ''),
    );
  };

  const deleteCatalog = (catalogId) => {
    actions.deleteCatalog(catalogId, companyData.get('slug', ''));
  };

  useEffect(() => {
    if (companyData.size > 0) {
      if (
        companyData.get('id', '')
        === parseInt(localStorage.getItem('userId'), 10)
      ) {
        setIsAuthorized(true);
      }
      actions.getCompanyCatalogs(companyData.get('slug', ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData]);

  useEffect(() => {
    if (adminRole) {
      setIsAuthorized(true);
    }
  }, [adminRole]);

  return (
    <div className={classNames('container', styles.catalogContainer)}>
      <div className={classNames(styles.navigationInfo)}>
        <Link to={composeViewCompanyUrl(companyData.get('slug', id))}>
          <h5>{companyName}</h5>
        </Link>
        <RightOutlined className="px-3 pt-1" />
        <h6>{t('uploadCatalog navigationInfoTitle')}</h6>
      </div>
      {isAuthorized && (
        <UploadCatalog
          uploadCatalog={actions.uploadCatalog}
          catalogUrl={uploadedCatalog.get('url', '')}
          catalogName={uploadedCatalog.get('name', '')}
        />
      )}
      {uploadedCatalog.size > 0 && (
        <button
          type="button"
          className={classNames(
            buttonStyles.buttonSuccessStyle,
            styles.buttonSuccessStyle,
          )}
          onClick={addCatalog}
        >
          {t('uploadCatalog addedUploadedCatalog')}
        </button>
      )}
      <div className={styles.uploadedPdfsContainer}>
        {catalogs.size > 0
          && catalogs.map((catalog) => (
            <div key={catalog.get('id', '')} className={styles.uploadedCatalog}>
              <div className={classNames(styles.pdfRowContainer)}>
                <PdfIcon fontSize={30} />
                <div>
                  <h5>{catalog.get('name', '') || catalog.get('url', '')}</h5>
                </div>
              </div>
              <div className="d-flex">
                {isAuthorized && (
                  <Popconfirm
                    title={t('popConfirm catalogsTitle')}
                    onConfirm={() => deleteCatalog(catalog.get('id', ''))}
                    okText={t('popConfirm confirm')}
                    cancelText={t('popConfirm cancel')}
                  >
                    <button
                      type="button"
                      className={classNames(styles.deletePdfButton, 'mr-4')}
                    >
                      {t('uploadCatalog deleteCatalogButton')}
                    </button>
                  </Popconfirm>
                )}
                <Link
                  target="_blank"
                  to={catalog.get('url', '')}
                  className={classNames(styles.viewPdfButton)}
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(catalog.get('url', ''));
                  }}
                >
                  {t('uploadCatalog viewUploadedPdf')}
                </Link>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

const {
  shape, func, string, bool,
} = PropTypes;
const { map, list } = ImmutablePropTypes;

Catalog.propTypes = {
  companyData: map.isRequired,
  companyName: string.isRequired,
  actions: shape({
    getCompanyDetailsById: func.isRequired,
    uploadCatalog: func.isRequired,
    getCompanyCatalogs: func.isRequired,
    addCompanyCatalog: func.isRequired,
    deleteCatalog: func.isRequired,
  }).isRequired,
  uploadedCatalog: map.isRequired,
  catalogs: list.isRequired,
  adminRole: bool.isRequired,
};

export default Catalog;
