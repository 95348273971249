export const types = {
  GET_PRODUCT_COMMENTS: 'GET_PRODUCT_COMMENTS',
  SET_PRODUCT_COMMENTS: 'SET_PRODUCT_COMMENTS',
  CREATE_NEW_COMMENT: 'CREATE_NEW_COMMENT',
  DELETE_COMMENT_BY_ID: 'DELETE_COMMENT_BY_ID',
  EDIT_COMMENT_BY_ID: 'EDIT_COMMENT_BY_ID',
  SET_QUERY_DATA: 'SET_QUERY_DATA'
};

export const getProductComments = (queryObject) => ({
  type: types.GET_PRODUCT_COMMENTS,
  payload: {
    queryObject
  }
});

export const setProductComments = (content) => ({
  type: types.SET_PRODUCT_COMMENTS,
  payload: {
    content
  }
});

export const createNewComment = (commentData) => ({
  type: types.CREATE_NEW_COMMENT,
  payload: {
    commentData
  }
});

export const deleteCommentById = (id, productId) => ({
  type: types.DELETE_COMMENT_BY_ID,
  payload: {
    id,
  }
});

export const editCommentById = (id, content, productId) => ({
  type: types.EDIT_COMMENT_BY_ID,
  payload: {
    id,
    content,
  }
});

export const setQueryData = (queryData) => ({
  type: types.SET_QUERY_DATA,
  payload: {
    queryData,
  },
});

export default types;
