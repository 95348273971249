export const feedUrl = '/feed';
export const tutorialUrl = '/tutorial';
export const createProductUrl = '/create/product';
export const createDemandUrl = '/create/demand';
export const createdProductDemandUrl = '/created';
export const loginUrl = '/login';
export const loginAfterConfirmationUrl = '/login-after-confirmation';
export const resendEmailActivationUrl = '/resend-email-activation';
export const registerConfirmedUrl = '/register-confirmed';
export const messagerUrl = '/messenger';
export const registerUrl = '/register';
export const testimonialsUrl = '/testimonials';
export const termsAndConditionsUrl = '/terms-and-conditions';
export const privacyPoliciesUrl = '/privacy-policies';
export const resetPasswordUrl = '/resetpassword';
export const adminPanelUrl = '/admin-panel';
export const companyInterestsUrl = '/interests';

export const homepageLinks = {
  packages: '/packages',
  rules: '/terms-and-conditions',
  news: '/news',
  contactUs: '/contact-us',
  feed: '/feed',
};

export const companyUrls = {
  editCompany: '/company/edit',
  companyAbout: '/company/about',
  products: '/products',
  createProduct: '/create/product',
  createDemand: '/create/demand',
  viewProductDemand: '/view',
  editProductDemand: '/edit',
  company: '/company',
  catalog: '/catalog',
};

export const composeEditCompanyUrl = (id) => `${companyUrls.editCompany}/${id}`;
export const composeViewCompanyUrl = (id) => `${companyUrls.company}/${id}`;
export const composeAboutCompanyUrl = (id) =>
  `${companyUrls.companyAbout}/${id}`;
export const composeProductsUrl = (id) => `${companyUrls.products}/${id}`;
export const composeCatalogsUrl = (id) => `${companyUrls.catalog}/${id}`;
export const composeBlogArticleUrl = (slug) => `${homepageLinks.news}/${slug}`;

export const urlTemplates = {
  messengerUrlTemplate: `${messagerUrl}/:id?`,
  editCompanyUrlTemplate: `${companyUrls.editCompany}/:id`,
  aboutCompanyUrlTemplate: `${companyUrls.companyAbout}/:companyId`,
  myFeedUrlTemplate: `${companyUrls.products}/:id`,
  createProductUrlTemplate: `${companyUrls.createProduct}/:id`,
  createDemandUrlTemplate: `${companyUrls.createDemand}/:id`,
  viewProductDemandUrlTemplate: `${companyUrls.viewProductDemand}/:type/:productId`,
  editProductUrlTemplate: `${companyUrls.editProductDemand}/:type/:id`,
  viewCompanyUrlTemplate: `${companyUrls.company}/:id`,
  catalogUrlTemplate: `${companyUrls.catalog}/:id`,
  blogArticleUrlTemplate: `${homepageLinks.news}/:slug`,
};
