import { createSelector } from 'reselect';
import { fromJS } from 'immutable';

const selectBannerState = (state) => state.bannerReducer;

export const selectBanner = createSelector(
  selectBannerState,
  (state) => state.get('banner', fromJS({ image: { url: '' }, url: '' })),
);

export default null;
