import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Carousel } from 'antd';

import styles from '../cardDetailedView.module.scss';

const ImagesContainer = (props) => {
  const { itemDetails, showPictureExpandModal } = props;
  const ref = useRef(null);
  const rowStyleImageWidth = 197;

  const scrollRight = () => {
    if (ref.current) {
      ref.current.scrollLeft += rowStyleImageWidth;
    }
  };

  const scrollLeft = () => {
    if (ref.current) {
      ref.current.scrollLeft -= rowStyleImageWidth;
    }
  };

  const expandPicture = (url) => showPictureExpandModal(url);

  return (
    <div className={classNames('col-12 col-md-7', styles.imageContainer)}>
      {itemDetails.size > 0 && itemDetails.get('images', '').size > 0 && (
        <img
          className={styles.coverImage}
          src={itemDetails.get('images', '').first().get('url', '')}
          alt="Main cover"
          role="presentation"
          onClick={() => expandPicture(itemDetails.get('images').first().get('url', ''))}
        />
      )}
      {itemDetails.size > 0 && itemDetails.get('images').size > 1 && (
        <div className={styles.rowContent}>
          <div ref={ref} className={styles.rowStyledImages}>
            {itemDetails.get('images').size > 3 && (
              <button
                type="button"
                className={styles.scrollLeftButton}
                onClick={scrollLeft}
              >
                <LeftOutlined className={styles.iconCustomStyle} />
              </button>
            )}

            {itemDetails
              .get('images')
              .map(
                (image, index) => index > 0 && (
                <img
                  key={image.get('url', '')}
                  src={image.get('url', '')}
                  alt="other"
                  role="presentation"
                  onClick={() => expandPicture(image.get('url', ''))}
                />
                ),
              )}
            {itemDetails.get('images').size > 3 && (
            <button
              type="button"
              className={styles.scrollRightButton}
              onClick={scrollRight}
            >
              <RightOutlined className={styles.iconCustomStyle} />
            </button>
            )}

          </div>
        </div>
      )}
      <div className={styles.carouselContainer}>
        <Carousel>
          {itemDetails.size > 0
            && itemDetails.get('images').map((image) => (
              <div key={image.get('url', '')}>
                <img src={image.get('url', '')} alt="other" />
              </div>
            ))}
        </Carousel>
      </div>
    </div>
  );
};

const { map } = ImmutablePropTypes;
const { func } = PropTypes;

ImagesContainer.propTypes = {
  itemDetails: map.isRequired,
  showPictureExpandModal: func.isRequired,
};

export default ImagesContainer;
