import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { loader } from 'images';
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PdfIcon from 'svgs/PdfIcon';
import { catalogUploadMaxLimit } from 'utils/constants';
import styles from './uploadCatalog.module.scss';

const UploadCatalog = (props) => {
  const { catalogUrl, uploadCatalog, catalogName } = props;
  const { t } = useTranslation();
  const [uploading, setUploadingStatus] = useState(false);

  const handleImageUpload = (data) => {
    if (data.file.status === 'uploading') {
      setUploadingStatus(true);
    }
  };
  const renderUploadContainer = (isUploading) => {
    if (isUploading) {
      return (
        <div className={styles.loaderContainer}>
          <LazyLoadImage
            alt="Loader"
            effect="blur"
            src={loader}
            className={styles.loaderImgStyle}
          />
        </div>
      );
    }
    return <div className={classNames(styles.uploadContainer, 'mx-auto')} />;
  };

  useEffect(() => {
    setUploadingStatus(false);
  }, [catalogUrl]);

  const parseCatalogName = catalogName || catalogUrl.slice(0, 50);

  return (
    <div className={classNames(styles.uploadContainer)}>
      {renderUploadContainer(uploading, catalogUrl)}
      <div
        className={classNames(
          styles.uploadedPdfContainer,
          uploading && 'invisible',
        )}
      >
        <PdfIcon fontSize={30} />
        <h5>
          {parseCatalogName
            ? `${parseCatalogName}...`
            : t('uploadCatalog noPdfUploaded')}
        </h5>
      </div>
      <Upload
        name="avatar"
        listType="picture-card"
        showUploadList={false}
        onChange={handleImageUpload}
        className={classNames(styles.imageUploadButtonContainer)}
        beforeUpload={(file) => {
          const isPdfFile = file.type === 'application/pdf';
          if (!isPdfFile) {
            notification.error({
              message: t('notification error'),
              description: `${file.name} ${t('notification notPdfFile')}`,
            });
          }
          const isLimitSizeExceded = file.size / 1024 / 1024 >= catalogUploadMaxLimit;
          if (isLimitSizeExceded) {
            notification.error({
              message: t('notification error'),
              description: t('uploadCatalog limitExceeded'),
            });
          }
          return isPdfFile && !isLimitSizeExceded;
        }}
        customRequest={(options) => {
          options.onSuccess(uploadCatalog(options));
        }}
      >
        <button
          type="button"
          className={classNames(
            styles.addPdfButtonStyle,
            uploading && 'invisible',
          )}
        >
          {catalogName
            ? t('uploadCatalog changePdfFile')
            : t('uploadCatalog addPdfFile')}
        </button>
      </Upload>
    </div>
  );
};

const { string, func } = PropTypes;

UploadCatalog.propTypes = {
  catalogUrl: string,
  uploadCatalog: func.isRequired,
  catalogName: string,
};

UploadCatalog.defaultProps = {
  catalogUrl: '',
  catalogName: '',
};

export default UploadCatalog;
