export const types = {
  GET_COUNTRIES_LIST: 'GET_COUNTRIES_LIST',
  SET_COUNTRIES_LIST: 'SET_COUNTRIES_LIST',
  GET_INDUSTRIES_LIST: 'GET_INDUSTRIES_LIST',
  SET_INDUSTRIES_LIST: 'SET_INDUSTRIES_LIST',
  EMAIL_EXISTS: 'EMAIL_EXISTS',
  RECEIVE_EMAIL_EXISTS: 'RECEIVE_EMAIL_EXISTS',
};

export const getCountriesList = () => ({
  type: types.GET_COUNTRIES_LIST,
  payload: {},
});

export const setCountriesList = (listOfCountries) => ({
  type: types.SET_COUNTRIES_LIST,
  payload: {
    listOfCountries,
  },
});

export const getIndustriesList = () => ({
  type: types.GET_INDUSTRIES_LIST,
  payload: {},
});

export const setIndustriesList = (listOfIndustries) => ({
  type: types.SET_INDUSTRIES_LIST,
  payload: {
    listOfIndustries,
  },
});

export const checkEmailExists = (emailData) => ({
  type: types.EMAIL_EXISTS,
  payload: {
    emailData,
  },
});

export const receiveEmailExists = (emailExists) => ({
  type: types.RECEIVE_EMAIL_EXISTS,
  payload: {
    emailExists,
  },
});

export default types;
