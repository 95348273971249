import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from '../navbar.module.scss';

const NavItem = ({
  title,
  route,
  closeNavbar,
  pathname,
  id,
}) => (
  <li
    className={classNames(
      styles.navItem,
      pathname === route ? styles.activeTab : '',
    )}
  >
    <Link
      className={styles.navLink}
      to={route}
      title={title}
      onClick={(event) => {
        if (id === 4) {
          event.preventDefault();
          window.open(route);
        }
        closeNavbar();
      }}
    >
      {title}
    </Link>
  </li>
);

NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  closeNavbar: PropTypes.func,
  pathname: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

NavItem.defaultProps = {
  closeNavbar: () => {},
};

export default NavItem;
