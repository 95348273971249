/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './whatWeDo.module.scss';

const ProductItem = (props) => {
  const {
    textAlignment,
    title,
    description,
  } = props;
  return (
    <div
      className={classNames(
        styles.productItemContainer,
        textAlignment === 'text-center'
          ? 'align-items-center'
          : textAlignment === 'text-right'
            ? 'align-items-end'
            : '',
        'mx-auto',
      )}
    >
      <div className={styles.sectionTitleContainerViewMode}>
        <h3
          className={classNames(
            styles.inputSectionTitle,
            textAlignment,
            styles.sectionTitleTextViewMode,
          )}
        >
          {title}
        </h3>
      </div>

      <div className={classNames(styles.horizontalBreakline)} />
      <h5
        className={classNames(
          styles.inputSectionDescription,
          textAlignment,
          styles.noBorderStyle,
        )}
      >
        {description}
      </h5>
    </div>
  );
};

const { string } = PropTypes;

ProductItem.propTypes = {
  textAlignment: string.isRequired,
  title: string.isRequired,
  description: string.isRequired,
};

export default ProductItem;
