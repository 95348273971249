import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import inputStyle from 'utils/inputStyle.module.scss';
import styles from '../createProduct.module.scss';

const InputField = (props) => {
  const {
    name,
    placeholder,
    value,
    label,
    rows,
    error,
    handleChange,
    maxLength,
    onBlur,
  } = props;
  return (
    <div
      className={classNames(
        inputStyle.formGroup,
        styles.textareaStyle,
        'col-12 my-3',
        error ? inputStyle.validationError : '',
      )}
    >
      <div>
        <h6 className={classNames(inputStyle.error, styles.error)}>{error}</h6>
        <h5 className={classNames(styles.textAreaLabel, 'px-1')}>{label}</h5>
      </div>
      <textarea
        className={classNames(inputStyle.formControl, 'py-2 my-1')}
        type="text"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleChange}
        rows={rows}
        maxLength={maxLength}
        onBlur={onBlur}
      />
    </div>
  );
};

const { string, number, func } = PropTypes;

InputField.propTypes = {
  name: string.isRequired,
  placeholder: string.isRequired,
  value: string,
  label: string.isRequired,
  rows: number.isRequired,
  error: string,
  handleChange: func.isRequired,
  maxLength: number,
  onBlur: func,
};

InputField.defaultProps = {
  error: '',
  value: '',
  maxLength: null,
  onBlur: () => {},
};

export default InputField;
