import React from 'react';
import { string } from 'prop-types';

const ForkIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 48.453 48.243"
  >
    <g
      id="prefix__Group_657"
      data-name="Group 657"
      transform="translate(-615.049 -477.128) translate(29.198 -42.855)"
    >
      <g id="prefix__Group_93" data-name="Group 93">
        <g id="prefix__Group_91" data-name="Group 91">
          <path
            id="prefix__Path_85"
            d="M588.229 566.06a2 2 0 0 1 0-2.828l31.82-31.819a2 2 0 0 1 2.828 0 2 2 0 0 1 0 2.828l-31.82 31.819a2 2 0 0 1-2.828 0z"
            data-name="Path 85"
            style={{ fill: '#050713' }}
          />
        </g>
        <g id="prefix__Group_92" data-name="Group 92">
          <path
            id="prefix__Path_86"
            d="M622.109 553.081a8.685 8.685 0 0 1-6.158-2.546l-11.982-11.982a9.118 9.118 0 0 1-2.749-5.815 8.663 8.663 0 0 1 2.533-6.716l5.452-5.453a2 2 0 0 1 2.828 0 2 2 0 0 1 0 2.828l-5.323 5.324a4.954 4.954 0 0 0-1.47 2.85 4.7 4.7 0 0 0 1.341 3.938l12.2 12.2a4.717 4.717 0 0 0 6.659 0l5.452-5.453a2 2 0 0 1 2.828 0 2 2 0 0 1 0 2.828l-5.453 5.453a8.675 8.675 0 0 1-6.158 2.544z"
            data-name="Path 86"
            style={{ fill: color }}
          />
        </g>
      </g>
      <path
        id="prefix__Path_87"
        d="M629.1 543.837l-5.452 5.453a4.717 4.717 0 0 1-6.659 0l-4.689-4.69 8.782-8.782a2 2 0 0 0-2.828-2.829l-8.782 8.781-4.685-4.685a4.7 4.7 0 0 1-1.341-3.938 4.961 4.961 0 0 1 1.47-2.85l5.323-5.324a2 2 0 0 0-2.828-2.828l-5.452 5.453a8.663 8.663 0 0 0-2.533 6.716 9.123 9.123 0 0 0 2.749 5.815l4.468 4.469-20.21 20.209a2 2 0 0 0 0 2.828 2 2 0 0 0 2.828 0l20.21-20.209 4.685 4.685a8.72 8.72 0 0 0 12.316 0l5.453-5.453a2 2 0 1 0-2.829-2.826z"
        data-name="Path 87"
        style={{ opacity: 0.2, fill: color }}
      />
    </g>
  </svg>
);

ForkIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

ForkIcon.defaultProps = {
  color: '#37ead9',
  width: '48.453',
  height: '48.243',
};

export default ForkIcon;
