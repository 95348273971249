/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { addIcon, rubbish } from 'images/createCompany';
import buttonStyles from 'utils/buttonStyle.module.scss';
import icons from 'images/Icons/index';
import { textInputMaxLength } from 'utils/constants';
import EditBar from '../modules/utils/EditBar';
import SelectIcon from '../AddIcon';
import styles from './aboutUs.module.scss';

const AboutUsSection = (props) => {
  const {
    sectionTitle,
    shortInfo,
    iconName,
    saveChange,
    textAlignment,
    actions,
    colorTheme,
    modalVisibilityAboutUs,
    fontFamily,
    setSectionCompleted,
  } = props;
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState('edit');
  const [formData, setFormData] = useState({
    sectionTitle,
    shortInfo,
    textAlignment,
    iconName,
  });
  const [changesSaved, setChangesSavedStatus] = useState(false);
  const sectionDidChange = (change) => {
    actions.setChangesMade(change);
    setChangesSavedStatus(!change);
  };
  const updateForm = (property, value) => {
    const newFormData = { ...formData };
    if (property) newFormData[property] = value;
    else {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in newFormData) {
        if (newFormData[key]) newFormData[key] = '';
      }
    }
    setFormData(newFormData);
    sectionDidChange(true);
  };
  const saveChanges = () => {
    saveChange('aboutUsSection', {
      ...formData,
      sectionTitle:
        formData.sectionTitle
        || ((formData.shortInfo.length > 0 || formData.iconName.length > 0)
        && 'Strategija našeg poslovanja') || '',
    });
    sectionDidChange(false);
  };
  useEffect(() => {
    setFormData({
      sectionTitle,
      shortInfo,
      textAlignment,
      iconName,
    });
    setSectionCompleted('aboutUs', sectionTitle.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconName, sectionTitle, shortInfo, textAlignment]);

  const setTextAlignment = (alignment) => {
    updateForm('textAlignment', alignment);
  };
  const clearCard = () => {
    updateForm();
  };
  const handleAddIcon = () => {
    actions.showModal('aboutUsIconSelect');
  };
  const handleIconSave = (icon) => {
    updateForm('iconName', icon);
  };
  return (
    <div className={classNames('container pb-5', styles.aboutUsMainContainer)}>
      <div
        className={classNames(
          styles.aboutUsContainer,
          formData.textAlignment === 'text-center'
            ? 'align-items-center'
            : formData.textAlignment === 'text-right'
              ? 'align-items-end'
              : '',
        )}
      >
        {formData.iconName ? (
          <div className={styles.svgIconContainer}>
            {icons(colorTheme, '77px', '62px')[formData.iconName]}
          </div>
        ) : (
          <img
            src={addIcon}
            alt="about us icon upload"
            className={styles.addIconImgStyle}
          />
        )}

        <button
          type="button"
          className={classNames(
            buttonStyles.buttonSuccessStyle,
            styles.buttonSuccessStyle,
            viewMode === 'view' ? 'd-none' : '',
          )}
          onClick={handleAddIcon}
        >
          {t('createCompany aboutUs buttonText addIcon')}
        </button>
        <h4
          className={classNames(
            viewMode === 'view' ? styles.aboutUsTextStyle : '',
          )}
        >
          {t('createCompany aboutUs titleText')}
        </h4>
        {viewMode === 'edit' ? (
          <input
            type="text"
            placeholder={t('createCompany aboutUs inputSection titleName')}
            name="sectionTitle"
            className={classNames(
              styles.inputSectionTitle,
              formData.textAlignment,
              viewMode === 'view' ? styles.noBorderStyle : '',
            )}
            value={formData.sectionTitle}
            onChange={(e) => {
              updateForm('sectionTitle', e.target.value);
            }}
            style={{ color: colorTheme, fontFamily }}
            maxLength={199}
          />
        ) : (
          <h2
            className={classNames(
              styles.inputSectionTitle,
              formData.textAlignment,
              styles.viewModeTextStyle,
            )}
            style={{ color: colorTheme, fontFamily }}
          >
            {formData.sectionTitle}
          </h2>
        )}

        {viewMode === 'edit' ? (
          <div className={styles.inputSectionDescriptionContainer}>
            <p
              className={classNames(
                styles.textCounterContainer,
                viewMode === 'view' && 'd-none',
              )}
            >
              {`${formData.shortInfo.length}/${textInputMaxLength}`}
            </p>
            <textarea
              type="text"
              placeholder={t(
                'createCompany aboutUs inputSection shortDescription',
              )}
              name="sectionShortDescription"
              className={classNames(
                styles.inputSectionDescription,
                formData.textAlignment,
                viewMode === 'view' ? styles.noBorderStyle : '',
              )}
              rows={6}
              maxLength={textInputMaxLength}
              value={formData.shortInfo}
              onChange={(e) => {
                updateForm('shortInfo', e.target.value);
              }}
            />
          </div>
        ) : (
          <h5
            className={classNames(
              styles.inputSectionDescription,
              formData.textAlignment,
              styles.shortDecriptionTextViewMode,
            )}
          >
            {formData.shortInfo}
          </h5>
        )}

        <div
          className={classNames(
            styles.rubbishIconStyle,
            viewMode === 'view' ? 'invisible' : '',
          )}
          onClick={clearCard}
          onKeyPress={clearCard}
        >
          <img src={rubbish} alt="delete" />
        </div>
      </div>
      <EditBar
        textAlignment={formData.textAlignment}
        viewMode={viewMode}
        setTextAlignment={setTextAlignment}
        setViewMode={setViewMode}
        saveChanges={saveChanges}
        changesSaved={changesSaved}
      />
      <SelectIcon
        modalType="aboutUsIconSelect"
        modalVisibility={modalVisibilityAboutUs}
        handleIconSave={handleIconSave}
        iconName={formData.iconName}
      />
    </div>
  );
};

const {
  string, func, shape, bool,
} = PropTypes;

AboutUsSection.propTypes = {
  sectionTitle: string,
  shortInfo: string,
  iconName: string,
  saveChange: func.isRequired,
  textAlignment: string.isRequired,
  actions: shape({
    showModal: func.isRequired,
    setChangesMade: func.isRequired,
  }).isRequired,
  colorTheme: string.isRequired,
  modalVisibilityAboutUs: bool.isRequired,
  fontFamily: string.isRequired,
  setSectionCompleted: func.isRequired,
};

AboutUsSection.defaultProps = {
  sectionTitle: '',
  shortInfo: '',
  iconName: '',
};

export default AboutUsSection;
