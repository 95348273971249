import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { RightOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import icons from 'images/Icons/index';
import ButtonSaveChangesMedium from '../../Buttons/ButtonSaveChanges/ButtonSaveChangesMedium/View';
import ButtonCancel from '../../Buttons/ButtonCancel/View';
import styles from './addIcon.module.scss';

const AddIcon = (props) => {
  const {
    actions,
    modalVisibility,
    colorTheme,
    iconName,
    handleIconSave,
    modalType,
    companyName,
  } = props;
  const [selectedIcon, selectIcon] = useState({ id: '' });
  const { t } = useTranslation();

  useEffect(() => {
    selectIcon(iconName);
  }, [iconName]);
  const toggle = () => {
    if (modalVisibility) {
      actions.hideModal(modalType);
    } else {
      actions.showModal(modalType);
    }
  };
  const handleSaveChanges = () => {
    if (selectedIcon) {
      handleIconSave(selectedIcon);
      actions.hideModal(modalType);
    } else {
      notification.error({
        message: 'Saving Error',
        description: 'Image not selected',
      });
    }
  };

  const handleHideModal = () => {
    actions.hideModal(modalType);
  };

  return (
    <Modal
      isOpen={modalVisibility}
      toggle={toggle}
      className={styles.modalContainerStyle}
    >
      <div className={styles.modalWrapper}>
        <div className={classNames(styles.navigationInfo)}>
          <h5>{companyName}</h5>
          <RightOutlined className="px-4 pt-1" />
          <h6>{t('iconSelect navigation text')}</h6>
        </div>
        {/* modal content */}
        <div className={classNames(styles.modalContent)}>
          {Object.keys(icons(colorTheme)).map((iconKey) => (
            <div
              className={classNames(
                styles.imageContainer,
                'my-3',
                iconKey === selectedIcon ? styles.selectedIcon : '',
              )}
              key={iconKey}
              onClick={() => selectIcon(iconKey)}
              role="presentation"
            >
              {icons(colorTheme)[iconKey]}
            </div>
          ))}
        </div>
        <div
          className={classNames(
            styles.buttonContainer,
            'col-12 mt-5 mt-md-0 ml-auto pr-0',
          )}
        >
          <ButtonCancel handleClick={handleHideModal} />
          <ButtonSaveChangesMedium handleClick={handleSaveChanges} />
        </div>
      </div>
    </Modal>
  );
};

const {
  bool, shape, func, string,
} = PropTypes;

AddIcon.propTypes = {
  actions: shape({
    showModal: func.isRequired,
    hideModal: func.isRequired,
  }).isRequired,
  modalVisibility: bool.isRequired,
  colorTheme: string.isRequired,
  iconName: string,
  handleIconSave: func.isRequired,
  modalType: string.isRequired,
  companyName: string.isRequired,
};

AddIcon.defaultProps = {
  iconName: '',
};

export default AddIcon;
