import React from 'react';
import { string } from 'prop-types';

const BackpackIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 38.325 51.86"
  >
    <g
      id="prefix__Group_647"
      data-name="Group 647"
      transform="translate(-283.699 -318.894)"
    >
      <g
        id="prefix__Group_183"
        data-name="Group 183"
        transform="translate(34.198 -36.855)"
      >
        <g id="prefix__Group_149" data-name="Group 149">
          <g id="prefix__Group_145" data-name="Group 145">
            <g id="prefix__Group_143" data-name="Group 143">
              <path
                id="prefix__Path_174"
                d="M279.328 389.4a2 2 0 0 1-2-2v-6.213a2 2 0 0 1 2-2 2 2 0 0 1 2 2v6.213a2 2 0 0 1-2 2z"
                className="prefix__cls-1"
                data-name="Path 174"
                style={{ fill: color }}
              />
            </g>
            <g id="prefix__Group_144" data-name="Group 144">
              <path
                id="prefix__Path_175"
                d="M260.093 389.4a2 2 0 0 1-2-2v-6.213a2 2 0 0 1 2-2 2 2 0 0 1 2 2v6.213a2 2 0 0 1-2 2z"
                className="prefix__cls-1"
                data-name="Path 175"
                style={{ fill: color }}
              />
            </g>
          </g>
          <g id="prefix__Group_146" data-name="Group 146">
            <path
              id="prefix__Path_176"
              d="M275.887 362.1h-4v-1.29a1.058 1.058 0 0 0-1.057-1.057h-2.239a1.058 1.058 0 0 0-1.057 1.057v1.29h-4v-1.29a5.063 5.063 0 0 1 5.057-5.057h2.239a5.063 5.063 0 0 1 5.057 5.057z"
              className="prefix__cls-1"
              data-name="Path 176"
              style={{ fill: color }}
            />
          </g>
          <g id="prefix__Group_147" data-name="Group 147">
            <path
              id="prefix__Path_177"
              d="M282.013 406.453h-24.6a5.819 5.819 0 0 1-5.813-5.813v-33.732a5.819 5.819 0 0 1 5.813-5.813h24.6a5.819 5.819 0 0 1 5.813 5.813v33.732a5.82 5.82 0 0 1-5.813 5.813zM257.408 365.1a1.815 1.815 0 0 0-1.813 1.813v33.732a1.815 1.815 0 0 0 1.813 1.813h24.6a1.815 1.815 0 0 0 1.813-1.813v-33.736a1.816 1.816 0 0 0-1.813-1.813z"
              className="prefix__cls-2"
              data-name="Path 177"
              style={{ fill: '#050713' }}
            />
          </g>
          <g id="prefix__Group_148" data-name="Group 148">
            <path
              id="prefix__Path_178"
              d="M276.371 382.53H263.05a10.966 10.966 0 0 1-10.95-10.954h3a7.963 7.963 0 0 0 7.954 7.954h13.321a7.963 7.963 0 0 0 7.954-7.954h3a10.966 10.966 0 0 1-10.958 10.954z"
              className="prefix__cls-2"
              data-name="Path 178"
              style={{ fill: '#050713' }}
            />
          </g>
        </g>
        <path
          id="prefix__Path_179"
          d="M279.919 407.609a5.819 5.819 0 0 0 5.813-5.813v-33.732a5.819 5.819 0 0 0-5.813-5.813h-6.126v-.29a5.063 5.063 0 0 0-5.057-5.057H266.5a5.063 5.063 0 0 0-5.057 5.057v.29h-6.126a5.819 5.819 0 0 0-5.813 5.813V401.8a5.819 5.819 0 0 0 5.813 5.813zm-14.479-45.647a1.058 1.058 0 0 1 1.06-1.062h2.239a1.058 1.058 0 0 1 1.057 1.057v.29h-4.356v-.29zm-10.126 4.29h24.605a1.815 1.815 0 0 1 1.813 1.813v7.417a7.96 7.96 0 0 1-7.454 5.2h-13.321a7.96 7.96 0 0 1-7.454-5.2v-7.417a1.813 1.813 0 0 1 1.811-1.813zM253.5 401.8v-21.064a10.938 10.938 0 0 0 2.5 1.751v6.073a2 2 0 0 0 4 0v-4.923c.316.027.634.048.957.048h13.321c.323 0 .641-.021.957-.048v4.923a2 2 0 0 0 4 0v-6.073a11 11 0 0 0 2.5-1.751V401.8a1.815 1.815 0 0 1-1.813 1.813h-24.6a1.815 1.815 0 0 1-1.822-1.813z"
          data-name="Path 179"
          style={{ opacity: 0.2, fill: color }}
        />
      </g>
    </g>
  </svg>
);

BackpackIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

BackpackIcon.defaultProps = {
  color: '#37ead9',
  width: '38.325',
  height: '51.86',
};

export default BackpackIcon;
