import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Rate } from 'antd';
import buttonStyles from 'utils/buttonStyle.module.scss';
import styles from './ratingModal.module.scss';

const ProductRatingModal = (props) => {
  const { actions, modalVisibility, productId } = props;
  const { t } = useTranslation();
  const [rating, setRating] = useState({
    descriptionRating: null,
    priceRating: null,
    communicationRating: null,
  });

  const toggle = () => {
    if (modalVisibility) {
      actions.hideRatingModal();
    } else {
      actions.showRatingModal();
    }
  };

  const rateProduct = () => {
    actions.hideRatingModal();
    actions.rateProductItem(productId, rating);
  };

  return (
    <div className={styles.productRatingModal}>
      <Modal
        isOpen={modalVisibility}
        toggle={toggle}
        className={classNames(styles.modalContainer)}
      >
        <div className={classNames('px-3 pb-3', styles.ratingSliderContainer)}>
          <h3>{t('cardDetailedView othersRateUs description tagline')}</h3>
          <div className={styles.ratingSlider}>
            <Rate
              onChange={(value) => setRating({ ...rating, descriptionRating: value })}
              value={rating.descriptionRating}
              style={{ color: 'black' }}
            />
            {rating.descriptionRating && (
              <p>{`${rating.descriptionRating}/5`}</p>
            )}
          </div>
        </div>
        <div className={classNames('px-3 pb-3', styles.ratingSliderContainer)}>
          <h3>{t('cardDetailedView othersRateUs quality tagline')}</h3>
          <div className={styles.ratingSlider}>
            <Rate
              onChange={(value) => setRating({ ...rating, priceRating: value })}
              value={rating.priceRating}
              style={{ color: 'black' }}
            />
            {rating.priceRating && <p>{`${rating.priceRating}/5`}</p>}
          </div>
        </div>
        <div className={classNames('px-3 pb-3', styles.ratingSliderContainer)}>
          <h3>{t('cardDetailedView othersRateUs communication tagline')}</h3>
          <div className={styles.ratingSlider}>
            <Rate
              onChange={(value) => setRating({ ...rating, communicationRating: value })}
              value={rating.communicationRating}
              style={{ color: 'black' }}
            />
            {rating.communicationRating && (
              <p>{`${rating.communicationRating}/5`}</p>
            )}
          </div>
        </div>
        <button
          type="button"
          className={classNames(
            buttonStyles.buttonSuccessStyle,
            styles.customButtonStyles,
            !rating.communicationRating
              || !rating.descriptionRating
              || !rating.priceRating
              ? 'btn disabled'
              : '',
          )}
          onClick={rateProduct}
          disabled={!rating}
        >
          {t('rateCompany button')}
        </button>
      </Modal>
    </div>
  );
};

const {
  bool, shape, func, string,
} = PropTypes;

ProductRatingModal.propTypes = {
  actions: shape({
    showRatingModal: func.isRequired,
    hideRatingModal: func.isRequired,
    rateProductItem: func.isRequired,
  }).isRequired,
  modalVisibility: bool.isRequired,
  productId: string.isRequired,
};

export default ProductRatingModal;
