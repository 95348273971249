export const types = {
  REGISTER_USER: 'REGISTER_USER',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  LOGIN_USER: 'LOGIN_USER',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  COMPANY_LOADED: 'COMPANY_LOADED',
  LOAD_COMPANY_DETAILS: 'LOAD_COMPANY_DETAILS',
  UPDATE_COMPANY_DETAILS: 'UPDATE_COMPANY_DETAILS',
  UPDATE_COMPANY_JSON: 'UPDATE_COMPANY_JSON',
  UPDATE_COMPANY_THEME: 'UPDATE_COMPANY_THEME',
  SAVE_COMPANY_DETAILS: 'SAVE_COMPANY_DETAILS',
  UPDATE_ACTIVE_COMPANY_DETAILS: 'UPDATE_ACTIVE_COMPANY_DETAILS',
  UPDATE_ACTIVE_COMPANY_JSON: 'UPDATE_ACTIVE_COMPANY_JSON',
  UPDATE_ACTIVE_COMPANY_THEME: 'UPDATE_ACTIVE_COMPANY_THEME',
  UPDATE_ACTIVE_COMPANY_BACKGROUND: 'UPDATE_ACTIVE_COMPANY_BACKGROUND',
  LOGOUT_USER: 'LOGOUT_USER',
  LOGOUT_CLEAR_DATA: 'LOGOUT_CLEAR_DATA',
  GET_COMPANY_DETAILS_BY_ID: 'GET_COMPANY_DETAILS_BY_ID',
  SET_ACTIVE_COMPANY_DETAILS: 'SET_ACTIVE_COMPANY_DETAILS',
  SET_TOGGLE_DISABLED: 'SET_TOGGLE_DISABLED',
  RATE_COMPANY: 'RATE_COMPANY',
  CREATE_NEW_COMPANY: 'CREATE_NEW_COMPANY',
  SET_OPEN_MODAL: 'SET_OPEN_MODAL',
  SET_NEXT_LOCATION: 'SET_NEXT_LOCATION',
  SET_CONFIRMED_NAVIGATION: 'SET_CONFIRMED_NAVIGATION',
  SET_CHANGES_MADE: 'SET_CHANGES_MADE',
  SET_ACTIVE_COMPANY_NAME_ID_SLUG: 'SET_ACTIVE_COMPANY_NAME_ID_SLUG',
};

export const register = (userData) => ({
  type: types.REGISTER_USER,
  payload: {
    userData,
  },
});

export const registerSuccess = (token) => ({
  type: types.REGISTER_SUCCESS,
  payload: {
    token,
  },
});

export const login = (userData) => ({
  type: types.LOGIN_USER,
  payload: {
    userData,
  },
});

export const loginSuccess = (token) => ({
  type: types.LOGIN_SUCCESS,
  payload: {
    token,
  },
});

export const companyLoaded = (companyData) => ({
  type: types.COMPANY_LOADED,
  payload: {
    companyData,
  },
});

export const loadCompanyDetails = (pathName) => ({
  type: types.LOAD_COMPANY_DETAILS,
  payload: { pathName },
});

export const updateCompanyDetails = (sectionId, value) => ({
  type: types.UPDATE_COMPANY_DETAILS,
  payload: {
    sectionId,
    value,
  },
});

export const updateCompanyJson = (sectionId, dataObject) => ({
  type: types.UPDATE_COMPANY_JSON,
  payload: {
    sectionId,
    dataObject,
  },
});

export const updateCompanyTheme = (sectionId, dataObject) => ({
  type: types.UPDATE_COMPANY_THEME,
  payload: {
    sectionId,
    dataObject,
  },
});

export const saveCompanyDetails = (companyData) => ({
  type: types.SAVE_COMPANY_DETAILS,
  payload: {
    companyData,
  },
});

export const logoutUser = () => ({
  type: types.LOGOUT_USER,
  payload: {},
});

export const clearData = () => ({
  type: types.LOGOUT_CLEAR_DATA,
  payload: {},
});

export const getCompanyDetailsById = (id) => ({
  type: types.GET_COMPANY_DETAILS_BY_ID,
  payload: {
    id,
  },
});

export const setActiveCompanyDetails = (details) => ({
  type: types.SET_ACTIVE_COMPANY_DETAILS,
  payload: {
    details,
  },
});

export const setToggleDisabled = (isDisabled) => ({
  type: types.SET_TOGGLE_DISABLED,
  payload: {
    isDisabled,
  },
});

export const rateCompany = (id, rating) => ({
  type: types.RATE_COMPANY,
  payload: {
    rating,
    id,
  },
});

export const updateActiveCompanyDetails = (sectionId, value) => ({
  type: types.UPDATE_ACTIVE_COMPANY_DETAILS,
  payload: {
    sectionId,
    value,
  },
});

export const updateActiveCompanyJson = (sectionId, dataObject) => ({
  type: types.UPDATE_ACTIVE_COMPANY_JSON,
  payload: {
    sectionId,
    dataObject,
  },
});

export const updateActiveCompanyTheme = (companyLogo, color, fontFamily) => ({
  type: types.UPDATE_ACTIVE_COMPANY_THEME,
  payload: {
    companyLogo,
    color,
    fontFamily,
  },
});

export const updateActiveCompanyBackground = (imageUrl) => ({
  type: types.UPDATE_ACTIVE_COMPANY_BACKGROUND,
  payload: {
    imageUrl,
  },
});

export const createNewCompany = (userData) => ({
  type: types.CREATE_NEW_COMPANY,
  payload: {
    userData,
  },
});

export const setOpenModal = (openModal) => ({
  type: types.SET_OPEN_MODAL,
  payload:
  {
    openModal,
  },
});

export const setNextLocation = (nextLocation) => ({
  type: types.SET_NEXT_LOCATION,
  payload:
  {
    nextLocation,
  },
});

export const setConfirmedNavigation = (confirmedNavigation) => ({
  type: types.SET_CONFIRMED_NAVIGATION,
  payload:
  {
    confirmedNavigation,
  },
});

export const setChangesMade = (changesMade) => ({
  type: types.SET_CHANGES_MADE,
  payload:
  {
    changesMade,
  },
});

export const setActiveCompanyNameIdSlug = (name, id, slug) => ({
  type: types.SET_ACTIVE_COMPANY_NAME_ID_SLUG,
  payload: {
    name,
    id,
    slug,
  },
});


export default types;
