import React from 'react';
import ImmutablePropsTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classNames from 'classnames';
import styles from './viewMode.module.scss';

const ViewMode = (props) => {
  const { formData, ratingsCount, currentRating } = props;
  const { t } = useTranslation();

  const maxDecimalPoints = (value, decimals) => value.toFixed(decimals).replace(/\.?0+$/, '');

  return (
    <div className={styles.viewModeSectionContainer}>
      <div className={styles.companyInfoSectionContianer}>
        {ratingsCount > 0 && (
          <div className={styles.profileRatingContianer}>
            <h6>{t('aboutCompany profileRating')}</h6>
            <h3>
              {`${
                maxDecimalPoints((currentRating / ratingsCount), 2)
              }/5`}
            </h3>
          </div>
        )}
        {formData.get('employeeCount', '0000') !== '0000' && (
        <div className={styles.employeeNumberContianer}>
          <h6>{t('aboutCompany numberOfEmployee')}</h6>
          <h3>{formData.get('employeeCount', '0000')}</h3>
        </div>
        )}
      </div>
      {formData.get('sectionTitle', '') && (
        <h5>{t('aboutCompany moreAboutCompanyHeader')}</h5>
      )}
      <h3 className={classNames(styles.inputContainer)}>
        {formData.get('sectionTitle', '')}
      </h3>
      <p className="mt-4">{formData.get('sectionDescription', '')}</p>
      {formData.getIn(['images', 'first']) && (
        <LazyLoadImage
          alt="Company images"
          effect="blur"
          src={formData.getIn(['images', 'first'], '')}
          className={styles.mainImageStyle}
        />
      )}
      <p className="mt-5">{formData.get('shortInfo', '')}</p>
      <div className={classNames(styles.imagesContainer)}>
        {formData.getIn(['images', 'second']) && (
          <div
            className={classNames(
              formData.getIn(['images', 'third'], '') && 'col-md-6 pl-0',
            )}
          >
            <LazyLoadImage
              alt="Company images"
              effect="blur"
              src={formData.getIn(['images', 'second'], '')}
              className={classNames(styles.secondaryImagesStyles)}
            />
          </div>
        )}
        {formData.getIn(['images', 'third'], '') && (
          <div
            className={classNames(
              formData.getIn(['images', 'second'], '') && 'col-md-6 pr-0',
            )}
          >
            <LazyLoadImage
              alt="Company images"
              effect="blur"
              src={formData.getIn(['images', 'third'], '')}
              className={classNames(styles.secondaryImagesStyles)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

ViewMode.propTypes = {
  formData: ImmutablePropsTypes.map.isRequired,
  ratingsCount: PropTypes.number.isRequired,
  currentRating: PropTypes.number.isRequired,
};

export default ViewMode;
