import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  selectCountryIso,
  selectAdminUserRole,
  selectActiveCompanyId,
  selectActiveCompanySlug,
  selectActiveCompanyName,
} from 'selectors/auth';
import {
  uploadNewProductOrDemandImage,
  clearUploadedImage,
} from 'components/CreateCompany/ColorPalette/modules/actions';
import { selectNewProductDemandImage } from 'components/CreateCompany/ColorPalette/modules/selectors';
import {
  selectSuggestedTags,
  selectActiveItem,
} from 'components/Feed/modules/selectors';
import { getSuggestedTags, getItemById } from 'components/Feed/modules/actions';
import { getCompanyDetailsById } from 'actions/auth';
import {
  createProduct, updateProduct, deleteProduct, setProductDemandType,
} from './modules/actions';

const mapStateToProps = (state) => ({
  companyName: selectActiveCompanyName(state),
  newProductImage: selectNewProductDemandImage(state),
  suggestedTags: selectSuggestedTags(state),
  countryIso: selectCountryIso(state),
  itemDetails: selectActiveItem(state),
  adminRole: selectAdminUserRole(state),
  activeCompanySlug: selectActiveCompanySlug(state),
  activeCompanyId: selectActiveCompanyId(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      uploadNewProductOrDemandImage,
      getSuggestedTags,
      createProduct,
      clearUploadedImage,
      getItemById,
      updateProduct,
      deleteProduct,
      getCompanyDetailsById,
      setProductDemandType,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
