import React from 'react';

const ArrowUp = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="4" viewBox="0 0 8 4">
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M5.068 9.5c-.133 0-.261-.041-.363-.116-.116-.084-.189-.206-.203-.338-.013-.132.033-.263.13-.365L7.17 6.007l-2.448-2.68c-.095-.102-.14-.234-.124-.366.016-.133.091-.254.209-.337.119-.091.275-.136.432-.121.157.014.3.085.395.196l2.737 2.994c.172.184.172.449 0 .633L5.538 9.32c-.115.123-.29.19-.47.18z" transform="rotate(-90 3.25 5.25)" />
    </g>
  </svg>


);

export default ArrowUp;
