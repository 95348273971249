import {
  all, call, takeLatest, put,
} from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';
import { uploadService } from 'services/cloudinaryService';
import {
  types,
  setLogo,
  setTestimonialUserImage,
  setUploadedAboutCompanyImage,
  setUploadedNewProducOrDemandImage,
} from './actions';

function * uploadCompanyLogo(action) {
  const data = yield uploadImageToCloudinary(action.payload.data);
  yield put(setLogo(data.secure_url));
}

function * uploadUserImage(action) {
  const data = yield uploadImageToCloudinary(action.payload.data);
  yield put(
    setTestimonialUserImage(action.payload.userObjectKey, data.secure_url),
  );
}

function * uploadAboutCompanyImage(action) {
  const data = yield uploadImageToCloudinary(action.payload.data);
  yield put(
    setUploadedAboutCompanyImage(action.payload.imageKey, data.secure_url),
  );
}

function * uploadNewProductDemandImage(action) {
  const data = yield uploadImageToCloudinary(action.payload.data);
  yield put(
    setUploadedNewProducOrDemandImage(
      action.payload.imageKey,
      data.secure_url,
      data.public_id,
    ),
  );
}

function * uploadImageToCloudinary(imageData) {
  const formData = new FormData();
  formData.append('file', imageData.file);
  formData.append('upload_preset', 'upload_logo');
  const { data, error } = yield call(uploadService, '', formData);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    return data;
  }
}

export default function * colorPaletteSaga() {
  yield all([
    takeLatest(types.UPLOAD_COMPANY_LOGO, uploadCompanyLogo),
    takeLatest(types.UPLOAD_USER_IMAGE, uploadUserImage),
    takeLatest(types.UPLOAD_ABOUT_COMPANY_IMAGE, uploadAboutCompanyImage),
    takeLatest(
      types.UPLOAD_NEW_PRODUCT_DEMAND_IMAGE,
      uploadNewProductDemandImage,
    ),
  ]);
}
