import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteProductById } from '../modules/actions';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      deleteProductById,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps);
