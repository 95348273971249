import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectAdminUserRole, selectCompanySubcriptionPackage } from 'selectors/auth';
import { logoutUser } from '../../../../actions/auth';
import {
  setMessages, setConversations, setConversationId,
  setVisibilityMessagesList,
  setCompanyChat,
  chatDisconnect,
} from '../../../Chat/modules/actions';

const mapStateToProps = (state) => ({
  packageType: selectCompanySubcriptionPackage(state),
  adminRole: selectAdminUserRole(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      logoutUser,
      setMessages,
      setConversations,
      setConversationId,
      setVisibilityMessagesList,
      setCompanyChat,
      chatDisconnect,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
