import React from 'react';
import { getIn } from 'immutable';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Popconfirm } from 'antd'
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import styles from './commentPreview.module.scss';
import profileIcon from 'images/profile-user.png';

const getFormatedDate = (dateString) => {
	const [year, month, day] = dateString.slice(0, 10).split("-");
	return `${day}.${month}.${year}`;
}

const CommentPreview = (props) => {
	const {
		comment,
		userCanEdit,
		userCanDelete,
		handleDeleteComment,
		toggleMode
	} = props;

	const { t } = useTranslation();
	const handleDeleteConfirm = () => {
		handleDeleteComment(comment.get('id', null))
	}

	const companyName = getIn(comment, ['company', 'name'], '');
	const commentContent = comment.get('content', '');
	const companyLogo = getIn(comment, ['company', 'theme', 'companyLogo'], null);
	const createdAt = comment.get('createdAt');
	const createdAtFormated = getFormatedDate(createdAt);

	return (
		<div className={styles.commentPreview}>
			<img
				src={companyLogo || profileIcon}
				alt="companyLogo"
			/>
			<div className={styles.commentPreviewContent}>
				<h4 className={styles.commentPrewiewCompany}>{companyName}</h4>
				<p className={styles.commentPreviewDate}>{createdAtFormated}</p>
				<p className={styles.commentPreviewText}>{commentContent}</p>
			</div>

			<div className={styles.actions}>

				{userCanDelete && (
					<Popconfirm
						placement="top"
						title={t('comment deleteQuestion')}
						onConfirm={handleDeleteConfirm}
						okText={t('comment deleteQuestion yes')}
						cancelText={t('comment deleteQuestion no')}>
						<button
							type="button"
							className={classNames(styles.actionButton, styles.actionButtonRed)}
						>
							{t('comment Delete')}
						</button>
					</Popconfirm>)}

				{userCanEdit && (<button
					type="button"
					className={styles.actionButton}
					onClick={toggleMode}
				>
					{t('comment Edit')}
				</button>)}
			</div>
		</div>
	)
}

const { func, bool } = PropTypes;
const { map } = ImmutablePropTypes;

CommentPreview.propTypes = {
	comment: map.isRequired,
	userCanEdit: bool.isRequired,
	userCanDelete: bool.isRequired,
	handleDeleteComment: func.isRequired,
	toggleMode: func.isRequired,
}

export default CommentPreview