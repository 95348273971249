import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map as IMap } from 'immutable';
import { errorResponseParseHelper } from '../../../../utils/constants';
import { EditableContext } from '../constants';

const EditableCell = (props) => {
  const {
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  } = props;
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      errorResponseParseHelper(errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <td {...restProps}>{childNode}</td>;
};

const {
  string, bool, func, element, shape, number, node,
} = PropTypes;

const { map } = ImmutablePropTypes;

EditableCell.propTypes = {
  title: string,
  editable: bool,
  children: node.isRequired,
  dataIndex: string,
  record: shape({
    block: element,
    country: string,
    delete: element,
    email: string,
    industry: string,
    key: number,
    name: string,
    phone: string,
    rawData: map,
    subscription: element,
    verification: element,
  }),
  handleSave: func,
};

EditableCell.defaultProps = {
  title: '',
  editable: false,
  record: {
    block: null,
    phone: '',
    country: '',
    delete: null,
    email: '',
    industry: '',
    key: null,
    name: '',
    subscription: null,
    verification: null,
    rawData: IMap(),
  },
  handleSave: () => {},
  dataIndex: '',
};

export default EditableCell;
