import React from 'react';

import SliderArrowRight from '../../../svgs/SliderArrowRight';

const NextArrow = (props) => (
  <div {...props}>
    <SliderArrowRight />
  </div>
);

export default NextArrow;
