import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './buttonCancel.module.scss';

const ButtonCancel = (props) => {
  const { handleClick, disabled } = props;
  const { t } = useTranslation();
  return (
    <button
      type="button"
      onClick={handleClick}
      className={classNames(styles.buttonCancelStyle, 'mx-2')}
      disabled={disabled}
    >
      {t('button cancel')}
    </button>
  );
};

const { func } = PropTypes;

ButtonCancel.propTypes = {
  handleClick: func.isRequired,
  disabled: PropTypes.bool,
};

ButtonCancel.defaultProps = {
  disabled: false,
};

export default ButtonCancel;
