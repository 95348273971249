import { fromJS } from 'immutable';

import { types } from './actions';

const defaultState = fromJS({
  products: [],
  demands: [],
  featuredProducts: [],
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_HOMEPAGE_CONTENT:
      return handleSetHomepageContent(state, action);
    case types.SET_FEATURED_PRODUCTS:
      return handleSetFeaturedProducts(state, action);
    default:
      return state;
  }
};

function handleSetHomepageContent(state, action) {
  const { content, type } = action.payload;
  return state.set(`${type}s`, fromJS(content));
}

function handleSetFeaturedProducts(state, action) {
  const { products } = action.payload;
  return state.set('featuredProducts', fromJS(products));
}
