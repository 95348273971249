import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { shape, func, string } from 'prop-types';
import { mapContains, list } from 'react-immutable-proptypes';
import classnames from 'classnames';
import Slider from 'react-slick';

import Category from '../CategoryDateElements/Category';
import DateContent from '../CategoryDateElements/DateContent';
import ForYou from '../ForYou';
import SocialShare from '../../SocialShare';

import styles from './newsArticle.module.scss';

const NewsArticle = (props) => {
  const { actions, article } = props;
  const params = useParams();
  const { slug } = params;

  useEffect(() => {
    actions.getBlogArticles();
    const newSlug = slug.split('?')[0];
    actions.setBlogArticleSlug({ slug: newSlug });
  }, [slug]);

  useEffect(() => {
    if (article.get('slug') === slug) {
      actions.increaseArticleViewCount({ article });
      actions.setSelectedCategory({ category: article.getIn(['category', 'slug']) });
    }
  }, [article]);

  const settings = {
    autoplay: false,
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    slideToScroll: true,
  };

  if (article.size === 0) {
    return (
      <div className={classnames('container', styles.newsArticleContainer)}>
        Article not found
      </div>
    );
  }

  return (
    <div className="container">
      <div className={styles.newsArticleContainer}>
        <div className={styles.newsArticle}>
          <Category showCategory category={article.getIn(['category', 'slug'])} />
          <h1 className={styles.title}>{article.get('title')}</h1>
          <div className={styles.dateAndShareContainer}>
            <DateContent date={article.get('date')} />
            <SocialShare className={styles.socialShare} url={window.location.href} />
          </div>
          {
            article.get('firstContent') === null
              ? null
              : <ReactMarkdown className={styles.content} children={article.get('firstContent')} />
          }
          {
            article.get('video') === null
              ? null
              : (
                <video className={styles.video} controls>
                  <source src={article.getIn(['video', 'url'])} type={article.getIn(['video', 'mime'])} />
                </video>
              )
          }
          {
            article.get('secondContent') === null
              ? null
              : <ReactMarkdown className={styles.content} children={article.get('secondContent')} />
          }
          {
            article.getIn(['imageSlider', 'images']) === null
              ? null
              : (
                <Slider {...settings}>
                  {article.getIn(['imageSlider', 'images']).map((img) => (
                    <div key={img.getIn(['image', 'name'])} className={styles.imgSlide}>
                      <img src={img.getIn(['image', 'url'])} alt={img.get('description', img.getIn(['image', 'name']))} />
                      {img.get('description') === null
                        ? null
                        : <p>{img.get('description')}</p>}
                    </div>
                  ))}
                </Slider>
              )
          }
        </div>
        <ForYou articleView />
      </div>
    </div>
  );
};

NewsArticle.propTypes = {
  actions: shape({
    getBlogArticles: func.isRequired,
    setSelectedCategory: func.isRequired,
    setBlogArticleSlug: func.isRequired,
    increaseArticleViewCount: func.isRequired,
  }).isRequired,
  article: mapContains({
    title: string,
    description: string,
    firstContent: string,
    date: string,
    video: mapContains({
      url: string,
      mime: string,
    }),
    secondContent: string,
    category: mapContains({
      name: string,
    }),
    imageSlider: mapContains({ images: list }),
  }).isRequired,
};

export default NewsArticle;
