import { parseError } from '../utils/parse-helper';
import { AuthorizedApiService } from './apiService';

const industryInterestApi = 'industry-interest';

export const getIndustryInterestsService = async () => AuthorizedApiService
  .get(industryInterestApi)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const saveIndustryInterestService = async (postData) => AuthorizedApiService
  .post(industryInterestApi, postData)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const deleteIndustryInterestService = async (id) => AuthorizedApiService
  .delete(`${industryInterestApi}/${id}`)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));
