import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectActiveCompanyLogo } from 'selectors/auth';
import { uploadLogo } from '../modules/actions';
import { selectCompanyLogo } from '../modules/selectors';


const mapStateToProps = (state) => ({
  imageUrl: selectCompanyLogo(state),
  companyLogo: selectActiveCompanyLogo(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      uploadLogo,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
