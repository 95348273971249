import React from 'react';
import { string, bool } from 'prop-types';
import { ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import classnames from 'classnames';

import styles from './CategoryDateElements.module.scss';

const DateContent = (props) => (
  <div className={styles.dateContainer}>
    <ClockCircleOutlined className={styles.clockIcon} />
    <span className={classnames(styles.date, props.isBanner && styles.dateBanner)}>
      {moment(props.date).format('LL')}
    </span>
  </div>
);

DateContent.propTypes = {
  date: string.isRequired,
  isBanner: bool,
};
DateContent.defaultProps = {
  isBanner: false,
};

export default DateContent;
