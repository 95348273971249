import { fromJS } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  newNotificationsCount: 0,
  notifications: [],
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_NOTIFICATIONS_COUNT:
      return state.set('newNotificationsCount', action.payload.count);
    case types.SET_NOTIFICATIONS:
      return state.set('notifications', fromJS(action.payload.notifications));
    default:
      return state;
  }
};
