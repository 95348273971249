import { List as IList, Map as IMap } from 'immutable';
import { createSelector } from 'reselect';

export const selectSelectedCategory = (state) => state.blogReducer.get('selectedCategory', 'all');

const selectAllArticles = (state) => state.blogReducer.get('articles', IList());

export const selectArticles = createSelector(
  (state) => selectAllArticles(state),
  (state) => selectSelectedCategory(state),
  (articles, selectedCategory) => {
    if (selectedCategory === 'all') {
      return articles;
    }
    return articles.filter((article) => article.getIn(['category', 'slug']) === selectedCategory);
  },
);

export const selectPromoArticles = createSelector(
  (state) => selectAllArticles(state),
  (articles) => articles.filter((article) => article.getIn(['category', 'slug']) === 'promo'),
);

export const selectBlogArticles = createSelector(
  (state) => selectAllArticles(state),
  (articles) => articles.filter((article) => article.getIn(['category', 'slug']) === 'blog'),
);

export const selectBlogArticleBySlug = createSelector(
  (state) => selectAllArticles(state),
  (state) => state.blogReducer.get('activeArticle', ''),
  (articles, activeArticle) => articles.find((article) => article.get('slug') === activeArticle) || IMap(),
);

export const selectForYouArticles = (state) => state.blogReducer.get('forYouArticles', IList());
