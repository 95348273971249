import React from 'react';
import { string } from 'prop-types';

const AncorIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 42.76 50.143"
  >
    <g
      id="prefix__Group_640"
      data-name="Group 640"
      transform="translate(-453.9 -174.116)"
    >
      <g
        id="prefix__Group_180"
        data-name="Group 180"
        transform="translate(34.198 -16.855)"
      >
        <g id="prefix__Group_103" data-name="Group 103">
          <g id="prefix__Group_99" data-name="Group 99">
            <path
              id="prefix__Rectangle_12"
              d="M0 0H4V36.986H0z"
              className="prefix__cls-1"
              data-name="Rectangle 12"
              transform="translate(440.128 200.971)"
              stye={{ fill: '#050713' }}
            />
          </g>
          <g id="prefix__Group_100" data-name="Group 100">
            <path
              id="prefix__Path_134"
              d="M450.181 210.921h-16.105a2 2 0 0 1-2-2 2 2 0 0 1 2-2h16.105a2 2 0 0 1 2 2 2 2 0 0 1-2 2z"
              className="prefix__cls-2"
              data-name="Path 134"
              style={{ fill: color }}
            />
          </g>
          <g id="prefix__Group_101" data-name="Group 101">
            <path
              id="prefix__Path_135"
              d="M442.128 202.971a6 6 0 1 1 6-6 6.007 6.007 0 0 1-6 6zm0-8a2 2 0 1 0 2 2 2 2 0 0 0-2-2z"
              className="prefix__cls-2"
              data-name="Path 135"
              style={{ fill: color }}
            />
          </g>
          <g id="prefix__Group_102" data-name="Group 102">
            <path
              id="prefix__Path_136"
              d="M442.128 239.957a20.356 20.356 0 0 1-20.333-20.333 4.533 4.533 0 0 1 4.4 3.591 16.336 16.336 0 0 0 31.871 0 4.532 4.532 0 0 1 4.4-3.591 20.356 20.356 0 0 1-20.338 20.333z"
              className="prefix__cls-1"
              data-name="Path 136"
            />
          </g>
        </g>
        <path
          id="prefix__Path_137"
          d="M440.035 241.114a20.357 20.357 0 0 0 20.334-20.333 4.533 4.533 0 0 0-4.4 3.591 16.366 16.366 0 0 1-13.936 12.6v-24.9h6.053a2 2 0 0 0 0-4h-6.053v-4.3a6 6 0 1 0-4 0v4.3h-6.052a2 2 0 0 0 0 4h6.052v24.9a16.365 16.365 0 0 1-13.935-12.6 4.532 4.532 0 0 0-4.4-3.591 20.356 20.356 0 0 0 20.337 20.333zm0-44.986a2 2 0 1 1-2 2 2 2 0 0 1 2-2z"
          data-name="Path 137"
          style={{ opacity: 0.2, fill: color }}
        />
      </g>
    </g>
  </svg>
);

AncorIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

AncorIcon.defaultProps = {
  color: '#37ead9',
  width: '42.76',
  height: '50.143',
};

export default AncorIcon;
