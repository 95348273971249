import React from 'react';

const EyeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
    <g fill="#FFF">
      <path d="M19.853 6.575c-.178-.267-4.431-6.54-9.863-6.54C4.558.036.305 6.309.127 6.576c-.17.253-.17.597 0 .85.178.267 4.431 6.54 9.863 6.54 5.432 0 9.685-6.273 9.863-6.54.17-.253.17-.597 0-.85zM9.99 12.523C5.99 12.523 2.524 8.36 1.498 7 2.522 5.637 5.98 1.477 9.99 1.477c4.001 0 7.466 4.162 8.492 5.523C17.458 8.363 14 12.523 9.99 12.523z" />
      <path d="M9.99 2.677c-2.18 0-3.952 1.94-3.952 4.323 0 2.383 1.773 4.323 3.952 4.323 2.18 0 3.952-1.94 3.952-4.323 0-2.383-1.773-4.323-3.952-4.323zm0 7.205C8.537 9.882 7.355 8.589 7.355 7c0-1.589 1.182-2.882 2.635-2.882 1.453 0 2.635 1.293 2.635 2.882 0 1.589-1.182 2.882-2.635 2.882z" />
    </g>
  </svg>


);

export default EyeIcon;
