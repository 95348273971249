import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import classnames from 'classnames';

import styles from '../../subscriptionPackages.module.scss';

const ItemDetatilsSection = (props) => {
  const { title, isIncluded, isRecommended } = props;

  return (
    <div className={styles.detailedItemContainer}>
      {isIncluded ? (
        <CheckCircleFilled className={isRecommended ? styles.recommended : styles.included} />
      ) : (
        <CloseCircleFilled className={styles.notIncluded} />
      )}
      <h4
        className={classnames(
          styles.detailedItem,
          isIncluded ? styles.included : styles.notIncluded,
          isIncluded && isRecommended ? styles.recommended : '',
        )}
      >
        {title}
      </h4>
    </div>
  );
};

const { bool, string } = PropTypes;

ItemDetatilsSection.propTypes = {
  title: string.isRequired,
  isIncluded: bool.isRequired,
  isRecommended: bool.isRequired,
};

export default ItemDetatilsSection;
