import React from 'react';
import { string } from 'prop-types';

const CreditCardIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 51.291 39.691"
  >
    <g
      id="prefix__Group_667"
      data-name="Group 667"
      transform="translate(-452.329 -626.113)"
    >
      <g
        id="prefix__Group_625"
        data-name="Group 625"
        transform="translate(-1807 254.661)"
      >
        <path
          id="prefix__Rectangle_42"
          d="M0 0H45.289V5H0z"
          className="prefix__cls-1"
          data-name="Rectangle 42"
          transform="translate(2263.524 385.015)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_645"
          d="M436.716 251.065h-5.564a2 2 0 0 1-2-2 2 2 0 0 1 2-2h5.564a2 2 0 0 1 2 2 2 2 0 0 1-2 2z"
          className="prefix__cls-1"
          data-name="Path 645"
          transform="translate(1839 151)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_646"
          d="M464.151 258.85h-35.062a7.477 7.477 0 0 1-7.469-7.469v-23.459a7.478 7.478 0 0 1 7.469-7.47h35.062a7.478 7.478 0 0 1 7.469 7.47v23.459a7.477 7.477 0 0 1-7.469 7.469zm-35.062-34.4a3.474 3.474 0 0 0-3.469 3.47v23.459a3.473 3.473 0 0 0 3.469 3.469h35.062a3.473 3.473 0 0 0 3.469-3.469v-23.456a3.474 3.474 0 0 0-3.469-3.47z"
          data-name="Path 646"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_647"
          d="M435.425 248.357h-5.564a2 2 0 0 0 0 4h5.564a2 2 0 0 0 0-4z"
          className="prefix__cls-3"
          data-name="Path 647"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
        <path
          id="prefix__Path_648"
          d="M462.86 221.745H427.8a7.478 7.478 0 0 0-7.469 7.47v23.459a7.478 7.478 0 0 0 7.469 7.469h35.06a7.478 7.478 0 0 0 7.469-7.469v-23.459a7.478 7.478 0 0 0-7.469-7.47zm-35.062 4h35.062a3.474 3.474 0 0 1 3.469 3.47v6.092h-42v-6.092a3.473 3.473 0 0 1 3.471-3.47zm35.062 30.4H427.8a3.473 3.473 0 0 1-3.469-3.469v-12.37h42v12.367a3.473 3.473 0 0 1-3.471 3.469z"
          className="prefix__cls-3"
          data-name="Path 648"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
      </g>
    </g>
  </svg>
);

CreditCardIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

CreditCardIcon.defaultProps = {
  color: '#37ead9',
  width: '51.291',
  height: '39.691',
};

export default CreditCardIcon;
