import React, { useState, useEffect } from 'react';
import PropTypes, { string } from 'prop-types';
import classNames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useTranslation } from 'react-i18next';
import { AutoComplete } from 'antd';
import inputStyle from 'utils/inputStyle.module.scss';
import styles from './filters.module.scss';
import checkFeedFilterType from '../checkFeedFilterType-helper';

const { Option } = AutoComplete;

const Filters = (props) => {
  const {
    suggestedTags,
    updateTagsQueryData,
    activeTagId,
    activeIndustry,
    resetFilters,
    searchByType,
    queryDataType,
    industriesList,
    searchByIndustry,
  } = props;
  const { t } = useTranslation();
  const [filterIsActive, setFilterActiveStatus] = useState(true);
  const [industries, setIndustries] = useState(industriesList);
  const [selectedIndustry, setSelectedIndustry] = useState(null);

  const resetFilter = () => {
    resetFilters();
    setFilterActiveStatus(true);
  };

  const findIndustryByName = (name) =>
    industriesList.find((industry) => industry.get('name', '') === name);

  const handleSelectIndustry = (selectedIndustryName) => {
    const industryObject = findIndustryByName(selectedIndustryName);
    setSelectedIndustry(industryObject);
  };

  const handleOnBlur = (e) => {
    if (e.target.value === '') {
      searchByIndustry({});
    }
  };

  const filterIndustrySearch = (input, option) =>
    option.value && option.value.toUpperCase().includes(input.toUpperCase());

  useEffect(() => {
    if (industriesList.size > 0) {
      setIndustries(
        industriesList.map((item) => ({
          value: item.get('name', ''),
          key: item.get('id', ''),
        }))
      );
    }
  }, [industriesList]);

  useEffect(() => {
    const findIndustry = (id) =>
      industriesList.find(
        (industry) => industry.get('id', '') === parseInt(id, 10)
      );
    const industryObject = findIndustry(activeIndustry);
    setSelectedIndustry(industryObject?.get('name', ''));
  }, [activeIndustry, industriesList]);

  useEffect(() => {
    if (selectedIndustry === '') {
      searchByIndustry({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndustry]);

  return (
    <div className={styles.filtersContainer}>
      <h3>{t('feed filters title')}</h3>
      <h4
        role="presentation"
        onClick={resetFilter}
        className={classNames(
          !queryDataType && filterIsActive && 'font-weight-bold'
        )}
      >
        {t('feed filters dropdownTitle showAll')}
      </h4>
      <div className={styles.horizontalBreakLineStyle} />
      <h4
        role="presentation"
        onClick={() => {
          searchByType('product');
          setFilterActiveStatus(false);
        }}
        className={classNames(
          queryDataType === 'product' && 'font-weight-bold'
        )}
      >
        {t('feed filters dropdownTitle productsServices')}
      </h4>
      <div className={styles.horizontalBreakLineStyle} />
      <h4
        role="presentation"
        className={classNames(queryDataType === 'demand' && 'font-weight-bold')}
        onClick={() => {
          searchByType('demand');
          setFilterActiveStatus(false);
        }}
      >
        {t('feed filters dropdownTitle demand')}
      </h4>
      <div className={styles.horizontalBreakLineStyle} />
      <h4
        role="presentation"
        className={classNames(
          queryDataType === 'companies' && 'font-weight-bold'
        )}
        onClick={() => {
          searchByType('companies');
          setFilterActiveStatus(false);
        }}
      >
        {t('feed filters dropdownTitle companies')}
      </h4>
      <div className={styles.horizontalBreakLineStyle} />
      <div
        className={classNames(
          inputStyle.formGroup,
          'my-3 p-0',
          styles.industryInputSearchStyle
        )}
        id="industry"
      >
        <h5 className="px-1 ml-3">{t('register input industry label')}</h5>
        <AutoComplete
          onChange={handleSelectIndustry}
          value={findIndustryByName(selectedIndustry)?.get('name', '')}
          defaultValue={findIndustryByName(selectedIndustry)?.get('name', '')}
          placeholder={t('register input industry placeholder')}
          className="my-2"
          filterOption={(input, option) => filterIndustrySearch(input, option)}
          onSelect={(value) =>
            searchByIndustry(industries.filter((item) => item.value === value))
          }
          onBlur={handleOnBlur}
        >
          {industries.map((opt) => (
            <Option value={opt.value} key={opt.value}>
              {opt.name}
            </Option>
          ))}
        </AutoComplete>
      </div>
      {checkFeedFilterType('companies') ? (
        <h3 className="pt-4">{t('feed filters suggestedCategories')}</h3>
      ) : null}
      <div className={styles.tagsContainer}>
        {suggestedTags.size > 0 &&
          suggestedTags.map((tag) => (
            <p
              key={tag.get('key')}
              role="presentation"
              onClick={() => {
                updateTagsQueryData(tag.get('key'));
                setFilterActiveStatus(true);
              }}
              className={classNames(
                parseInt(activeTagId, 10) === tag.get('key') &&
                  styles.activeTagStyle
              )}
            >
              {tag.get('value')}
            </p>
          ))}
      </div>
      {/* <a href="https://www.bhtelecom.ba/usluge-za-privatne-korisnike/2023/10/novi-i-brzi-netflat-i-internet-povremeno-paketi/">
        <img
          src="https://res.cloudinary.com/bizbook-blog/image/upload/v1696512159/Screenshot_2023_10_05_at_15_22_26_89785ee33d.png"
          alt="banner"
          style={{ width: '100%' }}
        />
      </a> */}
    </div>
  );
};

const { list } = ImmutablePropTypes;
const { func, number } = PropTypes;

Filters.propTypes = {
  suggestedTags: list.isRequired,
  updateTagsQueryData: func.isRequired,
  activeTagId: number,
  activeIndustry: number,
  resetFilters: func.isRequired,
  searchByType: func.isRequired,
  queryDataType: string,
  industriesList: list.isRequired,
  searchByIndustry: func.isRequired,
};

Filters.defaultProps = {
  activeTagId: null,
  activeIndustry: null,
  queryDataType: '',
};

export default Filters;
