import { fromJS } from 'immutable';

const initialState = fromJS({
  center: { },
  zoom: 7,
  draggable: true,
  lat: null,
  lng: null,
});

const setDraggable = (status) => ({
  type: 'SET_DRAGABBLE',
  payload: { status },
});

const setMarkerPosition = (coordinates) => ({
  type: 'SET_MARKER_POSITION',
  payload: { coordinates },
});

const setMapCenter = (coordinates) => ({
  type: 'SET_MAP_CENTER',
  payload: { coordinates },
});

const setZoomLevel = (zoom) => ({
  type: 'SET_ZOOM_LEVEL',
  payload: { zoom },
});

function reducer(state, action) {
  switch (action.type) {
    case 'SET_DRAGABBLE':
      return state.set('draggable', action.payload.status);
    case 'SET_MARKER_POSITION':
      return state.merge({
        lat: action.payload.coordinates.lat,
        lng: action.payload.coordinates.lng,
      });
    case 'SET_MAP_CENTER':
      return state.set('center', fromJS(action.payload.coordinates));
    case 'SET_ZOOM_LEVEL':
      return state.set('zoom', action.payload.zoom);
    default:
      return state;
  }
}

export {
  reducer,
  initialState,
  setDraggable,
  setMarkerPosition,
  setMapCenter,
  setZoomLevel,
};
