import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import InputContainer from 'utils/InputMain';
import buttonStyle from 'utils/buttonStyle.module.scss';
import useForm from 'utils/hooks/useForm';
import validate from '../validateEmailForm';
import styles from '../login.module.scss';
import confirmationStyles from '../loginContainer.module.scss';
import { loginExpired } from "../../../images";

const ResendEmailActivation = (props) => {
  const { actions } = props;
  const { t } = useTranslation();

  const {
    values: { email }, errors, handleChange, handleSubmit,
  } = useForm(
    sendEmail,
    validate,
  );

  function sendEmail() {
    actions.reactivateEmail(email);
  }

  return (
    <div className={classNames(confirmationStyles.loginFormContainer, 'col-md-12')}>
      <div className={confirmationStyles.loginHeader}>
        <img src={loginExpired} alt="link expired" />
        <div className={confirmationStyles.loginHeader}>
          <h1>{t('login linkExpired headline')}</h1>
          <h2>{t('login linkExpired tagline')}</h2>
        </div>
      </div>
      <div className={classNames(confirmationStyles.loginHeader, 'col-lg-5 col-md-6 col-sm-6 col-12')}>
        <form onSubmit={handleSubmit} noValidate>
          <div className={classNames(styles.formContainer, 'px-3   mx-auto')}>
            <InputContainer
              label={t('login input email label')}
              name="email"
              type="email"
              placeholder={t('login input email placeholder')}
              value={email}
              handleChange={handleChange}
              error={errors.email}
              customStyle="col-md-12 col-lg-12"
            />
            <button
              type="submit"
              className={classNames(
                buttonStyle.buttonSuccessStyle,
                'my-3 col-12',
                styles.buttonSuccessStyle,
              )}
            >
              {t('login linkExpired sendLinkButtonText')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const { shape, func } = PropTypes;

ResendEmailActivation.propTypes = {
  actions: shape({
    reactivateEmail: func.isRequired,
  }).isRequired,
};

export default ResendEmailActivation;
