import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import buttonStyle from 'utils/buttonStyle.module.scss';
import { Link } from 'react-router-dom';
import inputStyle from 'utils/inputStyle.module.scss';
import classNames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import useForm from 'utils/hooks/useForm';
import InputContainer from 'utils/InputMain';
import { EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { AutoComplete, Checkbox } from 'antd';
import { termsAndConditionsUrl, privacyPoliciesUrl } from 'utils/pathsHelper';
import i18n from "i18next";
import validate from '../validateRegisterForm';
import styles from '../register.module.scss';
import history from '../../../history';
import filterSearch from '../../../utils/filterSearch';

const Register = (props) => {
  const {
    actions,
    countriesList,
    activeLanguage,
    industriesList,
    handleRegister,
    createCompanyModal,
    registerData,
    emailExists,
  } = props;
  const { t } = useTranslation();
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleSelectCountry,
    handleChangeCheckbox,
    handleIndustrySelect,
    setValues,
    setErrors,
  } = useForm(register, validate);
  const [options, setOptions] = useState(countriesList);
  const [industries, setIndustries] = useState(industriesList);

  function setEmailExistsError() {
    setErrors({
      email: i18n.t('inputValidationError email inUse'),
    });
  }

  function register() {
    if (!emailExists) {
      handleRegister(values);
    } else {
      setEmailExistsError();
    }
  }

  function handleOnBlurEmail() {
    if (values.email) {
      actions.checkEmailExists(values.email);
    } else {
      setErrors({
        email: null,
      });
    }
  }

  useEffect(() => {
    if (emailExists) {
      setEmailExistsError();
    } else {
      const { email, ...newErrors } = errors;
      setErrors(newErrors);
    }
  }, [emailExists]);

  useEffect(() => {
    actions.getCountriesList();
    actions.getIndustriesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countriesList.size > 0) {
      setOptions(
        countriesList
          .sort((a, b) => a.get('name', '').localeCompare(b.get('name', '')))
          .map((item) => ({
            value: item.getIn(
              ['translations', activeLanguage === 'eng' ? 'en' : 'hr'],
              item.get('name', 'null'),
            ),
            name: item.get('ISO', ''),
            key: item.get('ISO', ''),
          })),
      );
    }
  }, [activeLanguage, countriesList]);

  useEffect(() => {
    if (industriesList.size > 0) {
      setIndustries(
        industriesList.map((item) => ({
          value: item.get('name', ''),
          key: item.get('id', ''),
        })),
      );
    }
  }, [industriesList]);

  useEffect(() => {
    setValues({
      company: registerData.company,
      country: registerData.country,
      email: registerData.email,
      industry: registerData.industry,
      password: registerData.password,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h3 className={classNames(styles.headlineRegister, 'py-5')}>
        {createCompanyModal ? 'Create new company' : t('register headline')}
      </h3>
      <h4 className={classNames(styles.mobileTitleRegister, 'mt-5 mb-4 px-3')}>
        {createCompanyModal
          ? 'Create new company'
          : t('register headlineMobile')}
      </h4>
      <form onSubmit={handleSubmit} noValidate>
        <div className={styles.inputsContainer}>
          <div
            className={classNames(
              'col-md-8 col-12 col-sm-8 col-md-5 col-lg-5 my-3 mx-5 mx-auto',
            )}
          >
            <h4 className={classNames(styles.titleRegister, 'pb-3')}>
              {t('register subtitle')}
            </h4>
          </div>
          <div
            className={classNames(
              inputStyle.formGroup,
              'col-12 col-sm-8 col-lg-5 my-3 mx-5 mx-auto p-0',
              errors.country ? inputStyle.validationError : '',
              styles.countryInputStyle,
            )}
            id="country"
          >
            <h6 className={inputStyle.error}>{errors.country}</h6>
            <h5 className="px-1 ml-3">{t('register input country label')}</h5>
            <AutoComplete
              options={options}
              onChange={handleSelectCountry}
              value={values.country ? values.country.value : ''}
              placeholder={t('register input country placeholder')}
              className="my-2"
              filterOption={(inputValue, option) => filterSearch(inputValue, option)}
            />
          </div>
          <InputContainer
            label={t('register input email label')}
            name="email"
            type="email"
            placeholder={t('register input email placeholder')}
            value={values.email}
            handleChange={handleChange}
            onBlur={handleOnBlurEmail}
            error={errors.email}
            id="email"
            customStyle="col-md-8"
          />

          <div
            className={classNames(
              inputStyle.formGroup,
              'col-12 col-sm-8 col-lg-5 my-3 mx-5 mx-auto',
              errors.password ? inputStyle.validationError : '',
            )}
            id="password"
          >
            <h6 className={inputStyle.error}>{errors.password}</h6>
            <h5 className="px-1">{t('register input password label')}</h5>
            <div className={inputStyle.passwordContainer}>
              <input
                type={passwordFieldType}
                className={classNames(inputStyle.formControl, 'py-2 my-1')}
                placeholder={t('register input password placeholder')}
                name="password"
                value={values.password || ''}
                onChange={handleChange}
              />
              <EyeOutlined
                onClick={() => setPasswordFieldType(
                  passwordFieldType === 'password' ? 'text' : 'password',
                )}
                className={classNames(
                  inputStyle.passwordInputImgStyle,
                  passwordFieldType !== 'password' ? 'text-info' : '',
                )}
              />
            </div>
          </div>
          <InputContainer
            label={t('register input company label')}
            name="company"
            type="text"
            placeholder={t('register input company placeholder')}
            value={values.company}
            handleChange={handleChange}
            error={errors.company}
            customStyle="col-md-8"
          />
          <div
            className={classNames(
              inputStyle.formGroup,
              'col-12 col-sm-8 col-lg-5 my-3 mx-5 mx-auto p-0',
              errors.industry ? inputStyle.validationError : '',
              styles.countryInputStyle,
            )}
            id="industry"
          >
            <h6 className={inputStyle.error}>{errors.industry}</h6>
            <h5 className="px-1 ml-3">{t('register input industry label')}</h5>
            <AutoComplete
              options={industries}
              onChange={handleIndustrySelect}
              value={values.industry ? values.industry.value : ''}
              placeholder={t('register input industry placeholder')}
              className="my-2"
              filterOption={(inputValue, option) => filterSearch(inputValue, option)}
            />
          </div>
          <div
            className={classNames(
              styles.checkoxFormGroup,
              'col-12 col-sm-8 col-lg-5 my-md-3 mx-5 mx-auto',
            )}
          >
            <h6 className={classNames(styles.error, styles.checkboxErrorStyle)}>
              {errors.termsOfUse}
            </h6>
            <div className={styles.checkBoxContainer}>
              <Checkbox
                onChange={handleChangeCheckbox}
                className={classNames(
                  styles.formControl,
                  'my-md-1 mt-2 mt-md-0',
                  errors.termsOfUse && styles.validationErrorStyle,
                )}
                name="termsOfUse"
              />
            </div>
            <h4 className="ml-3 mt-md-1">
              {t('register checkbox termsAndConditions accept')}
              {' '}
              <span className="font-weight-bold">
                <Link to={termsAndConditionsUrl}>
                  {t('register checkbox termsAndConditions termsOfUse')}
                </Link>
                <Link to={privacyPoliciesUrl}>
                  {t('register checkbox termsAndConditions privacyPolicies')}
                </Link>
              </span>
            </h4>
          </div>
          <div
            className={classNames(
              styles.checkoxFormGroup,
              'col-12 col-sm-8 col-lg-5 my-md-3 mx-5 mx-auto',
            )}
          >
            <h6 className={classNames(styles.error, styles.checkboxErrorStyle)}>
              {errors.companyAuthorization}
            </h6>
            <div className={styles.checkBoxContainer}>
              <Checkbox
                onChange={handleChangeCheckbox}
                className={classNames(
                  styles.formControl,
                  'my-md-1 mt-2 mt-md-0',
                  errors.companyAuthorization && styles.validationErrorStyle,
                )}
                name="companyAuthorization"
              />
              <h4 className="ml-3 mt-md-1">
                <span className="font-weight-bold">
                  {t('register checkbox companyAuthorization allowed')}
                </span>
                {' '}
                {t('register checkbox companyAuthorization toRegisterCompany')}
              </h4>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            styles.buttonContainer,
            'mb-3 col-12 mt-0 mt-md-0 ml-auto pr-lg-5',
          )}
        >
          <button
            type="button"
            onClick={() => (createCompanyModal ? actions.hideModal() : history.push('/'))}
            className={classNames(
              buttonStyle.mainButtonStyle,
              styles.mainButtonStyle,
              'my-1 my-md-5 mr-2',
            )}
          >
            {t('register button cancel')}
          </button>
          <button
            type="submit"
            className={classNames(
              buttonStyle.buttonSuccessStyle,
              styles.buttonSuccessStyle,
              'my-1 my-md-5',
            )}
          >
            {t('register button next')}
          </button>
        </div>
      </form>
    </div>
  );
};

const {
  shape, func, string, bool,
} = PropTypes;
const { list } = ImmutablePropTypes;

Register.propTypes = {
  actions: shape({
    getCountriesList: func.isRequired,
    getIndustriesList: func.isRequired,
    hideModal: func.isRequired,
    checkEmailExists: func.isRequired,
  }).isRequired,
  countriesList: list.isRequired,
  activeLanguage: string.isRequired,
  industriesList: list.isRequired,
  handleRegister: func.isRequired,
  createCompanyModal: bool.isRequired,
  registerData: shape({}),
  emailExists: bool.isRequired,
};

Register.defaultProps = {
  registerData: {},
};

export default Register;
