import React from 'react';
import PropTypes from 'prop-types';

import styles from './Banner.module.scss';

const BannerItem = (props) => {
  const { img, url, alt } = props;

  return (
    <a href={url} target="_blank">
      <img
        className={styles.banner}
        src={img}
        alt={alt}
      />
    </a>
  );
};

const { string } = PropTypes;

BannerItem.propTypes = {
  img: string.isRequired,
  url: string.isRequired,
  alt: string.isRequired,
};

export default BannerItem;
