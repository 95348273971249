import React from 'react';

const SliderArrowLeft = () => (
  <svg width="70px" height="69px" viewBox="0 0 70 69" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Group 15</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Hompage-update" transform="translate(-44.000000, -1133.000000)">
        <g id="Group-30" transform="translate(44.000000, 590.000000)">
          <g id="Group-15" transform="translate(0.000000, 543.000000)">
            <path d="M35,0 C54.3299662,0 70,15.4461761 70,34.5 C70,53.5538239 54.3299662,69 35,69 C15.6700338,69 0,53.5538239 0,34.5 C0,15.4461761 15.6700338,0 35,0 Z" id="Rectangle" fillOpacity="0.106834572" fill="#000000" transform="translate(35.000000, 34.500000) scale(-1, 1) translate(-35.000000, -34.500000) " />
            <path d="M19,22.0438968 L19,44.9564362 C19,46.6373501 20.39764,48 22.1217164,48 C22.7344047,48 23.3335478,47.8242195 23.8444883,47.4945621 L41.600714,36.0382924 C43.0384768,35.1106517 43.4327026,33.2222926 42.4812419,31.8205244 C42.2498457,31.4796129 41.9503794,31.1876438 41.600714,30.9620406 L23.8444883,19.5057709 C22.4067255,18.5781302 20.4698772,18.9624865 19.5184166,20.3642547 C19.1802941,20.8624038 19,21.4465472 19,22.0438968 Z" id="Path-Copy" fill="#FFFFFF" transform="translate(31.000000, 33.500000) scale(-1, 1) translate(-31.000000, -33.500000) " />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SliderArrowLeft;
