import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import {
  rateBizbook,
  stylesSelection,
  wayOfUsingBizbook,
} from 'images/tutorial';
import buttonStyle from 'utils/buttonStyle.module.scss';
import { composeEditCompanyUrl } from 'utils/pathsHelper';
import styles from './tutorial.module.scss';

const settings = {
  dots: true,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  customPaging: () => <div className="custom-paging" />,
};

const Tutorial = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.sliderContainer}>
      <Slider {...settings} className={classNames(styles.slider)}>
        <div className={styles.styleSelection}>
          <img src={stylesSelection} alt="bizbook registration tutorial" />
          <div className={styles.textContainer}>
            <h3 className="my-4">{t('tutorial styleSelection title')}</h3>
            <p className="mb-5 mb-md-0">{t('tutorial styleSelection text')}</p>
          </div>
          <div className={styles.rateBizbookButtonContainer}>
            <Link to="/">
              <button
                type="button"
                className={classNames(
                  buttonStyle.buttonSuccessStyle,
                  styles.cancelButtonStyle,
                )}
              >
                {t('tutorial cancelButtonText')}
              </button>
            </Link>
          </div>
        </div>
        <div className={styles.wayOfUsingSite}>
          <img src={wayOfUsingBizbook} alt="bizbook registration tutorial" />
          <div className={styles.textContainer}>
            <h3 className="my-4">{t('tutorial wayOfUsingSite title')}</h3>
            <p className="mb-5 mb-md-0">{t('tutorial wayOfUsingSite text')}</p>
          </div>
          <div className={styles.rateBizbookButtonContainer}>
            <Link to="/">
              <button
                type="button"
                className={classNames(
                  buttonStyle.buttonSuccessStyle,
                  styles.cancelButtonStyle,
                )}
              >
                {t('tutorial cancelButtonText')}
              </button>
            </Link>
          </div>
        </div>
        <div className={styles.rateBizbook}>
          <img src={rateBizbook} alt="bizbook registration tutorial" />
          <div className={styles.textContainer}>
            <h3 className="my-4">{t('tutorial rateBizbook title')}</h3>
            <p className="mb-5 mb-md-0">{t('tutorial rateBizbook text')}</p>
          </div>
          <div className={styles.rateBizbookButtonContainer}>
            <Link to="/">
              <button
                type="button"
                className={classNames(
                  buttonStyle.buttonSuccessStyle,
                  styles.cancelButtonStyle,
                  'mr-0 ml-md-2',
                )}
              >
                {t('tutorial cancelButtonText')}
              </button>
            </Link>
            <Link to={composeEditCompanyUrl(localStorage.getItem('userId'))}>
              <button
                type="button"
                className={classNames(
                  buttonStyle.buttonSuccessStyle,
                  styles.createPageButtonStyle,
                  'ml-0 ml-md-2',
                )}
              >
                {t('tutorial createPageButtonText')}
              </button>
            </Link>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Tutorial;
