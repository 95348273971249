import React from 'react';
import { string, bool } from 'prop-types';
import { mapContains, listOf } from 'react-immutable-proptypes';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import DateContent from '../CategoryDateElements/DateContent';
import Category from '../CategoryDateElements/Category';
import { composeBlogArticleUrl } from '../../../utils/pathsHelper';

import styles from '../news.module.scss';

const NewsArticle = (props) => {
  const { article, showTag } = props;

  return (
    <div className={styles.article}>
      <img className={styles.articleImg} src={article.getIn(['imageSlider', 'images', 0, 'image', 'url'])} alt="" />
      <div className={styles.overlay} />
      <div className={classnames(styles.articleContent, !showTag && styles.noTag)}>
        <Category showCategory={showTag} category={article.getIn(['category', 'slug'])} />
        <Link
          to={composeBlogArticleUrl(article.get('slug'))}
          className={styles.link}
        >
          <h2 className={styles.articleTitle}>
            {article.get('title')}
          </h2>
        </Link>
        <DateContent date={article.get('date')} isBanner />
      </div>
    </div>
  );
};

NewsArticle.propTypes = {
  showTag: bool.isRequired,
  article: mapContains({
    category: mapContains({
      name: string,
    }),
    title: string,
    date: string,
    slug: string,
    imageSlider: mapContains({
      images: listOf(mapContains({
        image: mapContains({
          url: string,
        }),
        description: string,
      })),
    }),
  }).isRequired,
};

export default NewsArticle;
