import { fromJS } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  banner: { image: { url: '', name: '' }, url: '' },
});

const handleSetBanner = (state, action) => {
  const { data } = action.payload;
  const banner = data ? fromJS(data) : fromJS({ image: { url: '', name: '' }, url: '' });
  return state.set('banner', banner);
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_BANNER: {
      return handleSetBanner(state, action);
    }
    default: {
      return state;
    }
  }
};
