import {
  put, call, race, take, delay,
} from 'redux-saga/effects';
import i18n from 'i18next';
import { notification } from 'antd';
import { getNotificationsCountService, getNotificationsService } from 'services/notificationService';
import {
  types,
  stopNotificationPolling,
  setNotificationCount,
  setNotifications,
} from './actions';

function * pollNotificationsCount() {
  while (true) {
    try {
      const { count } = yield getCounts();
      yield put(setNotificationCount(count));
      const notifications = yield getNotifications();
      yield put(setNotifications(notifications));
      yield delay(30000);
    } catch (err) {
      yield put(stopNotificationPolling());
    }
  }
}

function * getCounts() {
  const { data, error } = yield call(getNotificationsCountService);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    return data;
  }
}

function * getNotifications() {
  const { data, error } = yield call(getNotificationsService);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
    return [];
  }
  return data;
}

export default function * notificationsPollingSaga() {
  while (true) {
    yield take(types.START_NOTIFICATION_POLLING);
    yield race([
      call(pollNotificationsCount),
      take(types.STOP_NOTIFICATION_POLLING),
    ]);
  }
}
