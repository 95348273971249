import {
  all,
  put,
  call,
  takeLatest,
} from 'redux-saga/effects';
import i18n from 'i18next';
import { notification } from 'antd';

import { types, setBanner } from './actions';
import { getBanner } from '../../../services/bannersService';

export default function * bannerSagas() {
  yield all([
    takeLatest(types.GET_BANNER, handleGetBanner),
  ]);
}

function * handleGetBanner() {
  const { data, error } = yield call(getBanner);

  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setBanner({ data }));
  }
}
