import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendMessage, uploadFile } from '../modules/actions';
import { selectActiveConversationProductId } from '../modules/selectors';


const mapStateToProps = (state) => ({
  activeConversationProductId: selectActiveConversationProductId(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      sendMessage,
      uploadFile,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
