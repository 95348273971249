import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropsTypes from 'react-immutable-proptypes';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { CheckOutlined } from '@ant-design/icons';
import ButtonSaveChangesMedium from 'components/Buttons/ButtonSaveChanges/ButtonSaveChangesMedium/View';
import TextareaAutosize from 'react-textarea-autosize';
import UploadComponent from '../utils/UploadComponent';
import styles from '../aboutCompany.module.scss';

const EditMode = (props) => {
  const {
    formData,
    updateFormData,
    uploadAboutCompanyImage,
    handleSaveChanges,
  } = props;
  const { t } = useTranslation();
  const [changesSaved, setChangesSavedStatus] = useState(false);

  const maxLengthCheck = (e) => {
    if (e.target.value.length > e.target.max.length) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  };

  return (
    <div className={styles.editModeSectionContainer}>
      <div className={styles.companyInfoSectionContianer}>
        <div className={styles.employeeNumberContianer}>
          <h6>{t('aboutCompany numberOfEmployee')}</h6>
          <input
            type="number"
            placeholder="0000"
            name="employeeCount"
            className={styles.inputEmployeeCount}
            value={formData.get('employeeCount')}
            onChange={(e) => {
              updateFormData('employeeCount', e.target.value);
              setChangesSavedStatus(false);
            }}
            min={0}
            maxLength={4}
            onInput={maxLengthCheck}
          />
        </div>
      </div>
      <h5 className={styles.moreAboutCompanyText}>
        {t('aboutCompany moreAboutCompanyHeader')}
      </h5>
      <TextareaAutosize
        type="text"
        placeholder={t('aboutCompany inputPlaceholder sectionTitle')}
        name="aboutCompany"
        className={classNames(styles.inputContainer)}
        value={formData.get('sectionTitle')}
        onChange={(e) => {
          updateFormData('sectionTitle', e.target.value);
          setChangesSavedStatus(false);
        }}
        maxLength={60}
      />
      <TextareaAutosize
        type="text"
        placeholder={t('aboutCompany inputPlaceholder sectionDescription')}
        name="sectionDescription"
        className={classNames(styles.inputContainer, styles.inputDescription)}
        value={formData.get('sectionDescription')}
        onChange={(e) => {
          updateFormData('sectionDescription', e.target.value);
          setChangesSavedStatus(false);
        }}
      />
      <UploadComponent
        imageKey="first"
        imageUrl={formData.getIn(['images', 'first'])}
        uploadAboutCompanyImage={uploadAboutCompanyImage}
        setAboutUsSaved={setChangesSavedStatus}
      />
      <TextareaAutosize
        type="text"
        placeholder={t('aboutCompany inputPlaceholder sectionDescription')}
        name="shortInfo"
        className={classNames(
          styles.inputContainer,
          styles.inputDescription,
          styles.inputShortDescription,
          'mt-4',
        )}
        value={formData.get('shortInfo')}
        onChange={(e) => {
          updateFormData('shortInfo', e.target.value);
          setChangesSavedStatus(false);
        }}
      />
      <div className={classNames(styles.imagesContainer)}>
        <UploadComponent
          imageKey="second"
          customClass="w-100 w-md-50 p-0 py-md-3 mr-md-3 mb-4 mb-md-0"
          uploadAboutCompanyImage={uploadAboutCompanyImage}
          imageUrl={formData.getIn(['images', 'second'])}
          setAboutUsSaved={setChangesSavedStatus}
        />
        <UploadComponent
          imageKey="third"
          customClass="w-100 w-md-50 p-0 py-md-3 ml-md-3"
          uploadAboutCompanyImage={uploadAboutCompanyImage}
          imageUrl={formData.getIn(['images', 'third'])}
          setAboutUsSaved={setChangesSavedStatus}
        />
      </div>
      <div className={styles.saveChangesButtonContainer}>
        {changesSaved ? (
          <div className={styles.checkOutlinedContainer}>
            <CheckOutlined className={styles.iconStyle} />
          </div>
        ) : (
          <ButtonSaveChangesMedium
            handleClick={() => {
              handleSaveChanges();
              setChangesSavedStatus(true);
            }}
          />
        )}
      </div>
    </div>
  );
};

const { func } = PropTypes;

EditMode.propTypes = {
  updateFormData: func.isRequired,
  formData: ImmutablePropsTypes.map.isRequired,
  uploadAboutCompanyImage: func.isRequired,
  handleSaveChanges: func.isRequired,
};

export default EditMode;
