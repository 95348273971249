import React from 'react';
import { string } from 'prop-types';

const CompassIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 49.519 51.219"
  >
    <g
      id="prefix__Group_639"
      data-name="Group 639"
      transform="translate(-285.581 -173) translate(34.198 -16.855)"
    >
      <g id="prefix__Group_117" data-name="Group 117">
        <g id="prefix__Group_105" data-name="Group 105">
          <path
            id="prefix__Path_138"
            d="M283.111 193.855h-13.854a2 2 0 0 1-2-2 2 2 0 0 1 2-2h13.854a2 2 0 0 1 2 2 2 2 0 0 1-2 2z"
            className="prefix__cls-1"
            data-name="Path 138"
            style={{ fill: '#050713' }}
          />
        </g>
        <g id="prefix__Group_116" data-name="Group 116">
          <g id="prefix__Group_106" data-name="Group 106">
            <path
              id="prefix__Path_139"
              d="M274.692 241.032a24.752 24.752 0 0 1-20.614-35.962 2 2 0 0 1 2.834-.76 2 2 0 0 1 .712 2.6 20.784 20.784 0 1 0 4.6-6 2 2 0 0 1-2.711 0 2 2 0 0 1 0-2.941 24.759 24.759 0 1 1 15.179 43.06z"
              className="prefix__cls-1"
              data-name="Path 139"
            />
          </g>
          <g id="prefix__Group_107" data-name="Group 107">
            <path
              id="prefix__Path_140"
              d="M264.84 229.9a2.21 2.21 0 0 1-2.1-2.879l5.44-17.135a2.909 2.909 0 0 1 1.915-1.9l16.715-5.164a2.21 2.21 0 0 1 2.771 2.736l-5.03 16.924a2.9 2.9 0 0 1-1.921 1.949L265.5 229.8a2.2 2.2 0 0 1-.66.1zm-.45-3.657zm7.209-14.824l-4.43 13.953L281.1 221l4.1-13.787zm9.915 9.456zm-9.785-9.864z"
              data-name="Path 140"
              style={{ fill: color }}
            />
          </g>
          <g id="prefix__Group_108" data-name="Group 108">
            <circle
              id="prefix__Ellipse_3"
              cx="2.962"
              cy="2.962"
              r="2.962"
              className="prefix__cls-1"
              data-name="Ellipse 3"
              transform="translate(273.181 213.352)"
            />
          </g>
        </g>
      </g>
      <path
        id="prefix__Path_147"
        d="M274.05 220.432a2.962 2.962 0 1 0-2.962-2.962 2.962 2.962 0 0 0 2.962 2.962z"
        data-name="Path 147"
        style={{ opacity: 0.2, fill: color }}
      />
    </g>
  </svg>
);

CompassIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

CompassIcon.defaultProps = {
  color: '#37ead9',
  width: '49.519',
  height: '51.219',
};

export default CompassIcon;
