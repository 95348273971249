import {
  all, takeLatest, put,
} from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';
import { setConversationsCount } from 'components/Chat/modules/actions';
import {
  setNotificationReadService,
  setNotificationSeenStatusService,
  getNotificationsService,
  getNotificationsCountService,
} from 'services/notificationService';
import { getNewMessagesCountService } from 'services/conversationService';
import { types, setNotificationCount, setNotifications } from './actions';

function * setNotificationRead(action) {
  const { id } = action.payload;
  const { error } = yield setNotificationReadService({
    read: true,
    notificationIds: [id],
  });
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    const notifications = yield getNotifications();
    yield put(setNotifications(notifications));
  }
}

function * setNotificationSeen() {
  const { error } = yield setNotificationSeenStatusService();
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    const { count } = yield getCounts();
    yield put(setNotificationCount(count));
  }
}

function * getNotifications() {
  const { data, error } = yield getNotificationsService();
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    return data;
  }
}

function * getCounts() {
  const { data, error } = yield getNotificationsCountService();
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    return data;
  }
}

function * getMessagesCounts() {
  const { conversationsCount, error } = yield getNewMessagesCountService();
  if (!error) {
    yield put(setConversationsCount(conversationsCount.count));
  }
}

export default function * notificationSaga() {
  yield all([
    takeLatest(types.SET_NOTIFICATION_READ, setNotificationRead),
    takeLatest(types.SET_NOTIFICATIONS_SEEN_STATUS, setNotificationSeen),
    takeLatest(types.GET_MESSAGES_COUNTS, getMessagesCounts),
  ]);
}
