import { parseError } from 'utils/parse-helper';
import { ApiService } from './apiService';

export const authService = async (path, postData) => ApiService.post(`auth/${path}`, postData)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const VerifyEmailService = async (path, postData) => ApiService.post(`auth/${path}`, postData)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const ReactivateEmailService = async (email) => ApiService.post(`auth/send-verification-email/${email}`)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));
