/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { textInputMaxLength } from 'utils/constants';
import { rubbish } from 'images/createCompany';
import styles from './whatWeDo.module.scss';

const ProductItem = (props) => {
  const {
    textAlignment,
    viewMode,
    title,
    description,
    setProductsData,
    itemKey,
    actions,
  } = props;
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    key: itemKey,
    title,
    description,
  });
  useEffect(() => {
    setProductsData(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);
  useEffect(() => {
    setFormData({
      key: itemKey,
      title,
      description,
    });
  }, [description, itemKey, title]);
  const clearCard = () => {
    actions.setChangesMade(true);
    setProductsData({ key: formData.key, title: '', description: '' });
  };
  return (
    <div
      className={classNames(
        styles.productItemContainer,
        textAlignment === 'text-center'
          ? 'align-items-center'
          : textAlignment === 'text-right'
            ? 'align-items-end'
            : '',
        'mx-auto',
      )}
    >
      {viewMode === 'edit' ? (
        <input
          type="text"
          placeholder={t('createCompany whatWeDo inputTitle placeholder')}
          name="sectionTitle"
          className={classNames(
            styles.inputSectionTitle,
            textAlignment,
            viewMode === 'view' ? styles.noBorderStyle : '',
          )}
          value={formData.title}
          onChange={(e) => {
            actions.setChangesMade(true);
            setFormData({ ...formData, title: e.target.value });
          }}
          maxLength={73}
        />
      ) : (
        <div className={styles.sectionTitleContainerViewMode}>
          <h3
            className={classNames(
              styles.inputSectionTitle,
              textAlignment,
              styles.sectionTitleTextViewMode,
            )}
          >
            {formData.title}
          </h3>
        </div>
      )}

      <div className={classNames(styles.horizontalBreakline, 'mx-2')} />
      <p
        className={classNames(
          styles.textCounterContainer,
          viewMode === 'view' && 'd-none',
        )}
      >
        {`${formData.description.length}/${textInputMaxLength}`}
      </p>
      {viewMode === 'edit' ? (
        <textarea
          type="text"
          placeholder={t('createCompany whatWeDo inputText placeholder')}
          name="sectionShortDescription"
          className={classNames(
            styles.inputSectionDescription,
            textAlignment,
            viewMode === 'view' ? styles.noBorderStyle : '',
          )}
          rows={11}
          disabled={viewMode === 'view'}
          value={formData.description}
          onChange={(e) => {
            actions.setChangesMade(true);
            setFormData({ ...formData, description: e.target.value });
          }}
          maxLength={textInputMaxLength}
        />
      ) : (
        <h5
          className={classNames(
            styles.inputSectionDescription,
            textAlignment,
            styles.noBorderStyle,
          )}
        >
          {formData.description}
        </h5>
      )}
      <div
        className={classNames(
          styles.rubbishIconStyle,
          viewMode === 'view' ? 'invisible' : '',
        )}
        onClick={clearCard}
        onKeyPress={clearCard}
      >
        <img src={rubbish} alt="delete" />
      </div>
    </div>
  );
};

const { string, func, shape } = PropTypes;

ProductItem.propTypes = {
  viewMode: string.isRequired,
  textAlignment: string.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  setProductsData: func.isRequired,
  itemKey: string.isRequired,
  actions: shape({
    setChangesMade: func.isRequired,
  }).isRequired,
};

export default ProductItem;
