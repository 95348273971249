import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selectBanner } from './modules/selectors';
import { getBanner } from './modules/actions';

const mapStateToProps = (state) => ({
  banner: selectBanner(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getBanner }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
