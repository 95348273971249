import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  selectActiveCompanyBackgroundImage,
  selectActiveCompanyName,
} from 'selectors/auth';
import { updateActiveCompanyBackground } from 'actions/auth';
import { showModal, hideModal } from './modules/actions';
import {
  selectModalVisibility,
  selectBackgroundImages,
} from './modules/selectors';

const mapStateToProps = (state) => ({
  modalVisibility: selectModalVisibility(state),
  backgroundImage: selectActiveCompanyBackgroundImage(state),
  backgroundImages: selectBackgroundImages(state),
  companyName: selectActiveCompanyName(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showModal,
      hideModal,
      updateActiveCompanyBackground,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
