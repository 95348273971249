export const types = {
  SHOW_PICTURE_EXPAND_MODAL: 'SHOW_PICTURE_EXPAND_MODAL',
  HIDE_PICTURE_EXPAND_MODAL: 'HIDE_PICTURE_EXPAND_MODAL',
};

export const hidePictureExpandModal = () => ({
  type: types.HIDE_PICTURE_EXPAND_MODAL,
  payload: {},
});

export const showPictureExpandModal = (pictureUrl) => ({
  type: types.SHOW_PICTURE_EXPAND_MODAL,
  payload: {
    pictureUrl,
  },
});

export default types;
