import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationBOS from './locales/bos/translation.json';
import translationENG from './locales/eng/translation.json';
import translationCRO from './locales/hrv/translation.json';
import translationSRB from './locales/srb/translation.json';

const resources = {
  bos: {
    translation: translationBOS,
  },
  eng: {
    translation: translationENG,
  },
  cro: {
    translation: translationCRO,
  },
  srb: {
    translation: translationSRB,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'bos',

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
