import React from 'react';
import classnames from 'classnames';
import { bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './CategoryDateElements.module.scss';

const Category = (props) => {
  const { t } = useTranslation();

  if (!props.showCategory) {
    return null;
  }

  return (
    <div className={classnames(
      styles.categoryContainer,
      props.isBlogArticle && styles.smallCategoryContainer,
    )}
    >
      <span className={styles.category}>
        {t(`news category ${props.category}`)}
      </span>
    </div>
  );
};

Category.propTypes = {
  showCategory: bool.isRequired,
  isBlogArticle: bool,
  category: string.isRequired,
};

Category.defaultProps = {
  isBlogArticle: false,
};

export default Category;
