import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { itemAdded } from 'images';
import { Link } from 'react-router-dom';
import buttonStyles from 'utils/buttonStyle.module.scss';
import PropTypes from 'prop-types';
import { composeProductsUrl } from 'utils/pathsHelper';
import styles from './addedProductOrDemand.module.scss';

const AddedProductOrDemand = (props) => {
  const { activeCompanySlug } = props;
  const { t } = useTranslation();
  return (
    <div className={styles.addedProductContainer}>
      <img src={itemAdded} alt="item added" />
      <h4>{t('addedProductOrDemand title')}</h4>
      <h5>{t('addedProductOrDemand subtitle')}</h5>
      <Link
        to={composeProductsUrl(activeCompanySlug || localStorage.getItem('userSlug'))}
        className={classNames(
          buttonStyles.buttonSuccessStyle,
          styles.buttonSuccessStyle,
        )}
      >
        {t('addedProductOrDemand button confirm')}
      </Link>
    </div>
  );
};

const { string } = PropTypes;

AddedProductOrDemand.propTypes = {
  activeCompanySlug: string.isRequired,
};

export default AddedProductOrDemand;
