import { connect } from 'react-redux';
import { selectActiveLanguage } from 'components/Navbar/modules/selectors';
import {
  selectActiveCompanyColorTheme,
  selectActiveCompanyFontFamily,
  selectActiveCompanyPositionInfo,
} from 'selectors/auth';
import { selectCountriesList } from "../../Register/modules/selectors";
import { bindActionCreators } from "redux";
import { getCountriesList } from "../../Register/modules/actions";

const mapStateToProps = (state) => ({
  activeLanguage: selectActiveLanguage(state),
  colorTheme: selectActiveCompanyColorTheme(state),
  fontFamily: selectActiveCompanyFontFamily(state),
  positionInfo: selectActiveCompanyPositionInfo(state),
  countriesList: selectCountriesList(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getCountriesList,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
