/* eslint-disable no-nested-ternary */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import icons from 'images/Icons';
import styles from './aboutUs.module.scss';

const AboutUsSection = (props) => {
  const {
    sectionTitle,
    shortInfo,
    iconName,
    textAlignment,
    colorTheme,
    fontFamily,
  } = props;
  const { t } = useTranslation();

  return (
    <div className={classNames('container pb-5', styles.aboutUsMainContainer)}>
      <div
        className={classNames(
          styles.aboutUsContainer,
          textAlignment === 'text-center'
            ? 'align-items-center'
            : textAlignment === 'text-right'
              ? 'align-items-end'
              : '',
        )}
      >
        <div className={styles.svgIconContainer}>
          {icons(colorTheme, '77px', '62px')[iconName]}
        </div>

        <h4>{t('createCompany aboutUs titleText')}</h4>
        <h2
          className={classNames(
            styles.inputSectionTitle,
            textAlignment,
            styles.viewModeTextStyle,
          )}
          style={{ color: colorTheme, fontFamily }}
        >
          {sectionTitle}
        </h2>

        <h5
          className={classNames(
            styles.inputSectionDescription,
            textAlignment,
            styles.shortDecriptionTextViewMode,
          )}
        >
          {shortInfo}
        </h5>
      </div>
    </div>
  );
};

const { string } = PropTypes;

AboutUsSection.propTypes = {
  sectionTitle: string,
  shortInfo: string,
  iconName: string,
  textAlignment: string.isRequired,
  colorTheme: string.isRequired,
  fontFamily: string.isRequired,
};

AboutUsSection.defaultProps = {
  sectionTitle: '',
  shortInfo: '',
  iconName: '',
};

export default AboutUsSection;
