import React, { useState } from 'react';
import { Menu, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ArrowDownMedium, ArrowUpMedium } from '../../../../svgs';

import styles from './bizbookMenu.module.scss';

const BizbookMenu = () => {
  const [isDropDownVisible, setDropDownVisible] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const handleVisibleChange = (isVisible) => {
    setDropDownVisible(isVisible);
  };
  const menu = (
    <Menu className={styles.bizbookMenu}>
      <Menu.Item
        key="0"
        className={styles.bizbookMenuItem}
        onClick={() => history.push('/news')}
      >
        {t('navbar tab news')}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      onVisibleChange={handleVisibleChange}
    >
      <div>{isDropDownVisible ? <ArrowUpMedium /> : <ArrowDownMedium />}</div>
    </Dropdown>
  );
};

export default BizbookMenu;
