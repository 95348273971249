import {
  all, call, takeLatest, put,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { clearUploadedImage } from 'components/CreateCompany/ColorPalette/modules/actions';
import i18n from 'i18next';
import { createdProductDemandUrl, composeProductsUrl } from 'utils/pathsHelper';
import {
  createProductDemandService,
  deleteProductByIdService,
  updateProductService,
} from 'services/productsService';
import history from '../../../history';
import { types } from './actions';
import { errorResponseParseHelper } from '../../../utils/constants';

function * createProductDemand(action) {
  const { formData, type } = action.payload;

  const postData = {
    name: formData.title,
    price: formData.price,
    description: formData.description,
    type,
    expireAt: formData.expiryDate,
    productImages: formData.images
      .map((image) => ({
        url: image.get('url', ''),
        publicId: image.get('publicId', ''),
      }))
      .toJS(),
    tags: formData.selectedTags.toJS(),
    currency: formData.currency,
    isFeatured: false,
  };

  const data = yield handleCreateProductDemand(
    formData.companyId
      ? { ...postData, companyId: formData.companyId }
      : postData,
  );
  if (data) {
    history.push(createdProductDemandUrl);
  }
}

function * updateProduct(action) {
  const { formData, type, productId } = action.payload;

  const filteredDeletedTags = formData.deletedTags.filter((item) => item.get('id', ''));

  const postData = {
    name: formData.title,
    price: formData.price,
    description: formData.description,
    type,
    expireAt:
      typeof formData.expiryDate === 'object'
        ? formData.expiryDate.toISOString()
        : formData.expiryDate,
    productImages: [
      ...formData.images
        .map((image) => ({
          url: image.get('url', ''),
          id:
            typeof image.get('id', '') === 'string'
              ? null
              : image.get('id', ''),
          deleted: false,
          publicId: image.get('publicId', ''),
        }))
        .toJS(),
      ...formData.deletedImages
        .map((image) => ({
          url: image.get('url', ''),
          id: image.get('id', ''),
          deleted: true,
          publicId: image.get('publicId', ''),
        }))
        .toJS(),
    ],
    tags: [
      ...formData.selectedTags.map((tag) => ({
        name: tag.get('name', ''),
      })),
      ...filteredDeletedTags.map((tag) => ({
        id: tag.get('id', ''),
        name: tag.get('name', ''),
        deleted: true,
      })),
    ],
    currency: formData.currency,
    isFeatured: formData.isFeatured,
  };

  const { data, error } = yield call(updateProductService, productId, postData);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: errorResponseParseHelper(error),
    });
  } else {
    const newData = { ...data, companySlug: formData.companySlug };
    history.push(composeProductsUrl(newData.companySlug));
    yield put(clearUploadedImage());
    notification.success({
      message: i18n.t('notification success'),
      description: i18n.t(`${type}Updated`),
    });
  }
}

function * handleCreateProductDemand(formData) {
  const { data, error } = yield call(createProductDemandService, formData);
  if (error) {
    return notification.error({
      message: i18n.t('notification error'),
      description: errorResponseParseHelper(error),
    });
  } else {
    return data;
  }
}

function * deleteProduct(action) {
  const { id } = action.payload;
  const { data, error } = yield call(deleteProductByIdService, id);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: errorResponseParseHelper(error),
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
      description: data.message,
    });
    history.goBack();
  }
}

export default function * productDemandSaga() {
  yield all([
    takeLatest(types.CREATE_PRODUCT, createProductDemand),
    takeLatest(types.UPDATE_PRODUCT, updateProduct),
    takeLatest(types.DELETE_PRODUCT, deleteProduct),
  ]);
}
