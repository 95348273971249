import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import CommentEdit from '../CommentEdit';
import CommentPreview from '../CommentPreview';

const CommentItem = (props) => {
	const {
		comment,
		isAdmin,
		companyId,
		handleDeleteComment,
		handleEditComment
	} = props;

	const [previewMode, setPreviewMode] = useState(true);

	const commentCompanyId = comment.get('companyId', null);
	const userCanDelete = isAdmin || companyId === commentCompanyId;
	const userCanEdit = companyId === commentCompanyId;

	const toggleMode = () => {
		setPreviewMode(prevState => !prevState);
	}

	return (
		previewMode
			? <CommentPreview
				comment={comment}
				userCanEdit={userCanEdit}
				userCanDelete={userCanDelete}
				handleDeleteComment={handleDeleteComment}
				toggleMode={toggleMode}
			/>
			: <CommentEdit
				comment={comment}
				toggleMode={toggleMode}
				handleEditComment={handleEditComment}
			/>
	)
}

const { func, number, bool } = PropTypes;
const { map } = ImmutablePropTypes;

CommentItem.propTypes = {
	comment: map.isRequired,
	companyId: number.isRequired,
	isAdmin: bool.isRequired,
	handleDeleteComment: func.isRequired,
	handleEditComment: func.isRequired,
}

export default CommentItem;
