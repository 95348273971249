import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './Compose.scss';
import { Upload, notification } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { fileUploadMaxLimit } from '../../../utils/constants';

const Compose = (props) => {
  const {
    actions,
    activeConversationProductId,
    rightItems,
  } = props;
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState('');

  const allowedTypes = ['JPG', 'PNG', 'GIF', 'BMP', 'TIFF', 'ICO', 'PDF', 'EPS', 'PSD', 'SVG', 'WebP', 'JXR', 'WDP'];

  return (
    <div className="compose">
      <Upload
        showUploadList={false}
        beforeUpload={(file) => {
          if (!allowedTypes.includes(file.name.split('.').slice(-1).pop().toUpperCase())) {
            notification.error({
              message: t('notification error'),
              description: `${t('notification notAllowedFile')} .${file.name.split('.').slice(-1).pop()} ${t('notification notAllowedFile files')}`,
            });
            return false;
          }
          return true;
        }}
        customRequest={(options) => {
          const isLimitSizeExceeded = options.file.size / 1024 / 1024 >= fileUploadMaxLimit;
          if (isLimitSizeExceeded) {
            notification.error({
              message: t('notification error'),
              description: t('fileUpload fileTooLarge'),
            });
          } else {
            options.onSuccess(actions.uploadFile(options));
          }
        }}
      >
        {rightItems}
      </Upload>

      <input
        type="text"
        className="compose-input mx-3"
        placeholder={t('messenger compose placeholder')}
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        onKeyPress={(event) => {
          if (event.key === 'Enter' && searchInput !== '') {
            actions.sendMessage({
              message: searchInput,
              type: 'text',
              productId: activeConversationProductId || null,
            });
            setSearchInput('');
          }
        }}
      />

      <SendOutlined
        className="send-outlined-style"
        onClick={() => {
          actions.sendMessage({
            message: searchInput,
            type: 'text',
            productId: activeConversationProductId || null,
          });
          setSearchInput('');
        }}
      />
    </div>
  );
};
const {
  shape, func, number, element, arrayOf,
} = PropTypes;

Compose.propTypes = {
  actions: shape({
    sendMessage: func.isRequired,
    uploadFile: func.isRequired,
  }).isRequired,
  activeConversationProductId: number,
  rightItems: arrayOf(
    element.isRequired,
  ).isRequired,
};

Compose.defaultProps = {
  activeConversationProductId: null,
};

export default Compose;
