import React from 'react';
import PropTypes, { func } from 'prop-types';
import classNames from 'classnames';
import inputStyle from './inputStyle.module.scss';

const InputMain = ({
  label,
  name,
  type,
  placeholder,
  value,
  handleChange,
  error,
  customStyle,
  id,
  onBlur,
}) => (
  <div
    className={classNames(
      inputStyle.formGroup,
      customStyle,
      'col-12 col-sm-8 col-md-5 col-lg-5 my-3 mx-5 mx-auto',
      error ? inputStyle.validationError : '',
    )}
    id={id}
  >
    <h6 className={inputStyle.error}>{error}</h6>
    <h5 className="px-1">{label}</h5>
    <input
      type={type}
      className={classNames(inputStyle.formControl, 'py-2 my-1')}
      placeholder={placeholder}
      name={name}
      value={value || ''}
      onChange={handleChange}
      onBlur={onBlur}
    />
  </div>
);

InputMain.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  customStyle: PropTypes.string,
  id: PropTypes.string,
  onBlur: func,
};

InputMain.defaultProps = {
  error: '',
  customStyle: '',
  value: '',
  id: '',
  onBlur: () => {},
};

export default InputMain;
