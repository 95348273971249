import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setVisibilityCompaniesList,
  setVisibilityMessagesList,
  setCompanyChat,
  setChatRoute,
} from '../modules/actions';
import { getCompanies } from './modules/actions';
import { selectActiveCompanies } from '../modules/selectors';
// eslint-disable-next-line import/named
import { selectCompaniesList, selectLoaderVisible } from './modules/selectors';
import { activateLoader, deactivateLoader } from '../../Feed/modules/actions';

const mapStateToProps = (state) => ({
  companies: selectCompaniesList(state),
  activeCompanies: selectActiveCompanies(state),
  loaderVisible: selectLoaderVisible(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      deactivateLoader,
      activateLoader,
      setCompanyChat,
      setChatRoute,
      getCompanies,
      setVisibilityCompaniesList,
      setVisibilityMessagesList,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
