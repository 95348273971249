import i18n from 'i18next';
import { homepageLinks } from 'utils/pathsHelper';

const routeConstants = () => [
  {
    routeIndex: 0,
    title: i18n.t('navbar tab feed'),
    route: homepageLinks.feed,
    name: 'feed',
  },
  {
    routeIndex: 1,
    title: i18n.t('navbar tab packages'),
    route: homepageLinks.packages,
    name: 'packages',
  },
  {
    routeIndex: 2,
    title: i18n.t('navbar tab rules'),
    route: homepageLinks.rules,
    name: 'rules',
  },
  {
    routeIndex: 3,
    title: i18n.t('navbar tab news'),
    route: homepageLinks.news,
    name: 'news',
  },
  {
    routeIndex: 5,
    title: i18n.t('navbar tab contactUs'),
    route: homepageLinks.contactUs,
    name: 'contactUs',
  },
];

export default routeConstants;
