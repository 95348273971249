import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { map } from 'react-immutable-proptypes';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { truncateString } from '../../../../utils/truncateString';

import styles from './notifications.module.scss';

const Notification = (props) => {
  const {
    notification,
  } = props;
  const { t } = useTranslation();
  const [notificationType, setNotificationType] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    if (!notification.get('comment')) {
      setNotificationType('product');
      setContent(notification.getIn(['product', 'name']));
    } else {
      setNotificationType('comment');
      setContent(notification.getIn(['comment', 'content']));
    }
  }, [notification]);

  return (
    <Link
      to={`/view/${notification.getIn(['product', 'type'], '')}/${notification.getIn(['product', 'id'], '')}`}
      className={classNames(styles.menuLinkStyle)}
    >
      <div className={styles.linkContent}>
        <img
          src={notification.getIn([notificationType, 'company', 'theme', 'companyLogo'], '')}
          alt={notification.getIn([notificationType, 'company', 'name'])}
          className={styles.companyLogo}
        />
        <div>
          <p className={styles.title}>
            <span className={styles.companyName}>
              {notification.getIn([notification, content, 'name'], '')}
            </span>
            {` ${t(`notification ${notification.get('title', '')}`)}:`}
          </p>
          <p className={styles.content}>{`"${truncateString(content, 35)}"`}</p>
          <p className={styles.notificationReceivedTime}>
            {moment(notification.get('date', moment())).format('DD.MM.YYYY HH:mm')}
          </p>
        </div>
      </div>
    </Link>
  );
};

Notification.propTypes = {
  notification: map.isRequired,
};

export default Notification;
