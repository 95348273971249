import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showModal, setNextRouteParams, setNextRoute } from 'components/Login/modules/actions';
import {
  selectCountriesList,
  selectIndustriesList,
} from 'components/Register/modules/selectors';
import {
  getCountriesList,
  getIndustriesList,
} from 'components/Register/modules/actions';
import {
  getFeedContent,
  loadMoreContent,
  activateLoader,
  getSuggestedTags,
  clearActiveItem,
  setQueryData,
} from './modules/actions';
import {
  selectFeedContent,
  selectLoaderVisible,
  selectSuggestedTags,
  selectQueryData,
  selectHasMoreDataStatus,
} from './modules/selectors';
import { setChatRoute, setCompanyChat } from '../Chat/modules/actions';

const mapStateToProps = (state) => ({
  feedContent: selectFeedContent(state),
  loaderVisible: selectLoaderVisible(state),
  suggestedTags: selectSuggestedTags(state),
  countriesList: selectCountriesList(state),
  industriesList: selectIndustriesList(state),
  queryData: selectQueryData(state),
  hasMore: selectHasMoreDataStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getFeedContent,
      loadMoreContent,
      activateLoader,
      getSuggestedTags,
      getCountriesList,
      clearActiveItem,
      getIndustriesList,
      setChatRoute,
      setQueryData,
      setCompanyChat,
      showModal,
      setNextRouteParams,
      setNextRoute
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
