import { fromJS } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  companies: [],
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_COMPANIES:
      return state.set('companies', fromJS(action.payload.companies));
    default:
      return state;
  }
};
