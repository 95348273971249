const showProduct = (product, actions, location) => {
  if (localStorage.getItem('userId')) {
    window.open(
      parseInt(localStorage.getItem('userId'), 10) === product.get('companyId', '')
        ? `/edit/${product.get('type', '')}/${product.get('id', '')}`
        : `/view/${product.get('type', '')}/${product.get('id', '')}`,
      '_blank',
    );
  } else {
    actions.setNextRoute('');
    actions.setNextRouteParams({
      pathname: location.pathname,
      product: {
        companyId: product.get('companyId', ''),
        type: product.get('type', ''),
        id: product.get('id', ''),
      },
    });
    actions.showModal();
  }
};

export default showProduct;
