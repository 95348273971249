import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  bos, cro, eng, srb,
} from 'images/flags';
import Select from 'react-select';
import i18n from '../../i18n';

import changeDateLanguage from '../../utils/changeDateLanguage';
import LanguageOption from './LanguageOption';
import styles from './languageSelector.module.scss';

const LanguageSelector = (props) => {
  const { customStyle, actions, activeLanguage } = props;

  const changeLanguage = (language) => {
    changeDateLanguage(language.value);
    i18n.changeLanguage(language.value);
    actions.setActiveLanguage(language.value);
  };

  const languageOptions = [
    {
      value: 'bos',
      label: <LanguageOption src={bos} value="bos" />,
    },
    {
      value: 'eng',
      label:  <LanguageOption src={eng} value="eng" />,
    },
    {
      value: 'cro',
      label:  <LanguageOption src={cro} value="cro" />,
    },
    {
      value: 'srb',
      label:  <LanguageOption src={srb} value="srb" />,
    },
  ];

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.8,
      },
    }),
    option: (base, state) => ({
      ...base,
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.6,
      },
      display: state.isSelected ? 'none' : 'block',
    }),
    menu: (base) => ({
      ...base,
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px',
    }),
    menuList: (base) => ({
      ...base,
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px',
    }),
  };

  return (
    <Select
      options={languageOptions}
      isSearchable={false}
      className={classNames(styles.selectStyle, customStyle)}
      placeholder={false}
      value={languageOptions.find((obj) => obj.value === activeLanguage)}
      components={{
        IndicatorSeparator: () => null,
      }}
      styles={style}
      onChange={changeLanguage}
    />
  );
};

const { shape, string, func } = PropTypes;

LanguageSelector.propTypes = {
  customStyle: string,
  actions: shape({
    setActiveLanguage: func.isRequired,
  }).isRequired,
  activeLanguage: string.isRequired,
};

LanguageSelector.defaultProps = {
  customStyle: '',
};

export default LanguageSelector;
