import React from 'react';
import { map } from 'react-immutable-proptypes';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { shape, func } from 'prop-types';

import showProduct from '../../../utils/showProduct-helper';

import styles from './homeSlider.module.scss';

const HomeSliderCard = (props) => {
  const { product, actions } = props;
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className={styles.slideContent}>
      <div className={styles.productContent}>
        <h2 className={styles.category}>
          {t(`homepage slider card ${product.get('type')}`)}
        </h2>
        <h1 className={styles.title}>
          {product.get('name')}
        </h1>
        <p className={styles.validTo}>
          {`${t('feed card expireAt')}: ${moment(product.get('expireAt')).format('Do MMMM, YYYY')}`}
        </p>
        <button
          type="button"
          className={styles.descriptionButton}
          onClick={() => showProduct(product, actions, location)}
        >
          {t('feed card button description')}
        </button>
      </div>
      <div>
        <div className={styles.productContainer}>
          <div className={styles.overlay} />
          <img src={product.getIn(['images', 0, 'url'])} alt={product.get('name')} />
        </div>
      </div>
    </div>
  );
};

HomeSliderCard.propTypes = {
  product: map.isRequired,
  actions: shape({
    setNextRoute: func.isRequired,
    setNextRouteParams: func.isRequired,
    showModal: func.isRequired,
  }).isRequired,
};

export default HomeSliderCard;
