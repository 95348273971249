import React from 'react';
import { string } from 'prop-types';

const HillsIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 52.092 42.229"
  >
    <g
      id="prefix__Group_641"
      data-name="Group 641"
      transform="translate(-615.219 -178.073)"
    >
      <g
        id="prefix__Group_181"
        data-name="Group 181"
        transform="translate(34.198 -16.855)"
      >
        <path
          id="prefix__Path_121"
          d="M583.2 237.157h45.635a2.182 2.182 0 0 0 1.868-3.31l-15.042-24.91a2.181 2.181 0 0 0-3.738-.005L597.3 233.157h-10.876l7.22-11.956 2.765 4.579a2 2 0 1 0 3.428-2.072l-4.32-7.145a2.181 2.181 0 0 0-3.74-.008l-10.441 17.289a2.183 2.183 0 0 0 1.867 3.313zm30.59-23.58l11.824 19.58H601.97z"
          className="prefix__cls-1"
          data-name="Path 121"
          style={{ fill: '#37ead9', opacity: 0.2 }}
        />
        <path
          id="prefix__Path_122"
          d="M596.979 210.358a7.137 7.137 0 1 0-7.137-7.137 7.145 7.145 0 0 0 7.137 7.137zm0-10.273a3.137 3.137 0 1 1-3.137 3.137 3.139 3.139 0 0 1 3.137-3.137z"
          className="prefix__cls-1"
          data-name="Path 122"
          style={{ fill: '#37ead9', opacity: 0.2 }}
        />
        <g id="prefix__Group_90" data-name="Group 90">
          <path
            id="prefix__Path_123"
            d="M598.942 234.89a2.185 2.185 0 0 1 .034-2.2l.416-.688h-10.874l7.22-11.956 2.765 4.579a2 2 0 1 0 3.428-2.072l-4.32-7.144a2.181 2.181 0 0 0-3.74-.008l-10.44 17.288A2.182 2.182 0 0 0 585.3 236h15.546a2.185 2.185 0 0 1-1.904-1.11z"
            className="prefix__cls-2"
            data-name="Path 123"
            style={{ fill: '#050713' }}
          />
          <path
            id="prefix__Path_124"
            d="M632.8 232.69l-15.042-24.91a2.181 2.181 0 0 0-3.738-.005L599.392 232l-.416.688a2.181 2.181 0 0 0 1.867 3.312h30.089a2.182 2.182 0 0 0 1.868-3.309zm-16.913-20.269L627.711 232h-23.647z"
            className="prefix__cls-2"
            data-name="Path 124"
            style={{ fill: '#050713' }}
          />
          <path
            id="prefix__Path_125"
            d="M599.073 209.2a7.137 7.137 0 1 0-7.137-7.137 7.145 7.145 0 0 0 7.137 7.137zm0-10.273a3.137 3.137 0 1 1-3.137 3.137 3.14 3.14 0 0 1 3.137-3.135z"
            data-name="Path 125"
            style={{ fill: color }}
          />
        </g>
      </g>
    </g>
  </svg>
);

HillsIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

HillsIcon.defaultProps = {
  color: '#37ead9',
  width: '52.092',
  height: '42.229',
};

export default HillsIcon;
