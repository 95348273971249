import { fromJS } from 'immutable';

const mockData = fromJS({
  title: '',
  textAlignment: 'text-left',
  clients: [
    {
      key: '0',
      comment: '',
      name: '',
      position: '',
    },
    {
      key: '1',
      comment: '',
      name: '',
      position: '',
    },
    {
      key: '2',
      comment: '',
      name: '',
      position: '',
    },
  ],
});

export default mockData;
