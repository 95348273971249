import React from 'react';
import { string } from 'prop-types';

const TentIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 52.616 49.415"
  >
    <g
      id="prefix__Group_638"
      data-name="Group 638"
      transform="translate(-117 -174.48)"
    >
      <g
        id="prefix__Group_178"
        data-name="Group 178"
        transform="translate(34.198 -16.855)"
      >
        <g id="prefix__Group_98" data-name="Group 98">
          <g id="prefix__Group_97" data-name="Group 97">
            <g id="prefix__Group_92" data-name="Group 92">
              <path
                id="prefix__Path_126"
                d="M107.62 192.354a2 2 0 1 0-3.485 1.963l4.617 8.2 2.295-4.074z"
                className="prefix__cls-1"
                data-name="Path 126"
                style={{ fill: color }}
              />
              <path
                id="prefix__Path_127"
                d="M132.017 235.662l-18.674-33.149-1.742 3.093a2 2 0 0 0 0 1.963l16.485 29.264c.851 1.51 3.369 1.489 3.945-1.072a.147.147 0 0 0-.014-.099z"
                className="prefix__cls-1"
                data-name="Path 127"
                style={{ fill: color }}
              />
            </g>
            <g id="prefix__Group_93" data-name="Group 93">
              <path
                id="prefix__Path_128"
                d="M116.76 239.2h-12.944v-9.6a4.747 4.747 0 0 1 4.741-4.741h3.463a4.746 4.746 0 0 1 4.74 4.741v9.6zm-9.725-3.219h6.507V229.6a1.524 1.524 0 0 0-1.521-1.522h-3.463a1.523 1.523 0 0 0-1.522 1.522v6.384z"
                className="prefix__cls-2"
                data-name="Path 128"
                style={{ fill: '#050713' }}
              />
            </g>
            <g id="prefix__Group_95" data-name="Group 95">
              <path
                id="prefix__Path_130"
                d="M133.418 239.593H86.9a2 2 0 0 1-2-2 2 2 0 0 1 2-2h46.521a2 2 0 0 1 2 2 2 2 0 0 1-2.003 2z"
                className="prefix__cls-2"
                data-name="Path 130"
                style={{ fill: '#050713' }}
              />
            </g>
            <g id="prefix__Group_96" data-name="Group 96">
              <path
                id="prefix__Path_131"
                d="M90.9 238.575l-3.484-1.963 24.932-44.258a2 2 0 0 1 2.724-.761 2 2 0 0 1 .761 2.724z"
                className="prefix__cls-2"
                data-name="Path 131"
                style={{ fill: '#050713' }}
              />
            </g>
          </g>
          <path
            id="prefix__Path_133"
            d="M84.8 240.75h46.521a2 2 0 1 0 0-4h-1.44l-18.635-33.081-1.742 3.093a2 2 0 0 0 0 1.963l15.787 28.025h-10.625v-5.994a4.746 4.746 0 0 0-4.74-4.741h-3.463a4.747 4.747 0 0 0-4.741 4.741v5.994H90.485l23.252-41.276a2 2 0 0 0-3.485-1.963l-2.363 4.194-2.363-4.194a2 2 0 1 0-3.485 1.963l3.552 6.306-19.7 34.971H84.8a2 2 0 1 0 0 4zm20.138-9.994a1.524 1.524 0 0 1 1.523-1.522h3.463a1.523 1.523 0 0 1 1.521 1.522v5.994h-6.507v-5.994z"
            data-name="Path 133"
            style={{ opacity: 0.2, fill: color }}
          />
        </g>
      </g>
    </g>
  </svg>
);

TentIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

TentIcon.defaultProps = {
  color: '#37ead9',
  width: '52.616',
  height: '49.415',
};

export default TentIcon;
