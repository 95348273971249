import React from 'react';
import { string } from 'prop-types';

const LeavesIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 35.875 51.984"
  >
    <g
      id="prefix__Group_649"
      data-name="Group 649"
      transform="translate(-627.629 -324.419)"
    >
      <g
        id="prefix__Group_12"
        data-name="Group 12"
        transform="translate(29.198 -30.855)"
      >
        <g id="prefix__Group_6" data-name="Group 6">
          <path
            id="prefix__Path_19"
            d="M620.81 394.464h-5.546v-5.547a13.177 13.177 0 0 1 13.177-13.177h4.511a1.036 1.036 0 0 1 1.036 1.036v4.51a13.177 13.177 0 0 1-13.178 13.178zm-1.546-4h1.546a9.188 9.188 0 0 0 9.177-9.178v-1.546h-1.546a9.186 9.186 0 0 0-9.177 9.177z"
            className="prefix__cls-1"
            data-name="Path 19"
            style={{ fill: color }}
          />
        </g>
        <g id="prefix__Group_7" data-name="Group 7">
          <path
            id="prefix__Path_20"
            d="M619.264 394.464h-5.546a13.177 13.177 0 0 1-13.177-13.177v-4.51a1.036 1.036 0 0 1 1.036-1.036h4.51a13.177 13.177 0 0 1 13.177 13.177zm-14.724-14.723v1.546a9.189 9.189 0 0 0 9.177 9.178h1.546v-1.547a9.187 9.187 0 0 0-9.177-9.177z"
            className="prefix__cls-2"
            data-name="Path 20"
            style={{ fill: '#050713' }}
          />
        </g>
        <g id="prefix__Group_8" data-name="Group 8">
          <path
            id="prefix__Path_21"
            d="M617.264 381.325l-3.922-3.922a13.178 13.178 0 0 1 0-18.636l3.189-3.189a1.036 1.036 0 0 1 1.466 0l3.189 3.189a13.178 13.178 0 0 1 0 18.636zm0-20.824l-1.093 1.094a9.189 9.189 0 0 0 0 12.979l1.093 1.094 1.093-1.094a9.189 9.189 0 0 0 0-12.979z"
            className="prefix__cls-2"
            data-name="Path 21"
            style={{ fill: '#050713' }}
          />
        </g>
        <g id="prefix__Group_9" data-name="Group 9">
          <path
            id="prefix__Rectangle_4"
            d="M0 0H4V30.508H0z"
            className="prefix__cls-2"
            data-name="Rectangle 4"
            transform="translate(615.264 375.168)"
            style={{ fill: '#050713' }}
          />
        </g>
        <g id="prefix__Group_10" data-name="Group 10">
          <path
            id="prefix__Path_22"
            d="M632.306 372.539a2 2 0 0 1-2-2v-12.782a2 2 0 0 1 2-2 2 2 0 0 1 2 2v12.782a2 2 0 0 1-2 2z"
            className="prefix__cls-1"
            data-name="Path 22"
            style={{ fill: color }}
          />
        </g>
        <g id="prefix__Group_11" data-name="Group 11">
          <path
            id="prefix__Path_23"
            d="M602.222 372.587a2 2 0 0 1-2-2v-5.879a2 2 0 0 1 2-2 2 2 0 0 1 2 2v5.879a2 2 0 0 1-2 2z"
            className="prefix__cls-1"
            data-name="Path 23"
            style={{ fill: color }}
          />
        </g>
      </g>
      <path
        id="prefix__Path_24"
        d="M598.749 378.36v4.51a13.177 13.177 0 0 0 13.177 13.177h1.546v11.211h4v-11.211h1.546a13.178 13.178 0 0 0 13.182-13.178v-4.51a1.036 1.036 0 0 0-1.036-1.036h-4.511a13.127 13.127 0 0 0-9.177 3.732v-.147l1.922-1.922a13.178 13.178 0 0 0 0-18.636l-3.189-3.189a1.036 1.036 0 0 0-1.466 0l-3.189 3.189a13.178 13.178 0 0 0 0 18.636l1.922 1.922v.148a13.127 13.127 0 0 0-9.177-3.732h-4.51a1.036 1.036 0 0 0-1.04 1.036zm27.9 2.964h1.551v1.546a9.188 9.188 0 0 1-9.177 9.178h-1.546V390.5a9.186 9.186 0 0 1 9.173-9.176zm-12.271-18.145l1.093-1.094 1.093 1.094a9.19 9.19 0 0 1 0 12.98l-.593.593h-1l-.593-.593a9.191 9.191 0 0 1 .001-12.98zm-11.63 18.145h1.552a9.187 9.187 0 0 1 9.177 9.177v1.547h-1.546a9.187 9.187 0 0 1-9.177-9.178z"
        className="prefix__cls-3"
        data-name="Path 24"
        transform="translate(29.198 -30.855)"
        style={{ fill: color, opacity: 0.2 }}
      />
      <path
        id="prefix__Path_25"
        d="M630.514 357.34a2 2 0 0 0-2 2v12.782a2 2 0 0 0 4 0V359.34a2 2 0 0 0-2-2z"
        className="prefix__cls-3"
        data-name="Path 25"
        transform="translate(29.198 -30.855)"
        style={{ fill: color, opacity: 0.2 }}
      />
      <path
        id="prefix__Path_26"
        d="M600.431 374.171a2 2 0 0 0 2-2v-5.879a2 2 0 0 0-4 0v5.879a2 2 0 0 0 2 2z"
        className="prefix__cls-3"
        data-name="Path 26"
        transform="translate(29.198 -30.855)"
        style={{ fill: color, opacity: 0.2 }}
      />
    </g>
  </svg>
);

LeavesIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

LeavesIcon.defaultProps = {
  color: '#37ead9',
  width: '35.875',
  height: '51.984',
};

export default LeavesIcon;
