import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import i18n from 'i18next';
import { notification } from 'antd';
import { getFeedContent } from 'components/Feed/modules/actions';
import {
  adminPanelTableContentLimit,
  errorResponseParseHelper,
} from 'utils/constants';
import { deleteCompanyByIdService, updateCompanyByIdService } from 'services/companyService';
import { deleteProductByIdService } from 'services/productsService';
import { getAdminStatisticService } from 'services/statisticService';
import { types, updateCompanyById, setAdminStatistic } from './actions';

function * getAdminStatistic(action) {
  const { fromDate, toDate } = action.payload.queryObject;
  const { data, error } = yield call(
    getAdminStatisticService,
    { fromDate: fromDate || null, toDate: toDate || null },
  );
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
      description: 'Statistics succesfully generated',
    });
    yield put(setAdminStatistic(data));
  }
}

function * deleteCompany(action) {
  const { id } = action.payload;
  const { error } = yield deleteCompanyByIdService(id);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
      description: 'Company deleted successfully',
    });
    yield put(
      getFeedContent({ type: 'companies', limit: adminPanelTableContentLimit }),
    );
  }
}

function * deleteProductById(action) {
  const { id, type } = action.payload;
  const { data, error } = yield call(deleteProductByIdService, id);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
      description: data.message,
    });
    yield put(getFeedContent({ type, limit: adminPanelTableContentLimit }));
  }
}

function validateShopLink(shopLink) {
  if (shopLink !== '/' && shopLink !== '') return shopLink;
  return null;
}

function * changeSubscriptionOrIndustry(action) {
  const {
    companyId, dataId, formData, columnName,
  } = action.payload;

  const shopLinkValue = validateShopLink(formData.get('shopLink', ''));

  const postData = {
    name: formData.get('name', ''),
    email: formData.get('email', ''),
    isVerified: formData.get('isVerified', false),
    isBlocked: formData.get('isBlocked', false),
    shopLink: shopLinkValue,
    subscriptionEndDate: null,
  };

  switch (columnName) {
    case 'industryId':
      postData.industryId = dataId;
      postData.packageId = formData.get('packageId', null);
      postData.paidStatus = formData.get('paidStatus', 'Unpaid');
      postData.subscriptionEndDate = formData.get('subscriptionEndDate', null);
      break;
    case 'subscription':
      postData.packageId = dataId;
      postData.industryId = formData.get('industryId', null);
      postData.paidStatus = formData.get('paidStatus', null);
      postData.subscriptionEndDate = formData.get('subscriptionEndDate', null);
      break;
    case 'paidStatus':
      postData.paidStatus = dataId;
      postData.industryId = formData.get('industryId', null);
      postData.packageId = formData.get('packageId', null);
      postData.subscriptionEndDate = formData.get('subscriptionEndDate', null);
      break;
    case 'subscriptionEndDate':
      postData.subscriptionEndDate = dataId;
      postData.paidStatus = formData.get('paidStatus', 'Unpaid');
      postData.industryId = formData.get('industryId', null);
      postData.packageId = formData.get('packageId', null);
      break;
    default:
      break;
  }

  const { error } = yield call(updateCompanyByIdService, companyId, postData);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: Array.isArray(error) ? error[0]?.message : error,
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
      description: i18n.t(`${columnName} updated`),
    });
    yield put(updateCompanyById(companyId, postData));
  }
}

function * handleUpdateCompanyById(action) {
  const { companyId, formData, columnName } = action.payload;
  const shopLinkValue = validateShopLink(formData.shop);

  const postData = {
    name: formData.name,
    email: formData.email,
    industryId: formData.rawData.get('industryId', null),
    packageId: formData.rawData.get('packageId', null),
    isVerified: formData.rawData.get('isVerified', false),
    isBlocked: formData.rawData.get('isBlocked', false),
    shopLink: shopLinkValue,
    paidStatus: formData.rawData.get('paidStatus', 'Unpaid'),
    subscriptionEndDate: formData.rawData.get('subscriptionEndDate', null),
  };

  const { error } = yield call(updateCompanyByIdService, companyId, postData);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: errorResponseParseHelper(error),
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
      description: i18n.t(`${columnName} Updated`),
    });
    yield put(updateCompanyById(companyId, postData));
  }
}

function * updateCompanyStatus(action) {
  const { companyId, formData, columnName } = action.payload;

  const shouldCompanyBeBlocked = columnName === 'isBlocked'
    ? !formData.get('isBlocked')
    : formData.get('isBlocked');

  const shouldCompanyBeVerified = columnName === 'isVerified'
    ? !formData.get('isVerified')
    : formData.get('isVerified');

  const shopLinkValue = validateShopLink(formData.get('shopLink', ''));

  const postData = {
    name: formData.get('name', ''),
    email: formData.get('email', ''),
    industryId: formData.get('industryId', null),
    packageId: formData.get('packageId', null),
    isVerified: shouldCompanyBeVerified,
    isBlocked: shouldCompanyBeBlocked,
    shopLink: shopLinkValue,
    paidStatus: formData.get('paidStatus', 'Unpaid'),
    subscriptionEndDate: formData.get('subscriptionEndDate', null),
  };

  const { error } = yield call(updateCompanyByIdService, companyId, postData);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: errorResponseParseHelper(error),
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
      description: i18n.t(`${columnName} Updated`),
    });
    yield put(updateCompanyById(companyId, postData));
  }
}

export default function * adminPanelSaga() {
  yield all([
    takeLatest(types.GET_ADMIN_STATISTIC, getAdminStatistic),
    takeLatest(types.DELETE_COMPANY_BY_ID, deleteCompany),
    takeLatest(types.DELETE_PRODUCT_BY_ID, deleteProductById),
    takeLatest(types.CHANGE_SUBSCRIPTION_OR_INDUSTRY, changeSubscriptionOrIndustry),
    takeLatest(types.REQUEST_UPDATE_COMPANY_BY_ID, handleUpdateCompanyById),
    takeLatest(types.UPDATE_COMPANY_STATUS_BY_ID, updateCompanyStatus),
  ]);
}
