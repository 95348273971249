import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCompanyDetailsById } from 'actions/auth';
import {
  selectActiveCompanyName,
  selectActiveCompanyDetails,
  selectAdminUserRole,
} from 'selectors/auth';
import {
  uploadCatalog,
  getCompanyCatalogs,
  addCompanyCatalog,
  deleteCatalog,
} from './modules/actions';
import { selectCatalogs, selectUploadedCatalog } from './modules/selectors';

const mapStateToProps = (state) => ({
  companyName: selectActiveCompanyName(state),
  companyData: selectActiveCompanyDetails(state),
  catalogs: selectCatalogs(state),
  uploadedCatalog: selectUploadedCatalog(state),
  adminRole: selectAdminUserRole(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getCompanyDetailsById,
      uploadCatalog,
      getCompanyCatalogs,
      addCompanyCatalog,
      deleteCatalog,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
