import React from 'react';
import { string } from 'prop-types';

const CouponIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 40.201 40.308"
  >
    <g
      id="prefix__Group_663"
      data-name="Group 663"
      transform="translate(-1124.801 -626)"
    >
      <g
        id="prefix__Group_626"
        data-name="Group 626"
        transform="translate(-1137 74.618)"
      >
        <path
          id="prefix__Path_610"
          d="M443.125 439.4a7.813 7.813 0 0 1-5.559-2.26l-11.146-11.01a7.853 7.853 0 0 1-.074-11.087l13.768-13.968a1.956 1.956 0 0 1 1.385-.585l17.542-.108a3.833 3.833 0 0 1 3.853 3.806L463 421.73a1.962 1.962 0 0 1-.566 1.39l-13.778 13.951a7.822 7.822 0 0 1-5.531 2.329zm-.785-35l-13.2 13.392a3.927 3.927 0 0 0 .036 5.545l11.145 11.009a3.931 3.931 0 0 0 5.547-.034l13.207-13.373-.1-16.73z"
          data-name="Path 610"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_611"
          d="M453.837 414.079a2.941 2.941 0 1 1-.026-4.159 2.941 2.941 0 0 1 .026 4.159z"
          data-name="Path 611"
          transform="translate(1839 151)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_612"
          d="M441.833 440.69a7.818 7.818 0 0 0 5.533-2.328l13.778-13.951a1.962 1.962 0 0 0 .566-1.39l-.108-17.542a3.833 3.833 0 0 0-3.853-3.806l-17.541.108a1.956 1.956 0 0 0-1.385.585l-13.768 13.968a7.854 7.854 0 0 0 .074 11.087l11.146 11.009a7.8 7.8 0 0 0 5.558 2.26zm-13.986-21.6l13.2-13.392 16.631-.191.1 16.729-13.207 13.373a3.931 3.931 0 0 1-5.547.034l-11.146-11.009a3.927 3.927 0 0 1-.031-5.547z"
          className="prefix__cls-3"
          data-name="Path 612"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
        <path
          id="prefix__Path_613"
          d="M452.545 415.371a2.941 2.941 0 1 0-4.159.025 2.941 2.941 0 0 0 4.159-.025z"
          className="prefix__cls-3"
          data-name="Path 613"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
      </g>
    </g>
  </svg>
);

CouponIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

CouponIcon.defaultProps = {
  color: '#37ead9',
  width: '40.201',
  height: '40.308',
};

export default CouponIcon;
