import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RightOutlined } from '@ant-design/icons';

import SocialShare from 'components/SocialShare';
import { map as ImagePlaceholder } from 'images/createCompany';
import buttonStyles from 'utils/buttonStyle.module.scss';
import { composeViewCompanyUrl } from 'utils/pathsHelper';
import ItemInfoContainer from './Components/ItemInfoContainer';
import ImagesContainer from './Components/ImagesContainer';
import HowOthersRateUs from './Components/HowOthersRateUs/HowOthersRateUs';
import ProductRatingModal from './RatingModal';
import Comments from '../Comments';

import styles from './cardDetailedView.module.scss';

const CardDetailedView = (props) => {
  const {
    companyName,
    actions,
    itemDetails,
    userFeedContent,
    countryIso,
    currencyRates,
  } = props;

  const { t } = useTranslation();
  const params = useParams();
  const { productId, type } = params;

  useEffect(() => {
    if (itemDetails.size > 0) {
      actions.getCompanyDetailsById(itemDetails.getIn(['company', 'id'], ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemDetails]);

  useEffect(() => {
    actions.getItemById(productId);
  }, [actions, productId]);

  useEffect(() => {
    if (parseInt(productId, 10) === itemDetails.get('id', '')) {
      actions.getUserFeedContent(
        {
          limit: 4,
          type,
        },
        itemDetails.getIn(['company', 'slug'], ''),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemDetails]);

  return (
    <div className={classNames('container', styles.itemDetailsContainer)}>
      <div className={classNames(styles.navigationInfo)}>
        <Link
          to={composeViewCompanyUrl(
            itemDetails.getIn(
              ['company', 'slug'],
              localStorage.getItem('userId'),
            ),
          )}
        >
          <h5>{companyName}</h5>
        </Link>
        <RightOutlined className="px-3 pt-1" />
        <h6>{t('cardDetailedView navigationInfo title')}</h6>
        <SocialShare url={window.location.href} />
      </div>
      <div className={styles.itemDetailsContentContainer}>
        <ImagesContainer
          itemDetails={itemDetails}
          showPictureExpandModal={actions.showPictureExpandModal}
        />
        <ItemInfoContainer
          itemDetails={itemDetails}
          countryIso={countryIso}
          getCurrencyRates={actions.getRatesByCurrency}
          currencyRates={currencyRates}
          companyName={companyName}
          actions={actions}
        />
      </div>
      {itemDetails.get('ratings', '').size === 0 && (
        <button
          type="button"
          className={classNames(
            buttonStyles.mainButtonStyle,
            styles.rateProductButtonStyle,
          )}
          onClick={() => actions.showRatingModal()}
        >
          {t('rateProduct button')}
        </button>
      )}
      {itemDetails.get('communicationRating', '') > 0 && (
        <HowOthersRateUs itemDetails={itemDetails} />
      )}
      <Comments modifyingClass={styles.comments} />
      <h3>{t('cardDetailedView moreProductsOrServicesTitle')}</h3>
      <div className={styles.moreProductsContainer}>
        {userFeedContent.size > 0
          && userFeedContent.size <= 4
          && userFeedContent.map((item) => (
            <div
              key={item.get('id', '')}
              className={classNames(styles.moreProductsItem)}
            >
              <div className={styles.productImageContainer}>
                <img
                  src={
                    item.get('images', '').size > 0
                      ? item.get('images', '').first().get('url', '')
                      : ImagePlaceholder
                  }
                  alt="product"
                />
                <div className={styles.overlay} />
              </div>
              <div
                className={classNames(styles.itemTextContainer, 'px-2 px-md-4')}
              >
                <p>{item.get('name', '')}</p>
                <Link
                  to={`/view/${item.get('type', '')}/${item.get('id', '')}`}
                  className={styles.moreDetailsProductButton}
                >
                  <RightOutlined className={styles.iconCustomStyle} />
                </Link>
              </div>
            </div>
          ))}
      </div>
      <ProductRatingModal productId={productId} />
    </div>
  );
};

const { shape, string, func } = PropTypes;
const { map, list } = ImmutablePropTypes;

CardDetailedView.propTypes = {
  companyName: string.isRequired,
  actions: shape({
    getItemById: func.isRequired,
    getUserFeedContent: func.isRequired,
    showPictureExpandModal: func.isRequired,
    getRatesByCurrency: func.isRequired,
    getCompanyDetailsById: func.isRequired,
    showRatingModal: func.isRequired,
    setCompanyChat: func.isRequired,
  }).isRequired,
  itemDetails: map.isRequired,
  userFeedContent: list.isRequired,
  countryIso: string.isRequired,
  currencyRates: list.isRequired,
};

export default CardDetailedView;
