import i18n from 'i18next';

const contactInfoInputs = () => ({
  address: {
    key: 'address',
    label: i18n.t('createCompany contactInfo address label'),
    placeholder: i18n.t('createCompany contactInfo address placeholder'),
  },
  city: {
    key: 'city',
    label: i18n.t('createCompany contactInfo city label'),
    placeholder: i18n.t('createCompany contactInfo city placeholder'),
  },
  countryIso: {
    key: 'countryIso',
    label: i18n.t('createCompany contactInfo country label'),
    placeholder: i18n.t('createCompany contactInfo country placeholder'),
  },
  idNumber: {
    key: 'idNumber',
    label: i18n.t('createCompany contactInfo idNumber label'),
    placeholder: i18n.t('createCompany contactInfo idNumber placeholder'),
  },
  phone: {
    key: 'phone',
    label: i18n.t('createCompany contactInfo phone label'),
    placeholder: i18n.t('createCompany contactInfo phone placeholder'),
  },
  contactEmail: {
    key: 'contactEmail',
    label: i18n.t('createCompany contactInfo email label'),
    placeholder: i18n.t('createCompany contactInfo email placeholder'),
  },
  website: {
    key: 'website',
    label: i18n.t('createCompany contactInfo website label'),
    placeholder: i18n.t('createCompany contactInfo website placeholder'),
  },
});

export default contactInfoInputs;
