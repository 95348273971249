import { fromJS, Map as IMap, List as IList } from 'immutable';
import { types as adminPanelTypes } from 'components/AdminPanel/modules/actions';
import { types } from './actions';

const defaultState = fromJS({
  feedContent: [],
  loaderVisible: false,
  tags: [],
  userFeedContent: [],
  itemDetails: {},
  ratingModal: false,
  queryData: {
    limit: 15,
    page: 1,
  },
  hasMore: false,
  totalItems: null,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.ACTIVATE_LOADER:
      return state.set('loaderVisible', true);
    case types.DEACTIVATE_LOADER:
      return state.set('loaderVisible', false);
    case types.SET_FEED_CONTENT:
      return state.merge({
        feedContent: fromJS(action.payload.content.data),
        loaderVisible: false,
        totalItems: action.payload.content.totalItems,
        hasMore: action.payload.content.hasMore || false,
      });
    case types.SET_MORE_FEED_CONTENT:
      return state.withMutations((nextState) => {
        nextState.update('feedContent', (feedContent) => feedContent.concat(fromJS(action.payload.content.data)));
        nextState.set('hasMore', action.payload.content.hasMore);
        nextState.set('loaderVisible', false);
      });
    case types.SET_SUGGESTED_TAGS:
      return state.withMutations((nextState) => {
        nextState.set(
          'tags',
          fromJS(
            action.payload.tags.map((tag) => fromJS({
              value: tag.name,
              key: tag.id,
            })),
          ),
        );
      });
    case types.SET_USER_FEED_CONTENT:
      return state.merge({
        userFeedContent: fromJS(action.payload.content),
        loaderVisible: false,
      });
    case types.SET_MORE_LOADED_USER_FEED_CONTENT:
      return state.withMutations((nextState) => {
        nextState.update('userFeedContent', (userFeedContent) => userFeedContent.concat(fromJS(action.payload.content)));
        nextState.set('loaderVisible', false);
      });
    case types.SET_ITEM_BY_ID:
      return state.set('itemDetails', fromJS(action.payload.itemContent));
    case types.CLEAR_ACTIVE_ITEM:
      return state.set('itemDetails', IMap());
    case types.SET_RATES_BY_CURRENCY:
      return state.set('rates', fromJS(action.payload.rates));
    case types.SHOW_RATING_MODAL:
      return state.set('ratingModal', true);
    case types.HIDE_RATING_MODAL:
      return state.set('ratingModal', false);
    case types.SET_FILTERS_QUERY_DATA:
      return state.set('queryData', fromJS(action.payload.queryData));
    case adminPanelTypes.UPDATE_COMPANY_BY_ID:
      // eslint-disable-next-line no-use-before-define
      return updateCompanyById(state, action);
    default:
      return state;
  }
};

const updateCompanyById = (state, action) => {
  const { payload: { companyId, companyData } } = action;
  const companyToBeUpdatedIndex = state.get('feedContent', IList()).findIndex((i) => i.get('id') === companyId);
  const newState = state.mergeIn(['feedContent', companyToBeUpdatedIndex], fromJS({
    ...companyData,
  }));
  return newState;
};
