import React from 'react';
import PropTypes from 'prop-types';
import { Switch, withRouter } from 'react-router-dom';
import NotFound from 'utils/NotFound';
// Private Routes
import CreateCompany from 'components/CreateCompany';
import AboutCompany from 'components/AboutCompany';
import Feed from 'components/Feed';
import MyFeed from 'components/MyFeed';
import CreateProduct from 'components/CreateProductDemand';
import CreateDemand from 'components/CreateProductDemand';
import AddedProductOrDemand from 'components/AddedProductOrDemand';
import CardDetailedView from 'components/CardDetailedView';
import Catalog from 'components/Catalog';
import {
  registerUrl,
  homepageLinks,
  registerConfirmedUrl,
  testimonialsUrl,
  tutorialUrl,
  termsAndConditionsUrl,
  privacyPoliciesUrl,
  resetPasswordUrl,
  loginUrl,
  loginAfterConfirmationUrl,
  resendEmailActivationUrl,
  feedUrl,
  createdProductDemandUrl,
  adminPanelUrl,
  companyInterestsUrl,
  urlTemplates,
} from 'utils/pathsHelper';
import Messenger from './components/Chat';
import AdminPanel from './components/AdminPanel';
import Interests from './components/Interests';
import {
  PrivateRoute,
  NavRoute,
  RouteWithoutNavbar,
  PrivateRouteMessengerList,
  UniversalRoute,
} from './routing/routes';

// Public Routes
import Home from './components/Home';
import ContactUs from './components/ContactUs';
import Register from './components/Register';
import Testimonials from './components/Testimonials';
import SubscriptionPackages from './components/SubscriptionPackages';
import Tutorial from './components/Tutorial';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicies from './components/PrivacyPolicies';
import ResetPassword from './components/ResetPassword';
import RegisterConfirmed from './components/Register/RegisterConfirmed/View';
import ScrollToTop from './ScrollToTop';
import loginStandalone from './components/Login/loginStandalone';
import LoginAfterConfirmation from './components/Login/LoginAfterConfirmation';
import ResendEmailActivation from './components/Login/ReactivateEmail';
import ViewCompany from './components/ViewCompany';
import News from './components/News';
import NewsArticle from './components/News/NewsArticle';

const AppContainer = ({ location }) => (
  <div className="app--wrapper">
    <div className="route-section">
      <ScrollToTop />
      <Switch location={location}>
        <NavRoute path="/" exact component={Home} />
        <NavRoute path={homepageLinks.contactUs} exact component={ContactUs} />
        <NavRoute path={registerUrl} component={Register} />
        <RouteWithoutNavbar
          path={registerConfirmedUrl}
          component={RegisterConfirmed}
        />
        <NavRoute path={testimonialsUrl} exact component={Testimonials} />
        <NavRoute
          path={homepageLinks.packages}
          component={SubscriptionPackages}
        />
        <NavRoute exact path={homepageLinks.news} component={News} />
        <NavRoute path={tutorialUrl} component={Tutorial} />
        <NavRoute path={termsAndConditionsUrl} component={TermsAndConditions} />
        <NavRoute path={privacyPoliciesUrl} component={PrivacyPolicies} />
        <NavRoute path={resetPasswordUrl} component={ResetPassword} />
        <NavRoute
          path={urlTemplates.blogArticleUrlTemplate}
          component={NewsArticle}
        />

        <PrivateRouteMessengerList
          exact
          path={urlTemplates.messengerUrlTemplate}
          component={Messenger}
        />
        <PrivateRoute
          exact
          path={urlTemplates.editCompanyUrlTemplate}
          component={CreateCompany}
        />
        <PrivateRoute
          exact
          path={urlTemplates.aboutCompanyUrlTemplate}
          component={AboutCompany}
        />
        <RouteWithoutNavbar path={loginUrl} component={loginStandalone} />
        <RouteWithoutNavbar
          path={loginAfterConfirmationUrl}
          component={LoginAfterConfirmation}
        />
        <RouteWithoutNavbar
          path={resendEmailActivationUrl}
          component={ResendEmailActivation}
        />
        <UniversalRoute exact path={feedUrl} component={Feed} />
        <PrivateRoute
          exact
          path={urlTemplates.myFeedUrlTemplate}
          component={MyFeed}
        />
        <PrivateRoute
          exact
          path={urlTemplates.createProductUrlTemplate}
          component={CreateProduct}
        />
        <PrivateRoute
          exact
          path={urlTemplates.createDemandUrlTemplate}
          component={CreateDemand}
        />
        <PrivateRoute
          exact
          path={createdProductDemandUrl}
          component={AddedProductOrDemand}
        />
        <PrivateRoute
          exact
          path={urlTemplates.viewProductDemandUrlTemplate}
          component={CardDetailedView}
        />
        <PrivateRoute
          exact
          path={urlTemplates.editProductUrlTemplate}
          component={CreateProduct}
        />
        <UniversalRoute
          exact
          path={urlTemplates.viewCompanyUrlTemplate}
          component={ViewCompany}
        />
        <PrivateRoute
          exact
          path={urlTemplates.catalogUrlTemplate}
          component={Catalog}
        />
        <PrivateRoute exact path={companyInterestsUrl} component={Interests} />
        <PrivateRoute exact path={adminPanelUrl} component={AdminPanel} />
        <NavRoute component={NotFound} />
      </Switch>
    </div>
  </div>
);
AppContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(AppContainer);
