import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectActiveLanguage } from 'components/Navbar/modules/selectors';
import { selectModalVisibility } from 'components/AdminPanel/modules/selectors';
import { hideModal } from 'components/AdminPanel/modules/actions';
import { checkEmailExists, getCountriesList, getIndustriesList, receiveEmailExists } from '../modules/actions';
import {
  selectCountriesList, selectEmailExists,
  selectIndustriesList,
} from '../modules/selectors';

const mapStateToProps = (state) => ({
  countriesList: selectCountriesList(state),
  activeLanguage: selectActiveLanguage(state),
  industriesList: selectIndustriesList(state),
  createCompanyModal: selectModalVisibility(state),
  emailExists: selectEmailExists(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getCountriesList,
      getIndustriesList,
      hideModal,
      checkEmailExists,
      receiveEmailExists,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
