import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import buttonStyle from 'utils/buttonStyle.module.scss';
import useForm from 'utils/hooks/useForm';
import useInterests from '../../Interests/utils/useInterests';
import InterestInput from '../../Interests/InterestInput';

import styles from '../../Interests/interests.module.scss';

const Interests = (props) => {
  const {
    industries,
    addInterests,
    setActiveComponent,
    searchedResultsInterests,
    actions,
  } = props;
  const { t } = useTranslation();
  const {
    handleAddEnteredInterest,
    setFormData,
    handleDeleteInterest,
    state,
    setInputValue,
    setOptions,
  } = useInterests();

  const { errors, setErrors } = useForm(submitInterests, null);

  function submitInterests() {
    if (state.getIn(['formData', 'interests']).size >= 3
      && state.getIn(['formData', 'industryInterests']).size >= 3) {
      const { interests, industryInterests, ...newErrors } = errors;
      setErrors(newErrors);
      addInterests(
        state.getIn(['formData', 'interests']).toJS(),
        state.getIn(['formData', 'industryInterests']).toJS(),
      );
    } else {
      const newErrors = {};
      if (state.getIn(['formData', 'interests']).size < 3) {
        newErrors.interests = i18n.t('inputValidationError interests notSelectedEnough');
      }
      if (state.getIn(['formData', 'industryInterests']).size < 3) {
        newErrors.industryInterests = i18n.t('inputValidationError interests notSelectedEnough');
      }
      setErrors(newErrors);
    }
  }

  useEffect(() => {
    if (searchedResultsInterests.size > 0) {
      setOptions(
        searchedResultsInterests.map((item) => ({
          value: item.get('name', ''),
          name: item.get('name', ''),
          key: item.get('id', ''),
        })),
        'interest',
      );
    }
  }, [searchedResultsInterests]);

  useEffect(() => {
    setOptions(
      industries.map((item) => ({
        value: item.get('name', ''),
        name: item.get('name', ''),
        key: item.get('id', ''),
      })),
      'industry',
    );
  }, [industries]);

  useEffect(() => {
    if (state.get('inputValue').length === 0) {
      actions.getInterests();
    } else if (state.get('inputValue').length > 3) {
      actions.getInterests({ name: state.get('inputValue') });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.get('inputValue')]);

  useEffect(() => {
    if (state.getIn(['formData', 'interests']).size >= 3) {
      const { interests, ...newErrors } = errors;
      setErrors(newErrors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.getIn(['formData', 'interests'])]);

  return (
    <div className={styles.interestsContainer}>
      <h2>{t('interests mainTitle')}</h2>
      <InterestInput
        handleAddEnteredInterest={handleAddEnteredInterest}
        state={state}
        setFormData={setFormData}
        setInputValue={setInputValue}
        handleDeleteInterest={handleDeleteInterest}
        type="interest"
        hasError={!!errors.interests}
        error={errors.interests}
        component="register"
      />
      <InterestInput
        handleAddEnteredInterest={handleAddEnteredInterest}
        state={state}
        setFormData={setFormData}
        setInputValue={setInputValue}
        handleDeleteInterest={handleDeleteInterest}
        type="industry"
        hasError={!!errors.interests}
        error={errors.interests}
        component="register"
      />
      <div
        className={classNames(
          styles.buttonContainer,
          'mb-3 col-12 mt-0 mt-md-0 ml-auto pr-lg-5',
        )}
      >
        <button
          type="button"
          onClick={() => setActiveComponent('register')}
          className={classNames(
            buttonStyle.mainButtonStyle,
            styles.mainButtonStyle,
            'my-1 my-md-5 mr-2',
          )}
        >
          {t('register button back')}
        </button>
        <button
          type="button"
          className={classNames(
            buttonStyle.buttonSuccessStyle,
            styles.buttonSuccessStyle,
            'my-1 my-md-5',
          )}
          onClick={submitInterests}
        >
          {t('register button submit')}
        </button>
      </div>
    </div>
  );
};

const { func, shape } = PropTypes;
const { list } = ImmutablePropTypes;

Interests.propTypes = {
  addInterests: func.isRequired,
  setActiveComponent: func.isRequired,
  actions: shape({
    getInterests: func.isRequired,
  }).isRequired,
  searchedResultsInterests: list.isRequired,
  industries: list.isRequired,
};

export default Interests;
