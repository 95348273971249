import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import animationData from 'images/animationLoader.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const AnimationLoader = (props) => {
  const { isStopped, isPaused } = props;
  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={300}
        width={300}
        isStopped={isStopped}
        isPaused={isPaused}
      />
    </div>
  );
};

const { bool } = PropTypes;

AnimationLoader.propTypes = {
  isStopped: bool.isRequired,
  isPaused: bool.isRequired,
};

export default AnimationLoader;
