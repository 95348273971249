import {
  all, put, takeEvery, takeLatest,
} from 'redux-saga/effects';

import { getContent } from '../../Feed/modules/sagas';
import { setFeaturedProducts, setHomepageContent, types } from './actions';

export default function * homepageContentSaga() {
  yield all([
    takeEvery(types.GET_HOMEPAGE_CONTENT, handleGetHomepageContent),
    takeLatest(types.GET_FEATURED_PRODUCTS, handleGetFeaturedProducts),
  ]);
}

function * handleGetHomepageContent(action) {
  const { type } = action.payload;

  const queryObject = {
    type,
    page: 1,
    limit: 6,
  };

  const { data: content } = yield getContent(queryObject);
  yield put(setHomepageContent({ content, type }));
}

function * handleGetFeaturedProducts() {
  const queryObject = {
    page: 1,
    limit: 100,
    type: 'product',
    isFeatured: true,
  };

  const { data: products } = yield getContent(queryObject);
  yield put(setFeaturedProducts({ products }));
}
