import React from 'react';
import { string } from 'prop-types';

const GiftIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 52.157 48.781"
  >
    <g
      id="prefix__Group_670"
      data-name="Group 670"
      transform="translate(-280.945 -762.885)"
    >
      <g
        id="prefix__Group_628"
        data-name="Group 628"
        transform="translate(-2141 36)"
      >
        <path
          id="prefix__Path_678"
          d="M609.986 590.294h-6.443a6.152 6.152 0 0 1-6.145-6.145v-.821a6.45 6.45 0 0 1 6.443-6.443 6.151 6.151 0 0 1 6.144 6.144v7.265zm-6.144-9.41a2.446 2.446 0 0 0-2.443 2.443v.821a2.147 2.147 0 0 0 2.145 2.145h2.443v-3.265a2.148 2.148 0 0 0-2.145-2.144z"
          className="prefix__cls-1"
          data-name="Path 678"
          transform="translate(1839 150)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_679"
          d="M615.8 590.294h-6.443v-7.265a6.151 6.151 0 0 1 6.144-6.144 6.451 6.451 0 0 1 6.443 6.443v.821a6.151 6.151 0 0 1-6.144 6.145zm-2.443-4h2.443a2.146 2.146 0 0 0 2.144-2.145v-.821a2.445 2.445 0 0 0-2.443-2.443 2.146 2.146 0 0 0-2.144 2.144z"
          className="prefix__cls-1"
          data-name="Path 679"
          transform="translate(1839 150)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_680"
          d="M611.025 621.03h-4V604.7a2 2 0 0 1 2-2 2 2 0 0 1 2 2z"
          className="prefix__cls-2"
          data-name="Path 680"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Rectangle_53"
          d="M0 0H4V10.454H0z"
          className="prefix__cls-2"
          data-name="Rectangle 53"
          transform="translate(2446.295 739.294)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_681"
          d="M593.186 596.782l-.334 4h33.636v18.526l-.067.066-33.57-.066v-15.176a2 2 0 0 0-2-2 2 2 0 0 0-2 2v15.176a4.066 4.066 0 0 0 4.066 4.066h33.5a4.065 4.065 0 0 0 4.066-4.066v-22.527h-37.3z"
          className="prefix__cls-2"
          data-name="Path 681"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_682"
          d="M629.362 600.765h-39.384a5.747 5.747 0 0 1-5.74-5.74v-3.01a5.746 5.746 0 0 1 5.74-5.739h39.384a5.746 5.746 0 0 1 5.74 5.739v3.01a5.747 5.747 0 0 1-5.74 5.74zm-39.384-10.454a1.707 1.707 0 0 0-1.705 1.7v3.01a1.707 1.707 0 0 0 1.705 1.705h39.384a1.706 1.706 0 0 0 1.7-1.705v-3.01a1.707 1.707 0 0 0-1.7-1.7z"
          className="prefix__cls-2"
          data-name="Path 682"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_683"
          d="M628.07 587.568h-7.808a6.1 6.1 0 0 0 .387-2.127v-.821a6.45 6.45 0 0 0-6.443-6.443 6.153 6.153 0 0 0-5.829 4.212 6.153 6.153 0 0 0-5.828-4.212 6.451 6.451 0 0 0-6.443 6.443v.821a6.1 6.1 0 0 0 .387 2.127h-7.808a5.746 5.746 0 0 0-5.74 5.739v3.01a5.747 5.747 0 0 0 5.74 5.74h2.875v.016h33.64V620.6l-.067.066-15.4-.03v-14.648a2 2 0 0 0-4 0v14.64l-14.173-.028v-15.176a2 2 0 0 0-4 0V620.6a4.066 4.066 0 0 0 4.067 4.066h33.5a4.065 4.065 0 0 0 4.073-4.066v-18.656a5.749 5.749 0 0 0 4.614-5.628v-3.01a5.747 5.747 0 0 0-5.744-5.738zm-13.863-5.392a2.446 2.446 0 0 1 2.443 2.443v.821a2.14 2.14 0 0 1-1.971 2.127h-2.617v-3.247a2.148 2.148 0 0 1 2.145-2.144zm-14.1 3.265v-.821a2.445 2.445 0 0 1 2.443-2.443 2.147 2.147 0 0 1 2.144 2.145v3.247h-2.617a2.142 2.142 0 0 1-1.97-2.128zm-13.125 10.876v-3.01a1.706 1.706 0 0 1 1.705-1.7H606v6.419h-17.313a1.707 1.707 0 0 1-1.705-1.709zm42.793 0a1.707 1.707 0 0 1-1.705 1.705H610V591.6h18.07a1.707 1.707 0 0 1 1.705 1.7z"
          data-name="Path 683"
          transform="translate(1839 151)"
          style={{ opacity: 0.2, fill: color }}
        />
      </g>
    </g>
  </svg>
);

GiftIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

GiftIcon.defaultProps = {
  color: '#37ead9',
  width: '52.157',
  height: '48.781',
};

export default GiftIcon;
