import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  selectThemeCompanyLogo,
  selectActiveCompanyName,
  selectAdminUserRole,
  selectActiveCompanyId,
  selectActiveCompanyProgress,
} from 'selectors/auth';
import { getCompanyDetailsById } from 'actions/auth';
import {
  getUserFeedContent,
  activateLoader,
  loadMoreUserFeedContent,
  clearActiveItem,
} from '../Feed/modules/actions';
import {
  selectUserFeedContent,
  selectLoaderVisible,
} from '../Feed/modules/selectors';
import { selectProductDemandType } from '../CreateProductDemand/modules/selectors';
import { setProductDemandType } from '../CreateProductDemand/modules/actions';

const mapStateToProps = (state) => ({
  userFeedContent: selectUserFeedContent(state),
  loaderVisible: selectLoaderVisible(state),
  companyName: selectActiveCompanyName(state),
  companyLogo: selectThemeCompanyLogo(state),
  companyProgress: selectActiveCompanyProgress(state),
  adminRole: selectAdminUserRole(state),
  activeCompanyId: selectActiveCompanyId(state),
  productDemandType: selectProductDemandType(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getUserFeedContent,
      loadMoreUserFeedContent,
      activateLoader,
      clearActiveItem,
      getCompanyDetailsById,
      setProductDemandType,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
