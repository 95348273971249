import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Map as IMap } from 'immutable';
import { MessageOutlined } from '@ant-design/icons';
import { handleCurrencySelect } from 'utils/constants';
import buttonStyles from 'utils/buttonStyle.module.scss';
import styles from '../cardDetailedView.module.scss';
import history from '../../../history';

function forwardTo(location) {
  history.push(location);
}

const ItemInfoContainer = (props) => {
  const {
    itemDetails,
    countryIso,
    getCurrencyRates,
    companyName,
    currencyRates,
    actions,
  } = props;
  const { t } = useTranslation();
  const textHeightRef = useRef(null);
  const [fullText, setFullText] = useState(false);
  const [currency, setCurrency] = useState('');
  const [itemPrice, setItemPrice] = useState(0);

  const clickCollapse = () => {
    setFullText(!fullText);
  };

  const getTextHeight = () => {
    if (textHeightRef.current) {
      const elementHeight = textHeightRef.current.scrollHeight;
      return `${elementHeight}px`;
    }
  };

  const handleSendMessage = () => {
    actions.setCompanyChat({
      id: itemDetails.get('companyId', null),
      name: companyName,
    });
    actions.setChatRoute('feed-product', itemDetails.get('id', ''));
    forwardTo('/messenger');
  };

  useEffect(() => {
    if (countryIso) {
      setCurrency(handleCurrencySelect(countryIso));
    }
  }, [countryIso]);

  useEffect(() => {
    if (itemDetails.size > 0) {
      if (itemDetails.get('currency', '') === currency) {
        setItemPrice(itemDetails.get('price', ''));
      } else {
        const filteredCurrencyRates = currencyRates
          .filter(
            (item) => item.get('currencyCodeFrom', '')
                === itemDetails.get('currency', ''),
          )
          .first() || IMap();
        if (filteredCurrencyRates.size > 0) {
          setItemPrice(
            Math.round(
              filteredCurrencyRates.get('conversionValue', 0)
                * itemDetails.get('price', 0)
                * 100,
            ) / 100,
          );
        }
      }
    }
  }, [itemDetails, currency, currencyRates]);

  useEffect(() => {
    if (currency) {
      getCurrencyRates({ to: currency });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);

  return (
    <div className={classNames('col-12 col-md-5', styles.itemInfoContainer)}>
      <h2>{itemDetails.get('name', '')}</h2>
      {itemPrice > 0 ? (
        <div>
          <h3>{`${itemPrice} ${currency} `}</h3>
          <h5>{t('createProduct/Service price title')}</h5>
        </div>
      ) : (
        <p className={styles.noPriceTextStyle}>
          {t('productDetails noPriceText')}
        </p>
      )}
      <h4>{t('createProduct/Service description title')}</h4>
      <div
        className={classNames(
          styles.textContainer,
          fullText && styles.fullText,
        )}
        style={{ height: `${fullText ? getTextHeight() : '70px'}` }}
        ref={textHeightRef}
      >
        <p>{itemDetails.get('description', '')}</p>
      </div>
      <p
        role="presentation"
        onClick={clickCollapse}
        className={classNames(
          styles.readMoreText,
          getTextHeight() === '70px' && 'd-none',
        )}
      >
        {fullText
          ? t('cardDetailedView readLess')
          : t('cardDetailedView readMore')}
      </p>
      <h4 className={styles.contactCompanyTitle}>
        {t('cardDetailedView contactCompanyTitle')}
      </h4>
      <button
        type="button"
        className={classNames(
          buttonStyles.mainButtonStyle,
          styles.mainButtonStyle,
        )}
        onClick={handleSendMessage}
      >
        <MessageOutlined className={styles.messageIconStyle} />
        {t('cardDetailedView button sendMessageTitle')}
      </button>
    </div>
  );
};

const { string, func } = PropTypes;
const { map, list } = ImmutablePropTypes;

ItemInfoContainer.propTypes = {
  itemDetails: map.isRequired,
  countryIso: string.isRequired,
  currencyRates: list.isRequired,
  getCurrencyRates: func.isRequired,
};

export default ItemInfoContainer;
