import React from 'react';
import { bool } from 'prop-types';
import { StarFilled } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import styles from '../../subscriptionPackages.module.scss';

const RecommendsBanner = (props) => {
  const { isRecommended } = props;
  const { t } = useTranslation();

  if (!isRecommended) {
    return null;
  }

  return (
    <div className={styles.bizbookRecommends}>
      <StarFilled />
      <span>{t('subscriptionPackageItem bizbookRecommends')}</span>
    </div>
  );
};

RecommendsBanner.propTypes = {
  isRecommended: bool.isRequired,
};

export default RecommendsBanner;
