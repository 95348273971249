import {
  all, call, takeLatest, put,
} from 'redux-saga/effects';
import { countryService } from 'services/countryListService';
import { notification } from 'antd';
import i18n from 'i18next';
import { getCompaniesService } from 'services/companyService';
import { getIndustriesListService } from 'services/industryService';
import { errorResponseParseHelper } from '../../../utils/constants';
import {
  types,
  setCountriesList,
  setIndustriesList,
  receiveEmailExists,
} from './actions';

function * getCountriesList() {
  const { data, error } = yield call(countryService);
  if (!error) {
    yield put(setCountriesList(data));
  }
}

function * getIndustriesList() {
  const { data, error } = yield call(getIndustriesListService);
  if (!error) {
    yield put(setIndustriesList(data));
  }
}

function * checkEmailExists(action) {
  const email = action.payload.emailData;
  const postData = {
    property: 'email',
    value: email,
  };
  const { data, error } = yield call(getCompaniesService, postData);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: errorResponseParseHelper(error),
    });
  } else if (data.data.length !== 0) {
    yield put(receiveEmailExists(true));
  } else {
    yield put(receiveEmailExists(false));
  }
}

export default function * registerSaga() {
  yield all([
    takeLatest(types.GET_COUNTRIES_LIST, getCountriesList),
    takeLatest(types.GET_INDUSTRIES_LIST, getIndustriesList),
    takeLatest(types.EMAIL_EXISTS, checkEmailExists),
  ]);
}
