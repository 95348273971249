import React from 'react';
import { string } from 'prop-types';

const DollarIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 51.292 37.69"
  >
    <g
      id="prefix__Group_665"
      data-name="Group 665"
      transform="translate(-781.523 -627.309)"
    >
      <g
        id="prefix__Group_620"
        data-name="Group 620"
        transform="translate(-1137 74.618)"
      >
        <path
          id="prefix__Path_619"
          d="M123.346 438.089H88.284a7.477 7.477 0 0 1-7.469-7.469v-21.459a7.478 7.478 0 0 1 7.469-7.47h35.062a7.478 7.478 0 0 1 7.469 7.47v21.459a7.477 7.477 0 0 1-7.469 7.469zm-35.062-32.4a3.474 3.474 0 0 0-3.469 3.47v21.459a3.473 3.473 0 0 0 3.469 3.469h35.062a3.473 3.473 0 0 0 3.469-3.469v-21.456a3.474 3.474 0 0 0-3.469-3.47z"
          className="prefix__cls-1"
          data-name="Path 619"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_620"
          d="M106.295 427.308h-3.785a1.5 1.5 0 0 1-1.5-1.5 1.5 1.5 0 0 1 1.5-1.5h3.99a1.482 1.482 0 0 0 .006-2.964l-1.2-.006a4.617 4.617 0 0 1-4.638-4.043 4.487 4.487 0 0 1 4.461-4.922h3.99a1.5 1.5 0 0 1 1.5 1.5 1.5 1.5 0 0 1-1.5 1.5h-3.99a1.481 1.481 0 0 0-.008 2.963l1.4.007a4.491 4.491 0 0 1 4.437 4.947 4.622 4.622 0 0 1-4.663 4.018z"
          className="prefix__cls-2"
          data-name="Path 620"
          transform="translate(1839 151)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Rectangle_39"
          d="M0 0H3V3.748H0z"
          className="prefix__cls-2"
          data-name="Rectangle 39"
          transform="translate(1943.419 561.141)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Rectangle_40"
          d="M0 0H3V3.677H0z"
          className="prefix__cls-2"
          data-name="Rectangle 40"
          transform="translate(1943.341 576.964)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_621"
          d="M122.84 407.572l-2.021 1.325a1.5 1.5 0 0 0-.432 2.077 1.5 1.5 0 0 0 2.077.432l2.021-1.325a1.5 1.5 0 0 0 .432-2.077 1.5 1.5 0 0 0-2.077-.432z"
          className="prefix__cls-1"
          data-name="Path 621"
          style={{ fill: color }}
          transform="translate(1839 151)"
        />
        <path
          id="prefix__Path_622"
          d="M89.426 427.934l-1.978 1.39a1.5 1.5 0 0 0-.365 2.09 1.5 1.5 0 0 0 2.09.365l1.978-1.39a1.5 1.5 0 0 0 .365-2.09 1.5 1.5 0 0 0-2.09-.365z"
          className="prefix__cls-1"
          data-name="Path 622"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_623"
          d="M122.054 402.983H86.993a7.478 7.478 0 0 0-7.47 7.47v21.459a7.478 7.478 0 0 0 7.47 7.469h35.062a7.478 7.478 0 0 0 7.469-7.469v-21.459a7.48 7.48 0 0 0-7.47-7.47zm3.469 28.929a3.473 3.473 0 0 1-3.469 3.469H86.993a3.474 3.474 0 0 1-3.47-3.469v-21.459a3.474 3.474 0 0 1 3.47-3.47h35.062a3.474 3.474 0 0 1 3.469 3.47v21.459z"
          className="prefix__cls-3"
          data-name="Path 623"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
        <path
          id="prefix__Path_624"
          d="M105.23 419.635l-1.4-.007a1.481 1.481 0 0 1 .007-2.963h3.99a1.5 1.5 0 0 0 0-3h-1.7v-2.232h-3v2.308a4.475 4.475 0 0 0-3.75 4.846 4.618 4.618 0 0 0 4.638 4.043l1.2.006a1.482 1.482 0 0 1-.006 2.964h-3.991a1.5 1.5 0 0 0 0 3h1.832v2.333h3v-2.461a4.545 4.545 0 0 0 3.618-3.89 4.492 4.492 0 0 0-4.438-4.947z"
          className="prefix__cls-3"
          data-name="Path 624"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
        <path
          id="prefix__Path_625"
          d="M121.548 408.864l-2.021 1.325a1.5 1.5 0 0 0 1.645 2.509l2.021-1.325a1.5 1.5 0 0 0-1.645-2.509z"
          className="prefix__cls-3"
          data-name="Path 625"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
        <path
          id="prefix__Path_626"
          d="M88.134 429.226l-1.978 1.39a1.5 1.5 0 1 0 1.725 2.455l1.978-1.39a1.5 1.5 0 1 0-1.725-2.455z"
          className="prefix__cls-3"
          data-name="Path 626"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
      </g>
    </g>
  </svg>
);

DollarIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

DollarIcon.defaultProps = {
  color: '#37ead9',
  width: '51.292',
  height: '37.69',
};

export default DollarIcon;
