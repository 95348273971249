import {
  all, call, takeLatest, put,
} from 'redux-saga/effects';
import { loginSuccess } from 'actions/auth';
import { notification } from 'antd';
import i18n from 'i18next';
import JwtDecode from 'jwt-decode';
import { VerifyEmailService } from 'services/authService';
import { requestPasswordResetService, resetPasswordService } from 'services/passwordService';
import history from '../../../history';
import { types, hideModal, setAnimationStatus } from './actions';
import { ReactivateEmailService } from "../../../services/authService";

function * sendResetPasswordEmail(action) {
  const { email } = action.payload;
  const { data, error } = yield call(requestPasswordResetService, email);
  if (!error) {
    yield put(hideModal());
    yield put(setAnimationStatus(true));
    notification.success({
      message: i18n.t('notification success'),
      description: data.message,
    });
  } else {
    yield put(hideModal());
    yield put(setAnimationStatus(true));
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  }
}

function * registrationConfirmation(action) {
  const verifyEmailData = action.payload.userData;
  const { data, error } = yield call(VerifyEmailService, 'verify-email', verifyEmailData);
  if (!error) {
    notification.success({
      message: i18n.t('notification success'),
      description: data.message,
    });
  } else if (error !== 'Vaš email je već verifikovan') {
    history.push('/resend-email-activation');
  }
}

function * sendReactivateEmail(action) {
  const { email } = action.payload;
  const { data, error } = yield call(ReactivateEmailService, email);
  if (!error) {
    notification.success({
      message: i18n.t('notification success'),
      description: data.message,
    });
  } else {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  }
}
function * resetPassword(action) {
  const { email, password, token } = action.payload;
  const { data, error } = yield call(resetPasswordService, {
    email,
    password,
    token,
  });
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
      description: data.message,
    });
    const decodedToken = JwtDecode(data.accessToken);
    localStorage.setItem('userId', decodedToken.company.id);
    localStorage.setItem('userSlug', decodedToken.company.slug);
    localStorage.setItem('token', data.accessToken);
    localStorage.setItem('refresh_token', data.refreshToken);
    yield put(loginSuccess(data.accessToken));
    setTimeout(() => {
      history.push('/');
    }, 1000);
  }
}

export default function * loginSaga() {
  yield all([
    takeLatest(types.SEND_RESET_PASSWORD_EMAIL, sendResetPasswordEmail),
    takeLatest(types.RESET_PASSWORD, resetPassword),
    takeLatest(types.REGISTRATION_CONFIRMATION, registrationConfirmation),
    takeLatest(types.SEND_REACTIVATE_EMAIL, sendReactivateEmail),
  ]);
}
