import React, { useState } from 'react';
import { Rate } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import buttonStyles from 'utils/buttonStyle.module.scss';
import PropTypes from 'prop-types';
import styles from './rateContainer.module.scss';
import { useLocation } from 'react-router-dom';

const successRatingMessageDuration = 3000;

const RateContainer = (props) => {
  const { actions, id } = props;
  const { t } = useTranslation();
  const [rating, setRating] = useState(null);
  const [ratingDone, setRatingDone] = useState(false);
  const [customClass, setCustomClass] = useState('');
  const location = useLocation();

  const handleChange = (value) => {
    setRating(value);
  };

  const rateCompany = () => {
    if (localStorage.getItem('userId')) {
      actions.rateCompany(id, rating);
      setRatingDone(true);
      setTimeout(() => setCustomClass('invisible'), successRatingMessageDuration);
    } 
    else {
      actions.showModal();
      actions.setNextRoute(location.pathname);
    }
  };

  return (
    <div className={classNames(styles.rateContainer, customClass)}>
      {ratingDone ? (
        <div className={styles.ratingDoneContainer}>
          <CheckOutlined className={styles.checkOutlinedStyle} />
          <h3>{t('rateCompany ratingSuccess')}</h3>
        </div>
      ) : (
        <div>
          <div className="px-3">
            <h3>{t('rateCompany title')}</h3>
            <div className={styles.ratingSlider}>
              <Rate
                onChange={handleChange}
                value={rating}
                style={{ color: 'black' }}
              />
              {rating && <p>{`${rating}/5`}</p>}
            </div>
          </div>
          <button
            type="button"
            className={classNames(
              buttonStyles.buttonSuccessStyle,
              styles.customButtonStyles,
              !rating && 'btn disabled',
            )}
            onClick={rateCompany}
            disabled={!rating}
          >
            {t('rateCompany button')}
          </button>
        </div>
      )}
    </div>
  );
};

const { shape, func, string } = PropTypes;

RateContainer.propTypes = {
  actions: shape({
    rateCompany: func.isRequired,
  }).isRequired,
  id: string.isRequired,
};

export default RateContainer;
