import React from 'react';
import { number, string } from 'prop-types';

const OpenNewTabIcon = ({
  fontSize, width, height, color,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    style={{ fontSize: `${fontSize}px` }}
  >
    <path
      fill={color}
      d="M8.001 2.315h3.606V5.92h-1.041V4.08L5.509 9.139l-.726-.726L9.84 3.355H8v-1.04h.001zm2.565 8.251V6.961h1.04v3.605c0 .556-.484 1.04-1.04 1.04H3.355c-.58 0-1.04-.484-1.04-1.04V3.355c0-.557.46-1.04 1.04-1.04H6.96v1.04H3.355v7.211h7.211z"
    />
  </svg>
);

OpenNewTabIcon.propTypes = {
  fontSize: number,
  width: string,
  height: string,
  color: string,
};

OpenNewTabIcon.defaultProps = {
  fontSize: 15,
  width: '1em',
  height: '1em',
  color: 'black',
};

export default OpenNewTabIcon;
