import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { layoutPositions } from 'utils/parse-helper';
import { Map as IMap, List as iList } from 'immutable';
import ClientItem from './ClientItem';
import styles from './ourClients.module.scss';

const OurClients = (props) => {
  const { clients, colorTheme, fontFamily } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.ourClientsContainer}>
      <div className="container">
        <h4 className={clients.get('textAlignment', '')}>
          {t('createCompany ourClients sectionTitle')}
        </h4>
        <h2
          className={classNames(
            styles.inputSectionTitle,
            clients.get('textAlignment', ''),
            styles.noBorderStyle,
          )}
          style={{ color: colorTheme, fontFamily }}
        >
          {clients.get('title', '')}
        </h2>
        <div className={styles.clientsContainer}>
          {clients
            && clients
              .get('clients', iList())
              .filter(
                (item) => item.get('comment', '')
                  && item.get('name', '')
                  && item.get('position', ''),
              )
              .map((item) => (
                <div
                  className={classNames(
                    'col-12',
                    layoutPositions(
                      clients
                        .get('clients', iList())
                        .filter(
                          (client) => client.get('comment', '')
                            && client.get('name', '')
                            && client.get('position', ''),
                        ).size,
                    ),
                  )}
                  key={item.get('key', '')}
                >
                  <ClientItem
                    textAlignment={clients.get('textAlignment', '')}
                    comment={item.get('comment', '')}
                    name={item.get('name', '')}
                    position={item.get('position', '')}
                    itemKey={item.get('key', '')}
                    imageUrl={item.get('imageUrl', '')}
                  />
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

const { string } = PropTypes;
const { map } = ImmutablePropTypes;

OurClients.propTypes = {
  clients: map,
  colorTheme: string.isRequired,
  fontFamily: string.isRequired,
};

OurClients.defaultProps = {
  clients: IMap(),
};

export default OurClients;
