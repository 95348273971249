import React from 'react';
import { bool } from 'prop-types';
import { list } from 'react-immutable-proptypes';

import NewsArticle from './NewsArticle';
import styles from '../news.module.scss';

const NewsBanner = (props) => {
  if (props.newsArticles.size < 3) {
    return null;
  }

  return (
    <div className={styles.newsArticles}>
      <div className={styles.articleLeft}>
        <NewsArticle
          showTag={props.showTag}
          article={props.newsArticles.get(0)}
        />
      </div>
      <div className={styles.articlesRight}>
        <NewsArticle
          showTag={props.showTag}
          article={props.newsArticles.get(1)}
        />
        <NewsArticle
          showTag={props.showTag}
          article={props.newsArticles.get(2)}
        />
      </div>
    </div>
  );
};

NewsBanner.propTypes = {
  showTag: bool.isRequired,
  newsArticles: list.isRequired,
};

export default NewsBanner;
