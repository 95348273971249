import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  selectActiveCompanyTheme,
  selectIsToggleDisabled,
  selectActiveCompanyName,
} from 'selectors/auth';
import { updateActiveCompanyTheme } from 'actions/auth';
import { hideModal, showModal } from './modules/actions';
import { selectModalVisibility, selectCompanyLogo } from './modules/selectors';

const mapStateToProps = (state) => ({
  modalVisibility: selectModalVisibility(state),
  theme: selectActiveCompanyTheme(state),
  imageUrl: selectCompanyLogo(state),
  isToggleDisabled: selectIsToggleDisabled(state),
  companyName: selectActiveCompanyName(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      hideModal,
      showModal,
      updateActiveCompanyTheme,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
