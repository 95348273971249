import React from 'react';
import { string } from 'prop-types';

const CartIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 42.777 38.827"
  >
    <g
      id="prefix__Group_664"
      data-name="Group 664"
      transform="translate(-954.645 -626.741)"
    >
      <g
        id="prefix__Group_623"
        data-name="Group 623"
        transform="translate(-1137 74.618)"
      >
        <path
          id="prefix__Path_614"
          d="M287.608 426.8h-17.953a3.87 3.87 0 0 1-3.85-3.527l-5.437-18.2h-4.455a1.976 1.976 0 1 1 0-3.951h5.926a1.976 1.976 0 0 1 1.893 1.41l5.926 19.842a1.967 1.967 0 0 1 .083.565l17.866-.087c0-.183-.062-.278-.011-.453l3.237-11.4h-15.166a1.975 1.975 0 1 1 0-3.95h17.78a1.976 1.976 0 0 1 1.9 2.515l-3.889 13.7a3.867 3.867 0 0 1-3.85 3.536z"
          data-name="Path 614"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <circle
          id="prefix__Ellipse_10"
          cx="3.951"
          cy="3.951"
          r="3.951"
          className="prefix__cls-2"
          data-name="Ellipse 10"
          transform="translate(2102.816 581.756)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_615"
          d="M285.546 434.707a3.951 3.951 0 1 1 3.951 3.951 3.951 3.951 0 0 1-3.951-3.951z"
          className="prefix__cls-2"
          data-name="Path 615"
          transform="translate(1839 151)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_616"
          d="M268.367 423.667l-5.926-19.842a1.976 1.976 0 0 0-1.893-1.41h-5.927a1.976 1.976 0 1 0 0 3.951h4.455l5.438 18.2a3.87 3.87 0 0 0 3.85 3.527h17.953a3.87 3.87 0 0 0 3.851-3.542l3.889-13.7a1.976 1.976 0 0 0-1.9-2.515h-17.78a1.976 1.976 0 0 0 0 3.952h15.166l-3.237 11.4c-.05.176.012.27.012.453l-17.867.087a2.012 2.012 0 0 0-.084-.561z"
          className="prefix__cls-3"
          data-name="Path 616"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
        <path
          id="prefix__Path_617"
          d="M262.524 436a3.951 3.951 0 1 0 3.951-3.951 3.951 3.951 0 0 0-3.951 3.951z"
          className="prefix__cls-3"
          data-name="Path 617"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
        <path
          id="prefix__Path_618"
          d="M284.254 436a3.951 3.951 0 1 0 3.951-3.951 3.951 3.951 0 0 0-3.951 3.951z"
          className="prefix__cls-3"
          data-name="Path 618"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
      </g>
    </g>
  </svg>
);

CartIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

CartIcon.defaultProps = {
  color: '#37ead9',
  width: '42.777',
  height: '38.827',
};

export default CartIcon;
