export const types = {
  DELETE_COMPANY_BY_ID: 'DELETE_COMPANY_BY_ID',
  DELETE_PRODUCT_BY_ID: 'DELETE_PRODUCT_BY_ID',
  CHANGE_SUBSCRIPTION_OR_INDUSTRY: 'CHANGE_SUBSCRIPTION_OR_INDUSTRY',
  SHOW_REGISTER_NEW_COMPANY_MODAL: 'SHOW_REGISTER_NEW_COMPANY_MODAL',
  HIDE_REGISTER_NEW_COMPANY_MODAL: 'HIDE_REGISTER_NEW_COMPANY_MODAL',
  UPDATE_COMPANY_STATUS_BY_ID: 'UPDATE_COMPANY_STATUS_BY_ID',
  REQUEST_UPDATE_COMPANY_BY_ID: 'REQUEST_UPDATE_COMPANY_BY_ID',
  UPDATE_COMPANY_BY_ID: 'UPDATE_COMPANY_BY_ID',
  GET_ADMIN_STATISTIC: 'GET_ADMIN_STATISTIC',
  SET_ADMIN_STATISTIC: 'SET_ADMIN_STATISTIC'
};

export const getAdminStatistic = (queryObject) => ({
  type: types.GET_ADMIN_STATISTIC,
  payload: {
    queryObject,
  },
});

export const setAdminStatistic = (content) => ({
  type: types.SET_ADMIN_STATISTIC,
  payload: {
    content,
  },
});

export const deleteCompanyById = (id) => ({
  type: types.DELETE_COMPANY_BY_ID,
  payload: {
    id,
  },
});

export const deleteProductById = (id, type) => ({
  type: types.DELETE_PRODUCT_BY_ID,
  payload: {
    id,
    type,
  },
});

export const changeSubscriptionOrIndustry = (companyId, dataId, formData, columnName) => ({
  type: types.CHANGE_SUBSCRIPTION_OR_INDUSTRY,
  payload: {
    companyId,
    dataId,
    formData,
    columnName,
  },
});

export const hideModal = () => ({
  type: types.HIDE_REGISTER_NEW_COMPANY_MODAL,
  payload: {},
});

export const showModal = () => ({
  type: types.SHOW_REGISTER_NEW_COMPANY_MODAL,
  payload: {},
});

export const requestUpdateCompanyById = (companyId, formData, columnName) => ({
  type: types.REQUEST_UPDATE_COMPANY_BY_ID,
  payload: {
    companyId,
    formData,
    columnName,
  },
});

export const updateCompanyById = (companyId, companyData) => ({
  type: types.UPDATE_COMPANY_BY_ID,
  payload: {
    companyId,
    companyData,
  },
});

export const updateCompanyStatusById = (companyId, formData, columnName) => ({
  type: types.UPDATE_COMPANY_STATUS_BY_ID,
  payload: {
    companyId,
    formData,
    columnName,
  },
});

export default types;
