import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { rateCompany } from 'actions/auth';
import { showModal, setNextRoute } from 'components/Login/modules/actions';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      rateCompany,
      showModal,
      setNextRoute
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps);
