import React, { useEffect, useState } from 'react';
import { List as IList } from 'immutable';
import { list } from 'react-immutable-proptypes';

import Article from './Article';

import styles from './smallerArticles.module.scss';

const SmallerArticles = (props) => {
  const [newsArticles, setNewsArticles] = useState(IList());

  useEffect(() => {
    if (props.articles.size < 3) {
      setNewsArticles(props.articles);
    } else {
      setNewsArticles(props.articles.slice(3, 5));
    }
  }, [props.articles]);

  if (newsArticles.size === 0) {
    return null;
  }

  return (
    <div className="container">
      <div className={styles.divider} />
      <div className={styles.smallerArticles}>
        {newsArticles.map((article) => (
          <Article article={article} isHomepage style={styles} />
        ))}
      </div>
    </div>
  );
};

SmallerArticles.propTypes = {
  articles: list,
};

SmallerArticles.defaultProps = {
  articles: IList(),
};

export default SmallerArticles;
