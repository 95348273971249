import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { loginIcon } from '../../../images';
import styles from '../loginContainer.module.scss';
import LoginForm from '../loginForm';
import { registerUrl } from 'utils/pathsHelper';

const LoginStandalone = (props) => {
  const { actions } = props;
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.loginFormContainer, 'col-md-12')}>
      <div className={styles.loginHeader}>
        <img src={loginIcon} alt="login to bizbook" />
        <div className={styles.loginHeader}>
          <h1>{t('login headline')}</h1>
          <h2>{t('login tagline')}</h2>
        </div>
      </div>
      <div className={classNames(styles.loginHeader, 'col-lg-5 col-md-6 col-sm-6 col-12')}>
        <LoginForm actions={actions} setIsPaused={() => { }} />
      </div>
      <h3>
        {t('login noProfileText noProfile')}
        {' '}
        <Link to={registerUrl}>
          {' '}
          {t('login noProfileText becomeUser')}
        </Link>
      </h3>
    </div>
  );
};

const { shape, func } = PropTypes;

LoginStandalone.propTypes = {
  actions: shape({
    login: func.isRequired,
  }).isRequired,
};

export default LoginStandalone;
