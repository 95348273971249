import { parseError } from 'utils/parse-helper';
import { ApiService } from './apiService';

export const requestPasswordResetService = async (email) => ApiService.post(
  `/auth/requestpasswordreset/${email}`,
)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const resetPasswordService = async (userData) => ApiService.post('/auth/resetpassword', userData)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));
