import i18n from 'i18next';

export default function validate(values) {
  const errors = {};
  if (!values.email) {
    errors.email = i18n.t('inputValidationError email empty');
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = i18n.t('inputValidationError email invalid');
  }

  if (!values.body) {
    errors.body = i18n.t('inputValidationError textarea empty');
  }

  return errors;
}
