import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import termsAndConditions from './termsAndConditions';
import styles from './termsAndConditions.module.scss';

const TermsAndConditions = (props) => {
  const { activeLanguage } = props;
  const { t } = useTranslation();
  const [displayTermsAndConditions, setTermsAndConditions] = useState(
    termsAndConditions(),
  );
  useEffect(() => {
    setTermsAndConditions(termsAndConditions());
  }, [activeLanguage]);
  return (
    <div
      className={classNames('container', styles.termsAndConditionsContainer)}
    >
      <h2>{t('termsAndConditions title')}</h2>
      <div>
        {displayTermsAndConditions.map((item) => (
          <div key={item.id}>
            <h5>{item.title}</h5>
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const { string } = PropTypes;

TermsAndConditions.propTypes = {
  activeLanguage: string.isRequired,
};

export default TermsAndConditions;
