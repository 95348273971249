import React from 'react';
import PropTypes, { func } from 'prop-types';
import classNames from 'classnames';
import Autocomplete from 'react-google-autocomplete';
import inputStyle from 'utils/inputStyle.module.scss';
import styles from "../contactInfo.module.scss";

const GoogleAutocomplete = (props) => {
  const {
    label, setCoordinates, value, placeholder, handleChange, disabled, error, id, onBlur,
  } = props;

  return (
    <div
      className={classNames(
        inputStyle.formGroup,
        'col-11 my-3 mr-auto mx-auto',
        styles.countryInputStyle,
        error ? inputStyle.validationError : '',
      )}
      id={id}
    >
      <h6 className={inputStyle.error}>{error}</h6>
      <h5 className="px-1">{label}</h5>
      <Autocomplete
        className={classNames(inputStyle.formControl, 'py-2 my-1')}
        placeholder={placeholder}
        onPlaceSelected={(place) => {
          setCoordinates(
            {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
            place.formatted_address.split(',')[0],
          );
          handleChange();
        }}
        types={['geocode']}
        defaultValue={value}
        // NOTE: Check this feature
        // componentRestrictions={{ country: `${countryIsoCode}` }}
        disabled={disabled}
        onBlur={onBlur}
      />
    </div>
  );
};

const { string, bool } = PropTypes;

GoogleAutocomplete.propTypes = {
  label: string.isRequired,
  setCoordinates: func.isRequired,
  value: string,
  placeholder: string,
  handleChange: func.isRequired,
  disabled: bool,
  error: string,
  id: string,
  onBlur: func,
};

GoogleAutocomplete.defaultProps = {
  value: '',
  placeholder: '',
  disabled: false,
  error: '',
  id: '',
  onBlur: () => {},
};

export default GoogleAutocomplete;
