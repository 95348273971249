import React from 'react';
import { string } from 'prop-types';

const WindmillIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 49.817 52.204"
  >
    <g
      id="prefix__Group_643"
      data-name="Group 643"
      transform="translate(-966.088 -184.554) translate(712.198 -8.855)"
    >
      <g id="prefix__Group_56" data-name="Group 56">
        <g id="prefix__Group_39" data-name="Group 39">
          <g id="prefix__Group_36" data-name="Group 36">
            <path
              id="prefix__Path_49"
              d="M287.9 242.308l-4.415-13.458 3.766-1.351 4.414 13.457z"
              className="prefix__cls-1"
              data-name="Path 49"
              style={{ fill: '#050713' }}
            />
          </g>
          <g id="prefix__Group_37" data-name="Group 37">
            <path
              id="prefix__Path_50"
              d="M279.7 242.028a2 2 0 0 1-2-2v-1.854a2 2 0 0 1 2-2 2 2 0 0 1 2 2v1.854a2 2 0 0 1-2 2z"
              className="prefix__cls-1"
              data-name="Path 50"
              style={{ fill: '#050713' }}
            />
          </g>
          <g id="prefix__Group_38" data-name="Group 38">
            <path
              id="prefix__Path_51"
              d="M271.487 242.308l-3.765-1.352 1.122-3.49a2 2 0 0 1 2.579-1.27 2 2 0 0 1 1.228 2.493z"
              className="prefix__cls-1"
              data-name="Path 51"
              style={{ fill: '#050713' }}
            />
          </g>
        </g>
        <g id="prefix__Group_54" data-name="Group 54">
          <g id="prefix__Group_46" data-name="Group 46">
            <g id="prefix__Group_45" data-name="Group 45">
              <g id="prefix__Group_40" data-name="Group 40">
                <path
                  id="prefix__Rectangle_6"
                  d="M0 0H4V7.565H0z"
                  className="prefix__cls-1"
                  data-name="Rectangle 6"
                  transform="rotate(-45 390.13 -221.055)"
                  style={{ fill: '#050713' }}
                />
              </g>
              <g id="prefix__Group_41" data-name="Group 41">
                <path
                  id="prefix__Rectangle_7"
                  d="M0 0H4V7.564H0z"
                  className="prefix__cls-1"
                  data-name="Rectangle 7"
                  transform="rotate(-45 407.329 -228.148)"
                  style={{ fill: '#050713' }}
                />
              </g>
              <g id="prefix__Group_42" data-name="Group 42">
                <path
                  id="prefix__Path_52"
                  d="M279.7 222.742a5.324 5.324 0 1 1 3.764-1.56 5.286 5.286 0 0 1-3.764 1.56zm0-6.647a1.324 1.324 0 0 0-.936 2.26 1.354 1.354 0 0 0 1.871 0 1.323 1.323 0 0 0 0-1.87 1.311 1.311 0 0 0-.935-.385z"
                  className="prefix__cls-2"
                  data-name="Path 52"
                  style={{ fill: color }}
                />
              </g>
              <g id="prefix__Group_43" data-name="Group 43">
                <path
                  id="prefix__Path_53"
                  d="M269.361 213.865a3.159 3.159 0 0 1-2.251-.934l-10.495-10.495a3.179 3.179 0 0 1 0-4.5l3.6-3.6a3.186 3.186 0 0 1 4.5 0l10.5 10.5a3.185 3.185 0 0 1 0 4.5l-3.6 3.6a3.164 3.164 0 0 1-2.254.929zm-9.338-13.678l9.338 9.338 2.441-2.44-9.338-9.338z"
                  className="prefix__cls-1"
                  data-name="Path 53"
                  style={{ fill: '#050713' }}
                />
              </g>
              <g id="prefix__Group_44" data-name="Group 44">
                <path
                  id="prefix__Path_54"
                  d="M296.926 241.429a3.157 3.157 0 0 1-2.251-.934L284.18 230a3.179 3.179 0 0 1 0-4.5l3.6-3.6a3.257 3.257 0 0 1 4.5 0l10.494 10.5a3.181 3.181 0 0 1 0 4.5l-3.6 3.6a3.162 3.162 0 0 1-2.248.929zm-9.338-13.678l9.338 9.338 2.441-2.44-9.338-9.338zm3.02-3.02z"
                  className="prefix__cls-1"
                  data-name="Path 54"
                  style={{ fill: '#050713' }}
                />
              </g>
            </g>
          </g>
          <g id="prefix__Group_53" data-name="Group 53">
            <g id="prefix__Group_52" data-name="Group 52">
              <g id="prefix__Group_47" data-name="Group 47">
                <path
                  id="prefix__Path_55"
                  d="M262.464 241.429a3.163 3.163 0 0 1-2.251-.934l-3.6-3.6a3.181 3.181 0 0 1 0-4.5l10.499-10.495a3.257 3.257 0 0 1 4.5 0l3.6 3.6a3.181 3.181 0 0 1 0 4.5l-10.5 10.495a3.158 3.158 0 0 1-2.248.934zm-2.441-6.78l2.441 2.44 9.338-9.338-2.44-2.44z"
                  className="prefix__cls-2"
                  data-name="Path 55"
                  style={{ fill: color }}
                />
              </g>
              <g id="prefix__Group_48" data-name="Group 48">
                <path
                  id="prefix__Rectangle_8"
                  d="M0 0H7.564V4H0z"
                  className="prefix__cls-2"
                  data-name="Rectangle 8"
                  transform="rotate(-45 405.323 -214.766)"
                  style={{ fill: color }}
                />
              </g>
              <g id="prefix__Group_49" data-name="Group 49">
                <path
                  id="prefix__Path_56"
                  d="M290.028 213.865a3.157 3.157 0 0 1-2.25-.934l-3.6-3.6a3.181 3.181 0 0 1 0-4.5l10.495-10.495a3.187 3.187 0 0 1 4.5 0l3.6 3.6a3.181 3.181 0 0 1 0 4.5l-10.493 10.496a3.168 3.168 0 0 1-2.252.933zm-2.441-6.78l2.441 2.44 9.338-9.338-2.44-2.44z"
                  className="prefix__cls-2"
                  data-name="Path 56"
                  style={{ fill: color }}
                />
              </g>
              <g id="prefix__Group_50" data-name="Group 50">
                <path
                  id="prefix__Rectangle_9"
                  d="M0 0H7.565V4H0z"
                  className="prefix__cls-2"
                  data-name="Rectangle 9"
                  transform="rotate(-45 398.267 -231.87)"
                  style={{ fill: color }}
                />
              </g>
              <g id="prefix__Group_51" data-name="Group 51">
                <path
                  id="prefix__Path_57"
                  d="M279.7 222.742a5.324 5.324 0 0 1-3.764-9.088 5.323 5.323 0 1 1 3.764 9.089zm0-6.647a1.315 1.315 0 0 0-.936.388 1.323 1.323 0 0 0 0 1.87 1.354 1.354 0 0 0 1.871 0 1.324 1.324 0 0 0-.935-2.259z"
                  className="prefix__cls-1"
                  data-name="Path 57"
                  style={{ fill: '#050713' }}
                />
              </g>
            </g>
          </g>
        </g>
        <g id="prefix__Group_55" data-name="Group 55">
          <path
            id="prefix__Path_58"
            d="M290.941 244.028h-22.492a2 2 0 0 1-2-2 2 2 0 0 1 2-2h22.492a2 2 0 0 1 2 2 2 2 0 0 1-2 2z"
            className="prefix__cls-1"
            data-name="Path 58"
            style={{ fill: '#050713' }}
          />
        </g>
      </g>
      <path
        id="prefix__Path_59"
        d="M285.6 214.131l.384.384a3.178 3.178 0 0 0 4.5 0l10.495-10.495a3.181 3.181 0 0 0 0-4.5l-3.6-3.6a3.187 3.187 0 0 0-4.5 0l-10.495 10.495a3.181 3.181 0 0 0 0 4.5l.386.386-2.8 2.8a5.3 5.3 0 0 0-4.146 0l-2.8-2.8.385-.385a3.186 3.186 0 0 0 0-4.5l-10.5-10.5a3.186 3.186 0 0 0-4.5 0l-3.6 3.6a3.18 3.18 0 0 0 0 4.5l10.495 10.495a3.159 3.159 0 0 0 2.25.934 3.162 3.162 0 0 0 2.25-.933l.385-.385 2.8 2.8c-.005.012-.014.023-.019.035-.045.108-.065.226-.1.337a5.133 5.133 0 0 0-.2.654 5.35 5.35 0 0 0 .319 3.12l-2.8 2.8-.384-.384a3.257 3.257 0 0 0-4.5 0l-10.495 10.495a3.181 3.181 0 0 0 0 4.5l3.6 3.6a3.178 3.178 0 0 0 4.5 0l10.5-10.495a3.181 3.181 0 0 0 0-4.5l-.386-.386 2.8-2.8a5.313 5.313 0 0 0 4.148 0l2.8 2.8-.385.385a3.14 3.14 0 0 0-.6 3.651l3.568 10.875H279.9v-1.854a2 2 0 1 0-4 0v1.854h-5.475l.43-1.337a2 2 0 0 0-3.807-1.223l-.84 2.615a2 2 0 0 0 .445 3.946h22.492a2 2 0 0 0 .439-3.948l-1.406-4.285 4.7 4.7a3.178 3.178 0 0 0 4.5 0l3.6-3.6a3.181 3.181 0 0 0 0-4.5l-10.496-10.492a3.257 3.257 0 0 0-4.5 0l-.385.385-2.8-2.8a5.312 5.312 0 0 0 .421-2.073 5.427 5.427 0 0 0-.1-1.047 5.225 5.225 0 0 0-.319-1.027zm9.532-14.8l2.44 2.44-9.338 9.338-2.441-2.44zm-36.9 2.44l2.44-2.44 9.338 9.338-2.441 2.44zm2.44 36.9l-2.441-2.441 9.338-9.338 2.441 2.44zm36.9-2.441l-2.441 2.441-9.338-9.338 2.44-2.44zm-20.608-16.295a1.32 1.32 0 0 1 0-1.871 1.323 1.323 0 0 1 1.87 1.872 1.352 1.352 0 0 1-1.867.001z"
        data-name="Path 59"
        style={{ opacity: 0.2, fill: color }}
      />
    </g>
  </svg>
);

WindmillIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

WindmillIcon.defaultProps = {
  color: '#37ead9',
  width: '49.817',
  height: '52.204',
};

export default WindmillIcon;
