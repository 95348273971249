import { all, call, takeLatest, put } from 'redux-saga/effects';
import i18n from 'i18next';
import { notification } from 'antd';
import { packageContactService, sendMailContactService } from 'services/utilsService';
import { types, hideModal, resetContactUsFormData } from './actions';

function* sendSubscriptionPackageRequest(action) {
  const {
    values: { email, text, phone },
    requestedPackageType,
  } = action.payload.postData;
  const { error } = yield call(packageContactService, {
    email,
    body: text,
    phone,
    packageName: requestedPackageType,
  });
  yield put(hideModal());
  if (!error) {
    notification.success({
      message: i18n.t('notification success'),
      description: 'Email was successfully sent',
    });
  } else {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  }
}

function* sendContactUsMail(action) {
  const { body, email, phone } = action.payload.data;
  const { error } = yield call(sendMailContactService,
    phone ? {
      email,
      body,
      phone,
    } : {
      email,
      body,
    });
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
      description: i18n.t('conctactEmail emailSuccess'),
    });
    yield put(resetContactUsFormData());
  }
}

export default function* subscriptionPackagesSaga() {
  yield all([
    takeLatest(
      types.SEND_SUBSCRIPTION_PACKAGE_REQUEST,
      sendSubscriptionPackageRequest,
    ),
    takeLatest(types.SEND_CONTACT_US_MAIL, sendContactUsMail),
  ]);
}
