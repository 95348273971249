import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List as IList } from 'immutable';

import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import styles from './businessOpportunities.module.scss';
import homeStyles from '../home.module.scss';
import SliderCard from './SliderCard';
import PreviousArrow from '../Arrows/PreviousArrow';
import NextArrow from '../Arrows/NextArrow';

const BusinessOpportunities = (props) => {
  const {
    products,
    demands,
    actions,
    contentType,
  } = props;

  const [sliderContent, setSliderContent] = useState(IList());
  const { t } = useTranslation();
  const settings = {
    autoplay: true,
    infinite: true,
    dots: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: sliderContent.size >= 3 ? 3 : sliderContent.size,
    slidesToScroll: 1,
    initialSlide: 1,
    pauseOnHover: true,
    pauseOnDotsHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    slideToScroll: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: sliderContent.size >= 2 ? 2 : sliderContent.size,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: sliderContent.size >= 2 ? 2 : sliderContent.size,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    actions.getHomepageContent({ type: contentType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contentType === 'product') {
      setSliderContent(products);
    } else {
      setSliderContent(demands);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, demands]);

  return (
    <div className="container">
      <div className={styles.businessOpportunities}>
        {t(`homepage businessOpportunities ${contentType}s tagline`) === ''
          ? null
          : (
            <h3 className={homeStyles.homeSubtitle}>
              {t(`homepage businessOpportunities ${contentType}s tagline`)}
            </h3>
          )}
        <h1 className={homeStyles.homeTitle}>
          {t(`homepage businessOpportunities ${contentType}s headline`)}
        </h1>
        <div className={classnames(
          sliderContent.size === 2 && styles.slider2,
          sliderContent.size === 1 && styles.slider1,
        )}
        >
          <Slider {...settings}>
            {sliderContent.size > 0 && sliderContent.map((content) => (
              <SliderCard
                actions={actions}
                cardDetails={content}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

const { shape, func, string } = PropTypes;
const { list } = ImmutablePropTypes;

BusinessOpportunities.propTypes = {
  contentType: string.isRequired,
  products: list,
  demands: list,
  actions: shape({
    getHomepageContent: func.isRequired,
    showModal: func.isRequired,
    setNextRoute: func.isRequired,
    setNextRouteParams: func.isRequired,
  }).isRequired,
};

BusinessOpportunities.defaultProps = {
  products: IList(),
  demands: IList(),
};

export default BusinessOpportunities;
