import React from 'react';
import buttonStyle from 'utils/buttonStyle.module.scss';
import Fade from 'react-reveal/Fade';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import homeStyles from '../home.module.scss';
import styles from './businessPlatform.module.scss';

const BusinessPlatform = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <Fade>
        <div className={styles.businessPlatform}>
          <h4
            className={homeStyles.homeSubtitle}
          >
            {`${t('homepage businessPlatform tagline allInOne')} ${t('homepage businessPlatform tagline businessPlatform')}`}
          </h4>
          <h1 className={homeStyles.homeTitle}>
            {t('homepage businessPlatform headline')}
          </h1>
          <p
            className={classNames(
              homeStyles.homeText,
              styles.homeText,
            )}
          >
            {t('homepage businessPlatform text')}
          </p>
          <div className={styles.buttonContainer}>
            <Link to="/testimonials">
              <button
                type="button"
                className={classNames(buttonStyle.mainButtonStyle, 'mt-3')}
              >
                {t('homepage businessPlatform button')}
              </button>
            </Link>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default BusinessPlatform;
