import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import SimpleMap from 'components/Map';
import inputFields from './contactInfoInputs';
import styles from './contactInfo.module.scss';

const renderContactInfoContainer = (label, value) => (
  <div className={styles.contactInfo}>
    <h5>{label}</h5>
    <h4>{value}</h4>
  </div>
);

const renderWebsiteContainer = (label, value) => {
  let newValue = '';
  if (value.substring(0, 4) !== 'http') newValue = `https://${value}`;
  else newValue = value;
  return (
    <div className={styles.contactInfo}>
      <h5>{label}</h5>
      <a
        target="_blank"
        href={newValue}
      >
        {value}
      </a>
    </div>
  );
};

const ContactInfo = (props) => {
  const {
    title,
    address,
    city,
    countryIso,
    idNumber,
    phone,
    contactEmail,
    website,
    textAlignment,
    colorTheme,
    fontFamily,
    positionInfo,
    countriesList,
    activeLanguage,
    actions,
  } = props;
  const { t } = useTranslation();
  useEffect(() => {
    actions.getCountriesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const findCountryByIsoCode = (iso) => countriesList.find((country) => country.get('ISO') === iso);
  const translateCountryNameByIso = (countryISO) => findCountryByIsoCode(countryISO)?.getIn(
    ['translations', activeLanguage === 'eng' ? 'en' : 'hr'],
    findCountryByIsoCode(countryISO)?.get('name', ''),
  );

  return (
    <div className={classNames(styles.contactInfoMainContainer)}>
      <div
        className={classNames(
          'container',
          styles.contactInfoContainer,
          textAlignment === 'text-center'
            ? 'align-items-center'
            : textAlignment === 'text-right'
              ? 'align-items-end'
              : '',
        )}
      >
        <h4>{t('createCompany contactInfo sectionTitle')}</h4>
        <h2
          className={classNames(
            styles.inputSectionTitle,
            textAlignment,
            styles.noBorderStyle,
          )}
          style={{ color: colorTheme, fontFamily }}
        >
          {title}
        </h2>
        <div className={styles.inputFieldsContainer}>
          <div className={styles.inputMain}>
            {address
              && renderContactInfoContainer(inputFields().address.label, address)}
            {city && renderContactInfoContainer(inputFields().city.label, city)}
            {countryIso
              && renderContactInfoContainer(
                inputFields().countryIso.label,
                translateCountryNameByIso(countryIso),
              )}
            {idNumber
              && renderContactInfoContainer(
                inputFields().idNumber.label,
                idNumber,
              )}
            {phone
              && renderContactInfoContainer(inputFields().phone.label, phone)}
            {contactEmail
              && renderContactInfoContainer(
                inputFields().contactEmail.label,
                contactEmail,
              )}
            {website && renderWebsiteContainer(inputFields().website.label, website)}
          </div>
          <div
            className={classNames(
              styles.simpleMapContainer,
              'my-auto ml-0 ml-md-4 pb-5 pb-md-0',
            )}
          >
            <SimpleMap positionInfo={positionInfo} draggableMarker={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

const {
  string, shape, func,
} = PropTypes;

const {
  map, mapContains, listOf, list,
} = ImmutablePropTypes;

ContactInfo.propTypes = {
  title: string,
  address: string,
  city: string,
  countryIso: string,
  idNumber: string,
  phone: string,
  contactEmail: string,
  website: string,
  textAlignment: string.isRequired,
  colorTheme: string.isRequired,
  fontFamily: string.isRequired,
  positionInfo: map.isRequired,
  countriesList: listOf(mapContains({
    ISO: string.isRequired,
    name: string.isRequired,
    translations: map.isRequired,
    callingCodes: list.isRequired,
    createdAt: string,
    updatedAt: string,
  })).isRequired,
  activeLanguage: string.isRequired,
  actions: shape({
    getCountriesList: func.isRequired,
  }).isRequired,
};

ContactInfo.defaultProps = {
  title: '',
  address: '',
  city: '',
  countryIso: '',
  idNumber: '',
  phone: '',
  contactEmail: '',
  website: '',
};

export default ContactInfo;
