import { fromJS } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  modalCompanyBackgroundVisible: false,
  backgroundImages: [],
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SHOW_COMPANY_HEADER_BACKGROUND_MODAL:
      return state.set('modalCompanyBackgroundVisible', true);
    case types.HIDE_COMPANY_HEADER_BACKGROUND_MODAL:
      return state.set('modalCompanyBackgroundVisible', false);
    case types.SET_BACKGROUND_IMAGES:
      return state.set('backgroundImages', fromJS(action.payload.images));
    default:
      return state;
  }
};
