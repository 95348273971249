import { parseError } from 'utils/parse-helper';
import { AuthorizedApiService } from './apiService';

export const getProductsService = async (queryString) => AuthorizedApiService.get('/product', { params: queryString })
  .then((res) => res)
  .catch((err) => parseError(err));

export const getMyProductsService = async (queryString, companySlug) => AuthorizedApiService.get(
  `/product/company/slug/${companySlug || localStorage.getItem('userSlug')}`,
  { params: queryString },
)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const createProductDemandService = async (formData) => AuthorizedApiService.post('/product', formData)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const getItemByIdService = async (id) => AuthorizedApiService.get(`/product/${id}`)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const updateProductService = async (productId, formData) => AuthorizedApiService.put(`/product/${productId}`, formData)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const rateProductService = async (id, postData) => AuthorizedApiService.put(`/product/${id}/rate`, postData)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const deleteProductByIdService = async (id) => AuthorizedApiService.delete(`/product/${id}`)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));
