import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { layoutPositions } from 'utils/parse-helper';
import { List as iList, fromJS } from 'immutable';
import { notification } from 'antd';
import { createSelector } from 'reselect';
import EditBar from '../modules/utils/EditBar';
import ProductItem from './ProductItem';
import styles from './whatWeDo.module.scss';

const WhatWeDo = (props) => {
  const {
    productsAndServices,
    saveChange,
    textAlignment,
    colorTheme,
    fontFamily,
    setSectionCompleted,
    actions
  } = props;
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState('edit');
  const [productsData, setProductsData] = useState({
    productsAndServices,
    textAlignment,
  });
  const [changesSaved, setChangesSavedStatus] = useState(false);
  const [unsavedCards, setUnsavedCards] = useState([]);
  const isUnfinished = (product) => (
    (product.get('title')
    || product.get('description'))
    && (!product.get('title')
    || !product.get('description'))
  );
  const checkUnsavedCard = (itemKey, newProducts) => {
    const product = newProducts?.productsAndServices?.filter((item) => item.get('key') === itemKey).first();
    if (product) {
      if (isUnfinished(product)) {
        setUnsavedCards([...unsavedCards.filter((unsavedCard) => unsavedCard !== product.get('key')), product.get('key')]);
      } else {
        setUnsavedCards([...unsavedCards.filter((unsavedCard) => unsavedCard !== product.get('key'))]);
      }
    }
  };
  const sectionDidChange = (change) => {
    actions.setChangesMade(change);
    setChangesSavedStatus(!change);
  };
  const changeProductsData = (item) => {
    setProductsData({
      ...productsData,
      productsAndServices: productsData.productsAndServices.set(
        item.key,
        fromJS({ ...item }),
      ),
    });
    checkUnsavedCard(item.key, {
      ...productsData,
      productsAndServices: productsData.productsAndServices.set(
        item.key,
        fromJS({ ...item }),
      ),
    });
    setChangesSavedStatus(false);
  };
  useEffect(() => {
    if (productsAndServices.size > 0) {
      setProductsData({ productsAndServices, textAlignment });
    }
    const sectionIsFull = productsAndServices.some(
      (product) => product.get('title', '') && product.get('description', ''),
    );
    setSectionCompleted('whatWeDo', sectionIsFull);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAndServices, textAlignment]);
  const saveChanges = () => {
    if (unsavedCards.length > 0) {
      notification.error({
        message: t('notification error'),
        description: t('createCompany cardDataUnsaved'),
      });
    }
    saveChange('whatWeDo', productsData);
    sectionDidChange(false);
  };

  const setTextAlignment = (alignment) => {
    setProductsData({ ...productsData, textAlignment: alignment });
    sectionDidChange(true);
  };
  const filterProductsAndServicesSelector = createSelector(
    () => productsData.productsAndServices,
    (items) => items.filter(
      (item) => item.get('title', '') && item.get('description', ''),
    ),
  );
  const filteredSizeProductsAndServicesSelector = createSelector(
    filterProductsAndServicesSelector,
    (items) => items.size,
  );
  return (
    <div className={classNames('container pb-5', styles.whatWeDoMainContainer)}>
      <h4 style={{ color: colorTheme, fontFamily, textAlign: `${productsData.textAlignment.split('-')[1]}` }}>
        {t('createCompany whatWeDo titleText')}
      </h4>
      <div className={styles.productsAndServicesContainer}>
        {unsavedCards.length > 0 && viewMode === 'edit' && (
          <div className={styles.cardUnsavedAlert}>
            {t('createCompany cardDataUnsaved')}
          </div>
        )}
        {productsData.productsAndServices
          && (viewMode === 'edit'
            ? productsData.productsAndServices
            : filterProductsAndServicesSelector(
              productsData.productsAndServices,
            )
          ).map((item) => (
            <div
              className={classNames(
                styles.productItemContainer,
                'col-12',
                layoutPositions(
                  viewMode === 'edit'
                    ? productsData.productsAndServices.size
                    : filteredSizeProductsAndServicesSelector(
                      productsData.productsAndServices,
                    ),
                ),
                unsavedCards?.includes(item.get('key', '')) && styles.unsavedCard,
              )}
              key={item.get('key', '')}
            >
              <ProductItem
                textAlignment={productsData.textAlignment}
                viewMode={viewMode}
                title={item.get('title', '')}
                description={item.get('description', '')}
                itemKey={item.get('key', '')}
                setProductsData={changeProductsData}
                actions={actions}
              />
            </div>
          ))}
      </div>
      <EditBar
        textAlignment={productsData.textAlignment}
        viewMode={viewMode}
        setTextAlignment={setTextAlignment}
        setViewMode={setViewMode}
        saveChanges={saveChanges}
        changesSaved={changesSaved}
      />
    </div>
  );
};

const { func, node, string, shape } = PropTypes;

WhatWeDo.propTypes = {
  productsAndServices: node,
  saveChange: func.isRequired,
  textAlignment: string.isRequired,
  colorTheme: string.isRequired,
  fontFamily: string.isRequired,
  setSectionCompleted: func.isRequired,
  actions: shape({
    setChangesMade: func.isRequired,
  }).isRequired,
};

WhatWeDo.defaultProps = {
  productsAndServices: iList(),
};

export default WhatWeDo;
