import { Map as IMap, List as IList } from 'immutable';
import i18n from 'i18next';

export const selectIsAuthenticated = (state) => state.auth.get('isAuthenticated', false);
export const selectCompanyDetails = (state) => state.auth.get('companyData', IMap());
export const selectCompanyTheme = (state) => state.auth.getIn(['companyData', 'theme'], IMap());
export const selectActiveCompanyTheme = (state) => state.auth.getIn(['activeCompanyDetails', 'theme'], IMap());
export const selectThemeCompanyLogo = (state) => state.auth.getIn(['companyData', 'theme', 'companyLogo'], '');
export const selectBackgroundImage = (state) => state.auth.getIn(['companyData', 'theme', 'backgroundImage'], IMap());
export const selectActiveCompanyBackgroundImage = (state) => state.auth.getIn(['activeCompanyDetails', 'theme', 'backgroundImage'], IMap());
export const selectBackgroundImageUrl = (state) => state.auth.getIn(['companyData', 'theme', 'backgroundImage', 'url'], '');
export const selectColorTheme = (state) => state.auth.getIn(['companyData', 'theme', 'color'], '');
export const selectFontFamily = (state) => state.auth.getIn(['companyData', 'theme', 'fontFamily', 'key'], '');
export const selectBackgroundImageThumbnailUrl = (state) => state.auth.getIn(
  ['companyData', 'theme', 'backgroundImage', 'thumbnailUrl'],
  '',
);
export const selectAboutCompanyData = (state) => state.auth.getIn(['companyData', 'companyJson', 'aboutCompany'], IMap());
export const selectCompanyId = (state) => state.auth.getIn(['companyData', 'id']);
export const selectCompanySlug = (state) => state.auth.getIn(['companyData', 'slug'], '');

export const selectActiveCompanyDetails = (state) => state.auth.get('activeCompanyDetails', IMap());
export const selectIsToggleDisabled = (state) => state.auth.get('isToggleDisabled', false);
export const selectCompanyName = (state) => state.auth.getIn(['companyData', 'name'], '');
export const selectCountryIso = (state) => state.auth.getIn(['companyData', 'countryIso'], '');
export const selectActiveCompanyBackgroundImageUrl = (state) => state.auth.getIn(
  ['activeCompanyDetails', 'theme', 'backgroundImage', 'url'],
  '',
);
export const selectActiveCompanyBackgroundImageThumbnailUrl = (state) => state.auth.getIn(
  ['activeCompanyDetails', 'theme', 'backgroundImage', 'thumbnailUrl'],
  '',
);
export const selectCompanyProgress = (state) => state.auth.getIn(['companyData', 'companyJson', 'companyProgress'], 0);
export const selectActiveCompanyProgress = (state) => state.auth.getIn(
  ['activeCompanyDetails', 'companyJson', 'companyProgress'],
  0,
);
export const selectActiveCompanyName = (state) => state.auth.get('activeCompanyName', '');
export const selectActiveCompanyId = (state) => state.auth.get('activeCompanyId', null);
export const selectActiveCompanySlug = (state) => state.auth.get('activeCompanySlug', '');

export const selectActiveCompanyLogo = (state) => state.auth.getIn(['activeCompanyDetails', 'theme', 'companyLogo'], '');

export const selectActiveCompanyViewCount = (state) => state.auth.getIn(
  ['activeCompanyDetails', 'viewCount'],
  state.auth.getIn(['companyData', 'viewCount'], ''),
);
export const selectActiveCompanyFontFamily = (state) => state.auth.getIn(['activeCompanyDetails', 'theme', 'fontFamily', 'key'], '');
export const selectActiveCompanyColorTheme = (state) => state.auth.getIn(['activeCompanyDetails', 'theme', 'color'], '');
export const selectCountryIsoCode = (state) => state.auth.getIn(['companyData', 'countryIso'], 'BA');
export const selectPositionInfo = (state) => state.auth.getIn(
  ['companyData', 'companyJson', 'contactInfo', 'positionInfo'],
  IMap(),
);
export const selectActiveCompanyPositionInfo = (state) => state.auth.getIn(
  ['activeCompanyDetails', 'companyJson', 'contactInfo', 'positionInfo'],
  IMap(),
);
export const selectCompanyInterests = (state) => state.auth.getIn(['companyData', 'interests'], IList());
export const selectAdminUserRole = (state) => (state.auth.getIn(['companyData', 'roles'], IList()).first()?.get('name', '') === 'Admin');
export const selectCompanySubcriptionPackage = (state) => state.auth.getIn(['companyData', 'package', 'name'], i18n.t('subPackage freePackage packageType'));

export const selectOpenModal = (state) => (state.auth.get('openModal', false));
export const selectConfirmedNavigation = (state) => (state.auth.get('confirmedNavigation', false));
export const selectChangesMade = (state) => (state.auth.get('changesMade', false));
export const selectNextLocation = (state) => (state.auth.get('nextLocation', {}));
