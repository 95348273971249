import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setConversationId,
  setVisibilityMessagesList,
  setCompanyChat,
  getMessages,
  setTitle,
  archiveConversation,
  setChatRoute
} from '../modules/actions';
import { selectConversationId } from '../modules/selectors';

const mapStateToProps = (state) => ({
  activeConversationId: selectConversationId(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setVisibilityMessagesList,
      setConversationId,
      setCompanyChat,
      getMessages,
      setTitle,
      archiveConversation,
      setChatRoute
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
