import {
  all, call, put, takeLeading,
} from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';
import { getImagesService } from 'services/imagesService';
import { types, setIcons } from './actions';

function * getIcons() {
  const { data, error } = yield call(getImagesService, 'icon');
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setIcons(data));
  }
}

export default function * selectIconSaga() {
  yield all([takeLeading(types.GET_ICONS, getIcons)]);
}
