import React from 'react';

import Anchor from './Anchor';
import Axe from './Axe';
import Backpack from './Backpack';
import Bag from './Bag';
import Carrot from './Carrot';
import Cart from './Cart';
import Compass from './Compass';
import Coupon from './Coupon';
import Cow from './Cow';
import CreditCard from './CreditCard';
import Delivery from './Delivery';
import Discount from './Discount';
import Dollar from './Dollar';
import Emblem from './Emblem';
import Flashlight from './Flashlight';
import Fork from './Fork';
import Gift from './Gift';
import Hills from './Hills';
import Home from './Home';
import Leaves from './Leaves';
import Map from './Map';
import Mug from './Mug';
import Note from './Note';
import PineTree from './PineTree';
import Sheep from './Sheep';
import Shirt from './Shirt';
import Shop from './Shop';
import Shovel from './Shovel';
import Tent from './Tent';
import Tractor from './Tractor';
import Tree from './Tree';
import Wallet from './Wallet';
import West from './West';
import Windmill from './Windmill';

const icons = (color, width, height) => ({
  anchor: <Anchor color={color} width={width} height={height} />,
  axe: <Axe color={color} width={width} height={height} />,
  backpack: <Backpack color={color} width={width} height={height} />,
  bag: <Bag color={color} width={width} height={height} />,
  carrot: <Carrot color={color} width={width} height={height} />,
  cart: <Cart color={color} width={width} height={height} />,
  compass: <Compass color={color} width={width} height={height} />,
  coupon: <Coupon color={color} width={width} height={height} />,
  cow: <Cow color={color} width={width} height={height} />,
  creditCard: <CreditCard color={color} width={width} height={height} />,
  delivery: <Delivery color={color} width={width} height={height} />,
  discount: <Discount color={color} width={width} height={height} />,
  dollar: <Dollar color={color} width={width} height={height} />,
  emblem: <Emblem color={color} width={width} height={height} />,
  flashlight: <Flashlight color={color} width={width} height={height} />,
  fork: <Fork color={color} width={width} height={height} />,
  gift: <Gift color={color} width={width} height={height} />,
  hills: <Hills color={color} width={width} height={height} />,
  home: <Home color={color} width={width} height={height} />,
  leaves: <Leaves color={color} width={width} height={height} />,
  map: <Map color={color} width={width} height={height} />,
  mug: <Mug color={color} width={width} height={height} />,
  note: <Note color={color} width={width} height={height} />,
  pineTree: <PineTree color={color} width={width} height={height} />,
  sheep: <Sheep color={color} width={width} height={height} />,
  shirt: <Shirt color={color} width={width} height={height} />,
  shop: <Shop color={color} width={width} height={height} />,
  shovel: <Shovel color={color} width={width} height={height} />,
  tent: <Tent color={color} width={width} height={height} />,
  tractor: <Tractor color={color} width={width} height={height} />,
  tree: <Tree color={color} width={width} height={height} />,
  wallet: <Wallet color={color} width={width} height={height} />,
  west: <West color={color} width={width} height={height} />,
  windmill: <Windmill color={color} width={width} height={height} />,
});

export default icons;
