export const regexUrlValidation = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
export const CLOUDINARY_UPLOAD_URL = 'https://api.cloudinary.com/v1_1/bizbook/image/upload';

// Color palette
export const width = '109px';
export const height = '66px';
export const border = '1px dashed';
export const defautFont = { key: 'Poppins' };
export const defaultColor = '#37ead9';
export const heightLine = 0.07;
export const redBorderColor = '1px solid red';
export const logoBorder = 'solid 1px rgba(255, 255, 255, 0.4)';

// Edit company page
export const textInputMaxLength = 250;

export const greenLightColor = '#30cdc0';
export const whiteColor = '#FFF';

export const scrollToTop = () => {
  window.scroll({ top: 0, left: 0, behavior: 'smooth' });
};

export const handleCardsLayout = (cardWidth) => {
  if (cardWidth <= 768) {
    return '100%';
  }
  if (cardWidth <= 1024) {
    return '50%';
  }
  return '30%';
};

export const formatDate = (date) => new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
}).format(new Date(date));

export const handleCurrencySelect = (countryIso) => {
  switch (countryIso) {
    case 'BA':
      return 'BAM';
    case 'HR':
      return 'HRK';
    case 'RS':
      return 'RSD';
    default:
      return 'EUR';
  }
};

export const handleCompanyProgress = (numberOfFilledSections) => {
  switch (numberOfFilledSections) {
    case 1:
      return 20;
    case 2:
      return 40;
    case 3:
      return 60;
    case 4:
      return 80;
    case 5:
      return 100;
    default:
      return 0;
  }
};

export const bizbookFacebookLink = 'https://www.facebook.com/bizbook.ba';
export const bizbookLinkedinLink = 'https://www.linkedin.com/company/bizbook/';
export const bizbookInstagramLink = 'https://www.instagram.com/bizbook.ba/';

export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const googleRecaptchaApiKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY;

export const messengerAppId = process.env.REACT_APP_MESSENGER_APP_ID;
export const fullstoryOrgId = process.env.REACT_APP_FULLSTORY_ORG_ID;
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const STRAPI_URL = process.env.REACT_APP_STRAPI_API;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export const adminPanelTableContentLimit = 20;
export const catalogUploadMaxLimit = 10;
export const imageUploadMaxLimit = 10;
export const fileUploadMaxLimit = 10;

export const errorResponseParseHelper = (error) => (Array.isArray(error) ? error.map((item) => `${item.message},  `) : error);

export const handleRedirect = (history, pathname, actions) => {
  if (localStorage.getItem('token')) {
    history.push(pathname);
  } else actions.showModal();
};
