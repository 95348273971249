import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import packages from '../utils/packages';
import PackageItem from './PackageItem';

import styles from '../subscriptionPackages.module.scss';

const Packages = (props) => {
  const { showModal } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.packages}>
      <div className={styles.packagesContainer}>
        {packages.map((packageItem) => (<PackageItem showModal={showModal} item={packageItem} />))}
      </div>
      <div className={styles.finePrint}>
        <span>{`* ${t('subscription finePrint line1')}`}</span>
        <span>{`* ${t('subscription finePrint line2')}`}</span>
      </div>
    </div>
  );
};

Packages.propTypes = {
  showModal: PropTypes.func.isRequired,
};

export default Packages;
