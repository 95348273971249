import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import inputStyle from 'utils/inputStyle.module.scss';
import useForm from 'utils/hooks/useForm';
import InputContainer from 'utils/InputMain';
import ReCAPTCHA from 'react-google-recaptcha';
import { googleRecaptchaApiKey } from 'utils/constants';
import buttonSuccessStyle from '../../Buttons/ButtonSaveChanges/ButtonSaveChangesMedium/buttonSaveChangesMedium.module.scss';
import ButtonCancel from '../../Buttons/ButtonCancel/View';
import validate from './validateForm';
import styles from './contactFormModal.module.scss';

const ContactFormModal = (props) => {
  const { actions, modalVisibility, requestedPackageType } = props;
  const { t } = useTranslation();

  const sendMail = () => {
    actions.hideModal();
    actions.sendSubscriptionPackageRequest({ values, requestedPackageType });
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleChangeReCaptcha,
  } = useForm(sendMail, validate);

  const toggle = () => {
    if (modalVisibility) {
      actions.hideModal();
    } else {
      actions.showModal();
    }
  };

  const handleHideModal = () => {
    actions.hideModal();
  };

  return (
    <Modal
      isOpen={modalVisibility}
      toggle={toggle}
      className={styles.modalContainerStyle}
    >
      <div className={styles.modalWrapper}>
        <div className={classNames(styles.navigationInfo)}>
          <Link to="/">
            <h5>BIZBOOK</h5>
          </Link>
          <RightOutlined className="px-4 pt-1" />
          <h6>{t('subPackage modal navigationtitle')}</h6>
        </div>
        {/* modal content */}
        <div className={styles.modalContent}>
          <div className={styles.requestedPackageTypeText}>
            <h4>{t('subPackage modal interestedInPackage')}</h4>
            <p>{requestedPackageType}</p>
          </div>
          <form
            className={classNames(styles.inputsContainer, 'mt-2 mb-2')}
            onSubmit={handleSubmit}
          >
            <InputContainer
              label={t('contactUs input email label')}
              name="email"
              type="email"
              placeholder={t('contactUs input email placeholder')}
              value={values.email}
              handleChange={handleChange}
              error={errors.email}
            />
            <InputContainer
              label={t('contactUs input phone label')}
              name="phone"
              type="text"
              placeholder={t('contactUs input phone placeholder')}
              value={values.phone}
              handleChange={handleChange}
              error={errors.phone}
            />
            <div
              className={classNames(
                inputStyle.formGroup,
                styles.textareaStyle,
                'col-12 col-md-11 my-3 mx-auto',
                errors.text ? inputStyle.validationError : '',
              )}
            >
              <div>
                <h6 className={classNames(inputStyle.error, styles.error)}>
                  {errors.text}
                </h6>
                <h5 className={classNames(styles.textAreaLabel, 'px-1')}>
                  {t('contactUs input textarea label')}
                </h5>
              </div>
              <textarea
                className={classNames(inputStyle.formControl, 'py-2 my-1')}
                type="text"
                placeholder={t('contactUs input textarea placeholder')}
                name="text"
                value={values.text}
                onChange={handleChange}
                rows={4}
              />
            </div>
            <div className={classNames(styles.reCaptchaContainer, 'col-12 col-md-11 mx-auto p-0')}>
              <h6 className={styles.reCaptchaErrorText}>{errors.reCaptcha}</h6>
              <ReCAPTCHA
                sitekey={googleRecaptchaApiKey}
                onChange={handleChangeReCaptcha}
                className={styles.reCaptcha}
              />
            </div>
            <div
              className={classNames(
                styles.buttonContainer,
                'col-12 mt-5 mt-md-0 ml-auto pr-0',
              )}
            >
              <ButtonCancel handleClick={handleHideModal} />
              <button
                type="submit"
                className={buttonSuccessStyle.buttonSuccessStyle}
              >
                {t('contactUs buttonText')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

const {
  shape, func, bool, string,
} = PropTypes;

ContactFormModal.propTypes = {
  actions: shape({
    showModal: func.isRequired,
    hideModal: func.isRequired,
    sendSubscriptionPackageRequest: func.isRequired,
  }).isRequired,
  modalVisibility: bool.isRequired,
  requestedPackageType: string.isRequired,
};

export default ContactFormModal;
