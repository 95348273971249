const packages = [
  {
    id: 1,
    packageType: 'professional',
    packageIncludes: {
      companyProfile: true,
      businessBase: true,
      bizbookArticles: true,
      sendingNewsletterWithSpecialOffers: true,
      socialMediaPromotion: true,
      businessMeetingSchedule: true,
      bizbookWebShop: true,
    },
  },
  {
    id: 2,
    packageType: 'standard',
    packageIncludes: {
      companyProfile: true,
      businessBase: true,
      bizbookArticles: true,
      sendingNewsletterWithSpecialOffers: true,
      socialMediaPromotion: true,
      businessMeetingSchedule: true,
      bizbookWebShop: false,
    },
  },
  {
    id: 3,
    packageType: 'entrepreneurial',
    packageIncludes: {
      companyProfile: true,
      businessBase: true,
      bizbookArticles: true,
      digitalCatalog: true,
      sendingNewsletterWithSpecialOffers: false,
      socialMediaPromotion: false,
      businessMeetingSchedule: false,
    },
  },
];

export default packages;
