import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List as IList, Map as IMap } from 'immutable';
import { useTranslation } from 'react-i18next';
import { emptySearch, infinityLoader } from 'images';
import classNames from 'classnames';
import ConversationSearch from '../ConversationSearch/View';
import ConversationListItem from '../ConversationListItem';
import Toolbar from '../Toolbar/View';
import './ConversationList.scss';
import ToolbarButton from '../ToolbarButton/View';
import styles from '../../Feed/feed.module.scss';

const ConversationList = (props) => {
  const {
    actions,
    conversations,
    loaderVisible,
    activeCompanies,
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [conversationData, setConversations] = useState(conversations);

  const { t } = useTranslation();

  useEffect(() => {
    actions.activateLoader();
    actions.getConversations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setConversations(conversations);
  }, [conversations]);

  const updateSearch = (e) => {
    const newData = conversations.filter((item) => {
      const textData = e.target.value.toUpperCase();

      const itemData = item.get('productId', '')
        ? item.get('title', '').toUpperCase()
        : item
          .get('participants', '')
          .filter(
            (participant) => participant.get('id', '')
                !== parseInt(localStorage.getItem('userId'), 10),
          )
          .first()?.get('name', '')
          .toUpperCase();

      return itemData.includes(textData);
    });
    setConversations(newData);
    setSearchValue(e.target.value);
    actions.deactivateLoader();
  };

  const getCompanyActiveStatus = (conversation) => {
    const companyId = conversation.get('participants', '')
      .filter(
        (participant) => participant.get('id', '')
                !== parseInt(localStorage.getItem('userId'), 10),
      )
      .first()?.get('id', '');
    return !!activeCompanies.get(`${companyId}`, null);
  };

  return (
    <div className="conversation-list">
      <Toolbar
        title="Messenger"
        rightItems={(
          <ToolbarButton
            icon="ion-ios-add-circle-outline"
            actions={actions}
          />
          )}
      />
      <ConversationSearch
        placeholderText="messenger searchConversations"
        onChangeText={updateSearch}
        value={searchValue}
      />
      {conversationData.size > 0
        && conversationData.map((conversation) => (
          <ConversationListItem
            key={conversation.get('id', null)}
            activeStatus={getCompanyActiveStatus(conversation)}
            name={
              conversation.get('productId', '')
                ? conversation.get('title', '')
                : conversation
                  .get('participants', '')
                  .filter(
                    (participant) => participant.get('id', '')
                        !== parseInt(localStorage.getItem('userId'), 10),
                  )
                  .first()?.get('name', '')
            }
            id={conversation.get('id', null)}
            participants={conversation.get('participants', '')}
            text=""
            conversationItem={conversation}
            showSubtitle={!!conversation.get('productId', null)}
          />
        ))}
      {conversationData.size === 0 && !loaderVisible && (
        <div className="empty-search-container">
          <img src={emptySearch} alt="Empty search" />
          <h6>{t('feed search noResultsTitle')}</h6>
          <p>{t('feed search noResultsTagline')}</p>
        </div>
      )}
      {
        <img
          src={infinityLoader}
          alt="loader"
          className={classNames(
            styles.imageloaderStyle,
            loaderVisible && conversationData.size === 0 ? '' : 'invisible',
          )}
        />
      }
    </div>
  );
};

const {
  shape,
  func,
  bool,
} = PropTypes;
const { list, map } = ImmutablePropTypes;

ConversationList.propTypes = {
  actions: shape({
    getCompanies: func.isRequired,
    getConversations: func.isRequired,
    setVisibilityMessagesList: func.isRequired,
    setVisibilityCompaniesList: func.isRequired,
    activateLoader: func.isRequired,
    deactivateLoader: func.isRequired,
  }).isRequired,
  conversations: list,
  loaderVisible: bool.isRequired,
  activeCompanies: map,
};

ConversationList.defaultProps = {
  conversations: IList(),
  activeCompanies: IMap(),
};

export default ConversationList;
