import React from 'react';
import { string } from 'prop-types';

const MugIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 42.522 54.71"
  >
    <g
      id="prefix__Group_648"
      data-name="Group 648"
      transform="translate(-451.021 -317.469)"
    >
      <g
        id="prefix__Group_184"
        data-name="Group 184"
        transform="translate(34.198 -36.855)"
      >
        <g id="prefix__Group_129" data-name="Group 129">
          <path
            id="prefix__Path_159"
            d="M454.612 396.332h-10.183v-19.467h10.183a4.739 4.739 0 0 1 4.733 4.734v10a4.738 4.738 0 0 1-4.733 4.733zm-6.142-4.041h6.142a.693.693 0 0 0 .692-.692v-10a.694.694 0 0 0-.692-.693h-6.142z"
            className="prefix__cls-1"
            data-name="Path 159"
            style={{ fill: color }}
          />
        </g>
        <g id="prefix__Group_130" data-name="Group 130">
          <path
            id="prefix__Path_160"
            d="M442.642 407.878H424.8a5.884 5.884 0 0 1-5.884-5.884V374.14a2.779 2.779 0 0 1 2.779-2.779h15.551a2.076 2.076 0 0 1 2.076 2.076 2.076 2.076 0 0 1-2.076 2.076h-14.178v26.481a1.732 1.732 0 0 0 1.731 1.732h17.843a1.731 1.731 0 0 0 1.731-1.732v-26.481h-1.389a2.076 2.076 0 0 1-2.076-2.076 2.076 2.076 0 0 1 2.076-2.076h2.762a2.779 2.779 0 0 1 2.779 2.779v27.854a5.882 5.882 0 0 1-5.883 5.884z"
            data-name="Path 160"
            style={{ fill: '#050713' }}
          />
        </g>
        <g id="prefix__Group_131" data-name="Group 131">
          <path
            id="prefix__Path_161"
            d="M443.735 367.241a2 2 0 0 1-2-2v-3.208a2 2 0 0 1 2-2 2 2 0 0 1 2 2v3.208a2 2 0 0 1-2 2z"
            className="prefix__cls-1"
            data-name="Path 161"
            style={{ fill: color }}
          />
        </g>
        <g id="prefix__Group_132" data-name="Group 132">
          <path
            id="prefix__Path_162"
            d="M434.11 367.241a2 2 0 0 1-2-2v-8.917a2 2 0 0 1 2-2 2 2 0 0 1 2 2v8.917a2 2 0 0 1-2 2z"
            className="prefix__cls-1"
            data-name="Path 162"
            style={{ fill: color }}
          />
        </g>
        <g id="prefix__Group_133" data-name="Group 133">
          <path
            id="prefix__Path_163"
            d="M424.151 367.241a2 2 0 0 1-2-2V363a2 2 0 0 1 2-2 2 2 0 0 1 2 2v2.24a2 2 0 0 1-2 2.001z"
            className="prefix__cls-1"
            data-name="Path 163"
            style={{ fill: color }}
          />
        </g>
        <path
          id="prefix__Path_164"
          d="M422.706 409.034h17.843a5.884 5.884 0 0 0 5.884-5.884v-5.662h6.086a4.738 4.738 0 0 0 4.733-4.733v-10a4.739 4.739 0 0 0-4.733-4.734h-6.086V375.3a2.779 2.779 0 0 0-2.779-2.779h-2.762a2.076 2.076 0 0 0 0 4.152h1.389v26.477a1.732 1.732 0 0 1-1.732 1.732h-17.843a1.731 1.731 0 0 1-1.731-1.732v-26.481h14.178a2.076 2.076 0 0 0 0-4.152H419.6a2.779 2.779 0 0 0-2.779 2.779v27.854a5.882 5.882 0 0 0 5.885 5.884zm29.812-26.972a.693.693 0 0 1 .692.693v10a.693.693 0 0 1-.692.692h-6.086v-11.385h6.086z"
          data-name="Path 164"
          style={{ opacity: 0.2, fill: color }}
        />
        <path
          id="prefix__Path_165"
          d="M439.641 363.188v3.212a2 2 0 0 0 4 0v-3.208a2 2 0 0 0-4 0z"
          className="prefix__cls-4"
          data-name="Path 165"
          style={{ opacity: 0.2, fill: color }}
        />
        <path
          id="prefix__Path_166"
          d="M430.016 357.48v8.92a2 2 0 0 0 4 0v-8.92a2 2 0 0 0-4 0z"
          className="prefix__cls-4"
          data-name="Path 166"
          style={{ opacity: 0.2, fill: color }}
        />
        <path
          id="prefix__Path_167"
          d="M420.057 364.157v2.24a2 2 0 0 0 4 0v-2.24a2 2 0 0 0-4 0z"
          className="prefix__cls-4"
          data-name="Path 167"
          style={{ opacity: 0.2, fill: color }}
        />
      </g>
    </g>
  </svg>
);

MugIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

MugIcon.defaultProps = {
  color: '#37ead9',
  width: '42.522',
  height: '54.71',
};

export default MugIcon;
