import React from 'react';
import { string } from 'prop-types';

const NoteIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 47.172 47.46"
  >
    <g
      id="prefix__Group_671"
      data-name="Group 671"
      transform="translate(-114.779 -764.046)"
    >
      <g
        id="prefix__Group_627"
        data-name="Group 627"
        transform="translate(-2141 36)"
      >
        <path
          id="prefix__Rectangle_52"
          d="M0 0H33.289V4H0z"
          className="prefix__cls-1"
          data-name="Rectangle 52"
          transform="translate(2263.515 770.213)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_670"
          d="M457.6 623.213v-4a2.359 2.359 0 0 0 2.357-2.356V613.2H432.7v2.7a7.314 7.314 0 1 1-14.629 0v-35.728a3.129 3.129 0 0 1 3.125-3.126h32.483a3.128 3.128 0 0 1 3.125 3.126l-.079 29.025h5.062a2.168 2.168 0 0 1 2.166 2.166v5.493a6.361 6.361 0 0 1-6.353 6.357zm-35.527-42.167V615.9a3.314 3.314 0 1 0 6.629 0v-4.535a2.169 2.169 0 0 1 2.167-2.166h21.86l.076-28.151z"
          className="prefix__cls-1"
          data-name="Path 670"
          transform="translate(1839 151)"
          style={{ fill: color }}
        />
        <path
          id="prefix__Path_671"
          d="M445.421 591.274H428.8a1.5 1.5 0 0 1-1.5-1.5 1.5 1.5 0 0 1 1.5-1.5h16.62a1.5 1.5 0 0 1 1.5 1.5 1.5 1.5 0 0 1-1.499 1.5z"
          className="prefix__cls-2"
          data-name="Path 671"
          transform="translate(1839 151)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_672"
          d="M445.421 597.6H428.8a1.5 1.5 0 0 1-1.5-1.5 1.5 1.5 0 0 1 1.5-1.5h16.62a1.5 1.5 0 0 1 1.5 1.5 1.5 1.5 0 0 1-1.499 1.5z"
          className="prefix__cls-2"
          data-name="Path 672"
          transform="translate(1839 151)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_673"
          d="M438.421 603.92H428.8a1.5 1.5 0 0 1-1.5-1.5 1.5 1.5 0 0 1 1.5-1.5h9.62a1.5 1.5 0 0 1 1.5 1.5 1.5 1.5 0 0 1-1.499 1.5z"
          className="prefix__cls-2"
          data-name="Path 673"
          transform="translate(1839 151)"
          style={{ fill: '#050713' }}
        />
        <path
          id="prefix__Path_674"
          d="M460.495 610.489h-5.061l.079-29.025a3.129 3.129 0 0 0-3.126-3.126H419.9a3.128 3.128 0 0 0-3.125 3.126v35.727a7.322 7.322 0 0 0 6.445 7.258v.057h33.289v-.01a6.359 6.359 0 0 0 6.149-6.346v-5.493a2.169 2.169 0 0 0-2.163-2.168zm-39.717 6.7v-34.851h30.732l-.076 28.151h-21.86a2.169 2.169 0 0 0-2.167 2.166v4.535a3.315 3.315 0 0 1-6.629 0zm37.884.958a2.358 2.358 0 0 1-2.362 2.353h-25.7a7.263 7.263 0 0 0 .8-3.314v-2.7h27.255v3.659z"
          className="prefix__cls-3"
          data-name="Path 674"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
        <path
          id="prefix__Path_675"
          d="M427.509 592.566h16.62a1.5 1.5 0 0 0 0-3h-16.62a1.5 1.5 0 0 0 0 3z"
          className="prefix__cls-3"
          data-name="Path 675"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
        <path
          id="prefix__Path_676"
          d="M427.509 598.889h16.62a1.5 1.5 0 0 0 0-3h-16.62a1.5 1.5 0 0 0 0 3z"
          className="prefix__cls-3"
          data-name="Path 676"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
        <path
          id="prefix__Path_677"
          d="M427.509 605.212h9.62a1.5 1.5 0 0 0 0-3h-9.62a1.5 1.5 0 0 0 0 3z"
          className="prefix__cls-3"
          data-name="Path 677"
          transform="translate(1839 151)"
          style={{ fill: color, opacity: 0.2 }}
        />
      </g>
    </g>
  </svg>
);

NoteIcon.propTypes = {
  color: string,
  width: string,
  height: string,
};

NoteIcon.defaultProps = {
  color: '#37ead9',
  width: '47.172',
  height: '47.46',
};

export default NoteIcon;
