import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { facebook, linkedIn, instagram } from 'images/socialIcons';
import {
  bizbookLogo,
  confederaziunSvizra,
  marketMakersLogo,
  bizbookSupport,
} from 'images';
import classNames from 'classnames';
import {
  bizbookFacebookLink,
  bizbookLinkedinLink,
  bizbookInstagramLink,
} from 'utils/constants';
import {
  feedUrl,
  tutorialUrl,
  createProductUrl,
  loginUrl,
  loginAfterConfirmationUrl,
  resendEmailActivationUrl,
  registerConfirmedUrl,
  companyUrls,
} from 'utils/pathsHelper';
import styles from './footer.module.scss';

const footerDisabledUrls = [
  feedUrl,
  tutorialUrl,
  createProductUrl,
  loginUrl,
  loginAfterConfirmationUrl,
  resendEmailActivationUrl,
  registerConfirmedUrl,
];

const Footer = (props) => {
  const {
    location: { pathname },
  } = props;
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        styles.footer,
        pathname === '/tutorial' ? 'pt-md-3' : '',
        pathname.includes(companyUrls.company) ? 'd-none' : '',
        pathname.includes(companyUrls.companyAbout) ? 'd-none' : '',
        /^\/\d\d/.test(pathname) && 'd-none',
      )}
    >
      <div className="container">
        <div
          className={classNames(
            styles.footerMain,
            footerDisabledUrls.includes(pathname) && 'mb-0 mb-md-4',
            pathname.includes('/view') && 'mb-0 mb-md-4',
            pathname.includes('/edit') && 'mb-0 mb-md-4',
          )}
        >
          <Link to="/" className={styles.bizbookLogo}>
            <img
              src={bizbookLogo}
              alt="bizbook"
              className={classNames(styles.bizbookLogoImg, 'mr-2')}
            />
            <p className={styles.bizbookLogoTitle}>bizbook</p>
          </Link>
          <div className={styles.socialLinksContainer}>
            <h5 className={styles.followUsText}>{t('footer followUsText')}</h5>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={bizbookLinkedinLink}
            >
              <img src={linkedIn} alt="linkedIn" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={bizbookInstagramLink}
            >
              <img src={instagram} alt="instagram" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={bizbookFacebookLink}
            >
              <img src={facebook} alt="facebook" />
            </a>
          </div>
        </div>
        <div
          className={classNames(
            styles.marketMakersContainer,
            'py-5',
            footerDisabledUrls.includes(pathname) && 'd-none',
            pathname.includes('/view') && 'd-none',
            pathname.includes('/edit') && 'd-none',
            pathname.includes(companyUrls.products) && 'd-none',
          )}
        >
          <img
            src={confederaziunSvizra}
            alt="Swiss Confederation"
            className={styles.firstImage}
          />
          <img
            src={marketMakersLogo}
            alt="MarketMakers logo"
            className={styles.secondImage}
          />
          <img
            src={bizbookSupport}
            alt="bizbook support by Swiss Confederation Government"
            className={styles.thirdImage}
          />
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Footer);
