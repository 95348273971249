import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';

import { types, setBlogArticles, setForYouArticles } from './actions';
import { getArticlesService, getForYouArticlesService, increaseViewCountService } from '../../../services/blogService';

export default function * blogArticlesSaga() {
  yield all([
    takeLatest(types.GET_BLOG_ARTICLES, handleGetBlogArticles),
    takeLatest(types.GET_FOR_YOU_ARTICLES, handleGetForYouArticles),
    takeLatest(types.INCREASE_ARTICLE_VIEW_COUNT, handleIncreaseArticleViewCount),
  ]);
}

function * handleGetBlogArticles() {
  const { data, error } = yield call(getArticlesService);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setBlogArticles({ articles: data }));
  }
}

function * handleGetForYouArticles(action) {
  const { category } = action.payload;
  const { data, error } = yield call(getForYouArticlesService, category);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setForYouArticles({ articles: data }));
  }
}

function * handleIncreaseArticleViewCount(action) {
  const { article } = action.payload;
  const updatedData = {
    viewCount: article.get('viewCount') + 1,
  };
  return yield call(increaseViewCountService, article.get('id'), updatedData);
}
