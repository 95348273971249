import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectActiveCompanyColorTheme, selectActiveCompanyFontFamily } from 'selectors/auth';
import { setChangesMade } from 'actions/auth';
import { showModal } from '../AddIcon/modules/actions';
import { selectModalVisibilityProductsAndServices } from '../AddIcon/modules/selectors';

const mapStateToProps = (state) => ({
  colorTheme: selectActiveCompanyColorTheme(state),
  modalVisibilityProductsAndServices: selectModalVisibilityProductsAndServices(
    state,
  ),
  fontFamily: selectActiveCompanyFontFamily(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showModal,
      setChangesMade,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
