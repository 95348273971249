import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendContactUsMail } from 'components/SubscriptionPackages/modules/actions';
import { selectContactUsFormStateChange } from 'components/SubscriptionPackages/modules/selectors';

const mapStateToProps = (state) => ({
  formDataStatus: selectContactUsFormStateChange(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      sendContactUsMail,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
