import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'react-immutable-proptypes';
import { bool } from 'prop-types';
import { Map as IMap } from 'immutable';
import moment from 'moment';
import classnames from 'classnames';

import styles from './SubscriptionEndingBanner.module.scss';

const SubscriptionEndingBanner = (props) => {
  const { company, isAdmin } = props;
  const { t } = useTranslation();
  const [isEndingSoon, setIsEndingSoon] = useState(false);
  const [hasEnded, setHasEnded] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    if (!company) {
      return;
    }
    if (company.size > 0) {
      setSubscriptionData({
        endDate: company.get('subscriptionEndDate')
          ? moment().diff(company.get('subscriptionEndDate'), 'days')
          : null,
        paidStatus: company.get('paidStatus', 'Unpaid'),
        packageName: company.getIn(['package', 'name'], ''),
      });
    }
  }, [company]);
  useEffect(() => {
    if (
      !isAdmin
      && subscriptionData
      && subscriptionData?.paidStatus !== 'Trial'
    ) {
      if (subscriptionData.endDate >= -30) {
        setIsEndingSoon(true);
      }
      if (subscriptionData.endDate > 0) {
        setHasEnded(true);
      }
      if (subscriptionData.paidStatus === 'Unpaid') {
        setHasSubscription(false);
      }
    }
  }, [subscriptionData, isAdmin, company]);

  if (!isEndingSoon || !company || !subscriptionData.endDate) {
    return null;
  }

  return (
    <div className={styles.subscriptionEndingBanner}>
      <div className={classnames('container', styles.bannerContainer)}>
        <div className={styles.bannerContent}>
          {
            hasSubscription
              ? (
                <p>
                  {`${t('subscriptionEndingBanner yourSubscription')}
                   ${subscriptionData.packageName}
                    ${t(`subscriptionEndingBanner ${hasEnded ? 'hasEnded' : 'isEnding'}`)}
                     ${subscriptionData.endDate.toString().replace('-', '')}
                      ${t('subscriptionEndingBanner days')}`}
                </p>
              )
              : <p>{t('subscriptionEndingBanner noActiveSubscription')}</p>
          }
          <p>{`${t('subscriptionEndingBanner pleaseContact')} ${t(`subscriptionEndingBanner ${hasSubscription ? 'renewal' : 'activation'}`)}`}</p>
        </div>
      </div>
    </div>
  );
};

SubscriptionEndingBanner.propTypes = {
  company: map,
  isAdmin: bool.isRequired,
};

SubscriptionEndingBanner.defaultProps = { company: IMap() };

export default SubscriptionEndingBanner;
