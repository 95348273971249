/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { composeEditCompanyUrl } from 'utils/pathsHelper';
import UserMenu from '../UserMenu';
import styles from './loggedUserInfo.module.scss';
import history from '../../../../history';

function forwardTo(location) {
  history.push(location);
}

const LoggedUserInfo = (props) => {
  const { companyData, hideBorder, actions } = props;
  const getAcronym = (name) => {
    if (name) {
      const matches = name.match(/\b(\w)/g);
      return matches.join('');
    }
    return null;
  };

  const loadCompanyDetails = () => {
    actions.loadCompanyDetails(localStorage.getItem('userId'));
    forwardTo(composeEditCompanyUrl(companyData.get('slug', '')));
  };

  return (
    <div>
      <div
        className={classNames(
          styles.userInfoContainer,
          hideBorder ? 'border-0' : '',
        )}
      >
        <div className={styles.avatarContainer} onClick={loadCompanyDetails}>
          <Avatar size="large" className={styles.avatar}>
            <div className={styles.avatarName}>
              {getAcronym(companyData.get('name', ''))}
            </div>
          </Avatar>
        </div>

        <div className={styles.userData}>
          <div className={styles.userName}>{companyData.get('name', '')}</div>
          <div className={styles.emailUserInfo}>
            {companyData.get('email', '')}
          </div>
        </div>
        <div className={styles.userMenu}>
          <UserMenu />
        </div>
      </div>
    </div>
  );
};

const { shape, func } = PropTypes;

LoggedUserInfo.propTypes = {
  companyData: shape({}),
  hideBorder: PropTypes.bool,
  actions: shape({
    loadCompanyDetails: func.isRequired,
  }).isRequired,
};

LoggedUserInfo.defaultProps = {
  companyData: {},
  hideBorder: false,
};
export default LoggedUserInfo;
