import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Button, Table, DatePicker } from 'antd';
import styles from '../adminPanel.module.scss';
import { columns } from './constants'
import { CSVLink } from 'react-csv';
const { RangePicker } = DatePicker;

const Statistics = (props) => {
  const {
    actions,
    adminStatistic
  } = props;

  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")

  useEffect(() => {
    actions.getAdminStatistic({fromDate:"",toDate:""});
  },[]);

  const onDateChage = (dates, dateStrings) => {
    const [fromDatePicker, toDatePicker] = dateStrings
    setFromDate(fromDatePicker)
    setToDate(toDatePicker)
  }

  const handleClick = () => {
    actions.getAdminStatistic({ fromDate, toDate })
  }

  const stats = adminStatistic.toJS();
  const statsExists = Object.keys(stats).length > 0;

  const csvData = [
    [`Statistic for period ${fromDate || "1970-01-02"} - ${toDate || (new Date ()).toISOString().slice(0, 10)}`],
    ["", ...columns.map(column => column.title)],
    ["", ...Object.values(stats)],
  ];

  return (
    <>
      <div className={styles.statisticContainer}>
        <RangePicker className="mb-4 mr-2" onChange={onDateChage} />
        <Button className="mb-4" onClick={handleClick} >Generate statistics</Button>
      </div>
      {statsExists && (
        <div>
          <Table
            columns={columns}
            dataSource={[stats]}
            scroll={{ x: "max-content" }}
            pagination={false} />

          <CSVLink className="d-block my-2" filename={"statistic.csv"} data={csvData}>Export statistic to csv?</CSVLink>
        </div>)}
    </>
  );
};

const {
  func, shape,
} = PropTypes;
const { map } = ImmutablePropTypes;

Statistics.propTypes = {
  adminStatistic: map.isRequired,
  actions: shape({
    getAdminStatistic: func.isRequired,
    setAdminStatistic: func.isRequired,
  }).isRequired,
};

export default Statistics;
