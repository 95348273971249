import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectModalVisibility, selectRequestedPackageType } from '../modules/selectors';
import {
  hideModal,
  sendSubscriptionPackageRequest,
  showModal,
} from '../modules/actions';

const mapStateToProps = (state) => ({
  modalVisibility: selectModalVisibility(state),
  requestedPackageType: selectRequestedPackageType(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      hideModal,
      showModal,
      sendSubscriptionPackageRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
