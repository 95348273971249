import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCompanyDetailsById } from 'actions/auth';
import { showModal } from 'components/Login/modules/actions';
import {
  selectIsAuthenticated,
  selectActiveCompanyDetails,
  selectActiveCompanyProgress,
  selectAdminUserRole,
} from 'selectors/auth';
import { getCountriesList } from '../Register/modules/actions';
import { selectLoaderVisible } from '../Feed/modules/selectors';
import { activateLoader, deactivateLoader } from '../Feed/modules/actions';

const mapStateToProps = (state) => ({
  isLoading: selectLoaderVisible(state),
  isAuthenticated: selectIsAuthenticated(state),
  companyData: selectActiveCompanyDetails(state),
  companyProgress: selectActiveCompanyProgress(state),
  adminRole: selectAdminUserRole(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      activateLoader,
      deactivateLoader,
      getCountriesList,
      getCompanyDetailsById,
      showModal,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
