import i18n from 'i18next';

const privacyPolicies = () => [
  {
    id: 1,
    title: i18n.t('privacyPolicies gatheringInformation title'),
    text: i18n.t('privacyPolicies gatheringInformation text'),
  },
  {
    id: 2,
    title: i18n.t('privacyPolicies usingInformationsFor title'),
    text: i18n.t('privacyPolicies usingInformationsFor text'),
  },
  {
    id: 3,
    title: i18n.t('privacyPolicies chosendPublicInformations title'),
    text: i18n.t('privacyPolicies chosendPublicInformations text'),
  },
  {
    id: 4,
    title: i18n.t('privacyPolicies importanceOfRegisteredCompany title'),
    text: i18n.t('privacyPolicies importanceOfRegisteredCompany text'),
  },
  {
    id: 5,
    title: i18n.t('privacyPolicies importanceOfCompanyRepresentative title'),
    text: i18n.t('privacyPolicies importanceOfCompanyRepresentative text'),
  },
  {
    id: 6,
    title: i18n.t('privacyPolicies shareInformationsWithThirdParty title'),
    text: i18n.t('privacyPolicies shareInformationsWithThirdParty text'),
  },
  {
    id: 7,
    title: i18n.t('privacyPolicies termsOfUse title'),
    text: i18n.t('privacyPolicies termsOfUse text'),
  },
  {
    id: 8,
    title: i18n.t('privacyPolicies yourConsent title'),
    text: i18n.t('privacyPolicies yourConsent text'),
  },
  {
    id: 9,
    title: i18n.t('privacyPolicies privacyPolicyChanges title'),
    text: i18n.t('privacyPolicies privacyPolicyChanges text'),
  },
  {
    id: 10,
    title: i18n.t('privacyPolicies contactUs title'),
    text: i18n.t('privacyPolicies contactUs text'),
  },
];

export default privacyPolicies;
