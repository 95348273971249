import i18n from 'i18next';

export default function validate(values) {
  const errors = {};
  if (!values.password) {
    errors.password = i18n.t('inputValidationError password empty');
  } else if (values.password.length < 8) {
    errors.password = i18n.t('inputValidationError password invalid');
  }

  if (!values.repeatPassword) {
    errors.repeatPassword = i18n.t('inputValidationError repeatPassword empty');
  } else if (values.repeatPassword !== values.password) {
    errors.repeatPassword = i18n.t('inputValidationError repeatPassword notEqual');
  }

  return errors;
}
