import { adminPanelTableContentLimit as limit } from 'utils/constants';

const initialState = {
  filterQueryObject: { page: 1, type: 'companies', limit, order:"createdAt" },
  loading: false,
};

const setActiveTab = (tab, order) => ({
  type: 'SET_ACTIVE_TAB',
  payload: { tab, order },
});

const updatePage = (page) => ({
  type: 'UPDATE_CURRENT_PAGE',
  payload: { page },
});

const setLoadingStatus = (loading) => ({
  type: 'UPDATE_LOADING_STATUS',
  payload: { loading },
});

const filterData = (fieldName, value) => ({
  type: 'FILTER_DATA',
  payload: {
    fieldName,
    value,
  },
});

const resetFilters = () => ({
  type: 'RESET_FILTERS',
  payload: {},
});

function reducer(state, action) {
  switch (action.type) {
    case 'SET_ACTIVE_TAB':
      return {
        ...state,
        filterQueryObject: {
          ...state.filterQueryObject,
          type: action.payload.tab,
          page: 1,
          order: action.payload.order
        },
      };
    case 'UPDATE_CURRENT_PAGE':
      return {
        ...state,
        filterQueryObject: {
          ...state.filterQueryObject,
          page: action.payload.page,
        },
      };
    case 'UPDATE_LOADING_STATUS':
      return {
        ...state,
        loading: action.payload.loading,
      };
    case 'FILTER_DATA':
      return {
        ...state,
        filterQueryObject: {
          ...state.filterQueryObject,
          page: 1,
          value: action.payload.value,
          property: action.payload.fieldName,
        },
      };
    case 'RESET_FILTERS':
      return {
        ...state,
        filterQueryObject: {
          type: state.filterQueryObject.type,
          limit,
          page: 1,
          order: state.filterQueryObject.order
        },
      };
    default:
      return state;
  }
}

export {
  reducer,
  initialState,
  setActiveTab,
  setLoadingStatus,
  updatePage,
  filterData,
  resetFilters,
};
