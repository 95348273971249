import { fromJS, List as IList } from 'immutable';

import { types } from './actions';

const defaultState = fromJS({
  articles: [],
  selectedCategory: 'all',
  activeArticle: '',
  forYouArticles: [],
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_BLOG_ARTICLES:
      return handleSetBlogArticles(state, action);
    case types.SET_SELECTED_CATEGORY:
      return handleSetSelectedCategory(state, action);
    case types.SET_BLOG_ARTICLE_SLUG:
      return handleSetBlogArticleSlug(state, action);
    case types.SET_FOR_YOU_ARTICLES:
      return handleSetForYouArticles(state, action);
    default:
      return state;
  }
};

function handleSetBlogArticles(state, action) {
  const { articles } = action.payload;
  const newArticles = articles ? fromJS(articles) : IList();
  return state.set('articles', fromJS(newArticles));
}

function handleSetSelectedCategory(state, action) {
  const { category } = action.payload;
  return state.set('selectedCategory', category);
}

function handleSetBlogArticleSlug(state, action) {
  const { slug } = action.payload;
  return state.set('activeArticle', slug);
}

function handleSetForYouArticles(state, action) {
  const { articles } = action.payload;
  const newArticles = articles ? fromJS(articles) : IList();
  return state.set('forYouArticles', newArticles);
}
