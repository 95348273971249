import { parseError } from 'utils/parse-helper';
import { AuthorizedApiService } from './apiService';

export const getCatalogsService = async (slug) => AuthorizedApiService.get(`/catalogue/company/slug/${slug}`)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));

export const addCatalogService = async (postData) => {
  const { companySlug, ...cleanData } = postData;
  return AuthorizedApiService.post('/catalogue', cleanData)
    .then((res) => res)
    .catch((err) => ({
      data: null,
      error: parseError(err),
    }));
};

export const deleteCatalogService = async (id) => AuthorizedApiService.delete(`/catalogue/${id}`)
  .then((res) => res)
  .catch((err) => ({
    data: null,
    error: parseError(err),
  }));
