import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import StackGrid from 'react-stack-grid';
import BottomScrollListener from 'react-bottom-scroll-listener';
import { jumpToTop, infinityLoader } from 'images';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import {
  scrollToTop,
  handleCardsLayout,
} from 'utils/constants';
import {
  createDemandUrl,
  createProductUrl,
} from 'utils/pathsHelper';
import buttonStyles from 'utils/buttonStyle.module.scss';
import FeedCard from './FeedCard/FeedCard';
import styles from './myFeed.module.scss';

const MyFeed = (props) => {
  const {
    actions,
    userFeedContent,
    loaderVisible,
    companyName,
    companyLogo,
    companyProgress,
    adminRole,
    productDemandType,
  } = props;
  const { t } = useTranslation();
  const params = useParams();
  const { id } = params;
  const [jumpTopTopVisible, setJumpToTopVisible] = useState(false);
  const [queryData, setQueryData] = useState({
    page: 1,
    limit: 9,
    type: productDemandType,
  });
  const [cardWidth, setCardWidth] = useState(
    handleCardsLayout(window.innerWidth),
  );
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    actions.clearActiveItem();
    if (!companyName) {
      actions.getCompanyDetailsById(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (queryData.page === 1) {
      actions.getUserFeedContent(queryData, id);
    } else {
      actions.loadMoreUserFeedContent(queryData, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  const handleIncreasePage = () => {
    setQueryData({ ...queryData, page: queryData.page + 1 });
  };
  const debouncedHandleIncreasePage = debounce(handleIncreasePage, 1000);

  const increaseFeedLimit = () => {
    actions.activateLoader();
    debouncedHandleIncreasePage();
    if (!jumpTopTopVisible) setJumpToTopVisible(true);
  };

  useEffect(() => {
    const updateWidth = () => {
      setCardWidth(handleCardsLayout(window.innerWidth));
    };
    const debounceUpdateWidth = debounce(updateWidth, 200);
    window.addEventListener('resize', debounceUpdateWidth);
    return () => window.removeEventListener('resize', debounceUpdateWidth);
  }, []);

  useEffect(() => {
    if (id === localStorage.getItem('userSlug') || adminRole) {
      setIsAuthorized(true);
    }
  }, [id, adminRole]);

  const showProducts = () => {
    setQueryData({ ...queryData, type: 'product', page: 1 });
    actions.setProductDemandType('product');
  };

  const showDemands = () => {
    setQueryData({ ...queryData, type: 'demand', page: 1 });
    actions.setProductDemandType('demand');
  };

  return (
    <div className="container">
      <div className={styles.myFeedControlContainer}>
        <div className={styles.viewTypeContainer}>
          <button
            type="button"
            className={classNames(
              'mr-3',
              queryData.type === 'demand' && styles.activeButton,
            )}
            onClick={showProducts}
          >
            {t('myFeed viewType products')}
          </button>
          <button
            type="button"
            className={classNames(
              queryData.type === 'product' && styles.activeButton,
            )}
            onClick={showDemands}
          >
            {t('myFeed viewType demand')}
          </button>
        </div>
        {isAuthorized && (
          <Tooltip
            placement="bottom"
            title={
              companyProgress < 80
                ? t('myFeed createProduct/demand tooltip')
                : ''
            }
          >
            <div className={styles.buttonContainer}>
              <Link
                to={`${createProductUrl}/${id}`}
                className={classNames(
                  'mr-1',
                  companyProgress < 80 && 'btn disabled',
                )}
              >
                <button
                  type="submit"
                  className={classNames(
                    buttonStyles.buttonSuccessStyle,
                    styles.buttonSuccessStyle,
                    'px-3 px-md-4',
                  )}
                >
                  {cardWidth === '100%'
                    ? t('myFeed mobile button newProduct')
                    : t('myFeed button newProduct')}

                  {cardWidth === '100%' && (
                    <PlusOutlined className={styles.plusIconStyle} />
                  )}
                </button>
              </Link>
              <Link
                to={`${createDemandUrl}/${id}`}
                className={classNames(
                  'ml-1',
                  companyProgress < 80 && 'btn disabled',
                )}
              >
                <button
                  type="submit"
                  className={classNames(
                    buttonStyles.buttonSuccessStyle,
                    styles.buttonSuccessStyle,
                    'px-3 px-md-4',
                  )}
                >
                  {cardWidth === '100%'
                    ? t('myFeed mobile button newDemand')
                    : t('myFeed button newDemand')}
                  {cardWidth === '100%' && (
                    <PlusOutlined className={styles.plusIconStyle} />
                  )}
                </button>
              </Link>
            </div>
          </Tooltip>
        )}
      </div>
      <h2 className={styles.mainTitleStyle}>{queryData.type === 'product' ? t('myFeed mainTitle myProducts') : t('myFeed mainTitle myDemands')}</h2>
      <div className={classNames(styles.myFeedContent)}>
        {userFeedContent.size > 0 && (
          <StackGrid
            columnWidth={cardWidth}
            className="my-5"
            gutterWidth={23}
            gutterHeight={35}
            duration={550}
          >
            {userFeedContent?.map((item) => (
              <FeedCard
                item={item}
                key={item.get('id')}
                companyName={companyName}
                companyLogo={companyLogo}
                isAuthorized={isAuthorized}
              />
            ))}
          </StackGrid>
        )}

        {userFeedContent.size === 0 && (
          <div className={styles.noProductsContainer}>
            <h6>
              {queryData.type === 'product'
                ? t('myFeed noResults product')
                : t('myFeed noResults demand')}
            </h6>
          </div>
        )}
        {
          <img
            src={infinityLoader}
            alt="loader"
            className={classNames(
              styles.imageloaderStyle,
              loaderVisible && userFeedContent.size > 0 ? '' : 'invisible',
            )}
          />
        }
        {/* jump to to button */}
        {jumpTopTopVisible && (
          <img
            src={jumpToTop}
            alt="jump to top"
            className={styles.jumpTopTop}
            role="presentation"
            onClick={scrollToTop}
          />
        )}
        {!loaderVisible && (
          <BottomScrollListener onBottom={increaseFeedLimit} />
        )}
      </div>
    </div>
  );
};

const {
  shape, func, bool, string, number,
} = PropTypes;
const { list } = ImmutablePropTypes;

MyFeed.propTypes = {
  actions: shape({
    getUserFeedContent: func.isRequired,
    loadMoreUserFeedContent: func.isRequired,
    clearActiveItem: func.isRequired,
    getCompanyDetailsById: func.isRequired,
    setProductDemandType: func.isRequired,
  }).isRequired,
  userFeedContent: list.isRequired,
  loaderVisible: bool.isRequired,
  companyName: string.isRequired,
  companyLogo: string.isRequired,
  companyProgress: number.isRequired,
  adminRole: bool.isRequired,
  productDemandType: string.isRequired,
};

export default MyFeed;
