import {
  all, call, takeLatest, put,
} from 'redux-saga/effects';
import i18n from 'i18next';
import { notification } from 'antd';
import {
  getProductsService,
  getItemByIdService,
  getMyProductsService,
  rateProductService,
} from 'services/productsService';
import { getCompaniesService } from 'services/companyService';
import { getAllItemsService } from 'services/searchService';
import {
  getCurrenciesRatesByCurrencyService,
  getSuggestedTagsService,
} from 'services/utilsService';

import {
  types,
  setFeedContent,
  setMoreLoadedContent,
  setSuggestedTags,
  setUserFeedContent,
  updateUserFeedContent,
  deactivateLoader,
  setItemById,
  setRatesByCurrency,
} from './actions';

function * getFeedContent(action) {
  const { queryObject, adminPanelFeed } = action.payload;
  const data = yield getContent(queryObject, adminPanelFeed);
  yield put(setFeedContent(data));
}

function * loadMoreContent(action) {
  const { queryObject } = action.payload;
  const data = yield getContent(queryObject);
  if (data.data.length > 0) {
    yield put(setMoreLoadedContent(data));
  } else {
    yield put(deactivateLoader());
  }
}

// eslint-disable-next-line consistent-return
export function * getContent(queryObject, adminPanelFeed = false) {
  if (queryObject?.type === 'companies') {
    const companyQueryObject = {
      ...queryObject,
      value: queryObject.name,
      property: 'name',
    };
    const { data, error } = yield call(getCompaniesService,
      adminPanelFeed ? queryObject : companyQueryObject);
    if (error) {
      notification.error({
        message: i18n.t('notification error'),
        description: error,
      });
    } else {
      return data;
    }
  } else if (!queryObject?.type) {
    const { data, error } = yield call(getAllItemsService, queryObject);
    if (error) {
      notification.error({
        message: i18n.t('notification error'),
        description: error,
      });
    } else {
      return data;
    }
  } else {
    if (queryObject?.isFeatured) {
      delete queryObject?.type;
    }
    const { data, error } = yield call(getProductsService, queryObject);
    if (error) {
      notification.error({
        message: i18n.t('notification error'),
        description: error,
      });
    } else {
      return data;
    }
  }
}

function * getSuggestedTags(action) {
  const { queryObject } = action.payload;
  const { data, error } = yield call(getSuggestedTagsService, queryObject);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setSuggestedTags(data));
  }
}

function * getUserFeedContent(action) {
  const { queryObject, companyId } = action.payload;
  const data = yield getUserContent(queryObject, companyId);
  if (data) {
    yield put(setUserFeedContent(data));
  }
}

function * loadMoreUserFeedContent(action) {
  const { queryObject, id } = action.payload;
  const data = yield getUserContent(queryObject, id);
  if (data) {
    yield put(updateUserFeedContent(data));
  } else {
    yield put(deactivateLoader());
  }
}

function * getUserContent(queryObject, companyId) {
  const { data, error } = yield call(getMyProductsService, queryObject, companyId);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    return data;
  }
}

function * getItemById(action) {
  const { id } = action.payload;
  const { data, error } = yield call(getItemByIdService, id);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setItemById(data));
  }
}

function * getRatesByCurrency(action) {
  const { queryObject } = action.payload;
  const { data, error } = yield call(getCurrenciesRatesByCurrencyService, queryObject);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setRatesByCurrency(data));
  }
}

function * rateProductItem(action) {
  const { companyId, rating } = action.payload;
  const { error } = yield call(rateProductService, companyId, rating);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
      description: i18n.t('rateProduct ratingSuccessMessage'),
    });
  }
}

export default function * feedContentSaga() {
  yield all([
    takeLatest(types.GET_FEED_CONTENT, getFeedContent),
    takeLatest(types.LOAD_MORE_FEED_CONTENT, loadMoreContent),
    takeLatest(types.GET_SUGGESTED_TAGS, getSuggestedTags),
    takeLatest(types.GET_USER_FEED_CONTENT, getUserFeedContent),
    takeLatest(types.LOAD_MORE_USER_FEED_CONTENT, loadMoreUserFeedContent),
    takeLatest(types.GET_ITEM_BY_ID, getItemById),
    takeLatest(types.GET_RATES_BY_CURRENCY, getRatesByCurrency),
    takeLatest(types.RATE_PRODUCT, rateProductItem),
  ]);
}
