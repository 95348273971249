import React from 'react';
import { useTranslation } from 'react-i18next';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import RatingSlider from 'utils/RatingSlider/RatingSlider';
import styles from './howOthersRateUs.module.scss';

const HowOthersRateUs = (props) => {
  const { itemDetails } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.howOthersRateUsMainContainer}>
      <h3>{t('cardDetailedView howOtherRateThisProduct')}</h3>
      <div className={styles.howOthersRateUs}>
        <div className={classNames('col-12 col-md-4', styles.rateContainer)}>
          <h4>{t('cardDetailedView othersRateUs description')}</h4>
          <RatingSlider
            percentage={itemDetails.get('descriptionRating', 0) * 20}
          />
          <p>{t('cardDetailedView othersRateUs description tagline')}</p>
        </div>
        <div className={classNames('col-12 col-md-4', styles.rateContainer)}>
          <h4>{t('cardDetailedView othersRateUs quality')}</h4>
          <RatingSlider percentage={itemDetails.get('priceRating', 0) * 20} />
          <p>{t('cardDetailedView othersRateUs quality tagline')}</p>
        </div>
        <div className={classNames('col-12 col-md-4', styles.rateContainer)}>
          <h4>{t('cardDetailedView othersRateUs communication')}</h4>
          <RatingSlider
            percentage={itemDetails.get('communicationRating', 0) * 20}
          />
          <p>{t('cardDetailedView othersRateUs communication tagline')}</p>
        </div>
      </div>
    </div>
  );
};

const { map } = ImmutablePropTypes;

HowOthersRateUs.propTypes = {
  itemDetails: map.isRequired,
};

export default HowOthersRateUs;
